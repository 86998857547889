<template>
  <pop-up-tooltip ref="popUp">
    <tooltip ref="buttonTooltip" :content="tooltip">
      <button-component
        v-if="buttonText && costCheck"
        id="send-for-review"
        :text="buttonText"
        :colour="buttonColour"
        :type="buttonType"
        :icon="buttonIcon"
        :disable-click="inReview"
        icon-type="fas"
        :hide-small="true"
        :full-width="true"
        :ping="popUp ? popUp.showPopUp : false"
        @click="send()"
      />
    </tooltip>
    <template #popUpContent>
      <div>
        {{ popUpText }}
      </div>
    </template>
  </pop-up-tooltip>
  <Modal ref="modal">
    <template #title>
      {{ modalCopy.modalTitle }}
    </template>
    <div class="tw-flex tw-flex-col tw-gap-2 tw-text-sm tw-text-gray-500">
      <p class="tw-text-sm tw-text-gray-500">
        {{ modalCopy.modalInfo }}
      </p>
      <span v-if="modalCopy.modalWarning" class="tw-pt-4">
        <strong>Please Note</strong> - {{ modalCopy.modalWarning }}
      </span>
      <banner-component
        v-if="costRdPage && !finalised && !costsFinalised"
        type="warning"
        text="Your Costs have not been completed. Sending your time spent on R&D for review will also complete your costs collection so make sure you're happy with them before proceeding."
      />
    </div>
    <div class="tw-pt-7 tw-flex tw-gap-x-2 tw-justify-between tw-flex-row-reverse">
      <button-component
        :text="modalCopy.modalButton || buttonText"
        colour="confirm"
        @click="finalised ? recallFromReview() : sendForReview()"
      />
      <button-component
        text="Cancel"
        icon=""
        colour="error"
        @click="modal.close()"
      />
    </div>
  </Modal>
</template>

<script>

import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import useEventsBus from "../../../shared/eventBus";
import { toValidateFields } from "../../../shared/fields.js";
import { SECTION_STATUS } from "../../../shared/section_status.js";
import { sendForReviewOptions, sendForReviewCopy, sendForReviewTooltip } from "../../../shared/send_for_review_options.js";
import { patch } from "../../../shared/http.js";

import Modal from "../../generic/modal_component.vue";

export default {
  components: {
    Modal
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const { emit } = useEventsBus();
    const { bus } = useEventsBus();

    const modal = ref(null);

    // These are properties that control the type and state of the section

    const narrative = computed(() => ["Project", "Challenge"].includes(props.section?.type));
    const costPage = computed(() => props.section?.section_type_key === "costs");
    const costRdPage = computed(() => props.section?.section_type_key === "costs_rd");
    const epwPage = computed(() => props.section?.section_type_key === "epw_contract_review");
    const grantPage = computed(() => props.section?.section_type_key === "grant");
    const costsFinalised = computed(() => props.section?.ready_for_review);
    const isEmpowerrd = computed(() => store.getters["users/GET_IS_EMPOWERRD"]);

    const sectionStatus = computed(() => store.getters["states/GET_SECTION_STATUS"]);
    const inProgress = computed(() => sectionStatus.value === SECTION_STATUS.IN_PROGRESS);
    const finalised = computed(() => {
      if (costPage.value || grantPage.value) return sectionStatus.value === SECTION_STATUS.SECTION_COMPLETE;

      return sectionStatus.value === SECTION_STATUS.FINALISED;
    });
    const inReview = computed(() => SECTION_STATUS.IN_REVIEW === sectionStatus.value);
    const validToSend = computed(() => store.getters["validations/GET_READY_TO_SEND_FOR_REVIEW"](props.section?.type, props.section?.new_project_methodology));
    const validations = computed(() => store.getters["validations/GET_VALIDATIONS"]);
    const anyValidationMessages = computed(() => store.getters["validations/GET_ANY_VALIDATION_MESSAGES"]);
    const linkedValidations = computed(() => store.getters["validations/GET_LINKED_VALIDATIONS"]);
    const userType = computed(() => store.getters["states/GET_USER_TYPE"]);

    // These are the properties that control the content of button and modal

    const buttonOptions = computed(() => sendForReviewOptions?.[props.section?.section_type_key]?.[sectionStatus.value]);
    const buttonText = computed(() => buttonOptions.value?.buttonText);
    const buttonIcon = computed(() => buttonOptions.value?.icon);
    const buttonColour = computed(() => ((validToSend.value || !inProgress.value) ? buttonOptions.value?.colour : "disabled"));
    const buttonType = computed(() => buttonOptions.value?.type);

    const costCheck = computed(() => {
      if (!costPage.value || (inProgress.value && userType.value === "admin")) return true;

      return !props.section?.pc_rd_ready_for_review;
    });

    const tooltipOptions = computed(() => sendForReviewTooltip?.[props.section?.section_type_key]);
    const tooltip = computed(() => {
      if (!inProgress.value) return tooltipOptions.value?.recall;
      if (validToSend.value) return tooltipOptions.value?.valid;
      if (validToSend.value === false) return (narrative.value && !props.section?.new_project_methodology) ? narrativeErrorMessages.value : errorMessages.value;

      return null;
    });

    const errorMessages = computed(() => (anyValidationMessages.value ? tooltipOptions.value?.invalid : tooltipOptions.value?.noMessages));

    const narrativeErrorMessages = computed(() => {
      if (anyValidationMessages.value) return tooltipOptions.value?.invalid;
      if (!store.getters["validations/GET_ANY_LINKED_VALIDATIONS"]) return tooltipOptions.value?.noChallenges;

      const incomplete_sections = _.chain(linkedValidations.value)
          .values()
          .unshift(validations.value)
          .filter(l => !l.valid)
          .map(l => ((l.type === "Project") ? "Project Details" : `${l.type} ${l.title || "(Untitled)"}`))
          .join(", ")
          .value();
      return tooltipOptions.value?.incompleteSections + incomplete_sections;
    });

    const modalCopy = computed(() => sendForReviewCopy?.[props.section?.section_type_key]?.[finalised.value ? "recall" : "send"]);

    // Button Trigger logic

    const anyUnsaved = computed(() => store.getters["costs/GET_ANY_UNSAVED_COSTS"]);

    async function send() {
      if (inReview.value) return;
      if (costPage.value && anyUnsaved.value) {
        emit("sendForReviewPrevented");
        return;
      }

      if (!finalised.value) {
        await store.dispatch("validations/VALIDATE", [
          props.section.type, props.section.slug, toValidateFields(props.section?.fields), true
        ]);
      }

      if (validToSend.value || finalised.value) {
        modal.value?.show();
        popUp.value?.dismiss();
      } else {
        emit("sendForReviewPrevented");
      }
    }

    const controller = computed(() => {
      if (narrative.value || grantPage.value) return "forms";
      if (epwPage.value) return "epw_contract_reviews";

      return "costs";
    });

    function sendForReview() {
      modal.value?.close();
      const url = `/api/${controller.value}/${costPage.value ? "mark_costs_complete" : "send_for_review"}/${props.section.type}/${props.section.slug}`;
      patch(url).then(data => {
        if (data.path) window.location.href = data.path;
      });
    }

    function recallFromReview() {
      modal.value?.close();
      const url = `/api/${controller.value}/${costPage.value ? "unmark_costs_complete" : "recall_from_review"}/${props.section.type}/${props.section.slug}`;
      patch(url).then(data => {
        if (data?.path) {
          window.location.href = data.path;
          return;
        }
        store.commit("states/SET_SECTION_STATUS", SECTION_STATUS.IN_PROGRESS);
        store.dispatch("states/FETCH_STATES_FOR_SECTION", ({ sectionType: props.section.type, sectionSlug: props.section.slug, rd: costRdPage.value }));

        const claimPeriodSlug = store.getters["feedbacks/GET_CLAIM_PERIOD_SLUG"];
        if (claimPeriodSlug) store.dispatch("states/FETCH_ALL_CLAIM_SECTION_STATES", ({ claimPeriodSlug }));

        if (isEmpowerrd.value) store.dispatch("reviews/FETCH_SECTION_REVIEWS", { sectionType: props.section.type, sectionSlug: props.section.slug });

        modal.value?.close();
      });
    }

    // Pop up Logic

    const buttonTooltip = ref(null);
    const popUp = ref(null);
    const popUpText = ref("");

    watch(() => bus.value.get("allCompleted"), () => {
      if (narrative.value && validToSend.value && inProgress.value) {
        popUpText.value = "Looks like you've completed this project's narrative. If you're happy with all your answers in these sections, click here to send them to our claim team to review and give feedback";
        popUp.value.triggerShow();
      }
    });

    watch(() => popUp.value?.showPopUp, val => {
      if (val) buttonTooltip.value?.disable();
      else if (tooltip.value) buttonTooltip.value?.enable();
    });

    watch(() => validToSend.value, (newVal, oldVal) => {
      if (narrative.value && !oldVal && newVal && !props.section.prompted_for_review && inProgress.value) {
        popUpText.value = "All your answers for this narrative are valid. When you're happy with all your answers in these sections, click here to send them to our claim team to review and give feedback";
        setTimeout(() => { popUp.value.triggerShow(); }, 1000);

        patch(`/api/forms/project_prompted_for_review/${props.section.type}/${props.section.slug}`).then(data => {
          if (data.success) props.section.prompted_for_review = true;
        });
      } else if (!newVal) {
        popUp.value?.dismiss();
      }
    });

    return {
      send,
      sendForReview,
      recallFromReview,
      modal,
      validToSend,
      validations,
      buttonText,
      buttonIcon,
      buttonColour,
      buttonType,
      tooltip,
      sectionStatus,
      inProgress,
      finalised,
      inReview,
      popUp,
      popUpText,
      buttonTooltip,
      modalCopy,
      costRdPage,
      costsFinalised,
      tooltipOptions,
      costCheck
    };
  }
};

</script>
