import { html, render } from "lit-html";

const auditRowChangesUpdate = change => html`
${Object.keys(change).map(i => html`<b class='tw-text-gray-600'>${i}</b>: ${change[i][0] || "(Blank)"} <span class='tw-text-gray-600'>changed to</span> ${change[i][1]}<br />`)}
`;

const auditRowChangesCreate = change => html`
 ${Object.keys(change).map(i => html`<b class='tw-text-gray-600'>${i}</b>: ${change[i]}<br />`)}
`;

const auditRowTemplate = audit => html`
     <tr>
                <td>
                  ${audit.action}
                </td>
                <td>
                  ${new Date(audit.created_at).toLocaleString()}
                </td>
                <td>
                  ${audit.user_name}
                </td>
                <td>
                ${audit.action === "create" ? html`${auditRowChangesCreate(audit.audited_changes)}` : html`${auditRowChangesUpdate(audit.audited_changes)}`}
                </td>
       </tr>
`;

const modalTemplate = (auditable_type, auditable_id, data) => html`
<div class="modal fade bd-example-modal-lg audit-log-modal" id="audit-log-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${data.title}</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <table class="table table-sm" style="font-size: 0.8em">
            <thead>
                <tr>
                  <th>Action</th>
                  <th>When</th>
                  <th>Who</th>
                  <th width="60%">Changes</th>
                </tr>
            </thead>
            <tbody>
             ${data.audit_entries.map(audit => html`${auditRowTemplate(audit)}`)}
            </tbody>
         </table>
      </div>
    </div>
  </div>
</div>

`;

window.$ = $;

const AuditLogDisplay = auditLogDisplay();

function auditLogDisplay() {
  $(document).on("click", ".audit-log-display", function (e) {
    const clicked = $(this);
    const auditableType = $(this).data("auditable-type");
    const auditableId = $(this).data("auditable-id");

    if (auditableType === "" || auditableId === "") {
      return;
    }

    $.ajax({
      url: "/api/feedbacks/audit_log",
      dataType: "JSON",
      method: "POST",
      data: { auditable_type: auditableType, auditable_id: auditableId },
      success(data, status, xhr) {
        if (data) {
          render(modalTemplate(auditableType, auditableId, data), document.getElementById("audit-modal-holder"));
          $("#audit-log-modal").modal("show");
        }
      }
    });
  });
}

export default AuditLogDisplay;
