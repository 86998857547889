<template>
  <div id="section-feedback" class="relative tw-bg-muted-blue tw-rounded-lg hover:tw-bg-deep-blue-light tw-flex tw-h-9 tw-items-center tw-text-sm">
    <button-component id="section-feedback-header">
      <i
        :class="[
          'far fa-smile fa-lg tw-cursor-pointer tw-text-gray-50 hover:tw-text-gray-200 tw-py-2.5 tw-px-2.5',
          open ? 'tw-text-white' : '',
        ]"
        @click="toggle()"
      />
    </button-component>

    <transition name="fade" mode="out-in">
      <div v-if="open">
        <div
          static
          class="
            tw-absolute
            tw-z-10
            tw-px-4
            tw-top-12
            tw-right-5
            tw-transform
            tw-transition
            tw-mt-2
            sm:tw-px-0
            lg:tw-max-w-3xl
            tw-origin-top-right
            tw-w-80
          "
        >
          <div class="tw-bg-white tw-rounded tw-shadow tw-items-center tw-justify-center">
            <div v-show="saved == true" class="tw-p-4 tw-text-center tw-text-xs tw-text-gray-600">
              Thanks for sharing your experience with us.
            </div>

            <div v-show="saved == false" class="tw-p-4 tw-space-y-3">
              <div class="tw-font-medium tw-text-xs">
                {{ question }}
              </div>

              <div class="tw-flex tw-justify-center tw-items-center tw-gap-x-2">
                <template v-for="_mood in moods" :key="_mood">
                  <div class="tw-bg-white" @click="setMood(_mood)">
                    <img
                      :src="`/images/face_${_mood}.svg`"
                      :class="['user-experience-feedback-mood1 tw-cursor-pointer tw-text-red-400 ', _mood == mood ? 'tw-w-10' : 'tw-w-8']"
                    >
                  </div>
                </template>
              </div>

              <FadeTransition>
                <div v-if="moodSet" class="tw-space-y-3">
                  <div v-if="!(question_two || question_three)" class="tw-w-full">
                    <textarea
                      v-model="comments"
                      rows="4"
                      :class="theme.forms.textarea"
                      :placeholder="moodText[mood]"
                    />
                  </div>

                  <div v-if="question_two" class="tw-w-full">
                    <div class="tw-font-medium tw-text-xs tw-mb-2">
                      {{ question_two }}
                    </div>

                    <textarea
                      v-model="answer_one"
                      rows="4"
                      :class="theme.forms.textarea"
                      placeholder="Type something..."
                    />
                  </div>

                  <div v-if="question_three" class="tw-w-full">
                    <div class="tw-font-medium tw-text-xs tw-mb-2">
                      {{ question_three }}
                    </div>

                    <textarea
                      v-model="answer_two"
                      rows="4"
                      :class="theme.forms.textarea"
                      placeholder="Type something..."
                    />
                  </div>

                  <div class="tw-justify-end tw-text-right tw-text-xs">
                    <SimpleButton text="Share Feedback" @click="save()" />
                  </div>
                </div>
              </FadeTransition>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Tippy } from "vue-tippy";
import SimpleButton from "../../generic/simple_button.vue";
import { documentCsrfToken } from "../../../shared/http.js";
import { css } from "../../../shared/theme.js";
import FadeTransition from "../../components/transitions/fade.vue";

export default {
  components: { SimpleButton, Tippy, FadeTransition },
  props: {
    openOnLoad: { type: Boolean, default: false },
    ux: { type: Object }
  },
  data() {
    return {
      open: false,
      moods: ["frown", "neutral", "happy"],
      mood: null,
      comments: null,
      answer_one: null,
      answer_two: null,
      theme: css,
      saved: false,
      moodText: { frown: "We're sorry to hear that you had a bad experience with us. What could we have done better?", neutral: "Thank you for the feedback. How could we make the experience better next time?", happy: "Great! Glad to hear you had a good experience with us. Is there anything we could do even better?" }
    };
  },
  computed: {
    moodSet() {
      return this.mood != null;
    },
    question() {
      return this.ux.question ?? "How happy were you completing this section?";
    },
    question_two() {
      return this.ux.question_two;
    },
    question_three() {
      return this.ux.question_three;
    }
  },
  mounted() {
    if (this.ux.mood != null) this.mood = this.ux.mood;

    if (this.openOnLoad == true) {
      setTimeout(() => {
        this.toggle();
      }, 500);
    }
  },
  methods: {
    toggle() {
      if (this.open == false) {
        this.open = true;
        this.track("Customer Feedback Shown", this.ux);
      } else {
        this.open = false;
      }
    },
    setMood(mood) {
      this.mood = mood;
      this.track("Customer Feedback Mood Given", Object.assign(this.ux, { mood: this.mood }));
      this.saveMood();
    },
    saveMood() {
      const formData = new FormData();
      formData.append("user_experience_feedback[user_experience_feedbackable_type]", this.ux.user_experience_feedbackable_type);
      formData.append("user_experience_feedback[user_experience_feedbackable_subtype]", this.ux.user_experience_feedbackable_subtype) ?? null;
      formData.append("user_experience_feedback[user_experience_feedbackable_id]", this.ux.user_experience_feedbackable_id);
      formData.append("user_experience_feedback[mood]", this.mood);
      fetch("/user_experience_feedbacks/save_feedback", {
        method: "POST",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": documentCsrfToken()
        },
        body: formData
      });
    },
    save() {
      const formData = new FormData();
      formData.append("user_experience_feedback[user_experience_feedbackable_type]", this.ux.user_experience_feedbackable_type);
      formData.append("user_experience_feedback[user_experience_feedbackable_subtype]", this.ux.user_experience_feedbackable_subtype);
      formData.append("user_experience_feedback[user_experience_feedbackable_id]", this.ux.user_experience_feedbackable_id);
      formData.append("user_experience_feedback[mood]", this.mood);
      formData.append("user_experience_feedback[comments]", this.comments);
      formData.append("user_experience_feedback[answer_one]", this.answer_one);
      formData.append("user_experience_feedback[answer_two]", this.answer_two);

      fetch("/user_experience_feedbacks/save_feedback", {
        method: "POST",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": documentCsrfToken()
        },
        body: formData
      }).then(response => response.json())
      .then(data => this.handleResponseData(data));
      this.track("Customer Feedback Submitted", Object.assign(this.ux, { mood: this.mood, comment: this.comments }));
    },
    handleResponseData(data) {
      this.saved = true;
      setTimeout(() => {
        this.open = false;
      }, 2000);
    }
  }
};
</script>
