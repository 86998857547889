<template>
  <div
    v-if="enabled"
    class="tw-relative tw-cursor-pointer tw-text-gray-600 hover:tw-text-gray-900"
    @click="trigger()"
  >
    <i class="tw-w-5 far fa-plus-circle fa-lg tw-rounded-full tw-py-0.5 tw-bg-blue-200" />
    <tooltip v-if="showPing" :content="pingTooltip">
      <span class="tw-absolute tw--top-1 tw--right-1 tw-flex tw-h-3 tw-w-3">
        <span class="tw-animate-ping tw-absolute tw-inline-flex tw-h-3 tw-w-3 tw-rounded-full tw-bg-blue-400 tw-opacity-75" />
        <span class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-blue-400" />
      </span>
    </tooltip>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    colRefDatabase: {
      type: String,
      required: true
    },
    colRefObject: {
      type: String,
      required: true
    },
    existingFeedback: {
      type: Object,
      required: false
    },
    parentSectionType: {
      type: String,
      required: true
    },
    sectionSlug: {
      type: String,
      required: true
    },
    userSlug: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: {}
    },
    sectionType: {
      type: String,
      required: true,
      validator: value => {
        const validSections = [
          "CostsSubcontractor",
          "CostsEmployee",
          "CostsOther",
          "CostsConsumable",
          "EpwContract"
        ];

        return validSections.indexOf(value) >= 0;
      }
    }
  },
  setup(props) {
    const store = useStore();
    const enabled = ref(false);
    const sectionReview = computed(() => store.getters["reviews/GET_REVIEW_FOR_SECTION"]);
    const pingTooltip = ref(null);
    const showInternationalSubcontractorWorkFlag = !!props.options?.showInternationalSubcontractorWork;

    onMounted(() => {
      setEnabledState();
    });

    watch(sectionReview, () => {
      setEnabledState();
    });

    function trigger() {
      window.triggerLeaveFeedbackPanel({
        field: props.field,
        sectionType: props.sectionType,
        colRefDatabase: props.colRefDatabase,
        colRefObject: props.colRefObject,
        claimPeriodSlug: props.options.claimPeriodSlug,
        parentSectionType: props.parentSectionType,
        sectionSlug: props.sectionSlug,
        showInternationalSubcontractorWork: showInternationalSubcontractorWorkFlag
      });
    }

    function setEnabledState() {
      if (sectionReview.value !== undefined) {
        const isReviewAssignedToMe = sectionReview.value?.reviewer?.slug === props.userSlug;
        enabled.value = (sectionReview.value.started_at !== null && isReviewAssignedToMe == true);
      }
    }

    const showPing = computed(() => {
      if (props.colRefObject === "contract_file" && props.field?.approved === false && props.existingFeedback === undefined) {
        pingTooltip.value = "Please ensure you provide some feedback as to why you rejected this EPW contract";
        return true;
      }

      if (showInternationalSubcontractorWorkFlag && props.field?.uk_based === false && props.colRefObject === "pc_rd") {
        if (props.field?.overseas_exemption_approved === null) {
          pingTooltip.value = "Please ensure you review the justification of this overseas subcontractor";
          return true;
        } if (props.field?.overseas_exemption_approved === false && props.existingFeedback === undefined) {
          pingTooltip.value = "Please ensure you provide some feedback as to why you rejected the justification of this overseas subcontractor";
          return true;
        }
      }

      return false;
    });

    return {
      trigger,
      enabled,
      sectionReview,
      showPing,
      pingTooltip
    };
  }
};
</script>
