<template>
  <DataTable
    :data="configuredData"
    :columns="tableColumns"
    enable-search
    table-header="All Users"
    :paginator-rows="20"
    :error-message="errorMessage"
    :is-loading="isLoading"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { get } from "@shared/http.js";
import { formatDate } from "@shared/helpers.js";
import DataTable from "@generic/data_table.vue";
import IconGroup from "@generic/icon_group.vue";
import { User } from "./interfaces";

const users = ref<User[]>([]);
const errorMessage = ref<string>("");
const isLoading = ref<boolean>(true);

getUsers();

async function getUsers() {
  const result = await get("/api/users");
  isLoading.value = false;

  if (result.error) {
    errorMessage.value = "There was an error fetching the users.";
  } else {
    users.value = result;
  }
}

const configuredData = computed(() => users.value.map(user => ({
  ...user,
  name: `${user.first_name} ${user.last_name}`,
  company: user.company?.name,
  active_claims: user.company?.active_claims_count,
  icons: { icons: user.icons }
})));

const tableColumns = [
  {
    header: "Name",
    field: "name",
    sortable: true,
    alignmentOverride: "tw-text-left"
  },
  {
    header: "Email",
    field: "email",
    sortable: true,
    alignmentOverride: "tw-text-left"
  },
  {
    header: "Company",
    field: "company",
    sortable: true
  },
  {
    header: "Active Claims",
    field: "active_claims",
    sortable: true
  },
  {
    header: "Role",
    field: "role",
    sortable: true
  },
  {
    header: "Created",
    field: "created_at",
    formatter: formatDate,
    sortable: true
  },
  {
    header: "Last Seen",
    field: "last_seen",
    formatter: formatDate,
    sortable: true
  },
  {
    header: "",
    field: "icons",
    component: IconGroup
  }
];
</script>
