<template>
  <div ref="section" class="section collapsible" :style="{ height: sectionHeight }">
    <slot />
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";

export default {
  props: {
    open: {
      required: false,
      type: Boolean
    }
  },
  setup(props) {
    const section = ref(null);

    const isCollapsed = ref();
    if (props.open !== undefined) isCollapsed.value = !props.open;
    else isCollapsed.value = true;

    watch(() => props.open, newValue => {
      if (newValue === true) expandSection();
      else collapseSection();
    });

    const sectionHeight = ref("0px");

    const collapseSection = () => {
      const height = `${section.value.scrollHeight}px`;
      sectionHeight.value = height;
      setTimeout(() => {
        sectionHeight.value = "0px";
      }, 0);
      isCollapsed.value = true;
    };

    const expandSection = () => {
      const height = `${section.value.scrollHeight}px`;
      sectionHeight.value = height;
      const transitionEndHandler = () => {
        sectionHeight.value = null;
        section.value.removeEventListener("transitionend", transitionEndHandler);
      };
      section.value.addEventListener("transitionend", transitionEndHandler);
      isCollapsed.value = false;
    };

    const toggleSection = () => {
      if (isCollapsed.value) expandSection();
      else collapseSection();
    };

    onMounted(() => {
      sectionHeight.value = isCollapsed.value ? "0px" : `${section.value.scrollHeight}px`;
    });

    return {
      section,
      sectionHeight,
      toggleSection,
      isCollapsed
    };
  }
};
</script>

<style scoped>
  .section {
    overflow: hidden;
    transition: height 0.3s ease-out;
    height: auto;
  }
</style>
