<template>
  <div class="tw-w-full" v-bind="$attrs">
    <div>
      <!-- complete icon -->
      <div class="tw-flex tw-items-stretch tw-gap-2 tw-justify-between">
        <div class="tw-mb-2">
          <div :field-key="field.label" :group-index="groupIndex" class="tw-items-center tw-flex">
            <label
              :for="field.label"
              class="tw-block tw-font-medium tw-mb-0"
            >
              <div class="tw-inline tw-space-x-3">
                <span>
                  {{ field.title }}
                  <span v-if="fieldRequired(sectionType, field.label)">
                    <tooltip content="Required">
                      <span class="tw-text-red-500 tw-text-base">*</span>
                    </tooltip>
                  </span>
                </span>
                <state-wrapper component="SectionKnowledgeCentre">
                  <KnowledgeCentreTriggerIcon
                    v-if="field.knowledge_centre"
                    :question="field.label"
                    :section="sectionType"
                    :icon="true"
                  />
                </state-wrapper>
                <state-wrapper v-if="!basicForm" component="PreviousResolvedFeedbackIcon">
                  <PreviousResolvedFeedbackIcon
                    :section-type="sectionType"
                    :section-slug="sectionSlug"
                    :col-ref="field.label"
                  />
                </state-wrapper>
                <state-wrapper v-if="!basicForm" component="AuditHistoryTrigger">
                  <AuditHistoryTrigger
                    :field="field"
                    :section-type="sectionType"
                    :section-slug="sectionSlug"
                  />
                </state-wrapper>
                <saving-spin-indicator :section-type="sectionType" :section-slug="sectionSlug" :fields="[field.label]" />
              </div>
            </label>
          </div>
          <state-wrapper component="field_blurb">
            <p
              v-show="field.field_blurb !== undefined && field.field_blurb !== null"
              class="
                tw-text-xs
                tw-text-gray-500
                tw-pr-10
                tw-mt-1
                tw-mb-0
              "
              v-html="field.field_blurb"
            />
          </state-wrapper>
        </div>
        <div v-if="!basicForm" class="tw-text-right tw-h-full tw-flex-shrink-0">
          <div class="tw-flex tw-gap-x-2">
            <div class="tw-flex tw-flex-col tw-items-end">
              <state-wrapper component="CompleteIcon">
                <CompleteIcon
                  :fields="fieldsForCompleteIcon"
                  :section-type="sectionType"
                  :section-slug="sectionSlug"
                  complete-icon-type="FieldComplete"
                />
              </state-wrapper>
            </div>
          </div>
        </div>
      </div>
      <!-- end complete icon -->
      <!-- start fields -->
      <div>
        <state-wrapper
          v-slot="{ editable }"
          component="RadioSelect"
        >
          <component
            :is="revealFieldType"
            :section-slug="sectionSlug"
            :section-type="sectionType"
            :editable="editable"
            :field="fieldProxy(field)"
            :is-reviewer="isReviewer"
            :basic-form="basicForm"
          />
        </state-wrapper>
        <span v-if="isDebounced(field)">
          <state-wrapper component="DebouncedSave">
            <DebouncedSave
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :field="field"
            />
          </state-wrapper>
        </span>
        <div>
          <IsBeingEdited
            v-if="false"
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :section-field-name="field.label"
          />
        </div>
        <state-wrapper v-if="field.can_leave_feedback" component="Feedback">
          <Feedback
            :field="field"
            :section-type="sectionType"
            :section-slug="sectionSlug"
          />
        </state-wrapper>
        <state-wrapper component="ValidationMessage">
          <ValidationMessage
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :field="field"
          />
        </state-wrapper>
      </div>
      <!-- end fields -->
    </div>
  </div>
  <!-- start subfields -->
  <template v-for="subfield in subfields" :key="subfield.label">
    <SlideFadeTransition>
      <field
        v-if="showSubfield(field, subfield)"
        :field="fieldProxy(subfield)"
        :section-slug="sectionSlug"
        :section-type="sectionType"
        :group-index="groupIndex"
        :basic-form="basicForm"
        :nested-reveal="subfield.type === 'RadioButtonRevealField'"
        :class="subfieldClasses"
      />
    </SlideFadeTransition>
  </template>
  <!-- end subfields -->
</template>

<script>
import { reactive } from "vue";
import { mapMutations, mapGetters } from "vuex";

import { SET_FIELD_TO_WRITE } from "@store/fields.js";
import { GET_USER_TYPE } from "@store/states.js";
import { GET_FIELD_REQUIRED } from "@store/sections.js";

import SmallText from "./fields/small_text.vue";
import LargeText from "./fields/large_text.vue";
import YesNoRadio from "./fields/yes_no_radio.vue";
import MultiSelect from "./fields/multi_select.vue";
import RadioSelect from "./fields/radio_select.vue";
import Date from "./fields/date.vue";
import Number from "./fields/number.vue";
import Currency from "./fields/currency.vue";
import DropdownSelect from "./fields/dropdown_select.vue";

import DebouncedSave from "./save_buttons/debounced_save.vue";
import SaveButton from "./save_buttons/save_button.vue";
import ValidationMessage from "./validation_message.vue";
import IsBeingEdited from "./is_being_edited.vue";
import Feedback from "./feedback.vue";
import CompleteIcon from "./complete_icon.vue";
import SlideFadeTransition from "../transitions/slide_fade.vue";
import PreviousResolvedFeedbackIcon from "../../generic/previous_resolved_feedback_icon.vue";
import AuditHistoryTrigger from "./audit_history_trigger.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";

import { showSubfield } from "../../../shared/fields.js";
import { FIELD_STATE } from "./shared/field_state.js";

export default {
  components: {
    SmallText,
    LargeText,
    YesNoRadio,
    MultiSelect,
    Date,
    RadioSelect,
    DebouncedSave,
    SaveButton,
    CompleteIcon,
    IsBeingEdited,
    Feedback,
    ValidationMessage,
    Number,
    Currency,
    SlideFadeTransition,
    PreviousResolvedFeedbackIcon,
    AuditHistoryTrigger,
    DropdownSelect,
    KnowledgeCentreTriggerIcon
  },
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument",
          "RdProject"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true,
      validator: obj => {
        const valid_field_types = [
          "RadioButtonRevealField"
        ];

        return valid_field_types.indexOf(obj.type) >= 0;
      }
    },
    options: {
      type: Object,
      required: false
    },
    groupIndex: {
      required: false
    },
    basicForm: {
      type: Boolean,
      default: false
    },
    subfieldClasses: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      userType: `states/${GET_USER_TYPE}`,
      fieldRequired: `sections/${GET_FIELD_REQUIRED}`
    }),
    subfields() {
      return this.field.subfields;
    },
    isReviewer() {
      return this.userType == "reviewer";
    },
    fieldsForCompleteIcon() {
      return [_.omit(this.field, "subfields")];
    },
    revealFieldType() {
      return Object.keys(this.field?.reveal_values || []).every(key => key === "true" || key === "false") ? "YesNoRadio" : "RadioSelect";
    }
  },
  watch: {
    "field.value": function (_val) {
      this.setFieldToWrite(
        `${this.sectionType}:${this.sectionSlug}:${this.field.label}`
      );
      if (this.field.state < FIELD_STATE.EDITED) {
        this.field.state = FIELD_STATE.EDITED;
      }
    }
  },
  methods: {
    ...mapMutations({
      setFieldToWrite: `fields/${SET_FIELD_TO_WRITE}`
    }),
    isDebounced(field) {
      return field.type !== "LargeText";
    },
    fieldProxy(field) {
      const reactiveField = reactive(field);

      reactiveField.state = field.state ?? FIELD_STATE.UNTOUCHED;
      return reactiveField;
    },
    showSubfield
  }
};
</script>
