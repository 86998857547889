<template>
  <transition name="fade" :duration="2000">
    <span
      v-if="show && (loading || spinLoading || flashSaved || anyActiveRecordValidations)"
      :class="[
        bgColor && !anyActiveRecordValidations ? 'tw-bg-confirm-green-100 tw-p-1.5' : '',
        transparent ? 'tw-text-white' : 'tw-text-confirm-green'
      ]"
      class="tw-inline-flex tw-items-center tw-gap-1 tw-rounded-md"
    >
      <span v-if="flashSaved && !noText" class="tw-text-sm">
        Saved
      </span>
      <span class="tw-h-5 tw-w-5 tw-flex tw-items-center tw-justify-center">
        <i v-if="!anyActiveRecordValidations && (loading || spinLoading)" class="tw-animate-spin far fa-spinner-third" />
        <i v-if="flashSaved && !hasErrored" class="fas fa-check-circle" />
        <i v-if="flashSaved && hasErrored" class="fas fa-exclamation-triangle tw-text-error-red" />
        <tooltip v-else-if="anyActiveRecordValidations" content="Changes were not saved, see error message below">
          <i class="fas fa-exclamation-triangle tw-text-error-red" />
        </tooltip>
      </span>
    </span>
  </transition>
</template>

<script>

import { ref, watch, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "SavingSpinIndicator",
  props: {
    fields: {
      type: Array,
      required: false
    },
    sectionSlug: {
      type: String,
      required: false
    },
    sectionType: {
      type: String,
      required: false
    },
    bgColor: {
      type: Boolean,
      required: false
    },
    noText: {
      type: Boolean,
      required: false,
      default: false
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false
    },
    manual: {
      type: Boolean,
      required: false,
      default: false
    },
    hasErrored: {
      type: Boolean
    }
  },
  emits: ["finishedSaving"],
  setup(props, context) {
    const store = useStore();
    const spinLoading = ref(false);
    const flashSaved = ref(false);

    const loading = computed(() => store.getters["validations/GET_LOADING"]);
    const validatedFields = computed(() => store.getters["validations/GET_FIELDS_BEING_VALIDATED"]);
    const anyActiveRecordValidations = computed(() => store.getters["validations/GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_FIELDS"](props.fields));

    const show = computed(() => {
      if (props.manual) return true;

      validatedFields.value;
      return props.fields.every(field => validatedFields.value?.includes(`${props.sectionType}:${props.sectionSlug}:${field}`));
    });

    function startSpinner() {
      spinLoading.value = true;
    }

    function stopSpinner() {
      spinLoading.value = false;
    }

    watch(() => loading.value, () => {
      if (!loading.value && !anyActiveRecordValidations.value) {
        spinLoading.value = true;
        setTimeout(() => spinLoading.value = false, 1500);
      }
    });

    watch(() => spinLoading.value, () => {
      if (!spinLoading.value && !loading.value) {
        flashSaved.value = true;
        setTimeout(() => {
          flashSaved.value = false;
          context.emit("finishedSaving");
          if (validatedFields.value?.length > 0) store.commit("validations/SET_FIELDS_BEING_VALIDATED", []);
        }, 1000);
      }
    });

    return {
      loading,
      flashSaved,
      spinLoading,
      show,
      validatedFields,
      anyActiveRecordValidations,
      startSpinner,
      stopSpinner
    };
  }
};
</script>
