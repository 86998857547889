<template>
  <div class="tw-w-full">
    <div v-if="editable" class="tw-relative">
      <input
        :id="`${sectionType}_${sectionSlug}_${field.label}`"
        v-model="field.value"
        type="number"
        :name="field.label"
        min="0"
        :class="[theme.forms.input, borderStyle]"
        :placeholder="field.field_placeholder_blurb"
        @input="handleInput"
      >
      <span class="tw-absolute tw-right-0 tw-inset-y-0 tw-flex tw-items-center tw-m-1">
        <saving-spin-indicator
          :section-type="sectionType"
          :section-slug="sectionSlug"
          :fields="[field.label]"
          :bg-color="true"
          @finishedSaving="clean()"
        />
      </span>
    </div>
    <div v-else>
      <div class="tw-text-gray-500 tw-text-sm tw-flex tw-text-center tw-gap-1">
        <span>{{ field.value || '-' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { FIELD_STATE } from "../shared/field_state.js";
import { css } from "../../../../shared/theme.js";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      theme: css,
      borderStyle: null
    };
  },
  watch: {
    "field.value": function (_val) {
      this.debouncedBorderStyle();
    },
    "field.state": function (_val) {
      this.debouncedBorderStyle();
    }
  },
  mounted() {
    this.debouncedBorderStyle = _.debounce(this.changeBorderStyle, 1000);
  },
  methods: {
    changeBorderStyle() {
      if (this.field.state === FIELD_STATE.EDITED) {
        this.borderStyle = this.theme.borderStyles.edited;
      } else if (this.field.state === FIELD_STATE.SAVED) {
        this.borderStyle = this.theme.borderStyles.styleSaved;
      }
    },
    clean() {
      this.borderStyle = this.theme.borderStyles.untouched;
    },
    handleInput(event) {
      if (event.target.validity.valid === false) {
        event.target.value = "";
      }
    }
  }
};
</script>
