<template>
  <div class="md:tw-flex md:tw-gap-x-1 tw-mb-2 md:tw-mb-1">
    <div class="md:tw-w-1/2">
      <div v-if="hasUrl">
        <a :href="url">
          <dt class="tw-text-sm tw-font-medium tw-text-gray-400">
            {{ title }}
          </dt>
        </a>
      </div>

      <div v-else>
        <dt class="tw-text-sm tw-font-medium tw-text-gray-400">
          {{ title }}
        </dt>
      </div>
    </div>

    <div class="tw-flex tw-items-center">
      <dd class="tw-text-sm tw-text-gray-900">
        <slot />
      </dd>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: false
    }
  },

  setup(props) {
    const hasUrl = computed(() => props.url !== undefined);

    return {
      hasUrl
    };
  }
};
</script>
