export default {
  costs: {
    captureTable: {
      pageBlurb: 11,
      CostsEmployee: 12,
      CostsSubcontractor: 13,
      CostsConsumable: 14
    },
    rdTable: {
      pageBlurb: 25,
      CostsEmployee: 18,
      CostsSubcontractor: 19,
      CostsConsumable: 20,
      CostsOther: 21
    }
  },
  projectCostBreakdown: {
    allocateCost: {
      pageBlurb: 23
    },
    summary: {
      pageBlurb: 24
    }
  },
  projectGrantAllocation: {
    intro: 27
  },
  externalWorkers: {
    introForPaye: 33,
    introForPayeAndContracts: 32
  }
};
