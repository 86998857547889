<template>
  <SwitchGroup>
    <div :class="[disabled ? 'tw-text-gray-400' : '', 'tw-flex tw-flex-col tw-gap-y-1']">
      <div class="tw-inline-flex tw-justify-between tw-items-center">
        <SwitchLabel v-if="label" passive class="tw-text-sm tw-m-0">
          {{ label }}
        </SwitchLabel>
        <Switch
          v-model="value"
          :class="[
            value ? 'tw-bg-deep-blue' : 'tw-bg-gray-200',
            disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
            'tw-relative tw-inline-flex tw-h-6 tw-w-11 tw-flex-shrink-0 tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-deep-blue focus:tw-ring-offset-2'
          ]"
        >
          <span aria-hidden="true" :class="[value ? 'tw-translate-x-5' : 'tw-translate-x-0', 'tw-pointer-events-none tw-inline-flex tw-justify-center tw-items-center tw-h-5 tw-w-5 tw-transform tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out']">
            <span v-if="icon" class="tw--mt-0.5">
              <i :class="['fas fa-xs tw-transition tw-duration-300', value ? 'fa-check tw-text-deep-blue' : 'fa-times tw-text-gray-400']" />
            </span>
          </span>
        </Switch>
      </div>
      <SwitchDescription v-if="description" as="small" :class="[disabled ? 'tw-text-gray-300' : 'text-muted', 'tw-w-11/12 tw-text-xs tw-leading-tight']">
        {{ description }}
      </SwitchDescription>
    </div>
  </SwitchGroup>
</template>

<script>
import { Switch, SwitchGroup, SwitchLabel, SwitchDescription } from "@headlessui/vue";

export default {
  components: {
    SwitchGroup,
    Switch,
    SwitchLabel,
    SwitchDescription
  },
  props: {
    fieldValue: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    icon: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["updateFieldValue"],
  computed: {
    value: {
      get() {
        return this.fieldValue;
      },
      set(value) {
        this.$emit("updateFieldValue", value);
      }
    }
  }
};
</script>
