<template>
  <span>
    <Tooltip :content="tooltip">
      <a
        v-if="linkData && linkData.href"
        v-bind="linkData"
        class="tw-cursor-pointer"
      >
        <i :class="`far fa-${computedType} ${iconClasses} ${typeClasses}`" />
      </a>

      <button v-else-if="onClick" :aria-label="ariaLabel" @click="onClick()">
        <i :class="`far fa-${computedType} ${iconClasses} ${typeClasses}`" />
      </button>

      <i v-else :class="`far fa-${computedType} ${iconClasses} ${typeClasses}`" />
    </Tooltip>
  </span>
</template>

<script setup>
import { computed } from "vue";
import Tooltip from "@generic/tooltip.vue";

const props = defineProps({
  type: {
    type: [String, Boolean]
  },
  iconClasses: {
    type: String,
    default: ""
  },
  linkData: {
    type: Object,
    default: null
  },
  onClick: {
    type: Function,
    default: null
  },
  tooltip: {
    type: String,
    default: ""
  },
  ariaLabel: {
    type: String,
    default: ""
  }
});

const computedType = computed(() => {
  if (props.type === true || props.type === "true") {
    return "check";
  } else if (props.type === false || props.type === "false") {
    return "times";
  } else {
    return props.type;
  }
});

const typeClasses = computed(() => {
  switch (computedType.value) {
    case "trash":
    case "times":
    case "engine-warning":
    case "exclamation-triangle":
      return "tw-text-red-700";

    case "edit":
    case "pencil":
      return "tw-text-blue-700";

    case "check":
    case "check-circle":
    case "plus-square":
      return "tw-text-green-700";

    case "spinner-third":
      return "tw-text-green-700 tw-animate-spin";

    default:
      return "";
  }
});
</script>
