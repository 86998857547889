<template>
  <dropdown
    v-if="editable"
    ref="dropdownRef"
    class="tw-relative"
    :disabled="disabled"
  >
    <template #link>
      <div>
        <input
          v-model="query"
          type="text"
          :placeholder="fieldValue.length > 0 ? `${fieldValue.length} selected` : placeholder"
          :class="[disabled ? theme.forms.inputDisabled : theme.forms.input, 'tw-text-xs tw-pr-8 tw-truncate']"
          :disabled="disabled"
        >
        <span class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer tw-z-0">
          <FadeTransition>
            <i
              v-if="query !== null && query.length !== 0"
              class="far fa-times tw-text-gray-500"
              @click="query = null"
            />
          </FadeTransition>
          <i :class="['far fa-angle-down tw-text-gray-500 tw-duration-300 ', dropdownRef && dropdownRef.show ? 'tw-transform tw-rotate-180' : '']" />
        </span>
      </div>
    </template>
    <template #items>
      <div
        :class="['tw-w-full tw-mt-0.5 tw-max-h-60 tw-overflow-y-scroll tw-z-50', theme.dropDown.menuDivFullWidth]"
      >
        <div
          v-if="showSelectAll"
          :class="[theme.dropDown.itemDiv, 'tw-w-full tw-inline-flex tw-gap-x-1.5 tw-cursor-pointer']"
          @click="handleClick()"
        >
          <input
            type="checkbox"
            :class="[theme.forms.checkbox]"
            :checked="allSelected"
          >
          <span class="tw-truncate">
            {{ selectAllText }}
          </span>
        </div>
        <hr class="tw-mx-4">
        <template
          v-for="(option, key) in getOptions(query)"
          :key="key"
        >
          <div
            :class="[theme.dropDown.itemDiv, 'tw-w-full tw-inline-flex tw-gap-x-2']"
            @click="queryReturnsNull ? null : setFieldValue(option.slug)"
          >
            <input
              v-if="!queryReturnsNull"
              type="checkbox"
              :class="theme.forms.checkbox"
              :checked="includedInSelectedOptions(option.slug)"
            >
            <tooltip :content="option.title">
              <span class="tw-truncate">
                {{ option.title }}
              </span>
            </tooltip>
          </div>
        </template>
      </div>
    </template>
  </dropdown>
  <div v-else>
    <Tippy v-if="getOptions().length > 0" :content="getOptions().map(option => option.title).join(', ')">
      <div class="tw-text-xs tw-inline-flex tw-items-center tw-px-2 tw-h-6 tw-rounded-full tw-bg-gray-200">
        {{ getOptions().length }} {{ readOnlyText }}
      </div>
    </Tippy>
    <div v-else-if="!disabled" class="tw-text-gray-400 tw-italic tw-text-xs">
      No {{ readOnlyText }}
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { Tippy } from "vue-tippy";

import FadeTransition from "../../transitions/fade.vue";
import { css } from "../../../../shared/theme.js";

export default {
  components: {
    FadeTransition,
    Tippy
  },
  props: {
    placeholder: {
      type: String,
      required: false
    },
    readOnlyText: {
      type: String,
      required: false,
      default: "Options selected"
    },
    modelValue: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    selectAllText: {
      type: String,
      required: false,
      default: "Select All"
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const theme = css;
    const dropdownRef = ref(null);
    const query = ref(null);
    const fieldValue = ref(props.modelValue);
    const queryReturnsNull = ref(false);
    const allOptionsSlugs = props.options.map(option => option.slug);
    const allSelected = ref(fieldValue.value.length === allOptionsSlugs.length);
    const showSelectAll = computed(() => query.value === null || query.value.length === 0);

    function setFieldValue(optionSlug) {
      let updatedValue = [...fieldValue.value];

      if (fieldValue.value.includes(optionSlug)) {
        updatedValue = fieldValue.value.filter(slug => slug !== optionSlug);
      } else {
        updatedValue.push(optionSlug);
      }

      fieldValue.value = updatedValue;
      emit("update:modelValue", fieldValue.value);
    }

    function getOptions(query = null) {
      if (!props.editable) {
        return props.options.filter(f => fieldValue.value.includes(f.slug));
      }

      if (query === null) {
        return props.options;
      }

      const matches = props.options.filter(f => f.title.toUpperCase().indexOf(query.toUpperCase()) > -1);

      if (matches.length > 0) {
        queryReturnsNull.value = false;

        return matches;
      }
      queryReturnsNull.value = true;

      return [{ title: `No matches found for '${query}'` }];
    }

    function includedInSelectedOptions(optionSlug) {
      return fieldValue.value.includes(optionSlug);
    }

    function handleClick() {
      allSelected.value = !allSelected.value;
      fieldValue.value = allSelected.value ? allOptionsSlugs : [];
    }

    watch(() => fieldValue.value, value => {
      emit("update:modelValue", value);

      if (value.length === allOptionsSlugs.length) {
        allSelected.value = true;
      } else {
        allSelected.value = false;
      }
    });

    return {
      theme,
      setFieldValue,
      dropdownRef,
      query,
      getOptions,
      fieldValue,
      queryReturnsNull,
      allOptionsSlugs,
      allSelected,
      includedInSelectedOptions,
      handleClick,
      showSelectAll
    };
  }
};
</script>
