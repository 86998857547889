<template>
  <slot
    v-if="show"
    :editable="!!(editable || adminEditable || checkWhitelist)"
  />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import { alwaysHiddenFor,
  readOnlyHiddenFor,
  hiddenForSectionType,
  hiddenForSectionStatus,
  checkForFeatureFlag,
  multiDependencyWhitelist,
  sectionsAlwaysEditableForAdmins } from "../../shared/componentStates.js";

export default {
  props: {
    component: {
      type: String
    },
    rd: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const store = useStore();
    const editable = computed(() => store.getters["states/GET_EDIT_STATE"]);
    const userType = computed(() => store.getters["states/GET_USER_TYPE"]);
    const sectionType = computed(() => store.getters["sections/GET_SECTION_TYPE"]);
    const sectionStatus = computed(() => store.getters["states/GET_SECTION_STATUS"]);
    const flags = computed(() => store.getters["states/GET_ENABLED_FEATURE_FLAGS"]);

    const adminEditable = computed(() => userType.value === "admin" && sectionsAlwaysEditableForAdmins.includes(sectionType.value));

    function flagEnabled(flag) {
      return store.getters["states/GET_FLAG_ENABLED"](flag);
    }

    const checkWhitelist = computed(() => multiDependencyWhitelist(props.component, {
      sectionType: sectionType.value,
      userType: userType.value,
      sectionStatus: sectionStatus.value,
      editable: editable.value,
      rd: props.rd,
      flags: flags.value
    }));

    const show = computed(() => {
      if (checkWhitelist?.value) {
        // Show if there is a case where the component should be shown based on multiple conditions (see multiDependencyWhitelist function in componentStates.js)
        return true;
      } if (alwaysHiddenFor[userType.value]?.includes(props.component)) {
        // Hide if user role not allowed
        return false;
      } if (hiddenForSectionType[sectionType.value]?.includes(props.component)) {
        // Hide if not needed for Section Type
        return false;
      } if (hiddenForSectionStatus[sectionStatus.value]?.[props.component]) {
        // Hide if not needed for Section Status
        return false;
      } if (!editable.value && readOnlyHiddenFor[userType.value]?.includes(props.component)) {
        // Hide if not visible when section is read only (unless admin)
        return userType.value === "admin";
      } if (checkForFeatureFlag?.[sectionType.value]?.[props.component] && !flagEnabled(checkForFeatureFlag?.[sectionType.value]?.[props.component])) {
        // Hide if component needs feature flag that is missing
        return false;
      }
      return true;
    });

    return {
      show,
      editable,
      userType,
      checkWhitelist,
      adminEditable
    };
  }
};
</script>
