<template>
  <ButtonComponent
    full-width
    :text="isComplete ? 'Completed' : 'Mark as Complete'"
    icon="check-circle"
    :colour="buttonColor"
    :tooltip="tooltipContent"
    :disable-click="isDisabled"
    @click="!isDisabled && $emit('handle-complete')"
  />
</template>

<script setup>
import { computed } from "vue";
import ButtonComponent from "@generic/button_component.vue";

defineEmits(["handle-complete"]);

const props = defineProps({
  isComplete: {
    type: Boolean
  },
  isDisabled: {
    type: Boolean
  },
  tooltipContent: {
    type: String
  }
});

const buttonColor = computed(() => {
  if (props.isComplete) {
    return "confirm";
  } else if (props.isDisabled) {
    return "disabled";
  } else {
    return "cta";
  }
});

</script>
