<template>
  <ButtonComponent
    :id="`unlock-${sectionType.toLowerCase()}`"
    text="Unlock for Edits"
    icon="unlock"
    :hide-small="true"
    :full-width="true"
    @click="unlock()"
  />
</template>

<script>
import { useStore } from "vuex";
import { patch } from "../../shared/http.js";

import { SECTION_STATUS } from "../../shared/section_status.js";
import ButtonComponent from "../generic/button_component.vue";

export default {
  components: {
    ButtonComponent
  },
  props: {
    sectionSlug: {
      type: String,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    rd: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const store = useStore();

    function unlock() {
      const message = "Are you sure you want to unlock this Section for further edits? If further changes are made, it will need to be reviewed again";

      const route = props.sectionType === "Cost" ? "costs" : `forms/${props.sectionType}`;

      if (confirm(message)) {
        patch(`/api/${route}/${props.sectionSlug}/unlock`)
            .then(data => {
              if (data?.path) {
                window.location.href = data.path;
                return;
              }

              store.commit("states/SET_SECTION_STATUS", SECTION_STATUS.IN_PROGRESS);
              store.dispatch("states/FETCH_STATES_FOR_SECTION", ({ sectionType: props.sectionType, sectionSlug: props.sectionSlug, rd: props.rd }));
            });
      }
    }

    return {
      unlock
    };
  }
};
</script>
