<template>
  <tooltip :content="tooltip">
    <div :class="[color]" class="tw-inline-flex">
      <span v-if="type !== 'LargeText'" class="tw-text-sm tw-mr-2 tw-hidden 2xl:tw-inline">
        {{ label }}
      </span>
      <span class="tw-hidden 2xl:tw-inline">
        <i :class="icon" class="fa-1x tw-pt-0.5" />
      </span>
      <span class="2xl:tw-hidden">
        <i class="far fa-check-square tw-mr-1 fa-1x tw-pt-0.5" />
      </span>
    </div>
  </tooltip>
</template>

<script>
export default {
  props: {
    sectionType: {
      type: String,
      required: false
    },
    completed: {
      type: Boolean,
      required: true
    },
    validationErrors: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  computed: {
    color() {
      if (this.completed) return "tw-text-green-600 hover:tw-text-green-700";

      return "tw-text-gray-500 hover:tw-text-gray-900";
    },
    label() {
      if (this.completed) return "Completed";

      return "Mark as complete";
    },
    icon() {
      if (this.completed) return "far fa-check-square tw-mr-1";

      return "far fa-square tw-mr-1";
    },
    tooltip() {
      if (this.completed) return "This question is complete";
      if (this.validationErrors) return "Please address errors to complete this question";

      return "Click to mark this question as complete";
    }
  }
};
</script>
