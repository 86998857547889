<template>
  <ButtonComponent id="section-assign-icon" :tooltip="tooltip" :tooltip-options="{ delay: [400, null] }">
    <div :class="[color, 'section-assign tw-cursor-pointer', showInSmall ? '' : 'tw-text-base']">
      <span class="tw-cursor-pointer tw-font-semibold tw-mr-2 tw-hidden lg:tw-inline">{{ text }}</span>
      <i :class="[icon, 'fas', showInSmall ? '' : 'fa-lg']" />
    </div>
  </ButtonComponent>
</template>

<script>
import ButtonComponent from "@generic/button_component.vue";

export default {
  components: {
    ButtonComponent
  },
  props: {
    sectionType: {
      type: String
    },
    subsectionType: {
      type: String
    },
    assigned: {
      type: Boolean,
      required: true
    },
    assignedToUser: {
      type: Boolean
    },
    assignedUsers: {
      type: Object
    }
  },
  computed: {
    sectionTypeName() {
      if (this.sectionType === "Grant") {
        return "External Funding";
      } else if (this.sectionType === "Status") {
        return "Business Questionnaire";
      } else if (this.sectionType === "Cost") {
        return this.subsectionType ? "Time Spent on R&D" : "Cost Capture";
      } else if (this.sectionType === "ProjectGrantAllocationSection") {
        return "Project Grant Allocation";
      }

      return _.startCase(this.sectionType);
    },
    user() {
      return this.assignedUsers[0];
    },
    icon() {
      return this.assigned ? "fa-user lg:tw-px-1" : "fa-user-plus";
    },
    color() {
      if (this.assigned && this.assignedToUser) {
        return "tw-text-green-600 tw-font-semibold hover:tw-text-green-700";
      } else if (this.assigned) {
        return "tw-text-indigo-500 hover:tw-text-indigo-700";
      } else {
        return "tw-text-gray-500 hover:tw-text-gray-900";
      }
    },
    label() {
      return this.assigned ? `${this.sectionTypeName} assigned` : `Assign this ${this.sectionTypeName.toLowerCase()}`;
    },
    text() {
      if (this.assignedToUser && this.assignedUsers.length > 1) {
        return `${this.sectionTypeName} assigned to you & others`;
      } else if (this.assignedToUser) {
        return `${this.sectionTypeName} assigned to you`;
      } else if (this.assigned) {
        if (this.assignedUsers.length === 1 && this.user) {
          return `${this.sectionTypeName} assigned to ${this.user.first_name} ${this.user.last_name[0]}`;
        } else if (!this.assigned) {
          return `${this.sectionTypeName} assigned to ${this.assignedUsers.length} others`;
        } else {
          return "All questions have been assigned";
        }
      } else {
        return `Assign ${this.sectionTypeName}`;
      }
    },
    tooltip() {
      if (this.assignedToUser) {
        return `This ${this.sectionTypeName} has been assigned to you, click to assign others`;
      } else if (this.assigned) {
        return "Click to re-assign or assign others";
      } else {
        return `Click here to assign this ${this.sectionTypeName.toLowerCase()} to someone in your company`;
      }
    },
    showInSmall() {
      return this.sectionType === "ProjectSelection";
    }
  }
};
</script>
