<template>
  <div :class="['tw-flex tw-flex-col tw-gap-3', isReviewer ? 'xl:tw--mx-24' : '']">
    <div class="tw-w-full tw-flex tw-justify-between tw-gap-x-6">
      <div class="tw-flex-1 tw-min-w-0 tw-flex tw-flex-col">
        <h1 class="tw-text-2xl tw-font-semibold">
          {{ showAddRd ? 'Time Spent on R&D' : 'Cost Capture' }}
        </h1>
        <div v-if="!isReviewer" class="tw-prose tw-prose-sm tw-max-w-full">
          <CmsContentSlot
            :content-slot-id="blurbContentSlotId"
            size="small"
          />
        </div>
        <KnowledgeCentreTriggerIcon
          v-if="!showAddRd"
          section="Cost"
          faq="is-the-expenditure-qualifying"
          text="Help with Cost Capture"
        />
      </div>
      <div class="tw-flex-none xl:tw-w-1/3">
        <ReviewPanel
          v-if="isReviewer"
          section-type="Cost"
          :section-slug="slug"
          :user-slug="userSlug"
          :block-completion="blockReviewCompletion"
          :block-completion-message="blockReviewCompletionMessage"
          :financial-year-breakdown="financialYearBreakdown"
        />
        <CostActionCard
          v-else
          :slug="slug"
          :options="options"
          :rd="showAddRd"
          :accounting-integration-slug="accountingIntegrationSlug"
          :claim-period-slug="claimPeriodSlug"
        />
      </div>
    </div>
    <CostTabs
      v-if="!loading"
      :slug="slug"
      :user-role="userRole"
      :user-slug="userSlug"
      :claim-period-slug="claimPeriodSlug"
      :show-add-rd="showAddRd"
      :options="options"
      :external-funding="externalFunding"
      :open-tab="openTab"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import strapiContentIds from "../../../shared/strapi_content_ids.js";
import CmsContentSlot from "../../generic/cms_content_slot.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";
import ReviewPanel from "../sections/review_panel.vue";
import CostActionCard from "./cost_actions_card.vue";
import CostTabs from "./cost_tabs.vue";

import { featureEnabled } from "../../../shared/fields.js";

export default {
  name: "Cost",
  components: {
    CmsContentSlot,
    KnowledgeCentreTriggerIcon,
    ReviewPanel,
    CostActionCard,
    CostTabs
  },
  props: {
    slug: {
      type: String,
      required: true
    },
    userRole: {
      type: String,
      required: true
    },
    userSlug: {
      type: String,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: true
    },
    showAddRd: {
      type: Boolean,
      required: true
    },
    accountingIntegrationSlug: {
      type: String,
      required: false
    },
    options: {
      type: Object,
      default: () => ({})
    },
    externalFunding: {
      type: Boolean,
      default: false
    },
    openTab: {
      type: Number,
      required: false
    },
    financialYearBreakdown: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const loading = computed(() => store.getters["costs/GET_LOADING"]);
    const isAdmin = computed(() => props.userRole === "emp_admin");
    const isReviewer = computed(() => props.userRole === "emp_reviewer");
    const isEmpowerrd = computed(() => isAdmin.value || isReviewer.value);
    const subcontractors = computed(() => store.getters["costs/GET_SUBCOSTS"]("CostsSubcontractor"));
    const blockReviewCompletionMessage = ref(null);

    const blurbContentSlotId = computed(() => {
      if (props.showAddRd) {
        return strapiContentIds.costs.rdTable.pageBlurb;
      } else {
        return strapiContentIds.costs.captureTable.pageBlurb;
      }
    });

    function fetchData() {
      const promises = [
        store.commit("costs/SET_LOADING", true),
        store.dispatch("users/SET_USER"),
        store.commit("sections/SET_SECTION_TYPE", "Cost"),
        store.commit("sections/SET_SECTION_SLUG", props.slug),
        store.commit("feedbacks/SET_CLAIM_PERIOD_SLUG", props.claimPeriodSlug),
        store.commit("validations/SET_VALIDATION_PARAMS", { rd: props.showAddRd }),
        store.dispatch("feedbacks/FETCH_CLAIM_FEEDBACK", { claimPeriodSlug: props.claimPeriodSlug }),
        store.dispatch("states/FETCH_STATES_FOR_SECTION", { sectionType: "Cost", sectionSlug: props.slug, rd: props.showAddRd }),
        store.dispatch("costs/FETCH_SUBCOSTS", { costSlug: props.slug, subCostType: "CostsSubcontractor", rd: props.showAddRd }),
        store.dispatch("validations/VALIDATE", ["Cost", props.slug])
      ];

      if (isEmpowerrd.value) {
        promises.push(store.dispatch("audit_history/FETCH_AUDIT_HISTORY", { sectionType: "Cost", sectionSlug: props.slug }));
        promises.push(store.dispatch("reviews/FETCH_SECTION_REVIEWS", { sectionType: "Cost", sectionSlug: props.slug }));
      }

      Promise.all(promises).then(() => store.commit("costs/SET_LOADING", false));
    }

    onMounted(() => {
      fetchData();
    });

    function getUnresolvedFeedbackForSection(type, slug) {
      return store.getters["feedbacks/GET_FEEDBACKS_FOR_SECTION"](type, slug);
    }

    const blockReviewCompletion = computed(() => {
      let block = false;
      const overseasSubcontractors = _.filter(subcontractors.value, ["uk_based", false]) || null;

      if (featureEnabled(props.options, "feature_international_subcontractors") === false) {
        return block;
      }

      const notReviewed = _.filter(overseasSubcontractors, ["overseas_exemption_approved", null]) || [];

      const rejectedWithNoFeedback = _.filter(overseasSubcontractors, s => s.overseas_exemption_approved === false && getUnresolvedFeedbackForSection("CostsSubcontractor", s.slug).length === 0) || [];

      if (notReviewed.length > 0) {
        block = true;
        blockReviewCompletionMessage.value = `Before completing your review, you will need to approve or reject the justification of all overseas subcontractors (${notReviewed.length} remaining)`;
      } else if (rejectedWithNoFeedback.length > 0) {
        block = true;
        blockReviewCompletionMessage.value = `Before completing your review, you will need to provide at least one feedback for any rejected justification on overseas subcontractors (${rejectedWithNoFeedback.length} missing feedback)`;
      }

      return block;
    });

    return {
      isReviewer,
      blurbContentSlotId,
      blockReviewCompletionMessage,
      blockReviewCompletion,
      loading
    };
  }
};
</script>
