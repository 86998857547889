<template>
  <div class="tw-relative tw-cursor-pointer">
    <slot />
    <tooltip v-if="show || $slots.notification" :class="absoluteClasses" :content="text">
      <i v-if="icon" :class="['fa-xs', `fa-${icon}`, iconType, iconColour]" />
      <slot v-else-if="$slots.notification" name="notification" />
      <template v-else>
        <span v-if="ping" :class="`tw-animate-ping tw-absolute tw-inline-flex tw-h-3 tw-w-3 tw-rounded-full ${bgColour} tw-opacity-75`" />
        <span :class="`tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 ${bgColour}`" />
      </template>
    </tooltip>
  </div>
</template>

<script setup>
import { defineProps, computed, useSlots } from "vue";
import { css } from "../../shared/theme.js";

const props = defineProps({
  text: {
    type: String
  },
  show: {
    type: Boolean,
    required: false
  },
  icon: {
    type: String
  },
  iconType: {
    type: String,
    default: "fas"
  },
  ping: {
    type: Boolean
  },
  colour: {
    type: String,
    default: "error"
  }
});
const theme = css.notification;
const slots = useSlots();

const bgColour = computed(() => theme[props.colour]?.bg);
const iconColour = computed(() => theme[props.colour]?.icon);
const absoluteClasses = computed(() => {
  if (props.icon) return "tw-absolute tw--top-0.5 tw--right-1.5 tw-flex";
  if (slots.notification) return "tw-absolute tw--top-1.5 tw--right-2 tw-flex";

  return "tw-absolute tw-top-0 tw--right-1.5 tw-flex tw-h-3 tw-w-3";
});

</script>
