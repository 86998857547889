import { onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";

export default function useConfirmLeave() {
  const store = useStore();

  const isConfirmLeaveEnabled = computed(() => store.getters["feature_flags/GET_FEATURE_FLAG"]("leave-unsaved-changes-warning"));
  const hasUnsavedChanges = computed(() => store.getters["fields/HAS_UNSAVED_CHANGES"]);
  const isAdmin = computed(() => store.getters["users/GET_IS_ADMIN"]);

  const confirmLeave = event => {
    if (isConfirmLeaveEnabled.value && hasUnsavedChanges.value && !isAdmin.value) {
      event.preventDefault();
      event.returnValue = true;
    }
  };

  window.addEventListener("beforeunload", confirmLeave);

  onBeforeUnmount(() => {
    window.removeEventListener("beforeunload", confirmLeave);
  });
}
