<template>
  <transition name="bounce">
    <slot />
  </transition>
</template>

<script>
export default {};
</script>

<style scoped>
  .bounce-enter-active {
    animation: bounce-in 0.6s;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.07);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
