<template>
  <table class="tw-w-full">
    <thead class="tw-py-8">
      <th
        v-for="(column, index) in table_columns"
        :key="column.label"
        :class="[theme.tables.th_dark, 'tw-py-5 tw-px-20', index === 0 ? 'tw-rounded-tl-md' : '', column.class]"
      >
        {{ column.label }}
        <KnowledgeCentreTriggerIcon
          v-if="column.has_knowledge_center"
          :faq="column.faq_slug"
          section="Cost"
          icon
        />
      </th>
      <th :class="[theme.tables.th_dark, 'tw-w-1/12 tw-rounded-tr-md']" />
    </thead>

    <tbody v-if="!loading" class="tw-bg-white">
      <template v-for="cost in costs" :key="cost.slug">
        <tr class="tw-cursor-pointer cost-row" @click="toggle(cost.slug)">
          <td v-for="column in table_columns" :key="column.key" :class="[theme.tables.td, 'tw-px-20']">
            <div v-if="column.format == 'String'">
              {{ cost[column.key] }}
            </div>
            <div v-if="column.format == 'RadialProgressBar'" class="tw-w-full tw-inline-flex tw-items-center">
              <RadialProgressBar
                :section-status="costAllocationPercentages[cost.slug] == 100 ? 'Completed' : null"
                :section-percentage="costAllocationPercentages[cost.slug]"
                :size="35"
              />
              <span class="tw-text-sm tw-font-semibold tw-text-emp-blue tw-ml-3 tw-w-32">
                {{ costAllocationPercentages[cost.slug] }}% Allocated
              </span>
              <tooltip v-if="cost.errors && cost.errors.length > 0" :content="cost.errors[0]">
                <i class="fas fa-exclamation-triangle tw-text-warning-yellow" />
              </tooltip>
            </div>
          </td>
          <td class="tw-text-center">
            <i
              :class="!!costExpandableOpen[cost.slug] ? 'tw-transform tw-rotate-180' : ''"
              class="fas fa-chevron-down tw-flex-shrink-0 tw-text-sm tw-flex-none tw-px-0.5"
              aria-hidden="true"
            />
          </td>
        </tr>
        <tr>
          <td :colspan="table_columns.length + 1" class="tw-p-0">
            <ExpandClose ref="expandCloseRefs" :open="costExpandableOpen[cost.slug]">
              <CostProjectsExpandableTable :subcost="cost" :subcost-type="costType" />
            </ExpandClose>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { css } from "../../../shared/theme.js";

import ExpandClose from "../transitions/expand_close.vue";
import costLinkingTabs from "./cost_linking_tabs.js";
import RadialProgressBar from "../claim_progression/radial_progress_bar.vue";
import CostProjectsExpandableTable from "./cost_projects_expandable_table.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";

export default {
  name: "CostLinkingTable",
  components: {
    ExpandClose,
    RadialProgressBar,
    CostProjectsExpandableTable,
    KnowledgeCentreTriggerIcon
  },
  props: {
    costType: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const store = useStore();

    const { costType } = props;
    const table_columns = costLinkingTabs[costType].table;

    const costs = computed(() => store.getters["project_cost_breakdown/GET_SUBCOSTS"](costType));

    const costAllocationPercentages = computed(() => {
      const allCostAllocationPercentages = {};

      costs.value.forEach(cost => {
        allCostAllocationPercentages[cost.slug] = cost.rd_projects.reduce(
          (total, project) => total + project.percent_allocation,
          0
        );
      });
      return allCostAllocationPercentages;
    });

    const loading = computed(() => store.getters["project_cost_breakdown/GET_LOADING"]);

    const costExpandableOpen = ref({});

    function toggle(slug) {
      costExpandableOpen.value[slug] = !costExpandableOpen.value[slug];
    }

    return {
      theme: css,
      loading,
      costs,
      toggle,
      costExpandableOpen,
      table_columns,
      costType,
      costAllocationPercentages
    };
  }
};
</script>

<!-- We should refactor these styles when we get the @apply tailwind syntax working here -->
<style scoped>
:nth-child(odd of .cost-row) {
  background-color: #fff;
}

:nth-child(even of .cost-row) {
  background-color: #f9fafb;
}

.cost-row:hover {
  background-color: #f3f4f6;
}
</style>
