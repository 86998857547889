<template>
  <div class="tw-relative">
    <dropdown>
      <template #link>
        <div class="submenu-trigger tw-cursor-pointer">
          <i class="fas fa-ellipsis-v-alt tw-mx-2" />
        </div>
      </template>

      <template #items>
        <div v-cloak :class="css.dropDown.menuDiv">
          <tooltip :content="clickToCopyText">
            <span :class="css.dropDown.itemDiv" @click="copyReviewSlug(review.slug)">
              Review Slug {{ review.slug }}
            </span>
          </tooltip>
          <span :class="css.dropDown.itemDiv">
            <a :href="`/claim_periods/${review.claim_period.slug}/edit`" target="_blank">
              <i class="fas fa-receipt tw-text-center tw-mx-2" />
              Edit Claim Period
            </a>
          </span>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import { css } from "../../../../shared/theme.js";
import { copyToClipboard } from "../../../../shared/helpers.js";

export default {
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      css,
      copyToClipboard,
      clickToCopyText: "Click to copy"
    };
  },
  methods: {
    copyReviewSlug(slug) {
      copyToClipboard(slug);
      this.clickToCopyText = "Copied...";
    }
  }
};
</script>
