<template>
  <NotificationComponent
    v-if="isEmpowerrd || (field && ['LargeText', 'SmallText'].includes(field.type))"
    :id="`edit-history-trigger-${field ? field.label : `${sectionType}:${sectionSlug}`}`"
    class="tw-inline-block"
    :ping="true"
    colour="cta"
    :show="isEmpowerrd && auditsSinceReview && auditsSinceReview.length > 0"
    :text="`There have been changes made to this ${isCosts ? 'cost' : 'question'} since the last completed review`"
    @click="isCosts ? trigger() : showAuditHistory()"
  >
    <tooltip :content="`Show ${isEmpowerrd ? 'Audit' : 'Edit'} History`">
      <i class="far fa-history tw-text-gray-600" />
    </tooltip>
  </NotificationComponent>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useEventsBus from "../../../shared/eventBus.js";
import NotificationComponent from "../../generic/notification_component.vue";

export default {
  components: {
    NotificationComponent
  },
  props: {
    field: {
      type: Object,
      required: false
    },
    sectionSlug: {
      type: String,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { emit } = useEventsBus();
    const store = useStore();
    const auditHistory = computed(() => store.getters["audit_history/GET_AUDIT_HISTORY"]);
    const isCosts = computed(() => props.sectionType.includes("Cost"));
    const userType = computed(() => store.getters["states/GET_USER_TYPE"]);
    const submitted = computed(() => store.getters["states/GET_SUBMITTED"]);
    const isEmpowerrd = computed(() => userType.value == "reviewer" || userType.value == "admin");

    function trigger() {
      window.dispatchEvent(new CustomEvent("triggerquestionaudit", { detail: { model: props.sectionType, slug: props.sectionSlug } }));
    }

    function showAuditHistory() {
      if (!auditHistory.value) {
        store.dispatch("audit_history/FETCH_AUDIT_HISTORY", {
          sectionSlug: props.sectionSlug,
          sectionType: props.sectionType
        });
      }
      emit("hideDetails");
      emit("addFieldAudits", {
        label: props.field.label,
        title: props.field.title
      });
    }

    const lastReviewCompleted = computed(() => store.getters["reviews/GET_LAST_REVIEW_COMPLETED_FOR_SECTION"]);
    const auditsSinceReview = computed(() => store.getters["audit_history/GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP"](props.sectionType, props.sectionSlug, lastReviewCompleted.value?.completed_at, props.field?.label));

    return {
      showAuditHistory,
      isCosts,
      trigger,
      isEmpowerrd,
      auditsSinceReview,
      submitted
    };
  }
};
</script>
