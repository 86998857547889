<template>
  <Tippy :content="tooltip">
    <div class="tw-cursor-pointer" :class="[color, 'tw-inline-flex tw-items-center tw-gap-x-2']">
      <span class="tw-cursor-pointer tw-font-semibold">{{ text }}</span>
      <i :class="[icon, 'fas']" />
    </div>
  </Tippy>
</template>

<script>
import { Tippy } from "vue-tippy";

export default {
  components: {
    Tippy
  },
  props: {
    sectionType: {
      type: String,
      required: false
    },
    assigned: {
      type: Boolean,
      required: true
    },
    assignedToUser: {
      type: Boolean,
      required: false
    },
    assignedUsers: {
      type: Object,
      required: false
    }
  },
  computed: {
    color() {
      if (this.assignedToUser) {
        return "tw-text-green-600 hover:tw-text-green-700 tw-font-semibold";
      } else if (this.assigned) {
        return "tw-text-indigo-500 hover:tw-text-indigo-700";
      } else {
        return "tw-text-gray-500 hover:tw-text-gray-900";
      }
    },
    icon() {
      if (this.assigned) {
        return "fa-user tw-pr-1";
      } else {
        return "fa-user-plus";
      }
    },
    tooltip() {
      if (this.assignedToUser) {
        return "This step has been assigned to you, click to re-assign or assign others";
      } else if (this.assigned) {
        return "Click to re-assign or assign others to this step";
      } else {
        return "Click here to assign this step to someone in your company";
      }
    },
    user() {
      return this.assignedUsers[0];
    },
    text() {
      if (this.assignedToUser && this.assignedUsers.length > 1) {
        return "Step assigned to you & others";
      } else if (this.assignedToUser) {
        return "Step assigned to you";
      } else if (this.assignedUsers.length > 1) {
        return `Step assigned to ${this.assignedUsers.length} others`;
      } else if (this.assigned && this.assignedUsers.length === 1 && this.user) {
        return `Step assigned to ${this.user.first_name} ${this.user.last_name[0]}`;
      } else {
        return "Assign this Step";
      }
    }
  }
};
</script>
