import { get, post, del, patch } from "../../shared/http.js";
import { stepSortOptions } from "../../vue-components/components/project_selection/project_selection_steps.js";

export const FETCH_DATA = "FETCH_DATA";
export const REFRESH_RD_PROJECT = "REFRESH_RD_PROJECT";
export const FETCH_PROJECTS_TO_DETAIL_INFO = "FETCH_PROJECTS_TO_DETAIL_INFO";
export const DELETE_RD_PROJECT = "DELETE_RD_PROJECT";
export const CREATE_RD_PROJECT = "CREATE_RD_PROJECT";
export const SET_GLOBAL_VALIDATION_MESSAGE = "SET_GLOBAL_VALIDATION_MESSAGE";
export const SET_STEP = "SET_STEP";
export const PROGRESS = "PROGRESS";
export const UNLOCK = "UNLOCK";

export const GET_PROJECT_SELECTION = "GET_PROJECT_SELECTION";
export const GET_RD_PROJECTS = "GET_RD_PROJECTS";
export const GET_RD_PROJECT_BY_SLUG = "GET_RD_PROJECT_BY_SLUG";
export const GET_PROJECTS_TO_DETAIL = "GET_PROJECTS_TO_DETAIL";
export const GET_PROPOSED_PROJECTS_TO_DETAIL = "GET_PROPOSED_PROJECTS_TO_DETAIL";
export const GET_NUMBER_OF_PROPOSED_PROJECTS_REQUIRED = "GET_NUMBER_OF_PROPOSED_PROJECTS_REQUIRED";
export const GET_NUMBER_OF_PROPOSED_PROJECTS_LEFT_TO_SELECT = "GET_NUMBER_OF_PROPOSED_PROJECTS_LEFT_TO_SELECT";
export const GET_NEEDS_INTENSITY_RATING = "GET_NEEDS_INTENSITY_RATING";
export const GET_GLOBAL_VALIDATION_MESSAGE = "GET_GLOBAL_VALIDATION_MESSAGE";
export const GET_LOADING = "GET_LOADING";
export const GET_EXPENDITURES = "GET_EXPENDITURES";
export const GET_TOTAL_NUMBER_TO_DETAIL = "GET_TOTAL_NUMBER_TO_DETAIL";
export const GET_RD_PROJECT_REQUIRED = "GET_RD_PROJECT_REQUIRED";
export const GET_RD_PROJECT_PROPOSED = "GET_RD_PROJECT_PROPOSED";
export const GET_RD_PROJECT_INVALID = "GET_RD_PROJECT_INVALID";
export const GET_RD_PROJECT_NOT_REQUIRED = "GET_RD_PROJECT_NOT_REQUIRED";
export const GET_CLAIM_PROJECTS = "GET_CLAIM_PROJECTS";
export const GET_EXPENDITURES_TOTAL = "GET_EXPENDITURES_TOTAL";
export const GET_CURRENT_STATE = "GET_CURRENT_STATE";

export const project_selection = {
  namespaced: true,
  state: {
    step: null,
    project_selection: {},
    rd_projects: [],
    expenditures: {},
    required_projects_to_detail: [],
    proposed_projects_to_detail: [],
    invalid_projects: [],
    number_of_proposed_projects_required: 0,
    number_of_proposed_projects_left_to_select: 0,
    needs_intensity_rating: false,
    globalValidationMessage: null,
    loading: false
  },
  mutations: {
    SET_STEP: (state, payload) => (state.step = payload),
    SET_PROJECT_SELECTION: (state, payload) => (state.project_selection = payload),
    SET_PROJECT_RD_PROJECTS: (state, payload) => (state.rd_projects = payload),
    SET_RD_PROJECT: (state, payload) => {
      const index = state.rd_projects.findIndex(project => project.slug === payload.slug);
      if (index > -1) {
        state.rd_projects[index] = payload;
      } else {
        state.rd_projects.push(payload);
      }
    },
    SET_PROJECTS_TO_DETAIL: (state, payload) => (state.required_projects_to_detail = payload),
    SET_PROPOSED_PROJECTS_TO_DETAIL: (state, payload) => (state.proposed_projects_to_detail = payload),
    SET_INVALID_PROJECTS: (state, payload) => (state.invalid_projects = payload),
    SET_NUMBER_OF_PROPOSED_PROJECTS_REQUIRED: (state, payload) => (state.number_of_proposed_projects_required = payload),
    SET_NUMBER_OF_PROPOSED_PROJECTS_LEFT_TO_SELECT: (state, payload) => (state.number_of_proposed_projects_left_to_select = payload),
    SET_NEEDS_INTENSITY_RATING: (state, payload) => (state.needs_intensity_rating = payload),
    [SET_GLOBAL_VALIDATION_MESSAGE]: (state, payload) => (state.globalValidationMessage = payload),
    SET_LOADING: (state, payload) => (state.loading = payload),
    SET_EXPENDITURE: (state, payload) => state.expenditures[payload.id] = payload.value,
    SET_EXPENDITURES: (state, payload) => (state.expenditures = payload)
  },
  actions: {
    [FETCH_DATA]: async ({ commit, state }, payload) => {
      const response = await get(`/api/project_selections/${payload.slug}/fetch_data`);
      const stateProperty = payload.stateProperty || "project_selection";

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      if (stateProperty === "project_selection") {
        commit("SET_PROJECT_SELECTION", response);
      }
      const rdProjects = _.cloneDeep(response.rd_projects);
      const rdProjectsSorted = _.orderBy(
        rdProjects,
        stepSortOptions[state.step]?.sort,
        stepSortOptions[state.step]?.order || "asc"
      );

      commit("SET_PROJECT_RD_PROJECTS", rdProjectsSorted);
      commit(
        "SET_EXPENDITURES",
        rdProjects.reduce((result, rd) => {
          result[rd.slug] = rd.percent_expenditure;
          return result;
        }, {})
      );

      return response;
    },
    [REFRESH_RD_PROJECT]: async ({ dispatch, commit }, payload) => {
      const response = await get(`/api/rd_projects/${payload.slug}`);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      commit("SET_RD_PROJECT", response?.model);
      commit("SET_EXPENDITURE", { slug: response.model?.slug, percent: response.model?.percent_expenditure });
      return response?.model;
    },
    [FETCH_PROJECTS_TO_DETAIL_INFO]: async ({ commit }, payload) => {
      const response = await get(`/api/project_selections/${payload.slug}/fetch_projects_to_detail_info`);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      commit("SET_PROJECTS_TO_DETAIL", response.required_projects_to_detail);
      commit("SET_PROPOSED_PROJECTS_TO_DETAIL", response.proposed_projects_to_detail);
      commit("SET_INVALID_PROJECTS", response.invalid_projects);
      commit("SET_NUMBER_OF_PROPOSED_PROJECTS_REQUIRED", response.number_of_proposed_projects_required);
      commit("SET_NUMBER_OF_PROPOSED_PROJECTS_LEFT_TO_SELECT", response.number_of_proposed_projects_left_to_select);
      commit("SET_NEEDS_INTENSITY_RATING", response.needs_intensity_rating);

      return response;
    },
    [CREATE_RD_PROJECT]: async ({ dispatch, commit }, payload) => {
      const response = await post("/api/rd_projects", payload);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      dispatch(FETCH_DATA, { slug: payload.project_selection_slug });
      commit(SET_GLOBAL_VALIDATION_MESSAGE, "R&D Project created successfully.");

      return response;
    },
    [DELETE_RD_PROJECT]: async ({ dispatch, commit, getters }, payload) => {
      const title = getters.GET_RD_PROJECT_BY_SLUG(payload.slug)?.title;

      const response = await del(`/api/rd_projects/${payload.slug}`);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      dispatch(FETCH_DATA, { slug: response?.project_selection_slug });
      commit(SET_GLOBAL_VALIDATION_MESSAGE, `${title || "R&D Project"} deleted successfully.`);

      return response;
    },
    [PROGRESS]: async ({ commit }, payload) => {
      const response = await post(`/api/project_selections/${payload.slug}/progress`);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      return response;
    },
    [UNLOCK]: async ({ commit }, payload) => {
      const response = await patch(`/api/project_selections/${payload.slug}/unlock`);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      return response;
    }
  },
  getters: {
    [GET_PROJECT_SELECTION]: state => state.project_selection,
    [GET_CURRENT_STATE]: state => state.project_selection?.current_state,
    [GET_RD_PROJECTS]: state => state.rd_projects,
    [GET_RD_PROJECT_BY_SLUG]: state => slug => state.rd_projects.find(project => project.slug === slug),
    [GET_EXPENDITURES]: state => state.expenditures,
    [GET_PROJECTS_TO_DETAIL]: state => state.required_projects_to_detail,
    [GET_PROPOSED_PROJECTS_TO_DETAIL]: state => state.proposed_projects_to_detail,
    [GET_TOTAL_NUMBER_TO_DETAIL]: state => (state.required_projects_to_detail?.length ?? 0) + (state.number_of_proposed_projects_required ?? 0),
    [GET_NUMBER_OF_PROPOSED_PROJECTS_REQUIRED]: state => state.number_of_proposed_projects_required,
    [GET_NUMBER_OF_PROPOSED_PROJECTS_LEFT_TO_SELECT]: state => state.number_of_proposed_projects_left_to_select,
    [GET_RD_PROJECT_REQUIRED]: state => slug => state.required_projects_to_detail.some(project => project.slug === slug),
    [GET_RD_PROJECT_PROPOSED]: state => slug => state.proposed_projects_to_detail.some(project => project.slug === slug),
    [GET_RD_PROJECT_INVALID]: state => slug => state.invalid_projects.some(project => project.slug === slug),
    [GET_RD_PROJECT_NOT_REQUIRED]: (state, getters) => slug => {
      if (
        getters.GET_RD_PROJECT_REQUIRED(slug)
        || getters.GET_RD_PROJECT_PROPOSED(slug)
        || getters.GET_RD_PROJECT_INVALID(slug)
      ) {
        return false;
      } else {
        return true;
      }
    },
    [GET_NEEDS_INTENSITY_RATING]: state => state.needs_intensity_rating,
    [GET_GLOBAL_VALIDATION_MESSAGE]: state => state.globalValidationMessage,
    [GET_EXPENDITURES_TOTAL]: state => Object.values(state.expenditures).reduce((total, percent) => total + percent, 0),
    [GET_CLAIM_PROJECTS]: state => state.project_selection?.claim_period?.projects,
    [GET_LOADING]: state => state.loading
  }
};
