<template>
  <span class="tw-flex tw-items-center tw-gap-3">
    <tooltip :content="tooltip">
      <span class="tw-flex tw-items-center">
        <input
          v-model="val"
          type="checkbox"
          :class="[selectable ? 'tw-cursor-pointer tw-border-gray-700' : 'tw-cursor-not-allowed', themeClass]"
          :checked="val === true || val === 'true'"
          :disabled="!selectable"
        >
      </span>
    </tooltip>
    <state-wrapper component="ValidationMessage">
      <tooltip
        v-if="existingProject && !required"
        content="You already started detailing this project prior to the Project Selection process, so we've pre-selected it to detail as part of your claim but you can un-select it if it's not required, but you will lose all your previous narrative"
      >
        <i class="fas fa-exclamation-triangle tw-text-warning-yellow" />
      </tooltip>
      <ValidationMessage section-type="RdProject" :section-slug="slug" :field="{ label: 'selected_to_detail' }" />
    </state-wrapper>
  </span>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
import { css } from "../../../../shared/theme.js";
import ValidationMessage from "../../dynamic_forms/validation_message.vue";

export default {
  components: {
    ValidationMessage
  },
  props: {
    slug: {
      required: true,
      type: String
    },
    projectSelectionSlug: {
      required: true,
      type: String
    },
    editable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const theme = css;
    const val = ref(null);
    const rdProject = computed(() => store.getters["project_selection/GET_RD_PROJECT_BY_SLUG"](props.slug));
    const debouncedSave = _.debounce(save, 1000);

    const proposed = computed(() => store.getters["project_selection/GET_RD_PROJECT_PROPOSED"](props.slug));
    const required = computed(() => store.getters["project_selection/GET_RD_PROJECT_REQUIRED"](props.slug));
    const invalid = computed(() => store.getters["project_selection/GET_RD_PROJECT_INVALID"](props.slug));
    const not_required = computed(() => store.getters["project_selection/GET_RD_PROJECT_NOT_REQUIRED"](props.slug));
    const existingProject = computed(() => !!rdProject.value?.project);

    const selectable = computed(
      () => props.editable && (proposed.value || (not_required.value && existingProject.value))
    );

    const themeClass = computed(() => (selectable.value ? theme.forms.checkbox : theme.forms.checkboxDisabled));

    function save() {
      store.dispatch("validations/VALIDATE", ["RdProject", props.slug, { selected_to_detail: val.value }]).then(() => {
        store.dispatch("project_selection/REFRESH_RD_PROJECT", { slug: props.slug });
        store.dispatch("validations/VALIDATE", ["ProjectSelection", props.projectSelectionSlug]);
      });
    }

    const tooltip = computed(() => {
      if (!props.editable && val.value) return "This R&D Project has been selected to write-up in more detail for your claim you can find it in the Projects section";
      if (selectable.value) return "Click to select this R&D project to write-up in more detail for your claim";
      if (required.value) return "Based on your answers in previous steps, this R&D Project will require a detailed write up as part of your claim";
      if (invalid.value) return "This project is not eligible for write-up";

      return "This project is part of your R&D claim but not required to be written up";
    });

    onMounted(() => {
      val.value = rdProject.value?.selected_to_detail ?? false;
    });

    watch(
      () => val.value,
      (newVal, oldVal) => {
        if (newVal !== oldVal) debouncedSave();
      }
    );

    return {
      theme,
      val,
      rdProject,
      selectable,
      themeClass,
      tooltip,
      required,
      existingProject
    };
  }
};
</script>
