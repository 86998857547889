<template>
  <div class="tw-relative tw-text-center lg:tw-text-right">
    <Dropdown>
      <template #link>
        <component
          :is="assignIconType"
          :section-type="sectionType"
          :subsection-type="isSubsection"
          :assigned="assignedToAnyoneInCompany"
          :assigned-to-user="isAssignedToUser(companyAssignments, userSlug)"
          :assigned-users="assignedUsers"
          :something-assigned="somethingAssignedToUser(companyAssignments, userSlug)"
        />
      </template>

      <template #items>
        <div
          v-cloak
          v-click-away="away"
          class="tw-origin-top-right tw-z-50 tw-right-0 tw-absolute tw-mt-2 tw-w-56 tw-max-h-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-overflow-y-scroll"
        >
          <input
            id="query"
            v-model="query"
            type="text"
            class="tw-w-full tw-px-4 tw-py-2 tw-text-xs tw-border-0 focus:tw-ring-0"
            placeholder="Type to find a user..."
            autocomplete="off"
          >
          <template
            v-for="user in sortedUsers.filter(
              (user) => (user.first_name.toUpperCase() + ' ' + user.last_name.toUpperCase()).indexOf(query.toUpperCase()) > -1
            )"
            :key="user.id"
          >
            <div
              class="tw-flex tw-justify-between tw-cursor-pointer tw-group tw-items-center tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
              @click="assignTo(user)"
            >
              <div>
                <span> {{ user.first_name }} {{ user.last_name }} </span>
              </div>
              <div v-show="isAssignedToUser(companyAssignments, user.slug)">
                <i class="fas fa-check fa-1x tw-text-green-600" />
              </div>
            </div>
          </template>

          <ButtonComponent
            v-if="!isEmpowerred"
            class="tw-m-2"
            colour="cta"
            text="Invite Someone New"
            full-width
            @click="isModalOpen = true"
          />
        </div>
      </template>
    </Dropdown>

    <InviteSomeoneNewModal
      v-if="isModalOpen"
      :assign-type="assignIconType"
      @should-show-modal="payload => isModalOpen = payload"
      @update-users="payload => assignUserAndRefresh(payload)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InviteSomeoneNewModal from "@components/dynamic_forms/invite_someone_new_modal.vue";
import ButtonComponent from "@generic/button_component.vue";
import Dropdown from "@generic/dropdown";
import { FETCH_USERS,
  GET_LOADING,
  GET_USERS,
  GET_COMPANY_ASSIGNMENTS,
  FETCH_ASSIGNMENTS,
  ASSIGN_USER } from "../../../vuex-store/modules/assignments.js";
import { GET_USER_SLUG, GET_IS_EMPOWERRD } from "../../../vuex-store/modules/users.js";
import SectionAssign from "./assign_icon_types/section_assign.vue";
import FieldAssign from "./assign_icon_types/field_assign.vue";
import FieldGroupAssign from "./assign_icon_types/field_group_assign.vue";
import StepAssign from "./assign_icon_types/step_assign.vue";

export default {
  name: "AssignIcon",
  components: {
    Dropdown,
    SectionAssign,
    FieldAssign,
    FieldGroupAssign,
    StepAssign,
    InviteSomeoneNewModal,
    ButtonComponent
  },
  props: {
    fields: {
      type: Array,
      default: () => [null] // backend doesn't handle assignment without a column ref, so this null is necessary
    },
    sectionType: {
      type: String,
      required: true,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument",
          "ProjectSelection",
          "ProjectCostBreakdown",
          "ProjectGrantAllocationSection",
          "Cost"
        ];
        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    isSubsection: {
      type: String
    },
    assignIconType: {
      type: String,
      required: true,
      validator: str => {
        const valid_assign_icon_types = [
          "SectionAssign",
          "FieldAssign",
          "FieldGroupAssign",
          "StepAssign"
        ];

        return valid_assign_icon_types.indexOf(str) >= 0;
      }
    }
  },
  data() {
    return {
      query: "",
      isModalOpen: false
    };
  },
  computed: {
    ...mapGetters({
      assignmentsLoading: `assignments/${GET_LOADING}`,
      allUsers: `assignments/${GET_USERS}`,
      companyAssignments: `assignments/${GET_COMPANY_ASSIGNMENTS}`,
      userSlug: `users/${GET_USER_SLUG}`,
      isEmpowerred: `users/${GET_IS_EMPOWERRD}`
    }),
    assignedToAnyoneInCompany() {
      const assignedFields = this.fieldLabels(this.fields)
        .map(label => {
          const key = `${this.sectionType}:${this.sectionSlug}`;
          return _.get(this.companyAssignments, label ? `${key}:${label}` : key);
        })
        .reject(a => (a ?? []).length < 1)
        .value();

      if (assignedFields.length === 0) {
        return false;
      }

      return this.fieldLabels(this.fields).value().length === assignedFields.length;
    },
    sortedUsers() {
      if (this.allUsers !== null) {
        const assignedUsers = this.allUsers.filter(user => this.isAssignedToUser(this.companyAssignments, user.slug));
        const unassignedUsers = this.allUsers.filter(
          user => !this.isAssignedToUser(this.companyAssignments, user.slug)
        );
        return assignedUsers.concat(unassignedUsers);
      }
      return [];
    },
    assignedUsers() {
      if (this.allUsers !== null) {
        return this.allUsers.filter(user => this.isAssignedToUser(this.companyAssignments, user.slug));
      }
      return [];
    }
  },
  mounted() {
    if (!this.assignmentsLoading && !this.companyAssignments) {
      this.fetchAssignments([`${this.sectionType}:${this.sectionSlug}`]);
    }

    if (this.allUsers === null || this.allUsers.length === 0) {
      this.fetchUsers({ sectionSlug: this.sectionSlug, sectionType: this.sectionType });
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: `assignments/${FETCH_USERS}`,
      assignUser: `assignments/${ASSIGN_USER}`,
      fetchAssignments: `assignments/${FETCH_ASSIGNMENTS}`
    }),
    assignUserAndRefresh(payload) {
      const { user, shouldAssign } = payload;

      this.fetchUsers({ sectionSlug: this.sectionSlug, sectionType: this.sectionType });

      if (shouldAssign) this.assignTo(user);
    },
    assignTo(user) {
      const assign = !this.isAssignedToUser(this.companyAssignments, user.slug);

      const fieldLabels = this.fieldLabels(this.fields).value();

      this.assignUser({
        section_type: this.sectionType,
        section_slug: this.sectionSlug,
        fields: fieldLabels,
        asignee_slug: user.slug,
        assign
      }).then(data => {
        if (data === "success") {
          this.fetchAssignments([
            `${this.sectionType}:${this.sectionSlug}`
          ]);
        }
      });

      const eventTracked = assign ? "Section Assigned" : "Section Unassigned";
      this.track(eventTracked, {
        sectionType: this.sectionType,
        sectionSlug: this.sectionSlug,
        button: this.assignIconType,
        fields: _.map(this.fields, "label")
      });
    },
    isAssignedToUser(companyAssignments, userSlug) {
      const fieldsLabel = this.fieldLabels(this.fields).value();
      const usersAssignments = this.usersAssignments(
        companyAssignments,
        userSlug,
        this.fields
      );

      if (usersAssignments.length === 0) {
        return false;
      }

      return usersAssignments.length === fieldsLabel.length;
    },
    somethingAssignedToUser(companyAssignments, userSlug) {
      const usersAssignments = this.usersAssignments(
        companyAssignments,
        userSlug,
        this.fields
      );

      return (
        usersAssignments.length
          < this.fieldLabels(this.fields).value().length
        && usersAssignments.length > 0
      );
    },
    usersAssignments(companyAssignments, userSlug, fields) {
      return this.fieldLabels(fields)
        .map(label => {
          const key = `${this.sectionType}:${this.sectionSlug}${label ? `:${label}` : ""}`;
          return { key, assignments: _.get(companyAssignments, key) };
        })
        .compact()
        .flatten()
        .reject(a => (a?.assignments ?? []).indexOf(userSlug) < 0)
        .value();
    },
    fieldLabels(fields) {
      const doesNotRequireMapping = ["ProjectSelection", "ProjectCostBreakdown", "ProjectGrantAllocationSection"];

      if (doesNotRequireMapping.includes(this.sectionType)) {
        return _.chain(fields);
      }

      return _.chain(fields)
        .map(field => {
          if (field === null) {
            return null;
          } if (field.type === "Paired") {
            return _.map(field.subfields, f => f?.label);
          }
          return field?.label;
        })
        .compact()
        .flatten();
    },
    away() {
      this.query = "";
    }
  }
};
</script>
