<template>
  <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
    <div class="tw-flex tw-items-center tw-flex-1 tw-gap-2 tw-py-1">
      <template v-if="input_type == 'checkbox'">
        <i :class="[iconClasses, 'tw-pt-1']" />
        <Tooltip :tooltip-options="{ maxWidth: 750 }">
          <div class="tw-text-sm tw-leading-6 tw-font-light">
            {{ label }}
          </div>
          <template v-if="input_label && (input_label !== label)" #content>
            <div class="tw-p-2.5">
              <div class="tw-text-sm">
                <span class="tw-font-semibold">Full Check:</span> {{ input_label }}
              </div>
            </div>
          </template>
        </Tooltip>
      </template>
      <SectionFeedback
        v-for="feedback in feedbacks"
        :key="feedback.id"
        section-type="ReviewTask"
        :section-slug="slug"
        :feedback="feedback"
        :user-role="userRole"
        :redirect-url="submissionUrl"
      />
    </div>
    <div class="tw-flex tw-items-center tw-gap-2">
      <div v-if="type_ordinal_in_process > 1">
        <Tooltip :content="`This is the ${ordinalize(type_ordinal_in_process)} instance of this check for this review process`">
          <i class="fas fa-history tw-text-disabled-grey tw-text-base" />
        </Tooltip>
      </div>
      <div v-if="dependent_properties.length > 0">
        <Tooltip :tooltip-options="{ maxWidth: 750 }">
          <i class="fas fa-sitemap tw-text-disabled-grey tw-text-base" />
          <template #content>
            <div class="tw-flex tw-flex-col tw-p-2 tw-gap-y-1 tw-w-full">
              <div class="tw-font-semibold">
                This check depends on the following properties:
              </div>
              <div class="tw-pl-2">
                <DependentPropertySummary
                  v-for="(dependentProperty, index) in dependent_properties"
                  :key="index"
                  v-bind="dependentProperty"
                />
              </div>
            </div>
          </template>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import SectionFeedback from "../sections/section_feedback.vue";
import DependentPropertySummary from "./dependent_property_summary.vue";
import Tooltip from "../../generic/tooltip.vue";
import { ordinalize } from "../../../shared/helpers.js";

const props = defineProps({
  submission: { type: Object },
  userRole: { type: String },
  label: { type: String },
  input_label: { type: String },
  input_type: { type: String },
  feedbacks: { type: Array },
  status: { type: String },
  dependent_properties: { type: Array },
  slug: { type: String },
  type_ordinal_in_process: { type: Number }
});

const iconClasses = computed(() => statusIconClasses[props.status]);
const submissionUrl = computed(() => `/submission/${props.submission?.slug}`);

const statusIconClasses = {
  incomplete: "far fa-circle tw-text-disable-grey",
  with_feedback: "fas fa-exclamation-circle tw-text-warning-yellow",
  invalid: "fas fa-times-circle tw-text-error-red",
  approved: "fas fa-check-circle tw-text-confirm-green"
};

</script>
