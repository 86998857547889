<template>
  <div
    v-if="icon || text || $slots.default"
    :id="id"
    class="tw-flex-none"
    @click.once="!disableClick && trackClick()"
    @click="click"
  >
    <Tooltip
      :id="id"
      :content="tooltip || null"
      :class="fullWidth ? 'tw-w-full' : 'tw-inline-flex'"
      :tooltip-options="tooltipOptions"
      :options="options"
      :track="track"
    >
      <a
        :href="!disableClick && !formWrapped() ? (link || null) : null"
        :target="newTab ? '_blank' : null"
        :class="[
          'tw-no-underline focus:tw-outline-none',
          fullWidth ? 'tw-w-full' : 'tw-inline-flex',
          disableClick ? 'tw-cursor-default' : ''
        ]"
      >
        <FormWrapper :action="link" :method="method" :data="data">
          <slot>
            <button
              :type="submit || formWrapped() ? 'submit' : 'button'"
              :aria-label="text"
              class="tw-flex tw-items-center tw-relative tw-justify-center tw-font-medium tw-rounded-md focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-75"
              :class="[
                buttonClasses,
                sizeClasses,
                colourClasses,
                iconRight ? 'tw-flex-row-reverse' : '',
                uppercase ? 'tw-uppercase tw-tracking-tighter' : '',
                fullWidth ? 'tw-w-full' : '',
                disableClick ? 'tw-cursor-default tw-opacity-50' : ''
              ]"
            >
              <i v-if="pending" class="tw-animate-spin far fa-spinner-third" />
              <span v-if="ping" :class="[colourClasses, text ? 'tw-animate-button-ping' : 'tw-animate-ping']" class="tw-inline-flex tw-h-full tw-w-full tw-absolute tw-rounded-md" />
              <i
                v-if="icon"
                style="display: inline-flex;"
                class="tw-items-center tw-relative tw-justify-center"
                :class="iconClasses"
              />
              <span v-if="text" class="tw-flex-none tw-relative" :class="hideSmall ? 'tw-hidden lg:tw-block' : ''">
                {{ text }}
              </span>
            </button>
          </slot>
        </FormWrapper>
      </a>
      <template v-if="$slots.tooltipContent" #content>
        <slot name="tooltipContent" />
      </template>
    </Tooltip>
  </div>
</template>

<script>
import { computed, reactive, inject } from "vue";
import { css } from "../../shared/theme.js";
import Tooltip from "./tooltip.vue";
import FormWrapper from "./button_component/form_wrapper.vue";

export default {
  components: { Tooltip, FormWrapper },
  props: {
    id: {
      type: String
    },
    size: {
      type: String,
      default: "medium"
    },
    text: {
      type: String
    },
    icon: {
      type: String
    },
    iconType: {
      type: String,
      default: "far",
      validator: value => {
        const validTypes = ["far", "fas", "fal"];
        return validTypes.indexOf(value) >= 0;
      }
    },
    colour: {
      type: String,
      default: "cta",
      validator: value => {
        const validTypes = [
          "cta", // Light Blue
          "warning", // Yellow
          "error", // Red
          "disabled", // Gray
          "confirm", // Green
          "emp" // Navy Blue
        ];
        return validTypes.indexOf(value) >= 0;
      }
    },
    type: {
      type: String,
      default: "primary",
      validator: value => {
        const validTypes = [
          "primary",
          "light",
          "transparent"
        ];
        return validTypes.indexOf(value) >= 0;
      }
    },
    link: {
      type: String
    },
    newTab: {
      type: Boolean
    },
    submit: {
      type: Boolean
    },
    iconRight: {
      type: Boolean
    },
    uppercase: {
      type: Boolean
    },
    fullWidth: {
      type: Boolean
    },
    buttonClasses: {
      type: String
    },
    hideSmall: {
      type: Boolean
    },
    ping: {
      type: Boolean
    },
    tooltip: {
      type: String
    },
    tooltipOptions: {
      type: Object
    },
    disableClick: {
      type: Boolean
    },
    options: {
      type: Object
    },
    track: {
      type: Object
    },
    noTracking: {
      type: Boolean
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    pending: {
      type: Boolean
    },
    stopPropagation: {
      type: Boolean,
      default: false
    },
    method: {
      type: String,
      default: "get"
    },
    confirmMessage: {
      type: String
    },
    data: {
      type: Object
    }
  },
  setup(props) {
    const track = inject("track");
    const theme = reactive(css.buttons);

    function click(event) {
      if (props.stopPropagation) {
        event.stopPropagation();
      }

      if (props.disableClick) {
        event.preventDefault();
      } else if (props.confirmMessage) {
        confirmClick(event);
      } else {
        props.onClick(event);
      }
    }

    function confirmClick(event) {
      if (confirm(props.confirmMessage)) {
        props.onClick(event);
      } else {
        event.preventDefault();
      }
    }

    function formWrapped() {
      return props.method !== "get";
    }

    const sizeClasses = computed(() => theme.size_classes[props.text ? "text" : "icon_only"][props.size.toLowerCase()]);

    const iconClasses = computed(() => {
      if (props.icon) {
        return [
          props.iconType,
          `fa-${props.icon}`,
          theme.size_classes.icon[props.size.toLowerCase()],
          (props.text && !props.hideSmall) ? theme.size_classes[props.iconRight ? "icon_right" : "icon_left"][props.size.toLowerCase()] : ""
        ];
      }
    });

    const colourClasses = computed(() => theme.colours[props.colour][props.type]);

    function trackClick() {
      if (!props.noTracking) {
        const params = _.merge(
          {
            button_id: props.id,
            button_text: props.text,
            button_link: props.link
          },
          props.options,
          props.track
        );
        track("Button Clicked", params);
      }
    }

    return {
      theme,
      sizeClasses,
      iconClasses,
      colourClasses,
      trackClick,
      click,
      formWrapped
    };
  }
};
</script>
