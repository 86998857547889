<template>
  <StepNavigationComponent
    v-if="projectSelection && !loading"
    :steps="steps"
    :active-step="activeStep"
    :valid="valid"
    :validation-message="validationMessage"
    :progress="progressWithModalCheck"
    :completed-step="completedStep"
  >
    <div class="tw-flex tw-flex-col tw-gap-4">
      <Component :is="component" :slug="slug">
        <template #errorMessage>
          <FadeTransition v-if="globalMessage">
            <banner-component :text="globalMessage" />
          </FadeTransition>
        </template>
      </Component>
    </div>
  </StepNavigationComponent>

  <ProjectSelectionModal :progress="progress" />
</template>

<script>
import { onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import StepNavigationComponent from "../../generic/step_navigation_component.vue";
import ProjectSetup from "./project_setup.vue";
import ProjectValidation from "./project_validation.vue";
import ProjectExpenditureEstimate from "./project_expenditure_estimate.vue";
import FinaliseSelection from "./finalise_selection.vue";
import FadeTransition from "../transitions/fade.vue";
import ProjectSelectionModal from "./project_selection_modal.vue";

import { stepComponents } from "./project_selection_steps.js";
import useEventsBus from "../../../shared/eventBus";

export default {
  components: {
    StepNavigationComponent,
    ProjectSetup,
    ProjectValidation,
    ProjectExpenditureEstimate,
    FinaliseSelection,
    FadeTransition,
    ProjectSelectionModal
  },
  props: {
    slug: {
      required: true,
      type: String
    },
    stepKey: {
      required: true,
      type: String,
      default: "project_validation"
    }
  },
  setup(props) {
    const store = useStore();
    const { emit } = useEventsBus();
    const loading = computed(() => store.getters["project_selection/GET_LOADING"]);
    const isEmpowerrd = computed(() => store.getters["users/GET_IS_EMPOWERRD"]);
    const projectSelection = computed(() => store.getters["project_selection/GET_PROJECT_SELECTION"]);
    const steps = computed(() => projectSelection.value?.steps || []);
    const stepKeys = computed(() => steps.value?.map(step => step.stepKey));
    const valid = computed(() => store.getters["validations/GET_SECTION_VALID"]);

    const validationMessage = computed(() => (
      store.getters["validations/GET_VALIDATION_MESSAGES_FOR_SECTION"]("ProjectSelection", props.slug, true)?.[0]
        || "Please finish the section to progress to the next step"
    ));

    const completedStep = computed(() => ({
      text: "Return to Projects",
      url: `/claim_periods/${projectSelection.value?.claim_period?.slug}/manage_projects`
    }));

    const globalMessage = computed(() => store.getters["project_selection/GET_GLOBAL_VALIDATION_MESSAGE"]);

    watch(
      () => globalMessage.value,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          setTimeout(() => {
            store.commit("project_selection/SET_GLOBAL_VALIDATION_MESSAGE", null);
          }, 10000);
        }
      }
    );

    const progressWithModalCheck = () => {
      const isLastStep = activeStep.value + 1 === steps.value.length;
      const inUnfinished = projectSelection.value.current_state !== "finished";

      if (isLastStep && inUnfinished) {
        emit("toggleModal", "projectSelectionConfirmationModal");
      } else {
        progress();
      }
    };

    function progress() {
      validate(true).then(() => {
        if (valid.value) {
          store.dispatch("project_selection/PROGRESS", { slug: props.slug }).then(data => {
            if (data?.redirect_url) {
              window.location.href = data.redirect_url;
              return;
            }
          });
        } else {
          emit("validateAll");
          store.commit("project_selection/SET_GLOBAL_VALIDATION_MESSAGE", validationMessage.value);
        }
      });
    }

    function validate(validateFull = false) {
      return store.dispatch("validations/VALIDATE", ["ProjectSelection", props.slug, null, validateFull]);
    }

    const activeStep = computed(() => stepKeys.value?.indexOf(props.stepKey) || 0);

    const component = computed(() => stepComponents[props.stepKey]);

    onMounted(() => {
      const promises = [
        store.commit("project_selection/SET_STEP", props.stepKey),
        store.commit("project_selection/SET_LOADING", true),
        store.commit("sections/SET_SECTION_TYPE", "ProjectSelection"),
        store.commit("validations/SET_VALIDATION_PARAMS", { step: props.stepKey }),
        validate(),
        store.dispatch("users/SET_USER"),
        store.dispatch("states/FETCH_STATES_FOR_SECTION", { sectionType: "ProjectSelection", sectionSlug: props.slug }),
        store.dispatch("project_selection/FETCH_DATA", { slug: props.slug }),
        store.dispatch("assignments/FETCH_ASSIGNMENTS", [`ProjectSelection:${props.slug}`])
      ];

      if (isEmpowerrd.value) {
        promises.push(
          store.dispatch("reviews/FETCH_SECTION_REVIEWS", { sectionType: "ProjectSelection", sectionSlug: props.slug })
        );
      }

      Promise.all(promises).then(() => {
        store.commit("project_selection/SET_LOADING", false);
      });
    });

    return {
      activeStep,
      steps,
      valid,
      validationMessage,
      component,
      loading,
      projectSelection,
      progress,
      progressWithModalCheck,
      globalMessage,
      completedStep,
      emit
    };
  }
};
</script>
