<template>
  <div v-cloak v-show="hasUnresolvedFeedback">
    <Tooltip
      :content="tooltipString"
    >
      <i class="far fa-comment-alt fa-lg tw-text-yellow-400" />
    </Tooltip>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import Tooltip from "./tooltip.vue";
import { pluralize, pluralizeHasHave } from "../../shared/helpers";

export default {
  components: {
    Tooltip
  },
  props: {
    sectionSlugs: {
      type: Array,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();

    const hasUnresolvedFeedback = computed(() => store.getters["feedbacks/GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTIONS"](props.sectionType, props.sectionSlugs));

    const tooltipString = `${pluralize(props.sectionSlugs.length, props.sectionType)} ${pluralizeHasHave(props.sectionSlugs.length)} unresolved feedback`;

    return {
      hasUnresolvedFeedback, tooltipString
    };
  }
};
</script>
