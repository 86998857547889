import { get } from "../../shared/http.js";

export const SET_USER = "SET_USER";
export const GET_USER_SLUG = "GET_USER_SLUG";
export const GET_USER_NAME = "GET_USER_NAME";
export const GET_USER_ROLE = "GET_USER_ROLE";
export const GET_COMPANY_NAME = "GET_COMPANY_NAME";
export const GET_HELPHERO_TOUR_STARTED = "GET_HELPHERO_TOUR_STARTED";
export const GET_IS_EMPOWERRD = "GET_IS_EMPOWERRD";
export const GET_IS_ADMIN = "GET_IS_ADMIN";
export const GET_IS_COORDINATOR = "GET_IS_COORDINATOR";

export const users = {
  namespaced: true,
  state: {
    userSlug: null,
    userName: null,
    userRole: null,
    companyName: null,
    helpheroTours: null
  },
  mutations: {
    setUserSlug: (state, payload) => state.userSlug = payload,
    setUserName: (state, payload) => state.userName = payload,
    setUserRole: (state, payload) => state.userRole = payload,
    setCompanyName: (state, payload) => state.companyName = payload,
    setHelpheroTours: (state, payload) => state.helpheroTours = payload
  },
  actions: {
    [SET_USER]: ({ getters, commit }) => {
      if (getters.GET_USER_SLUG === null) {
        get("/api/users/slug").then(data => {
          commit("setUserSlug", data?.slug);
          commit("setUserName", data?.name);
          commit("setUserRole", data?.role);
          commit("setCompanyName", data?.companyName);
          commit("setHelpheroTours", data?.helpheroTours);
        });
      }
    }
  },
  getters: {
    [GET_USER_SLUG]: state => state.userSlug,
    [GET_USER_NAME]: state => state.userName,
    [GET_USER_ROLE]: state => state.userRole,
    [GET_COMPANY_NAME]: state => state.companyName,
    [GET_IS_EMPOWERRD]: state => state.userRole === "Emp reviewer" || state.userRole === "Emp admin",
    [GET_HELPHERO_TOUR_STARTED]: state => tour => state.helpheroTours?.tours_started?.includes(tour),
    [GET_IS_ADMIN]: state => state.userRole === "Emp admin",
    [GET_IS_COORDINATOR]: state => state.userRole === "Coordinator"
  }
};
