<template>
  <div class="tw-text-xs tw-items-center tw-justify-end tw-flex tw-gap-2 tw-my-5">
    <div class="tw-text-gray-500">
      Helpful?
    </div>
    <div @click="markHelpful(true, $event.target)">
      <i class="fas fa-thumbs-up fa-sm tw-cursor-pointer tw-text-gray-500 hover:tw-text-gray-900" />
    </div>
    <div @click="markHelpful(false, $event.target)">
      <i class="fas fa-thumbs-down fa-sm tw-cursor-pointer tw-text-gray-500 hover:tw-text-gray-900" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    query: { type: String },
    feedbackType: { type: String },
    feedbackIdentifier: { type: String }
  },
  data() {
    return {
    };
  },
  methods: {
    markHelpful(helpful, sender) {
      this.track("Knowledge Centre Answer Helpful", { type: this.feedbackType,
        identifier: this.feedbackIdentifier,
        helpful,
        query: this.query });
      sender.classList.add("animated", "rubberBand");
      setTimeout(() => {
        sender.classList.remove("animated", "rubberBand");
      }, 1000);
    }
  }
};
</script>
