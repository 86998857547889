<template>
  <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
    <state-wrapper component="SaveAll+Delete">
      <div :class="[section.type === 'Project' ? 'tw-w-full tw-flex tw-flex-col tw-gap-2' : '']">
        <SaveAllButton :section="section" />
        <a
          v-if="section.type === 'Project'"
          :href="`/projects/${section.slug}`"
          data-method="DELETE"
          data-confirm="Are you sure you want to proceed? Please note that deleting this project will remove all associated cost allocations."
        >
          <ButtonComponent
            id="delete-project"
            text="Delete Project"
            colour="error"
            icon="trash-alt"
            icon-type="fas"
            hide-small
            full-width
          />
        </a>
        <ButtonComponent
          v-if="section.type === 'Project' && isAdmin"
          :text="hasNarrativeCall ? 'Cancel Narrative Call' : 'Schedule Narrative Call'"
          :type="hasNarrativeCall ? 'transparent' : 'primary'"
          :colour="narrativeCallButtonOptions.disabled ? 'disabled' : 'emp'"
          :disable-click="narrativeCallButtonOptions.disabled"
          icon="phone"
          icon-type="fas"
          :tooltip="narrativeCallButtonOptions.tooltipContent"
          hide-small
          full-width
          :on-click="hasNarrativeCall ? cancelNarrativeCall : scheduleNarrativeCall"
        />
      </div>
    </state-wrapper>
    <state-wrapper component="Unlock">
      <Unlock v-if="section && section.type === 'Project'" :section-type="section.type" :section-slug="section.slug" />
    </state-wrapper>
    <state-wrapper component="SendForReviewButton">
      <SendForReviewButton
        :section="section"
      />
    </state-wrapper>
    <state-wrapper component="SectionValidationMessages">
      <div
        v-if="anyValidations"
        class="tw-cursor-pointer"
        :class="warningMessage ? 'tw-bg-error-red-100 tw-p-3 tw-rounded-lg' : ''"
        @click="sendToError()"
      >
        <i class="fas fa-exclamation-triangle tw-mr-1 tw-text-error-red" />
        <span class="tw-font-semibold tw-text-error-red tw-text-sm">
          {{ warningMessage || 'You have some answers that need addressing.' }}
        </span>
      </div>
    </state-wrapper>
  </div>
</template>

<script setup>
import { computed, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { patch } from "@shared/http.js";

import ButtonComponent from "@generic/button_component.vue";
import useEventsBus from "@shared/eventBus.js";
import SaveAllButton from "./save_buttons/save_all_button.vue";
import SendForReviewButton from "../sections/send_for_review_button.vue";
import Unlock from "../unlock.vue";

const props = defineProps({
  section: {
    type: Object,
    required: true
  }
});

const store = useStore();
const { bus, emit } = useEventsBus();

const isEmpowerrd = computed(() => store.getters["users/GET_IS_EMPOWERRD"]);
if (isEmpowerrd.value) store.dispatch("reviews/FETCH_SECTION_REVIEWS", { sectionType: props.section.type, sectionSlug: props.section.slug });

const validations = computed(() => store.getters["validations/GET_ALL_VALIDATION_MESSAGES"]);
const anyValidations = computed(() => store.getters["validations/GET_ANY_VALIDATION_MESSAGES"]);
const isSectionValid = computed(() => store.getters["validations/GET_SECTION_VALID"]);
const sectionReviews = computed(() => store.getters["reviews/GET_SECTION_REVIEWS"]);
const incompleteSectionReviews = computed(() => sectionReviews.value?.filter(review => !review.completed_at));
const hasNarrativeCall = computed(() => incompleteSectionReviews.value?.some(review => review.review_type.key === "narrative_call"));
const isAdmin = computed(() => store.getters["users/GET_IS_ADMIN"]);
const warningMessage = ref(null);

function sendToError() {
  if (_.size(validations.value) > 0) {
    const firstErrorField = _.chain(validations.value).keys().head().split(":")
      .last()
      .value();

    const validationField = document.querySelector(`[field-key="${firstErrorField}"]`);

    if (validationField) {
      const groupIndex = parseInt(validationField.getAttribute("group-index"));
      emit("openFieldGroup", groupIndex);

      nextTick(() => {
        validationField.scrollIntoView({ behavior: "smooth" });
      });
    }
  }
}

const narrativeCallButtonOptions = computed(() => {
  const options = { disabled: true, tooltipContent: "" };

  if (hasNarrativeCall.value) {
    options.disabled = false;
  } else if (incompleteSectionReviews.value && incompleteSectionReviews.value.length > 0) {
    options.tooltipContent = "You cannot schedule a narrative call for a project whilst there is an ongoing review.";
  } else if (!isSectionValid.value) {
    options.tooltipContent = "Before you schedule a narrative call, please ensure any invalid answers are resolved.";
  } else {
    options.disabled = false;
  }

  return options;
});

watch(() => bus.value.get("sendForReviewPrevented"), () => {
  warningMessage.value = "You have some answers that need addressing, these must be resolved before sending to our claim team to review";
});

const scheduleNarrativeCall = async () => {
  const url = `/api/forms/request_narrative_call/${props.section.type}/${props.section.slug}`;
  const res = await patch(url);

  if (res?.path) {
    window.location.href = res.path;
  } else if (isEmpowerrd.value) {
    store.dispatch("reviews/FETCH_SECTION_REVIEWS", { sectionType: props.section.type, sectionSlug: props.section.slug });
  }
};

const cancelNarrativeCall = async () => {
  const url = `/api/forms/recall_from_review/${props.section.type}/${props.section.slug}/?is_narrative_call=true`;
  await patch(url);

  store.dispatch("states/FETCH_STATES_FOR_SECTION", ({ sectionType: props.section.type, sectionSlug: props.section.slug }));

  const claimPeriodSlug = store.getters["feedbacks/GET_CLAIM_PERIOD_SLUG"];
  if (claimPeriodSlug) store.dispatch("states/FETCH_ALL_CLAIM_SECTION_STATES", ({ claimPeriodSlug }));

  if (isEmpowerrd.value) store.dispatch("reviews/FETCH_SECTION_REVIEWS", { sectionType: props.section.type, sectionSlug: props.section.slug });
};

</script>
