<template>
  <!-- <PulsingLoader :size="large" color="tw-bg-gray-200" /> -->
  <!-- Start Filters -->
  <div v-if="reviewsProxy.length > 0">
    <div class="tw-w-1/2 tw-inline-flex tw-gap-x-2 tw-my-1">
      <div class="tw-relative tw-inline-block">
        <dropdown>
          <template #link>
            <span class="tw-cursor-pointer">
              <span class="tw-rounded-md tw-shadow-sm">
                <button
                  type="button"
                  :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium focus:tw-outline-none tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150', anyFilterSet ? 'tw-text-green-600 hover:tw-text-green-500' : 'tw-text-gray-700 hover:tw-text-gray-500']"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div class="tw-flex tw-items-center">
                    <i class="fas fa-filter tw-mr-2" />
                    <div>
                      <div>Filters</div>
                    </div>
                    <i class="fas fa-chevron-down tw-ml-2" />
                  </div>
                </button>
              </span>
            </span>
          </template>
          <template #items>
            <div class="tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100">
              <div class="tw-py-1">
                <template v-for="reviewType in reviewTypes" :key="reviewType">
                  <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('review_type', reviewType) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('review_type', filterEquals('review_type', reviewType) ? null : reviewType)">
                    <i class="fas fa-dot-circle tw-mr-2" />
                    {{ `${titleCase(reviewType)}` }}
                  </div>
                </template>

                <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('expected_due_date', 'this_week') ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('expected_due_date', filterEquals('expected_due_date', 'this_week') ? null : 'this_week')">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Due This Week
                </div>

                <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('expected_due_date', 'next_week') ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('expected_due_date', filterEquals('expected_due_date', 'next_week') ? null : 'next_week')">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Due Next Week
                </div>

                <template v-for="status in statuses" :key="status">
                  <div
                    v-if="!completed"
                    :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('status', status) ? 'tw-text-green-600' : '']"
                    role="menuitem"
                    @click="updateFilter('status', filterEquals('status', status) ? null : status)"
                  >
                    <i class="fas fa-dot-circle tw-mr-2" />
                    {{ status }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </dropdown>
      </div>
      <FadeTransition>
        <a v-if="anyFilterSet" @click="resetFilters()">
          <ButtonComponent
            id="clear-filters"
            text="Clear Filters"
            colour="emp"
            type="transparent"
            icon="trash-alt"
            icon-type="fas"
            :hide-small="true"
          />
        </a>
      </FadeTransition>
    </div>
    <!-- End Filters -->

    <table
      class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-text-sm tw-mt-4 tw-border-b tw-border-gray-200"
    >
      <thead>
        <tr>
          <th v-if="!completed" :class="[css.tables.th, 'tw-w-2/12 tw-cursor-pointer']" @click="setSortColumn(undefined, 'due_at', 'number')">
            <span class="tw-inline-flex tw-gap-x-1 tw-items-center">
              <span class="hover:tw-underline tw-underline-offset-2">
                Due
              </span>
              <i :class="['tw-w-4', sortedIcon('undefined.due_at', 'number')]" />
            </span>
          </th>
          <th :class="[css.tables.th, 'tw-w-28']" @click="setSortColumn(undefined, 'company_name', 'string')">
            <span class="hover:tw-underline tw-underline-offset-2">
              Company
            </span>
            <i :class="['tw-w-4', sortedIcon('undefined.company_name', 'string')]" />
          </th>
          <th :class="[css.tables.th, 'tw-w-32']">
            Period
          </th>
          <th :class="[css.tables.th, 'tw-w-1/12 tw-cursor-pointer tw-text-center']" @click="setSortColumn('reviewer', 'first_name', 'string')">
            <span class="tw-inline-flex tw-gap-x-1 tw-items-center">
              <span class="hover:tw-underline tw-underline-offset-2">
                Reviewer
              </span>
              <i :class="['tw-w-4', sortedIcon('reviewer.first_name', 'string')]" />
            </span>
          </th>
          <th :class="[css.tables.th, 'tw-w-36 tw-text-center']" @click="setSortColumn('review_type', 'type_label', 'string')">
            <span class="hover:tw-underline tw-underline-offset-2">
              Type
            </span>
            <i :class="['tw-w-4', sortedIcon('review_type.type_label', 'string')]" />
          </th>
          <th v-if="isAdmin" :class="[css.tables.th, 'tw-w-1/12 tw-cursor-pointer tw-text-center']" @click="setSortColumn(undefined, 'no_of_reviews', 'number')">
            <span class="tw-inline-flex tw-gap-x-1 tw-items-center">
              <span class="hover:tw-underline tw-underline-offset-2">
                Rounds of Review
              </span>
              <i :class="['tw-w-4', sortedIcon('undefined.no_of_reviews', 'number')]" />
            </span>
          </th>
          <th v-if="!completed && isAdmin" :class="[css.tables.th, 'tw-w-1/12 tw-cursor-pointer tw-text-center']" @click="setSortColumn(undefined, 'priority', 'number')">
            <span class="tw-inline-flex tw-gap-x-1 tw-items-center">
              <span class="hover:tw-underline tw-underline-offset-2">
                Priority
              </span>
              <i :class="['tw-w-4', sortedIcon('undefined.priority', 'number')]" />
            </span>
          </th>
          <th v-if="!completed" :class="[css.tables.th, 'tw-w-28 tw-text-center']" @click="setSortColumn(undefined, 'status_label', 'string')">
            <span class="hover:tw-underline tw-underline-offset-2">
              Status
            </span>
            <i :class="['tw-w-4', sortedIcon('undefined.status_label', 'string')]" />
          </th>
          <th v-if="completed" :class="[css.tables.th, 'tw-w-28 tw-text-center']">
            Quality Score / Review Time
          </th>
          <th v-if="isAdmin" :class="[css.tables.th, 'tw-w-20 tw-text-center']" @click="setSortColumn(undefined, 'emc_in_epoch', 'number')">
            <span class="tw-inline-flex tw-gap-x-1 tw-items-center">
              <span class="hover:tw-underline tw-underline-offset-2">
                <tooltip content="Expected Month of Completion (set in Hubspot by CSM)">EMC</tooltip>
              </span>
              <i :class="['tw-w-4', sortedIcon('undefined.emc_in_epoch', 'number')]" />
            </span>
          </th>
          <th :class="[css.tables.th, 'tw-w-10']" />
        </tr>
      </thead>
      <tbody>
        <template v-for="(review, idx) in reviewsToDisplay" :key="review.slug">
          <tr :class="[reviewBackgroundColor(review, idx)]">
            <td v-if="!completed" :class="[css.tables.td]">
              {{ convertTime(review.due_at) }}
            </td>
            <td :class="[css.tables.td, 'tw-truncate tw-w-48']">
              {{ review.company_name }}
            </td>
            <td :class="[css.tables.td]">
              <div class="tw-inline-flex tw-items-center tw-gap-1">
                <a
                  target="_blank"
                  :href="isAdmin ? `/claim_periods/${review.claim_period.slug}` : null"
                  class="hover:tw-text-gray-400 tw-transition tw-delay-50"
                  v-text="review.claim_period.title_short"
                />
                <tooltip content="Claim Summary">
                  <a
                    :href="`/claim_periods/${review.claim_period.slug}/full_claim_view`"
                    target="_blank"
                  >
                    <i class="fas fa-file-alt tw-text-center tw-mx-1" />
                  </a>
                </tooltip>
                <tooltip v-if="isAdmin && review.submission" content="Submission">
                  <a
                    :href="`/submission/${review.submission.slug}`"
                    target="_blank"
                  >
                    <i class="fas fa-file-export tw-text-center tw-mx-1" />
                  </a>
                </tooltip>
              </div>
            </td>
            <td :class="[css.tables.td]">
              <div class="tw-flex tw-justify-between tw-items-center">
                {{ review.reviewer ? `${review.reviewer.first_name} ${review.reviewer.last_name}` : '??' }}
                <div v-if="isAdmin && review.status_label === 'Not Started' && availableReviewersForType(review).length > 0">
                  <dropdown>
                    <template #link>
                      <div class="submenu-trigger tw-cursor-pointer">
                        <i class="fas fa-exchange-alt tw-mx-2" />
                      </div>
                    </template>
                    <template #items>
                      <div v-cloak :class="css.dropDown.menuDiv">
                        <span
                          v-for="reviewer in availableReviewersForType(review)"
                          :key="reviewer.slug"
                          :class="css.dropDown.itemDiv"
                        >
                          <a
                            :href="`/reviews/${review.slug}/reassign_reviewer/${reviewer.slug}`"
                            data-method="POST"
                          >
                            {{ review.reviewer ? 'Reassign' : 'Assign' }} to {{ reviewer.name }}
                          </a>
                        </span>
                      </div>
                    </template>
                  </dropdown>
                </div>
              </div>
            </td>
            <td :class="[css.tables.td, 'tw-text-center']">
              <div class="tw-inline-flex tw-items-center tw-gap-1">
                <tooltip v-if="review.review_type.label.includes('Narrative')" :content="`Project - ${review.section_title}`">
                  {{ review.review_type.label }}
                  <br>
                  <span class="tw-text-center">
                    <em>{{ truncate(review.section_title, 12) }}</em>
                  </span>
                </tooltip>
                <span v-else class="tw-text-center">{{ review.review_type.label }}</span>
              </div>
            </td>
            <td v-if="isAdmin" :class="[css.tables.td, 'tw-text-center']">
              {{ review.no_of_reviews }}
            </td>
            <td v-if="!completed && isAdmin" :class="[css.tables.td, 'tw-text-center']">
              {{ review.priority }}
            </td>
            <td v-if="!completed" :class="[css.tables.td, 'tw-text-center']">
              {{ review.status_label }}
            </td>
            <td v-if="completed" :class="[css.tables.td, 'tw-text-center']">
              <tooltip
                v-if="review.status_label === 'Completed' && review.mins_spent_on_review"
                class="tw-text-xs tw-italic"
                :content="`This review took ${review.reviewer.first_name} ${review.mins_spent_on_review} minutes to complete.`"
              >
                <div class="tw-text-center">
                  <span v-if="review.quality_score" class="tw-block">{{ review.quality_score }} / 5</span>
                  <span class="tw-block">({{ review.mins_spent_on_review }} mins)</span>
                </div>
              </tooltip>
            </td>
            <td v-if="isAdmin" :class="[css.tables.td]">
              {{ convertToMonth(review.claim_period.expected_month_completion_parsed) }}
            </td>
            <th :class="[css.tables.td]">
              <div class="tw-inline-flex tw-items-center tw-gap-1">
                <div v-if="canStartReview">
                  <tooltip content="Go to Review">
                    <a :href="review.section_link" aria-label="Go to Review">
                      <i class="fa fa-arrow-right fas" />
                    </a>
                  </tooltip>
                </div>
                <tooltip v-if="isAdmin" content="Actions">
                  <ReviewListDropdown :review="review" :is-admin="isAdmin" />
                </tooltip>
              </div>
            </th>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div v-else class="tw-italic tw-text-gray-400">
    No {{ reviewsName }} found...
  </div>
</template>

<script>
import { computed, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { isSameWeek, lightFormat, parseISO } from "date-fns";

import { truncate, titleCase } from "shared/helpers";
import { css } from "../../../../shared/theme.js";
import { dynamicSort } from "../../../../shared/tables_sort.js";

import ReviewListDropdown from "./review_list_dropdown.vue";
import ButtonComponent from "../../../generic/button_component.vue";
import FadeTransition from "../../transitions/fade.vue";

const datefns = require("date-fns");

export default {
  components: {
    ReviewListDropdown,
    ButtonComponent,
    FadeTransition
  },
  props: {
    completed: {
      type: Boolean,
      default: false,
      required: false
    },
    reviewTypes: {
      type: Array,
      default: () => [],
      required: true
    },
    userId: {
      type: String,
      default: null,
      required: false
    },
    forReviewer: {
      type: Boolean,
      default: false,
      required: false
    },
    completedDaysAgo: {
      type: Number,
      default: null,
      required: false
    },
    userRole: {
      type: String,
      default: null,
      required: false
    },
    reviewsName: {
      type: String,
      default: "reviews",
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const reviews = ref([]);

    const allReviewsStoreKey = props.completed === false ? "GET_INCOMPLETE_REVIEWS" : "GET_COMPLETE_REVIEWS";

    const reviewParams = {
      completed: props.completed,
      completed_days_ago: props.completedDaysAgo,
      for_reviewer: props.forReviewer,
      review_types: props.reviewTypes,
      user_id: props.userId
    };

    const fetchReviews = () => {
      if (props.completed) {
        store.dispatch("reviews/FETCH_COMPLETE_REVIEWS", reviewParams);
      } else {
        store.dispatch("reviews/FETCH_INCOMPLETE_REVIEWS", reviewParams);
      }
    };

    function availableReviewersForType(review) {
      const reviewers = store.getters["reviews/GET_AVAILABLE_REVIEWERS_FOR_TYPE"](review?.review_type?.key);
      return _.filter(reviewers, r => r.slug !== review?.reviewer?.slug);
    }

    function fetchAvailableReviewers() {
      store.dispatch("reviews/FETCH_AVAILABLE_REVIEWERS");
    }
    const canStartReview = computed(() => ["emp_reviewer", "emp_claim_quality", "emp_finance"].includes(props.userRole));
    const isAdmin = computed(() => props.userRole === "emp_admin");

    const _incompleteReviews = computed(() => store.getters["reviews/GET_INCOMPLETE_REVIEWS"]);
    const _completeReviews = computed(() => store.getters["reviews/GET_COMPLETE_REVIEWS"]);

    onMounted(() => {
      console.log("props", props);
      fetchReviews();
      if (isAdmin.value) {
        fetchAvailableReviewers();
      }
    });

    watch(_incompleteReviews, newReviews => {
      if (newReviews.length > 0) {
        handleReviewWatchChange();
      }
    });

    watch(_completeReviews, newReviews => {
      if (newReviews.length > 0) {
        handleReviewWatchChange();
      }
    });

    function handleReviewWatchChange() {
      reviews.value = store.getters[`reviews/${allReviewsStoreKey}`];
    }

    return {
      reviews,
      fetchReviews,
      canStartReview,
      isAdmin,
      availableReviewersForType,
      fetchAvailableReviewers,
      truncate,
      titleCase
    };
  },
  data() {
    return {
      css,
      filters: {},
      thisWeekAsString: datefns.formatISO9075(datefns.startOfWeek(new Date()), { representation: "date" }),
      nextWeekAsString: datefns.formatISO9075(datefns.startOfWeek(datefns.add(new Date(), { weeks: 1 })), { representation: "date" }),
      statuses: ["Not Started", "In Progress", "Rejected"],
      sortedColumn: "",
      sortOrder: true,
      reviewsProxy: []
    };
  },
  computed: {
    anyFilterSet() {
      return _.find(_.values(this.filters), x => x !== null) !== undefined;
    },
    reviewsToDisplay() {
      if (this.reviewsProxy.length === 0) {
        return;
      }

      let dataToDisplay = this.reviewsProxy;

      if (this.filterValue("review_type") !== (null || undefined)) {
        dataToDisplay = dataToDisplay.filter(review => this.filterEquals("review_type", review.review_type.key));
      }

      if (this.filterValue("expected_due_date") !== (null || undefined)) {
        const filterForDate = this.filterEquals("expected_due_date", "this_week") ? this.thisWeekAsString : this.nextWeekAsString;

        dataToDisplay = dataToDisplay.filter(review => {
          if (review.due_at === null) {
            return;
          } else {
            const dueDate = lightFormat(new Date(review.due_at), "yyyy-MM-dd");

            return isSameWeek(parseISO(dueDate), parseISO(filterForDate));
          }
        });
      }

      if (this.filters.status !== (null || undefined)) {
        dataToDisplay = dataToDisplay.filter(review => this.filterEquals("status", review.status_label));
      }

      return dataToDisplay;
    }
  },
  watch: {
    reviews(val) {
      this.reviewsProxy = val;
    }
  },
  methods: {
    convertTime(date) {
      if (date !== null) {
        const newDate = new Date(date).toLocaleDateString("en-GB", { weekday: "short", year: "2-digit", month: "short", day: "numeric" });

        return newDate;
      }
    },
    convertToMonth(date) {
      if (date !== null) {
        const newDate = new Date(date).toLocaleDateString("en-GB", { year: "2-digit", month: "short" });

        return newDate;
      }
    },
    updateFilter(filter, value) {
      this.filters[filter] = value;
    },
    filterValue(filter) {
      return this.filters[filter];
    },
    filterEquals(filter, value) {
      return this.filterValue(filter) === value;
    },
    resetFilters() {
      this.filters = {};
    },
    setSortColumn(object, property, sortType) {
      const sortedColString = `${String(object)}.${property.toString()}`;

      if (sortedColString === this.sortedColumn) {
        this.sortOrder = !this.sortOrder;
      }

      this.sortedColumn = sortedColString;

      this.reviewsProxy = dynamicSort(
        this.reviewsProxy,
        object,
        property,
        this.sortOrder,
        sortType
      );
    },
    sortedIcon(columnName, sortType) {
      if (this.sortedColumn === columnName) {
        if (sortType === "number") {
          return this.sortOrder ? "far fa-sort-amount-down-alt" : "far fa-sort-amount-down";
        } else {
          return this.sortOrder ? "far fa-sort-alpha-down" : "far fa-sort-alpha-down-alt";
        }
      } else {
        return "far fa-sort-alt";
      }
    },
    reviewBackgroundColor(review, idx) {
      if (review.status_label === "Completed") {
        return idx % 2 === 0 ? "tw-bg-white" : "tw-bg-gray-50";
      }

      if (review.due_status === "OVERDUE") {
        return "tw-bg-red-200";
      }

      if (review.due_status === "DUE_TODAY") {
        return "tw-bg-red-100";
      }

      if (review.due_status === "DUE_TOMORROW") {
        return "tw-bg-yellow-200";
      }
    }
  }
};
</script>
