<template>
  <div class="tw-flex-shrink-0 tw-flex tw-border-b-2 tw-border-solid tw-border-0 tw-border-muted-blue tw-pb-2 tw-px-3">
    <div class="tw-flex-shrink-0 tw-w-full tw-group tw-block">
      <p class="tw-text-sm tw-m-0 tw-font-semibold tw-text-gray-100">
        {{ userName }}
      </p>
      <p class="tw-text-xs tw-m-0 tw-mt-1 tw-font-medium tw-text-gray-300">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  claimPeriod: {
    type: Object
  }
});

const store = useStore();
const userName = computed(() => store.getters["users/GET_USER_NAME"]);
const companyName = computed(() => store.getters["users/GET_COMPANY_NAME"]);
const userRole = computed(() => store.getters["users/GET_USER_ROLE"]);
const isAdmin = computed(() => store.getters["users/GET_IS_ADMIN"]);
const text = computed(() => (isAdmin.value
  ? `${userRole.value} looking at ${props.claimPeriod.admin_title}`
  : `${userRole.value} at ${companyName.value}`));
</script>
