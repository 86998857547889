<template>
  <div v-if="anyQualityCheckResults">
    <!-- Start Filters -->
    <div class="tw-w-1/2 tw-inline-flex tw-gap-x-2 tw-my-1">
      <div class="tw-relative tw-inline-block">
        <dropdown>
          <template #link>
            <span class="tw-cursor-pointer">
              <span class="tw-rounded-md tw-shadow-sm">
                <button
                  type="button"
                  :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium focus:tw-outline-none tw-bg-gray-50 tw-transition tw-ease-in-out tw-duration-150', anyFilterSet ? 'tw-text-green-600 hover:tw-text-green-500' : 'tw-text-gray-700 hover:tw-text-gray-500']"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div class="tw-flex tw-items-center">
                    <i class="fas fa-user-alt tw-mr-2" />
                    <div>
                      <div v-if="isFilterSet('emp_admin_id')">{{ activeClaimManagerName }}</div>
                      <div v-else>All Managers</div>
                    </div>
                    <i class="fas fa-chevron-down tw-ml-2" />
                  </div>
                </button>
              </span>
            </span>
          </template>
          <template #items>
            <div class="tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100">
              <div class="tw-py-1">
                <div class="tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900" role="menuitem" @click="updateFilter('emp_admin_id', null)">
                  All Managers
                </div>
                <template
                  v-for="[idx, manager] in Object.entries(managers)"
                  :key="idx"
                >
                  <div
                    class="tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                    role="menuitem"
                    @click="updateFilter('emp_admin_id', manager.id)"
                  >
                    <span v-text="manager.first_name" />
                  </div>
                </template>
              </div>
            </div>
          </template>
        </dropdown>
      </div>
      <FadeTransition>
        <a v-if="anyFilterSet" @click="resetFilters()">
          <ButtonComponent
            id="clear-filters"
            text="Clear Filters"
            colour="emp"
            type="transparent"
            icon="trash-alt"
            icon-type="fas"
            :hide-small="true"
          />
        </a>
      </FadeTransition>
    </div>
    <!-- End Filters -->
    <table class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-text-sm tw-mt-4">
      <thead>
        <tr>
          <th :class="[css.tables.th_no_w, 'tw-w-64']">
            Company
          </th>
          <th :class="[css.tables.th_no_w, 'tw-w-36']">
            Period
          </th>
          <th :class="[css.tables.th_no_w, 'tw-w-24 tw-cursor-pointer']" @click="setSortColumn('claim_manager', 'first_name', 'string')">
            <div class="tw-inline-flex tw-gap-x-1">
              <span class="hover:tw-underline tw-underline-offset-4 tw-whitespace-nowrap">Claim Manager</span>
              <span><i :class="['tw-w-4 tw-text-center', sortedIcon('claim_manager.first_name', 'string')]" /></span>
            </div>
          </th>
          <th :class="[css.tables.th_no_w, 'tw-w-24 tw-cursor-pointer tw-text-center']" @click="setSortColumn(undefined, 'claim_progress', 'number')">
            <div class="tw-inline-flex tw-gap-x-1">
              <span class="hover:tw-underline tw-underline-offset-4 tw-whitespace-nowrap">Data Collection</span>
              <span><i :class="['tw-w-4', sortedIcon('undefined.claim_progress', 'number')]" /></span>
            </div>
          </th>
          <th :class="[css.tables.th_no_w, 'tw-w-24 tw-text-left']">
            Biz Q
          </th>
          <th :class="[css.tables.th_no_w, 'tw-w-24 tw-text-left']">
            Costs
          </th>
          <!-- <th :class="[css.tables.th_no_w, 'tw-w-24 tw-text-left']">FDS</th> -->
          <th :class="[css.tables.th_no_w, 'tw-w-24 tw-text-left']">
            Narrative
          </th>
          <!-- <th :class="[css.tables.th_no_w, 'tw-w-24 tw-text-center']">Feedback Cycle</th> -->
          <!-- <th :class="[css.tables.th_no_w, 'tw-w-24 tw-text-center']">YoY Claim Comparison</th> -->
          <!-- @todo Implement Quality Score, add sort -->
          <!-- <th :class="[css.tables.th_no_w, 'tw-w-24 tw-cursor-pointer']" @click="setSortColumn()">
            <span class="tw-inline-flex tw-text-center tw-items-end tw-gap-x-1">
              <div class="hover:tw-underline tw-underline-offset-2 tw-whitespace-nowrap">
                Quality Score
              </div>
              <span>
                <i :class="['', sortedIcon('', 'number')]" />
              </span>
            </span>
          </th> -->
        </tr>
      </thead>
      <tbody>
        <template v-for="(claim, idx) in claimsToDisplay" :key="claim.slug">
          <tr :class="idx % 2 == 0 ? 'tw-bg-white' : 'tw-bg-gray-50'">
            <!-- Company -->
            <td :class="[css.tables.td]">
              <div class="tw-truncate tw-w-64">
                {{ claim.company.name }}
              </div>
            </td>
            <!-- Claim Period -->
            <td :class="[css.tables.td]">
              <a
                target="_blank"
                :href="`/claim_periods/${claim.slug}`"
                class="hover:tw-text-gray-400 tw-transition tw-delay-50"
                v-text="claim.title"
              />
            </td>
            <!-- Manager -->
            <td :class="[css.tables.td]">
              {{ claim.claim_manager.first_name }}
            </td>
            <!-- Progress -->
            <td :class="[css.tables.td, 'tw-text-center']">
              {{ claim.claim_progress }}%
            </td>
            <!-- Status -->
            <td :class="[css.tables.td, 'tw-text-start']">
              <div class="tw-inline-flex tw-gap-x-1">
                <template
                  v-for="(count, result) in claim.quality_check_results['Status']"
                  :key="result"
                >
                  <div
                    v-if="count > 0"
                    :class="`tw-inline-flex tw-justify-start tw-items-center tw-gap-x-1 tw-py-0.5 tw-pr-2 tw-pl-1.5 tw-bg-${resultColour(result) === 'gray' ? resultColour(result) + '-200' : resultColour(result) + '-100'} tw-rounded-full`"
                  >
                    <i :class="`fas fa-${resultIcon(result)} tw-text-${resultColour(result)}-500 fa-sm`" />
                    <span :class="`tw-text-xs tw-text-${resultColour(result)}-500`">
                      {{ count }}
                    </span>
                  </div>
                </template>
              </div>
            </td>
            <!-- Costs -->
            <td :class="[css.tables.td, 'tw-text-start']">
              <div class="tw-inline-flex tw-gap-x-1">
                <template
                  v-for="(count, result) in claim.quality_check_results['Cost']"
                  :key="result"
                >
                  <div
                    v-if="count > 0"
                    :class="`tw-inline-flex tw-justify-start tw-items-center tw-gap-x-1 tw-py-0.5 tw-pr-2 tw-pl-1.5 tw-bg-${resultColour(result) === 'gray' ? resultColour(result) + '-200' : resultColour(result) + '-100'} tw-rounded-full`"
                  >
                    <i :class="`fas fa-${resultIcon(result)} tw-text-${resultColour(result)}-500 fa-sm`" />
                    <span :class="`tw-text-xs tw-text-${resultColour(result)}-500`">
                      {{ count }}
                    </span>
                  </div>
                </template>
              </div>
            </td>
            <!-- FDS -->
            <!-- <td :class="[css.tables.td, 'tw-text-center']"></td> -->
            <!-- Narrative (i.e. Project/Challenge Checks) -->
            <td :class="[css.tables.td, 'tw-text-start']">
              <div class="tw-inline-flex tw-gap-x-1">
                <template
                  v-for="(count, result) in claim.quality_check_results['Narrative']"
                  :key="result"
                >
                  <div
                    v-if="count > 0"
                    :class="`tw-inline-flex tw-justify-start tw-items-center tw-gap-x-1 tw-py-0.5 tw-pr-2 tw-pl-1.5 tw-bg-${resultColour(result) === 'gray' ? resultColour(result) + '-200' : resultColour(result) + '-100'} tw-rounded-full`"
                  >
                    <i :class="`fas fa-${resultIcon(result)} tw-text-${resultColour(result)}-500 fa-sm`" />
                    <span :class="`tw-text-xs tw-text-${resultColour(result)}-500`">
                      {{ count }}
                    </span>
                  </div>
                </template>
              </div>
            </td>
            <!-- Feedback Cycle -->
            <!-- <td :class="[css.tables.td, 'tw-text-center']"></td> -->
            <!-- YoY Claim Comparaison -->
            <!-- <td :class="[css.tables.td, 'tw-text-center']"></td> -->
            <!-- Quality Score -->
            <!-- <td :class="[css.tables.td, 'tw-text-center']"></td> -->
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div v-else class="tw-text-base tw-italic tw-text-gray-400">
    No results found...
  </div>
</template>

<script>
import { Tippy } from "vue-tippy";
import { css } from "../../../../shared/theme.js";
import { dynamicSort } from "../../../../shared/tables_sort.js";

import ButtonComponent from "../../../generic/button_component.vue";
import FadeTransition from "../../transitions/fade.vue";

export default {
  components: {
    Tippy,
    ButtonComponent,
    FadeTransition
  },
  props: {
    allClaimsWithQualityChecks: {
      type: Array,
      required: true
    },
    managers: {
      type: Array,
      default: []
    },
    activeClaimManagerId: {
      type: Number,
      required: false,
      validator(value) {
        return value > 0;
      }
    }
  },
  data() {
    return {
      css,
      filters: {},
      allClaimsWithQualityChecksProxy: this.allClaimsWithQualityChecks,
      sortedColumn: "",
      sortOrder: true
    };
  },
  computed: {
    anyFilterSet() {
      return _.find(_.values(this.filters), x => x !== null) !== undefined;
    },
    claimsToDisplay() {
      if (!this.anyQualityCheckResults) {
        return;
      }

      let dataToDisplay = this.allClaimsWithQualityChecksProxy;

      if (this.filterValue("emp_admin_id") != (null || undefined)) {
        dataToDisplay = dataToDisplay.filter(claim => this.filterEquals("emp_admin_id", claim.claim_manager.id));
      }

      return dataToDisplay;
    },
    activeClaimManagerName() {
      if (this.managers.length > 0 && this.isFilterSet("emp_admin_id")) {
        return this.managers.filter(x => x.id == this.filters.emp_admin_id)[0].first_name;
      }
    },
    anyQualityCheckResults() {
      return this.allClaimsWithQualityChecksProxy.some(claim => Object.keys(claim.quality_check_results).length > 0);
    }
  },
  mounted() {
    if (this.activeClaimManagerId && this.anyQualityCheckResults) {
      this.updateFilter("emp_admin_id", this.activeClaimManagerId);
    }
  },
  methods: {
    updateFilter(filter, value) {
      this.filters[filter] = value;
    },
    filterValue(filter) {
      return this.filters[filter];
    },
    filterEquals(filter, value) {
      return this.filterValue(filter) === value;
    },
    resetFilters() {
      this.filters = {};
    },
    setSortColumn(object, property, sortType) {
      const sortedColString = `${String(object)}.${property.toString()}`;

      if (sortedColString === this.sortedColumn) {
        this.sortOrder = !this.sortOrder;
      }

      this.sortedColumn = sortedColString;

      this.allClaimsWithQualityChecksProxy = dynamicSort(
        this.allClaimsWithQualityChecksProxy,
        object,
        property,
        this.sortOrder,
        sortType
      );
    },
    sortedIcon(columnName, sortType) {
      if (this.sortedColumn === columnName) {
        if (sortType === "number") {
          return this.sortOrder ? "far fa-sort-amount-down-alt" : "far fa-sort-amount-down";
        } else {
          return this.sortOrder ? "far fa-sort-alpha-down" : "far fa-sort-alpha-down-alt";
        }
      } else {
        return "far fa-sort-alt";
      }
    },
    resultIcon(result) {
      if (result === "unknown") {
        return "fas fa-question-circle";
      } else if (result === "pass") {
        return "fas fa-check-circle";
      } else {
        return "fas fa-times-circle";
      }
    },
    resultColour(result) {
      if (result === "unknown") {
        return "gray";
      } else if (result === "pass") {
        return "green";
      } else {
        return "red";
      }
    },
    isFilterSet(filter) {
      return this.filters[filter] ?? "" !== "";
    }
  }
};
</script>
