<template />

<script>
import { mapActions, mapMutations } from "vuex";

import { FIELD_STATE } from "../shared/field_state.js";
import { VALIDATE, SET_LOADING } from "../../../../vuex-store/modules/validations.js";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant", "RdProject", "ProjectSelection"];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    }
  },
  watch: {
    "field.value": function (_val) {
      this.debouncedSave();
    }
  },
  mounted() {
    this.debouncedSave = _.debounce(this.save, 1000);
  },
  methods: {
    ...mapActions({
      validate: `validations/${VALIDATE}`
    }),
    ...mapMutations({
      setLoading: `validations/${SET_LOADING}`
    }),
    save() {
      const fields = {};
      fields[this.field.label] = this.field.value;

      this.setLoading(true);
      this.validate([this.sectionType, this.sectionSlug, fields]).then(data => {
        if (data.valid_fields.indexOf(this.field.label) > -1) {
          this.field.state = FIELD_STATE.SAVED;
        }
      });
    }
  }
};
</script>
