<template>
  <BarChart
    :chart-data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import { BarChart } from "vue-chart-3";
import { ref, onMounted } from "vue";
import { barChartOptions } from "./chart-options.js";

export default {
  components: { BarChart },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    currency: {
      type: Boolean,
      required: false
    },
    showLegend: {
      type: Boolean,
      required: false,
      default: true
    },
    showGridLines: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props) {
    const chartOptions = ref(barChartOptions);

    onMounted(() => {
      if (props.currency) {
        chartOptions.value.scales.y.ticks = {
          callback(value) {
            const originalLabelValue = this.getLabelForValue(value);

            return `£${originalLabelValue}`;
          }
        };

        chartOptions.value.plugins.tooltip.callbacks.title = function (ctx) {
          return `£${ctx[0].formattedValue}`;
        };
      }

      if (props.showGridLines === false) {
        chartOptions.value.scales.x.grid.display = false;
        chartOptions.value.scales.x.grid.drawBorder = false;

        chartOptions.value.scales.y.grid.display = false;
        chartOptions.value.scales.y.grid.drawBorder = false;
      }

      if (props.showLegend === false) {
        chartOptions.value.plugins.legend.display = false;
        chartOptions.value.plugins.tooltip.displayColors = false;
      }

      if (props.title) {
        chartOptions.value.plugins.title.display = true;
        chartOptions.value.plugins.title.text = props.title;
      }
    });

    return {
      chartOptions
    };
  }
};
</script>
