<template>
  <div v-if="showSteps" class="tw-flex tw-flex-col tw-gap-6 tw-items-stretch tw-min-h-full">
    <ol class="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0">
      <StepNavigationItemComponent
        v-for="(step, index) in steps"
        :key="index"
        :step="index + 1"
        :total-length="steps.length"
        :title="step.title"
        :url="step.disabled && valid ? null : step.url"
        :active="activeStep == index"
        :disabled="(index == activeStep + 1) ? (showProgress && !valid) : step.disabled"
        :status="step.status"
        :on-click="(index > activeStep && showProgress) ? progress : null"
        :validation-message="(index == activeStep + 1) ? validationMessage : null"
      />
    </ol>
    <div class="tw-flex-1">
      <slot />
    </div>
    <div id="step-pagination" class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-4">
      <div id="buttons" :class="['tw-grid tw-items-center tw-gap-8 tw-mb-2', ((showProgress || nextLink) && activeStep > 0) ? 'tw-grid-cols-2' : 'tw-grid-cols-1']">
        <button-component
          v-if="activeStep > 0"
          :link="previousLink"
          text="Previous Step"
          colour="confirm"
          type="transparent"
          size="small"
          :full-width="true"
          :center-text="true"
          icon="chevron-left"
          :uppercase="true"
          button-classes="tw-px-8 tw-font-bold tw-tracking-tight"
        />
        <button-component
          v-if="showProgress || nextLink"
          :on-click="showProgress ? progress : () => {}"
          :link="nextLink"
          :text="nextText"
          :colour="showProgress && !valid ? 'disabled' : 'confirm'"
          size="small"
          :full-width="true"
          :icon="lastStep ? null : 'chevron-right'"
          :icon-right="true"
          :center-text="true"
          :tooltip="valid ? null : (validationMessage || null)"
          :uppercase="true"
          button-classes="tw-px-8 tw-font-bold tw-tracking-tight"
        />
      </div>
      <ol class="tw-flex tw-items-center tw-space-x-5">
        <li v-for="(step, index) in steps" :key="step.stepKey" class="tw-group">
          <a
            :href="step.disabled ? null : step.url"
            :class="
              index === activeStep ?
                'tw-relative tw-flex tw-items-center tw-justify-center' :
                `tw-block tw-h-2.5 tw-w-2.5 tw-rounded-full ${stepDotColours(index)}`
            "
          >
            <span v-if="index === activeStep" class="tw-absolute tw-flex tw-h-5 tw-w-5">
              <span class="tw-rounded-full tw-h-full tw-w-full tw-bg-confirm-green-250" />
            </span>
            <span
              v-if="index === activeStep"
              :class="[
                stepDotColours(index),
                'tw-relative tw-block tw-h-2.5 tw-w-2.5 tw-rounded-full'
              ]"
            />
          </a>
        </li>
      </ol>
      <div class="tw-text-sm tw-font-medium">
        Step {{ activeStep + 1 }} of {{ steps.length }}
      </div>
    </div>
  </div>
</template>

<script>

import { computed } from "vue";
import StepNavigationItemComponent from "./step_navigation_item_component.vue";

// Example usage of props:
// :steps="[
//   { title: 'Project Selection', url: '/projects/new' },
//   { title: 'Project Breakdown', url: '/projects/new' },
//   { title: 'Project Review', url: '/projects/new' }
// ]"
// :activeStep="3"
// :completed_redirect_url="'/projects'"

export default {
  // You can pass any props you would pass a button component to this component and
  // it will be passed through to the button component
  name: "StepNavigationComponent",
  components: {
    StepNavigationItemComponent
  },
  props: {
    steps: {
      type: Array,
      required: true
    },
    // This should be the index of the step that is being rendered in the slot
    activeStep: {
      type: Number,
      required: true
    },
    completed_redirect_url: {
      type: String,
      required: false
    },
    valid: {
      type: Boolean,
      required: false,
      default: true
    },
    validationMessage: {
      type: String,
      required: false
    },
    progress: {
      type: Function,
      required: false
    },
    completedStep: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const lastStep = computed(() => (props.activeStep + 1) === props.steps.length);

    const showProgress = computed(() => {
      if (lastStep.value) return props.steps[props.activeStep]?.status !== "Complete";

      return (!nextStep.value || nextStep.value.disabled) && !!props.progress;
    });

    const nextLink = computed(() => {
      if (showProgress.value) return null;
      if (nextStep.value) return nextStep.value.url;
      if (props.completedStep) return props.completedStep.url;

      return;
    });

    const nextText = computed(() => {
      if (lastStep.value) return showProgress.value ? "Finish" : props.completedStep?.text;

      return "Next Step";
    });

    const previousLink = computed(() => {
      if (props.activeStep === 0) return null;

      return props.steps[props.activeStep - 1].url;
    });

    const nextStep = computed(() => props.steps[props.activeStep + 1]);

    function stepDotColours(index) {
      if (index <= props.activeStep) return "tw-bg-confirm-green group-hover:tw-bg-confirm-green-500";

      return "tw-bg-disable-grey hover:tw-bg-disable-grey-500";
    }

    function stepValid(index) {
      return (index <= props.activeStep) || props.valid;
    }

    const showSteps = computed(() => props.steps.length > props.activeStep);

    return {
      showSteps,
      stepDotColours,
      nextLink,
      previousLink,
      nextText,
      lastStep,
      showProgress,
      stepValid
    };
  }
};
</script>
