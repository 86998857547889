<template>
  <div class="tw-flex tw-flex-col tw-gap-3">
    <div class="tw-bg-white tw-rounded-lg tw-shadow tw-flex tw-flex-col tw-gap-2 lg:tw-gap-0 lg:tw-py-4 lg:tw-px-6 tw-py-2 tw-px-2">
      <state-wrapper v-if="cost && !loading" ref="sectionStatus" component="SectionStatus">
        <SectionStatus :section="cost" />
        <hr class="tw-hidden lg:tw-block tw-my-4">
      </state-wrapper>
      <div id="cost-actions" class="tw-flex tw-flex-col tw-gap-3 tw-text-right">
        <button-component id="assign-costs" @click="scrollAssign()">
          <div class="tw-cursor-pointer tw-text-base tw-font-semibold tw-flex tw-items-center tw-gap-2 tw-text-gray-500 hover:tw-text-gray-900">
            <span class="tw-hidden lg:tw-inline">Assign {{ rd ? 'Time Spent on R&D' : 'this Cost Capture' }}</span>
            <i class="fa-user-plus fas fa-lg tw-w-6 tw-text-center" />
          </div>
        </button-component>

        <!-- This is ready to go, just needs to be wired up on the backend first as it currently has a different assignment method -->
        <!-- <AssignIcon
          section-type="Cost"
          :section-slug="slug"
          assign-icon-type="SectionAssign"
          :subsection="rd"
        /> -->

        <button-component v-if="!rd" id="import_export_button" :link="`/costs/${slug}/import_export`">
          <div class="tw-cursor-pointer tw-text-base tw-font-semibold tw-flex tw-items-center tw-gap-2 tw-text-gray-500 hover:tw-text-gray-900">
            <span class="tw-hidden lg:tw-inline">Import/Export</span>
            <i class="fas fa-lg fa-file-spreadsheet tw-w-6 tw-text-center" />
          </div>
        </button-component>
        <button-component
          v-if="!rd"
          id="accounting_integrations_button"
          :link="accountingIntegrationSlug ? `/integrations/xero/${accountingIntegrationSlug}?section=manage` : `/integrations/accounting/${claimPeriodSlug}`"
        >
          <div class="tw-cursor-pointer tw-text-base tw-font-semibold tw-flex tw-items-center tw-gap-2 tw-text-gray-500 hover:tw-text-gray-900">
            <span class="tw-hidden lg:tw-inline">{{ accountingIntegrationSlug ? 'Accounting Integration' : 'Connect Accounting System' }}</span>
            <i class="fas fa-lg fa-cog tw-w-6 tw-text-center" />
          </div>
        </button-component>
      </div>
      <state-wrapper v-if="cost && !loading" component="CostFeedbackSummary">
        <hr v-if="(shouldShowFeedback || allFeedbacksResolved) && rd" class="tw-hidden lg:tw-block tw-my-4">
        <SlideFadeTransition v-if="rd">
          <div v-if="shouldShowFeedback">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-1">
              <div class="tw-hidden lg:tw-block tw-font-semibold tw-flew tw-items-center tw-text-base">
                Feedback
              </div>
              <div class="tw-h-7 tw-flex tw-items-center tw-gap-1.5 tw-px-2 tw-rounded-lg tw-text-yellow-400 tw-bg-amber-50">
                <i
                  class="
                    far
                    fa-comment-alt fa-1x
                  "
                />
                <div class="tw-h-6 tw-w-2 tw-relative tw-inline-block">
                  <SlideUpTransition>
                    <span :key="getAllFeedbackCount" class="tw-absolute tw-font-semibold">
                      {{ getAllFeedbackCount }}
                    </span>
                  </SlideUpTransition>
                </div>
              </div>
            </div>
            <FadeTransition id="feedback-list" group class="tw-relative tw-hidden lg:tw-block tw-text-xs tw-text-light-grey-blue tw-font-semibold">
              <div
                v-for="feedback in feedbacksToShow"
                :key="feedback[0].feedbackable_type"
                :class="['tw-w-full tw-flex lg:tw-justify-between tw-gap-x-2 tw-mt-3', isGeneralCostFeedback(feedback[0]) ? '' : 'tw-cursor-pointer']"
                @click="isGeneralCostFeedback(feedback[0]) ? null : sendToFeedback(feedback[0]['feedbackable_type'])"
              >
                <span>{{ feedbackableTypeWithTitle[feedback[0]["feedbackable_type"]] }}</span>
                <div v-if="isGeneralCostFeedback(feedback[0])">
                  <!-- Cost feedbacks for general cost as a whole -->
                  <Tippy
                    :interactive="true"
                    :allow-h-t-m-l="true"
                    theme="light"
                  >
                    <slot name="toggle">
                      <div class="tw-text-yellow-400 hover:tw-text-yellow-500 tw-cursor-pointer">
                        Read feedback
                      </div>
                    </slot>
                    <template #content="{ hide }">
                      <slot :hide="hide">
                        <div class="tw-flex-col tw-flex tw-gap-2 tw-p-2 tw-justify-start tw-font-normal">
                          <div class="tw-text-yellow-400">
                            <i class="far fa-comment-alt tw-text-base tw-text-yellow-400 tw-inline tw-mr-1" />
                            {{ feedback[0].feedback }}
                          </div>
                          <div class="tw-flex tw-flex-col tw-gap-2">
                            <button class="tw-w-fit tw-font-medium tw-text-sm tw-border tw-rounded-md tw-border-gray-600 hover:tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-gray-600 hover:text-gray-900 focus:tw-outline-none" type="button" @click.stop="markResolved(feedback[0].col_ref, feedback[0].id)">
                              Review/Accept
                            </button>
                            <span v-if="feedback.error" class="tw-text-red-500 tw-font-medium tw-text-xs">
                              <i class="fas fa-exclamation-triangle tw-text-base tw-inline" />
                              {{ feedback.error[colRef][0] }}
                            </span>
                          </div>
                        </div>
                      </slot>
                    </template>
                  </Tippy>
                </div>
                <div v-else>
                  <!-- Cost feedbacks for line items -->
                  <span class="tw-flex-none tw-text-yellow-400 hover:tw-text-yellow-500">
                    See {{ feedback.length > 1 ? `${feedback.length} feedbacks` : 'feedback' }}
                  </span>
                </div>
              </div>
            </FadeTransition>
          </div>
          <div v-else-if="allFeedbacksResolved" class="tw-text-green-700 tw-text-base tw-font-medium tw-hidden lg:tw-block">
            <i class="fas fa-check-circle tw-mr-1" />
            All feedback has been resolved
          </div>
        </SlideFadeTransition>
      </state-wrapper>
    </div>
    <div v-if="cost && !loading">
      <state-wrapper component="SendForReviewButton" :rd="rd">
        <SendForReviewButton :section="cost" />
      </state-wrapper>
      <state-wrapper v-if="bothComplete" component="Unlock">
        <Unlock :rd="rd" section-type="Cost" :section-slug="slug" />
      </state-wrapper>
    </div>
  </div>
</template>

<script>
import { nextTick, computed } from "vue";
import { useStore } from "vuex";
import { Tippy } from "vue-tippy";

import AssignIcon from "@components/dynamic_forms/assign_icon.vue";
import SlideUpTransition from "../transitions/slide_up.vue";
import SlideFadeTransition from "../transitions/slide_fade.vue";
import FadeTransition from "../transitions/fade.vue";
import SendForReviewButton from "../sections/send_for_review_button.vue";
import SectionStatus from "../sections/section_status.vue";
import Unlock from "../unlock.vue";

export default {
  components: {
    Tippy,
    SlideUpTransition,
    SlideFadeTransition,
    FadeTransition,
    SendForReviewButton,
    SectionStatus,
    Unlock,
    AssignIcon
  },
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    slug: {
      type: String,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: false
    },
    rd: {
      type: Boolean,
      required: false,
      default: true
    },
    accountingIntegrationSlug: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useStore();

    const feedbackableTypeWithTitle = {
      CostsEmployee: "Employee Costs",
      CostsSubcontractor: "External Worker Costs",
      CostsConsumable: "Consumable Costs",
      CostsOther: "Other Costs",
      Cost: "General Feedback"
    };

    const cost = computed(() => store.getters["costs/GET_COST"]);
    const loading = computed(() => store.getters["costs/GET_LOADING"]);
    const bothComplete = computed(() => cost.value?.ready_for_review && cost.value?.pc_rd_ready_for_review);

    const allUnresolvedFeedback = computed(() => store.getters["feedbacks/GET_ALL_FEEDBACKS_UNRESOLVED"]);
    const getAllFeedbacks = computed(() => store.getters["feedbacks/GET_ALL_FEEDBACKS"]);
    const getActiveTab = computed(() => store.getters["costs/GET_ACTIVE_TAB"]);

    const getAllFeedbackCount = computed(() => _.chain(allUnresolvedFeedback.value)
          .filter(f => f.feedbackable_type.includes("Cost"))
          .keys()
          .size()
          .value());

    const feedbacksToShow = computed(() => _.chain(allUnresolvedFeedback.value)
          .filter(f => f.feedbackable_type.includes("Cost"))
          .groupBy("feedbackable_type")
          .value());

    const shouldShowFeedback = computed(() => props.rd && getAllFeedbackCount.value > 0);

    const allFeedbacksResolved = computed(() => {
      const allCostFeedbacks = _.chain(getAllFeedbacks.value)
          .values()
          .flatten()
          .filter(f => f.feedbackable_type.includes("Cost"))
          .value();

      const resolvedCostFeedbacksCount = _.filter(allCostFeedbacks ?? [], c => c.resolved === true).length;
      if ((resolvedCostFeedbacksCount && allCostFeedbacks.length) === 0) {
        return false;
      } else {
        return resolvedCostFeedbacksCount === allCostFeedbacks.length;
      }
    });

    function isGeneralCostFeedback(feedback) { feedback.feedbackable_type == "Cost"; }

    async function sendToFeedback(costType) {
      const costTypeIndex = Object.keys(feedbackableTypeWithTitle).indexOf(costType);

      if (costTypeIndex != getActiveTab.value) {
        store.commit("costs/SET_ACTIVE_TAB_BY_NAME", costType);
        await nextTick();
      }

      setTimeout(() => {
        scrollCost(costType, feedbacksToShow.value[costType][0].slug);
      }, 200);
    }

    function scrollCost(costType, slug) {
      const costNode = document.querySelector(`[cost-key="${costType}::${slug}"]`);

      scrollTo(costNode);
    }

    function scrollAssign() {
      const manageTeamCardNode = document.getElementById("assign-card");

      scrollTo(manageTeamCardNode);
    }

    function scrollTo(node) {
      if (!node) return;

      node.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }

    function markResolved(colRef, id) {
      const payload = {
        sectionType: "Cost",
        sectionSlug: props.slug,
        field: colRef,
        feedbackId: id
      };
      store.dispatch("feedbacks/RESOLVE_FEEDBACK", payload).then(() => {
        store.dispatch("states/FETCH_STATES_FOR_SECTION", { sectionType: "Cost", sectionSlug: props.slug, rd: true });
      });
    }

    return {
      cost,
      feedbackableTypeWithTitle,
      allUnresolvedFeedback,
      getAllFeedbacks,
      getActiveTab,
      getAllFeedbackCount,
      feedbacksToShow,
      shouldShowFeedback,
      allFeedbacksResolved,
      sendToFeedback,
      isGeneralCostFeedback,
      scrollAssign,
      markResolved,
      loading,
      bothComplete
    };
  }
};
</script>
