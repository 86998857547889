<template>
  <span v-if="icon">
    <i
      :class="[iconColour, 'fas fa-question-circle tw-cursor-pointer tw-rounded-full']"
      style="background: radial-gradient(white 50%, transparent 50%);"
      @click.stop="trigger()"
    />
  </span>
  <div v-else>
    <span
      class="tw-cursor-pointer tw-inline-flex tw-items-center tw-font-bold tw-text-sm tw-bg-yellow-50 tw-rounded-md tw-max-w-max tw-px-1.5 tw-py-1"
      @click.stop="trigger()"
    >
      <i :class="`fas fa-question-circle fa-1x ${iconColour}`" />
      <span class="tw-pl-2">{{ text }}</span>
    </span>
  </div>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps({
  section: {
    type: String
  },
  question: {
    type: String
  },
  faq: {
    type: String
  },
  text: {
    type: String,
    default: "Show some tips for answering this"
  },
  icon: {
    type: Boolean
  },
  iconColour: {
    type: String,
    default: "tw-text-cta-blue"
  }
});

const track = inject("track");

const trigger = () => {
  track("Knowledge Centre Opened", {
    name: "KnowledgeCentre Triggered from Collab",
    section: props.section,
    question: props.question,
    faq: props.faq
  });

  if (props.question) {
    triggerKnowledgeCentre({ helpQuestion: props.question, helpSection: props.section });
  } else if (props.faq) {
    triggerKnowledgeCentre({ slug: props.faq });
  }
};
</script>
