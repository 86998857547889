<template>
  <div>
    <div class="sm:tw-hidden">
      <label for="tabs" class="tw-sr-only">Select a tab</label>
      <select id="tabs" name="tabs" class="tw-block tw-w-full focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-border-gray-300 tw-rounded-md">
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="tw-hidden sm:tw-block">
      <nav class="tw-flex tw-space-x-4 tw-mb-4" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[selected == tab.name ? 'tw-bg-indigo-100 tw-text-indigo-700' : 'tw-text-gray-500 hover:tw-text-gray-700', 'tw-px-3 tw-py-2 tw-font-medium tw-text-sm tw-rounded-md tw-cursor-pointer']"
          :aria-current="tab.current ? 'page' : undefined"
          @click="loadTab(tab)"
        >
          {{ tab.name }}
        </a>
      </nav>
    </div>

    <div class="tw-m-4">
      <div v-show="loading" class="tw-flex tw-w-full">
        Loading
      </div>

      <div v-show="errorState" class="tw-flex tw-w-full tw-text-red-400">
        Unfortunately we had an error fetching that, could you please reload?
      </div>

      <span v-show="!loading">
        <template v-for="tab in tabs" :key="tab.name">
          <transition name="fade">
            <div v-if="selected == tab.name && !loading" :key="tab.name">
              <div v-html="tabContent[tab.href]" />
            </div>
          </transition>
        </template>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    tabs: { type: Array },
    initialtabname: { type: String }
  },
  data() {
    return {
      loading: false,
      tabContent: {},
      selected: this.initialtabname,
      errorState: false
    };
  },
  beforeMount() {
    // Loads the selected tab if it is passed
    if (this.initialtabname != "" && this.tabs.length > 0) this.loadTab(this.tabs.filter(x => x.name == this.initialtabname)[0]);
  },
  methods: {
    selectedTab() {
      return this.tabs.filter(x => x.selected == this.selected)[0];
    },
    loadTab(tab) {
      if (this.tabContent[tab.href] !== undefined) {
        this.selected = tab.name;
        return this.tabContent[tab.href];
      }

      this.loading = true;

      fetch(tab.href)
        .then(res => res.text())
        .then(html => this.processTab(tab, html))
        .catch(error => this.processError(error));
    },
    processTab(tab, html) {
      this.tabContent[tab.href] = html; // elem.body.innerText;
      this.selected = tab.name;
      this.loading = false;
    },
    processError(_) {
      this.loading = false;
      this.errorState = true;
    }
  }
};
</script>

<style scoped>
  .fade-enter-active{
    transition: opacity 0.5s ease-in;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
