<template>
  <div class="tw-flex tw-flex-col tw-relative">
    <button-component
      id="save-all-changes"
      text="Save All Changes"
      colour="confirm"
      icon="save"
      icon-type="fas"
      :hide-small="true"
      :full-width="true"
      @click="save()"
    />
    <span class="tw-absolute tw-right-2 tw-inset-y-0 tw-flex tw-items-center">
      <saving-spin-indicator
        :section-type="section.type"
        :section-slug="section.slug"
        :fields="fieldsArray"
        :no-text="true"
        :transparent="true"
        @finishedSaving="emitSave()"
      />
    </span>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { toValidateFields } from "../../../../shared/fields.js";

import { VALIDATE, SET_LOADING, GET_ANY_VALIDATIONS_FOR_SECTION } from "../../../../vuex-store/modules/validations.js";

export default {
  props: {
    section: {
      type: Object
    }
  },
  emits: ["finishedSaving"],
  data() {
    return {
      saveTriggered: false
    };
  },
  computed: {
    ...mapGetters({
      anyValidationErrors: `validations/${GET_ANY_VALIDATIONS_FOR_SECTION}`
    }),
    fields() {
      return toValidateFields(this.section.fields);
    },
    fieldsArray() {
      return _.keys(this.fields);
    }
  },
  methods: {
    ...mapActions({
      validate: `validations/${VALIDATE}`
    }),
    ...mapMutations({
      setLoading: `validations/${SET_LOADING}`,
      setEditedFieldsToSaved: "sections/SET_EDITED_FIELDS_TO_SAVED"
    }),
    emitSave() {
      if (this.saveTriggered && !this.anyValidationErrors(this.section.type, this.section.slug)) {
        this.$emit("finishedSaving");
        this.saveTriggered = false;
      }
    },
    save() {
      this.setLoading(true);
      this.saveTriggered = true;
      this.validate([this.section.type, this.section.slug, this.fields, true]);
      this.setEditedFieldsToSaved();
    }
  }
};
</script>
