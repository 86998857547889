<template>
  <table class="tw-w-full tw-border-b-4">
    <thead class="tw-py-8 tw-text-white">
      <th class="tw-w-1/3 tw-py-3 tw-px-10">
        R&D Project Name
      </th>
      <th class="tw-w-1/3 tw-py-3 tw-px-10">
        {{ allocationLabel }}
      </th>
    </thead>
    <tbody>
      <tr v-for="rdProject in rdProjects" :key="rdProject.slug" class="project-row">
        <td class="tw-w-1/2 tw-px-10">
          <div class="tw-relative tw-flex tw-items-start">
            <div class="tw-flex tw-items-center tw-justify-center">
              <input
                :id="`${rdProject.slug}-toggle`"
                v-model="selectedProjects[rdProject.slug]"
                type="checkbox"
                :class="[theme.forms.checkbox, 'tw-mt-1']"
                :disabled="completed"
                @input="toggleProject(rdProject.slug)"
              >
            </div>
            <div class="tw-ml-3 tw-text-sm tw-leading-6 tw-float-left tw-h-6 tw-items-center">
              <span class="font-medium text-gray-900">{{ rdProject.title }}</span>
            </div>
          </div>
        </td>
        <td class="tw-w-1/2 tw-px-10">
          <div class="tw-flex tw-items-start">
            <SliderComponent
              :ref="setSliderRef(rdProject.slug)"
              v-model="sliders[rdProject.slug]"
              :on-change="onSliderChange.bind(this, rdProject.slug)"
              side-buttons
              tooltip-formatter="{value}%"
              :local-max="localMax(sliders[rdProject.slug])"
              :disabled="!selectedProjects[rdProject.slug] || completed"
              class="tw-w-72 tw-grow"
              :group-percentage="slidersTotal"
            />
            <div class="tw-ml-3 tw-w-12 tw-grow-0 tw-h-6 tw-flex tw-self-center">
              <span class="">
                {{ sliders[rdProject.slug] || 0 }}%
              </span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot class="tw-border-t tw-py-8">
      <tr>
        <td />
        <td class="tw-py-3 tw-px-14 tw-text-right">
          <span v-if="slidersTotal === 100" class="tw-text-green-700">
            100% allocated
          </span>
          <span v-if="slidersTotal < 100" class="tw-text-red-700">
            {{ 100 - slidersTotal }}% left to allocate
          </span>
          <span v-if="slidersTotal > 100" class="tw-text-red-700">
            {{ slidersTotal - 100 }}% over allocated
          </span>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { css } from "../../../shared/theme.js";
import SliderComponent from "../../generic/slider_component.vue";
import costLinkingTabs from "./cost_linking_tabs.js";

export default {
  name: "CostProjectsExpandableTable",
  components: {
    SliderComponent
  },
  props: {
    subcost: {
      required: true,
      type: Object
    },
    subcostType: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const store = useStore();

    const allocationLabel = costLinkingTabs[props.subcostType].expandableTable.allocation;

    const projectCostBreakdown = computed(() => store.getters["project_cost_breakdown/GET_PROJECT_COST_BREAKDOWN"]);

    const completed = computed(() => projectCostBreakdown.value?.state === "completed");

    const subcostRdProjects = computed(() => store.getters["project_cost_breakdown/GET_SUBCOST_RD_PROJECTS"](props.subcostType, props.subcost.slug));

    const selectedProjects = ref({});
    function setSelectedProjects() {
      const selected = {};

      subcostRdProjects.value.forEach(rdProject => {
        selected[rdProject.slug] = true;
      });

      selectedProjects.value = selected;
    }
    setSelectedProjects();

    const rdProjectsWithAllocation = computed(() => Object.keys(selectedProjects.value).map(slug => ({
      slug,
      percent_allocation: sliders.value[slug] || 0
    })));

    const sliders = ref({});
    subcostRdProjects.value.forEach(rdProject => {
      sliders.value[rdProject.slug] = rdProject.percent_allocation;
    });

    const sliderInstances = ref([]);
    const setSliderRef = slug => el => {
      sliderInstances.value[slug] = el;
    };

    watch(() => subcostRdProjects.value, rdProjects => {
      Object.keys(sliders.value).forEach(slug => {
        const rdProject = rdProjects.find(rdProject => rdProject.slug === slug);

        if (!rdProject) {
          delete sliders.value[slug];
        }
      });
    });

    const slidersTotal = computed(() => Object.values(sliders.value).reduce((total, value) => total + value, 0));

    function localMax(value) {
      const sliderValues = store.getters["project_cost_breakdown/GET_SUBCOST_RD_PROJECTS"](props.subcostType, props.subcost.slug);
      const allocated = sliderValues.reduce((total, slider) => total + slider.percent_allocation, 0);

      return 100 - allocated + value < 0 ? 0 : 100 - allocated + value;
    }

    function onSliderChange(slug, value) {
      const sliderValues = store.getters["project_cost_breakdown/GET_SUBCOST_RD_PROJECTS"](props.subcostType, props.subcost.slug);
      const sliderValue = sliderValues.find(rdProject => rdProject.slug === slug);

      if (sliderValue) sliderValue.percent_allocation = value;

      store.dispatch("project_cost_breakdown/UPSERT_PROJECT_SUBCOSTS", {
        rdProjects: sliderValues,
        subcostType: props.subcostType,
        subcostSlug: props.subcost.slug
      });
    }

    function toggleProject(rdProjectSlug) {
      sliderInstances.value[rdProjectSlug].setValue(0);

      store.commit("project_cost_breakdown/TOGGLE_RD_PROJECT", {
        rdProjectSlug,
        subcostSlug: props.subcost.slug,
        subcostType: props.subcostType
      });

      setSelectedProjects();

      store.dispatch("project_cost_breakdown/UPSERT_PROJECT_SUBCOSTS", {
        rdProjects: rdProjectsWithAllocation.value,
        subcostType: props.subcostType,
        subcostSlug: props.subcost.slug
      });
    }

    const rdProjects = computed(() => store.getters["project_cost_breakdown/GET_RD_PROJECTS"]);

    return {
      rdProjects,
      sliders,
      selectedProjects,
      toggleProject,
      onSliderChange,
      slidersTotal,
      allocationLabel,
      localMax,
      theme: css,
      completed,
      setSliderRef
    };
  }
};
</script>

<!-- We should refactor these styles when we get the @apply tailwind syntax working here -->
<style scoped>
  thead {
    background-color: #B5C0CE;
  }

  table {
    border-color: #B5C0CE;
  }

  :nth-child(odd of .project-row) {
    background-color:  #fff;
  }

  :nth-child(even of .project-row) {
    background-color:  #f9fafb;
  }
</style>
