<template>
  <DisclosureComponent :default-open="defaultOpen" class="tw--mr-4">
    <template #titleContent>
      <div
        :class="[
          'tw-font-semibold tw-flex tw-items-center tw-justify-start tw-gap-3',
          disabled ? 'tw-text-gray-500 tw-cursor-not-allowed' : ''
        ]"
      >
        <i :class="iconClasses" />
        <span v-if="checkResult.type === 'require_any'">
          Require Any of the Following:
        </span>
        <span v-else>
          <tooltip v-if="checkResult.link || !disabled" :content="checkResult.link ? 'Go to Section' : null">
            <a :href="checkResult.link" target="_blank" class="hover:tw-underline tw-flex tw-items-center tw-gap-2">
              <span>{{ checkResult.title || checkResult.check }}</span>
              <i v-if="checkResult.link" class="fas fa-external-link-alt" />
            </a>
          </tooltip>
          <div v-else class="tw-flex tw-items-center tw-gap-2">
            {{ checkResult.title || checkResult.check }}
          </div>
        </span>
        <div v-if="checkResult.type === 'require_all'">
          <span class="tw-text-sm tw-text-gray-500">
            {{ passingCount }} / {{ totalCount }}
          </span>
        </div>
      </div>
    </template>
    <template v-if="showDropdown" #dropdownContent>
      <div :class="['tw-border-l-2 tw-border-black tw-ml-1.5', checkResult.type === 'require_all' && dependentResults.length > 4 ? 'tw-max-h-[300px] tw-overflow-y-scroll tw-shadow-sm' : '']">
        <div v-if="checkResult.type === 'dependent' && checkResult.dependent_result" class="tw-relative">
          <div class="tw-absolute tw-left-0 tw-top-5 tw--translate-y-1/2 tw-w-3 tw-border-t tw-border-black" />
          <check-result-component
            :check-result="{
              title: dependentValue ? 'Override Passed' : 'Prerequisites Failed',
              value: dependentValue,
              check_results: [checkResult.dependent_result]
            }"
            :level="level + 1"
            :default-open="true"
          />
        </div>
        <div v-if="showFailMessage" class="tw-relative">
          <div class="tw-absolute tw-left-0 tw-top-1/2 tw--translate-y-1/2 tw-w-5 tw-border-t tw-border-black" />
          <div class="tw-pl-6 tw-text-sm tw-text-error-red tw-flex tw-items-center tw-gap-2">
            {{ checkResult.validation_message }}
          </div>
        </div>
        <tooltip
          :content="dependentResultsTooltip"
          :tooltip-options="{ placement: 'top-start' }"
        >
          <div v-for="check_result in dependentResults" :key="check_result.check" class="tw-relative">
            <div class="tw-absolute tw-left-0 tw-top-5 tw--translate-y-1/2 tw-w-3 tw-border-t tw-border-black" />
            <check-result-component
              :check-result="check_result"
              :level="level + 1"
              :disabled="disabled || checkResult.type === 'dependent'"
              :default-open="failCount < 3 && !check_result.value"
            />
          </div>
        </tooltip>
      </div>
    </template>
  </DisclosureComponent>
</template>

<script setup>
import { computed } from "vue";
import DisclosureComponent from "../../generic/disclosure.vue";

const props = defineProps({
  checkResult: { type: Object },
  level: {
    type: Number,
    required: false,
    default: 0
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  defaultOpen: {
    type: Boolean,
    default: undefined
  }
});

const dependentResults = computed(() => {
  if (props.checkResult.type === "require_all") {
    return props.checkResult.check_results.toSorted((a, b) => a.value - b.value) || [];
  }

  return props.checkResult.check_results || [];
});

const iconClasses = computed(() => {
  if (props.disabled || props.checkResult.value === null) {
    return "far fa-circle tw-text-gray-500";
  }
  return props.checkResult.value ? "fas fa-check-circle tw-text-confirm-green" : "fas fa-times-circle tw-text-error-red";
});

const defaultOpen = computed(() => {
  if (props.defaultOpen !== undefined) {
    return props.defaultOpen;
  } else if (props.level === 0) {
    return true;
  } else if (props.level >= 2) {
    return false;
  } else {
    return !props.checkResult.value;
  }
});

const passingCount = computed(() => dependentResults.value.filter(result => result.value).length);
const totalCount = computed(() => dependentResults.value.length);
const failCount = computed(() => dependentResults.value.filter(result => !result.value).length);
const showFailMessage = computed(() => !props.checkResult.value && props.checkResult.validation_message && dependentResults.value.length === 0 && dependentValue.value !== false);

const showDropdown = computed(() => dependentResults.value.length > 0 || showFailMessage.value || dependentValue.value !== null);

const dependentValue = computed(() => {
  if (!props.checkResult.dependent_result) return null;

  return props.checkResult.dependent_result.value;
});

const dependentResultsTooltip = computed(() => {
  if (props.checkResult.dependent_result && props.checkResult.type === "dependent") {
    return `The checks have no results because ${dependentValue.value ? "the override passed so they are not needed" : "the prerequisites failed"}`;
  }

  return null;
});

</script>
