<template>
  <div class="tw-flex tw-gap-x-8 tw-items-start">
    <div class="tw-w-1/3">
      <h2 :class="theme.typography.h2">
        Claim Setup
      </h2>
      <div class="tw-flex tw-items-center tw-gap-x-2 tw-w-100 tw-mb-4">
        <div>
          Start
          <input
            v-model="claimData.start_date"
            type="date"
            :class="theme.forms.input"
            placeholder="Start Date"
          >
        </div>
        <div>
          End
          <input
            v-model="claimData.end_date"
            type="date"
            :class="theme.forms.input"
            placeholder="End Date"
          >
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-gap-x-2 tw-w-100 tw-mb-4">
        <div>
          Non-funded TQE
          <input
            v-model="claimData.total_regular_qualifying_expenditure"
            type="number"
            :class="theme.forms.input"
            placeholder="TQE"
          >
        </div>
        <div>
          Funded TQE
          <input
            v-model="claimData.total_grant_funded_qualifying_expenditure"
            type="number"
            :class="theme.forms.input"
            placeholder="TQE Funded"
          >
        </div>
      </div>

      <h2 :class="theme.typography.h2">
        Company Setup
      </h2>

      <div class="tw-flex tw-items-start tw-gap-x-4 tw-w-100 tw-mb-4">
        <!--  -->
        <!-- PROFIT OR LOSS MAKING -->
        <!--  -->
        <div>
          <div class="tw-flex tw-gap-x-2 tw-items-center">
            <input
              id="loss_making"
              v-model="companyData.is_loss_making"
              name="lossOrProfitMaking"
              type="radio"
              :class="theme.forms.radio"
              :value="true"
            >
            <label for="loss_making">Loss Making</label>
          </div>
          <div class="tw-flex tw-gap-x-2 tw-items-center">
            <input
              id="profit_making"
              v-model="companyData.is_loss_making"
              name="lossOrProfitMaking"
              type="radio"
              :class="theme.forms.radio"
              :value="false"
            >
            <label for="profit_making">Profit Making</label>
          </div>

          <div v-cloak v-show="companyData.is_loss_making == true">
            Loss Amount
            <input
              v-model="companyData.loss_amount"
              type="number"
              :class="theme.forms.input"
              placeholder="Loss Amount"
            >
          </div>
          <div v-cloak v-show="companyData.is_loss_making == false">
            Profit Amount
            <input
              v-model="companyData.profit_amount"
              type="number"
              :class="theme.forms.input"
              placeholder="Profit Amount"
            >
          </div>
          <div v-cloak v-show="companyData.is_loss_making == false">
            Income from distributions
            <input
              v-model="companyData.distributions"
              type="number"
              :class="theme.forms.input"
              placeholder="Distribution Income"
            >
          </div>
          <div v-cloak v-show="companyData.is_loss_making == false">
            Associated Companies (optional)
            <input
              v-model="companyData.associated_companies"
              type="number"
              :class="theme.forms.input"
              placeholder="Associated Companies"
            >
          </div>
          <div v-cloak v-show="companyData.is_loss_making == true && companyData.company_is_sme == true">
            PAYE Amount (Payroll)
            <input
              v-model="companyData.total_gross_pay_estimate"
              type="number"
              :class="theme.forms.input"
              placeholder="Payroll Amount"
            >
          </div>
          <div v-cloak v-show="companyData.company_is_sme == true">
            Claimant Trading and Operating Expenses
            <input
              v-model="costData.trading_operating_expenses_amount"
              type="number"
              :class="theme.forms.input"
              placeholder="Claimant Trading and Operating Expenses"
            >
          </div>
          <div v-cloak v-show="companyData.company_is_sme == true">
            Claimant Capitalised Expenditure Adjustment
            <input
              v-model="costData.capitalised_rd_expenditure_adjustment_amount"
              type="number"
              :class="theme.forms.input"
              placeholder="Claimant Capitalised Expenditure Adjustment"
            >
          </div>
          <div v-cloak v-show="companyData.company_is_sme == true">
            Connected Companies Trading and Operating Expenses
            <input
              v-model="connectedCompaniesData.trading_operating_expenses_amount"
              type="number"
              :class="theme.forms.input"
              placeholder="Connected Companies Trading and Operating Expenses"
            >
          </div>
          <div v-cloak v-show="companyData.company_is_sme == true">
            Connected Companies Capitalised Expenditure Adjustment
            <input
              v-model="connectedCompaniesData.capitalised_rd_expenditure_adjustment_amount"
              type="number"
              :class="theme.forms.input"
              placeholder="Connected Companies Capitalised Expenditure Adjustment"
            >
          </div>
          <div v-cloak v-show="companyData.company_is_sme == true">
            Connected Companies R&D Expenditure
            <input
              v-model="connectedCompaniesData.total_qualifying_amount"
              type="number"
              :class="theme.forms.input"
              placeholder="Connected Companies R&D Expenditure"
            >
          </div>
        </div>
      </div>

      <div class="tw-flex tw-items-start tw-gap-x-4 tw-w-100 tw-mb-4">
        <!-- SME -->

        <div>
          <div class="tw-flex tw-gap-x-2 tw-items-center">
            <input
              id="sme"
              v-model="companyData.company_is_sme"
              name="isSme"
              type="radio"
              :class="theme.forms.radio"
              :value="true"
            >
            <label for="sme">SME Only</label>
          </div>
          <div class="tw-flex tw-gap-x-2 tw-items-center">
            <input
              id="rdec"
              v-model="companyData.company_is_sme"
              name="isSme"
              type="radio"
              :class="theme.forms.radio"
              :value="false"
            >
            <label for="rdec">RDEC only</label>
          </div>
        </div>
      </div>

      <!--  -->
      <!-- PROFIT OR LOSS AMOUNT -->
      <!--  -->

      <ButtonComponent
        text="Calculate"
        colour="cta"
        type="primary"
        @click="doit()"
      />
    </div>

    <div :class="[theme.cards.large, 'tw-w-2/3']">
      <h2 :class="theme.typography.h2">
        Results
      </h2>
      <div v-if="Object.keys(result).length > 0">
        <h3 :class="theme.typography.h3">
          Outputs
        </h3>

        <DescriptionListItem title="Regular Qualifying Expenditure">
          {{ formatCurrency(result.qualifying_expenditure.total, true) }}
        </DescriptionListItem>

        <DescriptionListItem title="Funded Qualifying Expenditure">
          {{ formatCurrency(result.qualifying_grant_funded_expenditure.total, true) }}
        </DescriptionListItem>

        <DescriptionListItem title="Claim Enhancement">
          {{ formatCurrency(result.claim_enhancement.total, true) }}
        </DescriptionListItem>
        <DescriptionListItem title="Enhanced Expenditure">
          {{ formatCurrency(result.enhanced_expenditure.total, true) }}
        </DescriptionListItem>

        <DescriptionListItem title="Adjusted Trading Loss or Profit">
          {{ formatCurrency(result.adjusted_trading_loss_or_profit, true) }}
        </DescriptionListItem>

        <hr class="tw-my-2">

        <DescriptionListItem title="Corporation Tax (before R&D)">
          {{ formatCurrency(result.corporation_tax, true) }}
        </DescriptionListItem>

        <DescriptionListItem title="Corporation Tax (after R&D)">
          {{ formatCurrency(result.corporation_tax_after_rd, true) }}
        </DescriptionListItem>

        <hr class="tw-my-2">

        <DescriptionListItem title="SME Scheme Benefit">
          {{ formatCurrency(result.sme_value, true) }}
        </DescriptionListItem>

        <DescriptionListItem title="SME Scheme Benefit Type">
          {{ (result.sme_type, true) }}
        </DescriptionListItem>

        <DescriptionListItem title="RDEC Scheme Benefit">
          {{ formatCurrency(result.rdec_value, true) }}
        </DescriptionListItem>

        <hr class="tw-my-2">

        <!-- @todo fix parsing of values when one of them could be nil -->
        <DescriptionListItem title="Combined Total Benefit">
          {{ formatCurrency(parseFloat(result.rdec_value) + parseFloat(result.sme_value), true) }}
        </DescriptionListItem>

        <DescriptionListItem title="Combined Total Benefit After Paying Corp Tax">
          {{ formatCurrency(parseFloat(result.rdec_value) + parseFloat(result.sme_value) - parseFloat(result.corporation_tax_after_rd), true) }}
        </DescriptionListItem>

        <!-- <DescriptionListItem title="Expenditure Enhancement Methodology">
          <template v-for="item in result.enhanced_expenditure_pro_rata_steps" :key="item">
            <li>{{item}}</li>
          </template>

        </DescriptionListItem>

 -->
        <h3 :class="theme.typography.h3">
          Methodology
        </h3>
        <div>
          <template v-for="(m, index) in result.methodology" :key="index">
            <div class="tw-flex tw-font-mono tw-text-xs tw-gap-x-2 tw-items-center tw-justify-between">
              <div>{{ m.message }}</div>
              <div v-if="typeof m.value === 'object' && !Array.isArray(m.value)">
                <!-- Handle cases where value is an object (e.g., financial years) -->
                <div v-for="(value, key) in m.value" :key="key">
                  <div>{{ key }}</div>
                  <div class="tw-text-right">
                    {{ value }}
                  </div>
                </div>
              </div>
              <div v-else-if="Array.isArray(m.value)">
                <!-- Handle cases where value is an array -->
                <div v-for="(value, idx) in m.value" :key="idx">
                  <div v-if="typeof value === 'object'">
                    <div v-for="(v, k) in value" :key="k">
                      <div>{{ k }}</div>
                      <div class="tw-text-right">
                        {{ v }}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div>{{ value }}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <!-- Default case when value is not an object or array -->
                <div class="tw-text-right">
                  {{ m.value }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import ButtonComponent from "../generic/button_component.vue";
import DescriptionListItem from "../generic/description_list_item.vue";
import { css } from "../../shared/theme.js";
import { post } from "../../shared/http.js";
import { formatCurrency } from "../../shared/helpers.js";

const theme = css;

export default {
  components: { ButtonComponent, DescriptionListItem },
  setup(props) {
    // const claimData = ref({start_date: '2022-10-01', end_date: '2023-09-30', tqe: 100000});
    const claimData = ref({ start_date: "2021-04-01", end_date: "2022-03-31", total_regular_qualifying_expenditure: 65000, total_grant_funded_qualifying_expenditure: 0 });
    const costData = ref({ trading_operating_expenses_amount: 0, capitalised_rd_expenditure_adjustment_amount: 0 });
    const connectedCompaniesData = ref({ total_qualifying_amount: 0, trading_operating_expenses_amount: 0, capitalised_rd_expenditure_adjustment_amount: 0 });

    const companyData = ref({ is_loss_making: false, loss_amount: 0, company_is_sme: true, total_gross_pay_estimate: 10000000, profit_amount: 100 });
    const result = ref({});

    async function doit() {
      const response = await post("/api/claim_periods/prototype_calculation", {
        claim: claimData.value,
        company: companyData.value,
        cost: costData.value,
        connected_companies: [connectedCompaniesData.value]
      });

      result.value = response;
    }

    onMounted(() => doit());

    return {
      claimData,
      companyData,
      costData,
      connectedCompaniesData,
      theme,
      result,
      doit,
      formatCurrency
    };
  }
};
</script>
