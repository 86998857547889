<template>
  <div class="tw-w-full tw-overflow-x-auto tw-mt-3">
    <ul class="tw-space-y-2 tw-list-none tw-mb-0">
      <li
        v-for="(step, idx) in dropdownData"
        :key="idx"
        :class="color(step)"
        class="tw-text-xs tw-font-medium tw-flex tw-items-center tw-gap-2 tw-ml-2"
      >
        <div v-if="step.link">
          <a class="tw-inline-flex tw-items-center tw-gap-2 tw-text-xs" :class="color(step)" :href="step.link">
            <i :class="icon(step)" />
            {{ step.title }}
            <i class="tw-font-semibold far fa-external-link" />
          </a>
        </div>
        <div v-else class="tw-inline-flex tw-items-center tw-gap-2">
          <i :class="icon(step)" />
          {{ step.title }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    sectionData: {
      type: Object,
      required: false
    },
    dropdownData: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      required: true,
      validator: str => {
        const validSectionTypes = [
          "status",
          "compliance_docs",
          "epw_contracts",
          "finance_docs",
          "review",
          "submission"
        ];

        return validSectionTypes.indexOf(str) >= 0;
      }
    }
  },
  methods: {
    icon(step) {
      if (this.sectionType === "review" && step.title.includes("unresolved") && step.status === true) {
        return "fas fa-exclamation-triangle";
      } else if (this.sectionType === "submission" && step.value === 5.0) {
        return "fas fa-clock";
      } else if (step.status === true) {
        return "tw-font-semibold fas fa-check-circle";
      } else {
        return "far fa-circle";
      }
    },
    color(step) {
      if (this.sectionType === "review" && step.title.includes("unresolved") && step.status === true) {
        return "tw-text-yellow-400 hover:tw-text-yellow-500";
      } else if (this.sectionType === "submission" && step.value === 10.0) {
        return "tw-text-light-grey-blue";
      } else if (step.status === true) {
        return step.link ? "tw-text-green-700 hover:tw-text-green-900" : "tw-text-green-700";
      } else {
        return "tw-text-light-grey-blue";
      }
    }
  }
};
</script>
