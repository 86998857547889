<template>
  <div class="tw-w-full">
    <div v-if="editable && !isReviewer">
      <div class="tw-max-w-lg tw-flex tw-flex-col tw-gap-y-3 tw-mt-1">
        <template v-for="option in options(`${sectionType}:${sectionSlug}:${field.label}`)" :key="option[0]">
          <div class="tw-flex tw-items-start tw-gap-x-3">
            <div class="tw-flex tw-items-center tw-h-5">
              <input
                :id="fieldId(option)"
                v-model="field.value"
                :name="`${field.label}`"
                type="checkbox"
                :value="`${option[0]}`"
                class="
                  focus:tw-ring-deep-blue
                  tw-h-4
                  tw-w-4
                  tw-text-deep-blue
                  tw-border-solid
                  tw-border-gray-300
                  tw-rounded
                "
              >
            </div>
            <label :for="fieldId(option)" class="tw-font-medium tw-text-sm tw-text-gray-700 tw-mb-0">
              {{ option[1] }}
            </label>
          </div>
        </template>
      </div>
    </div>
    <div v-else>
      <div class="tw-text-gray-500 tw-text-sm">
        <div v-if="selectedOptions && selectedOptions.length > 0" class="tw-flex tw-flex-col tw-gap-2">
          <span
            v-for="option in selectedOptions"
            :key="option[0]"
            class="tw-inline-flex tw-items-start tw-gap-2"
          >
            <i class="far fa-check tw-mt-0.5" />
            {{ option[1] }}
          </span>
        </div>
        <div v-else>
          N/A
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_MULTI_SELECT_OPTIONS } from "../../../../vuex-store/modules/sections.js";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant"];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isReviewer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      options: `sections/${GET_MULTI_SELECT_OPTIONS}`
    }),
    selectedOptions() {
      const options = this.options(`${this.sectionType}:${this.sectionSlug}:${this.field.label}`);
      if (options) {
        return _.chain(options)
                .filter(option => this.field.value.includes(option[0]))
                .value();
      }
    }
  },
  methods: {
    fieldId(option) {
      return `${this.sectionType}:${this.sectionSlug}:${this.field.label}:${option[0]}`;
    }
  }
};
</script>
