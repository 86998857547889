<template>
  <div class="tw-flex tw-flex-col tw-gap-y-1 tw-py-0.5 tw-text-sm tw-leading-6 tw-font-light">
    <div class="tw-flex tw-items-center tw-flex-1 tw-gap-2">
      <i :class="[iconClasses, 'tw-pt-0.5']" />
      <span>{{ label }} -</span>
      <span class="tw-italic">{{ client_facing_value }}</span>
    </div>
    <div v-if="!unchanged && client_facing_value_at_task_completion" class="tw-pl-5 tw-flex tw-items-center">
      <div class="tw-text-red-900 tw-italic">
        - Was {{ client_facing_value_at_task_completion }} at time of check
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  id: { type: Number },
  label: { type: String },
  unchanged: { type: Boolean },
  value_at_task_completion: { type: String },
  value: { type: String },
  client_facing_value: { type: String },
  client_facing_value_at_task_completion: { type: String }
});

const iconClasses = computed(() => {
  if (!props.id) {
    return "far fa-circle tw-text-disable-grey";
  } else if (props.unchanged) {
    return "fas fa-check-circle tw-text-confirm-green";
  } else {
    return "fas fa-times-circle tw-text-error-red";
  }
});

</script>
