<template>
  <Tippy :content="tooltip" delay="400">
    <div class="tw-cursor-pointer tw-text-right" :class="color">
      <span class="tw-mr-2 tw-text-sm tw-hidden xl:tw-inline">{{ text }}</span>
      <i :class="icon" class="fas fa-1x" />
    </div>
  </Tippy>
</template>

<script>
import { Tippy } from "vue-tippy";

export default {
  components: {
    Tippy
  },
  props: {
    sectionType: {
      type: String,
      required: false
    },
    assigned: {
      type: Boolean,
      required: true
    },
    assignedToUser: {
      type: Boolean,
      required: false
    },
    assignedUsers: {
      type: Object,
      required: false
    },
    somethingAssigned: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    user() {
      return this.assignedUsers[0];
    },
    icon() {
      if (this.assigned) {
        return "fa-user tw-pr-1";
      } else {
        return "fa-user-plus";
      }
    },
    color() {
      if (this.assignedToUser || this.somethingAssigned) {
        return "tw-text-green-600 tw-font-semibold hover:tw-text-green-700";
      } else if (this.assigned) {
        return "tw-text-indigo-500 hover:tw-text-indigo-700";
      } else {
        return "tw-text-gray-500 hover:tw-text-gray-900";
      }
    },
    text() {
      if (this.assignedToUser && this.assignedUsers.length > 1) {
        return "Section assigned to you & others";
      } else if (this.assignedToUser) {
        return "Section assigned to you";
      } else if (this.somethingAssigned) {
        return "Questions assigned to you";
      } else if (this.assigned) {
        if (this.assignedUsers.length === 1 && this.user) {
          return `Section assigned to ${this.user.first_name} ${this.user.last_name[0]}`;
        } else {
          return "Section assigned to others";
        }
      } else {
        return "Assign this section";
      }
    },
    tooltip() {
      if (this.assignedToUser) {
        return "All of the questions in this section have been assigned to you, click to re-assign or assign others";
      } else if (this.assigned) {
        return "Click to re-assign or assign others";
      } else {
        return "Click here to assign this section to someone in your company";
      }
    }
  }
};
</script>
