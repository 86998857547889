<template>
  <transition v-if="showWarningMessage && anyValidationErrors" name="fade">
    <banner-component type="error">
      {{ warningMessage }} {{ reviewable ? 'before our claim team can review.' : 'before you can complete this section.' }}
    </banner-component>
  </transition>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import useEventsBus from "../../../shared/eventBus.js";

export default {
  props: {
    slug: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    rd: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore();
    const showWarningMessage = ref(false);
    const warningMessage = computed(() => {
      if (getAnyUnsavedCosts.value) return "Please save any unsaved changes";

      return validationMessage.value[0] || `Some ${props.type === "Cost" ? "costs" : "contracts"} are missing information needed`;
    });

    const reviewable = computed(() => props.rd || props.type !== "Cost");

    const getAnyUnsavedCosts = computed(() => store.getters["costs/GET_ANY_UNSAVED_COSTS"]);
    const anyValidationErrors = computed(() => store.getters["validations/GET_ANY_VALIDATION_MESSAGES"]);
    const validationMessage = computed(() => store.getters["validations/GET_VALIDATION_MESSAGES_FOR_SECTION"](props.type, props.slug));
    const validationMessages = computed(() => store.getters["validations/GET_VALIDATION_MESSAGES"]);

    const { bus } = useEventsBus();

    watch(() => bus.value.get("sendForReviewPrevented"), () => {
      showWarningMessage.value = true;
    });

    return {
      anyValidationErrors,
      validationMessages,
      validationMessage,
      warningMessage,
      showWarningMessage,
      reviewable
    };
  }
};
</script>
