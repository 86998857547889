const epw_columns = {
  EpwContract: [
    {
      field: "costs_subcontractor",
      nested_field: "name",
      label: "Externally Provided Worker",
      format: "String"
    },
    {
      field: "costs_subcontractor",
      nested_field: "amount",
      label: "Amount",
      format: "Currency"
    },
    {
      field: "file_name",
      label: "Contract File Name",
      format: "File"
    },
    {
      field: "project",
      nested_field: "title",
      label: "Project",
      format: "String"
    },
    {
      field: "approved",
      label: "Approved",
      format: "Boolean"
    }
  ]
};

export { epw_columns };
