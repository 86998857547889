<template>
  <template v-for="(group, idx) in groups" :key="idx">
    <FieldGroup
      :group-name="group[0]"
      :group-key="group[1]"
      :fields="getFields(group[0])"
      :section="section"
      :group-index="idx"
      :options="options"
    />
  </template>
</template>

<script>
import FieldGroup from "./field_group.vue";

export default {
  components: {
    FieldGroup
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      fields: null
    };
  },
  computed: {
    groups() {
      if ((this.section?.fields ?? []).length > 0) {
        this.fields = _.chain(this.section.fields)
          .groupBy("field_group")
          .value();

        return _.chain(this.section.fields)
          .orderBy("field_group_order")
          .groupBy("field_group")
          .map(g => [g[0].field_group, g[0].field_group_key])
          .value();
      }
    }
  },
  methods: {
    getFields(groupName) {
      return this.fields[groupName];
    }
  }
};
</script>
