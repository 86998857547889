<template>
  <div v-for="index in lines" :key="index">
    <div
      :class="['tw-h-6 tw-rounded-sm tw-animate-pulse tw-mb-4', color]"
    />
  </div>
</template>

<script>
export default {
  name: "PulsingLoader",
  props: {
    size: {
      type: String,
      required: false,
      default: "medium",
      validator(value) {
        return value === "small" || value === "medium" || value === "large";
      }
    },
    color: {
      type: String,
      required: false,
      default: "tw-bg-gray-100"
    }
  },
  computed: {
    lines() {
      switch (this.size) {
        case "small":
          return 2;
        case "medium":
          return 4;
        case "large":
          return 6;
        default:
          return 4;
      }
    }
  }
};
</script>
