<template>
  <div id="project-selection-project-validation" class="tw-flex tw-flex-col tw-gap-4">
    <card-component
      id="project-validation-blurb"
      :title="title"
      :knowledge-centre="{ section: 'ProjectSelection', question: 'project_validation' }"
    >
      <template #action>
        <state-wrapper component="AssignIcon">
          <AssignIcon
            section-type="ProjectSelection"
            :section-slug="slug"
            assign-icon-type="StepAssign"
            :fields="['project_validation']"
          />
        </state-wrapper>
      </template>
      <template #content>
        To ensure full compliance with HMRC's R&D guidelines, we need you to provide us with some high-level information
        on each project so that we can determine the projects that will require detailed documentation later on.
      </template>
    </card-component>
    <slot name="errorMessage" />
    <div id="project-validation-table">
      <RdProjectsTable :slug="slug" step-key="project_validation">
        <template v-if="rdProjects.length === 0" #bottomTableContent>
          <div class="tw-flex tw-items-center tw-justify-center tw-gap-4 tw-p-4 tw-italic tw-text-gray-500">
            No R&D Projects added
            <state-wrapper component="AddRdProject">
              <AddRdProject text="Click to Add" :slug="slug" />
            </state-wrapper>
          </div>
        </template>
      </RdProjectsTable>
    </div>
  </div>
</template>

<script>
import { computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { stepTitles } from "./project_selection_steps.js";
import RdProjectsTable from "./rd_projects_table.vue";
import AddRdProject from "./column_components/add_rd_project.vue";
import AssignIcon from "../dynamic_forms/assign_icon.vue";

export default {
  components: {
    RdProjectsTable,
    AddRdProject,
    AssignIcon
  },
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const store = useStore();
    const title = computed(() => stepTitles.project_validation);
    const rdProjects = computed(() => store.getters["project_selection/GET_RD_PROJECTS"]);

    watch(() => rdProjects.value?.length, (newVal, oldVal) => {
      if (newVal !== oldVal) fetchFormRdProjects();
    });

    onMounted(() => {
      fetchFormRdProjects();
    });

    function fetchFormRdProjects() {
      store.dispatch("sections/SET_ALL_SECTIONS", {
        sectionType: "ProjectSelection",
        sectionSlug: props.slug
      });
    }

    return {
      title,
      rdProjects
    };
  }
};

</script>
