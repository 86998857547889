<template>
  <div class="tw-text-center" :class="accordionClasses">
    <div class="dropdown" @click="toggleAccordion">
      <i
        class="fas fa-chevron-down header-icon"
        :class="{ rotate: isOpen }"
        data-toggle="tooltip"
        title="Assigned Sections"
      />
    </div>
    <div class="sections-body">
      <slot name="sections" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    accordionClasses() {
      return {
        "is-closed": this.isOpen === false
      };
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style>
  .sections-body {
    transition: 0.3s ease all;
    overflow: hidden;
  }
  .dropdown {
    cursor: pointer;
  }
  .is-closed .sections-body {
    max-height: 0;
  }
  .header-icon {
    transform: rotate(0deg);
    transition-duration: 0.3s;
  }
  .header-icon.rotate {
    transform: rotate(180deg);
    transition-duration: 0.3s;
  }
</style>
