import { get, put, patch, post } from "@shared/http.js";
import { decimalGreaterThan } from "@shared/helpers.js";
import _ from "lodash";

// mutations
const SET_RD_PROJECT_DATA = "SET_RD_PROJECT_DATA";
const SET_SECTION_COMPLETED_AT = "SET_SECTION_COMPLETED_AT";
const UPDATE_SLIDER_VALUE = "UPDATE_SLIDER_VALUE";
const UPDATE_PROJECT_GRANT_ALLOCATION_VALUE = "UPDATE_PROJECT_GRANT_ALLOCATION_VALUE";

// actions
const FETCH_RD_PROJECT_DATA = "FETCH_RD_PROJECT_DATA";
const TRIGGER_UPDATE_SLIDER_VALUE = "TRIGGER_UPDATE_SLIDER_VALUE";
const SAVE_SLIDER_VALUES = "SAVE_SLIDER_VALUES";
const DEBOUNCED_SAVE_SLIDER_VALUES = "DEBOUNCED_SAVE_SLIDER_VALUES";
const SAVE_PROJECT_GRANT_ALLOCATION_VALUE = "SAVE_PROJECT_GRANT_ALLOCATION_VALUE";
const MARK_AS_COMPLETE = "MARK_AS_COMPLETE";

// getters
const GET_RD_PROJECTS = "GET_RD_PROJECTS";
const GET_VALIDATION_STATUS = "GET_VALIDATION_STATUS";
const GET_IS_FULLY_ALLOCATED = "GET_IS_FULLY_ALLOCATED";
const GET_IS_SECTION_COMPLETE = "GET_IS_SECTION_COMPLETE";

export const project_grant_allocation = {
  namespaced: true,
  state: {
    section_completed_at: undefined,
    rd_projects: []
  },
  mutations: {
    [SET_RD_PROJECT_DATA]: (state, payload) => (state.rd_projects = payload),
    [SET_SECTION_COMPLETED_AT]: (state, payload) => (state.section_completed_at = payload),
    [UPDATE_SLIDER_VALUE]: (state, payload) => {
      const { value, id, projectId } = payload;

      const projectToUpdate = state.rd_projects.find(project => project.id === projectId);
      const costObjectToUpdate = projectToUpdate.cost_breakdowns.find(cost => cost.slider_props.id === id);

      costObjectToUpdate.slider_props.value = value;

      const totalGrantAllocationAmount = projectToUpdate.grant_claim_allocations.reduce((acc, next) => acc + parseFloat(next.amount), 0);
      costObjectToUpdate.project_grant_cost_breakdown_amount = parseFloat((totalGrantAllocationAmount / 100) * value);

      const allocatedPercentage = projectToUpdate.cost_breakdowns.reduce((acc, next) => acc + parseFloat(next.slider_props.value || 0), 0);
      projectToUpdate.allocatedPercentage = allocatedPercentage;

      costObjectToUpdate.slider_props.localMax = allocatedPercentage > 100 ? 100 - allocatedPercentage + value : undefined;
      costObjectToUpdate.error = decimalGreaterThan(costObjectToUpdate.project_grant_cost_breakdown_amount, parseFloat(costObjectToUpdate.total_qualifying_expenditure), 2);

      projectToUpdate.cost_breakdowns.forEach(cost => cost.slider_props.groupPercentage = allocatedPercentage);
    },
    [UPDATE_PROJECT_GRANT_ALLOCATION_VALUE]: (state, payload) => {
      const { value, grantClaimAllocationId, projectId } = payload;

      const projectToUpdate = state.rd_projects.find(project => project.id === projectId);

      const grantToUpdate = projectToUpdate.grant_claim_allocations.find(grantClaimAllocation => grantClaimAllocation.id === grantClaimAllocationId);
      grantToUpdate.amount = parseFloat(value);

      const shouldDisableSliders = projectToUpdate.grant_claim_allocations.every(grantAllocation => !Number(grantAllocation.amount));

      const totalGrantAllocationAmount = projectToUpdate.grant_claim_allocations.reduce((acc, next) => acc + parseFloat(next.amount), 0);
      projectToUpdate.cost_breakdowns.forEach(cost => {
        cost.project_grant_cost_breakdown_amount = parseFloat((totalGrantAllocationAmount / 100) * cost.slider_props.value);
        cost.error = decimalGreaterThan(cost.project_grant_cost_breakdown_amount, cost.total_qualifying_expenditure, 2);
        cost.slider_props.disabled = shouldDisableSliders;
      });
    }
  },
  actions: {
    [FETCH_RD_PROJECT_DATA]: async ({ state, commit }, payload) => {
      const response = await get(`/api/v1/claim_periods/${payload.claimPeriodSlug}/project_grant_allocations`);

      const configuredResponse = response.map(project => {
        const shouldDisableSliders = !!state.section_completed_at || project.grant_claim_allocations.every(grantAllocation => !Number(grantAllocation.amount));
        const allocatedPercentage = project.cost_breakdowns.reduce((acc, next) => acc + parseFloat(next.percentage_of_grant_claim_allocation || 0), 0);

        const costsWithConfig = project.cost_breakdowns.map(cost => ({
          ...cost,
          error: decimalGreaterThan(parseFloat(cost.project_grant_cost_breakdown_amount), parseFloat(cost.total_qualifying_expenditure), 2),
          slider_props: {
            id: cost.key,
            value: cost.percentage_of_grant_claim_allocation || 0,
            tooltipFormatter: "{value}%",
            disabled: shouldDisableSliders,
            sideButtons: true,
            displayValue: {
              type: "percentage"
            },
            storeAction: {
              action: "dispatch",
              name: "project_grant_allocation/TRIGGER_UPDATE_SLIDER_VALUE",
              data: {
                projectId: project.id
              }
            }
          }
        }));

        return {
          ...project,
          cost_breakdowns: costsWithConfig,
          allocatedPercentage
        };
      });

      commit("SET_RD_PROJECT_DATA", configuredResponse);

      return configuredResponse;
    },
    [TRIGGER_UPDATE_SLIDER_VALUE]: ({ commit, dispatch }, payload) => {
      commit(UPDATE_SLIDER_VALUE, payload);
      dispatch(DEBOUNCED_SAVE_SLIDER_VALUES, payload.projectId);
    },
    [DEBOUNCED_SAVE_SLIDER_VALUES]: _.debounce(async ({ dispatch }, projectId) => {
      dispatch(SAVE_SLIDER_VALUES, projectId);
    }, 1000),
    [SAVE_SLIDER_VALUES]: async ({ state }, projectId) => {
      const projectToSave = state.rd_projects.find(project => project.id === projectId);

      const body = {
        rd_project_id: projectId,
        cost_breakdowns: projectToSave.cost_breakdowns.map(cost => ({
          subcost_type_key: cost.key,
          amount_percentage: cost.slider_props.value || 0
        }))
      };

      await put("/api/v1/project_grant_cost_breakdowns/upsert", body);
    },
    [SAVE_PROJECT_GRANT_ALLOCATION_VALUE]: async ({ dispatch, state }, payload) => {
      const { grantClaimAllocationId, projectId } = payload;
      const projectToSave = state.rd_projects.find(project => project.id === projectId);
      const grantAllocationToSave = projectToSave.grant_claim_allocations.find(grant => grant.id === grantClaimAllocationId);

      const body = { amount: grantAllocationToSave.amount };
      const response = await patch(`/api/v1/grant_claim_allocations/${payload.grantClaimAllocationId}`, body);

      if (projectToSave.cost_breakdowns.some(cost => cost.slider_props.value > 0)) {
        dispatch(SAVE_SLIDER_VALUES, projectId);
      }

      return response;
    },
    [MARK_AS_COMPLETE]: async ({ state }, claimPeriodSlug) => {
      const response = await post(`/api/v1/claim_periods/${claimPeriodSlug}/project_grant_allocations/complete`);

      return response;
    }
  },
  getters: {
    [GET_RD_PROJECTS]: state => state.rd_projects,
    [GET_VALIDATION_STATUS]: state => {
      let validationObject = {};

      state.rd_projects.every(project => {
        // invalid if 100% of the grant allocation has not been assigned
        if (project.allocatedPercentage !== 100) {
          validationObject = { isValid: false, message: `Unable to complete. For the '${project.title}' project you have not assigned 100% of the grant allocation` };
          return false;
        }

        // invalid if the grant allocation for a cost exceeds the value of the cost (when cost.error is true)
        const costWhichExceedsAllocation = project.cost_breakdowns.find(cost => cost.error);
        if (costWhichExceedsAllocation) {
          validationObject = { isValid: false, message: `Unable to complete. For the '${project.title}' project, the grant contribution for '${costWhichExceedsAllocation.name}' exceeds the cost's amount.` };
          return false;
        }

        // invalid if the grant allocation exceeds the value of grant
        const allocationWhichExceedsGrant = project.grant_claim_allocations.find(grantAllocation => decimalGreaterThan(parseFloat(grantAllocation.amount), parseFloat(grantAllocation.grant.amount), 2));
        if (allocationWhichExceedsGrant) {
          validationObject = {
            isValid: false, message: `Unable to complete. For the '${project.title}' project, the amount you've provided for ${allocationWhichExceedsGrant.grant_title_with_date} exceeds the amount issued by the grant.`
          };
          return false;
        }

        // invalid if the grant allocation value exceeds the total qualifying expenditure
        const combinedGrantAllocation = project.grant_claim_allocations.reduce((acc, next) => acc + parseFloat(next.amount), 0);
        const totalTqe = project.cost_breakdowns.reduce((acc, next) => acc + parseFloat(next.total_qualifying_expenditure || 0), 0);
        if (decimalGreaterThan(combinedGrantAllocation, totalTqe, 2)) {
          validationObject = { isValid: false, message: `Unable to complete. For the '${project.title}' project, the grant allocation exceeds the total qualifying expenditure for a project` };
          return false;
        }

        validationObject = { isValid: true, message: "Completion Successful!" };
        return true;
      });

      return validationObject;
    },
    [GET_IS_FULLY_ALLOCATED]: state => state.rd_projects.length > 0 && state.rd_projects.every(project => project.allocatedPercentage === 100),
    [GET_IS_SECTION_COMPLETE]: state => !!state.section_completed_at
  }
};
