// If you change any of the constants, you must also do so in section_status.rb
export const SECTION_STATUS = {
  IN_PROGRESS: 0,
  FINALISED: 1,
  IN_REVIEW: 2,
  UNRESOLVED_FEEDBACK: 3,
  SECTION_COMPLETE: 4,
  CLAIM_SUBMITTED: 5
};

export const SECTION_STATUS_LABELS = ["In Progress", "Sent For Review", "Review In Progress", "Feedback to Resolve", "Complete", "Claim Submitted"];
export const SECTION_STATUS_BADGE_OPTIONS = {
  // IN_PROGRESS
  0: {
    text: "In Progress",
    colour: "cta",
    icon: "spinner"
  },
  // FINALISED
  1: {
    text: "In Review",
    colour: "disabled",
    icon: "hourglass-start"
  },
  // IN_REVIEW
  2: {
    text: "In Review",
    colour: "disabled",
    icon: "hourglass-start"
  },
  // UNRESOLVED_FEEDBACK
  3: {
    text: "Unresolved Feedback",
    colour: "warning",
    icon: "comment-alt-lines"
  },
  // SECTION_COMPLETE
  4: {
    text: "Completed",
    colour: "confirm",
    icon: "check-circle",
    iconType: "fas"
  },
  // CLAIM_SUBMITTED
  5: {
    text: "Completed",
    colour: "confirm",
    icon: "check-circle",
    iconType: "fas"
  }
};

const COMMON_TOOLTIPS = {
  // IN_PROGRESS
  [SECTION_STATUS.IN_PROGRESS]: "This section is in progress",
  // FINALISED
  [SECTION_STATUS.FINALISED]: "This section has been sent to our claim team for review, if you have changes you want to make before they start the review you can unsubmit it",
  // IN_REVIEW
  [SECTION_STATUS.IN_REVIEW]: "This section is currently being reviewed by our claim team so you may not make any changes. We will notify you once the review has concluded.",
  // UNRESOLVED_FEEDBACK
  [SECTION_STATUS.UNRESOLVED_FEEDBACK]: "Our claim team have reviewed this section and have left some feedback for you to address, all the feedback left on the section must be resolved for the project to be complete",
  // SECTION_COMPLETE
  [SECTION_STATUS.SECTION_COMPLETE]: "All feedback has been resolved and this section is now complete and ready for the claim report",
  // CLAIM_SUBMITTED
  [SECTION_STATUS.CLAIM_SUBMITTED]: "This section's claim has already been submitted to HMRC. No changes can be made."
};

export const SECTION_STATUS_TOOLTIPS = {
  projects: {
    ...COMMON_TOOLTIPS,
    // UNRESOLVED_FEEDBACK
    3: "Our claim team have reviewed this section and have left some feedback for you to address, all the feedback left in the project details and/or challenges must be resolved for the whole section to be complete"
  },
  new_project: {
    ...COMMON_TOOLTIPS
  },
  costs_rd: {
    ...COMMON_TOOLTIPS,
    // UNRESOLVED_FEEDBACK
    3: "Our claim team have reviewed your costs and time spent on R&D and left some feedback for you to address, all the feedback must be resolved for the whole section to be complete",
    // SECTION_COMPLETE
    4: "All feedback has been resolved and your costs and time spent on R&D are now ready for the claim report"
  },
  costs: {
    ...COMMON_TOOLTIPS,
    // FINALISED
    1: "This section has been sent to our claim team for review, if you have changes you want to make before they start the review you can unsubmit it from the R&D Percentages page",
    // UNRESOLVED_FEEDBACK
    3: null,
    // SECTION_COMPLETE
    4: "Costs Collection has been completed"
  }
};
