<template>
  <span
    v-if="hasResolvedFeedback"
    class="tw-text-blue-300"
  >
    <Tippy :content="`This ${isNarrative ? 'question' : 'field'} has previously resolved feedback`">
      <i class="fas fa-comment-alt-check" />
    </Tippy>
  </span>
</template>

<script>
import { Tippy } from "vue-tippy";

import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    Tippy
  },
  props: {
    sectionType: {
      type: String,
      required: true,
      validator: value => {
        const validSections = [
          "CostsEmployee",
          "CostsSubcontractor",
          "CostsConsumable",
          "CostsOther",
          "Project",
          "Challenge",
          "EpwContract"
        ];
        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    colRef: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();

    const isNarrative = computed(() => ["Challenge", "Project"].includes(props.sectionType));

    const hasResolvedFeedback = computed(() => store.getters["feedbacks/GET_RESOLVED_FEEDBACKS_FOR_FIELD"](props.sectionType, props.sectionSlug, props.colRef) !== undefined);

    return {
      hasResolvedFeedback,
      isNarrative
    };
  }
};
</script>
