<template>
  <BarChart
    :chart-data="analyticsData"
    :options="options"
  />
</template>

<script>
import { BarChart } from "vue-chart-3";

export default {
  name: "Home",
  components: { BarChart },
  props: {
    analyticsData: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    },
    title: {
      required: true,
      type: String
    },
    xTitle: {
      required: false,
      type: String
    },
    legend: {
      required: false,
      type: Boolean
    },
    currency: {
      required: false,
      type: Boolean
    },
    percentage: {
      required: false,
      type: Boolean
    },
    noTooltips: {
      required: false,
      type: Boolean
    }
  },
  created() {
    this.options.plugins.title.text = this.title;
    if (this.legend === false) {
      this.options.plugins.legend.display = false;
    }
    if (this.currency) {
      this.options.scales.y.ticks = {
        callback(value, index, values) {
          if (parseInt(value) >= 1000) {
            return `£${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          } else {
            return `£${value}`;
          }
        }
      };
    }
    if (this.percentage) {
      this.options.scales.y.ticks = {
        callback(value, index, values) {
          return `${value}%`;
        }
      };
    }
    if (this.xTitle) {
      this.options.scales.x.title.text = this.xTitle;
    }
    if (this.noTooltips) {
      this.options.plugins.tooltip.enabled = false;
    }
  }
};
</script>
