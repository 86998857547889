<template>
  <span :id="id" ref="btn">
    <slot />
  </span>
</template>

<script>
import _ from "lodash";
import { ref, computed, watch, onMounted, inject } from "vue";
import { useTippy } from "vue-tippy";

export default {
  name: "Tooltip",
  props: {
    id: {
      type: String
    },
    content: {
      type: String
    },
    theme: {
      type: String
    },
    trigger: {
      type: String
    },
    delay: {
      type: Number
    },
    tooltipOptions: {
      type: Object
    },
    options: {
      type: Object
    },
    track: {
      type: Object
    },
    showImmediately: {
      type: Boolean
    },
    offset: {
      type: Array,
      default: () => [0, 10]
    }
  },
  setup(props, context) {
    const track = inject("track");
    const btn = ref();
    const executeTimer = ref(false);

    const content = computed(() => {
      if (context.slots.content) return _.isArray(context.slots.content()[0]?.children) ? context.slots.content()[0] : null;

      return props.content;
    });

    const trackOpen = _.debounce(() => {
      executeTimer.value = true;
    }, 600);

    function trackHide() {
      if (!executeTimer.value) {
        trackOpen.cancel();
      } else {
        track("Tooltip Hovered", {
          id: props.id,
          options: props.options,
          data: props.track
        });
        executeTimer.value = false;
      }
    }

    const options = computed(() => _.merge({
      content: content.value,
      theme: props.theme || context.slots.content ? "light" : "dark",
      trigger: props.trigger,
      offset: props.offset,
      hideOnClick: !(props.trigger),
      delay: [props.delay, null],
      allowHTML: !!context.slots.content,
      interactive: !!context.slots.content,
      onHide() {
        if (props.id) trackHide();
      },
      onShow() {
        if (props.id) trackOpen();
      }
    }, props.tooltipOptions ?? {}));

    const { show, hide, disable, enable, setContent, setProps } = useTippy(btn, options.value);
    const tippyInstance = computed(() => btn.value?._tippy);

    onMounted(() => {
      enable();
      if (!content.value) disable();
      if (props.showImmediately) show();
    });

    watch(() => [content.value, context.slots.content], () => {
      if (content.value) {
        enable();
        setContent(content.value);
      } else {
        disable();
      }
    });

    watch(() => options.value, val => {
      setProps(val);
    });

    return {
      btn,
      options,
      executeTimer,
      trackOpen,
      trackHide,
      context,
      show,
      hide,
      disable,
      enable,
      setContent,
      tippyInstance,
      content
    };
  }
};
</script>
