<template>
  <div :class="[loading ? 'tw-mt-3' : classes]" @mouseover="hovering = true" @mouseleave="hovering = false">
    <PulsingLoader v-if="loading == true" :size="size" />

    <div v-show="loading == false && errorState == true" class="tw-mt-0">
      <span class="tw-text-red-500 tw-text-xs">We could not fetch some content here.  <span class="tw-underline tw-cursor-pointer" @click="fetchContentSlot()">Click to retry...</span></span>
    </div>

    <div v-if="loading == false && errorState !== true" v-html="_marked(content)" />
    <div v-if="isAdmin && hovering" class="tw-text-xs tw-my-0">
      <a :href="`https://strapi.empowerrd.com/admin/plugins/content-manager/collectionType/application::content-slots.content-slots/${contentSlotId}`" target="_blank">Edit</a>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
import PulsingLoader from "./loading_pulse_lines.vue";

export default {
  components: { PulsingLoader },
  props: {
    contentSlotId: {
      type: Number,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: true,
      validator(value) {
        return value === "small" || value === "medium" || value === "large";
      }
    },
    classes: {
      type: String,
      required: false
    },
    replaceContent: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      content: "null",
      loading: true,
      errorState: false,
      hovering: false
    };
  },
  mounted() {
    this.fetchContentSlot();
  },
  methods: {
    _marked(input) {
      return marked(input);
    },
    fetchContentSlot() {
      this.errorState = false;
      this.loading = true;
      fetch(
        `/strapi/?query=${
          encodeURIComponent(
            `content-slots?&id=${
              this.contentSlotId}`
          )}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(json => this.processContent(json))
        .catch(error => this.processError(error));
    },
    processContent(data) {
      if (data[0].Content) {
        this.content = this.replaceContent ? this.replaceContent(data[0].Content) : data[0].Content;
        this.loading = false;
      } else {
        this.processError();
      }
    },
    processError() {
      this.loading = false;
      this.errorState = true;
    }
  }
};
</script>
