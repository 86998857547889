import _ from "lodash";
import { get, post, del } from "../../shared/http.js";

export const UPDATE_SUBCOSTS = "UPDATE_SUBCOSTS";
export const SET_SUBCOST = "SET_SUBCOST";
export const CREATE_SUBCOST = "CREATE_SUBCOST";
export const SET_SUBCOSTS = "SET_SUBCOSTS";
export const SET_COST = "SET_COST";
export const GET_SUBCOSTS = "GET_SUBCOSTS";
export const GET_COST = "GET_COST";
export const FETCH_AUTO_FEEDBACK = "FETCH_AUTO_FEEDBACK";
export const FETCH_HISTORICALS = "FETCH_HISTORICALS";
export const SET_HISTORICALS = "SET_HISTORICALS";
export const GET_HISTORICAL_PC_RD = "GET_HISTORICAL_PC_RD";
export const SET_AUTO_FEEDBACK = "SET_AUTO_FEEDBACK";
export const GET_AUTO_FEEDBACK = "GET_AUTO_FEEDBACK";
export const GET_GLOBAL_ERROR = "GET_GLOBAL_ERROR";
export const FETCH_SUBCOSTS = "FETCH_SUBCOSTS";
export const FETCH_CALCULATED_TOTALS = "FETCH_CALCULATED_TOTALS";
export const DELETE_SUBCOSTS = "DELETE_SUBCOSTS";
export const HANDLE_PROJECT_SUBCOSTS = "HANDLE_PROJECT_SUBCOSTS";

export const GET_SUBCOST_VALIDATION_ERRORS = "GET_SUBCOST_VALIDATION_ERRORS";
export const SET_SUBCOST_VALIDATION_ERRORS = "SET_SUBCOST_VALIDATION_ERRORS";
export const SET_GLOBAL_VALIDATION_ERROR = "SET_GLOBAL_VALIDATION_ERROR";

export const SET_LOADING = "SET_LOADING";
export const GET_LOADING = "GET_LOADING";

export const GET_CALCULATED_TOTAL = "GET_CALCULATED_TOTAL";
export const SET_CALCULATED_TOTALS = "SET_CALCULATED_TOTALS";
export const SET_ACTIVE_TAB_BY_NAME = "SET_ACTIVE_TAB_BY_NAME";
export const SET_ACTIVE_TAB_BY_INDEX = "SET_ACTIVE_TAB_BY_INDEX";
export const GET_ACTIVE_TAB = "GET_ACTIVE_TAB";
export const SET_UNSAVED_COST = "SET_UNSAVED_COST";
export const SET_SAVED_COST = "SET_SAVED_COST";
export const GET_UNSAVED_COSTS = "GET_UNSAVED_COSTS";
export const GET_ANY_UNSAVED_COSTS = "GET_ANY_UNSAVED_COSTS";
export const GET_ANY_UNSAVED_COSTS_FOR_TYPE = "GET_ANY_UNSAVED_COSTS_FOR_TYPE";
export const SET_SUBCOST_TYPES = "SET_SUBCOST_TYPES";
export const GET_SUBCOST_TYPES = "GET_SUBCOST_TYPES";
export const GET_ALL_SUBCOST_TYPES = "GET_ALL_SUBCOST_TYPES";
export const FETCH_QDA = "FETCH_QDA";
export const SET_QDA = "SET_QDA";

export const costs = {
  namespaced: true,
  state: {
    loading: true,
    subcostValidationErrors: {},
    subcostAutoFeedback: {},
    subcosts: {},
    cost: {},
    unsavedCosts: {},
    subcostHistoricals: [],
    calculatedTotals: {},
    globalError: null,
    activeTabIndex: 0,
    tabIndex: ["CostsEmployee", "CostsSubcontractor", "CostsConsumable", "CostsOther"],
    subcostTypes: {}
  },
  mutations: {
    [SET_LOADING]: (state, payload) => state.loading = payload,
    [SET_SUBCOST_VALIDATION_ERRORS]: (state, payload) => {
      state.subcostValidationErrors[payload.subCostType] = payload.response;
    },
    [SET_AUTO_FEEDBACK]: (state, payload) => {
      state.subcostAutoFeedback[payload.subCostType] = payload.data;
    },
    [SET_SUBCOSTS]: (state, payload) => {
      state.subcosts[payload.subCostType] = payload.data;
    },
    [SET_COST]: (state, payload) => state.cost = payload,
    [SET_HISTORICALS]: (state, payload) => {
      state.subcostHistoricals = payload;
    },
    [SET_GLOBAL_VALIDATION_ERROR]: (state, payload) => {
      state.globalError = payload;
    },
    [SET_ACTIVE_TAB_BY_NAME]: (state, payload) => {
      state.activeTabIndex = state.tabIndex.indexOf(payload) || 0;
    },
    [SET_ACTIVE_TAB_BY_INDEX]: (state, payload) => {
      state.activeTabIndex = payload;
    },
    [SET_CALCULATED_TOTALS]: (state, payload) => {
      state.calculatedTotals[payload.subCostType] = payload.data;
    },
    [SET_UNSAVED_COST]: (state, payload) => {
      state.unsavedCosts[payload.subCostType] = state.unsavedCosts[payload.subCostType] || [];
      state.unsavedCosts[payload.subCostType] = _.union(state.unsavedCosts[payload.subCostType], [payload.subcostSlug]);
    },
    [SET_SAVED_COST]: (state, payload) => {
      _.pull(state.unsavedCosts?.[payload.subCostType], payload.subcostSlug);
    },
    [SET_SUBCOST_TYPES]: (state, payload) => {
      state.subcostTypes[payload.subCostType] = payload.data;
    },
    [SET_QDA]: (state, payload) => {
      const index = state.subcosts[payload.costType].findIndex(subcost => subcost.slug == payload.costSlug);
      state.subcosts[payload.costType][index].is_direct_activity = payload.is_direct_activity;
    },
    [SET_SUBCOST]: (state, payload) => {
      const subcostToUpdate = state.subcosts[payload.subCostType].find(subcost => subcost.slug === payload.subCostSlug);
      subcostToUpdate[payload.col] = payload.value;
    }
  },
  actions: {
    [FETCH_SUBCOSTS]: async ({ state, commit }, payload) => {
      // @radar Sentry issue PLATFORM-ZS is firing undefined slugs, possibly a timing issue?
      if (payload.costSlug === undefined) {
        return;
      }

      const response = await get(`/api/costs/${payload.costSlug}/subcosts/${payload.subCostType}${payload?.rd ? "/?rd=true" : ""}`);

      if (response.error == true) {
        commit(SET_GLOBAL_VALIDATION_ERROR, "We could not load your costs, please reload the page");
        return;
      }

      let { subcosts } = response;
      const unsaved = state.unsavedCosts?.[payload.subCostType] || [];
      // If we are omitting unsaved costs, we need to only commit the saved ones
      if (unsaved?.length > 0) {
        const existingUnsaved = _.filter(state.subcosts[payload.subCostType], subcost => unsaved?.includes(subcost.slug));

        subcosts = _.map(subcosts, subcost => {
          if (unsaved?.includes(subcost.slug)) {
            return _.find(existingUnsaved, unsaved => unsaved.slug == subcost.slug);
          } else {
            return subcost;
          }
        });
      }

      await commit(SET_SUBCOSTS, { subCostType: payload.subCostType, data: subcosts });

      if (response.subcost_types.length > 0) {
        await commit(SET_SUBCOST_TYPES, { subCostType: payload.subCostType, data: response.subcost_types });
      }

      if (_.isEmpty(state.cost)) {
        const { cost } = response;
        cost.subcosts = state.subcosts;

        commit(SET_COST, cost);
      }
    },
    [FETCH_CALCULATED_TOTALS]: async ({ commit }, payload) => {
      get(`/api/costs/${payload.costSlug}/calculated_subcosts/${payload.subCostType}`).then(data => {
        if (data.error == true) {
          commit(SET_GLOBAL_VALIDATION_ERROR, "We could not load your costs, please reload the page");
          return;
        }
        commit(SET_CALCULATED_TOTALS, { subCostType: payload.subCostType, data });
      });
    },
    [FETCH_HISTORICALS]: async ({ commit }, payload) => {
      get(`/api/costs/${payload.costSlug}/historical`).then(data => {
        commit(SET_HISTORICALS, data);
      });
    },
    [FETCH_AUTO_FEEDBACK]: async ({ commit }, payload) => {
      // @todo be good to be able to do this incrementally and just update the local store and merge in changes
      // we could do this by passing in array of sub cost slugs we're interested in
      get(`/api/costs/${payload.costSlug}/auto_feedback_subcosts/${payload.subCostType}`).then(data => {
        if (data.error == true) {
          commit(SET_GLOBAL_VALIDATION_ERROR, "We could not load your costs, please reload the page");
          return;
        }

        commit(SET_AUTO_FEEDBACK, { subCostType: payload.subCostType, data });
      });
    },
    [UPDATE_SUBCOSTS]: async ({ commit, dispatch }, payload) => {
      if (payload.error == true) {
        commit(SET_GLOBAL_VALIDATION_ERROR, "We could not save that change, please reload the page");
        return;
      }

      dispatch(FETCH_QDA, { costSlug: payload.subCostSlug, costType: payload.subCostType });

      if (payload.col !== "rd_projects") commit("SET_SUBCOST", payload);

      commit(SET_GLOBAL_VALIDATION_ERROR, null);

      dispatch(FETCH_AUTO_FEEDBACK, payload);
      if (payload.isEmpowerrd) {
        dispatch(FETCH_CALCULATED_TOTALS, payload);
      }
    },
    FETCH_QDA: async ({ commit }, payload) => {
      get(`/api/v1/subcosts/${payload.costType}/${payload.costSlug}`).then(data => {
        commit(SET_QDA, {
          costType: payload.costType,
          costSlug: payload.costSlug,
          is_direct_activity: data.is_direct_activity
        });
      });
    },
    [CREATE_SUBCOST]: async ({ commit, dispatch }, payload) => {
      post(`/api/costs/${payload.costSlug}/subcosts/${payload.subCostType}`, {}).then(data => {
        if (data.error == true) {
          commit(SET_GLOBAL_VALIDATION_ERROR, "We could not create your cost, please reload the page");
          return;
        }
        dispatch(FETCH_SUBCOSTS, data);
      });

      return "success";
    },
    [DELETE_SUBCOSTS]: async ({ commit, dispatch }, payload) => {
      del(`/api/costs/${payload.costSlug}/subcosts/${payload.subCostType}`, { subcost_slugs: payload.subcostSlugs }).then(data => {
        if (data.error == true) {
          commit(SET_GLOBAL_VALIDATION_ERROR, "We could not delete that cost, please relaod the page");
          return;
        }

        dispatch(FETCH_SUBCOSTS, data);
      });
    },
    [HANDLE_PROJECT_SUBCOSTS]: async ({ dispatch, commit }, payload) => {
      const slugsToDelete = payload.initialVal.filter(slug => !payload.rdProjectSlugs.includes(slug));
      const slugsToAdd = payload.rdProjectSlugs.filter(slug => !payload.initialVal.includes(slug));

      if (slugsToDelete.length === 0 && slugsToAdd.length === 0) { return; }

      if (slugsToAdd.length > 0) {
        const response = await post("/api/v1/project_subcosts", {
          project_cost_breakdown_id: payload.projectCostBreakdownSlug,
          rd_project_ids: slugsToAdd,
          subcost_type: payload.subCostType,
          subcost_id: payload.subcostSlug
        });

        if (response.error) {
          commit(SET_GLOBAL_VALIDATION_ERROR, "We could not link the selected R&D projects, please reload the page");
        }
      }

      if (slugsToDelete.length > 0) {
        const response = await del("/api/v1/project_subcosts/delete", {
          project_cost_breakdown_id: payload.projectCostBreakdownSlug,
          rd_project_ids: slugsToDelete,
          subcost_type: payload.subCostType,
          subcost_id: payload.subcostSlug
        });

        if (response.error) {
          commit(SET_GLOBAL_VALIDATION_ERROR, "We could not delete these associations, please reload the page");
        }
      }

      dispatch(FETCH_SUBCOSTS, payload);

      return;
    }
  },
  getters: {
    [GET_LOADING]: state => state.loading,
    [GET_UNSAVED_COSTS]: state => state.unsavedCosts,
    [GET_ANY_UNSAVED_COSTS]: state => {
      const allUnsaved = _.chain(state.unsavedCosts)
        .values()
        .flatten()
        .value();
      return allUnsaved.length > 0;
    },
    [GET_ANY_UNSAVED_COSTS_FOR_TYPE]: state => subCostType => state.unsavedCosts[subCostType]?.length > 0,
    [GET_SUBCOST_VALIDATION_ERRORS]: state => subCostType => _.get(state.subcostValidationErrors, subCostType),
    [GET_AUTO_FEEDBACK]: state => subCostType => _.get(state.subcostAutoFeedback, subCostType),
    [GET_SUBCOSTS]: state => subCostType => _.get(state.subcosts, subCostType),
    [GET_COST]: state => state.cost,
    [GET_HISTORICAL_PC_RD]: state => (subCostType, subCostSlug) => _.get(_.first(_.filter(state.subcostHistoricals, o => o.subcost_slug == subCostSlug && o.subcost_type == subCostType)), "pc_rd_previous"),
    [GET_CALCULATED_TOTAL]: state => (subCostType, subCostSlug) => _.get(state.calculatedTotals[subCostType], subCostSlug),
    [GET_GLOBAL_ERROR]: state => state.globalError,
    [GET_ACTIVE_TAB]: state => state.activeTabIndex,
    [GET_SUBCOST_TYPES]: state => subCostType => _.chain(state.subcostTypes)
              .get(subCostType)
              .filter("valid")
              .value(),
    [GET_ALL_SUBCOST_TYPES]: state => subCostType => _.get(state.subcostTypes, subCostType)
  }
};
