export const SORT = {
  STRING: "string",
  NUMBER: "number",
  CUSTOM: "custom"
};

export const DIRECTION = {
  ASCENDING: 1,
  DESCENDING: -1
};

export const ZERO_VALUES = {
  STRING: "",
  NUMBER: 0
};

export function dynamicSort(
  collection,
  objName,
  property,
  sortOrder,
  sortType,
  sortFunction = null
) {
  let sortFunc = null;

  if (sortType === SORT.STRING) {
    sortFunc = sortString(objName, property);
  } else if (sortType === SORT.NUMBER) {
    sortFunc = sortNumber(objName, property);
  } else if (sortType === SORT.CUSTOM) {
    sortFunc = sortCustom(sortFunction);
  }

  if (sortOrder) {
    return collection?.sort(sortFunc);
  } else {
    return collection?.sort(sortFunc).reverse();
  }
}

function getValue(element, objName, property, zero_value = null) {
  if (objName === undefined || objName === null) {
    return element[property];
  } else {
    return element[objName] ? element[objName][property] : zero_value;
  }
}

function sortNumber(objName, property) {
  return function (a, b) {
    const valA = getValue(a, objName, property, ZERO_VALUES.NUMBER);
    const valB = getValue(b, objName, property, ZERO_VALUES.NUMBER);

    return valA < valB ? -1 : valA > valB ? 1 : 0;
  };
}

function sortString(objName, property) {
  return function (a, b) {
    const valA = getValue(a, objName, property, ZERO_VALUES.STRING)?.toLowerCase();
    const valB = getValue(b, objName, property, ZERO_VALUES.STRING)?.toLowerCase();

    return valA < valB ? -1 : valA > valB ? 1 : 0;
  };
}

function sortCustom(sortFunction) {
  return (a, b) => (sortFunction(a) < sortFunction(b)
    ? -1
    : sortFunction(a) > sortFunction(b)
      ? 1
      : 0);
}
