export const stepKeys = [
  "project_setup",
  "project_validation",
  "project_expenditure_estimate",
  "finalise_selection"
];

export const stepTitles = {
  project_setup: "Setup Project Selection",
  project_validation: "R&D Project Validation",
  project_expenditure_estimate: "Project Expenditure Estimate",
  finalise_selection: "Finalise Project Selection"
};

export const stepComponents = {
  project_setup: "ProjectSetup",
  project_validation: "ProjectValidation",
  project_expenditure_estimate: "ProjectExpenditureEstimate",
  finalise_selection: "FinaliseSelection"
};

export const stepSortOptions = {
  project_validation: {
    sort: ["qualifying?", "created_at"],
    order: ["desc", "asc"]
  },
  project_expenditure_estimate: {
    sort: ["qualifying?", "created_at"],
    order: ["desc", "desc", "asc"]
  },
  finalise_selection: {
    sort: ["qualifying?", "percent_expenditure", "selected_to_detail"],
    order: ["desc", "desc", "desc"]
  }
};

export const project_selection_cols = {
  project_validation: [
    {
      label: null,
      width: "tw-w-52",
      component: "RdProjectActions",
      headerComponent: "AddRdProject"
    }
  ],
  project_expenditure_estimate: [
    {
      label: "Estimated Expenditure Percent",
      field: "percent_expenditure",
      knowledge_centre: "",
      component: "ProjectExpenditureSlider",
      headerComponent: "ProjectExpendituresTotal",
      colClasses: "tw-py-7"
    }
  ],
  finalise_selection: [
    {
      label: "Project Requirement",
      knowledge_centre: "",
      width: "md:tw-w-1/2",
      component: "ProjectRequirement",
      reviewer: false
    },
    {
      label: "Choose Project",
      knowledge_centre: "",
      width: "md:tw-w-1/2",
      component: "ProjectSelector"
    }
  ]
};
