<template>
  <div
    :class="[
      `tw-rounded-lg tw-transition-bg tw-duration-300 tw-ease-in-out tw-delay-150 tw-space-y-2 ${background}`
    ]"
  >
    <div>
      <div v-if="showDocumentTypes">
        <div class="tw-font-semibold tw-mb-2">
          Select a document type to add
        </div>
        <select v-model="selectedDocumentTypeId" class="tw-form-select tw-w-full tw-border-solid m:tw-text-sm sm:tw-leading-5 tw-mb-2">
          <template v-for="documentType in allowedDocumentTypes" :key="documentType.id">
            <option :value="documentType.id">
              {{ documentType.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="tw-text-md">
        <div v-if="hasFile" class="tw-flex tw-gap-x-2 tw-items-center">
          <a
            :class="['tw-text-ellipsis tw-overflow-hidden tw-inline-flex tw-items-center tw-gap-x-2', textColor, editable || hasFeedback ? 'tw-font-medium' : '']"
            :href="fileObject.url"
            target="_blank"
          >
            <span>{{ fileName }}</span>
            <i v-if="!hasFeedback" class="fas fa-file-alt" />
          </a>
          <slot name="feedback" />
        </div>

        <div v-if="!hasFile" class="tw-italics">
          No file added yet <span v-show="inputDisabled">, please select a document type first</span>
        </div>
      </div>
    </div>

    <div v-show="editable">
      <div>
        <input
          type="file"
          :accept="accept"
          capture
          :disabled="inputDisabled"
          @change="onFileChanged($event)"
        >
      </div>
      <FadeTransition>
        <div v-show="message != ''" class="tw-inline-flex tw-items-center tw-gap-x-2 tw-font-medium tw-mt-2">
          <i class="fas fa-check-circle tw-text-green-500" />
          <span>{{ message }}</span>
        </div>
      </FadeTransition>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { documentCsrfToken } from "../../shared/http.js";
import { truncate } from "../../shared/helpers.js";

import FadeTransition from "../components/transitions/fade.vue";

export default defineComponent({
  name: "FileUpload",
  components: {
    FadeTransition
  },
  props: {
    accept: {
      type: String,
      required: false,
      default: ".doc,.docx,.pdf,.xls,.xlsx"
    },
    httpMethod: {
      type: String,
      default: "POST"
    },
    url: {
      type: String,
      required: true
    },
    existingFile: {
      type: Object,
      required: false,
      default: {}
    },
    showDocumentTypes: {
      type: Boolean,
      default: false
    },
    allowedDocumentTypes: {
      type: Array,
      default: [],
      required: false
    },
    additionalData: {
      type: Object,
      default: {},
      required: false
    },
    isNewFile: {
      type: Boolean,
      default: false
    },
    dataModel: {
      type: String,
      default: "document",
      required: false
    },
    fileString: {
      type: String,
      default: "file",
      required: false
    },
    feedback: {
      type: Object,
      required: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ["fileUploaded"],
  setup(props, context) {
    const fileObject = reactive(props.existingFile);
    const message = ref("");
    const file = ref();
    const selectedDocumentTypeId = ref(null);

    function onFileChanged($event) {
      const { target } = $event;

      if (target && target.files) {
        file.value = target.files[0];
        const formData = new FormData();

        formData.append(`${props.dataModel}[${props.fileString}]`, file.value);
        formData.append("_upload_type", "js");

        if (props.showDocumentTypes) formData.append(`${props.dataModel}[document_type_id]`, selectedDocumentTypeId.value);

        for (const [key, value] of Object.entries(props.additionalData)) formData.append(`${props.dataModel}[${key}]`, value);

        fetch(props.url, {
          method: props.httpMethod,
          body: formData,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": documentCsrfToken()
          }
        })
          .then(response => response.json())
          .then(data => handleResponseData(data));
      }
    }

    function handleResponseData(json) {
      context.emit("fileUploaded");
      if (json?.file) {
        fileObject.name = file.value?.name;
        fileObject.url = json?.file?.url;
      }
      if (props.isNewFile) message.value = "New document added";
    }

    const fileName = computed(() => truncate(fileObject?.name, 28));

    const hasFile = computed(() => fileObject?.url && fileObject.url !== "");

    const hasFeedback = computed(() => props.feedback !== undefined && props.feedback !== null);

    const background = computed(() => {
      if (hasFeedback.value) return `${props.editable ? "tw-p-4" : "tw-px-2 tw-py-1.5"} ${props.feedback?.resolve_activity_type === "Review/Accept" ? "tw-bg-amber-50" : "tw-bg-rose-50"}`;
      if (!props.editable) return "";

      return `tw-p-4 ${hasFile.value ? "tw-bg-green-100" : "tw-bg-yellow-50"}`;
    });

    const textColor = computed(() => {
      if (hasFeedback.value) return props.feedback?.resolve_activity_type === "Review/Accept" ? "hover:tw-text-yellow-500 tw-text-yellow-400" : "hover:tw-text-rose-500 tw-text-rose-400";
    });

    const inputDisabled = computed(() => {
      if (props.showDocumentTypes == true && selectedDocumentTypeId.value == null) return true;

      return false;
    });

    return {
      onFileChanged,
      fileObject,
      message,
      fileName,
      hasFile,
      background,
      inputDisabled,
      textColor,
      hasFeedback,
      truncate,
      selectedDocumentTypeId
    };
  }
});
</script>
