const en = {
  integration: {
    accounting: {
      nominalInclusion: {
        included: "This account code has been selected at a previous step to likely be qualifying spend",
        excluded: "This account code has not been selected and may include non-qualifying spend"
      }
    }
  }

};

export { en };
