<template>
  <div v-show="show">
    <div
      id="modal-id"
      class="tw-z-50 tw-fixed tw-inset-0 tw-outline-none focus:tw-outline-none"
    >
      <div class="tw-w-4/5 md:tw-w-3/5 tw-my-12 tw-mx-auto">
        <!--content-->
        <div
          class="tw-w-full tw-rounded-lg tw-shadow-lg tw-flex tw-flex-col tw-bg-white tw-outline-none focus:tw-outline-none tw-px-8 tw-py-6 tw-divide-y tw-divide-gray-200 tw-relative"
        >
          <!--header-->
          <div class="tw-mb-2">
            <h4 class="tw-text-center tw-text-lg tw-font-semibold">
              <div
                v-if="loading"
                class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4"
              />
              <span v-else class="tw-px-2" v-text="title" />
            </h4>
            <div
              class="tw-cursor-pointer tw-absolute tw-top-0 tw-right-0 tw-mr-4 tw-mt-3 hover:tw-text-red-600"
              @click="close()"
            >
              <i class="far fa-times fa-lg" />
            </div>
          </div>
          <!--body-->
          <div class="tw-h-96 tw-overflow-y-scroll">
            <div v-show="errorState">
              <p>
                Unfortunately we had a problem loading that history; an error
                has been logged and the team will be alerted
              </p>
            </div>

            <div v-show="loading && !errorState" class="tw-py-3 tw-px-1.5">
              <div
                class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4"
              />
              <div
                class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4"
              />
              <div
                class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4"
              />
            </div>

            <div v-show="!loading" class="tw-py-3 tw-px-1.5">
              <div
                v-if="
                  results.messages === undefined || results.messages.length > 0
                "
              >
                <banner-component
                  type="warning"
                  class="tw-flex tw-flex-col tw-gap-y-1 tw-mb-3 tw-text-sm"
                >
                  <template v-for="message in results.messages" :key="message">
                    <span>{{ message }}</span>
                  </template>
                </banner-component>
              </div>

              <div v-if="results.entries.length > 0">
                <ul
                  role="list"
                  class="tw-list-none tw-flex tw-flex-col tw-gap-y-2"
                >
                  <li
                    v-for="entry in results.entries"
                    :key="entry.index"
                    class="tw-w-full"
                  >
                    <template v-if="entry.type == 'audit'">
                      <div class="tw-flex tw-flex-col tw-gap-y-0.5">
                        <span
                          v-if="entry.user !== undefined"
                          class="tw-text-sm tw-font-semibold"
                          v-text="
                            `${
                              entry.user.is_empowerrd == true
                                ? 'EmpowerRD'
                                : entry.user.first_name
                            }, ${formatTimeDistance(entry.created_at, {
                              addSuffix: true,
                            })}`
                          "
                        />
                        <template
                          v-for="changes in entry.audited_changes"
                          :key="changes"
                        >
                          <div
                            class="tw-flex tw-flex-col tw-text-xs tw-prose tw-prose-xs"
                          >
                            <span
                              v-if="entry.title"
                              class="tw-font-medium tw-font-mono tw-text-light-grey-blue"
                            >{{ entry.auditable_type }} - {{ entry.title }}</span>
                            <span
                              class="tw-font-medium tw-font-mono tw-text-light-grey-blue"
                              v-text="changes.label"
                            />
                            <span
                              v-html="changes.value || 'Null'"
                            />
                          </div>
                        </template>
                      </div>
                    </template>

                    <template v-else-if="entry.type == 'feedback'">
                      <div class="tw-flex tw-flex-col tw-gap-y-0.5">
                        <div class="tw-flex tw-items-center tw-justify-between">
                          <span
                            v-if="entry.user !== (undefined || null)"
                            class="tw-text-sm tw-font-semibold"
                            v-text="
                              `${
                                entry.user.is_empowerrd == true
                                  ? 'EmpowerRD'
                                  : `${entry.user.first_name} ${entry.user.last_name}`
                              }, ${formatTimeDistance(entry.created_at, {
                                addSuffix: true,
                              })}`
                            "
                          />
                          <div v-show="entry.resolved === true">
                            <span
                              class="tw-inline-flex tw-items-center tw-px-2 tw-py-0.5 tw-rounded-md tw-text-xs tw-font-medium tw-bg-green-100 tw-text-green-800"
                            >
                              <i class="fas fa-check-circle tw-mr-2" />
                              Resolved
                            </span>
                          </div>

                          <div v-show="entry.resolved === false">
                            <span
                              class="tw-inline-flex tw-items-center tw-px-2 tw-py-0.5 tw-rounded-md tw-text-xs tw-font-medium tw-bg-red-100 tw-text-red-800"
                            >
                              <i class="fas fa-times-circle tw-mr-2" />
                              Unresolved
                            </span>
                          </div>
                        </div>
                        <div class="tw-flex tw-flex-col tw-text-xs tw-prose tw-prose-xs">
                          <span
                            v-if="entry.title"
                            class="tw-font-medium tw-text-xs tw-font-mono tw-text-light-grey-blue"
                          >{{ entry.feedbackable_type }} - {{ entry.title }}</span>
                          <span
                            class="tw-font-medium tw-font-mono tw-text-light-grey-blue"
                            v-text="entry.field_label"
                          />
                          <div
                            class="tw-text-xs"
                            v-html="`${entry.feedback}`"
                          />
                        </div>
                      </div>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--footer-->
          <div />
        </div>
      </div>
    </div>
    <!--<div class="tw-hidden tw-opacity-25 tw-fixed tw-inset-0 tw-z-40 tw-bg-black" id="modal-id-backdrop"></div>-->
  </div>
</template>

<script>
import { documentCsrfToken } from "../../../shared/http.js";

const datefns = require("date-fns");

export default {
  data() {
    return {
      loading: true,
      show: false,
      errorState: false,
      results: [],
      title: ""
    };
  },
  created() {
    window.addEventListener("triggerquestionaudit", this.trigger);
  },
  methods: {
    close() {
      this.results = { entries: [], messages: [] };
      this.tittle = "";
      this.show = false;
      this.errorState = false;
    },
    trigger(event) {
      const { detail } = event;
      this.show = true;
      this.loading = true;
      fetch("/api/feedbacks/audit_log_with_feedback", {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": documentCsrfToken()
        },
        method: "POST",
        body: JSON.stringify({
          auditable_type: detail.model,
          auditable_id: detail.slug,
          questions: detail.questions
        })
      })
        .then(response => response.json())
        .then(data => this.processResponse(data))
        .catch(error => this.processError(error));
    },
    processError(_) {
      this.errorState = true;
      // @radar sentry trap
    },
    processResponse(data) {
      this.loading = false;
      this.results = data.results;
      this.title = data.title;
    },
    formatTimeDistance(start, opts) {
      return datefns.formatDistance(datefns.parseJSON(start), new Date(), opts);
    }
  }
};
</script>

<style scoped>

:deep(p) {
  margin: 0px !important;
}

</style>
