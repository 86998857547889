<template>
  <tooltip ref="popUpTooltip" trigger="manual">
    <slot />
    <template #content>
      <div class="tw-relative tw-p-4">
        <slot name="popUpContent" />
        <span class="tw-absolute tw-cursor-pointer tw--top-0.5 tw--right-1 tw-rounded-md tw-p-1" @click="dismiss">
          <i class="fas fa-times" />
        </span>
      </div>
    </template>
  </tooltip>
</template>

<script>
import _ from "lodash";
import { ref } from "vue";

export default {
  setup() {
    const popUpTooltip = ref();
    const showPopUp = ref(false);

    function triggerShow() {
      showPopUp.value = true;
      popUpTooltip.value.show();
    }

    function dismiss() {
      showPopUp.value = false;
      popUpTooltip.value.hide();
    }

    return {
      popUpTooltip,
      showPopUp,
      triggerShow,
      dismiss
    };
  }
};
</script>
