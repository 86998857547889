<template>
  <div v-if="loaded && show">
    <Tippy
      v-if="show"
      :interactive="true"
      :allow-h-t-m-l="true"
      theme="light"
      :on-show="hoverIcon"
    >
      <slot name="toggle">
        <div class="tw-text-xs tw-text-gray-400 tw-flex tw-items-center tw-gap-x-1 tw-cursor-help">
          <div><i :class="`far fa-${upOrDown}`" /></div>
          <div>{{ Math.round(parseFloat(getHistoricalPcRd)) }}%</div>
        </div>
      </slot>
      <template #content="{ hide }">
        <slot :hide="hide">
          <div class="tw-font-medium tw-text-gray-400 tw-tracking-wide">
            Previous Claim
          </div>
          <div class="tw-text-gray-600">
            This cost was allocated at {{ Math.round(parseFloat(getHistoricalPcRd)) }}%
          </div>
        </slot>
      </template>
    </Tippy>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import { Tippy } from "vue-tippy";

export default {
  components: { Tippy },
  props: {
    sectionSlug: {
      type: String,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    current: {
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const track = inject("track");
    const store = useStore();
    const getHistoricalPcRd = computed(() => store.getters["costs/GET_HISTORICAL_PC_RD"](props.sectionType, props.sectionSlug));

    const loaded = computed(() => store.getters["costs/GET_HISTORICAL_PC_RD"](props.sectionType, props.sectionSlug) !== undefined);

    const show = computed(() => store.getters["costs/GET_HISTORICAL_PC_RD"](props.sectionType, props.sectionSlug) !== null);

    const upOrDown = computed(() => {
      if (parseFloat(store.getters["costs/GET_HISTORICAL_PC_RD"](props.sectionType, props.sectionSlug)) == 0) {
        return;
      }

      if (parseFloat(store.getters["costs/GET_HISTORICAL_PC_RD"](props.sectionType, props.sectionSlug)) == parseFloat(props.current)) {
        return "equals";
      }

      return store.getters["costs/GET_HISTORICAL_PC_RD"](props.sectionType, props.sectionSlug) > parseFloat(props.current) ? "long-arrow-down" : "long-arrow-up";
    });

    const hoverIcon = () => {
      track("Historical Cost Icon Hovered", { sectionType: props.sectionType, sectionSlug: props.sectionSlug, colRef: "pc_rd" });
    };

    return {
      getHistoricalPcRd,
      show,
      upOrDown,
      hoverIcon,
      loaded
    };
  }
};

</script>
