<script>
export default {
  props: {
    slug: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    questions: {
      type: Array,
      required: true
    }

  },
  data() {
    return {
    };
  },
  created() {

  },
  methods: {
    trigger() {
      window.dispatchEvent(new CustomEvent("triggerquestionaudit", { detail: { model: this.model, slug: this.slug, questions: this.questions } }));
    }
  }
};
</script>
