<template>
  <div v-if="showCompanyAccountPeriods">
    <DataTable
      :data="configuredData"
      :columns="tableColumns"
      table-header="Period Of Accounts"
      :paginator-rows="5"
      alt-styling
      :error-message="errorMessage"
    />

    <PeriodOfAccountModal :modal-id="modalId" :period-of-account="modalPeriodOfAccount" />
  </div>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { get } from "@shared/http.js";
import { formatDate } from "@shared/helpers.js";
import DataTable from "@generic/data_table.vue";
import IconGroup from "@generic/icon_group.vue";
import useEventsBus from "@shared/eventBus.js";
import PeriodOfAccountModal from "./period_of_account_modal.vue";

const props = defineProps({
  companySlug: {
    type: String
  }
});

const periodOfAccounts = ref([]);
const errorMessage = ref("");
const modalId = "periodOfAccountModal";
const modalPeriodOfAccount = ref({});
const { emit } = useEventsBus();

const store = useStore();
const showCompanyAccountPeriods = computed(() => store.getters["feature_flags/GET_FEATURE_FLAG"]("show_company_account_periods"));

watch(() => showCompanyAccountPeriods.value, () => {
  if (showCompanyAccountPeriods.value) getCompanyPeriodOfAccounts();
}, { immediate: true });

async function getCompanyPeriodOfAccounts() {
  const res = await get(`/api/period_of_accounts?company_slug=${props.companySlug}`);

  if (res.error) {
    errorMessage.value = "There was an error fetching the period of accounts. Please try again.";
  } else {
    periodOfAccounts.value = res;
  }
}

const configuredData = computed(() => periodOfAccounts.value.map(data => ({
  ...data,
  closed: { icons: [{ type: data.closed }] },
  reference_date_changed: { icons: getReferenceDataChangedIcons(data) },
  claim_periods: data.claim_periods.map(period => period.title_short).join(", ")
})));

function getReferenceDataChangedIcons(dataRow) {
  const icons = [{ type: dataRow.reference_date_changed }];

  if (dataRow.reference_date_changed) {
    icons.push({
      type: "engine-warning",
      onClick: () => onIconWarningClick(dataRow),
      tooltip: "This period of account has been changed. You may need to manually change your Claim Period dates. Click for further details.",
      ariaLabel: "Toggle period of account warning modal"
    });
  }

  return icons;
}

function onIconWarningClick(dataRow) {
  modalPeriodOfAccount.value = dataRow;
  emit("toggleModal", modalId);
}

const tableColumns = [
  {
    header: "Year",
    field: "year"
  },
  {
    header: "Closed",
    field: "closed",
    component: IconGroup
  },
  {
    header: "Start Date",
    field: "start_date",
    formatter: formatDate,
    sortable: true
  },
  {
    header: "End Date",
    field: "end_date",
    formatter: formatDate,
    sortable: true
  },
  {
    header: "Filed Date",
    field: "filed_date",
    formatter: formatDate,
    sortable: true
  },
  {
    header: "Filing Due Date",
    field: "filing_due_date",
    formatter: formatDate,
    sortable: true
  },
  {
    header: "Reference Date Changed",
    field: "reference_date_changed",
    component: IconGroup
  },
  {
    header: "Filing Link",
    field: "description"
  },
  {
    header: "Claim Period(s)",
    field: "claim_periods"
  }
];
</script>
