<template>
  <Tab v-slot="{ selected }" class="mr-3">
    <button
      tab-key="costsemployee-tab"
      class="tw-inline-flex tw-gap-2 tw-items-center"
      :class="['tw-transition', selected ? theme.tabs.large_selected : theme.tabs.large]"
    >
      <slot />

      <tooltip v-if="!valid" content="The cost to project assignments in this section are incomplete">
        <i class="fas fa-exclamation-triangle tw-text-warning-yellow" />
      </tooltip>
    </button>
  </Tab>
</template>

<script>
import { Tab } from "@headlessui/vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { css } from "../../../shared/theme.js";

export default {
  name: "CostLinkingTab",
  components: {
    Tab
  },
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const { key } = props.tab;

    const valid = computed(() => store.getters["project_cost_breakdown/GET_SUBCOSTS_VALID"](key));
    return {
      theme: css,
      valid
    };
  }
};
</script>
