<template>
  <div class="tw-flex">
    <tooltip :content="tooltip">
      <div class="tw-flex tw-items-center tw-gap-x-2">
        <i :class="[iconClassForRequirementCol]" />
        <span class="tw-text-gray-900">{{ textForRequirementCol }}</span>
      </div>
    </tooltip>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  props: {
    slug: {
      required: true,
      type: String
    },
    editable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const rdProject = computed(() => store.getters["project_selection/GET_RD_PROJECT_BY_SLUG"](props.slug));

    const selectedToDetail = computed(() => rdProject.value?.selected_to_detail);
    const proposed = computed(() => store.getters["project_selection/GET_RD_PROJECT_PROPOSED"](props.slug));
    const required = computed(() => store.getters["project_selection/GET_RD_PROJECT_REQUIRED"](props.slug));
    const invalid = computed(() => store.getters["project_selection/GET_RD_PROJECT_INVALID"](props.slug));
    const proposedReq = computed(() => store.getters["project_selection/GET_NUMBER_OF_PROPOSED_PROJECTS_REQUIRED"]);

    const iconClassForRequirementCol = computed(() => {
      if ((selectedToDetail.value && !props.editable) || required.value || proposed.value) return "fas fa-check-circle tw-pt-0.5 tw-text-confirm-green";
      if (invalid.value) return "far fa-ban tw-pt-0.5 tw-text-error-red";

      return "fas fa-file-alt tw-text-cta-blue";
    });

    const textForRequirementCol = computed(() => {
      if (selectedToDetail.value && !props.editable) return "Selected to Write-up";
      if (required.value) return "Write-up Required";
      if (proposed.value) return "Write-up Proposed";
      if (invalid.value) return "Not a valid R&D Project";

      return "Write-up Not Necessary";
    });

    const tooltip = computed(() => {
      if (selectedToDetail.value && !props.editable) return "You've selected this project to write up as part of your claim, see Projects section to detail";
      if (proposed.value) return `Since you have projects with similar levels of R&D intensity you can choose between which of the proposed projects you'd like to detail. You will need to select ${proposedReq.value} of the proposed projects to proceed`;
      if (required.value) return "Based on your answers in previous steps, this R&D Project will need to be written up as part of your claim";
      if (invalid.value) return "This project does not qualify as R&D and therefore is not eligible to be a part of your claim";

      return "This project is part of your R&D claim but a write up is not required due to the number of projects you have described";
    });

    return {
      iconClassForRequirementCol,
      textForRequirementCol,
      rdProject,
      tooltip
    };
  }
};
</script>
