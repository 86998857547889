<template>
  <div class="tw-w-full">
    <div v-if="editable && !isReviewer">
      <div :class="['tw-max-w-lg tw-flex tw-gap-3', basicForm ? null : 'tw-flex-col']">
        <template v-for="option in options(`${sectionType}:${sectionSlug}:${field.label}`)" :key="option[0]">
          <div class="tw-flex tw-items-start tw-gap-x-2">
            <div class="tw-flex tw-items-center tw-h-5">
              <input
                :id="fieldId(option)"
                v-model="field.value"
                :name="`${field.label}`"
                :value="`${option[0]}`"
                type="radio"
                class="
                  tw-form-radio
                  tw-border-1
                  tw-text-deep-blue
                  tw-border-solid
                  focus:tw-ring-deep-blue
                "
              >
            </div>
            <label
              :for="fieldId(option)"
              class="tw-font-medium tw-text-sm tw-text-gray-700 tw-mb-0"
            >
              {{ option[1] }}
            </label>
          </div>
        </template>
      </div>
    </div>
    <div v-else>
      <div class="tw-text-gray-500 tw-text-sm">
        {{ fieldReadOnly || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { GET_MULTI_SELECT_OPTIONS } from "../../../../vuex-store/modules/sections.js";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant", "RdProject"];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isReviewer: {
      type: Boolean,
      required: true
    },
    basicForm: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      options: `sections/${GET_MULTI_SELECT_OPTIONS}`
    }),
    fieldReadOnly() {
      const options = this.options(`${this.sectionType}:${this.sectionSlug}:${this.field.label}`);
      if (options) {
        return _.chain(options)
                .find(option => option[0] === this.field.value)
                .value()?.[1];
      }
    }
  },
  methods: {
    fieldId(option) {
      return `${this.sectionType}:${this.sectionSlug}:${this.field.label}:${option[0]}`;
    }
  }
};
</script>
