<template>
  <div class="tw-relative">
    <dropdown>
      <template #link>
        <div class="submenu-trigger tw-cursor-pointer">
          <i class="fas fa-ellipsis-v-alt tw-mx-2" />
        </div>
      </template>

      <template #items>
        <div v-cloak :class="cssClasses.div">
          <span :class="cssClasses.span">
            <a :href="`/claim_benefits/${benefit.slug}#record_receipt`" target="_blank">
              <i class="fas fa-receipt tw-text-center tw-mx-2" />
              Record Receipt of Benefit
            </a>

          </span>

          <span :class="cssClasses.span">
            <a :href="`https://app.hubspot.com/contacts/5102898/deal/${benefit.claim_period.hubspot_deal_id}/`" target="_blank">
              <i class="fas fa-link tw-text-center tw-mx-2" />
              View on Hubspot
            </a>
          </span>

          <span :class="cssClasses.span">
            <a :href="`/claim_benefits/${benefit.slug}`" target="_blank">
              <i class="fas fa-arrow-right tw-text-center tw-mx-2" />
              Update Status
            </a>
          </span>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
export default {
  props: {
    benefit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cssClasses: {
        div: "tw-origin-top-right tw-z-50 tw-right-0 tw-absolute tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white",
        span: "tw-cursor-pointer tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-grapy-100 focus:tw-text-gray-900"
      }
    };
  }
};
</script>
