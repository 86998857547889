<template>
  <BadgeComponent
    size="small"
    type="light"
    v-bind="options"
  />
</template>

<script setup>
import { defineProps, reactive } from "vue";
import BadgeComponent from "../../generic/badge_component.vue";
import { SECTION_STATUS_BADGE_OPTIONS } from "../../../shared/section_status.js";

const props = defineProps({
  sectionStatus: {
    type: Number,
    validator: value => {
      const numberRange = Array.from({ length: 5 }, (value, index) => index);
      return numberRange.includes(value);
    }
  }
});

const options = reactive(SECTION_STATUS_BADGE_OPTIONS[props.sectionStatus]);

</script>
