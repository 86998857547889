import { parse } from "date-fns";
import { get, post } from "../../shared/http.js";

export const SET_LOADING_NOMINALS = "SET_LOADING_NOMINALS";
export const GET_LOADING_NOMINALS = "GET_LOADING_NOMINALS";
export const SET_LOADING_SUPPLIERS = "SET_LOADING_SUPPLIERS";
export const SET_LOADING_COSTS = "SET_LOADING_COSTS";
export const FETCH_INTEGRATION_REFRESH_DATA = "FETCH_INTEGRATION_REFRESH_DATA";
export const GET_INTEGRATION_REFRESH_DATA = "GET_INTEGRATION_REFRESH_DATA";

// Nominals
export const FETCH_NOMINALS = "FETCH_NOMINALS";
export const GET_NOMINALS = "GET_NOMINALS";
export const GET_NOMINALS_LOOKUP = "GET_NOMINALS";
export const SET_NOMINALS = "SET_NOMINALS";
export const SET_NOMINALS_LOOKUP = "SET_NOMINALS_LOOKUP";
export const SET_GOOD_NOMINALS = "SET_GOOD_NOMINALS";
export const GET_GOOD_NOMINALS = "GET_GOOD_NOMINALS";
export const SET_BAD_NOMINALS = "SET_BAD_NOMINALS";
export const GET_BAD_NOMINALS = "GET_BAD_NOMINALS";
export const GET_BAD_NOMINALS_INCLUDED = "GET_BAD_NOMINALS_INCLUDED";
export const TOGGLE_NOMINAL = "TOGGLE_NOMINAL";

// Suppliers
export const FETCH_SUPPLIERS = "FETCH_SUPPLIERS";
export const FILTER_SUPPLIERS = "FILTER_SUPPLIERS";
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const SET_HAS_EXCLUDED_SUPPLIERS = "SET_HAS_EXCLUDED_SUPPLIERS";
export const TOGGLE_SUPPLIER = "TOGGLE_SUPPLIER";
export const GET_LOADING_SUPPLIERS = "GET_LOADING_SUPPLIERS";
export const SET_SUPPLIER_SUBCOST_TYPE = "SET_SUPPLIER_SUBCOST_TYPE";
export const GET_SUPPLIER_SUBCOST_TYPES = "GET_SUPPLIER_SUBCOST_TYPES";
export const SET_SUPPLIERS_FILTER = "SET_SUPPLIERS_FILTER";

// Costs
export const FETCH_COSTS = "FETCH_COSTS";
export const FETCH_COSTS_BY_SUPPLIER = "FETCH_COSTS_BY_SUPPLIER";
export const GET_COSTS_BY_SUPPLIER = "GET_COSTS_BY_SUPPLIER";
export const SET_COSTS = "SET_COSTS";
export const TOGGLE_COST_INCLUSION = "TOGGLE_COST_INCLUSION";
export const SET_UPDATE_COSTS = "SET_UPDATE_COSTS";
export const SET_COSTS_SORT = "SET_COSTS_SORT";
export const SORT_COSTS_FOR_SUPPLIER = "SORT_COSTS_FOR_SUPPLIER";

export const accountingIntegration = {
  namespaced: true,
  state: {
    loadingNominals: false,
    loadingSuppliers: false,
    loadingCosts: false,
    nominals: [],
    goodNominals: [],
    badNominals: [],
    nominalsLookup: [],
    suppliers: [],
    has_excluded_suppliers: false,
    filteredSuppliers: [],
    costsBySupplier: {},
    supplierCategories: {},
    suppliersFilter: {},
    costsSortFilter: { sort: {}, filter: {} },
    integrationRefreshData: {}
  },
  mutations: {
    [SET_LOADING_NOMINALS]: (state, payload) => state.loadingNominals = payload,
    [SET_LOADING_SUPPLIERS]: (state, payload) => state.loadingSuppliers = payload,
    [SET_LOADING_COSTS]: (state, payload) => state.loadingCosts = payload,

    [SET_NOMINALS]: (state, payload) => (state.nominals = payload),
    [SET_NOMINALS_LOOKUP]: state => (state.nominalsLookup = Object.assign({}, ...state.nominals.map(nominal => ({ [nominal.code]: nominal })))),
    [SET_GOOD_NOMINALS]: state => (state.goodNominals = state.nominals.filter(nominal => nominal.could_include == true)),
    [SET_BAD_NOMINALS]: state => (state.badNominals = state.nominals.filter(nominal => nominal.could_include != true)),
    [SET_SUPPLIERS]: (state, payload) => (state.suppliers = payload.suppliers_for_selection),
    [SET_HAS_EXCLUDED_SUPPLIERS]: (state, payload) => (state.has_excluded_suppliers = payload.has_excluded_suppliers),
    [SET_COSTS]: (state, payload) => (state.costs = payload),
    [SET_SUPPLIERS_FILTER]: (state, payload) => { state.suppliersFilter = payload; },

    [SET_UPDATE_COSTS]: (state, payload) => {
      state.costsBySupplier = Object.assign(state.costsBySupplier, { [payload.identifier]: payload.costs });
    },

    // mutuate sort prop from outside then call sort mutation below
    [SET_COSTS_SORT]: (state, payload) => {
      state.costsSortFilter = Object.assign(state.costsSortFilter, { sort: payload.sort });
    },

    // "private" mutations called from actions
    optimisticSetSupplierCategory: (state, payload) => (state.supplierCategories = Object.assign(state.supplierCategories, ...[{ [payload.identifier]: payload.subcostType }])),
    setFilteredSuppliers: (state, payload) => (state.filteredSuppliers = payload),
    setSupplierCategories: (state, payload) => (state.supplierCategories = payload),
    setCostsBySupplier: (state, payload) => (state.costsBySupplier = payload),
    setIntegrationRefreshData: (state, payload) => (state.integrationRefreshData = payload)
  },
  actions: {

    [FILTER_SUPPLIERS]: async ({ commit, state }, data) => {
      let suppliers = [];
      suppliers = state.suppliers;
      if (Object.keys(state.suppliersFilter).length > 0) {
        if (state.suppliersFilter.query.toString() != "") { suppliers = suppliers.filter(supplier => supplier.name.toLowerCase().indexOf(state.suppliersFilter.query) > -1); }

        if (state.suppliersFilter.hide_ignored == true) { suppliers = suppliers.filter(supplier => supplier?.subcost_type !== null && supplier?.subcost_type?.toLowerCase() != "" && supplier?.subcost_type?.toLowerCase() != "ignore"); }
      }

      commit("setFilteredSuppliers", suppliers);
      commit("setSupplierCategories", Object.fromEntries(state.filteredSuppliers.map((c, index) => [c.identifier, c.subcost_type])));
    },

    [SORT_COSTS_FOR_SUPPLIER]: async ({ commit, state }, payload) => {
      const sortedCostsThisSupplier = state.costsBySupplier[payload.identifier];
      if (state.costsSortFilter.sort[0] == "amount") { sortedCostsThisSupplier.sort((a, b) => b.sub_total - a.sub_total); }

      if (state.costsSortFilter.sort[0] == "date") { sortedCostsThisSupplier.sort(cost => new Date() - parse(cost.date_paid, "do MMM yyyy", new Date())); }

      commit("setCostsBySupplier", Object.assign(state.costsBySupplier, ...[{ [payload.identifier]: sortedCostsThisSupplier }]));
    },

    [FETCH_NOMINALS]: async ({ commit, state }, data) => {
      if (state.loadingNominals == true) { return; }

      commit(SET_LOADING_NOMINALS, true);
      const response = await get(`/api/accounting_integrations/${data.integrationSlug}/nominals`);
      commit(SET_LOADING_NOMINALS, false);
      commit(SET_NOMINALS, response);
      commit(SET_NOMINALS_LOOKUP);
      commit(SET_GOOD_NOMINALS);
      commit(SET_BAD_NOMINALS);
    },

    [TOGGLE_NOMINAL]: async ({ commit, state, dispatch }, data) => {
      // @radar add optimistic updates to local state object
      const response = await post(
        `/api/accounting_integrations/${data.integrationSlug}/toggle_nominal`,
        {
          identifier: data.identifier,
          include: data.include
        }
      );

      dispatch(FETCH_NOMINALS, data);
    },

    [TOGGLE_SUPPLIER]: async ({ commit, state, dispatch }, data) => {
      // @radar add optimistic updates to local state object
      const response = await post(
        `/api/accounting_integrations/${data.integrationSlug}/toggle_supplier`,
        {
          identifier: data.identifier,
          include: data.include
        }
      );

      dispatch(FETCH_COSTS_BY_SUPPLIER, data);
    },

    [SET_SUPPLIER_SUBCOST_TYPE]: async ({ commit, state, dispatch }, data) => {
      commit("optimisticSetSupplierCategory", data);
      const response = await post(
        `/api/accounting_integrations/${data.integrationSlug}/set_supplier_cost_type`,
        {
          identifier: data.identifier,
          subcost_type: data.subcostType
        }
      );

      dispatch(FETCH_SUPPLIERS, data);
    },

    [FETCH_SUPPLIERS]: async ({ commit, state, dispatch }, data) => {
      if (state.loadingNominals == true) { return; }

      commit(SET_LOADING_SUPPLIERS, true);
      const response = await get(`/api/accounting_integrations/${data.integrationSlug}/suppliers`);
      commit(SET_LOADING_SUPPLIERS, false);
      commit(SET_SUPPLIERS, response);
      commit(SET_HAS_EXCLUDED_SUPPLIERS, response);
      dispatch(FILTER_SUPPLIERS, null);
    },

    [FETCH_COSTS_BY_SUPPLIER]: async ({ commit, state, dispatch }, data) => {
      if (state.loadingCosts == true) { return; }

      commit(SET_LOADING_COSTS, true);
      const response = await get(`/api/accounting_integrations/${data.integrationSlug}/costs/${data.identifier}`);
      commit(SET_LOADING_COSTS, false);
      commit(SET_UPDATE_COSTS, { identifier: data.identifier, costs: response });
    },

    [TOGGLE_COST_INCLUSION]: async ({ commit, state, dispatch }, data) => {
      // @radar could optimistically update the `costsBySupplier` state
      const response = await post(
        `/api/accounting_integrations/${data.integrationSlug}/toggle_cost_inclusion`,
        {
          id: data.id,
          include: data.include,
          supplier_identifier: data.supplier_identifier
        }
      );

      dispatch(FETCH_SUPPLIERS, data);
    },
    [FETCH_INTEGRATION_REFRESH_DATA]: async ({ commit }, data) => {
      const response = await get(`/api/accounting_integrations/${data.integrationSlug}/integration_refresh_data`);

      commit("setIntegrationRefreshData", response);
    }
  },
  getters: {
    [GET_NOMINALS]: state => state.nominals,
    [GET_NOMINALS_LOOKUP]: state => state.nominalsLookup,
    [GET_GOOD_NOMINALS]: state => state.goodNominals,
    [GET_BAD_NOMINALS]: state => state.badNominals,
    [GET_BAD_NOMINALS_INCLUDED]: state => state.badNominals.filter(nominal => nominal.include == true),
    [GET_LOADING_NOMINALS]: state => state.loadingNominals,

    [GET_LOADING_SUPPLIERS]: state => state.loadingSuppliers,
    [GET_SUPPLIERS]: state => state.filteredSuppliers,
    // [GET_SUPPLIER_CATEGORIES]: (state) => state.supplierCategories,
    [GET_COSTS_BY_SUPPLIER]: state => state.costsBySupplier,
    [GET_SUPPLIER_SUBCOST_TYPES]: state => state.supplierCategories,
    [GET_INTEGRATION_REFRESH_DATA]: state => state.integrationRefreshData
  }
};
