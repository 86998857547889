<template>
  <DataTable
    :data="configuredData"
    :columns="tableColumns"
    :paginator-rows="50"
    enable-search
    table-header="All Companies"
    :error-message="errorMessage"
    :filter-fields="[ 'name.name' ]"
    :is-loading="isLoading"
    row-classes="tw-cursor-pointer"
    use-row-click
    @on-row-click="onRowClick"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import { get } from "@shared/http.js";
import { formatDate, formatCurrency } from "@shared/helpers.js";
import DataTable from "@generic/data_table.vue";
import IconGroup from "@generic/icon_group.vue";
import CompaniesTableNameCell from "./companies_table_name_cell.vue";

const companies = ref([]);
const errorMessage = ref("");
const isLoading = ref(true);

getCompanies();

async function getCompanies() {
  const res = await get("/api/companies");
  isLoading.value = false;

  if (res.error) {
    errorMessage.value = "There was an error fetching the companies.";
    return;
  }

  companies.value = res;
}

function onRowClick(event) {
  const { data, originalEvent } = event;

  if (originalEvent?.target?.hasAttribute("exclude-from-click")) return;

  window.location.href = data.company_href;
}

const configuredData = computed(() => companies.value.map(company => ({
  ...company,
  name: {
    name: company.name,
    amlStatus: company.aml_check_status
  },
  icons: {
    icons: company.icons
  }
})));

const tableColumns = [
  {
    header: "Name",
    field: "name",
    filterField: "name.name",
    sortField: "name.name",
    component: CompaniesTableNameCell,
    colClasses: "tw-w-1/5",
    sortable: true
  },
  {
    header: "Claims",
    field: "claims_count",
    sortable: true
  },
  {
    header: "Active Claims",
    field: "active_claims_count",
    sortable: true
  },
  {
    header: "Last Claim Value",
    field: "last_claim_period_value",
    formatter: formatCurrency,
    formatterArguments: [false, false],
    sortable: true
  },
  {
    header: "Last Year End",
    field: "last_claim_period_end_date",
    sortable: true
  },
  {
    header: "Year End Withing 6 Weeks",
    field: "in_upcoming_year_end",
    sortable: true
  },
  {
    header: "Users",
    field: "users_count",
    sortable: true
  },
  {
    header: "Status",
    field: "freeze_status",
    sortable: true
  },
  {
    header: "Created",
    field: "created_at",
    formatter: formatDate,
    colClasses: "tw-whitespace-nowrap",
    sortable: true
  },
  {
    header: "",
    field: "icons",
    component: IconGroup,
    excludeFromClick: true
  }
];
</script>
