<template>
  <div
    :id="id"
    :class="[fullWidth ? 'tw-w-full' : '', 'tw-mx-auto tw-max-w-none tw-w-full tw-bg-white tw-px-4 tw-py-3 xl:tw-px-6 tw-rounded-md tw-shadow']"
  >
    <slot>
      <div :class="['tw-flex tw-flex-col tw-gap-3', dividers && 'tw-divide-y tw-divide-solid']">
        <div v-if="$slots.title || title">
          <slot name="title">
            <div v-if="title" class="tw-flex tw-items-stretch tw-justify-between tw-sm:flex-no-wrap">
              <div class="tw-flex tw-items-center">
                <div class="tw-block">
                  <div class="tw-flex tw-gap-2 tw-items-center">
                    <h3 class="tw-text-base tw-font-semibold tw-text-deep-blue tw-tracking-tight tw-mb-0">
                      {{ title }}
                    </h3>
                    <div v-if="knowledgeCentre">
                      <state-wrapper component="SectionKnowledgeCentre">
                        <KnowledgeCentreTriggerIcon
                          :question="knowledgeCentre.question"
                          :faq="knowledgeCentre.faq"
                          :section="knowledgeCentre.section"
                          :icon="true"
                        />
                      </state-wrapper>
                    </div>
                  </div>
                  <p v-if="subtitle" class="tw-my-0 tw-text-xs tw-leading-5 tw-text-lighter-blue">
                    {{ subtitle }}
                  </p>
                </div>
              </div>
              <div class="tw-flex-shrink-0">
                <slot name="action" />
              </div>
            </div>
          </slot>
        </div>
        <div v-if="$slots.content || content" :class="dividers && 'tw-pt-3'">
          <slot name="content">
            <div v-if="content" class="tw-block tw-mt-1">
              {{ content }}
            </div>
          </slot>
        </div>
        <div v-if="$slots.footer || footer" :class="dividers && 'tw-pt-3'">
          <slot name="footer" class="tw-mt-1">
            <div v-if="footer" class="tw-block">
              {{ footer }}
            </div>
          </slot>
        </div>
      </div>
    </slot>
  </div>
</template>

<!--
  For this component you can choose to pass strings as props or use the slots to integrate code into each section.
  The general slot for the component means you can wrap any code in CardComponent and it will put all the content in a standard card with white background
  If you want to follow more consistent card design though (title, content, footer etc) you can pass code into specific slots too:
  - Top (#title) - this is a slot for the top title section of the card
  - Middle (#content) - this is a slot for the main content section of the card
  - Bottom (#footer) - this is a slot for the bottom footer section of the card
  - Link/action space (#action) - this is a slot for a link or button that will be placed on the right side of the top title section of the card

  Make sure if you don't want to overwrite the default card design you use only the slots or props to pass in your content. Anything between
  <CardComponent> and </CardComponent> that is not in a <template> with slot identifier (#title for ex) will overwrite the default styling.
-->
<script>

import KnowledgeCentreTriggerIcon from "./knowledge_centre_trigger_icon.vue";

export default {
  components: {
    KnowledgeCentreTriggerIcon
  },
  props: {
    id: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    },
    footer: {
      type: String,
      required: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true
    },
    dividers: {
      type: Boolean,
      required: false,
      default: false
    },
    knowledgeCentre: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>
