<template>
  <div>
    <TabGroup :default-index="defaultIndex">
      <TabList>
        <div class="tw-flex tw-gap-6">
          <Tab
            v-if="elearnFirst"
            v-slot="{ selected }"
            as="template"
            class="tw-outline-none"
          >
            <button-component :track="order ? { selected: selected, tab: order[0] } : null">
              <div class="tw-group tw-flex tw-flex-col tw-cursor-pointer tw--mt-2 focus:tw-outline-none">
                <div :class="[selected ? theme.tabbedContent.title_selected : theme.tabbedContent.title]">
                  <slot name="third-tab-title" :selected="selected" />
                </div>
                <div :class="[selected ? theme.tabbedContent.border_selected : theme.tabbedContent.border]" />
              </div>
            </button-component>
          </Tab>

          <Tab v-slot="{ selected }" as="template" class="tw-outline-none">
            <button-component :track="order ? { selected: selected, tab: order[elearnFirst ? 1 : 0] } : null">
              <div class="tw-group tw-flex tw-flex-col tw-cursor-pointer tw--mt-2 focus:tw-outline-none">
                <div :class="[selected ? theme.tabbedContent.title_selected : theme.tabbedContent.title]">
                  <slot name="first-tab-title" />
                </div>
                <div :class="[selected ? theme.tabbedContent.border_selected : theme.tabbedContent.border]" />
              </div>
            </button-component>
          </Tab>

          <Tab v-slot="{ selected }" as="template" class="tw-outline-none">
            <button-component :track="order ? { selected: selected, tab: order[elearnFirst ? 2 : 1] } : null">
              <div class="tw-group tw-flex tw-flex-col tw-cursor-pointer tw--mt-2 focus:tw-outline-none">
                <div :class="[selected ? theme.tabbedContent.title_selected : theme.tabbedContent.title]">
                  <slot name="second-tab-title" />
                </div>
                <div :class="[selected ? theme.tabbedContent.border_selected : theme.tabbedContent.border]" />
              </div>
            </button-component>
          </Tab>

          <Tab
            v-if="!elearnFirst"
            v-slot="{ selected }"
            as="template"
            class="tw-outline-none"
          >
            <button-component :track="order ? { selected: selected, tab: order[2] } : null">
              <div class="tw-group tw-flex tw-flex-col tw-cursor-pointer tw--mt-2 focus:tw-outline-none">
                <div :class="[selected ? theme.tabbedContent.title_selected : theme.tabbedContent.title]">
                  <slot name="third-tab-title" />
                </div>
                <div :class="[selected ? theme.tabbedContent.border_selected : theme.tabbedContent.border]" />
              </div>
            </button-component>
          </Tab>
        </div>
      </TabList>

      <TabPanels>
        <TabPanel v-if="elearnFirst">
          <div class="tw-mt-4">
            <slot name="third-tab-content" />
          </div>
        </TabPanel>

        <TabPanel>
          <div class="tw-mt-4">
            <slot name="first-tab-content" />
          </div>
        </TabPanel>

        <TabPanel>
          <div class="tw-mt-4">
            <slot name="second-tab-content" />
          </div>
        </TabPanel>

        <TabPanel v-if="!elearnFirst">
          <div class="tw-mt-4">
            <slot name="third-tab-content" />
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import _ from "lodash";
import { css } from "../../shared/theme.js";

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel
  },
  props: {
    elearnFirst: {
      type: Boolean,
      required: false
    },
    defaultIndex: {
      type: Number,
      required: false,
      default: 0
    },
    order: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      theme: css
    };
  }
};
</script>
