<template>
  <TransitionRoot as="template" :show="open">
    <div tabindex="0" @keyup.esc="close()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 sm:scale-100"
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div id="modal-id" class="tw-overflow-x-hidden tw-overflow-y-auto tw-fixed tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none tw-justify-center tw-items-center">
          <div class="tw-relative tw-my-6 tw-mx-auto tw-w-1/2">
            <!--content-->
            <div class="tw-border-0 tw-rounded-lg tw-shadow-lg tw-relative tw-flex tw-flex-col tw-w-full tw-bg-white tw-outline-none focus:tw-outline-none tw-px-8 tw-py-8 tw-divide-y tw-divide-gray-200">
              <!--header-->
              <div class="tw-mb-2">
                <h3 class="tw-text-center tw-text-xl tw-font-semibold">
                  <div v-if="loading" class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4" />
                  <span v-else class="tw-px-2" v-text="title" />
                </h3>
                <div class="tw-cursor-pointer tw-absolute tw-top-0 tw-right-0 tw-mr-8 tw-mt-3 hover:tw-text-red-600" @click="close()">
                  <i class="far fa-times fa-lg" />
                </div>
              </div>
              <!--body-->
              <div :class="[displayType == 'url' ? 'tw-overflow-y-scroll' :'', 'tw-relative  tw-flex-auto']" :style="[displayType == 'url' ? 'height: 500px' : 'height: 200px']">
                <div v-show="errorState">
                  <p>Unfortunately we had a problem loading that history; an error has been logged and the team will be alerted</p>
                </div>

                <div v-show="loading && !errorState" class="tw-px-2 tw-py-2">
                  <PulsingLoader />
                </div>

                <div v-show="!loading" class="tw-px-2 tw-py-2">
                  <div class="tw-relative tw-overflow-y-auto" v-html="content" />
                </div>
              </div>
              <!--footer-->
              <!-- <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">

            </div> -->
            </div>
          </div>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import PulsingLoader from "./loading_pulse_lines";

export default {
  components: {
    PulsingLoader,
    TransitionChild,
    TransitionRoot
  },
  setup() {
    const open = ref(false);

    return {
      open
    };
  },
  data() {
    return {
      loading: true,
      errorState: false,
      content: "",
      title: "",
      displayType: ""
    };
  },
  created() {
    window.addEventListener("modal", this.trigger);
    window.addEventListener("closeModal", this.close);
  },
  methods: {
    close() {
      this.open = false;
    },
    trigger(event) {
      if (event.detail.url) {
        this.url = event.detail.url;
        this.title = event.detail.title;
        this.displayType = "url";
        fetch(this.url, {
          headers: {
            "Content-Type": "text/html"
          }
        })
            .then(response => response.text())
            .then(html => this.processResponse(html));

        this.open = true;
        this.displayType = "url";
      } else if (event.detail.text) {
        this.content = event.detail.text;
        this.title = event.detail.title;
        this.loading = false;
        this.displayType = "text";
        this.open = true;
      }
    },
    processResponse(html) {
      this.content = html;
      this.loading = false;
    }
  }
};
</script>
