<template>
  <div class="tw-w-full">
    <div v-if="editable">
      <div class="tw-bg-white tw-my-2">
        <quill-editor
          v-model:value="field.value"
          :options="editorOption"
          :style="heightStyle"
          @change="onEditorChange($event)"
        />
        <span v-if="field.limit" :class="['tw-italic tw-text-xs', hitMax ? 'tw-text-error-red tw-animate-bounce' : 'tw-text-disable-grey-500']">
          Max {{ numberWithCommas(field.limit) }} characters
          <i v-if="hitMax" class="fas fa-exclamation-triangle" />
        </span>
      </div>

      <state-wrapper component="NarrativeFeedback3">
        <narrative-feedback-3
          :section-type="sectionType"
          :section-slug="sectionSlug"
          :question="field.label"
          :field="field"
        />
      </state-wrapper>
    </div>
    <div v-else>
      <section class="tw-prose tw-prose-md">
        <div class="read-only tw-text-gray-500 tw-text-sm" v-html="field.value ? field.value.replace('<p><br></p>', '') : ''" />
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { numberWithCommas } from "../../../../shared/helpers.js";

export default {
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument",
          "RdProject"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true,
      validator: obj => (obj.label ?? "").length > 0 && (obj.title ?? "").length > 0
    },
    editable: {
      type: Boolean,
      default: false
    },
    basicForm: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      numberWithCommas
    };
  },
  data() {
    return {
      validationError: null,
      content: null,
      lastContent: null,
      hitMax: false,
      editorOption: {
        modules: {
          toolbar: [["bold", { list: "ordered" }, { list: "bullet" }, "clean"]]
        },
        theme: "snow",
        placeholder: this.field.field_placeholder_blurb
      }
    };
  },
  computed: {
    heightStyle() {
      if (!_.isNil(this.field.limit) && this.field.limit < 500) {
        return "height: 100px; max-height: 100px;";
      } else {
        return "height: 300px; max-height: 300px;";
      }
    }
  },
  methods: {
    ...mapActions({
      setEditedFields: "fields/SET_EDITED_FIELDS"
    }),
    onEditorChange({ quill, html, text }) {
      if (this.lastContent !== html) {
        window.dispatchEvent(
          new CustomEvent("quilledited", {
            detail: {
              section: this.section,
              question: this.question,
              text,
              id: this.id,
              auto_feedback: this.auto_feedback
            }
          })
        );
        this.setEditedFields({ field: `${this.sectionType}:${this.sectionSlug}:${this.field.label}` });
        window.unsaved = true;
      }
      if (this.field.limit && (quill.getLength() > this.field.limit)) {
        quill.deleteText(this.field.limit, quill.getLength());
        this.hitMax = true;
      } else {
        this.hitMax = false;
      }
      this.content = html;
      this.lastContent = html;
    }
  }
};
</script>

<style>
.read-only p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(107 114 128);
  margin: 0px;
}

.ql-container, .ql-editor {
  border: 2px solid transparent;
  max-height: inherit;
  overflow: auto;
}
</style>
