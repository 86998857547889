<template>
  <button-component v-bind="$props" :on-click="triggerModal">
    <slot />
  </button-component>
</template>

<script>
import useEventsBus from "../../shared/eventBus";

export default {
  // You can pass any props you would pass a button component to this component and
  // it will be passed through to the button component
  props: {
    modalId: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const { emit } = useEventsBus();

    function triggerModal() {
      emit("toggleModal", props.modalId);
    }

    return {
      triggerModal
    };
  }
};
</script>
