<template>
  <DataTable
    :data="configuredData"
    :columns="tableColumns"
    :paginator-rows="50"
    enable-search
    :error-message="errorMessage"
    :is-loading="isLoading"
    edit-mode="cell"
    :cell-metadata="cellMetadata"
    @on-cell-edit-complete="onCellEditComplete"
    @on-input-text-update="onInputTextUpdate"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { DataTableCellEditCompleteEvent } from "primevue/datatable";
import DataTable from "@generic/data_table.vue";
import { ExternalWorker } from "./interfaces";

const store = useStore();

const props = defineProps<{
  externalWorkers: ExternalWorker[]
  errorMessage: string
  isLoading: boolean
  editable: boolean
}>();

const configuredData = computed(() => props.externalWorkers.map((externalWorker: ExternalWorker) => ({
  externally_provided_worker: `${externalWorker.name}, ${externalWorker.work_description}`,
  ...externalWorker
})));

const cellMetadata = ref<{ [key: string]: { state: string, message: string } }>({});

// need timeouts for each field value so they do not reset each other
const resetMetadataTimeouts = {};

// better to set cell metadata here and keep it separate from state. If it's done on state, any async operations will
// retrigger the externalWorkers computed property at page level which will overwrite any changes not yet set on state, such as text inputs.
const onCellEditComplete = async (event: DataTableCellEditCompleteEvent) => {
  const { field, value, newValue, data } = event;

  if (cellMetadata.value[data.slug]?.[field]?.state !== "error" && resetMetadataTimeouts[data.slug]) {
    setCellMetadata(data.slug, field, "");
    clearTimeout(resetMetadataTimeouts[data.slug][field]);
  }

  if (value === newValue) return;

  setCellMetadata(data.slug, field, "saving");

  const res = await store.dispatch("external_workers/UPDATE_EXTERNAL_WORKER", {
    slug: data.slug,
    field,
    oldValue: value,
    newValue
  });

  if (res.error) {
    const errorMessage = res.data?.error?.length > 0 ? res.data.error[0] : res.statusText;

    setCellMetadata(data.slug, field, "error", errorMessage);
  } else {
    setCellMetadata(data.slug, field, "saved");

    resetMetadataTimeouts[data.slug] = {
      ...resetMetadataTimeouts[data.slug],
      [field]: setTimeout(() => setCellMetadata(data.slug, field, ""), 2000)
    };
  }
};

const setCellMetadata = (slug: string, field: string, state: string, message: string = "") => {
  cellMetadata.value = {
    ...cellMetadata.value,
    [slug]: {
      ...cellMetadata.value[slug],
      [field]: {
        state,
        message
      }
    }
  };
};

const onInputTextUpdate = event => {
  const { field, slug, value } = event;

  if (field === "paye_reference") {
    const isValidPayeReference = /^\d{3}\/[A-Za-z0-9]{1,10}$/;
    const state = isValidPayeReference.test(value) ? "" : "error";
    const message = state === "error" ? "Your input cannot be saved. PAYE reference must have 3 numbers, a slash, and 1-10 alphanumeric characters e.g. 123/AB456." : "";

    setCellMetadata(slug, field, state, message);
  }
};

const tableColumns = computed(() => [
  {
    header: "Externally Provided Workers",
    field: "externally_provided_worker",
    alignmentOverride: "tw-text-left",
    sortable: true
  },
  {
    header: "Paye Reference",
    field: "paye_reference",
    editable: props.editable,
    colClasses: "tw-w-1/5"
  },
  {
    header: "Uk Address",
    field: "uk_address",
    editable: props.editable,
    colClasses: "tw-w-1/2"
  }
]);
</script>
