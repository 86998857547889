const css = {
  forms: {
    input: "tw-block tw-w-full tw-border-solid tw-box-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm focus:tw-ring-deep-blue focus:tw-border-deep-blue tw-py-2 ",
    inputErrors: "tw-border-red-300 focus:tw-ring-red-300 focus:tw-border-red-300",
    inputDisabled: "tw-block tw-w-full placeholder:tw-text-gray-300 tw-border-solid tw-box-border tw-border-gray-200 tw-rounded-md tw-shadow-xs tw-text-sm focus:tw-ring-deep-blue focus:tw-border-deep-blue tw-py-2 ",
    radio: "tw-border-solid tw-h-4 tw-w-4 tw-text-deep-blue tw-transition tw-duration-150 tw-ease-in-out focus:tw-ring-deep-blue focus:tw-border-deep-blue",
    input_error: "tw-block tw-w-full tw-border-solid tw-box-border tw-border-red-300 tw-border-2 tw-rounded-md tw-shadow-sm tw-text-sm focus:tw-ring-red-500 focus:tw-border-red-500 tw-py-2 ",
    checkbox: "focus:tw-ring-deep-blue tw-h-4 tw-w-4 tw-text-deep-blue tw-border-solid tw-border-gray-300 tw-rounded tw-cursor-pointer",
    checkboxDisabled: "tw-h-4 tw-w-4 tw-text-gray-400 tw-border-solid tw-border-gray-300 tw-rounded",
    checkbox_rounded: "focus:tw-ring-deep-blue tw-h-4 tw-w-4 tw-text-deep-blue tw-border-solid tw-border-gray-300 tw-rounded-lg",
    textarea: "tw-shadow-sm focus:tw-ring-deep-blue focus:tw-border-deep-blue tw-text-xs tw-block tw-w-full tw-border-gray-300 tw-rounded-md tw-border-solid tw-box-border",
    button: "tw-border tw-border-transparent tw-font-medium tw-rounded-md  focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-75 hover:tw-no-underline tw-shadow-xs tw-text-xs tw-p-2"
  },
  tables: {
    td: "tw-px-2 tw-py-2 tw-whitespace-no-wrap tw-leading-5 tw-text-gray-500",
    td_no_text_colour: "tw-px-2 tw-py-2 tw-whitespace-no-wrap tw-leading-5",
    th: "tw-px-2 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-w-48",
    th_no_w: "tw-px-2 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase",
    th_dark: "tw-px-2 tw-py-3 tw-bg-deep-blue tw-text-left tw-text-xs tw-leading-2 tw-font-medium tw-text-white tw-uppercase tw-cursor-default"
  },
  tabs: {
    large: "tw-text-gray-500 hover:tw-text-gray-700 tw-px-3 tw-py-2 tw-font-medium  tw-rounded-md tw-bg-white",
    large_selected: "tw-bg-deep-blue tw-text-white tw-px-3 tw-py-2 tw-font-medium  tw-rounded-md focus:tw-outline-none focus:tw-ring-2 tw-ring-offset-2 tw-ring-offset-deep-blue tw-ring-white tw-ring-opacity-60"
  },
  typography: {
    h2: "tw-text-xl tw-font-medium",
    h3: "tw-text-lg tw-my-2",
    h4: "tw-text-lg tw-font-medium tw-my-2",
    h5: "tw-text-md tw-font-medium tw-my-2"
  },
  dropDown: {
    menuDiv: "tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100",
    menuDivFullWidth: "tw-absolute tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100",
    itemDiv: "tw-cursor-pointer tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-grapy-100 focus:tw-text-gray-900",
    itemDivFullWidth: "tw-cursor-pointer tw-group tw-items-center tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-grapy-100 focus:tw-text-gray-900"
  },

  detailList: {
    container: "tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6",
    containerFull: "sm:tw-grid sm:tw-grid-cols-1 sm:tw-gap-1",
    title: "tw-text-sm tw-font-semibold tw-text-deep-blue",
    detail: "tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2"
  },

  tabbedContent: {
    title: "tw-text-base tw-font-bold tw-text-gray-500 group-hover:tw-text-deep-blue group-hover:tw-transition group-hover:tw-duration-100",
    title_selected: "tw-text-base tw-font-bold",
    border: "tw-w-0 tw-self-center tw-border-b-solid tw-border-b-2 tw-rounded-full tw-border-b-deep-blue group-hover:tw-transition-all group-hover:tw-duration-300 group-hover:tw-w-8",
    border_selected: "tw-w-8 tw-self-center tw-border-b-solid tw-border-b-2 tw-rounded-full tw-border-b-deep-blue"
  },
  alert: {
    error: {
      bg: "tw-bg-error-red-100",
      bgHover: "hover:tw-bg-error-red-250 hover:tw-text-error-red",
      border: "tw-border-error-red-750 tw-border-l-4 tw-border-l-solid tw-rounded-r-md",
      text: "tw-text-error-red",
      icon: "fas fa-times-circle tw-text-error-red-500"
    },
    warning: {
      bg: "tw-bg-warning-yellow-100",
      bgHover: "hover:tw-bg-warning-yellow-250 hover:tw-text-warning-yellow",
      border: "tw-border-warning-yellow-750 tw-border-l-4 tw-border-l-solid tw-rounded-r-md",
      text: "tw-text-warning-yellow",
      icon: "fas fa-exclamation-triangle tw-text-warning-yellow-500"
    },
    confirm: {
      bg: "tw-bg-confirm-green-100",
      bgHover: "hover:tw-bg-confirm-green-250 hover:tw-text-confirm-green",
      border: "tw-border-confirm-green-750 tw-border-l-4 tw-border-l-solid tw-rounded-r-md",
      text: "tw-text-confirm-green",
      icon: "fas fa-check-circle tw-text-confirm-green-500"
    },
    cta: {
      bg: "tw-bg-cta-blue-100",
      bgHover: "hover:tw-bg-cta-blue-250 hover:tw-text-cta-blue",
      border: "tw-border-cta-blue-750 tw-border-l-4 tw-border-l-solid tw-rounded-r-md",
      text: "tw-text-cta-blue",
      icon: "fas fa-info-circle tw-text-cta-blue-500"
    },
    disabled: {
      bg: "tw-bg-disable-grey-100",
      bgHover: "hover:tw-bg-disable-grey-250 hover:tw-text-disable-grey",
      border: "tw-border-disable-grey-750 tw-border-l-4 tw-border-l-solid tw-rounded-r-md",
      text: "tw-text-disable-grey",
      icon: "fas fa-info tw-text-disable-grey-500"
    }
  },
  notification: {
    error: {
      bg: "tw-bg-error-red-750",
      icon: "tw-text-error-red-750"
    },
    warning: {
      bg: "tw-bg-warning-yellow-500",
      icon: "tw-text-warning-yellow-500"
    },
    confirm: {
      bg: "tw-bg-confirm-green-750",
      icon: "tw-text-confirm-green-750"
    },
    cta: {
      bg: "tw-bg-cta-blue-500",
      icon: "tw-text-cta-blue-500"
    },
    disabled: {
      bg: "tw-bg-disable-grey-500",
      icon: "tw-text-disable-grey-500"
    }
  },
  buttons: {
    size_classes: {
      text: {
        xsmall: "tw-text-xs tw-leading-3 tw-py-1 tw-px-2 tw-gap-x-1",
        small: "tw-text-xs tw-leading-4 tw-py-1.5 tw-px-2 tw-gap-x-1.5",
        medium: "tw-text-sm tw-leading-4 tw-py-1.5 tw-px-2 tw-gap-x-1.5",
        large: "tw-text-md tw-leading-5 tw-py-2 tw-px-4 tw-gap-x-2",
        xlarge: "tw-text-lg tw-leading-6 tw-py-2 tw-px-4 tw-gap-x-2"
      },
      icon_only: {
        xsmall: "tw-text-md tw-p-1",
        small: "tw-text-lg tw-p-1.5",
        medium: "tw-text-xl tw-p-1.5",
        large: "tw-text-2xl tw-p-2",
        xlarge: "tw-text-3xl tw-p-2"
      },
      icon: {
        xsmall: "tw-w-3.5 tw-h-3.5",
        small: "tw-w-4 tw-h-4",
        medium: "tw-w-5 tw-h-5",
        large: "tw-w-6 tw-h-6",
        xlarge: "tw-w-7 tw-h-7"
      },
      icon_left: {
        xsmall: "tw--ml-0.5",
        small: "tw--ml-0.5",
        medium: "tw--ml-1",
        large: "tw--ml-1",
        xlarge: "tw--ml-1.5"
      },
      icon_right: {
        xsmall: "tw--mr-0.5",
        small: "tw--mr-0.5",
        medium: "tw--mr-1",
        large: "tw--mr-1",
        xlarge: "tw--mr-1.5"
      }
    },
    colours: {
      cta: {
        primary: "tw-bg-cta-blue hover:tw-bg-cta-blue-750 tw-text-white tw-shadow-sm",
        light: "tw-bg-cta-blue-100 hover:tw-bg-cta-blue-250 tw-text-cta-blue",
        transparent: "tw-bg-transparent hover:tw-bg-cta-blue-100 tw-text-cta-blue tw-border tw-border-cta-blue"
      },
      confirm: {
        primary: "tw-bg-confirm-green hover:tw-bg-confirm-green-750 tw-text-white tw-shadow-sm",
        light: "tw-bg-confirm-green-100 hover:tw-bg-confirm-green-250 tw-text-confirm-green",
        transparent: "tw-bg-transparent hover:tw-bg-confirm-green-100 tw-text-confirm-green tw-border tw-border-confirm-green"
      },
      warning: {
        primary: "tw-bg-warning-yellow hover:tw-bg-warning-yellow-750 tw-text-white tw-shadow-sm",
        light: "tw-bg-warning-yellow-100 hover:tw-bg-warning-yellow-250 tw-text-warning-yellow",
        transparent: "tw-bg-transparent hover:tw-bg-warning-yellow-100 tw-text-warning-yellow tw-border tw-border-warning-yellow"
      },
      error: {
        primary: "tw-bg-error-red hover:tw-bg-error-red-750 tw-text-white tw-shadow-sm",
        light: "tw-bg-error-red-100 hover:tw-bg-error-red-250 tw-text-error-red",
        transparent: "tw-bg-transparent hover:tw-bg-error-red-100 tw-text-error-red tw-border tw-border-error-red"
      },
      disabled: {
        primary: "tw-bg-disable-grey-750 tw-text-white tw-shadow-sm",
        light: "tw-bg-disable-grey-100 tw-text-disable-grey",
        transparent: "tw-bg-transparent tw-text-disable-grey tw-border tw-border-disable-grey"
      },
      emp: {
        primary: "tw-bg-emp-blue hover:tw-bg-emp-blue-hover tw-text-white tw-shadow-sm",
        light: "",
        transparent: "tw-bg-transparent hover:tw-bg-gray-200 tw-text-emp-blue tw-border tw-border-emp-blue"
      }
    }
  },
  cards: {
    large: "tw-bg-white tw-rounded-lg tw-px-4 tw-text-left tw-overflow-hidden tw-shadow-sm tw-transition-all sm:tw-my-2 sm:tw-w-full sm:tw-p-6"
  },
  borderStyles: {
    untouched: "tw-border-blue-gray-300 focus:tw-ring-blue-gray-300 focus:tw-border-blue-gray-300",
    edited: "tw-border-yellow-300 focus:tw-ring-yellow-300 focus:tw-border-yellow-300",
    styleSaved: "tw-border-green-500 focus:tw-ring-green-500 focus:tw-border-green-500",
    error: "tw-border-red-300 focus:tw-ring-red-300 focus:tw-border-red-300"
  }
};

export { css };
