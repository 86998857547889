<template>
  <div class="tw-pb-2 tw-mt-2 tw-border-b-2 tw-border-solid tw-border-0 tw-border-muted-blue">
    <div id="claim_dashboard_sidebar">
      <a
        :href="`/claim_periods/${claimPeriod.slug}/dashboard`"
        :class="[claimPeriod.home ? 'tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white' : 'tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white', 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-my-1 tw-text-base tw-font-bold tw-tracking-tight']"
        :aria-current="claimPeriod.home ? 'page' : undefined"
        @click="trackButtonClick('claim-dashboard-link-sidebar')"
      >
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-1">
          <div class="tw-flex tw-gap-2">
            <span>{{ claimPeriod.title }} Claim</span>
          </div>
          <div v-if="claimPeriod.scheme_type_label" class="tw-inline-flex tw-gap-1">
            <Tooltip content="This is the scheme you're claiming under for this claim period">
              <span class="tw-inline-flex tw-text-black tw-opacity-75 tw-items-center tw-rounded-full tw-bg-disable-grey-100 tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-medium">
                {{ claimPeriod.scheme_type_label }}
              </span>
            </Tooltip>
          </div>
        </div>
      </a>
    </div>

    <Menu as="div" class="tw-relative">
      <MenuButton id="claim-switcher" class="tw-text-gray-100 hover:tw-bg-deep-blue-light tw-group tw-border-none tw-outline-none tw-cursor-pointer tw-w-full tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-xs tw-tracking-tight tw-font-semibold focus:tw-outline-none">
        <span class="tw-flex tw-items-center">
          <i
            class="fas fa-sort tw-text-center tw-pr-2 tw-w-6 tw-text-gray-200 group-hover:tw-text-white tw-flex-shrink-0"
            aria-hidden="true"
          />
          <span class="tw-italic">Switch Claim</span>
        </span>
      </MenuButton>
      <transition
        enter-active-class="tw-transition tw-ease-out tw-duration-100"
        enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
        enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-active-class="tw-transition tw-ease-in tw-duration-75"
        leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
      >
        <MenuItems class="tw-z-10 tw-origin-top tw-absolute tw-bg-muted-blue tw-right-0 tw-left-0 tw-mt-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
          <div class="">
            <div v-for="cp in claimPeriods" :key="cp.title_short">
              <MenuItem v-slot="{ active }">
                <a
                  :href="`/claim_periods/${cp.slug}/dashboard`"
                  :class="[active ? 'tw-bg-gray-600 tw-text-white hover:tw-text-white' : 'tw-text-gray-100', 'tw-block tw-px-4 tw-py-2 tw-text-sm']"
                  @click="trackButtonClick('claim-switch-sidebar', { slug: cp.slug })"
                >
                  {{ cp.title_short }} Claim<span v-if="cp.current" class="tw-italic"> - Current</span>
                </a>
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </transition>
    </Menu>

    <a
      v-if="!isAdmin"
      href="/claim_periods/new?from=sidebar"
      data-confirm="Are you sure you want to start a new claim?"
      class="tw-text-gray-100 tw-bg-transparent hover:tw-bg-deep-blue-light hover:tw-text-white tw-group tw-flex tw-items-center tw-tracking-tight tw-px-4 tw-py-1 tw-text-xs tw-font-semibold"
      @click="trackButtonClick('start-new-claim-sidebar')"
    >
      <i class="fas fa-plus tw-flex-shrink-0 tw-flex-non tw-text-center tw-pr-2 tw-w-6 tw-text-gray-200 group-hover:tw-text-white" aria-hidden="true" />
      <span class="tw-italic">Start a New Claim</span>
    </a>
  </div>
</template>

<script setup>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import { Menu,
  MenuButton,
  MenuItem,
  MenuItems } from "@headlessui/vue";
import Tooltip from "@generic/tooltip.vue";

defineProps({
  claimPeriod: {
    type: Object
  },
  claimPeriods: {
    type: Array
  }
});

const trackButtonClick = inject("trackButtonClick");
const store = useStore();
const isAdmin = computed(() => store.getters["users/GET_IS_ADMIN"]);
</script>
