<template>
  <div>
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-w-full tw-mb-5">
      <div class="tw-w-2/3 tw-pr-10">
        <h1 class="tw-text-2xl tw-mb-2">
          Allocate Grant Funding to Projects
        </h1>
        <CmsContentSlot
          class="cms-content tw-mb-5"
          :content-slot-id="strapiContentIds.projectGrantAllocation.intro"
          size="small"
          :replace-content="replaceContent"
        />
      </div>

      <div class="tw-text-right tw-space-y-3 tw-w-1/3">
        <div class="tw-bg-white tw-rounded-lg tw-shadow tw-flex tw-flex-col tw-gap-2 lg:tw-gap-0 lg:tw-py-4 lg:tw-px-6 tw-py-2 tw-px-2">
          <state-wrapper component="AssignIcon">
            <AssignIcon
              section-type="ProjectGrantAllocationSection"
              :section-slug="props.sectionSlug"
              assign-icon-type="SectionAssign"
            />
          </state-wrapper>
        </div>
        <MarkAsComplete
          :is-complete="isComplete"
          :is-disabled="!isFullyAllocated || isComplete"
          @handle-complete="handleComplete()"
        />
      </div>
    </div>

    <p
      v-if="validationObject"
      :class="`${validationObject.isValid ? 'tw-text-green-700' : 'tw-text-red-700'} tw-mb-5 tw-font-bold`"
    >
      {{ validationObject.message }}
    </p>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import CmsContentSlot from "@generic/cms_content_slot.vue";
import MarkAsComplete from "@generic/mark_as_complete.vue";
import AssignIcon from "@components/dynamic_forms/assign_icon.vue";
import strapiContentIds from "@shared/strapi_content_ids.js";

const store = useStore();

const props = defineProps({
  claimTitle: {
    type: String
  },
  claimPeriodSlug: {
    type: String
  },
  sectionSlug: {
    type: String
  }
});

const replaceContent = original => original.replace(/{claimPeriodTitle}/g, props.claimTitle);

const validationObject = ref(null);

const handleComplete = async () => {
  validationObject.value = null;
  const validationResult = store.getters["project_grant_allocation/GET_VALIDATION_STATUS"];

  if (validationResult.isValid) {
    const completionResult = await store.dispatch("project_grant_allocation/MARK_AS_COMPLETE", props.claimPeriodSlug);

    if (completionResult.error) {
      validationObject.value = { message: "There was a problem completing the section" };
    } else {
      store.commit("project_grant_allocation/SET_SECTION_DATA", completionResult.data);
      window.location.href = `/claim_periods/${props.claimPeriodSlug}/dashboard`;
    }
  }

  validationObject.value = validationResult;
  setTimeout(() => validationObject.value = null, 10000);
};

const isFullyAllocated = computed(() => store.getters["project_grant_allocation/GET_IS_FULLY_ALLOCATED"]);
const isComplete = computed(() => store.getters["project_grant_allocation/GET_IS_SECTION_COMPLETE"]);
</script>

<style lang="scss" scoped>
:deep() {
  .cms-content {
    p, li {
      margin-bottom: 5px;
      font-weight: 600;
    }

    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }
  }
}
</style>
