<template>
  <div v-if="!loading" class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-7 tw-gap-x-4">
    <!-- Left Half -->
    <div class="tw-col-span-1 lg:tw-col-span-4 tw-flex tw-flex-col tw-gap-4">
      <!-- Claims Here -->
      <div class="tw-flex tw-flex-col tw-gap-4">
        <div v-for="claimPeriod in activeClaims" :key="claimPeriod.id">
          <ClaimPeriodComponent
            :claim-period="claimPeriod"
            :company="company"
            :user-role="userRole"
            :last-active-section="lastActiveSection && lastActiveSection.claim_period_slug === claimPeriod.slug ? lastActiveSection : null"
            :cost-data="company.cost_data[claimPeriod.slug]"
          />
        </div>
        <div class="tw-mx-auto tw-flex tw-items-center tw-justify-between tw-max-w-none tw-border-2 tw-border-disable-grey tw-border-dashed tw-w-full tw-px-4 tw-py-5 tw-rounded-md ">
          <div>{{ company.new_claim_title }}</div>
          <button-component
            id="start-new-claim"
            :link="`/claim_periods/new?from=company_dashboard${userRole === 'Emp admin' ? `?company_id=${company.id}` : ''}`"
            icon="plus-circle"
            text="Start a New Claim"
            size="small"
            :track="{ new_company_dashboard: true }"
            :uppercase="true"
          />
        </div>
      </div>
      <div v-if="awardedClaims.length > 0">
        <Disclosure v-slot="{ open }">
          <DisclosureButton class="tw-p-1 tw-mb-4">
            Awarded claims
            <i :class="[open ? 'tw-transform tw-rotate-180' : '', 'far fa-chevron-down tw-w-5 tw-h-5 tw-pt-1']" />
          </DisclosureButton>
          <DisclosurePanel>
            <div v-for="claimPeriod in awardedClaims" :key="claimPeriod.id">
              <ClaimPeriodComponent
                :claim-period="claimPeriod"
                :company="company"
                :user-role="userRole"
                :awarded="true"
                :cost-data="company.cost_data[claimPeriod.slug]"
              />
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>
    <!-- Right Half -->
    <div class="tw-col-span-1 lg:tw-col-span-3 tw-flex tw-flex-col tw-gap-2">
      <CardComponent title="Compare Claims">
        <template #action>
          <button-component
            v-if="!hideAnalytics"
            id="go-to-analytics"
            text="Go to Analytics"
            link="/analytics"
            size="small"
            :track="{ new_company_dashboard: true }"
          />
        </template>
        <template #content>
          <div v-if="hideAnalytics" class="tw-text-lighter-blue tw-font-medium tw-text-sm tw-mb-1">
            Add more than one claim to start comparing them
          </div>
          <BarChart
            v-else
            :chart-data="dataForAnalyticsChart()"
            :currency="true"
            :show-legend="false"
            :show-grid-lines="false"
            title="Claim Value Over Time"
          />
        </template>
      </CardComponent>
      <!-- FAQs -->
      <CardComponent v-if="faqs.length > 0" title="FAQs">
        <template #content>
          <Disclosure v-for="faq in faqs" v-slot="{ open }" :key="faq.id">
            <DisclosureButton class="tw-py-2 tw-border-0 focus:tw-outline-none tw-w-full tw-text-left tw-flex tw-justify-between">
              <div class="tw-font-semibold tw-flex-1 ">
                {{ faq.question }}
              </div>

              <i :class="[open ? 'tw-transform tw-rotate-180' : '', 'far fa-chevron-down tw-w-5 tw-h-5 tw-pt-1 tw-flex-none tw-text-center']" />
            </DisclosureButton>
            <DisclosurePanel class="tw-text-gray-500 py-2">
              {{ faq.answer }}
            </DisclosurePanel>
          </Disclosure>

          <button type="button" class="focus:tw-outline-none mt-4 mb-2 tw-font-semibold tw-uppercase tw-text-xs" @click="fetchMoreFaqs">
            View more
          </button>
        </template>
      </CardComponent>
      <!-- Marketing Component -->
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import { Disclosure,
  DisclosureButton,
  DisclosurePanel } from "@headlessui/vue";
import CardComponent from "../../../generic/card_component.vue";
import BarChart from "../../../generic/bar_chart.vue";
import ClaimPeriodComponent from "./claim_period_component.vue";

export default {
  name: "CompanyDashboard",
  components: {
    CardComponent,
    ClaimPeriodComponent,
    BarChart,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  },
  props: {
    companySlug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const loading = ref(true);

    const company = computed(() => store.getters["company_dashboard/GET_COMPANY"]);
    const claimPeriods = computed(() => store.getters["company_dashboard/GET_CLAIM_PERIODS"]);
    const userRole = computed(() => store.getters["users/GET_USER_ROLE"]);
    const hideAnalytics = computed(() => claimPeriods.value === null || claimPeriods.value?.length < 2);

    const lastActiveSection = computed(() => store.getters["company_dashboard/GET_LAST_ACTIVE_SECTION"]);
    const faqs = computed(() => store.getters["company_dashboard/GET_FAQS"]);

    const activeClaims = computed(() => _.filter(claimPeriods.value, claimPeriod => claimPeriod.claim_status !== "Complete").reverse());

    const awardedClaims = computed(() => _.filter(claimPeriods.value, claimPeriod => claimPeriod.claim_status === "Complete"));

    function dataForAnalyticsChart() {
      const costData = company.value.cost_data;

      const claimValues = Object.values(costData).map(data => data.claim_value).reverse();
      const labels = claimPeriods.value.map(cp => cp.claim_year_title).reverse();
      const colors = claimPeriods.value.map(cp => (cp.emp_approved ? "#011d32" : "rgba(1, 29, 50, 0.2)")).reverse();

      const datasets = {
        labels,
        datasets: [
          {
            data: claimValues,
            backgroundColor: colors,
            label: "Claim",
            borderRadius: 16,
            borderSkipped: false
          }
        ]
      };

      return datasets;
    }

    function fetchMoreFaqs(event) {
      store.dispatch("company_dashboard/FETCH_FAQS");
    }

    onMounted(() => {
      Promise.all([
        store.dispatch("users/SET_USER"),
        store.dispatch("company_dashboard/FETCH_DATA", { slug: props.companySlug }),
        store.dispatch("company_dashboard/FETCH_FAQS")
      ]).then(() => {
        loading.value = false;
      });
    });

    return {
      loading,
      company,
      claimPeriods,
      userRole,
      activeClaims,
      awardedClaims,
      lastActiveSection,
      dataForAnalyticsChart,
      hideAnalytics,
      faqs,
      fetchMoreFaqs
    };
  }
};
</script>
