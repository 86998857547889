<template>
  <div id="project-selection-project-setup" class="tw-flex tw-flex-col tw-gap-4">
    <card-component
      v-if="!loading"
      id="project-setup-blurb"
      title="Welcome to Project Selection"
    >
      <template #action>
        <state-wrapper component="AssignIcon">
          <AssignIcon
            section-type="ProjectSelection"
            :section-slug="slug"
            assign-icon-type="SectionAssign"
            :fields="stepKeys"
          />
        </state-wrapper>
      </template>
      <template #content>
        <div>
          <div class="tw-space-y-4">
            <p>
              To streamline your claim process and ensure full compliance with HMRC guidelines (see
              <a
                class="tw-text-blue-600 hover:tw-text-blue-800 tw-duration-150"
                href="https://www.empowerrd.com/new-additional-information-form/"
                target="_blank"
              >
                Additional Information Form
              </a>
              ) we've designed a 3-step process to identify the number of R&D projects undertaken by {{ companyName }} during the
              {{ claimPeriodTitle }} claim period. This process will help identify and validate the projects that require detailed information later on.
            </p>
            <p>
              Throughout the process we will ask you to:
            </p>
            <ul class="tw-list-disc tw-list-inside tw-space-y-1">
              <li>
                Tell us about the R&D projects you undertook during the {{ claimPeriodTitle }} claim period
              </li>
              <li>
                Estimate the expenditure for each project
              </li>
              <li>
                Finalise your project selection
              </li>
            </ul>
          </div>
        </div>
      </template>
    </card-component>
    <slot name="errorMessage" />
    <card-component id="project-setup-form">
      <template #content>
        <div class="md:tw-w-4/5 tw-mx-auto tw-space-y-4 tw-my-4">
          <state-wrapper v-slot="{ editable }">
            <div class="tw-inline tw-space-x-3">
              <span class="tw-font-medium tw-text-deep-blue">
                Do you know how many R&D projects your company has undertaken during your claim period?
              </span>
              <saving-spin-indicator
                section-type="ProjectSelection"
                :section-slug="slug"
                :fields="[knowNoProjectField.label]"
              />
            </div>
            <div class="tw-w-full tw-inline-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2">
              <div class="tw-grid tw-grid-cols-2 tw-gap-x-4">
                <label
                  for="know_how_many_rd_projects_true"
                  class="tw-rounded-md tw-px-4 tw-py-2 tw-text-center tw-border tw-border-gray-300 tw-font-semibold focus-within:tw-text-white focus-within:tw-border-deep-blue focus-within:tw-bg-deep-blue tw-transition tw-ease-in-out tw-duration-300 tw-mb-0"
                >
                  <div :class="editable ? 'tw-cursor-pointer' : null" class="tw-inline-flex tw-items-center tw-gap-x-3">
                    <input
                      id="know_how_many_rd_projects_true"
                      v-model="knowNoProjectField.value"
                      type="radio"
                      :value="true"
                      :class="editable ? 'tw-cursor-pointer tw-text-deep-blue focus:tw-ring-0' : 'tw-text-gray-500'"
                      required
                      :disabled="!editable"
                    >
                    Yes
                  </div>
                </label>
                <label
                  for="know_how_many_rd_projects_false"
                  class="tw-rounded-md tw-px-4 tw-py-2 tw-text-center tw-border tw-border-gray-300 tw-font-semibold focus-within:tw-text-white focus-within:tw-border-deep-blue focus-within:tw-bg-deep-blue tw-transition tw-ease-in-out tw-duration-300 tw-mb-0"
                >
                  <div :class="editable ? 'tw-cursor-pointer' : null" class="tw-inline-flex tw-items-center tw-gap-x-3">
                    <input
                      id="know_how_many_rd_projects_false"
                      v-model="knowNoProjectField.value"
                      type="radio"
                      :value="false"
                      :class="editable ? 'tw-cursor-pointer tw-text-deep-blue focus:tw-ring-0' : 'tw-text-gray-500'"
                      :disabled="!editable"
                    >
                    No
                  </div>
                </label>
              </div>
              <state-wrapper component="ValidationMessage">
                <ValidationMessage section-type="ProjectSelection" :section-slug="slug" :field="knowNoProjectField" />
              </state-wrapper>
              <state-wrapper component="DebouncedSave">
                <DebouncedSave
                  :field="knowNoProjectField"
                  section-type="ProjectSelection"
                  :section-slug="slug"
                />
              </state-wrapper>
            </div>
            <SlideFadeTransition>
              <div v-if="knowNoProjectField.value" class="tw-flex tw-flex-col tw-items-stretch">
                <div class="tw-inline tw-space-x-3">
                  <span class="tw-font-medium tw-text-deep-blue">
                    Please confirm how many R&D projects were undertaken during your claim period.
                  </span>
                  <saving-spin-indicator
                    :fields="[noProjectField.label]"
                    section-type="ProjectSelection"
                    :section-slug="slug"
                    @finishedSaving="clean(noProjectField.label)"
                  />
                </div>
                <div v-if="alreadyAddedProjectNo && editable" class="tw-text-xs tw-italic tw-text-light-grey-blue">
                  You have already added {{ alreadyAddedProjectNo }} projects to your claim but please specify the total if you have more.
                </div>
                <div class="tw-w-1/4 tw-mx-auto tw-mt-4">
                  <input
                    id="initial_no_of_projects"
                    v-model="noProjectField.value"
                    type="number"
                    min="1"
                    :class="['tw-text-center tw-font-semibold', css.forms.input, borderStyleNoProjectField]"
                    :disabled="!editable"
                    @input="handleInput"
                  >
                </div>
                <state-wrapper component="ValidationMessage">
                  <ValidationMessage section-type="ProjectSelection" :section-slug="slug" :field="noProjectField" />
                </state-wrapper>
                <state-wrapper component="DebouncedSave">
                  <DebouncedSave
                    :field="noProjectField"
                    section-type="ProjectSelection"
                    :section-slug="slug"
                  />
                </state-wrapper>
              </div>
            </SlideFadeTransition>
            <SlideFadeTransition v-if="editable">
              <div v-if="knowNoProjectField.value === false || noProjectField.value">
                {{ explanatoryBlurb }}
              </div>
            </SlideFadeTransition>
          </state-wrapper>
        </div>
      </template>
    </card-component>
  </div>
</template>

<script>
import { reactive, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { css } from "../../../shared/theme.js";
import { stepTitles } from "./project_selection_steps.js";
import { FIELD_STATE } from "../dynamic_forms/shared/field_state.js";

import DebouncedSave from "../dynamic_forms/save_buttons/debounced_save.vue";
import ValidationMessage from "../dynamic_forms/validation_message.vue";
import SlideFadeTransition from "../transitions/slide_fade.vue";
import AssignIcon from "../dynamic_forms/assign_icon.vue";

export default {
  name: "ProjectSetup",
  components: {
    DebouncedSave,
    SlideFadeTransition,
    ValidationMessage,
    AssignIcon
  },
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  setup() {
    const store = useStore();
    const title = computed(() => stepTitles.project_setup);
    const projectSelection = computed(() => store.getters["project_selection/GET_PROJECT_SELECTION"]);
    const claimPeriodTitle = computed(() => {
      const claimPeriods = store.getters["states/GET_SIDEBAR_CLAIM_PERIODS"];
      return claimPeriods?.find(claimPeriod => claimPeriod.slug === projectSelection.value.claim_period.slug).title_short;
    });
    const loading = computed(() => store.getters["project_selection/GET_LOADING"]);
    const alreadyAddedProjectNo = computed(() => store.getters["project_selection/GET_CLAIM_PROJECTS"]?.length);
    const companyName = computed(() => store.getters["users/GET_COMPANY_NAME"]);

    const knowNoProjectField = reactive({ label: "know_how_many_rd_projects", value: projectSelection.value.know_how_many_rd_projects, state: FIELD_STATE.UNTOUCHED });
    const noProjectField = reactive({ label: "initial_no_of_projects", value: projectSelection.value.initial_no_of_projects, state: FIELD_STATE.UNTOUCHED });

    const borderStyleNoProjectField = ref(null);

    const stepKeys = computed(() => projectSelection.value?.steps?.map(s => s.stepKey));

    function updateBorderStyle(field, stylingConst) {
      if (field.state === FIELD_STATE.EDITED) {
        stylingConst.value = css.borderStyles.edited;
      } else if (field.state === FIELD_STATE.SAVED) {
        stylingConst.value = css.borderStyles.styleSaved;
      }
    }

    function handleInput(event) {
      if (event.target.validity.valid === false) {
        event.target.value = "";
      }
    }

    function clean(field) {
      if (field === "initial_no_of_projects") {
        borderStyleNoProjectField.value = css.borderStyles.untouched;
      }
    }

    const explanatoryBlurb = computed(() => {
      if (knowNoProjectField.value === false) {
        return "You’ve said you are not sure. Please click on the next step to proceed.";
      } if (noProjectField.value <= 3) {
        return "For claims where there has been less than 3 projects you need to write about all the projects and you can add them through the project home.";
      } if (noProjectField.value >= 4) {
        return "You have multiple projects to write about. Please click on continue to proceed to the next step and choose your projects.";
      }
    });

    watch(noProjectField, val => {
      const fieldIsBeingEdited = projectSelection.value.know_how_many_rd_projects = !val.value
                                   || projectSelection.value.know_how_many_rd_projects == null;

      if (fieldIsBeingEdited && val.state < FIELD_STATE.EDITED) {
        val.state = FIELD_STATE.EDITED;
      }
      setTimeout(() => updateBorderStyle(val, borderStyleNoProjectField), 1000);
    });

    return {
      title,
      handleInput,
      css,
      projectSelection,
      loading,
      clean,
      knowNoProjectField,
      noProjectField,
      borderStyleNoProjectField,
      explanatoryBlurb,
      stepKeys,
      alreadyAddedProjectNo,
      companyName,
      claimPeriodTitle
    };
  }
};
</script>
