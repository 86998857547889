import { get } from "../../shared/http.js";

export const FETCH_DATA = "FETCH_DATA";
export const GET_EPW_CONTRACT_REVIEW = "GET_EPW_CONTRACT_REVIEW";
export const GET_EPW_CONTRACTS = "GET_EPW_CONTRACTS";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_EXISTING_FILES = "GET_EXISTING_FILES";
export const GET_EXISTING_FILE = "GET_EXISTING_FILE";
export const GET_LOADING = "GET_LOADING";

export const epw_contract_review = {
  namespaced: true,
  state: {
    epw_contract_review: {},
    epw_contracts: [],
    projects: [],
    loading: true
  },
  mutations: {
    setEPWContactsReview: (state, payload) => state.epw_contract_review = payload,
    setEPWContracts: (state, payload) => state.epw_contracts = payload,
    setProjects: (state, payload) => state.projects = payload,
    setLoading: (state, payload) => state.loading = payload
  },
  actions: {
    [FETCH_DATA]: async ({ state, commit }, payload) => {
      if (_.isEmpty(state.epw_contract_review)) {
        commit("setLoading", true);
      }

      const response = await get(`/api/epw_contract_reviews/fetch_data/${payload.slug}`);
      const epw_review = response[0];

      commit("setEPWContactsReview", epw_review);
      commit("setEPWContracts", epw_review?.epw_contracts);
      commit("setProjects", epw_review?.claim_period?.projects);
      commit("setLoading", false);

      return epw_review;
    }
  },
  getters: {
    [GET_EPW_CONTRACT_REVIEW]: state => state.epw_contract_review,
    [GET_EPW_CONTRACTS]: state => state.epw_contracts,
    [GET_PROJECTS]: state => state.projects,
    [GET_EXISTING_FILES]: state => _.chain(state.epw_contracts)
        .keyBy("slug")
        .mapValues(epwContract => ({
          url: epwContract?.contract_file?.url,
          name: epwContract?.file_name
        }))
        .value(),
    [GET_LOADING]: state => state.loading
  }
};
