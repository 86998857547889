<template>
  <div>
    <div class="tw-prose tw-prose-md tw-max-w-full">
      <p>
        Using the cost categories below, select which suppliers and payments
        should be included in your claim.
      </p>
      <p>
        Please note that all amounts shown from Xero are excluding VAT. Foreign
        currency payments are shown in GBP based on the Xero exchange rate set
        on the payment.
      </p>
      <p>
        Once you have selected relevant costs from the tabs below, you can
        continue to add your payroll information by clicking on the 'Continue to
        Finalise Costs'.
      </p>
    </div>
    <hr class="tw-my-4">
    <div class="tw-flex tw-justify-between tw-items-center">
      <nav class="tw-flex tw-space-x-4 tw-mt-4 tw-mb-6" aria-label="Tabs">
        <div
          v-for="cost_type in cost_types"
          :key="cost_type"
          :class="[
            selected == cost_type
              ? 'tw-bg-deep-blue tw-text-gray-100'
              : 'tw-text-gray-500 hover:tw-text-gray-700 hover:tw-bg-gray-100',
            'tw-px-3 tw-py-2 tw-font-semibold tw-rounded-md tw-cursor-pointer tw-text-lg',
          ]"
          :aria-current="selected == cost_type ? 'page' : undefined"
          @click="selected = cost_type"
        >
          {{ cost_type }}
        </div>
      </nav>
      <div class="tw-text-right">
        <transition
          enter-active-class="tw-transition tw-ease-out tw-duration-100"
          enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
          enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-active-class="tw-transition ease-in duration-75"
          leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
        >
          <div
            v-show="is_saving"
            class="
              tw-text-sm
              tw-font-semibold
              tw-text-green-700
              tw-p-2
              tw-pr-4
              tw-inline
            "
          >
            Saving...
          </div>
        </transition>
      </div>
    </div>
    <template v-for="cost_type in cost_types" :key="cost_type">
      <div v-show="selected == cost_type" class="tw-px-3">
        <template v-for="supplier in selectedSuppliers()">
          <div class="tw-flex tw-items-start tw-my-4" @click.once="fetchSupplierTransactions(supplier.identifier)">
            <div
              class="expanding-holder tw-mt-1"
              @click="
                expanded == supplier.identifier
                  ? (expanded = null)
                  : (expanded = supplier.identifier)
              "
            >
              <span v-show="expanded != supplier.identifier">
                <i class="fa fa-chevron-double-right fas tw-mx-2 color-heather" />
              </span>
              <span v-show="expanded == supplier.identifier">
                <i class="fa fa-chevron-double-down fas tw-mx-2 color-deep-blue" />
              </span>
            </div>
            <div class="tw-w-full">
              <p
                class="tw-font-medium tw-text-lg tw-cursor-pointer"
                @click="
                  expanded == supplier.identifier
                    ? (expanded = null)
                    : (expanded = supplier.identifier)
                "
              /><div>
                {{ supplier.name }}
                <span class="tw-text-gray-400 tw-text-sm  tw-font-normal">
                  <span v-show="false && selected_spend_by_supplier[supplier.identifier] != null">
                    Selected <span class="tw-font-medium">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(supplier.selected_spend) }}</span> of
                  </span>
                  {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(supplier.spend) }} total
                </span>
              </div>
              <transition
                enter-active-class="tw-transition tw-ease-out tw-duration-100"
                enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
                enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
                leave-active-class="tw-transition ease-in duration-75"
                leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
                leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
              >
                <div v-show="expanded == supplier.identifier" class="tw-w-full">
                  <!-- Show spinner whilst we load -->
                  <div
                    v-show="
                      nullOrUndefined(
                        transactions_by_supplier[supplier.identifier]
                      )
                    "
                  >
                    <PulsingLoader />
                  </div>
                  <div
                    v-show="
                      !nullOrUndefined(excluded_count_by_supplier[expanded]) &&
                        excluded_count_by_supplier[expanded] > 0
                    "
                  >
                    <div class="tw-text-gray-700 tw-text-sm tw-mb-2">
                      Some small payments have been excluded.
                    </div>
                  </div>
                  <div
                    class="
                      tw-border-b tw-border-gray-200
                      sm:tw-rounded-lg
                      tw-w-full
                    "
                  >
                    <div
                      v-show="
                        !nullOrUndefined(transactions_by_supplier[expanded]) &&
                          transactions_by_supplier[expanded].length > 0
                      "
                    >
                      <div class="tw-w-full tw-flex tw-items-start tw-mb-2">
                        <div
                          class="
                            tw-w-1/12
                            tw-px-6
                            tw-py-3
                            tw-text-center
                            tw-text-xs
                            tw-font-medium
                            tw-text-gray-100
                            tw-bg-deep-blue
                            tw-uppercase
                            tw-tracking-wider
                          "
                          style="padding-bottom: 11px;"
                        >
                          <input
                            type="checkbox"
                            :class="[theme.forms.checkbox]"
                            @click="Object.values(transactions)
                                      .filter(function (cost) {
                                        return (
                                          cost.source_supplier_identifier ==
                                          supplier.identifier
                                        );
                                      })
                                      .map(function (cost) {
                                        cost.include = $event.target.checked;
                                      });
                                    toggleSupplier(
                                      selected,
                                      supplier.identifier,
                                      $event.target.checked
                                    );
                            "
                          >
                        </div>
                        <div
                          class="
                            tw-w-2/12
                            tw-px-6
                            tw-py-3
                            tw-text-left
                            tw-text-xs
                            tw-font-medium
                            tw-text-gray-100
                            tw-bg-deep-blue
                            tw-uppercase
                            tw-tracking-wider
                          "
                        >
                          Amount
                        </div>
                        <div
                          class="
                            tw-w-2/12
                            tw-px-6
                            tw-py-3
                            tw-text-left
                            tw-text-xs
                            tw-font-medium
                            tw-text-gray-100
                            tw-bg-deep-blue
                            tw-uppercase
                            tw-tracking-wider
                          "
                        >
                          Date
                        </div>
                        <div
                          class="
                            tw-px-6
                            tw-py-3
                            tw-text-left
                            tw-text-xs
                            tw-font-medium
                            tw-text-gray-100
                            tw-bg-deep-blue
                            tw-uppercase
                            tw-tracking-wider
                            tw-w-8/12
                          "
                        >
                          Line Items
                        </div>
                      </div>
                    </div>
                    <template
                      v-for="cost in transactions_by_supplier[
                        supplier.identifier
                      ]"
                      :key="cost.id"
                    >
                      <div class="tw-w-full tw-flex tw-items-start tw-bg-gray-50 tw-my-1">
                        <div class="tw-w-1/12 tw-text-center">
                          <input
                            :id="`cost_${cost.id}`"
                            v-model="cost.include"
                            :data-cost-id="cost.id"
                            type="checkbox"
                            :class="[theme.forms.checkbox]"
                            @click="toggleCost(
                              selected,
                              cost.id,
                              cost.source_supplier_identifier,
                              $event.target.checked
                            )
                            "
                          >
                        </div>
                        <div class="tw-w-2/12">
                          <span class="tw-font-semibold">
                            {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: cost.currency }).format(cost.sub_total) }}
                          </span>
                        </div>
                        <div class="tw-w-2/12">
                          <span class="tw-text-sm">{{ cost.date_paid }}</span>
                        </div>
                        <div class="tw-w-7/12 tw-flex tw-items-start">
                          <div class="tw-text-gray-700 tw-text-xs tw-bg-gray-200 tw-rounded-full tw-px-2.5 tw-py-0.05 tw-mr-1" v-text="`${cost.source_type}`" />
                          <div class="tw-text-xs">
                            <template v-for="line in cost.data.line_items" :key="line.LineItemID">
                              <div class=" tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-x-2">
                                <div v-text="line.Description" />
                                <div v-if="nominalLookup[line.AccountCode]" :class="['tw-text-right', nominalLabelColour(line.AccountCode)]" v-text="nominalLookup[line.AccountCode].name" />
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <hr>
                </div>
              </transition>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import PulsingLoader from "../../../generic/loading_pulse_lines";
import { documentCsrfToken } from "../../../../shared/http.js";
import { css } from "../../../../shared/theme.js";

export default {
  name: "XeroManage",
  components: {
    PulsingLoader
  },
  props: {
    suppliers: { type: Array, required: true },
    nominals: { type: Array, required: true },
    includedNominals: { type: Array, required: true },
    integration_slug: { type: String, required: true }
  },
  data() {
    return {
      cost_types: ["Subcontractors", "Consumables", "Others"],
      selected: "Subcontractors",
      theme: css,
      expanded: null,
      transactions_by_supplier: {},
      excluded_count_by_supplier: {},
      selected_spend_by_supplier: {},
      transactions: {},
      supplier_sub_menu_open: "",
      is_saving: false,
      toggled_on_categories: [] // <%= @included_subcost_types.to_json.html_safe %>,
    };
  },
  computed: {
    nominalLookup() {
      const dictionary = Object.assign({}, ...this.nominals.map(x => ({ [x.code]: x })));
      return dictionary;
    }
  },
  mounted() {
    this.fetchSupplierTransactions(this.expanded);
  },
  methods: {
    nullOrUndefined(obj) {
      return !!(obj === undefined || obj === null);
    },

    selectedSuppliers() {
      // Filters suppliers based on them being in the selected cost category
      return this.suppliers.filter(
        supplier => supplier.subcost_type == this.selected
      );
    },
    setCategoryToggled(category) {
      if (this.toggled_on_categories.indexOf(category) === -1) this.toggled_on_categories.push(category);
    },
    selectedSupplierTransactions(supplier_id) {
      return this.transactions_by_supplier[supplier_id];
    },
    toggleCost(category, cost_id, supplier_id, checked) {
      if (checked) this.setCategoryToggled(category);

      // this.selected_spend_by_supplier[supplier_id] -= amount;

      this.is_saving = true;
      fetch(
        `/integrations/xero/${
          this.integration_slug
        }/toggle-cost-inclusion/${
          cost_id}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": documentCsrfToken()
          },
          method: "POST",
          body: JSON.stringify({
            include: checked,
            source_supplier_identifier: supplier_id
          })
        }
      )
        .then(res => res.json())
        .then(data => {
          this.is_saving = false;
          // this.refreshTotalSpendForSupplier(supplier_id);
        });
    },
    fetchSupplierTransactions(supplier_id) {
      this.excluded_count_by_supplier[supplier_id] = 0;

      fetch(
        `/integrations/xero/${
          this.integration_slug
        }/costs-by-supplier/${
          supplier_id}`
      )
        .then(response => response.json())
        .then(data => this.processSupplierTransactions(supplier_id, data));
    },
    processSupplierTransactions(supplier_id, data) {
      this.excluded_count_by_supplier[supplier_id] = 0;
      this.excluded_count_by_supplier[supplier_id] = data.excluded_count;

      for (const cost of data.costs) this.transactions[cost.id] = cost;

      // if (cost.include === true) {
      //   this.toggled_on_categories[selected] = true;
      // }

      // set(this.transactions_by_supplier, supplier_id, data.costs);
      this.transactions_by_supplier[supplier_id] = data.costs;
      // this.refreshTotalSpendForSupplier(supplier_id);
      return true;
    },
    refreshTotalSpendForSupplier(supplier_id) {
      let val = 0;
      for (const cost of this.transactions_by_supplier[supplier_id]) if (cost.include == true) val += parseFloat(cost.sub_total);

      this.selected_spend_by_supplier[supplier_id] = val;
    },
    toggleSupplier(category, supplier_id, checked) {
      if (checked) this.setCategoryToggled(category);

      this.is_saving = true;
      fetch(
        `/integrations/xero/${
          this.integration_slug
        }/toggle-supplier-inclusion`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": documentCsrfToken()
          },
          method: "POST",
          body: JSON.stringify({
            include: checked,
            source_supplier_identifier: supplier_id
          })
        }
      )
        .then(res => res.json())
        .then(data => {
          this.is_saving = false;
          // this.refreshTotalSpendForSupplier(supplier_id);
        });
    },
    redirectManageCosts() {
      if (this.toggled_on_categories.length < 2) {
        $("#manage-sections-modal").modal();
        return;
      }
      window.location = "<%= edit_cost_path(@claim_period.cost) %>";
    },
    nominalLabelColour(code) {
      return this.includedNominals.indexOf(code) > -1 ? "tw-text-green-500" : "tw-text-red-500";
    }
  }
};
</script>
