<template>
  <div class="tw-w-full">
    <div v-if="auditField" class="tw-hidden tw-w-full tw-bg-cta-blue-100 tw-border tw-border-cta-blue-500 tw-text-cta-blue tw-rounded-md tw-p-2 lg:tw-flex tw-gap-5 tw-flex-col">
      <div class="tw-flex tw-justify-between tw-w-full">
        <div class="tw-w-6 tw-flex-none" />
        <div class="tw-text-base tw-font-bold tw-text-center">
          {{ userType == 'client' ? 'Edit History' : 'Audit History' }} for '{{ auditField.title }}'
        </div>
        <div class="tw-w-6 tw-flex-none" :aria-label="`Hide ${auditField.title} Audit History`" @click="dismiss()">
          <button-component
            icon="times"
            :tooltip="userType == 'client' ? 'Dismiss Edit History' : 'Dismiss Audit History'"
            colour="cta"
            type="light"
            size="xsmall"
          />
        </div>
      </div>
      <Carousel
        v-if="auditHistory && auditHistory.length > 0"
        ref="carousel"
        class="tw-w-full"
        :mouse-drag="false"
      >
        <Slide v-for="audit in auditHistory" :key="audit" class="tw-h-full">
          <div
            class="tw-w-full tw-flex tw-flex-col tw-text-left tw-gap-2"
            :class="audit.type === 'Feedback' ? 'tw-border-l-2 tw-border-yellow-400 tw-pl-2' : ''"
          >
            <div class="tw-inline-flex tw-items-center">
              <tooltip :content="audit.resolved ? 'This feedback has been resolved' : null">
                <i
                  v-if="audit.type === 'Feedback'"
                  :class="audit.resolved ? 'fa-comment-alt-check tw-text-cta-blue' : 'tw-text-yellow-400 fa-comment-alt'"
                  class="feedback far tw-text-base tw-inline tw-mr-1"
                />
              </tooltip>
              <span class="carousel-content tw-text-xs tw-font-medium tw-text-cta-blue tw-flex-1" v-html="audit.content" />
            </div>
            <span class="tw-text-xs tw-italic tw-font-light tw-text-cta-blue-750 tw-flex-none">
              {{ audit.user || 'No Author' }} {{ audit.review_type ? `(${audit.review_type})` : '' }} - {{ cleanDate(audit.created_at) }}
            </span>
          </div>
        </Slide>
        <template #addons="{ slidesCount, currentSlide }">
          <div class="tw-flex tw-justify-between tw-w-full tw-mt-4">
            <div class="tw-w-6 tw-flex-none">
              <div
                v-if="currentSlide > 0"
                id="prev"
                class="tw-cursor-pointer"
                aria-label="Previous Audit"
                @click="prev()"
              >
                <span class="tw-text-cta-blue tw-text-sm">
                  <i class="fa-chevron-left far" />
                </span>
              </div>
            </div>
            <div v-if="slidesCount > 1" id="pagination" class="tw-overflow-scroll tw-text-cta-blue">
              <Pagination />
            </div>
            <div class="tw-w-6 tw-flex-none">
              <div
                v-if="currentSlide < slidesCount - 1"
                id="next"
                class="tw-cursor-pointer"
                aria-label="Next Audit"
                @click="next()"
              >
                <span class="tw-text-cta-blue tw-text-sm">
                  <i class="fa-chevron-right far" />
                </span>
              </div>
            </div>
          </div>
        </template>
      </Carousel>
      <div v-else class="tw-text-sm tw-italic">
        No history found for "{{ auditField.title }}"
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import useEventsBus from "../../../shared/eventBus.js";
import "vue3-carousel/dist/carousel.css";

const datefns = require("date-fns");

export default {
  components: {
    Carousel,
    Slide,
    Pagination
  },
  setup() {
    const { bus } = useEventsBus();
    const carousel = ref(null);

    const store = useStore();
    const auditField = ref(null);

    const userType = computed(() => store.getters["states/GET_USER_TYPE"]);

    const auditHistory = computed(() => store.getters["audit_history/GET_AUDIT_HISTORY_FOR_FIELD"](
      auditField.value?.label
    ));

    function cleanDate(date) {
      return datefns.format(datefns.parseISO(date), "p do MMM yyyy");
    }

    function dismiss() {
      auditField.value = null;
    }

    function next() {
      carousel.value.updateSlideWidth();
      carousel.value.next();
    }

    function prev() {
      carousel.value.updateSlideWidth();
      carousel.value.prev();
    }

    watch(() => bus.value.get("addFieldAudits"), val => {
      const [field] = val ?? [];

      if (field && auditField.value !== field) {
        auditField.value = field;
      }
    });

    watch(auditField, () => {
      if (carousel.value) {
        carousel.value.slideTo(0);
      }
    });

    watch(() => bus.value.get("removeFieldAudits"), val => {
      const [field] = val ?? [];
      dismiss(field);
    });

    return {
      auditField,
      auditHistory,
      carousel,
      cleanDate,
      dismiss,
      userType,
      next,
      prev
    };
  }
};
</script>

<style scoped>
:deep(.carousel__pagination-button) {
  background-color: rgb(191 223 255);
}

:deep(.carousel__pagination-button--active) {
  background-color: rgb(0 128 255);
}

:deep(p), .carousel-content :deep(span), :deep(li) {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  color: rgb(0 128 255) !important;
  margin: 0px !important;
}

.carousel-content :deep(ul) {
  list-style-type: disc !important;
  list-style-position: inside !important;
}

.carousel-content :deep(ol) {
  list-style-position: inside !important;
  list-style-type: decimal !important;
}

.carousel-content :deep(li) {
  padding-left: 0.5rem !important;
}
</style>
