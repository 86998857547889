import { SECTION_STATUS } from "./section_status";

const alwaysHiddenFor = {
  reviewer: [
    "AssignIcon",
    "CompleteIcon",
    "field_blurb",
    "field_group_blurb",
    "IsBeingEdited",
    "ValidationMessage",
    "CompleteInfo",
    "SectionActions",
    "SectionKnowledgeCentre",
    "Feedback",
    "SendForReviewButton",
    "SectionStatus",
    "NarrativeFeedback3",
    "Unlock",
    "UpdateFeedbackForAdmins"
  ],
  admin: [
    "LeaveNarrativeFeedback",
    "LeaveFeedbackIcon",
    "ReviewPanel"
  ],
  client: [
    "LeaveNarrativeFeedback",
    "LeaveFeedbackIcon",
    "ReviewPanel",
    "PreviousResolvedFeedbackIcon",
    "Unlock",
    "UpdateFeedbackForAdmins"
  ]
};

const readOnlyHiddenFor = {
  reviewer: [
    "SaveButton",
    "DebouncedSave",
    "DebouncedSavePair",
    "AddRdProject"
  ],
  admin: [
    "SaveButton",
    "DebouncedSave",
    "DebouncedSavePair",
    "AddRdProject"
  ],
  client: [
    "SaveButton",
    "DebouncedSave",
    "DebouncedSavePair",
    "IsBeingEdited",
    "ValidationMessage",
    "field_blurb",
    "field_group_blurb",
    "AssignIcon",
    "CompleteIcon",
    "CompleteInfo",
    "SaveAll+Delete",
    "SaveAllButton",
    "SectionKnowledgeCentre",
    "NarrativeFeedback3",
    "SectionValidationMessages",
    "AddRdProject"
  ]
};

const hiddenForSectionType = {
  Status: [
    "PreviousResolvedFeedbackIcon",
    "SendForReviewButton",
    "SectionStatus"
  ],
  Grant: [
    "PreviousResolvedFeedbackIcon",
    "AuditHistoryTrigger",
    "CompleteIcon"
  ],
  RdProject: [
    "AssignIcon",
    "CompleteIcon",
    "PreviousResolvedFeedbackIcon",
    "SendForReviewButton",
    "SectionStatus"
  ]
};

const checkForFeatureFlag = {
  Cost: {
    SendForReviewButton: "send_for_review_in_costs",
    SectionStatus: "send_for_review_in_costs",
    OverseasSubcontractorJustification: "international_subcontractors"
  }
};

const sectionsAlwaysEditableForAdmins = [
  "Status",
  "Project",
  "Cost",
  "Grant"
];

// Whitelist function for cases where a component's state is dependent on multiple conditions relating to sectionType/state/user/etc
export function multiDependencyWhitelist(component, params) {
  // controlling assign and complete for BizQ
  if (["CompleteIcon", "AssignIcon", "CompleteInfo"].includes(component) && params?.sectionType === "Status") {
    return params?.sectionStatus !== SECTION_STATUS.CLAIM_SUBMITTED;
  }

  if (["RdProject", "ProjectSelection", "ProjectCostBreakdown"].includes(params?.sectionType) && params?.flags.includes("feature_enquiry_editability_override")) {
    return true;
  }
}

// Keep updated with section_status.js and section_status.rb
// Call with hiddenForSectionStatus[this.sectionStatus]['SendForReviewButton'] for ex

const hiddenForSectionStatus = {
  0: { // IN_PROGRESS
    SectionStatus: true,
    CostFeedbackSummary: true,
    Unlock: true
  },
  1: { // FINALISED
    CompleteIcon: true,
    CompleteInfo: true,
    NarrativeFeedback3: true,
    Unlock: true,
    CostFeedbackSummary: true
  },
  2: { // IN_REVIEW
    CompleteIcon: true,
    CompleteInfo: true,
    SendForReviewButton: true,
    FeedbackSummary: true,
    Unlock: true,
    CostFeedbackSummary: true
  },
  3: { // UNRESOLVED_FEEDBACK
    CompleteIcon: true,
    CompleteInfo: true,
    SendForReviewButton: true,
    NarrativeFeedback3: true,
    Unlock: true
  },
  4: { // SECTION_COMPLETE
    CompleteIcon: true,
    AssignIcon: true,
    CompleteInfo: true,
    FeedbackSummary: true,
    NarrativeFeedback3: true
  },
  5: { // CLAIM_SUBMITTED
    CompleteIcon: true,
    AssignIcon: true,
    CompleteInfo: true,
    SendForReviewButton: true,
    SectionActions: true,
    SectionStatus: true,
    FeedbackSummary: true,
    NarrativeFeedback3: true,
    Unlock: true
  }
};

export {
  alwaysHiddenFor,
  readOnlyHiddenFor,
  hiddenForSectionType,
  hiddenForSectionStatus,
  checkForFeatureFlag,
  sectionsAlwaysEditableForAdmins
};
