import { FIELD_STATE } from "@dynamic_forms/shared/field_state.js";
import { get, post } from "../../shared/http.js";

export const SET_ALL_SECTIONS = "SET_ALL_SECTIONS";
export const SET_SECTION_SLUG = "SET_SECTION_SLUG";
export const SET_SECTION_TYPE = "SET_SECTION_TYPE";
export const SET_LOADING = "SET_LOADING";

export const GET_ALL_SECTIONS = "GET_ALL_SECTIONS";
export const GET_SLUGS = "GET_SLUGS";
export const GET_SECTION_SLUG = "GET_SECTION_SLUG";
export const GET_SECTION_TYPE = "GET_SECTION_TYPE";
export const GET_LOADING = "GET_LOADING";
export const GET_NEW_PROJECT = "GET_NEW_PROJECT";
export const GET_SECTION_BY_SLUG = "GET_SECTION_BY_SLUG";
export const GET_FIELD_REQUIRED = "GET_FIELD_REQUIRED";
export const SET_EDITED_FIELDS_TO_SAVED = "SET_EDITED_FIELDS_TO_SAVED";

export const GET_MULTI_SELECT_OPTIONS = "GET_MULTI_SELECT_OPTIONS";

export const sections = {
  namespaced: true,
  state: {
    allSections: null,
    sectionSlug: null,
    sectionType: null,
    sectionSlugs: null,
    multiSelectOptions: null,
    loading: false,
    newProject: false,
    required_fields: {}
  },
  mutations: {
    setLoading: (state, payload) => state.loading = payload,
    setAllSections: (state, payload) => (state.allSections = payload),
    setSectionSlugs: (state, payload) => (state.sectionSlugs = payload),
    setMultiSelectOptions: (state, payload) => state.multiSelectOptions = payload,
    [SET_SECTION_TYPE]: (state, payload) => (state.sectionType = payload),
    [SET_SECTION_SLUG]: (state, payload) => (state.sectionSlug = payload),
    setNewProject: (state, payload) => (state.newProject = payload),
    setRequiredFields: (state, payload) => (state.required_fields[payload.sectionSlug] = payload.required_fields),
    [SET_EDITED_FIELDS_TO_SAVED]: state => {
      Object.entries(state.allSections).forEach(([key, section]) => {
        section.fields.forEach(field => {
          if (field.state >= FIELD_STATE.EDITED) field.state = FIELD_STATE.SAVED;
        });
      });
    }
  },
  actions: {
    [SET_ALL_SECTIONS]: async ({ commit, state, getters }, payload) => {
      if (!payload.sectionType || _.isArray(getters.allSections)) {
        return;
      }
      if (state.allSections === null) {
        commit("setLoading", true);
      }

      commit("setNewProject", payload.newProject || false);

      let url = `/api/forms/${payload.sectionType}/${payload.sectionSlug}/`;

      if (payload.newProject) { url += "?new_project=true"; }
      if (payload.oldProject) { url += "?old_project=true"; }

      const sectionsData = await get(url);

      const slugs = sectionsData.map(
        section => `${section.type}:${section.slug}`
      );

      sectionsData.forEach(section => {
        commit("setRequiredFields", { sectionSlug: section.type, required_fields: section.required_fields });
      });

      commit("setAllSections", sectionsData);
      commit("setSectionSlugs", slugs);

      const multiSelectColumnsFields = _.chain(sectionsData)
        .map("fields")
        .flattenDeep()
        .filter(f => f.type === "MultiSelect" || "RadioSelect" || "RadioButtonRevealField" || "NestedSelect" || "DropdownSelect")
        .map(f => f.label)
        .uniq()
        .value();

      const multiSelectColumnsSubfields = _.chain(sectionsData)
        .map("fields")
        .flattenDeep()
        .filter(f => f.subfields)
        .map(f => f.subfields)
        .flattenDeep()
        .filter(f => f.type === "MultiSelect" || "RadioSelect" || "RadioButtonRevealField" || "NestedSelect" || "DropdownSelect")
        .map(f => f.label)
        .uniq()
        .value();

      const multiSelectColumns = multiSelectColumnsFields.concat(multiSelectColumnsSubfields);

      await post("/api/forms/mutli_select_options", {
        multi_select: {
          section_type: sectionsData[0]?.type || payload.sectionType,
          section_slugs: _.map(sectionsData, "slug") || [payload.sectionSlug],
          fields: multiSelectColumns
        }
      }).then(data => {
        // @radar nothing done here if request times out or fails
        commit("setMultiSelectOptions", data);
        commit("setLoading", false);
      });

      return sectionsData[0];
    }
  },
  getters: {
    [GET_LOADING]: state => state.loading,
    [GET_ALL_SECTIONS]: state => state.allSections,
    [GET_SECTION_SLUG]: state => state.sectionSlug,
    [GET_SECTION_TYPE]: state => state.sectionType,
    [GET_SLUGS]: state => state.sectionSlugs,
    [GET_SECTION_BY_SLUG]: state => slug => _.find(state.allSections, section => section.slug === slug),
    [GET_MULTI_SELECT_OPTIONS]: state => key => _.get(state.multiSelectOptions, key),
    [GET_NEW_PROJECT]: state => state.newProject,
    [GET_FIELD_REQUIRED]: state => (sectionType, field) => state.required_fields[sectionType]?.includes(field)
  }
};
