<template>
  <div class="tw-w-full tw-flex tw-flex-col tw-gap-y-1.5">
    <Disclosure
      v-for="(values, key) in accordionSections"
      :key="key"
      v-slot="{ open }"
    >
      <div class="tw-bg-gray-50 tw-rounded-lg tw-px-4">
        <DisclosureButton
          class="tw-w-full tw-inline-flex tw-justify-between tw-items-center focus:tw-outline-none tw-py-2.5"
        >
          <div class="tw-inline-flex tw-items-center tw-gap-x-1.5">
            <a
              target="_blank"
              :href="getUrl(key, values[0].section_id)"
              class="tw-group tw-inline-flex tw-items-center tw-gap-x-2.5 tw-text-xs"
            >
              <span class="tw-uppercase tw-font-bold group-hover:tw-text-gray-400 tw-transition tw-delay-50 tw-leading-none">{{ getTitle(key) }}</span>
              <tooltip v-if="open" :content="`Go to ${getTitle(key)}`">
                <i v-if="open" class="far fa-external-link" />
              </tooltip>
            </a>
          </div>
          <div class="tw-inline-flex tw-items-center tw-gap-x-3">
            <template
              v-for="(count, result) in sortedCheckResults[key]"
              :key="result"
            >
              <div
                v-if="count > 0"
                :class="`tw-inline-flex tw-justify-start tw-items-center tw-gap-x-1 tw-py-0.5 tw-pr-2 tw-pl-1.5 tw-bg-${resultColour(result) === 'gray' ? resultColour(result) + '-200' : resultColour(result) + '-100'} tw-rounded-full`"
              >
                <i :class="`fas fa-${resultIcon(result)} tw-text-${resultColour(result)}-500 fa-sm`" />
                <span :class="`tw-text-xs tw-text-${resultColour(result)}-500`">
                  {{ count }}
                </span>
              </div>
            </template>
            <i :class="['far fa-chevron-down tw-duration-300', open ? 'tw-transform tw-rotate-180' : '']" />
          </div>
        </DisclosureButton>
        <Transition
          enter-active-class="tw-transition tw-duration-300 tw-ease-out"
          enter-from-class="tw-transform tw-scale-y-90 tw-opacity-0 tw--translate-y-1/4"
          enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
          move-class="tw-transition tw-duration-300"
          leave-active-class="tw-transition tw-ease-out tw-duration-300"
          leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-to-class="tw-transform tw-opacity-0 tw-scale-y-90 tw--translate-y-1/4"
        >
          <DisclosurePanel class="tw-flex tw-flex-col tw-gap-y-2.5 tw-pt-1 tw-pb-3 tw-gap-x-2">
            <div
              v-for="value in sortedChecks(values)"
              :key="value.id"
              class="tw-flex tw-gap-x-2 tw-items-start"
            >
              <span :class="['tw-flex tw-items-start tw-leading-none tw--mt-0.5', value.result === 'FAIL' && value.severity === 'HIGH' ? 'tw-gap-x-2' : '']">
                <div>
                  <i :class="`fas fa-${resultIcon(value.result)} tw-text-${resultColour(value.result)}-500 fa-sm`" />
                </div>
                <div v-if="value.result === 'FAIL' && value.severity === 'HIGH'">
                  <Tippy content="This failure is for a high severity check">
                    <i class="fas fa-engine-warning tw-text-red-500 fa-sm" />
                  </Tippy>
                </div>
              </span>

              <span class="tw-text-xs tw-leading-none">
                {{ value.description }}
                <span v-if="key === 'Narrative'">
                  for
                  <span class="tw-italic tw-text-gray-400 tw-font-light">
                    {{ findProjectTitle(value.section_id) }}
                  </span>
                  Project
                </span>
              </span>
            </div>
          </DisclosurePanel>
        </Transition>
      </div>
    </Disclosure>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Tippy } from "vue-tippy";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Tippy
  },
  props: {
    accordionSections: {
      type: Object,
      required: true
    },
    sortedCheckResults: {
      type: Object,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: true
    },
    projectTitles: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  methods: {
    getTitle(key) {
      if (key === "Status") {
        return "Business Questionnaire";
      } else if (key === "Cost") {
        return `${key}s`;
      } else {
        return key;
      }
    },
    getUrl(key, section_id) {
      if (key === "Narrative") {
        return `/claim_periods/${this.claimPeriodSlug}/manage_projects`;
      } else if (key === "Status") {
        return `/statuses/${section_id}/edit`;
      } else {
        return `/${`${key.toLowerCase()}s`}/${section_id}/edit`;
      }
    },
    resultIcon(result) {
      if (result === "UNKNOWN" || result === "unknown") {
        return "fas fa-question-circle";
      } else if (result === "PASS" || result === "pass") {
        return "fas fa-check-circle";
      } else {
        return "fas fa-times-circle";
      }
    },
    resultColour(result) {
      if (result === "UNKNOWN" || result === "unknown") {
        return "gray";
      } else if (result === "PASS" || result === "pass") {
        return "green";
      } else {
        return "red";
      }
    },
    sortedChecks(values) {
      const resultOrder = ["FAIL", "PASS", "UNKNOWN"];
      const severityOrder = ["HIGH", "MEDIUM", "LOW"];

      return _.chain(values)
                .orderBy(c => [
                  resultOrder.indexOf(c.result),
                  severityOrder.indexOf(c.severity)
                ])
                .value();
    },
    findProjectTitle(project_id) {
      const titles = Object.fromEntries(this.projectTitles);

      return titles[project_id];
    }
  }
};
</script>
