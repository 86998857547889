<template>
  <div
    v-show="isOpen"
    id="kb"
    class="tw-fixed tw-h-screen tw-overflow-y-scroll tw-w-screen tw-bg-white tw-max-w-md tw-right-0 tw-top-0 tw-z-50 tw-shadow-lg"
    tabindex="0"
    @keydown.esc="isOpen = false"
  >
    <div class="tw-overflow-hidden">
      <section class="" aria-labelledby="slide-over-heading">
        <div class="tw-w-screen tw-max-w-md tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700">
          <div class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-overflow-y-scroll">
            <div class="tw-py-4 tw-px-4 tw-bg-deep-blue sm:tw-px-6">
              <div class="tw-flex tw-items-center tw-justify-between">
                <h2
                  id="slide-over-heading"
                  class="tw-text-lg tw-font-medium tw-text-white"
                >
                  EmpowerRD Knowledge Centre
                </h2>
                <div class="tw-ml-3 tw-h-7 tw-flex tw-items-center" @click="close()">
                  <button class="tw-bg-deep-blue tw-border-none tw-rounded-md tw-text-indigo-200 hover:tw-text-white focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-white">
                    <span class="tw-sr-only">Close panel</span>
                    <svg
                      class="h-6 w-6 tw-h-6 tw-w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <p class="tw-text-sm tw-text-blue-300">
                Context aware, specialised help for your R&D Claim
              </p>
            </div>

            <div class="tw-relative tw-flex-1 tw-px-4 sm:tw-px-6">
              <div>
                <div class="tw-flex tw-items-center tw-justify-center lg:tw-justify-end tw-my-4">
                  <div class="tw-w-full">
                    <label for="search" class="tw-sr-only">Search</label>
                    <div class="tw-relative">
                      <div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
                        <svg
                          class="h-5 w-5 text-gray-400 tw-h-5 tw-w-5 tw-text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        id="search"
                        name="search"
                        class="tw-block tw-border-solid tw-w-full tw-pl-10 tw-pr-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-leading-5 tw-bg-white tw-placeholder-gray-500 focus:tw-outline-none focus:tw-placeholder-gray-400 focus:tw-ring-1 focus:tw-ring-deep-blue focus:tw-border-deep-blue sm:tw-text-sm"
                        placeholder="Search"
                        type="search"
                        @input="debounceSearch"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="errorState">
                <div>
                  <span class="tw-text-red-600">Unfortunately something went wrong</span>
                </div>
              </template>

              <div v-show="searchTerm != ''">
                <div
                  v-show="searchLoading"
                  class="tw-flex tw-items-center tw-justify-center tw-text-center"
                >
                  <div class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4" />
                  <div class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4" />
                  <div class="tw-h-6 tw-rounded-sm tw-bg-gray-200 tw-animate-pulse tw-mb-4" />
                </div>

                <div v-show="searchNoResults && searchResults.length == 0">
                  <section class="tw-prose tw-prose-sm tw-text-center tw-text-gray-500">
                    <p>
                      Unfortunately we couldn't find any matches for that search
                      but we're always working to improve this support and have
                      made a note.
                    </p>
                  </section>
                </div>

                <template v-for="entry in searchResults" :key="entry.id">
                  <div class="tw-mb-3">
                    <div
                      class="tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-mb-2"
                      @click="
                        helpSlugOpen == entry.Slug
                          ? (helpSlugOpen = '')
                          : expandAnswer('Search', entry.id, entry.Slug)
                      "
                    >
                      <div class="tw-w-full">
                        <span
                          class="tw-text-sm tw-font-semibold tw-text-gray-800"
                          v-text="entry.Question"
                        />
                      </div>
                      <div v-show="helpSlugOpen == entry.Slug" class="tw-text-gray-400">
                        <svg
                          class="tw-h-4 tw-w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>

                    <div v-show="helpSlugOpen == entry.Slug">
                      <div
                        class="tw-text-sm tw-text-gray-500 tw-prose tw-prose-sm"
                        v-html="_marked(entry.Answer)"
                      />
                      <KnowledgeCentreHelpfulWidget
                        query="this.searchTerm"
                        feedback-type="Search"
                        feedback-identifier="entry.id"
                      />
                      <hr>
                    </div>
                  </div>
                </template>
              </div>

              <template v-if="helpSlug !== '' && helpData.length > 0">
                <div>
                  <h5 class="tw-text-lg tw-font-bold" v-text="helpData[0].Question" />
                  <div
                    class="tw-text-sm tw-text-gray-500 tw-prose tw-prose-sm"
                    v-html="_marked(helpData[0].Answer)"
                  />
                  <KnowledgeCentreHelpfulWidget
                    query="this.searchTerm"
                    feedback-type="QuestionHelp"
                    feedback-identifier="helpData[0].id"
                  />
                </div>
              </template>

              <template v-if="showQuestion() && helpData.length > 0">
                <div>
                  <h3 class="tw-text-md tw-font-semibold tw-text-gray-800 tw-mb-5" v-text="helpData[0].Title" />
                  <div class="tw-text-sm tw-text-gray-500 tw-prose tw-prose-sm" v-html="_marked(helpData[0].Help)" />
                  <KnowledgeCentreHelpfulWidget
                    query="this.searchTerm"
                    feedback-type="SingleSlug"
                    feedback-identifier="helpData[0].id"
                  />
                </div>
              </template>

              <div v-show="showSection()" class="tw-text-gray-600 tw-text-xs tw-mb-2">
                <p>
                  We're showing you the most commonly asked questions for this
                  part of the data capture process.
                  <span>
                    If you need any further help please drop us a message through our live chat!
                  </span>
                </p>
                <hr>
              </div>

              <span v-if="showSection() && helpData.length > 0">
                <template v-for="entry in helpData" :key="entry.id">
                  <div class="tw-mb-3">
                    <div
                      class="tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-mb-2"
                      @click="
                        helpSlugOpen == entry.Slug
                          ? (helpSlugOpen = '')
                          : expandAnswer('Section', entry.id, entry.Slug)
                      "
                    >
                      <div class="tw-w-full">
                        <span class="tw-text-sm tw-font-semibold tw-text-gray-800" v-text="entry.Question" />
                      </div>
                      <div v-show="helpSlugOpen == entry.Slug" class="tw-text-gray-400">
                        <svg
                          class="tw-h-4 tw-w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>

                    <div v-show="helpSlugOpen == entry.Slug">
                      <div class="tw-text-sm tw-text-gray-500 tw-prose tw-prose-sm" v-html="_marked(entry.Answer)" />

                      <KnowledgeCentreHelpfulWidget
                        query="this.searchTerm"
                        feedback-type="Section"
                        feedback-identifier="entry.id"
                      />
                    </div>
                    <hr>
                  </div>
                </template>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
import KnowledgeCentreHelpfulWidget from "../../generic/knowledge_centre_helpful_widget.vue";

export default {
  components: { KnowledgeCentreHelpfulWidget },
  props: {
    delivery_model: { type: Object },
    industry: { type: String, required: true, default: "software" },
    role: { type: String, required: true, default: "coordinator" },
    section_type: { type: String, required: true, default: "Dashboard" }
  },
  data() {
    return {
      isOpen: false,
      helpSlug: "",
      helpSection: "",
      helpQuestion: "",
      helpSlugOpen: "",
      errorState: false,
      searchTerm: "",
      searchResults: [],
      searchLoading: false,
      searchNoResults: false,
      helpData: [],
      walkthroughData: [],
      debounce: null
    };
  },
  created() {
    window.addEventListener("kb", this.trigger);
  },
  methods: {
    _marked(input) {
      return marked(input);
    },
    debounceSearch(event) {
      this.searchTerm = "";
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchTerm = event.target.value;
        this.submitSearch();
      }, 600);
    },
    trigger(event) {
      const { detail } = event;
      this.isOpen = true;

      if (detail.slug !== undefined) {
        this.helpSlug = detail.slug;
        this.fetchHelpSlug(detail.slug);
      }
      if (detail.section !== undefined) {
        this.helpSection = detail.section;
        this.fetchHelpSection(detail.section);
      }
      if (detail.helpSection !== undefined) {
        this.helpSection = detail.helpSection;
        this.helpQuestion = detail.helpQuestion;
        this.fetchHelpQuestion(detail);
      }
      this.fetchWalkthroughData();

      this.track("Knowledge Centre Opened", {
        showSingleSlug: this.showSingleSlug(),
        showSection: this.showSection(),
        showQuestion: this.showQuestion()
      });
    },
    fetchHelpSection(detail) {
      fetch(
        `/strapi/?query=${
          encodeURIComponent(
            `faqs?Industry_in=all&Industry_in=${
              this.industry
            }&sections.Type=${
              detail}`
          )}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(json => this.processResponse(json))
        .catch(console.error.bind(console));
    },
    fetchHelpQuestion(detail) {
      fetch(
        `/strapi/?query=${
          encodeURIComponent(
            `section-helps?Industry_in=all&Industry_in=${
              this.industry
            }&Section=${
              detail.helpSection
            }&Field=${
              detail.helpQuestion}`
          )}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(json => this.processResponse(json))
        .catch(console.error.bind(console));
    },
    fetchHelpSlug(detail) {
      fetch(`/strapi/?query=${encodeURIComponent(`faqs?Slug=${detail}`)}`, {
        method: "GET"
      })
        .then(res => res.json())
        .then(json => this.processResponse(json))
        .catch(console.error.bind(console));
    },
    fetchWalkthroughData() {
      fetch(
        `/strapi/?query=${
          encodeURIComponent(
            `walkthrough-for-sections?&Section.Type=${
              this.section_type
            }&Role=${
              this.role}`
          )}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(json => (this.walkthroughData = json))
        .catch(console.error.bind(console));
    },
    submitSearch() {
      this.searchLoading = true;
      this.searchNoResults = false;
      this.searchResults = [];
      fetch(
        `/strapi/?query=${
          encodeURIComponent(
            `faqs?Industry_in=all&Industry_in=${
              this.industry
            }&_q=${
              this.searchTerm}`
          )}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(json => this.processSearchResult(json))
        .catch(console.error.bind(console));
    },
    processResponse(json) {
      if (json.error) this.errorState = true;
      else this.helpData = json;
    },

    processSearchResult(json) {
      this.searchLoading = false;
      if (json.error) {
        this.errorState = true;
      } else {
        this.searchResults = json;
        this.track("Knowledge Centre Searched", { query: this.searchTerm,
          result_count: json.length });

        if (json.length === 0) this.searchNoResults = true;
      }
    },
    close() {
      this.helpData = [];
      this.helpSlug = "";
      this.helpSection = "";
      this.helpQuestion = "";
      this.isOpen = false;
      this.track("KnowledgeCentre Closed", {});
    },
    showSingleSlug() {
      return this.helpSlug !== "";
    },
    showSection() {
      return (
        this.helpSection !== ""
        && this.helpQuestion === ""
        && this.searchTerm == ""
      );
    },
    showQuestion() {
      return this.helpSection !== "" && this.helpQuestion !== "";
    },
    getDeliveryModel() {
      if (this.deliveryModel.low_touch) return "low_touch";

      return "high_touch";
    },
    expandAnswer(type, identifier, slug) {
      this.helpSlugOpen = slug;
      this.track("Knowledge Centre Answer Expanded", {
        type,
        identifier,
        query: this.searchTerm
      });
    },
    markHelpful(type, identifier, helpful, sender) {
      this.track("Knowledge Centre Answer Helpful", {
        type,
        identifier,
        helpful,
        query: this.searchTerm
      });
      sender.classList.add("animated", "rubberBand");
      setTimeout(() => {
        sender.classList.remove("animated", "rubberBand");
      }, 1000);
    },
    triggerTourFromKnowledgeCentre(tour_id) {
      triggerTour(tour_id);
    }
  }
};
</script>
