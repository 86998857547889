<template>
  <div class="tw-w-full">
    <tooltip
      v-if="editable && !isReviewer"
      :content="isDisabled ? 'There are no options for you to select' : ''"
      :tooltip-options="{ hideOnClick: false }"
    >
      <dropdown class="tw-relative" :disabled="isDisabled">
        <template #link>
          <div>
            <input
              v-model="query"
              type="text"
              :placeholder="fieldValue || 'Select an option...'"
              :class="[theme.forms.input, 'tw-pr-8 tw-truncate', field.value ? 'placeholder:tw-text-deep-blue' : '']"
              :disabled="isDisabled"
            >
            <span class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer tw-z-0">
              <FadeTransition>
                <i
                  v-if="query !== null && query.length !== 0"
                  class="far fa-lg fa-times-circle tw-text-gray-500"
                  @click="query = null"
                />
              </FadeTransition>
              <i :class="['far fa-lg fa-angle-down tw-text-gray-500 tw-duration-300 ', dropdownRef && dropdownRef.show ? 'tw-transform tw-rotate-180' : '']" />
            </span>
          </div>
        </template>
        <template #items>
          <div :class="['tw-w-full tw-mt-0.5 tw-max-h-60 tw-overflow-y-scroll tw-z-50', theme.dropDown.menuDivFullWidth]">
            <template
              v-for="(option, key) in getOptions(query)"
              :key="key"
            >
              <div
                :class="theme.dropDown.itemDiv"
                @click="queryReturnsNull ? null : setFieldValue(option.identifier)"
              >
                {{ option.label }}
              </div>
            </template>
          </div>
        </template>
      </dropdown>
    </tooltip>
    <div v-else>
      <div class="tw-text-gray-500 tw-text-sm">
        {{ fieldValue || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";

import _ from "lodash";
import { css } from "../../../../shared/theme.js";

import FadeTransition from "../../transitions/fade.vue";

export default {
  components: {
    FadeTransition
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant", "RdProject"];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isReviewer: {
      type: Boolean,
      required: true
    },
    basicForm: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore();

    const theme = css;
    const dropdownRef = ref(null);
    const query = ref(null);
    const queryReturnsNull = ref(false);
    const options = computed(() => store.getters["sections/GET_MULTI_SELECT_OPTIONS"](`${props.sectionType}:${props.sectionSlug}:${props.field.label}`));
    const isDisabled = computed(() => options.value.length === 0);

    const fieldValue = computed(() => _.find(options.value, option => option.identifier === props.field.value)?.label);

    function getOptions(query = null) {
      if (query === null) {
        return options.value;
      }

      const matches = options.value.filter(o => o.toUpperCase().indexOf(query.toUpperCase()) > -1);

      if (matches.length > 0) {
        queryReturnsNull.value = false;

        return matches;
      }
      queryReturnsNull.value = true;

      return [`No matches found for '${query}'`];
    }

    function setFieldValue(optionSlug) {
      props.field.value = optionSlug;
      query.value = null;
      dropdownRef.value?.away();
    }

    return {
      theme,
      options,
      dropdownRef,
      query,
      queryReturnsNull,
      getOptions,
      setFieldValue,
      fieldValue,
      isDisabled
    };
  }
};
</script>
