<template>
  <div v-if="(yesLink || noLink) && !dismissed" class="tw-flex-none tw-flex tw-items-center tw-gap-2 tw-space-evenly">
    <button-component
      v-if="yesLink"
      :id="`${modelType}-${modelSlug}-dashboard-yes`"
      text="Yes"
      :link="yesLink"
      colour="confirm"
      type="light"
      size="large"
    />
    <button-component
      v-if="isBenefit"
      :id="`${modelType}-${modelSlug}-dashboard-no`"
      text="No"
      colour="error"
      type="light"
      size="large"
      @click="delayPrompt"
    />
    <button-component
      v-if="isSubmission"
      :id="`${modelType}-${modelSlug}-dashboard-no`"
      text="No"
      :link="noLink"
      colour="error"
      type="light"
      size="large"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import useEventsBus from "../../../../shared/eventBus";

export default {
  props: {
    modelType: {
      type: String,
      required: true
    },
    modelSlug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { emit } = useEventsBus();
    const dismissed = ref(false);

    const isSubmission = computed(() => props.modelType === "Submission");

    const isBenefit = computed(() => props.modelType === "ClaimBenefit");

    const yesLink = computed(() => {
      if (isSubmission.value) {
        return `/submission/${props.modelSlug}/record_claim_submission?from=dashboard`;
      } else if (isBenefit.value) {
        return `/claim_benefits/${props.modelSlug}/record_claim_benefit?from=dashboard`;
      } else {
        null;
      }
    });

    console.log(isBenefit);

    const noLink = computed(() => {
      if (isSubmission.value) {
        return `/submission/${props.modelSlug}/record_claim_submission?from=dashboard&no=true`;
      } else {
        null;
      }
    });

    function delayPrompt() {
      if (props.modelType === "ClaimBenefit") {
        fetch(`/api/claim_benefits/delay_prompt/?slugs[]=${props.modelSlug}`, {
          method: "PATCH"
        }).then(response => response.json())
          .then(json => {
            if (json) {
              emit("dismissBanner");
              dismissed.value = true;
            }
          });
      }
    }

    return {
      yesLink,
      noLink,
      isBenefit,
      isSubmission,
      dismissed,
      delayPrompt
    };
  }
};
</script>
