<template>
  <span :class="`tw-flex tw-items-center tw-font-bold tw-h-12 ${textColor} ${field === 'grantContributionPercentage' ? 'tw-justify-end tw-mr-5' : 'tw-justify-center'}`">
    {{ text }}
  </span>
</template>

<script setup>
import { computed } from "vue";
import { formatCurrency } from "@shared/helpers";

const props = defineProps({
  allocatedPercentage: {
    type: Number
  },
  totalGrantContribution: {
    type: [Number, String]
  },
  totalAllocatedValue: {
    type: Number
  },
  field: {
    type: String
  }
});

const textColor = computed(() => (props.allocatedPercentage === 100 ? "tw-text-green-700" : "tw-text-red-700"));
const text = computed(() => (props.field === "slider_props"
  ? `${props.allocatedPercentage}% of 100%`
  : `${formatCurrency(props.totalAllocatedValue)} allocated of ${formatCurrency(props.totalGrantContribution)}`));
</script>
