<template>
  <Tab v-slot="{ selected }" as="template">
    <button
      :class="`${tabStyles[selected ? 'selected' : 'unselected']} px-4 py-2 focus:tw-outline-none focus-visible:bg-red tw-rounded-t`"
    >
      {{ text }}
    </button>
  </Tab>
</template>

<script setup>
import { Tab } from "@headlessui/vue";

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  selectedStyle: {
    type: String,
    default: "bg-white"
  },
  unselectedStyle: {
    type: String,
    default: "bg-background-grey"
  }
});

const tabStyles = {
  selected: `${props.selectedStyle} tw-border-x tw-border-t tw-border-disable-grey-250 tw-font-semibold`,
  unselected: props.unselectedStyle
};

</script>
