<template>
  <div class="tw-flex tw-items-center tw-justify-center">
    <DoughnutChart
      :chart-data="chartData"
      :options="options"
      :height="size"
      :width="size"
    />
  </div>
</template>

<script>
import { onBeforeMount, defineComponent, ref, watch } from "vue";
import { DoughnutChart } from "vue-chart-3";

export default defineComponent({
  name: "RadialProgressBar",
  components: { DoughnutChart },
  props: {
    sectionStatus: {
      type: String
    },
    sectionPercentage: {
      type: Number
    },
    size: {
      type: Number,
      default: 40
    },
    animate: {
      type: Boolean,
      defaul: true
    }
  },
  setup(props) {
    const chartData = ref({
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          borderRadius: [50, 0],
          spacing: -2,
          datalabels: {
            display: false
          }
        }
      ]
    });

    function updateBackgroundColour() {
      const status = props.sectionStatus;

      if (status === "Completed") {
        chartData.value.datasets[0].backgroundColor = ["#047857", "#047857"];
      } else if (props.size === 15) {
        chartData.value.datasets[0].backgroundColor = ["#6D8AA0", "#DAE1E7"];
      } else {
        chartData.value.datasets[0].backgroundColor = ["#011d32", "#011d3260"];
      }
    }

    function updateData() {
      const percentage = props.sectionPercentage;

      chartData.value.datasets[0].data = [(percentage), (100 - percentage)];
    }

    watch(() => props.sectionPercentage, () => {
      updateData();
    });

    watch(() => props.sectionStatus, () => {
      updateBackgroundColour();
    });

    const options = ref({
      cutout: "72%",
      responsive: false,
      animation: { duration: props.animate ? 1000 : 0 },
      plugins: { tooltip: { enabled: false } },
      layout: { padding: { top: -10 } }
    });

    onBeforeMount(() => {
      updateBackgroundColour();
      updateData();
    });

    return { chartData, options };
  }
});
</script>
