<template>
  <Field
    :field="fieldProxy(field)"
    :section-slug="section.slug"
    :section-type="section.type"
  />
</template>

<script>
import { reactive } from "vue";
import { mapGetters } from "vuex";

import { FIELD_STATE } from "./shared/field_state.js";
import { GET_COMPLETED_ASSIGNMENTS } from "../../../vuex-store/modules/assignments.js";
import Field from "./field.vue";

export default {
  components: {
    Field
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    groupName: {
      type: String,
      default: ""
    },
    groupIndex: {
      type: Number
    },
    field: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      expanded: false,
      hasBeenClosed: false,
      isCompleted: false
    };
  },
  computed: {
    orderedFields() {
      return _.chain(this.fields).orderBy("order").value();
    },
    ...mapGetters({
      completedAssignments: `assignments/${GET_COMPLETED_ASSIGNMENTS}`
    })
  },
  mounted() {
    this.unwatch = this.$store.watch(
      (_state, getters) => getters[`assignments/${GET_COMPLETED_ASSIGNMENTS}`],
      (newValue, _oldValue) => {
        const completedFields = _.chain(this.fields)
          .map(field => _.get(
            newValue,
            `${this.section.type}:${this.section.slug}:${field?.label}`
          ))
          .reject(a => (a ?? false) === false)
          .value();

        this.isCompleted = this.fields.length === completedFields.length;

        if (this.isCompleted && this.expanded === true && this.hasBeenClosed === false) {
          this.expanded = false;
          this.hasBeenClosed = true;
        } else if (this.isCompleted) {
          this.hasBeenClosed = true;
        }
      }
    );
    if (!this.isCompleted) {
      this.expanded = this.groupIndex == 0;
    }
  },
  methods: {
    fieldProxy(field) {
      const reactiveField = reactive(field);
      reactiveField.state = field.state ?? FIELD_STATE.UNTOUCHED;
      return reactiveField;
    }
  },
  unmount() {
    this.unwatch();
  }
};
</script>
