<template>
  <div class="tw-px-3">
    <ProjectGrantAllocationIntro
      :claim-title="claimTitle"
      :claim-period-slug="claimPeriodSlug"
      :section-slug="sectionSlug"
    />
  </div>

  <div class="tw-space-y-7">
    <div v-for="(project, index) in projects" :key="index" class="tw-bg-white tw-p-3">
      <ProjectGrantAllocationInputs :project="project" :claim-title="claimTitle" />

      <DataTable
        v-if="project.cost_breakdowns"
        class="tw-mt-10"
        :data="project.cost_breakdowns"
        :columns="tableColumns"
        :footer-props="{
          allocatedPercentage: project.allocatedPercentage,
          totalGrantContribution: project.grant_claim_allocations.reduce((acc, next) => acc + parseFloat(next.amount), 0),
          totalAllocatedValue: project.cost_breakdowns.reduce((acc, next) => acc + parseFloat(next.project_grant_cost_breakdown_amount || 0), 0),
        }"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { formatCurrency } from "@shared/helpers";
import SliderComponent from "@generic/slider_component.vue";
import DataTable from "@generic/data_table.vue";
import ProjectGrantAllocationIntro from "./project_grant_allocation_intro.vue";
import ProjectGrantAllocationInputs from "./project_grant_allocation_inputs.vue";
import ProjectGrantAllocationTableFooter from "./project_grant_allocation_table_footer.vue";

const props = defineProps({
  claimTitle: {
    type: String
  },
  claimPeriodSlug: {
    type: String
  },
  sectionCompletedAt: {
    type: String
  },
  sectionSlug: {
    type: String
  }
});

const store = useStore();
store.commit("project_grant_allocation/SET_SECTION_COMPLETED_AT", props.sectionCompletedAt);
store.dispatch("project_grant_allocation/FETCH_RD_PROJECT_DATA", { claimPeriodSlug: props.claimPeriodSlug });

const projects = computed(() => store.getters["project_grant_allocation/GET_RD_PROJECTS"]);

const tableColumns = [
  {
    header: "Cost Category",
    field: "name",
    alignmentOverride: "tw-text-left"
  },
  {
    header: "TQE (£)",
    field: "total_qualifying_expenditure",
    formatter: formatCurrency,
    knowledgeCentreFaq: "what-is-the-total-qualifying-expenditure-column"
  },
  {
    header: "Total Grant Contribution (£)",
    field: "project_grant_cost_breakdown_amount",
    formatter: formatCurrency,
    colClasses: "tw-w-1/3",
    footerComponent: ProjectGrantAllocationTableFooter
  },
  {
    header: "% Grant Contribution Per Cost Category",
    field: "slider_props",
    component: SliderComponent,
    colClasses: "tw-px-7 tw-w-2/5",
    knowledgeCentreFaq: "why-do-i-need-to-allocate-a-percentage-of-my-grant-s-contribution-to-my-project-s",
    footerComponent: ProjectGrantAllocationTableFooter
  }
];
</script>
