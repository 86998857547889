<template>
  <component
    :is="group ? 'transition-group' : 'transition'"
    :tag="tag"
    name="fade"
    v-bind="$attrs"
    v-on="hooks"
  >
    <slot />
  </component>
</template>

<script>
import { Transition, TransitionGroup } from "vue";

export default {
  components: {
    Transition,
    TransitionGroup
  },
  props: {
    group: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: "div"
    }
  },
  computed: {
    hooks() {
      return {
        leave: this.setAbsolutePosition
      };
    }
  },
  methods: {
    setAbsolutePosition(el) {
      if (this.group) el.style.position = "absolute";
    }
  }
};
</script>

<style scoped>
  .fade-move,
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.4s ease-in-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
