<template>
  <div class="tw-min-h-full tw-max-w-full">
    <div v-if="claimPeriodSlug">
      <!-- Open sidebar icon for small screens -->
      <div class="tw-absolute tw-flex tw-items-center tw-justify-center tw-top-16 tw-left-0 tw-m-2 tw-h-10 tw-w-10">
        <button
          class="tw-outline-none tw-h-8 tw-w-8 tw-rounded-full tw-text-white tw-shadow-md tw-bg-deep-blue tw-border-muted-blue tw-border tw-border-solid focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-white"
          :class="hideSooner === true ? 'xl:tw-hidden' : 'lg:tw-hidden'"
          @click="openSidebar()"
        >
          <span class="sr-only">Open sidebar</span>
          <i class="fas fa-chevron-right tw-pl-0.5" aria-hidden="true" />
        </button>
      </div>
      <!-- Pop out sidebar for small screens -->
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog
          as="div"
          class="tw-fixed tw-top-0 tw-bottom-0 tw-flex tw-z-40"
          :class="hideSooner === true ? 'xl:tw-hidden' : 'lg:tw-hidden'"
          @close="closeSidebar()"
        >
          <TransitionChild
            as="template"
            enter="tw-transition-opacity tw-ease-linear tw-duration-900"
            enter-from="tw-opacity-0"
            enter-to="tw-opacity-100"
            leave="tw-transition-opacity tw-ease-linear tw-duration-300"
            leave-from="opacity-100"
            leave-to="tw-opacity-0"
          >
            <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75" />
          </TransitionChild>
          <TransitionChild
            as="template"
            enter="tw-transition tw-ease-in-out tw-duration-900 tw-transform"
            enter-from="tw--translate-x-full"
            enter-to="tw-translate-x-0"
            leave="tw-transition tw-ease-in-out tw-duration-300 tw-transform"
            leave-from="tw-translate-x-0"
            leave-to="tw--translate-x-full"
          >
            <div class="tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-xs tw-w-full tw-bg-deep-blue">
              <TransitionChild
                as="template"
                enter="tw-ease-in-out tw-duration-900"
                enter-from="tw-opacity-0"
                enter-to="tw-opacity-100"
                leave="tw-ease-in-out tw-duration-300"
                leave-from="tw-opacity-100"
                leave-to="tw-opacity-0"
              >
                <div class="tw-absolute tw-flex tw-items-center tw-justify-center tw-top-0 tw-right-0 tw-z-10 tw-my-2 tw-h-10 tw-w-10 tw--mr-5">
                  <button
                    :class="hideSooner === true ? 'xl:tw-hidden' : 'lg:tw-hidden'"
                    class="tw-outline-none tw-h-8 tw-w-8 tw-text-white tw-rounded-full tw-border-muted-blue tw-border-solid tw-border tw-shadow-lg tw-bg-deep-blue focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-white"
                    @click="closeSidebar()"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <i class="fas fa-chevron-left tw-pr-0.5" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="tw-block tw-h-full tw-w-64 tw-flex-none tw-bg-deep-blue">
                <div class="tw-top-0 tw-self-start tw-sticky tw-py-4">
                  <div
                    v-if="loading"
                    id="loading"
                    class="tw-w-fit tw-text-center tw-m-4"
                  >
                    <pulsingloader size="large" color="tw-bg-light-muted-blue" />
                  </div>
                  <ClaimSidebarContent
                    v-if="!loading"
                    :claim-data="claimData"
                    :claim-periods="claimPeriods"
                  />
                </div>
              </div>
            </div>
          </TransitionChild>
          <div class="tw-flex-shrink-0 tw-w-14" aria-hidden="true" />
        </Dialog>
      </TransitionRoot>
    </div>
    <!-- Main page -->
    <div class="tw-flex tw-mx-auto tw-w-full">
      <!-- Sidebar -->
      <div v-if="claimPeriodSlug" :class="hideSooner === true ? 'xl:tw-block' : 'lg:tw-block'" class="tw-hidden tw--mt-16 tw-w-64 tw-flex-none">
        <div id="claim-sidebar" class="tw-top-0 tw-bg-deep-blue tw-mt-16 tw-self-start tw-sticky tw-min-h-screen tw-py-2">
          <div
            v-if="loading"
            id="loading"
            class="tw-w-full tw-text-center tw-px-4"
          >
            <pulsingloader size="large" color="tw-bg-light-muted-blue" />
          </div>
          <ClaimSidebarContent
            v-if="!loading"
            :claim-data="claimData"
            :claim-periods="claimPeriods"
          />
        </div>
      </div>
      <!-- Page Content -->
      <div
        :class="[
          'tw-flex-1 tw-flex tw-flex-col tw-m-4 tw-min-h-screen tw-grow',
          hideSooner === true ? '2xl:tw-mx-16' : ' 2xl:tw-mx-auto'
        ]"
      >
        <slot name="page-content" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot } from "@headlessui/vue";

import ClaimSidebarContent from "../../components/claim_sidebar/claim_sidebar_content.vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ClaimSidebarContent
  },
  props: {
    claimPeriodSlug: {
      type: String,
      required: true
    },
    pageString: {
      type: String,
      required: false
    },
    options: {
      type: Object,
      default: {}
    },
    hideSooner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const sidebarOpen = ref(false);
    const store = useStore();
    const loading = ref(true);
    const claimData = computed(() => store.getters["states/GET_SIDEBAR_CLAIM_DATA"]);
    const claimPeriods = computed(() => store.getters["states/GET_SIDEBAR_CLAIM_PERIODS"]);

    function openSidebar() {
      sidebarOpen.value = true;
    }
    function closeSidebar() {
      sidebarOpen.value = false;
    }

    onMounted(() => {
      Promise.all([
        store.dispatch("users/SET_USER"),
        store.dispatch("states/FETCH_SIDEBAR_DATA", { claimPeriodSlug: props.claimPeriodSlug, pageString: props.pageString }),
        store.dispatch("states/FETCH_ALL_CLAIM_SECTION_STATES", { claimPeriodSlug: props.claimPeriodSlug }),
        store.dispatch("feedbacks/FETCH_CLAIM_FEEDBACK", { claimPeriodSlug: props.claimPeriodSlug })
      ]).then(() => {
        loading.value = false;
      });
    });

    return {
      sidebarOpen,
      openSidebar,
      closeSidebar,
      loading,
      claimData,
      claimPeriods
    };
  }
};
</script>
