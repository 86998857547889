<template>
  <div v-if="dropdownData.projects.length > 0">
    <div class="tw-uppercase tw-text-light-grey-blue tw-text-xs tw-font-semibold tw-mt-3">
      Individual Projects
    </div>
    <div class="tw-flex tw-flex-col tw-gap-4 tw-mt-3">
      <div v-for="(project, idx) in dropdownData.projects" :key="idx">
        <div class="tw-inline-flex tw-items-center tw-gap-1">
          <span>
            <a
              :href="project.access ? `/projects/${project.slug}/build/project` : null"
              :class="project.overall_progression == 100.0 ? 'tw-text-green-700 hover:tw-text-green-900' : 'tw-text-deep-blue hover:tw-text-deep-blue-hover'"
              class="tw-text-xs tw-font-semibold tw-inline-flex tw-items-center tw-gap-2"
              @click="trackClick(project)"
            >
              {{ project.title }}
              <i v-if="project.overall_progression == 100.0" class="fas fa-check-circle" />
            </a>
          </span>
        </div>
        <tooltip
          :id="`project_${project.slug}_progression`"
          :content="projectTooltip(project)"
        >
          <a
            :href="projectProgressionLink(project)"
            class="tw-group tw-inline-flex tw-w-full tw-mt-2 tw-items-center tw-gap-2 tw-text-xs"
            @click="trackProgressClick(project)"
          >
            <span v-if="sectionStatus(project.overall_progression) === 'In Progress'">
              <i class="fas fa-exclamation-triangle tw-text-yellow-400 group-hover:tw-text-yellow-500" />
            </span>
            <div :class="barBgColor(project.overall_progression)" class="tw-h-1 tw-rounded tw-flex-1">
              <div class="tw-h-1" :class="barColor(project.overall_progression)" :style="`width: ${project.overall_progression}%`" />
            </div>
          </a>
        </tooltip>
      </div>
    </div>
  </div>
  <div v-else class="tw-mt-2">
    <a :href="`/projects/?claim_period_id=${claimPeriodSlug}`" class="tw-text-light-grey-blue tw-text-xs tw-italic">
      No Projects yet on this claim. Click to add a project
    </a>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  props: {
    sectionData: {
      type: Object,
      required: false
    },
    dropdownData: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const track = inject("track");

    function trackClick(project) {
      track("Claim Progression Link Clicked", {
        section: props.sectionData?.title || props.sectionType,
        project,
        claimPeriodslug: props.claimPeriodSlug
      });
    }

    function trackProgressClick(project) {
      track("Claim Progression Link Clicked", {
        section: props.sectionData?.title || props.sectionType,
        project,
        progressClick: true,
        claimPeriodslug: props.claimPeriodSlug
      });
    }

    function barBgColor(progression) {
      if (progression === 0.0) {
        return "tw-bg-gray-100";
      } else if (progression < 100.0) {
        return "tw-bg-yellow-100";
      } else {
        return "tw-bg-white";
      }
    }
    function barColor(progression) {
      if (progression === 100.0) {
        return "tw-rounded tw-bg-green-700";
      } else if (progression > 0.0) {
        return "tw-rounded-l tw-bg-yellow-300 group-hover:tw-bg-yellow-400";
      } else {
        return "";
      }
    }
    function sectionStatus(progression) {
      if (progression === 100.0) {
        return "Completed";
      } else if (progression === 0.0) {
        return "Not Started";
      } else {
        return "In Progress";
      }
    }
    function projectProgressionLink(project) {
      if (project.project_progression < 100.0 || project.overall_progression === 90.0) {
        return `/projects/${project.slug}/build/project`;
      } else if (project.overall_progression < 90.0 && !project.new_project_methodology) {
        return `/projects/${project.slug}/build/challenge_start`;
      } else {
        return null;
      }
    }
    function projectTooltip(project) {
      if (project.project_progression < 100.0) {
        return `${project.title} is still incomplete, click to continue the project`;
      } else if (project.overall_progression === 90.0) {
        return `You've finished all the details for ${project.title}, once you're happy with it, click here to go and send it for review`;
      } else if (project.no_challenges && !project.new_project_methodology) {
        return `${project.title} has no challenges, continue the project by adding challenges`;
      } else if (project.overall_progression < 100.0 && !project.new_project_methodology) {
        return `The Challenges step of ${project.title} is still incomplete, click to continue`;
      } else {
        return null;
      }
    }

    return {
      trackClick,
      trackProgressClick,
      barBgColor,
      barColor,
      sectionStatus,
      projectProgressionLink,
      projectTooltip
    };
  }
};
</script>
