<template>
  <table
    v-if="benefitsProxy !== []"
    class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-border-b tw-border-gray-200"
  >
    <thead>
      <tr>
        <th :class="[cssClasses.th, 'tw-w-44']" />
        <th :class="[cssClasses.th, 'tw-w-2/12']">
          Company
        </th>
        <th :class="[cssClasses.th, 'tw-w-1/12']">
          Claim
        </th>
        <th :class="[cssClasses.th, 'tw-w-44']">
          Manager
        </th>
        <th :class="[cssClasses.th, 'tw-text-center']">
          <i class="fas fa-pound-sign tw-text-center tw-mr-2" />
        </th>
        <th :class="[cssClasses.th, 'tw-text-center tw-w-44']">
          <Tippy content="Data Collection">
            <i class="fas fa-percent tw-text-center tw-mr-2" />
          </Tippy>
        </th>
        <!-- <th :class="cssClasses.th">Scheme</th> -->
        <th :class="[cssClasses.th, 'tw-text-center tw-w-44']">
          Who
        </th>
        <!-- <th :class="cssClasses.th">
          <tippy content="When was the report shared with the client">
            <i class="fas fa-share tw-text-center tw-mr-2"></i>
          </tippy>
        </th> -->

        <th :class="[cssClasses.th, 'tw-border-b-4 tw-border-b-solid tw-text-center', sortedStyle('undefined.best_submitted_date_epoch')]" @click="setSortColumnWithType(undefined, 'best_submitted_date_epoch', SORT.NUMBER)">
          <Tippy content="When was the claim submitted to HMRC?  If it's client led, their submission within the platform must have been marked as submitted to see a date here">
            <i class="fas fa-mailbox tw-text-center tw-mr-2" />
          </Tippy>
        </th>
        <!-- <th :class="[cssClasses.th, 'tw-text-center']">
          <tippy content="When did we last chase this benefit?">
            <i class="fas fa-user-clock tw-text-center tw-mr-2"></i>
          </tippy>
        </th> -->
        <th :class="[cssClasses.th, 'tw-border-b-4 tw-border-b-solid tw-text-center', sortedStyle('undefined.date_for_referral_epoch')]" @click="setSortColumnWithType(undefined, 'date_for_referral_epoch', SORT.NUMBER)">
          <Tippy content="When did HMRC set a referral date for?">
            <i class="fas fa-user-clock tw-text-center tw-mr-2" />
          </Tippy>
        </th>
        <th :class="[cssClasses.th, 'tw-border-b-4 tw-border-b-solid tw-w-72', sortedStyle('status.status')]" @click="setSortColumnWithType('status', 'status', SORT.STRING)">
          Status
        </th>
        <th :class="[cssClasses.th, 'tw-border-b-4 tw-border-b-solid tw-w-64', sortedStyle('due_status.status')]" @click="setSortColumnWithType('due_status', 'status', SORT.STRING)">
          Due
        </th>

        <th :class="[cssClasses.th, 'tw-border-b-4 tw-border-b-solid tw-text-center', sortedStyle('due_days.days')]" @click="setSortColumnWithType('due_days', 'days', SORT.NUMBER)">
          Days to Due
        </th>
        <th :class="[cssClasses.th, 'tw-text-center']">
          Paid?
        </th>

        <th :class="[cssClasses.th, 'tw-w-44']" />
      </tr>
    </thead>
    <tbody>
      <template

        v-for="[idx, benefit] in filterBenefitsProxy()"
        :key="benefit.slug"
      >
        <tr :class="idx % 2 == 0 ? 'tw-bg-white' : 'tw-bg-gray-50'">
          <td :class="[cssClasses.td, 'tw-text-center']">
            <div class="tw-flex tw-gap-x-1 tw-justify-end">
              <div v-if="benefit.enquiry_opened == true">
                <tooltip content="This claim has an enquiry open">
                  <i class="fas fa-binoculars fa-1x tw-text-red-200" />
                </tooltip>
              </div>
              <div
                class="tw-cursor-pointer"
                @click="toggleFlag(benefit, !benefit.flagged)"
              >
                <Tippy
                  :content="
                    benefit.flagged
                      ? 'This Benefit has been flagged'
                      : 'Flag this Benefit'
                  "
                  delay="500"
                >
                  <i
                    :class="`fas fa-exclamation-triangle fa-1x tw-z-1 ${flagCssClass(
                      benefit.flagged
                    )}`"
                  />
                </Tippy>
              </div>
            </div>
          </td>
          <td :class="[cssClasses.td, 'tw-...']">
            <a :href="`/companies/${benefit.claim_period.company.slug}`" target="_blank" class="tw-text-sm">
              {{ getProperty(benefit, 'claim_period.company.name') }}
            </a>
          </td>
          <td :class="[cssClasses.td, '']">
            <a :href="`/claim_periods/${benefit.claim_period.slug}`" target="_blank" class="tw-text-xs">
              {{ getProperty(benefit, 'claim_period.title_short') }}
            </a>
          </td>
          <td :class="[cssClasses.td, 'tw-text-xs']">
            {{ getProperty(benefit, 'claim_period.claim_manager.first_name') }}
          </td>
          <td :class="[cssClasses.td, 'tw-text-center']">
            <Tippy
              :content="expectedBenefitPopup(benefit)"
              :allow-h-t-m-l="true"
              :interactive="true"
            >
              <a :href="`/claim_benefits/${benefit.slug}`" target="_blank" class="tw-text-sm">
                £<span
                  class="tw-font-medium"
                  v-text="nFormatter(benefit.claim_period._claim_value, 1)"
                />
              </a>
            </Tippy>
          </td>
          <td :class="[cssClasses.td, 'tw-text-center tw-text-sm']">
            {{ getProperty(benefit, 'claim_period._claim_progress') }}%
          </td>
          <!--<td :class="cssClasses.td">
            <span class="tw-font-medium">{{
              schemeString(
                benefit.claim_period["has_sme?"],
                benefit.claim_period["has_rdec?"]
              )
            }}</span>
          </td>-->
          <td :class="[cssClasses.td, 'tw-text-center']">
            <Tippy v-if="benefit.claim_period.finance_document" :content="whoGetsBenefitPopup(benefit)" :allow-h-t-m-l="true">
              <a
                :href="`/finance_documents/${benefit.claim_period.finance_document.slug}/edit`"
                target="_blank"
                class="tw-text-sm"
              >
                {{ whoGetsBenefitString(benefit) }}
              </a>
            </Tippy>
          </td>
          <!-- <td :class="[cssClasses.td, 'tw-text-xs']">
            {{
              formatDate(
                benefit.claim_period.submission &&
                  benefit.claim_period.submission.sent_to_client_at
              )
            }}
          </td> -->
          <td :class="[cssClasses.td, 'tw-text-xs tw-text-center']">
            {{ formatDate(benefit.best_submitted_date) }}
          </td>
          <td :class="[cssClasses.td, 'tw-text-xs tw-text-center']">
            {{ formatDate(benefit.date_for_referral) }}
          </td>
          <td :class="cssClasses.td">
            <a :href="`/claim_benefits/${benefit.slug}`" target="_blank" class="tw-text-sm">
              {{ getProperty(benefit, 'status.status') }}
            </a>
          </td>

          <td :class="cssClasses.td">
            <Tippy :content="benefit.due_status.methodology">
              <a :href="`/claim_benefits/${benefit.slug}`" target="_blank" class="tw-text-sm">
                {{ getProperty(benefit, 'due_status.status') }}
              </a>
            </Tippy>
          </td>

          <td :class="[cssClasses.td, 'tw-text-xs tw-text-center']">
            <Tippy :content="getProperty(benefit.due_days, 'methodology')">
              {{ getProperty(benefit.due_days, 'days') }}
            </Tippy>
          </td>

          <td :class="[cssClasses.td, 'tw-text-xs tw-text-center']">
            <i :class="[invoicePaidIcon(getProperty(benefit, 'invoice_paid?'))]" />
          </td>

          <td :class="cssClasses.td">
            <BenefitsTableDropDown :benefit="benefit" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { Tippy } from "vue-tippy";
import { nFormatter } from "../../../../shared/helpers.js";
import BenefitsTableDropDown from "./benefits_table_dropdown.vue";

import { SORT, dynamicSort } from "../../../../shared/tables_sort.js";

const datefns = require("date-fns");

export default {
  components: { Tippy, BenefitsTableDropDown },
  props: {
    query: {
      type: String,
      default: ""
    },
    benefits: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      sortOrder: true,
      sortedColumn: "",
      SORT,
      subMenuClaimPeriod: 0,
      benefitsProxy: [],
      cssClasses: {
        td: "tw-px-1 tw-py-2 tw-whitespace-no-wrap tw-leading-5 tw-text-gray-500",
        th: "tw-px-1 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-48",
        thnoborder: "tw-px-1 tw-py-3 tw-bg-gray-100 tw-text-left tw-text-xs tw-leading-4 tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-w-48"
      }
    };
  },
  watch: {
    benefits(val) {
      this.benefitsProxy = val;
    }
  },
  created() {
    this.debounceToggleFlagAPI = _.debounce(this.toggleFlagAPI, 500);
  },
  methods: {
    nFormatter(num, digits) {
      return nFormatter(num, digits);
    },
    formatDate(string, format = "do MMM") {
      if (string === undefined || string == null) {
        return null;
      } else {
        try {
          return datefns.format(datefns.parseISO(string), format);
        } catch {
          return "Error";
        }
      }
    },
    filterBenefitsProxy() {
      const y = Object.entries(this.benefitsProxy).filter(
        x => x[1].search_string.toUpperCase().indexOf(this.query.toUpperCase()) > -1
      );

      return y;
    },
    whoGetsBenefitString(benefit) {
      let string = "";

      const fd = benefit.claim_period.finance_document;
      if (fd == null || fd.submit_by_emp == null) {
        string += "?";
      } else if (fd.submit_by_emp == true) {
        string += "E";
      } else if (fd.submit_by_emp == false) {
        string += "C";
      }

      string += "/";

      if (fd == null || fd.who_receives_benefit == null) {
        string += "?";
      } else if (fd.who_receives_benefit == "empowerrd") {
        string += "E";
      } else if (fd.who_receives_benefit == "client") {
        string += "C";
      }

      return string;
    },
    whoGetsBenefitPopup(benefit) {
      let string = "";

      const fd = benefit.claim_period.finance_document;
      if (fd == null || fd.submit_by_emp == null) {
        string += "Don't know who was submitting";
      } else if (fd.submit_by_emp == true) {
        string += "EmpowerRD was submitting";
      } else if (fd.submit_by_emp == false) {
        string += "Client was submitting";
      }

      string += "<br />";

      if (fd == null || fd.who_receives_benefit == null) {
        string += "Don't know who gets benefit";
      } else if (fd.who_receives_benefit == "empowerrd") {
        string += "EmpowerRD receives benefit";
      } else if (fd.who_receives_benefit == "client") {
        string += "Client receives benefit";
      }

      return string;
    },
    getProperty: (object, path) => _.get(object, path),
    expectedBenefitPopup(benefit) {
      let string = `<b>£${Number(benefit.claim_period._claim_value).toLocaleString()}`;
      string += "</b><br /><br />";
      benefit.claim_period._expected_benefits.forEach(benefit_part => {
        string
          += `${benefit_part.scheme.toString()
          } - ${
            benefit_part.type
          } - £${
            Number(benefit_part.value).toLocaleString()}`;
        string += "<br />";
      });
      return string;
    },
    schemeString(has_sme, has_rdec) {
      let string = "";
      string += has_sme === true ? "S" : "";
      string += has_rdec === true ? "R" : "";
      return string;
    },
    flagCssClass(flagValue) {
      return flagValue
        ? "tw-text-red-800 tw-animate-pulse"
        : "tw-text-gray-200";
    },
    toggleFlag(claim_benefit, flag_value) {
      claim_benefit.flagged = flag_value;
      this.debounceToggleFlagAPI(claim_benefit, flag_value);
    },
    toggleFlagAPI(claim_benefit, flag_value) {
      fetch(`/api/claim_benefits/${claim_benefit.slug}/update_attribute`, {
        method: "PATCH",
        body: JSON.stringify({ flagged: flag_value })
      });
    },
    setSortColumnWithType(object, property, sortType) {
      const sortedColString = `${String(object)}.${property.toString()}`;
      if (sortedColString === this.sortedColumn) {
        this.sortOrder = !this.sortOrder;
      }

      this.sortedColumn = sortedColString;

      this.benefitsProxy = dynamicSort(this.benefitsProxy, object, property, this.sortOrder, sortType);
    },
    sortedStyle(columnName) {
      if (this.sortedColumn === columnName) {
        return this.sortOrder ? "tw-border-red-400" : "tw-border-green-500";
      } else {
        return "";
      }
    },
    invoicePaidIcon(paid) {
      return paid
        ? "tw-text-green-600 fas fa-check"
        : "tw-text-red-600 fas fa-times";
    }
  }
};
</script>
