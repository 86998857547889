<template>
  <transition name="fade">
    <div v-if="humanReadableEditedDate">
      <div class="tw-text-xs tw-text-green-500 tw-mt-2">
        {{ humanReadableEditedDate }}
      </div>
    </div>
  </transition>
</template>

<script>
import { formatRelative } from "date-fns";
import { mapGetters } from "vuex";

import { GET_EDITED_FIELD } from "../../../vuex-store/modules/fields.js";
import { GET_USER_SLUG } from "../../../vuex-store/modules/users.js";

export default {
  props: {
    sectionFieldName: {
      type: String,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    sectionSlug: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      edited: `fields/${GET_EDITED_FIELD}`,
      getUserSlug: `users/${GET_USER_SLUG}`
    }),
    humanReadableEditedDate() {
      const fieldId = `${this.sectionType}:${this.sectionSlug}:${this.sectionFieldName}`;
      const slug = this.getUserSlug;
      const user = this.edited(fieldId, slug);

      if ((user?.name ?? null) !== null) {
        let userName = user.name;

        if (slug === user?.slug) {
          userName = "you";
        }

        if ((user?.date ?? null) !== null) {
          return `Edited by ${userName} ${formatRelative(
            new Date(user.date),
            Date.now()
          )}`;
        }
      }
    }
  }
};
</script>
