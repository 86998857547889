<template>
  <SlideFade>
    <div
      v-show="isOpen"
      id="leaveFeedbackPanel"
      class="tw-fixed tw-h-screen tw-overflow-y-scroll tw-w-screen tw-bg-white tw-max-w-md tw-right-0 tw-top-0 tw-z-50 tw-shadow-lg"
      tabindex="1"
      @keyup.esc="isOpen = false"
    >
      <div class="tw-overflow-hidden">
        <section aria-labelledby="slide-over-heading">
          <div class="tw-w-screen tw-max-w-md">
            <div class="tw-h-full tw-flex tw-flex-col tw-bg-white tw-shadow-xl tw-overflow-y-scroll">
              <div class="tw-py-4 tw-px-4 tw-bg-deep-blue sm:tw-px-6">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <h2
                    id="slide-over-heading"
                    class="tw-text-lg tw-font-medium tw-text-white"
                  >
                    Leave Feedback
                  </h2>
                  <div
                    class="tw-ml-3 tw-h-7 tw-flex tw-items-center"
                    @click="isOpen = false"
                  >
                    <button
                      class="
                        tw-bg-deep-blue
                        tw-border-none
                        tw-rounded-md
                        tw-text-indigo-200
                        hover:tw-text-white
                        focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-white
                      "
                    >
                      <span class="tw-sr-only">Close panel</span>
                      <i class="fas fa-times tw-text-indigo-200" />
                    </button>
                  </div>
                </div>

                <p class="tw-text-sm tw-text-blue-300">
                  Answer details + leave feedback
                </p>
              </div>

              <div class="tw-py-4 tw-px-4 sm:tw-px-6">
                <dl class="tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-4 sm:tw-grid-cols-2 tw-my-4">
                  <div v-for="col in getColumns" :key="col.label" class="sm:tw-col-span-1">
                    <dt class="tw-text-sm tw-font-medium tw-text-gray-500">
                      {{ col.label }}
                    </dt>
                    <dd class="tw-mt-1 tw-text-sm tw-text-gray-900">
                      {{ fieldValue(col) }}
                    </dd>
                  </div>
                </dl>

                <!-- Overseas Justification -->
                <div v-if="showInternationalSubcontractorWork && colRefObject == 'pc_rd' && field.uk_based === false" class="tw-space-y-3 tw-mb-4">
                  <h3 class="tw-text-lg tw-font-medium">
                    Overseases Justification
                  </h3>
                  <div :class="['tw-text-sm', field.overseas_exemption_justification ? 'tw-text-gray-900' : 'tw-italic tw-text-gray-500' ]">
                    {{ field.overseas_exemption_justification || 'No justification provided...' }}
                  </div>
                  <div class="tw-w-full tw-inline-flex tw-justify-between">
                    <label for="overseas_exemption_approved_true" class="tw-mb-0">
                      <div class="tw-flex tw-items-center tw-gap-x-2">
                        <input
                          id="overseas_exemption_approved_true"
                          v-model="justificationApproved"
                          type="radio"
                          :class="[theme.forms.radio]"
                          :value="true"
                        >
                        <div class="tw-font-medium tw-text-gray-700">Approve Justification</div>
                      </div>
                    </label>
                    <label for="overseas_exemption_approved_false" class="tw-mb-0">
                      <div class="tw-flex tw-items-center tw-gap-x-2">
                        <input
                          id="overseas_exemption_approved_false"
                          v-model="justificationApproved"
                          type="radio"
                          :class="[theme.forms.radio]"
                          :value="false"
                        >
                        <div class="tw-font-medium tw-text-gray-700">Reject Justification</div>
                      </div>
                    </label>
                  </div>
                </div>

                <h3 class="tw-text-lg tw-font-medium">
                  Feedback
                </h3>

                <!-- Div shows if feedback is already set -->
                <div class="tw-my-4">
                  <textarea
                    ref="feedbackTextArea"
                    v-model="feedbackText"
                    rows="4"
                    :class="theme.forms.textarea"
                  />

                  <!-- RAT buttons -->
                  <div class="tw-flex tw-my-4 tw-flex-col tw-gap-y-1.5">
                    <div>
                      <label for="rat_review" class="tw-mb-0">
                        <div class="tw-flex tw-items-center tw-gap-x-2">
                          <input
                            id="rat_review"
                            v-model="feedbackRat"
                            type="radio"
                            :class="[theme.forms.radio]"
                            value="Review/Accept"
                          >
                          <div class="tw-font-medium tw-text-gray-700">Review/Accept</div>
                        </div>
                      </label>
                    </div>

                    <div>
                      <label for="rat_changes_needed" class="tw-mb-0">
                        <div class="tw-flex tw-items-center tw-gap-x-2">
                          <input
                            id="rat_changes_needed"
                            v-model="feedbackRat"
                            type="radio"
                            :class="[theme.forms.radio]"
                            value="Changes Needed"
                          >
                          <div class="tw-font-medium tw-text-gray-700">Changes Needed</div>
                        </div>
                      </label>
                    </div>

                    <div v-if="colRefObject == 'pc_rd'">
                      <label for="rat_non_qualifying" class="tw-mb-0">
                        <div class="tw-flex tw-items-center tw-gap-x-2">
                          <input
                            id="rat_non_qualifying"
                            v-model="feedbackRat"
                            type="radio"
                            :class="[theme.forms.radio]"
                            value="Accept Non-Qualifying"
                          >
                          <div class="tw-font-medium tw-text-gray-700">Non-Qualifying</div>
                        </div>
                        <span class="tw-text-xs tw-font-normal text-muted">This feedback will force clients to remove or reduce to 0% the %R&D</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="tw-gap-y-2 tw-grid tw-grid-cols-1">
                  <ButtonComponent
                    text="Save Feedback"
                    icon="save"
                    :full-width="true"
                    @click="saveFeedback"
                  />
                  <ButtonComponent
                    v-if="feedback !== undefined"
                    text="Delete Feedback"
                    icon="trash"
                    :full-width="true"
                    colour="error"
                    @click="deleteFeedback()"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </SlideFade>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import { Tippy } from "vue-tippy";
import { css } from "../../../shared/theme.js";
import { truncate } from "../../../shared/helpers.js";

import { costColumns, fundedColumns, pcRdColumn } from "../../components/costs/cost-columns.js";
import { epw_columns } from "../epw_contracts/epw-contract-columns.js";

import ButtonComponent from "../../generic/button_component.vue";
import SlideFade from "../../components/transitions/slide_fade.vue";

export default {
  components: {
    Tippy,
    ButtonComponent,
    SlideFade
  },
  props: {
    options: {
      type: Object,
      default: {}
    }
  },
  setup() {
    const theme = css;
    const store = useStore();
    const isOpen = ref(false);
    const field = ref({});
    const feedback = ref({});
    const review = ref({});
    const sectionType = ref("");
    const colRefDatabase = ref("");
    const colRefObject = ref("");
    const sectionSlug = ref("");
    const parentSectionType = ref("");
    const feedbackText = ref("");
    const feedbackRat = ref("Review/Accept");
    const claimPeriodSlug = ref("");
    const feedbackTextArea = ref(null);
    const justificationApproved = ref(null);
    const showInternationalSubcontractorWork = ref(false);

    const getColumns = computed(() => {
      if (sectionType.value == "") return [];
      if (sectionType.value == "EpwContract") return epw_columns[sectionType.value];

      return _.concat(costColumns[sectionType.value], fundedColumns, pcRdColumn);
    });

    function trigger(event) {
      // reset defaults
      feedback.value = {};
      field.value = {};
      feedbackText.value = "";
      feedbackRat.value = "Review/Accept";
      justificationApproved.value = null;

      field.value = event.detail.field;
      sectionType.value = event.detail.sectionType;
      colRefDatabase.value = event.detail.colRefDatabase;
      colRefObject.value = event.detail.colRefObject;
      claimPeriodSlug.value = event.detail.claimPeriodSlug;
      parentSectionType.value = event.detail.parentSectionType;
      sectionSlug.value = event.detail.sectionSlug;
      feedback.value = store.getters["feedbacks/GET_FEEDBACKS_FOR_FIELD"](sectionType.value, field.value.slug, colRefDatabase.value);
      review.value = store.getters["reviews/GET_REVIEW_FOR_SECTION"];
      isOpen.value = true;
      showInternationalSubcontractorWork.value = event.detail.showInternationalSubcontractorWork;

      if (event.detail.field?.overseas_exemption_approved !== null) {
        justificationApproved.value = event.detail.field.overseas_exemption_approved;

        if (justificationApproved.value === false) feedbackRat.value = "Accept Non-Qualifying";
      }
    }

    function saveFeedback() {
      if (feedback.value === undefined) createFeedback();
      else updateFeedback();

      setTimeout(() => isOpen.value = false, 300);
    }

    function createFeedback() {
      store.dispatch("feedbacks/CREATE_FEEDBACK", {
        fields: {
          col_ref: colRefDatabase.value,
          feedback: feedbackText.value,
          review_id: review.value.id,
          resolve_activity_type: feedbackRat.value,
          feedbacked_value: colRefObject.value === "contract_file" ? field.value.file_name : field.value[colRefObject.value] // @todo passes the costs_sub hash
        },
        feedbacked_value: colRefObject.value === "contract_file" ? field.value.file_name : field.value[colRefObject.value],
        feedbackable_type: sectionType.value,
        feedbackable_slug: field.value.slug,
        claimPeriodSlug: claimPeriodSlug.value
      });
    }

    function updateFeedback() {
      store.dispatch("feedbacks/UPDATE_FEEDBACK", {
        fields: {
          feedback: feedbackText.value,
          feedbacked_value: colRefObject.value === "contract_file" ? field.value.file_name : field.value[colRefObject.value],
          review_id: review.value.id,
          resolve_activity_type: feedbackRat.value
        },
        feedbacked_value: colRefObject.value === "contract_file" ? field.value.file_name : field.value[colRefObject.value],
        feedbackable_type: sectionType.value,
        feedbackable_slug: field.value.slug,
        append: false,
        claimPeriodSlug: claimPeriodSlug.value,
        id: feedback.value.id
      });
    }

    function deleteFeedback() {
      store.dispatch("feedbacks/DELETE_FEEDBACK", {
        id: feedback.value.id
      });
      setTimeout(() => isOpen.value = false, 300);
    }

    function fieldValue(column) {
      const { format } = column;
      let value = null;

      if (column?.nested_field !== (null || undefined)) value = field.value[column.field] !== (null || undefined) ? field.value[column.field][column.nested_field] : null;
      else value = field.value[column.field] !== (null || undefined) ? field.value[column.field] : field.value[column.database_field];

      if (format === "Boolean" && value === false) return "No";
      if (format === "Boolean" && value === true) return "Yes";
      if (format && [null, undefined].includes(value)) return "N/A";
      if (format === "Percent") return Math.floor(value);
      if (format === "Currency") return `£${value}`;

      return truncate(value, 24);
    }

    onMounted(() => { window.addEventListener("triggerLeaveFeedbackPanel", trigger); });

    watch(feedback, newFeedback => {
      feedbackText.value = newFeedback !== undefined ? newFeedback.feedback : "";
      feedbackRat.value = newFeedback !== undefined ? newFeedback.resolve_activity_type : "Review/Accept";
    });

    function validate(model, fields = null) {
      store.dispatch("validations/VALIDATE", [model, fields?.slug || props.slug, fields]);
    }

    watch(justificationApproved, newValue => {
      if (newValue === false) feedbackRat.value = "Accept Non-Qualifying";

      field.value.overseas_exemption_approved = newValue;
      validate(sectionType.value, field.value);
    });

    return {
      isOpen,
      field,
      feedback,
      getColumns,
      theme,
      sectionType,
      saveFeedback,
      deleteFeedback,
      feedbackText,
      feedbackRat,
      review,
      colRefObject,
      colRefDatabase,
      fieldValue,
      truncate,
      justificationApproved,
      showInternationalSubcontractorWork
    };
  }
};
</script>
