<template>
  <!-- Two-way Data-Binding -->
  <div :id="id">
    <quill-editor
      :ref="id"
      v-model:value="content"
      :options="editorOption"
      @change="onEditorChange($event)"
    />
    <input
      :id="`${id}_input`"
      type="hidden"
      :name="input_name"
      :value="content"
    >
  </div>
</template>

<script>
// You can also register Quill modules in the component

export default {
  props: {
    input_name: { type: String, required: true },
    initial_content: { type: String, required: true },
    section: { type: String, required: true },
    question: { type: String, required: true },
    id: { type: String, required: true },
    project_id: { type: Number, required: false },
    challenge_id: { type: Number, required: false },
    placeholder: { type: String, required: false },
    slug: { type: String, required: false },
    auto_feedback: { type: Boolean, default: false },
    encode: { type: Boolean, required: false }
  },
  data() {
    return {
      content: null,
      lastContent: null,
      editorOption: {
        modules: {
          toolbar: [["bold", { list: "ordered" }, { list: "bullet" }, "clean"]]
        },
        theme: "snow",
        placeholder: this.getPlaceHolder()
      }
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  created() {
    this.content = this.encode ? atob(this.initial_content) : this.initial_content;
    this.lastContent = this.encode ? atob(this.initial_content) : this.initial_content;
    this.editorOption.placeholder = this.placeholder;
  },
  methods: {
    getPlaceHolder() {
      return this.placeholder;
    },
    onEditorChange({ quill, html, text }) {
      if (this.lastContent !== html) {
        window.dispatchEvent(new CustomEvent("quilledited", { detail: { slug: this.slug, section: this.section, question: this.question, text, id: this.id, auto_feedback: this.auto_feedback } }));
        window.unsaved = true;
      }

      this.content = html;
      this.lastContent = html;
    }
  }
};
</script>
