<template>
  <div>
    <Currency
      :id="`grant-claim-allocation-${grantClaimAllocation.id}`"
      :label="grantClaimAllocation.grant_title_with_date"
      class="tw-w-60"
      :value="parseFloat(grantClaimAllocation.amount) || ''"
      :editable="!isComplete"
      use-emitter
      :save-state="saveState"
      @currency-input="handleCurrencyInput"
    />
  </div>
</template>

<script setup>
import _ from "lodash";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Currency from "@components/dynamic_forms/fields/currency.vue";

const store = useStore();

const props = defineProps({
  grantClaimAllocation: {
    type: Object
  },
  projectId: {
    type: Number
  }
});

const isComplete = computed(() => store.getters["project_grant_allocation/GET_IS_SECTION_COMPLETE"]);

const saveState = ref(null);

const handleCurrencyInput = async value => {
  store.commit("project_grant_allocation/UPDATE_PROJECT_GRANT_ALLOCATION_VALUE", {
    grantClaimAllocationId: props.grantClaimAllocation.id,
    projectId: props.projectId,
    value: parseFloat(value || 0)
  });

  saveGrantValue();
};

const saveGrantValue = _.debounce(
  async () => {
    saveState.value = "saving";

    const result = await store.dispatch("project_grant_allocation/SAVE_PROJECT_GRANT_ALLOCATION_VALUE", {
      grantClaimAllocationId: props.grantClaimAllocation.id,
      projectId: props.projectId
    });

    setTimeout(() => saveState.value = result.success ? "saveSuccess" : "saveError", 1000);
  },
  1000
);
</script>
