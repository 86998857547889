export default {
  CostsEmployee: {
    label: "Employee Costs",
    key: "CostsEmployee",
    table: [
      {
        class: "tw-w-4/12",
        key: "rd_col_1",
        label: "Name",
        format: "String"
      },
      {
        class: "tw-w-3/12",
        key: "rd_col_2",
        label: "Job Title ",
        format: "String"
      },
      {
        class: "tw-w-4/12",
        key: "total_allocated",
        label: "% Of Time Distributed Across R&D Projects",
        format: "RadialProgressBar",
        has_knowledge_center: true,
        faq_slug: "how-do-i-allocate-percentages-for-costs-across-projects"
      }
    ],
    expandableTable: {
      allocation: "% Of Time Distributed Across R&D Projects"
    }
  },
  CostsSubcontractor: {
    label: "External Worker Costs",
    key: "CostsSubcontractor",
    table: [
      {
        class: "tw-w-4/12",
        key: "rd_col_1",
        label: "External Worker",
        format: "String"
      },
      {
        class: "tw-w-3/12",
        key: "rd_col_2",
        label: "Work Description",
        format: "String"
      },
      {
        class: "tw-w-4/12",
        key: "total_allocated",
        label: "% Of Time Distributed Across R&D Projects",
        format: "RadialProgressBar",
        has_knowledge_center: true,
        faq_slug: "how-do-i-allocate-percentages-for-costs-across-projects"
      }
    ],
    expandableTable: {
      allocation: "% Of Time Distributed Across R&D Projects"
    }
  },
  CostsConsumable: {
    label: "Consumable Costs",
    key: "CostsConsumable",
    table: [
      {
        class: "tw-w-4/12",
        key: "rd_col_1",
        label: "Consumable",
        format: "String"
      },
      {
        class: "tw-w-3/12",
        key: "rd_col_2",
        label: "Description",
        format: "String"
      },
      {
        class: "tw-w-4/12",
        key: "total_allocated",
        label: "% Distributed Across R&D Projects",
        format: "RadialProgressBar",
        has_knowledge_center: true,
        faq_slug: "how-do-i-allocate-percentages-for-costs-across-projects"
      }
    ],
    expandableTable: {
      allocation: "% Distributed Across R&D Projects"
    }
  },
  CostsOther: {
    label: "Other Costs",
    key: "CostsOther",
    table: [
      {
        class: "tw-w-7/12",
        key: "rd_col_1",
        label: "Description",
        format: "String"
      },
      {
        class: "tw-w-4/12",
        key: "total_allocated",
        label: "% Distributed Across R&D Projects",
        format: "RadialProgressBar",
        has_knowledge_center: true,
        faq_slug: "how-do-i-allocate-percentages-for-costs-across-projects"
      }
    ],
    expandableTable: {
      allocation: "% Distributed Across R&D Projects"
    }
  }
};
