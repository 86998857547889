<template>
  <div class="tw-min-w-0 tw-flex tw-flex-col tw-gap-6">
    <div class="tw-flex tw-justify-between tw-gap-4">
      <div>
        <h1 class="tw-text-2xl tw-font-semibold tw-mb-5">
          3rd Party Contracts
        </h1>
        <div v-if="!isReviewer" class="tw-prose tw-prose-md">
          <p>
            The contracts you have with externally provided workers (EPWs) need to be structured in a certain way in order to be able to claim R&D relief this type of expenditure. Uploading the contracts will enable us to review and approve them.
          </p>
        </div>
      </div>
      <div v-if="!loading" class="tw-flex-none">
        <state-wrapper ref="sectionStatus" component="SectionStatus">
          <div class="tw-bg-white tw-rounded-lg tw-shadow tw-flex tw-flex-col tw-gap-2 lg:tw-gap-0 lg:tw-py-4 lg:tw-px-6 tw-py-2 tw-px-2">
            <SectionStatus :section="epwContractReview" />
          </div>
        </state-wrapper>
        <state-wrapper component="ReviewPanel">
          <ReviewPanel
            ref="reviewPanel"
            :user-slug="userSlug"
            :section-slug="slug"
            section-type="EpwContractReview"
            :block-completion="blockReviewCompletion"
            :block-completion-message="blockReviewCompletionMessage"
          />
        </state-wrapper>
        <state-wrapper component="SendForReviewButton">
          <SendForReviewButton :section="epwContractReview" />
        </state-wrapper>
      </div>
    </div>
    <state-wrapper v-if="epwContractReview && !loading" component="SectionValidationMessages">
      <SectionValidationMessages :slug="slug" type="EpwContractReview" />
    </state-wrapper>
    <banner-component
      v-if="!loading && isReviewer && hasReviewStarted && epwContractReview.directors_list.length > 0"
      :dismiss="true"
      :title="`Director(s) at ${companyName}:`"
      type="cta"
    >
      <template #content>
        <div class="tw-mt-2">
          <li v-for="director in epwContractReview.directors_list" :key="director" class="tw-text-sm tw-text-cta-blue">
            {{ director }}
          </li>
        </div>
      </template>
    </banner-component>
    <div class="tw-overflow-x-scroll tw-bg-white tw-rounded-md tw-p-4">
      <div class="tw-block tw-mb-4">
        <div class="tw-flex tw-gap-2 tw-items-center">
          <h3 class="tw-text-lg tw-font-medium tw-text-gray-900 tw-mb-0">
            Contracts
          </h3>
        </div>
      </div>

      <div class="tw-shadow-sm tw-max-w-full">
        <table id="epw-contracts-table" class="tw-w-full">
          <thead>
            <tr>
              <th :class="[theme.tables.th_dark, 'tw-w-1/4']">
                Externally Provided Worker
              </th>
              <th :class="[theme.tables.th_dark, 'tw-w-1/6']">
                Amount
              </th>
              <th :class="[theme.tables.th_dark, 'tw-w-1/4']">
                Contract File
              </th>
              <th :class="[theme.tables.th_dark]">
                Project
              </th>
              <th v-if="isReviewer || isAdmin" :class="[theme.tables.th_dark]">
                Approved
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && epwContracts">
            <tr
              v-for="epwContract in orderedEpwContracts"
              :key="epwContract.slug"
            >
              <td :class="[theme.tables.td]">
                <div class="tw-w-full tw-flex tw-gap-x-2 tw-items-center">
                  <div
                    v-if="epwContract.costs_subcontractor"
                    :class="[
                      'tw-flex tw-gap-x-2 tw-items-center tw-transition-bg tw-duration-300 tw-ease-in-out tw-delay-150',
                      hasFeedback('EpwContract', epwContract.slug, 'costs_subcontractor_id') ? `${backgroundColor(getUnresolvedFeedbackForField('EpwContract', epwContract.slug, 'costs_subcontractor_id'))} tw-rounded-md tw-px-2 tw-py-1.5 tw-font-medium` : ''
                    ]"
                  >
                    <a
                      v-if="epwContract.costs_subcontractor"
                      :href="costLink"
                      target="_blank"
                      :class="[textColor(getUnresolvedFeedbackForField('EpwContract', epwContract.slug, 'costs_subcontractor_id'))]"
                    >
                      {{ epwContract.costs_subcontractor.name }}
                    </a>
                    <SectionFeedback
                      v-if="hasFeedback('EpwContract', epwContract.slug, 'costs_subcontractor_id')"
                      :feedback="getUnresolvedFeedbackForField('EpwContract', epwContract.slug, 'costs_subcontractor_id') || null"
                      section-type="EpwContract"
                      :section-slug="epwContract.slug"
                      parent-section-type="EpwContractReview"
                      :parent-section-slug="slug"
                      col-ref="costs_subcontractor_id"
                      :user-role="userRole"
                      :claim-period-slug="claimPeriodSlug"
                    />
                  </div>
                  <div v-if="isReviewer || isAdmin" class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-text-center tw-gap-x-1.5 tw-gap-y-0.5">
                    <state-wrapper component="LeaveFeedbackIcon">
                      <LeaveFeedbackIcon
                        :field="epwContract"
                        :existing-feedback="getUnresolvedFeedbackForField('EpwContract', epwContract.slug, 'costs_subcontractor_id')"
                        section-type="EpwContract"
                        :options="{claimPeriodSlug: claimPeriodSlug}"
                        :section-slug="epwContract.slug"
                        parent-section-type="EpwContractReview"
                        col-ref-database="costs_subcontractor_id"
                        col-ref-object="costs_subcontractor_id"
                        :user-slug="userSlug"
                      />
                    </state-wrapper>
                    <state-wrapper component="PreviousResolvedFeedbackIcon">
                      <div class="tw-text-lg">
                        <PreviousResolvedFeedbackIcon
                          section-type="EpwContract"
                          :section-slug="epwContract.slug"
                          col-ref="costs_subcontractor_id"
                        />
                      </div>
                    </state-wrapper>
                  </div>
                </div>
              </td>
              <td :class="[theme.tables.td]">
                <div v-if="epwContract.costs_subcontractor.amount">
                  £{{ nFormatter(epwContract.costs_subcontractor.amount, 1) }}
                </div>
              </td>
              <td :class="[theme.tables.td]">
                <div class="tw-w-full tw-flex tw-gap-x-2 tw-items-center">
                  <file-upload
                    http-method="PATCH"
                    :existing-file="existingFiles[epwContract.slug]"
                    :url="`/api/epw_contracts/upload/${epwContract.slug}`"
                    :is-new-file="true"
                    :accept="'.pdf,.doc,.docx'"
                    data-model="epw_contract"
                    file-string="contract_file"
                    :feedback="getUnresolvedFeedbackForField('EpwContract', epwContract.slug, 'contract_file')"
                    :editable="!epwContract.approved && editable && !(isReviewer)"
                    @file-uploaded="fileUploaded"
                  >
                    <template #feedback>
                      <SectionFeedback
                        v-if="hasFeedback('EpwContract', epwContract.slug, 'contract_file')"
                        :feedback="getUnresolvedFeedbackForField('EpwContract', epwContract.slug, 'contract_file') || null"
                        section-type="EpwContract"
                        :section-slug="epwContract.slug"
                        parent-section-type="EpwContractReview"
                        :parent-section-slug="slug"
                        col-ref="contract_file"
                        :user-role="userRole"
                        :claim-period-slug="claimPeriodSlug"
                      />
                    </template>
                  </file-upload>
                  <div v-if="isReviewer || isAdmin" class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-text-center tw-gap-x-1.5 tw-gap-y-0.5">
                    <state-wrapper component="LeaveFeedbackIcon">
                      <LeaveFeedbackIcon
                        :field="epwContract"
                        :existing-feedback="getUnresolvedFeedbackForField('EpwContract', epwContract.slug, 'contract_file')"
                        section-type="EpwContract"
                        :options="{claimPeriodSlug: claimPeriodSlug}"
                        :section-slug="epwContract.slug"
                        parent-section-type="EpwContractReview"
                        col-ref-database="contract_file"
                        col-ref-object="contract_file"
                        :user-slug="userSlug"
                      />
                    </state-wrapper>
                    <state-wrapper component="PreviousResolvedFeedbackIcon">
                      <div class="tw-text-lg">
                        <PreviousResolvedFeedbackIcon
                          section-type="EpwContract"
                          :section-slug="epwContract.slug"
                          col-ref="contract_file"
                        />
                      </div>
                    </state-wrapper>
                  </div>
                </div>
              </td>
              <td :class="[theme.tables.td]">
                <div v-if="!epwContract.approved && editable">
                  <select v-model="epwContract.project_id" :class="[theme.forms.input, 'tw-text-xs']" @change="validate('EpwContract', epwContract)">
                    <option :value="null" />
                    <template v-for="project in projects" :key="project.slug">
                      <option :value="project.id" :selected="project.id == epwContract.project_id">
                        {{ project.title }}
                      </option>
                    </template>
                  </select>
                </div>
                <div v-else>
                  <div v-if="epwContract.project !== undefined">
                    {{ epwContract.project.title }}
                  </div>
                </div>
              </td>
              <td v-if="isReviewer || isAdmin" :class="[theme.tables.td]">
                <div v-if="isReviewer && hasReviewStarted">
                  <select v-model="epwContract.approved" :class="[theme.forms.input, 'tw-text-xs']" @change="validate('EpwContract', epwContract)">
                    <option :value="null" />
                    <template v-for="(value, label ) in approvedOptions" :key="value">
                      <option :value="value" :selected="value == epwContract.approved">
                        {{ label }}
                      </option>
                    </template>
                  </select>
                </div>
                <div v-else>
                  {{ epwContract.approved ? 'Yes' : `${epwContract.approved === false ? 'No' : ''}` }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import { css } from "../../../shared/theme.js";
import { nFormatter } from "../../../shared/helpers.js";

import ButtonComponent from "../../generic/button_component.vue";
import SectionFeedback from "../../components/sections/section_feedback.vue";
import LeaveFeedbackIcon from "../../components/sections/leave_feedback_icon.vue";
import PreviousResolvedFeedbackIcon from "../../generic/previous_resolved_feedback_icon.vue";
import ReviewPanel from "../../components/sections/review_panel.vue";
import SendForReviewButton from "../../components/sections/send_for_review_button.vue";
import SectionValidationMessages from "../../components/sections/section_validation_messages.vue";
import SectionStatus from "../../components/sections/section_status.vue";
import { FETCH_ALL_CLAIM_SECTION_STATES } from "../../../vuex-store/modules/states.js";

export default {
  name: "EPWContractsReview",
  components: {
    ButtonComponent,
    SectionFeedback,
    LeaveFeedbackIcon,
    PreviousResolvedFeedbackIcon,
    ReviewPanel,
    SendForReviewButton,
    SectionValidationMessages,
    SectionStatus
  },
  props: {
    slug: {
      type: String,
      required: true
    },
    userRole: {
      type: String,
      required: true
    },
    userSlug: {
      type: String,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const theme = css;
    const store = useStore();
    const loading = ref(true);

    const editable = computed(() => store.getters["states/GET_EDIT_STATE"]);
    const isReviewer = computed(() => props.userRole == "emp_reviewer");
    const isAdmin = computed(() => props.userRole == "emp_admin");
    const isEmpowerrd = computed(() => isAdmin.value || isReviewer.value);
    const approvedOptions = { Approve: true, Reject: false };

    const epwContractReview = computed(() => store.getters["epw_contract_review/GET_EPW_CONTRACT_REVIEW"]);
    const epwContracts = computed(() => store.getters["epw_contract_review/GET_EPW_CONTRACTS"]);
    const projects = computed(() => store.getters["epw_contract_review/GET_PROJECTS"]);
    const companyName = computed(() => epwContractReview.value?.claim_period?.company?.name);

    const reviewPanel = ref(null);
    const hasReviewStarted = computed(() => reviewPanel.value?.reviewStartedAt !== null);
    const blockReviewCompletionMessage = ref(null);

    const orderedEpwContracts = computed(() => _.orderBy(epwContracts.value, contract => +contract?.costs_subcontractor?.amount, ["desc"]));
    const existingFiles = computed(() => store.getters["epw_contract_review/GET_EXISTING_FILES"]);

    function fetchData() {
      store.dispatch("epw_contract_review/FETCH_DATA", { slug: props.slug });
    }

    function validate(model = "EpwContractReview", fields = null) {
      store.dispatch("validations/VALIDATE", [model, fields?.slug || props.slug, fields]);
    }

    const costLink = computed(() => {
      if (epwContractReview.value?.claim_period?.cost?.slug) return `/costs/${epwContractReview.value?.claim_period?.cost?.slug}/edit/?section=costs_subcontractors`;

      return null;
    });

    function fileUploaded() {
      fetchData();
      validate();
    }

    function getUnresolvedFeedbackForField(type, slug, field) {
      return store.getters["feedbacks/GET_FEEDBACKS_FOR_FIELD"](type, slug, field);
    }

    function getUnresolvedFeedbackForContract(type, slug) {
      return store.getters["feedbacks/GET_FEEDBACKS_FOR_SECTION"](type, slug);
    }

    function hasFeedback(type, slug, field) {
      return getUnresolvedFeedbackForField(type, slug, field) !== undefined;
    }

    function backgroundColor(feedback) {
      if (feedback?.resolve_activity_type) return feedback.resolve_activity_type === "Review/Accept" ? "tw-bg-amber-50" : "tw-bg-rose-50";

      return "";
    }

    function textColor(feedback) {
      if (feedback?.resolve_activity_type) return feedback.resolve_activity_type === "Review/Accept" ? "hover:tw-text-yellow-500 tw-text-yellow-400" : "hover:tw-text-rose-500 tw-text-rose-400";

      return "";
    }

    const blockReviewCompletion = computed(() => {
      let block = false;
      const contracts = orderedEpwContracts.value;
      const highestContract = contracts[0];

      const rejectedContractWithNoFeedback = _.find(contracts, c => c.approved === false && getUnresolvedFeedbackForContract("EpwContract", c.slug).length === 0) || null;

      if (highestContract.approved === null) {
        block = true;
        blockReviewCompletionMessage.value = `Before completing your review, you will need to approve or reject the contract for ${highestContract?.costs_subcontractor?.name}`;
      } else if (rejectedContractWithNoFeedback !== null) {
        block = true;
        blockReviewCompletionMessage.value = "Before completing your review, you will need to provide at least one feedback for any rejected contracts";
      }

      return block;
    });

    onMounted(() => {
      const promises = [
        store.dispatch("users/SET_USER"),
        fetchData(),
        validate(),
        store.dispatch("states/FETCH_STATES_FOR_SECTION", { sectionType: "EpwContractReview", sectionSlug: props.slug }),
        store.dispatch("feedbacks/FETCH_CLAIM_FEEDBACK", { claimPeriodSlug: props.claimPeriodSlug })
      ];

      if (isEmpowerrd.value) promises.push(store.dispatch("reviews/FETCH_SECTION_REVIEWS", { sectionType: "EpwContractReview", sectionSlug: props.slug }));

      Promise.all(promises).then(() => { loading.value = false; });
    });

    return {
      theme,
      nFormatter,
      loading,
      epwContractReview,
      epwContracts,
      orderedEpwContracts,
      projects,
      existingFiles,
      costLink,
      fileUploaded,
      validate,
      getUnresolvedFeedbackForField,
      hasFeedback,
      backgroundColor,
      textColor,
      editable,
      isReviewer,
      isAdmin,
      approvedOptions,
      reviewPanel,
      hasReviewStarted,
      blockReviewCompletion,
      blockReviewCompletionMessage,
      companyName
    };
  }
};
</script>
