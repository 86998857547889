export const SET_EDITED_FIELDS = "SET_EDITED_FIELDS";
export const GET_EDITED_FIELD = "GET_EDITED_FIELD";
export const SET_FIELD_TO_WRITE = "SET_FIELD_TO_WRITE";
export const REMOVE_FROM_EDITED_FIELDS = "REMOVE_FROM_EDITED_FIELDS";
export const REMOVE_ALL_EDITED_FIELDS = "REMOVE_ALL_EDITED_FIELDS";
export const GET_EDITED_FIELDS = "GET_EDITED_FIELDS";
export const HAS_UNSAVED_CHANGES = "HAS_UNSAVED_CHANGES";

export const fields = {
  namespaced: true,
  state: {
    editedFields: {},
    toEdit: null,
    dirtyFields: []
  },
  mutations: {
    [SET_FIELD_TO_WRITE]: (state, payload) => state.toEdit = payload,
    [SET_EDITED_FIELDS]: (state, payload) => state.editedFields = payload,
    [REMOVE_FROM_EDITED_FIELDS]: (state, payload) => {
      delete state.editedFields[payload];
    },
    [REMOVE_ALL_EDITED_FIELDS]: state => state.editedFields = {}
  },
  actions: {
    [SET_EDITED_FIELDS]: async ({ commit, state }, payload) => {
      const editedFieldsClone = _.cloneDeep(state.editedFields);
      _.set(editedFieldsClone, payload.field, payload.user);
      commit(SET_EDITED_FIELDS, editedFieldsClone);
    }
  },
  getters: {
    [GET_EDITED_FIELDS]: state => state.editedFields,
    [GET_EDITED_FIELD]: state => fieldId => _.get(state.editedFields, fieldId),
    [HAS_UNSAVED_CHANGES]: state => !_.isEmpty(state.editedFields)
  }
};
