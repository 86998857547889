<template>
  <div v-if="state.res" class="tw-rounded-lg">
    <div class="tw-my-4">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-uppercase tw-font-medium tw-tracking-tighter tw-text-xs">
          Answer Strength
        </div>
        <div class="tw-text-xs tw-tracking-tighter">
          {{ lengthFeedback }}
        </div>
      </div>

      <div class="tw-w-full tw-bg-white tw-rounded-full tw-h-2.5 dark:tw-bg-gray-700 tw-my-2">
        <div :class="`${barColor} tw-h-2.5 tw-rounded-full`" :style="`width: ${barWidth}%`" />
      </div>

      <div v-for="answer in wordFeedback" :key="answer.message">
        <p
          :class="`${answer.level == 'warning' || answer.level == 'bad' ? 'tw-text-red-600' : 'tw-text-green-600' } tw-text-xs tw-mb-2`"
        >
          {{ answer.message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, onMounted } from "vue";
import { throttledWatch } from "@vueuse/core";
import { post } from "@shared/http.js";

const props = defineProps({
  sectionType: {
    type: String
  },
  sectionSlug: {
    type: String
  },
  question: {
    type: String
  },
  field: {
    type: Object
  }
});

const state = reactive({ answer: "", res: {} });

const barWidth = computed(() => Math.min(state.res?.points < 10 ? 10 : state.res?.points, 100));
const barColor = computed(() => {
  if (!_.isNil(state.res?.points)) {
    if (state.res.points < 20) {
      return "tw-bg-gray-100";
    } if (state.res.points >= 20 && state.res.points < 40) {
      return "tw-bg-blue-200";
    } if (state.res.points >= 40 && state.res.points < 60) {
      return "tw-bg-blue-700";
    } if (state.res.points >= 60 && state.res.points <= 80) {
      return "tw-bg-green-200";
    } if (state.res.points > 80) {
      return "tw-bg-green-700";
    }
  }
});

const updateData = async () => {
  const narrativeFeedback = await post("/api/feedbacks/narrative", {
    field_name: props.field.label,
    text: props.field.value || "",
    category: props.sectionType,
    categorySlug: props.sectionSlug
  });

  state.res = narrativeFeedback;
};

const lengthFeedback = computed(() => _.first(_.filter(state.res?.messages, f => f.type === "CheckNarrativeAnswerLength"))?.message);
const wordFeedback = computed(() => _.filter(state.res?.messages, f => f.type === "CheckNarrativeAnswerWords"));

throttledWatch(props.field, updateData, { throttle: 2000 });

onMounted(() => updateData());
</script>
