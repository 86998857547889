<template>
  <div v-if="isLoading" id="loading" class="tw-w-full tw-text-center tw-px-4">
    <PulsingLoader size="large" />
  </div>
  <div v-if="!isLoading">
    <div id="overall-progression" class="tw-pb-4 tw-mb-2 tw-px-6">
      <div
        class="tw-uppercase tw-text-light-grey-blue tw-text-xs tw-font-semibold tw-mb-4"
      >
        Overall
      </div>
      <div class="tw-w-full tw-h-1 tw-rounded tw-bg-yellow-100">
        <div
          class="tw-h-1"
          :class="
            totalProgression == 100.0
              ? 'tw-rounded tw-bg-green-700'
              : 'tw-rounded-l tw-bg-yellow-300'
          "
          :style="`width: ${totalProgression}%`"
        />
      </div>
    </div>

    <div id="progression-sections" class="tw-flex tw-flex-col tw-gap-1">
      <div
        class="tw-uppercase tw-text-light-grey-blue tw-text-xs tw-font-semibold tw-mb-1 tw-px-6"
      >
        Sections
      </div>
      <Accordion
        :accordion-sections="progressionSteps"
        wrapping-classes="hover:tw-bg-gray-100 tw-rounded-lg tw-mx-4 tw-px-2"
        open-classes="tw-bg-gray-100"
        @first-click="firstClick"
        @all-closed="[(allOpen = true), (isHovering = false)]"
        @reopened="allOpen = false"
      >
        <template #sectionTitleContent="titleProps">
          <SectionProgressionTitle
            :section="titleProps.section"
            :open="titleProps.open"
            :start-open="startOpen"
            :all-open="allOpen"
            :hovering="isHovering"
          />
        </template>
        <template #sectionDropdownContent="dropdownProps">
          <SectionProgressionDropdown
            :claim-period-slug="claimPeriodSlug"
            :section="dropdownProps.section"
          />
        </template>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import SectionProgressionTitle from "./section_progression_title.vue";
import SectionProgressionDropdown from "./section_progression_dropdown.vue";
import PulsingLoader from "../../generic/loading_pulse_lines";
import Accordion from "../../generic/accordion";

export default {
  components: {
    SectionProgressionTitle,
    SectionProgressionDropdown,
    PulsingLoader,
    Accordion
  },
  props: {
    claimPeriodSlug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const startOpen = ref(true);
    const allOpen = ref(false);
    const isHovering = ref(false);

    return {
      startOpen,
      allOpen,
      isHovering,
      fetchData() {
        fetch(`/api/claim_progression/fetch_data/${props.claimPeriodSlug}`)
            .then(response => response.json())
            .then(data => {
              this.progressionSteps = data.progression_steps;
              this.totalProgression = data.total_progression;
              this.empSubmitting = data.emp_submitting;
              this.isLoading = false;
            });
      }
    };
  },
  data() {
    return {
      progressionSteps: [],
      totalProgression: 0.0,
      projects: [],
      sectionData: {},
      isLoading: true,
      pageLoaded: true
    };
  },
  mounted() {
    if (this.claimPeriodSlug) {
      this.fetchData();
    }
  },
  methods: {
    firstClick(section) {
      if (this.pageLoaded) {
        this.startOpen = false;

        this.track("Claim Progression First Dropdown Opened", {
          section: section?.claim_section?.title || section.type,
          claimPeriodSlug: this.claimPeriodSlug,
          allSectionsProgression: this.progressionSteps.map(section => `${section?.claim_section?.title || section.type} - ${section.status}`)
        });
        this.pageLoaded = false;
      }
    }
  }
};
</script>
