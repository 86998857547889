<template>
  <div class="tw-inline-block">
    <Icon v-if="editMode === 'cell'" type="pencil" class="tw-ml-1" />

    <span :class="`tw-ml-1 tw-w-4 tw-inline-block`">
      <Tooltip :content="tooltipContent">
        <Icon :type="icon" />
      </Tooltip>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Tooltip from "@generic/tooltip.vue";
import Icon from "@generic/icon.vue";

interface CellMetadata {
  state: string,
  message: string
}

const props = defineProps<{
  cellMetadata: CellMetadata | undefined,
  editMode: string
}>();

const icon = computed(() => {
  if (!props.cellMetadata) return "";

  if (props.cellMetadata.state === "saving") {
    return "spinner-third";
  } else if (props.cellMetadata.state === "saved") {
    return "check-circle";
  } else if (props.cellMetadata.state === "error") {
    return "exclamation-triangle";
  } else {
    return "";
  }
});

const tooltipContent = computed(() => (props.cellMetadata ? props.cellMetadata.message : ""));
</script>
