<template>
  <div :id="`${item.sectionLabel}_sidebar`">
    <div v-if="item.isEnabled">
      <Tooltip :content="tooltipText">
        <a
          :href="item.canAccess ? item.url : null"
          :class="`${textColor} tw-group tw-flex tw-justify-between tw-items-center tw-px-2 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter`"
          aria-current="undefined"
          @click="item.sectionObject && trackButtonClick(item.trackId)"
        >
          <span ref="optionalTooltipSpan" :class="`${textColor} tw-flex tw-items-center`">
            <i
              :class="[iconColor, item.iconClass, 'tw-w-6 tw-text-base far tw-flex-shrink-0 tw-text-center tw-pr-2']"
              aria-hidden="true"
            />
            {{ item.text }}
          </span>

          <ClaimSidebarSectionStatus
            :section-complete="item.isSectionComplete"
            :section="item.sectionObject"
            :current-page="item.isOnPage"
            :section-label="`${item.sectionLabel}`"
            :complete-tooltip="item.sectionStatusTooltipContent"
            :check-unresolved-feedback="item.checkUnresolvedFeedback"
            :rd="item.rd"
          />
        </a>
      </Tooltip>
    </div>

    <div v-else>
      <Tooltip content="This feature will be live soon">
        <div
          class="tw-group tw-flex tw-justify-between tw-items-center tw-px-2 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter tw-text-gray-100 hover:tw-bg-deep-blue-light"
          aria-current="undefined"
        >
          <span class="tw-flex tw-text-gray-500 tw-items-center hover:tw-bg-deep-blue-light">
            <i :class="`tw-w-6 tw-text-base far ${item.iconClass} tw-flex-shrink-0 tw-text-center tw-pr-2`" aria-hidden="true" />
            {{ item.text }} (Coming Soon)
          </span>
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, computed } from "vue";
import { useTippy } from "vue-tippy";
import Tooltip from "@generic/tooltip.vue";
import ClaimSidebarSectionStatus from "./claim_sidebar_section_status.vue";

const trackButtonClick = inject("trackButtonClick");
const props = defineProps({
  item: {
    type: Object
  }
});

const optionalTooltipSpan = ref();
useTippy(optionalTooltipSpan, {
  content: props.item.tooltip
});

const iconColor = computed(() => {
  if (!props.item.sectionObject && props.item.canAccess) {
    return "tw-text-gray-200 group-hover:tw-text-white";
  } else if (!props.item.canAccess) {
    return "tw-text-gray-500";
  } else if (props.item.isOnPage) {
    return "tw-text-white";
  } else {
    return "tw-text-gray-200 group-hover:tw-text-white";
  }
});

const textColor = computed(() => {
  if (!props.item.sectionObject) {
    return "tw-text-gray-100 hover:tw-bg-deep-blue-light";
  } else if (!props.item.canAccess) {
    return "tw-text-gray-500";
  } else if (props.item.isOnPage) {
    return "tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white";
  } else {
    return "tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white";
  }
});

const tooltipText = computed(() => {
  if (props.item.tooltipContent) {
    return props.item.tooltipContent;
  } else if (!props.item.canAccess) {
    return "You do not have access to this section";
  } else {
    return "";
  }
});
</script>
