<template>
  <li class="tw-relative md:tw-flex md:tw-flex-1 md:tw-items-center">
    <tooltip :content="disabled ? `Complete Step ${step - 1} to reach this step` : null">
      <a
        :href="disabled ? null : url"
        :class="[
          'tw-group tw-flex tw-items-center tw-px-5 tw-py-3 tw-text-sm tw-font-medium',
          disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
        ]"
        @click="onClick"
      >
        <span :class="borderColor" class="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full">
          <i v-if="status === 'Complete'" class="fa-lg far fa-check tw-text-white" aria-hidden="true" />
          <span v-else :class="textColor">{{ step }}</span>
        </span>
        <span :class="textColor" class="tw-ml-3 tw-text-sm tw-font-medium">{{ title }}</span>
      </a>
    </tooltip>
    <template v-if="step !== totalLength">
      <div class="tw-absolute tw-right-0 tw-top-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
        <svg
          class="tw-h-full tw-w-full tw-text-gray-300"
          viewBox="0 0 22 80"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 -2L20 40L0 82"
            vector-effect="non-scaling-stroke"
            stroke="currentcolor"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
  </li>
</template>

<script>
import { computed } from "vue";

export default {
  // You can pass any props you would pass a button component to this component and
  // it will be passed through to the button component
  props: {
    title: {
      type: String,
      required: false
    },
    step: {
      type: Number,
      required: true
    },
    url: {
      type: String,
      required: false
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    onClick: {
      type: Function,
      required: false
    },
    status: {
      type: String,
      required: false
    },
    totalLength: {
      type: Number,
      required: false
    }
  },
  setup(props) {
    const borderColor = computed(() => {
      if (props.status === "Complete") return "tw-bg-cta-blue-1200 group-hover:tw-bg-cta-blue";
      if (props.active) return "tw-border-cta-blue-1200 group-hover:tw-border-cta-blue tw-border-2";
      if (props.disabled) return "tw-border-gray-300 tw-border-2";

      return "tw-border-gray-500 group-hover:tw-border-gray-800 tw-border-2";
    });
    const textColor = computed(() => {
      if (props.active) return "tw-text-cta-blue-1200 group-hover:tw-text-cta-blue-750";
      if (props.status === "Complete") return "tw-text-emp-blue";
      if (props.disabled) return "tw-text-gray-500";

      return "tw-text-gray-700 group-hover:tw-text-gray-900";
    });

    return {
      borderColor,
      textColor
    };
  }
};
</script>
