<template>
  <div class="tw-shadow tw-overflow-x-auto tw-ring-1 tw-ring-black tw-ring-opacity-5 sm:tw-rounded-lg">
    <table class="tw-w-full tw-table-fixed tw-divide-y tw-divide-gray-300">
      <thead class="tw-bg-gray-50">
        <tr>
          <th scope="col" class="tw-w-1/12 tw-px-4 tw-py-3.5 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider">
            #
          </th>
          <th scope="col" class="tw-w-1/4 tw-px-4 tw-py-3.5 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider">
            Project Title
          </th>
          <th
            v-for="(col, idx) in projectSelectionCols"
            :key="idx"
            scope="col"
            :class="[col.width, 'tw-px-4 tw-py-3.5 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-space-x-2']"
          >
            <div class="tw-flex tw-items-center tw-justify-between">
              <div class="tw-flex-1">
                {{ col.label }}
                <KnowledgeCentreTriggerIcon
                  v-if="col.knowledgeCentre"
                  section="ProjectSelection"
                  :faq="col.knowledgeCentre"
                  :icon="true"
                />
              </div>
              <template v-if="col.headerComponent">
                <div class="tw-flex-none tw-justify-self-end">
                  <state-wrapper v-slot="{ editable }" :component="col.headerComponent">
                    <component
                      :is="col.headerComponent"
                      :slug="slug"
                      :editable="editable"
                    />
                  </state-wrapper>
                </div>
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="tw-bg-white">
        <template v-for="(rdProject, index) in rdProjects" :key="rdProject.slug">
          <tr class="tw-border-t tw-border-gray-200 tw-border-solid">
            <td class="tw-align-center tw-whitespace-normal tw-p-4 tw-text-gray-500">
              {{ index + 1 }}
            </td>
            <td class="tw-align-center tw-whitespace-normal tw-p-4 tw-font-medium tw-text-gray-900 tw-break-words">
              {{ rdProject.title }}
            </td>
            <td
              v-for="(col, idx) in projectSelectionCols"
              :key="idx"
              :class="[col.colClasses, 'tw-align-top tw-whitespace-normal tw-p-4 tw-text-gray-500 tw-break-words']"
            >
              <span v-if="col.component ==='FieldDescription' && col.field" v-html="rdProject[col.field] ? rdProject[col.field].replace('<p><br></p>','') : ''" />
              <state-wrapper v-else v-slot="{ editable }" :component="col.component">
                <component
                  :is="col.component"
                  :slug="rdProject.slug"
                  :model="rdProject[col.field]"
                  :project-selection-slug="slug"
                  :editable="editable"
                />
              </state-wrapper>
            </td>
          </tr>
          <tr v-if="stepKey === 'project_validation'">
            <td colspan="3">
              <state-wrapper v-slot="{ editable }" component="RdProjectForm">
                <RdProjectForm :slug="rdProject.slug" :editable="editable" />
              </state-wrapper>
            </td>
          </tr>
        </template>
        <tr v-if="$slots.bottomTableContent" class="tw-border-t tw-border-gray-200 tw-border-solid">
          <td :colspan="projectSelectionCols.length + 2">
            <slot name="bottomTableContent" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import RdProjectActions from "./column_components/rd_project_actions.vue";
import AddRdProject from "./column_components/add_rd_project.vue";
import ProjectRequirement from "./column_components/project_requirement.vue";
import ProjectSelector from "./column_components/project_selector.vue";
import ProjectExpenditureSlider from "./column_components/project_expenditure_slider.vue";
import ProjectExpendituresTotal from "./column_components/project_expenditures_total.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";
import { project_selection_cols, stepKeys } from "./project_selection_steps.js";
import RdProjectForm from "./rd_project_form.vue";

import { css } from "../../../shared/theme.js";

export default {
  name: "RdProjectsTable",
  components: {
    RdProjectForm,
    KnowledgeCentreTriggerIcon,
    RdProjectActions,
    AddRdProject,
    ProjectRequirement,
    ProjectSelector,
    ProjectExpenditureSlider,
    ProjectExpendituresTotal
  },
  props: {
    stepKey: {
      type: String,
      required: true,
      validator: value => stepKeys.indexOf(value) >= 0,
      default: "project_validation"
    },
    slug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const theme = css;
    const store = useStore();

    const projectSelectionCols = computed(() => project_selection_cols[props.stepKey]);
    const rdProjects = computed(() => store.getters["project_selection/GET_RD_PROJECTS"]);

    return {
      rdProjects,
      projectSelectionCols,
      theme
    };
  }
};
</script>
