<template>
  <tooltip
    v-if="icon || text || $slots.default"
    :id="id"
    :content="tooltip || null"
    :class="[fullWidth ? 'tw-w-full' : 'tw-inline-flex', 'tw-flex-none']"
    :tooltip-options="tooltipOptions"
  >
    <slot>
      <div
        :aria-label="text"
        class="tw-flex tw-items-center tw-relative tw-justify-center tw-font-medium focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-75"
        :class="[
          sizeClasses,
          colourClasses,
          iconRight ? 'tw-flex-row-reverse' : '',
          uppercase ? 'tw-uppercase tw-tracking-tighter' : '',
          fullWidth ? 'tw-w-full' : '',
          rounded ? 'tw-rounded-full' : 'tw-rounded-md'
        ]"
      >
        <span v-if="ping" :class="[colourClasses, text ? 'tw-animate-button-ping' : 'tw-animate-ping']" class="tw-inline-flex tw-h-full tw-w-full tw-absolute tw-rounded-md" />
        <i
          v-if="icon"
          style="display: inline-flex;"
          class="tw-items-center tw-relative tw-justify-center"
          :class="iconClasses"
        />
        <span v-if="text" class="tw-flex-none tw-relative" :class="hideSmall ? 'tw-hidden lg:tw-block' : ''">
          {{ text }}
        </span>
      </div>
    </slot>
    <template v-if="$slots.tooltipContent" #content>
      <slot name="tooltipContent" />
    </template>
  </tooltip>
</template>

<script>
import { computed, reactive } from "vue";
import { css } from "../../shared/theme.js";

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: "medium"
    },
    text: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    iconType: {
      type: String,
      required: false,
      default: "far",
      validator: value => {
        const validTypes = ["far", "fas", "fal"];
        return validTypes.indexOf(value) >= 0;
      }
    },
    colour: {
      type: String,
      required: false,
      default: "cta",
      validator: value => {
        const validTypes = [
          "cta", // Light Blue
          "warning", // Yellow
          "error", // Red
          "disabled", // Gray
          "confirm", // Green
          "emp" // Navy Blue
        ];
        return validTypes.indexOf(value) >= 0;
      }
    },
    type: {
      type: String,
      required: false,
      default: "primary",
      validator: value => {
        const validTypes = [
          "primary",
          "light",
          "transparent"
        ];
        return validTypes.indexOf(value) >= 0;
      }
    },
    iconRight: {
      type: Boolean,
      required: false,
      default: false
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    hideSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    ping: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    tooltipOptions: {
      type: Object,
      required: false
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const theme = reactive(css.buttons);

    const sizeClasses = computed(() => theme.size_classes[props.text ? "text" : "icon_only"][props.size.toLowerCase()]);

    const iconClasses = computed(() => {
      if (props.icon) {
        return [
          props.iconType,
          `fa-${props.icon}`,
          theme.size_classes.icon[props.size.toLowerCase()],
          (props.text && !props.hideSmall) ? theme.size_classes[props.iconRight ? "icon_right" : "icon_left"][props.size.toLowerCase()] : ""
        ];
      }
    });

    const colourClasses = computed(() => theme.colours[props.colour][props.type].replace(/hover:\S+/g, ""));

    return {
      theme,
      sizeClasses,
      iconClasses,
      colourClasses
    };
  }
};
</script>
