import { get } from "../../shared/http.js";

export const FETCH_DATA = "FETCH_DATA";
export const GET_ELEARN_DATA = "GET_ELEARN_DATA";

export const elearn = {
  namespaced: true,
  state: {
    elearn_data: {}
  },
  mutations: {
    setElearnData: (state, payload) => (state.elearn_data = payload)
  },
  actions: {
    [FETCH_DATA]: async ({ state, commit }, payload) => {
      try {
        const { elearnContentId } = payload;
        const response = await get(`/api/v1/elearns/${elearnContentId}`);

        commit("setElearnData", response);

        return response;
      } catch {
        return {};
      }
    }
  },
  getters: {
    [GET_ELEARN_DATA]: state => state.elearn_data
  }
};
