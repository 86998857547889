const genericOptions = {
  plugins: {
    title: {
      display: false,
      align: "start",
      padding: {
        bottom: 20
      },
      font: {
        size: 14,
        weight: 500
      },
      color: "#6D8AA0"
    },
    legend: {
      display: true,
      position: "bottom"
    },
    tooltip: {
      enabled: true,
      titleAlign: "center",
      displayColors: true,
      caretSize: 0,
      padding: 12,
      titleFont: {
        weight: 700,
        size: 14
      }
    }
  }
};

const barChartOptions = {
  ...genericOptions,
  plugins: {
    ...genericOptions.plugins,
    tooltip: {
      ...genericOptions.plugins.tooltip,
      callbacks: {
        label(ctx) {
          return `${ctx.label} ${ctx.dataset.label}`;
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: true,
        drawBorder: true
      },
      ticks: {}
    },
    y: {
      grid: {
        display: true,
        drawBorder: true
      },
      ticks: {}
    }
  }
};

const doughnutChartOptions = {
  ...genericOptions,
  maintainAspectRatio: false,
  plugins: {
    ...genericOptions.plugins,
    legend: {
      ...genericOptions.plugins.legend,
      position: "bottom",
      title: { display: true } // hack to add some space between legend and chart, which is not confirgurable
    },
    tooltip: {
      ...genericOptions.plugins.tooltip,
      callbacks: {
        title(context) {
          return context[0].label;
        },
        label(context) {
          const totalValue = context.dataset.data.reduce((a, b) => a + b, 0);
          const percentage = Math.round((context.parsed / totalValue) * 100);

          return `${percentage}%`;
        }
      }
    },
    datalabels: {
      backgroundColor(context) {
        return context.dataset.backgroundColor;
      },
      borderColor: "white",
      borderRadius: 25,
      borderWidth: 2,
      anchor: "end",
      color: "white",
      font: {
        weight: "bold"
      },
      padding: 6
    }
  }
};

export { doughnutChartOptions, barChartOptions };
