<template>
  <Transition v-if="show">
    <div class="tw-text-left">
      <Tippy :interactive="true" theme="light" :on-show="hoverIcon">
        <slot name="toggle">
          <i :class="`fas fa-${icon} fa-lg ${color}`" />
        </slot>
        <template #content="{ hide }">
          <slot :hide="hide">
            <div :class="`${backgroundColor} tw-p-1 tw-rounded-md tw-text-sm`">
              <div
                class="tw-flex tw-gap-x-2 tw-items-center tw-justify-between"
              >
                <span class="tw-text-sm tw-font-bold">Cost Feedback</span>
                <i :class="`fas fa-${icon} fa-lg ${color} tw-my-2`" />
              </div>
              <div v-for="(feedback, index) in feedbacks" :key="index" class="tw-pb-2 tw-text-sm">
                {{ feedback.message }}
              </div>
            </div>
          </slot>
        </template>
      </Tippy>
    </div>
  </Transition>
</template>

<script>
import { computed, onMounted, inject } from "vue";
import { Tippy } from "vue-tippy";

export default {
  components: { Tippy },
  props: {
    feedbacks: {
      type: Array,
      required: true,
      default: {}
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const track = inject("track");
    const anyUnacceptable = computed(() => _.some(props.feedbacks ?? [], feedback => {
      feedback?.data?.feedback_level == "unacceptable";
    }));
    const color = computed(() => (anyUnacceptable.value ? "tw-text-red-600" : "tw-text-yellow-400"));
    const icon = computed(() => (anyUnacceptable.value ? "do-not-enter" : "exclamation-triangle"));

    const backgroundColor = computed(() => (anyUnacceptable.value ? "tw-border-top-2 tw-border-red-300 tw-border-t-solid" : "tw-bg-white"));

    const show = computed(() => (props.feedbacks ?? undefined) !== undefined);

    const hoverIcon = () => {
      track("Cost Auto Feedback Icon Hovered", {
        sectionType: props.feedbacks[0]?.type,
        sectionSlug: props.feedbacks[0]?.slug,
        ruleIds: _.map(props.feedbacks, "rule_id"),
        claimPeriodSlug: props.options.claimPeriodSlug,
        feedbackLevel: anyUnacceptable.value ? "unacceptable" : null
      });
    };

    onMounted(() => {
      track("Cost Auto Feedback Icon Shown", {
        sectionType: props.feedbacks[0]?.type,
        sectionSlug: props.feedbacks[0]?.slug,
        ruleIds: _.map(props.feedbacks, "rule_id"),
        claimPeriodSlug: props.options.claimPeriodSlug,
        feedbackLevel: anyUnacceptable.value ? "unacceptable" : null
      });
    });

    return {
      color,
      icon,
      show,
      backgroundColor,
      hoverIcon
    };
  }
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
