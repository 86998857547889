<template>
  <div v-if="sectionReview && isReviewAssignedToMe">
    <Dialog
      as="div"
      class="tw-relative tw-z-10"
      :open="isOpen"
      @close="setIsOpen"
    >
      <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75" />
      <div class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto">
        <div class="tw-flex tw-items-end sm:tw-items-center tw-justify-center tw-min-h-full tw-p-4 tw-text-center sm:tw-p-0">
          <DialogPanel class="tw-relative tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6">
            <div class="tw-hidden sm:tw-block tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-4">
              <button type="button" class="tw-bg-white tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none" @click="closeDialog()">
                <span class="tw-sr-only">Close</span>
                <i class="fa fa-times fas color-heather tw-text-lg hover:tw-text-red-600 tw-transition tw-delay-50" />
              </button>
            </div>

            <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left">
              <DialogTitle as="h3" class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                {{ dialogTitle }}
              </DialogTitle>
              <div class="tw-flex tw-flex-col tw-gap-4 tw-my-4">
                <p class="tw-text-sm tw-text-gray-500">
                  {{ displayText }}
                </p>
                <div>
                  <textarea
                    v-model="comment"
                    rows="5"
                    :class="theme.forms.textarea"
                    aria-label="Leave comments"
                    :placeholder="`${isNarrativeCall ? 'Call' : 'Review'} comments`"
                  />
                </div>
                <div id="review-time" class="tw-flex tw-flex-col tw-gap-1">
                  <div class="tw-inline-flex tw-items-center">
                    <span class="tw-text-sm tw-text-gray-900 tw-font-semibold">
                      How long did this {{ isNarrativeCall ? 'call' : 'review' }} take?
                    </span>
                    <span class="tw-text-sm tw-text-error-red tw-font-semibold">*</span>
                    <span v-if="sectionReview.started_at" class="tw-text-xs tw-text-gray-500 tw-ml-2">(Review Started {{ formatTimeDistance(sectionReview.started_at, { unit: 'minute' }, true) }} ago)</span>
                  </div>
                  <span v-if="rejecting" class="tw-text-xs tw-italic tw-text-gray-600">Even if rejecting please still enter the time you spent on the review. Put 0 if you did not spend any time on it.</span>
                  <div class="tw-inline-flex tw-items-center tw-gap-2">
                    <input
                      id="review_minutes"
                      v-model="reviewMinutes"
                      type="number"
                      name="Minutes"
                      min="0"
                      :class="[theme.forms.input, 'tw-w-1/2']"
                    >
                    <span class="tw-text-sm tw-text-gray-900">minutes</span>
                    <span v-if="reviewMinutes > 60" class="tw-text-xs tw-italic tw-text-gray-600">({{ reviewMinutesFormatted }})</span>
                  </div>
                </div>
                <div v-if="isNarrative && !(rejecting && isNarrativeReview)" id="quality-score-scoring" class="tw-flex tw-flex-col tw-gap-2">
                  <div class="tw-inline-flex tw-items-center">
                    <span class="tw-text-sm tw-text-gray-900 tw-font-semibold">Quality Score</span>
                    <span class="tw-text-sm tw-text-error-red tw-font-semibold">*</span>
                    <KnowledgeCentreTriggerIcon
                      section="Review"
                      question="quality_score"
                      :icon="true"
                      class="tw-ml-2"
                    />
                  </div>
                  <div class="tw-inline-flex tw-items-center tw-gap-x-4">
                    <template v-for="score in [1, 2, 3, 4, 5]" :key="score">
                      <div class="tw-flex tw-items-center tw-gap-x-2 tw-h-5">
                        <input
                          :id="`quality_score_${score}`"
                          v-model="qualityScore"
                          :value="score"
                          type="radio"
                          :class="theme.forms.radio"
                        >
                        {{ score }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <span v-if="displayWarning" class="tw-text-warning-yellow tw-text-xs tw-inline-block">
              <i class="fas fa-exclamation-triangle" /> {{ displayWarning }}
            </span>

            <ToggleComponent
              v-if="draftCheckRequired !== null && !rejecting"
              name="draft_check_required"
              :field-value="draftCheckOverride"
              label="Require Additional Draft Review"
              description="If enabled, an additional Narrative Draft will be created once all feedback left in this review has been resolved"
              :icon="true"
              :disabled="checkRequired"
              @updateFieldValue="(v) => handleUpdateFieldValue(v, true)"
            />

            <ToggleComponent
              v-if="!rejecting && !isNarrativeCall"
              name="check_required"
              :field-value="checkRequired"
              :label="`Require Additional ${sectionReview.review_type.label}`"
              :description="`If enabled, an additional ${sectionReview.review_type.label.includes('Review') ? sectionReview.review_type.label : sectionReview.review_type.label + ' Review'} will be created and assigned to you once all feedback left in this review has been resolved`"
              :icon="true"
              :disabled="draftCheckOverride"
              @updateFieldValue="handleUpdateFieldValue"
            />

            <SlideFadeTransition v-if="!rejecting">
              <div v-if="checkRequired">
                <banner-component
                  :text="`A ${getReviewTypeCountForSection} ${sectionReview.review_type.label.includes('Review') ? sectionReview.review_type.label : sectionReview.review_type.label + ' Review'} will be created allowing you to verify any feedback left in this review has been appropriately actioned by the client`"
                  type="warning"
                />
              </div>
              <div v-else-if="draftCheckOverride">
                <banner-component
                  :text="`Another Narrative Draft Review will be created once all feedback left in this review has been resolved.
                  ${!draftCheckRequired && draftCheckOverride ? '<br><strong>Please Note: </strong> The narrative drafter for this project did not feel another draft was necessary' : ''}`
                  "
                  type="warning"
                />
              </div>
            </SlideFadeTransition>

            <SlideFadeTransition>
              <div v-if="error">
                <banner-component
                  :text="error"
                  type="error"
                />
              </div>
            </SlideFadeTransition>

            <div class="tw-mt-3 tw-flex tw-gap-x-2 tw-justify-between tw-flex-row-reverse">
              <ButtonComponent :text="confirmButtonText" colour="confirm" @click="rejecting ? reviewRejected() : reviewCompleted()" />
              <ButtonComponent
                text="Cancel"
                icon=""
                colour="error"
                @click="closeDialog()"
              />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>

    <SlideFadeTransition>
      <div class="tw-bg-white tw-rounded-lg tw-shadow tw-py-4 tw-px-6 tw-mb-3">
        <div class="tw-flex tw-flex-col tw-w-full tw-gap-4">
          <div class="tw-inline-flex tw-gap-1 tw-items-center tw-justify-between">
            <h1 class="tw-text-lg">
              {{ sectionType == 'EpwContractReview' ? 'EPW CONTRACTS' : sectionType.toUpperCase() }} REVIEW PANEL
            </h1>
            <tooltip :content="expanded ? 'Hide Details' : 'Show Details'">
              <i
                :class="expanded ? 'tw-duration-200' : 'tw-transform tw-origin-center tw-rotate-180 tw-duration-200'"
                class="far fa-chevron-up tw-w-5 tw-h-5 tw-cursor-pointer tw-text-center tw-pt-1"
                :aria-label="expanded ? 'Hide Review Details' : 'Show Review Details'"
                @click="toggleExpanded()"
              />
            </tooltip>
          </div>
          <div v-if="expanded">
            <hr class="tw-mb-4">
            <div class="tw-w-full tw-flex tw-flex-col tw-gap-y-3">
              <div class="tw-text-base">
                <div class="tw-grid tw-grid-cols-1 tw-gap-x-2 tw-justify-between">
                  <DescriptionListItem title="Company">
                    <tooltip :content="sectionReview.company.name">
                      {{ truncate(sectionReview.company.name, 18) }}
                    </tooltip>
                  </DescriptionListItem>
                  <DescriptionListItem title="Claim">
                    <tooltip content="Open Claim Summary">
                      <a
                        :href="`/claim_periods/${sectionReview.claim_period.slug}/full_claim_view`"
                        target="_blank"
                        class="hover:tw-text-deep-blue-hover tw-transition tw-delay-50"
                      >
                        {{ sectionReview.claim_period.title_short }}
                        <i class="far fa-external-link tw-text-center tw-ml-1.5" />
                      </a>
                    </tooltip>
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.claim_manager !== null" title="Claim Manager">
                    {{ sectionReview.claim_manager.first_name }} {{ sectionReview.claim_manager.last_name }}
                  </DescriptionListItem>
                  <DescriptionListItem title="Deal Category">
                    {{ sectionReview.claim_period.deal_category || '??' }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.company_type" title="Company Type">
                    {{ sectionReview.company_type }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.status" title="Scheme Type">
                    <tooltip :content="sectionReview.claim_scheme_type">
                      {{ truncate(sectionReview.claim_scheme_type, 12) }}
                      <i class="far fa-info-circle" />
                    </tooltip>
                  </DescriptionListItem>
                  <DescriptionListItem v-if="financialYearBreakdown" title="Days in claim">
                    {{ financialYearBreakdown.total }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="financialYearBreakdown && financialYearBreakdown.fy2 !== 0" title="Days in fy1">
                    {{ financialYearBreakdown.fy1 }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="financialYearBreakdown && financialYearBreakdown.fy2 !== 0" title="Days in fy2">
                    {{ financialYearBreakdown.fy2 }}
                  </DescriptionListItem>
                  <DescriptionListItem title="Claim Size">
                    £{{ nFormatter(sectionReview.claim_period._claim_value) || '??' }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.total_expenditure_amount" title="Total Expenditure">
                    £{{ nFormatter(sectionReview.total_expenditure_amount) || '??' }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.status" title="# of Employees">
                    {{ sectionReview.status.employee_count }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.status" title="# of Projects">
                    {{ sectionReview.projects_number }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.status" title="Part of Group">
                    {{ sectionReview.status.company_has_group_structure ? 'Yes' : 'No' }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.status" title="Grants Received">
                    {{ sectionReview.status.external_funding_received ? 'Yes' : 'No' }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="showCapitalisedIntangibleNote" title="Has Capitalised Intangible subcosts?">
                    {{ sectionReview.any_capitalised_intangible_subcosts ? 'Yes' : 'No' }}
                  </DescriptionListItem>
                </div>
                <hr class="tw-mb-4 tw-mt-2">
                <div class="tw-grid tw-grid-cols-1 tw-justify-between">
                  <DescriptionListItem title="Assigned">
                    {{ capitalize(formatTimeDistance(sectionReview.assigned_at, { addSuffix: true })) }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="reviewStartedAt" title="Started">
                    {{ capitalize(formatTimeDistance(sectionReview.started_at, { addSuffix: true })) }}
                  </DescriptionListItem>
                  <DescriptionListItem v-if="sectionReview.due_at" title="Due">
                    {{ convertTime(sectionReview.due_at) }}
                  </DescriptionListItem>
                  <DescriptionListItem title="Priority">
                    {{ sectionReview.priority }}
                  </DescriptionListItem>
                </div>
                <hr class="tw-mb-4 tw-mt-2">
                <DescriptionListItem title="Review History" @click="fetchReviewCommentHistory()">
                  <tooltip :content="`Open History for this ${sectionType}`">
                    <i class="far fa-external-link tw-text-center hover:tw-text-deep-blue-hover tw-transition tw-delay-50 tw-cursor-pointer" />
                  </tooltip>
                </DescriptionListItem>
                <template v-if="helpfulLinks">
                  <DescriptionListItem v-for="link in helpfulLinks" :key="link.title" :title="link.title">
                    <a
                      :href="link.url"
                      target="_blank"
                      class="hover:tw-text-deep-blue-hover tw-transition tw-delay-50"
                    >
                      <i class="far fa-external-link tw-text-center hover:tw-text-deep-blue-hover tw-transition tw-delay-50 tw-cursor-pointer" />
                    </a>
                  </DescriptionListItem>
                </template>
              </div>
            </div>
          </div>
          <span v-if="draftCheckRequired" class="tw-text-warning-yellow tw-text-sm tw-mt-1 tw-inline-block">
            <i class="fas fa-exclamation-triangle" />
            The Narrative Drafter has asked to draft this section again, you can override this when you complete the review.
          </span>
          <div class="tw-w-full tw-flex tw-flex-wrap tw-relative tw-gap-2">
            <span class="tw-flex-grow tw-w-full">
              <SlideUpTransition mode="out-in">
                <ButtonComponent
                  v-if="!reviewStartedAt"
                  text="Start Review"
                  icon="play"
                  colour="cta"
                  :full-width="true"
                  @click="reviewStarted()"
                />
                <ButtonComponent
                  v-else
                  text="Finish Review"
                  icon="flag-checkered"
                  :colour="blockCompletion ? 'disabled' : 'cta'"
                  :full-width="true"
                  :disable-click="blockCompletion"
                  :tooltip="blockCompletion ? blockCompletionMessage : null"
                  @click="blockCompletion ? null : setIsOpen(true)"
                />
              </SlideUpTransition>
            </span>
            <span class="tw-flex-grow tw-w-full">
              <SlideUpTransition v-if="sectionReview.review_type.key !== 'narrative_review'" mode="out-in">
                <ButtonComponent
                  text="Reject Review"
                  icon="do-not-enter"
                  colour="error"
                  icon-type="fas"
                  :full-width="true"
                  type="light"
                  @click="openReject"
                />
              </SlideUpTransition>
              <SlideUpTransition v-else mode="out-in">
                <ButtonComponent
                  text="Send back to Drafter"
                  icon="undo"
                  colour="warning"
                  icon-type="fas"
                  :full-width="true"
                  @click="openReject"
                />
              </SlideUpTransition>
            </span>
          </div>
        </div>
      </div>
    </SlideFadeTransition>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
  DialogOverlay,
  TransitionRoot,
  TransitionChild } from "@headlessui/vue";
import { is } from "date-fns/locale";
import useEventsBus from "../../../shared/eventBus.js";

import { nFormatter, capitalize, truncate } from "../../../shared/helpers.js";
import { css } from "../../../shared/theme.js";

import SlideFadeTransition from "../transitions/slide_fade.vue";
import DescriptionListItem from "../../generic/description_list_item.vue";
import ButtonComponent from "../../generic/button_component.vue";
import ToggleComponent from "../../generic/toggle_component.vue";
import FadeTransition from "../transitions/fade.vue";
import SlideUpTransition from "../transitions/slide_up.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";

const datefns = require("date-fns");

export default {
  components: {
    SlideFadeTransition,
    FadeTransition,
    SlideUpTransition,
    DescriptionListItem,
    ButtonComponent,
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    DialogOverlay,
    TransitionRoot,
    TransitionChild,
    ToggleComponent,
    KnowledgeCentreTriggerIcon
  },
  props: {
    sectionSlug: {
      type: String,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    userSlug: {
      type: String,
      required: true
    },
    blockCompletion: {
      type: Boolean,
      required: false,
      default: false
    },
    blockCompletionMessage: {
      type: String,
      required: false,
      default: null
    },
    helpfulLinks: {
      type: Array,
      required: false
    },
    financialYearBreakdown: {
      type: Object,
      required: false
    }
  },
  setup(props, context) {
    const store = useStore();
    const { bus } = useEventsBus();
    const theme = css;

    const sectionReviews = computed(() => store.getters["reviews/GET_SECTION_REVIEWS"]);
    const sectionReview = computed(() => store.getters["reviews/GET_REVIEW_FOR_SECTION"]);
    const isReviewAssignedToMe = computed(() => sectionReview.value?.reviewer?.slug === props.userSlug);
    const reviewStartedAt = computed(() => sectionReview.value?.started_at);
    const showCapitalisedIntangibleNote = computed(() => sectionReview.value?.section_type === "Cost" && store.getters["feature_flags/GET_FEATURE_FLAG"]("capitalised_intangible_costs"));
    const feedbacks = computed(() => store.getters["feedbacks/GET_REVIEW_FEEDBACKS"]([sectionReview.value?.id, sectionReview.value?.triggering_review_id]));
    const draftReview = computed(() => sectionReview.value?.review_type?.key === "narrative_draft");
    const isNarrative = computed(() => ["narrative_draft", "narrative_review"].includes(sectionReview.value?.review_type?.key));
    const isNarrativeReview = computed(() => sectionReview.value?.review_type?.key === "narrative_review");
    const isNarrativeCall = computed(() => sectionReview.value?.review_type?.key === "narrative_call");
    const displayText = computed(() => {
      if (isNarrativeCall.value) {
        return "When you have finished the narrative call you can progress the project to the drafting stage. Only progress to drafting once you believe you have enough information to finalise the project to a high quality score where no additional information will be required from the client.";
      } else if (isNarrativeReview.value && rejecting.value) {
        return "Please leave a comment below for the Drafter as to why you're sending the review back to them.";
      }

      return `When you have finished reviewing you can leave a comment below for the CSM.  It will not be shared with the client. ${rejecting.value ? "If rejecting be aware any feedback you left will be deleted and you must leave a comment for the CSM." : " Any feedback left will be automatically sent to the client."}`;
    });
    const dialogTitle = computed(() => {
      if (isNarrativeReview.value && rejecting.value) {
        return "Send Review back to Narrative Drafter";
      }
      return `${rejecting.value ? "Reject" : "Complete"} ${isNarrativeCall.value ? "Narrative Call" : "Review"}`;
    });
    const displayWarning = computed(() => {
      if (isNarrativeReview.value && rejecting.value) {
        return "By confirming below this will delete this review, and assign another narrative draft to the narrative drafter who completed the previous draft review.";
      } else if (draftCheckRequired.value) {
        return "The Narrative Drafter has asked to review this section again, you can override this below if needed.";
      }
    });

    const confirmButtonText = computed(() => {
      if (isNarrativeCall.value) {
        return "Progress to Drafting";
      } else if (isNarrativeReview.value && rejecting.value) {
        return "Send back to Narrative Drafter";
      }

      return `${rejecting.value ? "Reject" : "Finish"} Review`;
    });

    const reviewParams = reactive({
      reviewSlug: sectionReview.value?.slug,
      comment: "",
      checkRequired: false,
      draftCheckOverride: false,
      sectionType: props.sectionType,
      sectionSlug: props.sectionSlug,
      reviewMinutes: null,
      qualityScore: null
    });

    watch(() => sectionReview.value, () => {
      reviewParams.reviewSlug = sectionReview.value.slug;
    });

    const currentDate = ref(new Date());
    const rejecting = ref(false);
    const isOpen = ref(false);
    const error = ref(null);

    const reviewMinutesFormatted = computed(() => {
      const hours = Math.floor(reviewParams.reviewMinutes / 60);
      const minutes = reviewParams.reviewMinutes % 60;
      return `${hours}h ${minutes}m`;
    });
    const expanded = ref(!reviewStartedAt.value);

    const draftCheckRequired = computed(() => {
      if (typeof sectionReview.value?.triggering_draft_check_required === "boolean") {
        reviewParams.draftCheckOverride = sectionReview.value?.triggering_draft_check_required;
        return sectionReview.value?.triggering_draft_check_required;
      }
      return null;
    });

    const getReviewTypeCountForSection = computed(() => {
      const count = _.chain(sectionReviews.value)
          .filter(r => r.review_type.key === sectionReview.value?.review_type?.key)
          .keys()
          .size()
          .value();

      if (count == 1) return "2nd";
      if (count == 2) return "3rd";
      if (count == 3) return "4th";
    });

    const reviewStarted = () => {
      store.dispatch("reviews/START_REVIEW", reviewParams);
      expanded.value = false;
      rejecting.value = false;
      setTimeout(() => { store.dispatch("states/FETCH_STATES_FOR_SECTION", ({ sectionType: props.sectionType, sectionSlug: props.sectionSlug, rd: props.sectionType === "Cost" })); }, 800);
    };

    function setIsOpen(value) {
      isOpen.value = value;
      rejecting.value = false;
    }

    function openReject() {
      rejecting.value = true;
      isOpen.value = true;
    }

    function closeDialog() {
      isOpen.value = false;
      rejecting.value = false;
      error.value = null;
    }

    function toggleExpanded() {
      expanded.value = !expanded.value;
    }

    async function reviewCompleted() {
      if (!reviewParams.reviewMinutes || reviewParams.reviewMinutes === 0) {
        error.value = "You must enter how long the review took before completing the review.";
        return;
      }

      if (isNarrative.value && reviewParams.qualityScore === null) {
        error.value = "You must specify the narrative scoring before completing the review.";
        return;
      }

      await store.dispatch("reviews/COMPLETE_REVIEW", reviewParams);

      window.location.href = "/";
    }

    async function reviewRejected() {
      if (!(reviewParams.reviewMinutes || reviewParams.reviewMinutes === 0)) {
        error.value = "You must enter how long the review took before rejecting, please put 0 if you did not spend any time on it.";
        return;
      }

      if (reviewParams.comment.trim() === "") {
        error.value = "You must leave a comment if rejecting the review.";
        return;
      }

      if (isNarrative.value && reviewParams.qualityScore === null && !isNarrativeReview.value) {
        error.value = "You must specify the narrative scoring before completing the review.";
        return;
      }

      await store.dispatch("reviews/REJECT_REVIEW", reviewParams);

      window.location.href = "/";
    }

    function handleUpdateFieldValue(data, triggeringReview = null) {
      if (!feedbacks.value?.length > 0 && data === true) {
        error.value = "You cannot ask for another review if you have not left any feedbacks. Either Reject the review or leave feedbacks for the client to action before creating another review.";
        return;
      } if (data && (triggeringReview ? reviewParams.checkRequired : reviewParams.draftCheckOverride)) {
        error.value = "You cannot select both options, only one additional review type can be requested";
        return;
      }
      error.value = null;

      if (triggeringReview) reviewParams.draftCheckOverride = data;
      else reviewParams.checkRequired = data;
    }

    onMounted(() => {
      setInterval(() => { currentDate.value = new Date(); }, 30000);
    });

    watch(() => bus.value.get("hideDetails"), () => {
      expanded.value = false;
    });

    context.expose({ reviewStartedAt });

    return {
      sectionReview,
      isReviewAssignedToMe,
      reviewStartedAt,
      reviewStarted,
      showCapitalisedIntangibleNote,
      nFormatter,
      currentDate,
      isOpen,
      setIsOpen,
      theme,
      reviewCompleted,
      expanded,
      toggleExpanded,
      handleUpdateFieldValue,
      getReviewTypeCountForSection,
      openReject,
      reviewRejected,
      closeDialog,
      rejecting,
      error,
      capitalize,
      draftCheckRequired,
      truncate,
      draftReview,
      reviewMinutesFormatted,
      isNarrative,
      isNarrativeReview,
      isNarrativeCall,
      displayText,
      dialogTitle,
      displayWarning,
      confirmButtonText,
      ...toRefs(reviewParams)
    };
  },
  methods: {
    formatTimeDistance(start, opts = {}, strict = false) {
      if (strict) return datefns.formatDistanceStrict(datefns.parseJSON(start), new Date(), opts);
      return datefns.formatDistance(datefns.parseJSON(start), new Date(), opts);
    },
    convertTime(date) {
      if (date !== null) {
        const newDate = new Date(date).toLocaleDateString("en-GB", { weekday: "short", year: "numeric", month: "short", day: "numeric" });

        return newDate;
      }
    },
    fetchReviewCommentHistory() {
      window.triggerModal({
        url: `/claim_periods/${this.sectionReview.claim_period.slug}/review_comment_history/${this.sectionType}/${this.sectionSlug}.html`,
        title: "Review History"
      });
    }
  }
};
</script>
