<template>
  <div class="tw-relative tw-flex tw-items-center tw-justify-end tw-normal-case tw-tracking-normal tw-font-normal">
    <transition name="fade">
      <span v-if="showError" class="tw-absolute tw-flex tw-items-center tw-right-full tw-inset-y-0 tw-text-xs tw-text-error-red tw-whitespace-nowrap tw-pr-6">
        Cannot Exceed 100% Total
      </span>
    </transition>
    <div class="tw-flex-none">
      <TotalCompletion
        :values="allExpenditures"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";
import useEventsBus from "../../../../shared/eventBus";
import TotalCompletion from "../total_completion.vue";

export default {
  components: {
    TotalCompletion
  },
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const store = useStore();
    const { bus } = useEventsBus();
    const showError = ref(false);

    const expenditures = computed(() => store.getters["project_selection/GET_EXPENDITURES"]);
    const allExpenditures = computed(() => Object.values(expenditures.value));

    watch(() => bus.value.get("sliderMaxBlocked"), () => {
      showError.value = true;
      setTimeout(() => {
        showError.value = false;
      }, 1000);
    });

    return {
      allExpenditures,
      showError
    };
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
