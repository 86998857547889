<template>
  <div id="project-selection-finalise-selection" class="tw-space-y-4">
    <card-component id="finalise-selection-blurb" :title="title">
      <template #action>
        <state-wrapper component="AssignIcon">
          <AssignIcon
            section-type="ProjectSelection"
            :section-slug="slug"
            assign-icon-type="StepAssign"
            :fields="['finalise_selection']"
          />
        </state-wrapper>
        <ButtonComponent
          v-if="isAdmin && projectSelectionFinished && serviceNotCompleted "
          class="tw-flex tw-justify-end"
          text="Unlock for Edits"
          icon="unlock"
          :hide-small="true"
          @click="unlock()"
        />
      </template>

      <template #content>
        <div class="tw-space-y-3">
          <p>
            Based on what you have told us, HMRC requires you to write about
            <span class="tw-font-semibold">{{ noProjectsBlurb }}</span>
            of your R&D projects. Their guidelines state:
          </p>
          <p>{{ projectsToDetailCopy }}</p>
          <p>
            We have suggested which projects you should write about below. However, you have the option to change some
            of the projects you write about.
          </p>
        </div>
      </template>
    </card-component>

    <slot name="errorMessage" />

    <div id="finalise-selection-table">
      <RdProjectsTable step-key="finalise_selection" :slug="slug" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, defineProps } from "vue";
import { useStore } from "vuex";

import { stepTitles } from "./project_selection_steps.js";
import RdProjectsTable from "./rd_projects_table.vue";
import AssignIcon from "../dynamic_forms/assign_icon.vue";
import ButtonComponent from "../../generic/button_component.vue";

const props = defineProps({
  slug: {
    type: String,
    required: true
  }
});

const store = useStore();
const title = stepTitles.finalise_selection;
const rdProjects = computed(() => store.getters["project_selection/GET_RD_PROJECTS"]);
const totalToDetail = computed(() => store.getters["project_selection/GET_TOTAL_NUMBER_TO_DETAIL"]);
const noProjectsBlurb = computed(() => `${totalToDetail.value || "0"} out of ${rdProjects.value.length}`);
const projectsToDetailCopy = computed(() => {
  if (totalToDetail.value <= 3) {
    return "For 1 - 3 qualifying R&D projects you are required to provide details for all of these projects.";
  } else if (totalToDetail.value > 3 && totalToDetail.value <= 10) {
    return "For 4 or more qualifying R&D projects, you are required to provide details for 3 projects plus the extra number of projects that account for at least 50% of your total qualifying R&D expenditure. Additionally, you must list all the remaining qualifying projects undertaken during your claim period.";
  } else if (totalToDetail.value > 10) {
    return "For over 10 qualifying projects, you need to describe those projects that account for at least 50% of the total expenditure, with a minimum of 3 projects described. If the qualifying expenditure is split across multiple smaller projects, describe the 10 with the most qualifying expenditure.";
  }
});

const projectSelection = computed(() => store.getters["project_selection/GET_PROJECT_SELECTION"]);

onMounted(async () => {
  await store.dispatch("project_selection/FETCH_PROJECTS_TO_DETAIL_INFO", { slug: props.slug });

  store.commit("project_selection/SET_LOADING", false);
});

// unlock display conditions
const userRole = computed(() => store.getters["users/GET_USER_ROLE"]);
const isAdmin = computed(() => userRole.value === "Emp admin");
const projectSelectionFinished = computed(() => projectSelection.value.current_state === "finished");
const serviceNotCompleted = computed(() => projectSelection.value.claim_period.service_completed_at === null);
const projectCostBreakdownCompleted = computed(() => projectSelection.value.project_cost_breakdown_state === "completed");

const unlock = async () => {
  let confirmationMessage = "Are you sure you want to unlock the Project Selection for further edits?";
  if (projectCostBreakdownCompleted.value) {
    confirmationMessage += " Project Cost Linking is complete, unlocking Project Selection will also unlock Project Cost Linking";
  }

  if (confirm(confirmationMessage)) {
    try {
      await store.dispatch("project_selection/UNLOCK", { slug: props.slug });

      store.dispatch("states/FETCH_STATES_FOR_SECTION", { sectionType: "ProjectSelection", sectionSlug: props.slug });
      store.dispatch("project_selection/FETCH_DATA", { slug: props.slug });
    } catch (error) {
      console.error(error);
    }
  }
};
</script>
