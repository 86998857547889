<template>
  <div class="tw-flex tw-flex-col tw-gap-3">
    <state-wrapper v-if="cost && !loading" component="SectionValidationMessages">
      <SectionValidationMessages :slug="slug" type="Cost" :rd="showAddRd" />
    </state-wrapper>
    <transition v-if="stateMessage && userType != 'admin'" name="fade">
      <banner-component
        :text="stateMessage"
        type="disabled"
        :dismiss="true"
      />
    </transition>
    <div>
      <TabGroup :selected-index="selectedTab" @change="changeTab">
        <TabList class="tw-mb-4 tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-1">
          <Tab v-slot="{ selected }" as="template" class="tw-flex-none">
            <button tab-key="costsemployee-tab" class="tw-inline-flex tw-gap-2 tw-items-center" :class="['tw-transition', selected ? theme.tabs.large_selected : theme.tabs.large]">
              Employee Costs
              <FadeTransition>
                <div v-if="checkFeedbackForType('CostsEmployee') && showAddRd" :key="checkFeedbackForType" class="tw-flex tw-items-center tw-gap-1.5 tw-px-2 tw-text-yellow-400">
                  <i class="far fa-comment-alt" />
                  <div class="tw-h-6 tw-relative tw-inline-block">
                    <SlideUpTransition>
                      <span :key="getCostTypeFeedbackCount('CostsEmployee')" class="tw-absolute tw-font-semibold">
                        {{ getCostTypeFeedbackCount('CostsEmployee') }}
                      </span>
                    </SlideUpTransition>
                  </div>
                </div>
              </FadeTransition>
              <state-wrapper component="SectionValidationMessages">
                <FadeTransition v-if="anyValidationsForType('CostsEmployee')">
                  <div class="tw-text-error-red-750">
                    <tooltip :content="tooltipContent('CostsEmployee')">
                      <i class="fas fa-exclamation-triangle" />
                    </tooltip>
                  </div>
                </FadeTransition>
              </state-wrapper>
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template" class="tw-flex-none">
            <button tab-key="costssubcontractor-tab" class="tw-inline-flex tw-gap-2 tw-items-center" :class="['tw-transition', selected ? theme.tabs.large_selected : theme.tabs.large]">
              External Worker Costs
              <FadeTransition>
                <div v-if="checkFeedbackForType('CostsSubcontractor') && showAddRd" class="tw-flex tw-items-center tw-gap-1.5 tw-px-2 tw-text-yellow-400">
                  <i class="far fa-comment-alt" />
                  <div class="tw-h-6 tw-relative tw-inline-block">
                    <SlideUpTransition>
                      <span :key="getCostTypeFeedbackCount('CostsSubcontractor')" class="tw-absolute tw-font-semibold">
                        {{ getCostTypeFeedbackCount('CostsSubcontractor') }}
                      </span>
                    </SlideUpTransition>
                  </div>
                </div>
              </FadeTransition>
              <state-wrapper component="SectionValidationMessages">
                <FadeTransition v-if="anyValidationsForType('CostsSubcontractor')">
                  <div class="tw-text-error-red-750">
                    <tooltip :content="tooltipContent('CostsSubcontractor')">
                      <i class="fas fa-exclamation-triangle" />
                    </tooltip>
                  </div>
                </FadeTransition>
              </state-wrapper>
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template" class="tw-flex-none">
            <button tab-key="costsconsumable-tab" class="tw-inline-flex tw-gap-2 tw-items-center" :class="['tw-transition', selected ? theme.tabs.large_selected : theme.tabs.large]">
              Consumable Costs
              <FadeTransition>
                <div v-if="checkFeedbackForType('CostsConsumable') && showAddRd" class="tw-flex tw-items-center tw-gap-1.5 tw-px-2 tw-text-yellow-400">
                  <i class="far fa-comment-alt" />
                  <div class="tw-h-6 tw-relative tw-inline-block">
                    <SlideUpTransition>
                      <span :key="getCostTypeFeedbackCount('CostsConsumable')" class="tw-absolute tw-font-semibold">
                        {{ getCostTypeFeedbackCount('CostsConsumable') }}
                      </span>
                    </SlideUpTransition>
                  </div>
                </div>
              </FadeTransition>
              <state-wrapper component="SectionValidationMessages">
                <FadeTransition v-if="anyValidationsForType('CostsConsumable')">
                  <div class="tw-text-error-red-750">
                    <tooltip :content="tooltipContent('CostsConsumable')">
                      <i class="fas fa-exclamation-triangle" />
                    </tooltip>
                  </div>
                </FadeTransition>
              </state-wrapper>
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template" class="tw-flex-none">
            <button tab-key="costsother-tab" class="tw-inline-flex tw-gap-2 tw-items-center" :class="['tw-transition', selected ? theme.tabs.large_selected : theme.tabs.large]">
              Other Costs
              <FadeTransition>
                <div v-if="checkFeedbackForType('CostsOther') && showAddRd" class="tw-flex tw-items-center tw-gap-1.5 tw-px-2 tw-text-yellow-400">
                  <i class="far fa-comment-alt" />
                  <div class="tw-h-6 tw-relative tw-inline-block">
                    <SlideUpTransition>
                      <span :key="getCostTypeFeedbackCount('CostsOther')" class="tw-absolute tw-font-semibold">
                        {{ getCostTypeFeedbackCount('CostsOther') }}
                      </span>
                    </SlideUpTransition>
                  </div>
                </div>
              </FadeTransition>
              <state-wrapper component="SectionValidationMessages">
                <FadeTransition v-if="anyValidationsForType('CostsOther')">
                  <div class="tw-text-error-red-750">
                    <tooltip :content="tooltipContent('CostsOther')">
                      <i class="fas fa-exclamation-triangle" />
                    </tooltip>
                  </div>
                </FadeTransition>
              </state-wrapper>
            </button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel :unmount="false">
            <CostTable
              :slug="slug"
              cost-type="CostsEmployee"
              :external-funding="externalFunding"
              :show-add-rd="showAddRd"
              :user-role="userRole"
              :user-slug="userSlug"
              :claim-period-slug="claimPeriodSlug"
              :options="options"
            />
          </TabPanel>
          <TabPanel :unmount="unmountTabs">
            <CostTable
              :slug="slug"
              cost-type="CostsSubcontractor"
              :external-funding="externalFunding"
              :show-add-rd="showAddRd"
              :user-role="userRole"
              :user-slug="userSlug"
              :claim-period-slug="claimPeriodSlug"
              :options="options"
            />
          </TabPanel>
          <TabPanel :unmount="unmountTabs">
            <CostTable
              :slug="slug"
              cost-type="CostsConsumable"
              :external-funding="externalFunding"
              :show-add-rd="showAddRd"
              :user-role="userRole"
              :user-slug="userSlug"
              :claim-period-slug="claimPeriodSlug"
              :options="options"
            />
          </TabPanel>
          <TabPanel :unmount="unmountTabs">
            <CostTable
              :slug="slug"
              cost-type="CostsOther"
              :external-funding="externalFunding"
              :show-add-rd="showAddRd"
              :user-role="userRole"
              :user-slug="userSlug"
              :claim-period-slug="claimPeriodSlug"
              :options="options"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script>
/*
 * The tabs can be switched by mutating the `activeTabIndex` in the costs store
 * This can be done directly via the index or by passing the class name
 * of the costs you want to show:
 *  setActiveTabByName('CostsOther')
 */

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { ref } from "vue";
import { mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import { featureEnabled } from "../../../shared/fields.js";
import CostTable from "./cost_table.vue";
import SectionValidationMessages from "../sections/section_validation_messages.vue";
import SlideUpTransition from "../transitions/slide_up.vue";
import FadeTransition from "../transitions/fade.vue";

import { GET_ACTIVE_TAB,
  SET_ACTIVE_TAB_BY_INDEX,
  GET_COST,
  GET_LOADING,
  GET_ANY_UNSAVED_COSTS_FOR_TYPE } from "../../../vuex-store/modules/costs.js";

import { GET_ALL_FEEDBACKS_UNRESOLVED } from "../../../vuex-store/modules/feedbacks.js";
import { GET_SECTION_STATUS, GET_STATE_MESSAGE, GET_USER_TYPE } from "../../../vuex-store/modules/states.js";
import { GET_ANY_VALIDATIONS_FOR_SECTION_TYPE, GET_VALIDATIONS_FOR_SECTION_TYPE } from "../../../vuex-store/modules/validations.js";

import { SECTION_STATUS } from "../../../shared/section_status.js";
import { css } from "../../../shared/theme.js";

const selectedTab = ref(0);

export default {
  components: {
    CostTable,
    SectionValidationMessages,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    SlideUpTransition,
    FadeTransition
  },
  props: {
    showAddRd: {
      type: Boolean,
      default: false
    },
    externalFunding: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      required: true
    },
    userRole: {
      type: String,
      required: true
    },
    userSlug: {
      type: String,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: {}
    },
    openTab: {
      type: Number
    }
  },
  data() {
    return {
      theme: css,
      selectedTab,
      unmountTabs: true
    };
  },
  computed: {
    ...mapGetters({
      getActiveTab: `costs/${GET_ACTIVE_TAB}`,
      allUnresolvedFeedback: `feedbacks/${GET_ALL_FEEDBACKS_UNRESOLVED}`,
      anyValidationsForType: `validations/${GET_ANY_VALIDATIONS_FOR_SECTION_TYPE}`,
      cost: `costs/${GET_COST}`,
      loading: `costs/${GET_LOADING}`,
      validationsForType: `validations/${GET_VALIDATIONS_FOR_SECTION_TYPE}`,
      getUnsavedCostsForType: `costs/${GET_ANY_UNSAVED_COSTS_FOR_TYPE}`,
      getSectionStatus: `states/${GET_SECTION_STATUS}`,
      stateMessage: `states/${GET_STATE_MESSAGE}`,
      userType: `states/${GET_USER_TYPE}`
    }),
    sectionComplete() {
      return SECTION_STATUS.SECTION_COMPLETE <= this.getSectionStatus;
    }
  },
  watch: {
    getActiveTab(newTabIndex, oldTabIndex) {
      if (newTabIndex != oldTabIndex) {
        // takes the store state change and forces Headless UI tabs to update
        selectedTab.value = newTabIndex;
      }
    }
  },
  mounted() {
    // set our default tab out of the store
    selectedTab.value = this.openTab || this.getActiveTab;
  },
  methods: {
    ...mapMutations({
      setActiveTabByIndex: `costs/${SET_ACTIVE_TAB_BY_INDEX}`
    }),
    changeTab(index) {
      // need to force state change in the store when the UI tabs are clicked on
      this.setActiveTabByIndex(index);
      this.unmountTabs = false;
    },
    getCostTypeFeedbackCount(costType) {
      return _.chain(this.allUnresolvedFeedback)
        .filter(f => f.feedbackable_type.includes(costType))
        .size()
        .value();
    },
    checkFeedbackForType(costType) {
      if (
        featureEnabled(this.options, "feature_integrated_feedback") == false
      ) {
        return false;
      }
      return this.getCostTypeFeedbackCount(costType) > 0;
    },
    onlyRdMissing(costType) {
      if (this.validationsForType(costType).length > 1) {
        return false;
      } else {
        return _.chain(this.validationsForType(costType))
        .some(v => v.type == "missing_some_pc_rd")
        .value();
      }
    },
    tooltipContent(costType) {
      if (this.getUnsavedCostsForType(costType)) {
        return `Your ${this.getFriendlyCostTypeName(costType)} have unsaved changes`;
      } else if (this.onlyRdMissing(costType)) {
        return this.validationsForType(costType)[0].message;
      } else {
        return `There are ${this.getFriendlyCostTypeName(costType)} that need addressing`;
      }
    },
    getFriendlyCostTypeName(costType) {
      if (costType == "CostsSubcontractor") {
        return "external worker costs";
      } else {
        return _.chain(costType)
        .startCase()
        .split(" ")
        .reverse()
        .join(" ")
        .lowerCase()
        .value();
      }
    }
  }
};
</script>
