<template>
  <Tippy :content="tooltip">
    <div :class="color" class="complete-info tw-inline-flex tw-cursor-default">
      <span class="tw-text-sm tw-mr-2">
        {{ completed }} / {{ total }}
      </span>
      <i :class="icon" class="fa-1x tw-pt-0.5" />
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from "vuex";
import { Tippy } from "vue-tippy";
import { flattenFields } from "../../../shared/fields.js";
import { GET_COMPLETED_ASSIGNMENTS } from "../../../vuex-store/modules/assignments.js";

export default {
  name: "CompleteInfo",
  components: {
    Tippy
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    sectionType: {
      type: String,
      required: true,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant"];
        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      completedAssignments: `assignments/${GET_COMPLETED_ASSIGNMENTS}`
    }),
    total() {
      return flattenFields(this.fields, true).length;
    },
    completed() {
      const completedFields = _.chain(flattenFields(this.fields, true))
        .map(field => _.get(
          this.completedAssignments,
          `${this.sectionType}:${this.sectionSlug}:${field?.label}`
        ))
        .reject(a => (a ?? false) === false)
        .value();

      return completedFields.length;
    },
    isCompleted() {
      return this.total === this.completed;
    },
    color() {
      if (this.isCompleted) return "tw-text-green-600";
      return "tw-text-gray-500";
    },
    icon() {
      if (this.isCompleted) return "fas fa-check";
      return "fas fa-edit";
    },
    tooltip() {
      if (this.isCompleted) return "This section is complete";
      if (this.completed === 0) return "No questions have been completed in this section";
      return `${this.completed} out of ${this.total} questions have been completed`;
    }
  }
};
</script>
