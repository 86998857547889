<template>
  <div class="tw-fixed tw-bottom-0 tw-inset-x-0 tw-px-4 tw-pb-6 sm:tw-inset-0 sm:tw-p-0 sm:tw-flex sm:tw-items-center sm:tw-justify-center tw-z-50">
    <div class="tw-fixed tw-inset-0 tw-transition-opacity">
      <div class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75" />
    </div>

    <div
      class="tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-opacity sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div
        v-show="invited"
        v-cloak
        class="tw-border-l-4 tw-text-xs tw-border-l-solid tw-border-green-500 tw-bg-green-100 tw-p-1 tw-pl-3 tw-mt-2 tw-mb-4 tw-text-green-700"
        role="alert"
      >
        User has been invited and emailed
      </div>

      <div
        v-show="!_.isEmpty(errors)"
        v-cloak
        id="invite-form-errors-holder"
      >
        <div
          class="tw-border-l-4 tw-text-xs tw-border-l-solid tw-border-red-500 tw-bg-red-100 tw-p-1 tw-pl-3 tw-mt-2 tw-mb-4 tw-text-red-700"
          role="alert"
        >
          <div v-for="error in Object.keys(errors)" :key="error">
            <span class="tw-font-bold tw-mr-1" v-text="titleCase(error)" />
            <span v-text="errors[error].join(', ')" />
          </div>
        </div>
      </div>

      <div class="sm:tw-flex sm:tw-items-start">
        <form
          id="new_user"
          class="simple_form new_user tw-w-full"
          action="/users"
          accept-charset="UTF-8"
          method="post"
        >
          <div class="tw-grid tw-grid-cols-8 tw-gap-2">
            <div class="tw-col-span-8 sm:tw-col-span-4">
              <div class=" string optional user_first_name">
                <label class="col-form-label string optional" for="user_first_name">First Name</label>
                <input
                  id="user_first_name"
                  v-model="user.firstName"
                  :class="css.forms.input"
                  type="text"
                  name="user[first_name]"
                >
              </div>
            </div>
            <div class="tw-col-span-8 sm:tw-col-span-4">
              <div class="string optional user_last_name">
                <label class="col-form-label string optional" for="user_last_name">Last Name</label>
                <input
                  id="user_last_name"
                  v-model="user.lastName"
                  :class="css.forms.input"
                  type="text"
                  name="user[last_name]"
                >
              </div>
            </div>
            <div class="tw-col-span-8 sm:tw-col-span-4">
              <div class="email optional user_email">
                <label class="col-form-label email optional" for="user_email">Email</label>
                <input
                  id="user_email"
                  v-model="user.email"
                  :class="css.forms.input"
                  type="email"
                  name="user[email]"
                >
              </div>
            </div>
            <div class="tw-col-span-8 sm:tw-col-span-4">
              <div class="tel optional user_phone_number">
                <label class="col-form-label tel optional" for="user_phone_number">Mobile Phone Number</label>
                <input
                  id="user_phone_number"
                  v-model="user.phoneNumber"
                  :class="css.forms.input"
                  type="tel"
                  name="user[phone_number]"
                >
              </div>
              <p class="help-text" />
            </div>
            <div class="tw-col-span-8 sm:tw-col-span-4">
              <div class="form-group select optional user_role">
                <label class="col-form-label select optional" for="user_role">Role</label>
                <select
                  id="user_role"
                  v-model="user.roleIds"
                  class="form-control select optional"
                  name="user[role]"
                >
                  <option label=" " />
                  <option value="4">
                    Engineer
                  </option>
                  <option value="5">
                    Accountant
                  </option>
                  <option value="2">
                    Coordinator
                  </option>
                  <option value="3">
                    Tech Lead
                  </option>
                </select>
              </div>
            </div>

            <div class="tw-col-span-8">
              <div class="form-check boolean optional user__section_assigned">
                <input
                  type="hidden"
                  value="0"
                  name="user[_section_assigned]"
                >
                <label class="boolean optional form-check-label" for="user__section_assigned">
                  <input
                    id="user__section_assigned"
                    v-model="user.sectionAssigned"
                    :class="css.forms.checkbox"
                    type="checkbox"
                    value="1"
                    name="user[_section_assigned]"
                  >
                  Assign this {{ readableSectionType() }} to them
                </label>
                <small class="form-text text-muted">
                  This user will be responsible for ensuring this {{ readableSectionType() }} is completed
                </small>
              </div>
            </div>

            <div class="tw-col-span-8 sm:tw-col-span-8 tw-flex tw-justify-between tw-mt-4">
              <div>
                <div class="tw-inline-flex tw-rounded-md">
                  <button
                    type="button"
                    class="tw-border tw-border-transparent tw-text-sm tw-leading-3 tw-px-3 tw-py-2 tw-font-medium tw-rounded-md tw-text-white tw-bg-red-600 focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-75 hover:tw-no-underline"
                    @click="$emit('should-show-modal' , false)"
                  >
                    Close
                  </button>
                </div>
              </div>
              <div>
                <div
                  v-show="!invited"
                  class="tw-inline-flex tw-rounded-md"
                  @click="inviteUser()"
                >
                  <button
                    type="button"
                    class="tw-border tw-border-transparent tw-text-sm tw-leading-3 tw-px-3 tw-py-2 tw-font-medium tw-rounded-md tw-text-white tw-bg-green-600 focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-75 hover:tw-no-underline"
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { css } from "@shared/theme.js";
import { post } from "@shared/http.js";
import { titleCase } from "@shared/helpers.js";
import _ from "lodash";

interface Errors {
  [key: string]: string[]; // Assuming each error key has an array of strings
}

const props = defineProps<{
  assignType: string;
}>();

const emit = defineEmits<{(event: "should-show-modal", value: boolean): void;
  (event: "update-users", value: object): void;
}>();

interface User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string,
  roleIds: string;
  sectionAssigned: boolean;
}

const user = ref<User>({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roleIds: "",
  sectionAssigned: false
});

const errors = ref<Errors>({});
const invited = ref<boolean>(false);

const resetUserData = (): void => {
  user.value = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    roleIds: "",
    sectionAssigned: false
  };
};

const readableSectionType = () => {
  if (props.assignType === "StepAssign") return "step";
  if (props.assignType === "FieldAssign") return "question";

  return "section";
};

const inviteUser = async () => {
  errors.value = {};

  const formData = new FormData();
  formData.append("user[first_name]", user.value.firstName);
  formData.append("user[last_name]", user.value.lastName);
  formData.append("user[email]", user.value.email);
  formData.append("user[role_ids]", user.value.roleIds);
  formData.append("user[_section_assigned]", JSON.stringify(user.value.sectionAssigned));
  if (user.value.phoneNumber !== null) formData.append("user[phone_number]", user.value.phoneNumber);

  const result = await post("/users/invitation", formData);

  if ("errors" in result) errors.value = result.errors;

  if ("email" in result) {
    invited.value = true;
    emit("update-users", { user: result, shouldAssign: user.value.sectionAssigned });

    resetUserData();

    setTimeout(() => emit("should-show-modal", false), 2000);
  }
};
</script>
