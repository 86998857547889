<template>
  <div />
</template>

<script>
export default {
  name: "Summary",
  components: {

  },
  props: {
    slug: {
      required: true,
      type: String
    }
  }
};
</script>
