<template>
  <tooltip
    content="Non-UK based subcontractors are non-qualifying unless you are able to provide an appropriate justification for it being included. Click here to provide this justification"
    class="tw-flex tw-relative tw-items-center tw-text-lg tw-cursor-pointer"
    theme="light"
    @click="addJustification()"
  >
    <i class="far fa-file-plus tw-w-5" />
    <span v-if="!showPing" class="tw-absolute tw--top-1 tw--right-0.5 tw-flex tw-h-3 tw-w-3">
      <span class="tw-animate-ping tw-absolute tw-inline-flex tw-h-3 tw-w-3 tw-rounded-full tw-bg-error-red-750 tw-opacity-75" />
      <span class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-error-red-750" />
    </span>
  </tooltip>
  <Dialog
    as="div"
    class="tw-relative tw-z-10"
    :open="isOpen"
    @close="setIsOpen"
    @keyup.esc="closeDialog()"
  >
    <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75" />
    <div class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto">
      <div class="tw-flex tw-items-end sm:tw-items-center tw-justify-center tw-min-h-full tw-p-4 tw-text-center sm:tw-p-0">
        <DialogPanel class="tw-relative tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-max-w-xl sm:tw-w-full sm:tw-p-8">
          <div class="tw-hidden sm:tw-block tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-4">
            <button type="button" class="tw-bg-white tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none" @click="closeDialog()">
              <span class="tw-sr-only">Close</span>
              <i class="fa fa-times fas color-heather tw-text-lg hover:tw-text-red-600 tw-transition tw-delay-50" />
            </button>
          </div>

          <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left tw-flex tw-flex-col tw-gap-2">
            <DialogTitle as="h3" class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
              Justification for {{ subCost.rd_col_1 || 'Untitled' }} exemption in claim
            </DialogTitle>
            <DialogDescription>
              <div class="tw-flex tw-flex-col tw-gap-2">
                <p class="tw-text-sm tw-text-gray-500">
                  Non-UK based subcontractors are non-qualifying unless you are able to provide an appropriate justification to HMRC for its inclusion.
                  Please provide this justification below so we can review if it qualifies.
                </p>
                <div class="tw-my-4">
                  <textarea
                    v-model="justification"
                    rows="4"
                    :class="theme.forms.textarea"
                    aria-label="Explanation to justify why this cost is exempt:"
                  />
                </div>
              </div>
              <div class="tw-pt-2 tw-flex tw-gap-x-2 tw-justify-end">
                <button-component
                  text="Save"
                  colour="confirm"
                  @click="save()"
                />
                <button-component text="Cancel" colour="error" @click="closeDialog()" />
              </div>
            </DialogDescription>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
  DialogOverlay } from "@headlessui/vue";
import { css } from "../../../shared/theme.js";

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    DialogOverlay
  },
  props: {
    subCost: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const theme = css;

    const isOpen = ref(false);
    function setIsOpen(value) {
      isOpen.value = value;
    }
    function closeDialog() {
      isOpen.value = false;
    }

    const justification = ref("");
    function addJustification() {
      justification.value = props.subCost?.overseas_exemption_justification;
      setIsOpen(true);
    }

    function save() {
      store.dispatch(
        "validations/VALIDATE",
        ["CostsSubcontractor", props.subCost.slug, { overseas_exemption_justification: justification.value }]
      );
      closeDialog();
    }

    const showPing = computed(() => props.subCost?.overseas_exemption_justification && props.subCost.overseas_exemption_justification !== "");

    return {
      isOpen, setIsOpen, closeDialog, showPing, justification, theme, save, addJustification
    };
  }
};
</script>
