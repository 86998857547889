<template>
  <div>
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-w-full">
      <div class="tw-w-2/3 tw-pr-10">
        <h1 class="tw-text-2xl tw-mb-2">
          Add relevant information for your EPW Costs
        </h1>
        <CmsContentSlot
          class="cms-content tw-mb-5"
          :content-slot-id="strapiIntroContent"
          size="small"
        />
      </div>
      <div class="tw-text-right tw-space-y-3 tw-w-1/3">
        <div
          class="tw-bg-white tw-rounded-lg tw-shadow tw-flex tw-flex-col tw-gap-2 lg:tw-gap-0 lg:tw-py-4 lg:tw-px-6 tw-py-2 tw-px-2"
        >
          <!-- assignment component to go here -->
          <MarkAsComplete
            :is-complete="!!externalWorkersSection.completed_at"
            :tooltip-content="completeButtonTooltip"
            :is-disabled="isLoading || !!externalWorkersSection.completed_at"
            @handle-complete="handleComplete"
          />
        </div>
      </div>
    </div>

    <banner-component v-if="pageErrorMessage" :text="pageErrorMessage" />

    <ExternalWorkersTable
      :external-workers="externalWorkerData"
      :is-loading="isLoading"
      :error-message="dataFetchErrorMessage"
      :editable="!externalWorkersSection.completed_at"
    />
  </div>
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { computed } from "vue";

import CmsContentSlot from "@generic/cms_content_slot.vue";
import strapiContentIds from "@shared/strapi_content_ids.js";
import MarkAsComplete from "@generic/mark_as_complete.vue";
import ExternalWorkersTable from "./external_workers_table.vue";

const props = defineProps<{
  externalWorkersSectionSlug: string;
}>();

const store = useStore();

const strapiIntroContent = strapiContentIds.externalWorkers.introForPaye;

store.dispatch("external_workers/FETCH_EXTERNAL_WORKERS", { externalWorkersSectionSlug: props.externalWorkersSectionSlug });

const externalWorkerData = computed(() => store.getters["external_workers/GET_EXTERNAL_WORKERS"]);
const externalWorkersSection = computed(() => store.getters["external_workers/GET_EXTERNAL_WORKERS_SECTION"]);
const isLoading = computed(() => store.getters["external_workers/GET_IS_LOADING"]);
const dataFetchErrorMessage = computed(() => store.getters["external_workers/GET_DATA_FETCH_ERROR_MESSAGE"]);
const pageErrorMessage = computed(() => store.getters["external_workers/GET_PAGE_ERROR_MESSAGE"]);

function handleComplete() {
  if (externalWorkersSection.value.completed_at) return;

  store.dispatch("external_workers/COMPLETE_EXTERNAL_WORKERS_SECTION", {
    externalWorkersSectionSlug: props.externalWorkersSectionSlug
  });
}

const completeButtonTooltip = computed(() => {
  if (externalWorkersSection.value.completed_at) {
    return "This section has been completed, if you would like to make amendments to this section please contact your Claim Manager";
  }
  return null;
});
</script>

<style lang="scss" scoped>
:deep() {
  p {
    margin-bottom: 0.25rem;
  }
}
</style>
