<template>
  <div class="tw-inline-flex tw-rounded-md tw-shadow-sm tw-pointer-cursor">
    <button
      :type="buttonType"
      :class="['tw-border tw-border-transparent tw-font-medium tw-rounded-md  focus:tw-outline-none tw-transition tw-ease-in-out tw-duration-75 hover:tw-no-underline tw-shadow-xs tw-text-xs tw-p-2', backgroundColor]"
    >
      <div class="tw-flex tw-items-center">
        <div v-if="icon != null">
          <i :class="`tw-w-4 tw-mr-2 far ${icon} fa-1x`" />
        </div>
        <div>
          {{ text }}
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    buttonType: { type: String, default: "button" },
    color: { type: String, default: "deep-blue" },
    text: { type: String, default: "Submit" },
    icon: { type: String, default: null },
    primary: false
  },
  computed: {
    backgroundColor() {
      if (this.color == "deep-blue") return this.primary ? "tw-bg-deep-blue hover:tw-bg-deep-blue-light tw-text-white tw-border-transparent" : "tw-bg-transparent hover:tw-bg-gray-200 tw-text-deep-blue hover:tw-text-darker-blue tw-border-deep-blue";
    }
  }
};
</script>
