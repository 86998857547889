import { get, post } from "../../shared/http.js";

export const GET_USERS = "GET_USERS";
export const FETCH_ASSIGNMENTS = "FETCH_ASSIGNMENTS";
export const GET_COMPANY_ASSIGNMENTS = "GET_COMPANY_ASSIGNMENTS";
export const GET_COMPLETED_ASSIGNMENTS = "GET_COMPLETED_ASSIGNMENTS";
export const FETCH_USERS = "FETCH_USERS";
export const ASSIGN_USER = "ASSIGN_USER";
export const UNASSIGN_USER = "UNASSIGN_USER";
export const GET_LOADING = "GET_LOADING";
export const COMPLETE_ASSIGNMENT = "COMPLETE_ASSIGNMENT";
export const SET_LOADING = "SET_LOADING";

export const ASSIGNMENT_MODE_QUESTION = "ASSIGNMENT_MODE_QUESTION";
export const ASSIGNMENT_MODE_SECTION = "ASSIGNMENT_MODE_SECTION";

export const assignments = {
  namespaced: true,
  state: {
    users: null,
    companyAssignments: null,
    completedAssignments: null,
    companySlug: null,
    loading: false
  },
  mutations: {
    setAllUsers: (state, payload) => (state.users = payload),
    setCompanyAssignments: (state, payload) => state.companyAssignments = payload,
    setCompletedAssignments: (state, payload) => state.completedAssignments = payload,
    setLoading: (state, payload) => (state.loading = payload)
  },
  actions: {
    [FETCH_USERS]: async ({ getters, commit, state }, payload) => {
      commit("setLoading", true);
      const usersData = await get(`/api/forms/assignable_users/${payload.sectionType}/${payload.sectionSlug}`);
      commit("setAllUsers", usersData);
      commit("setLoading", false);
      return state.users;
    },
    [FETCH_ASSIGNMENTS]: async ({ commit, state }, payload) => {
      if (state.loading) return;

      if (state.companyAssignments === null || state.completedAssignments === null) {
        commit("setLoading", true);
      }

      const [companyAssignmentsRes, completedAssignmentsRes] = await Promise.all([
        post("/api/company_assignments", { sections: payload }),
        post("/api/completed_assignments", { sections: payload })
      ]);

      commit("setCompanyAssignments", companyAssignmentsRes);
      commit("setCompletedAssignments", completedAssignmentsRes);
      commit("setLoading", false);
    },
    [ASSIGN_USER]: async ({ getters, commit }, payload) => {
      const assignData = await post("/api/forms/assign", payload);

      if (assignData.success === true) {
        return "success";
      }
    },
    [COMPLETE_ASSIGNMENT]: async ({ getters, commit }, payload) => {
      const completeData = await post("/api/forms/complete", payload);

      if (completeData.success === true) {
        return completeData;
      }
    }
  },
  getters: {
    [GET_USERS]: state => state.users,
    [GET_LOADING]: state => state.loading,
    [GET_COMPANY_ASSIGNMENTS]: state => state.companyAssignments,
    [GET_COMPLETED_ASSIGNMENTS]: state => state.completedAssignments
  }
};
