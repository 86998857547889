<template>
  <Modal :id="modalId" ref="periodOfAccountModal" show-close>
    <template #title>
      Period Of Account Date Change
    </template>

    <div class="tw-text-gray-900">
      <p v-if="filingChanges.length > 1" class="tw-text-sm tw-mb-4">
        There have been multiple reference date changes for this period of account. Please refer to the most recent change and ensure it is correct.
      </p>

      <ol class="tw-text-sm">
        <li v-for="(filingChange, index) in filingChanges" :key="index" class="tw-space-y-2 tw-mb-6 tw-ml-6">
          <p class="tw-text-sm tw-font-semibold">
            {{ formatDate(filingChange.date, 'dd/MM/yyyy') }} - change registered at Companies House
          </p>
          <p class="tw-text-sm">
            {{ filingChange.formatted_description }}
          </p>
        </li>
      </ol>

      <div class="tw-space-y-2">
        <p class="tw-text-sm">
          Please review the readiness checks to ensure that the dates on the Claim Period match. If they do not, please update them.
        </p>
        <p class="tw-text-sm">
          You may need to check the subsequent Claim Period dates too.
        </p>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { formatDate } from "@shared/helpers.js";
import Modal from "@generic/modal_component.vue";
import { PeriodOfAccount, PeriodOfAccountChangeFiling } from "./interfaces";

const props = defineProps<{
  periodOfAccount: PeriodOfAccount | {};
  modalId: string;
}>();

const filingChanges = computed<PeriodOfAccountChangeFiling[] | []>(() => {
  if (!("period_of_account_change_filings" in props.periodOfAccount)) return [];

  return props.periodOfAccount.period_of_account_change_filings;
});
</script>
