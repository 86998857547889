<template>
  <div id="project-selection-project-expenditure-estimate" class="tw-flex tw-flex-col tw-gap-4">
    <card-component
      id="project-expenditure-estimate-blurb"
      :title="title"
    >
      <template #action>
        <state-wrapper component="AssignIcon">
          <AssignIcon
            section-type="ProjectSelection"
            :section-slug="slug"
            assign-icon-type="StepAssign"
            :fields="['project_expenditure_estimate']"
          />
        </state-wrapper>
      </template>
      <template #content>
        <div class="tw-mb-4">
          You have listed <span class="tw-font-semibold">{{ rdProjects.length }}</span> projects.
        </div>
        <div class="tw-mb-4">
          Please use the sliders below to assign a percentage estimate for the qualifying R&D expenditure spent on each project.
          It is important to choose a percentage that accurately reflects the amount of qualifying R&D expenditure you spent on each project, as we will use these figures to validate the projects you’ve told us about.
        </div>
        <div>
          The total figure for all percentages should add up to 100% only.
        </div>
      </template>
    </card-component>
    <slot name="errorMessage" />
    <div id="project-expenditure-estimate-table">
      <RdProjectsTable step-key="project_expenditure_estimate" :slug="slug">
        <template #bottomTableContent>
          <div class="tw-p-4 tw-flex tw-items-center tw-justify-end">
            <ProjectExpendituresTotal
              :slug="slug"
            />
          </div>
        </template>
      </RdProjectsTable>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { stepTitles } from "./project_selection_steps.js";
import RdProjectsTable from "./rd_projects_table.vue";
import AssignIcon from "../dynamic_forms/assign_icon.vue";
import ProjectExpendituresTotal from "./column_components/project_expenditures_total.vue";

export default {
  components: {
    RdProjectsTable,
    AssignIcon,
    ProjectExpendituresTotal
  },
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  setup() {
    const store = useStore();
    const title = computed(() => stepTitles.project_expenditure_estimate);
    const rdProjects = computed(() => store.getters["project_selection/GET_RD_PROJECTS"]);

    return {
      title,
      rdProjects
    };
  }
};

</script>
