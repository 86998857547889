<template>
  <div v-if="editable && !isReviewer">
    <div class="tw-relative">
      <dropdown ref="dropdownCategory">
        <template #link>
          <div>
            <input
              v-model="query"
              type="text"
              :placeholder="field.value || 'Select an option...'"
              :class="[theme.forms.input, field.value ? 'placeholder:tw-text-deep-blue' : '', 'tw-pr-8 tw-truncate']"
            >
            <span class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer">
              <FadeTransition>
                <i
                  v-if="query !== null && query.length !== 0"
                  class="far fa-times-circle fa-lg tw-text-gray-500"
                  @click="query = null"
                />
              </FadeTransition>
              <i :class="['far fa-lg fa-angle-down tw-text-gray-500 tw-duration-300 ', dropdownCategory && dropdownCategory.show ? 'tw-transform tw-rotate-180' : '']" />
            </span>
          </div>
        </template>
        <template #items>
          <div
            v-if="query !== null"
            :class="['tw-w-full tw-z-50 tw-mt-0.5 tw-max-h-60 tw-overflow-y-scroll', theme.dropDown.menuDivFullWidth]"
          >
            <template
              v-for="(field, field_key) in filteredFields(query)"
              :key="field_key"
            >
              <div
                :class="[theme.dropDown.itemDiv]"
                @click="setFieldValue(field)"
              >
                {{ field }}
              </div>
            </template>
          </div>
          <div
            v-else
            :class="['tw-w-1/2 tw-z-50 tw-mt-0.5', theme.dropDown.menuDivFullWidth]"
          >
            <template
              v-for="(option, key) in options(`${sectionType}:${sectionSlug}:${field.label}`)"
              :key="key"
            >
              <dropdown :transition="false">
                <template #link>
                  <div :class="[theme.dropDown.itemDiv, 'tw-w-full tw-inline-flex tw-items-center tw-justify-between']">
                    {{ option.label }}
                    <i class="far fa-chevron-right" />
                  </div>
                </template>
                <template #items>
                  <div :class="['tw-w-full tw-z-50 tw-top-0 tw-left-full tw-max-h-60 tw-overflow-y-scroll', theme.dropDown.menuDivFullWidth]">
                    <template
                      v-for="(field, field_key) in option.fields"
                      :key="field_key"
                    >
                      <div
                        :class="[theme.dropDown.itemDiv]"
                        @click="setFieldValue(field, option.label)"
                      >
                        {{ field }}
                      </div>
                    </template>
                  </div>
                </template>
              </dropdown>
            </template>
          </div>
        </template>
      </dropdown>
    </div>
  </div>
  <div v-else>
    <div class="tw-text-gray-500 tw-text-sm">
      {{ field.value }}
    </div>
  </div>
</template>

<script>

import { useStore } from "vuex";
import _ from "lodash";
import { computed, ref } from "vue";
import FadeTransition from "../../transitions/fade.vue";

import { css } from "../../../../shared/theme.js";

export default {
  components: {
    FadeTransition
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant", "RdProject"];

        return validSections.indexOf(value) >= 0;
      },
      required: true
    },
    sectionSlug: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isReviewer: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const options = computed(() => store.getters["sections/GET_MULTI_SELECT_OPTIONS"]);

    const theme = css;
    const dropdownCategory = ref(null);
    const query = ref(null);

    function setFieldValue(field, parent = null) {
      props.field.value = parent === (undefined || null) ? field : `${parent} > ${field}`;
      query.value = null;
      dropdownCategory.value?.away();
    }

    function filteredFields(query) {
      const getFieldsObj = _.chain(options.value(`${props.sectionType}:${props.sectionSlug}:${props.field.label}`))
                              .mapValues("fields")
                              .values()
                              .value();

      const allFields = Object.assign({}, ...getFieldsObj);

      const matches = _.chain(allFields)
                          .filter(f => f.toUpperCase().indexOf(query.toUpperCase()) > -1)
                          .value();

      if (matches.length > 0) {
        return _.chain(matches)
                  .map(field => `${getParentForField(field)} > ${field}`)
                  .value();
      }
      return [`No matches found for '${query}'`];
    }

    function getParentForField(field) {
      const parentKey = _.chain(options.value(`${props.sectionType}:${props.sectionSlug}:${props.field.label}`))
                           .findKey(category => Object.values(category.fields).indexOf(field) > -1)
                           .value();

      return options.value(`${props.sectionType}:${props.sectionSlug}:${props.field.label}`)[parentKey].label;
    }

    return {
      theme,
      setFieldValue,
      options,
      dropdownCategory,
      query,
      filteredFields
    };
  }
};
</script>
