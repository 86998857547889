<template>
  <Dialog
    as="div"
    class="tw-relative tw-z-10"
    :open="isOpen"
    @close="autoClose ? close : null"
  >
    <DialogOverlay
      :class="[
        withinSidebar ? 'xl:tw-top-0 xl:tw-left-64 xl:tw-right-0 xl:tw-bottom-0' : '',
        'tw-inset-0 tw-fixed tw-bg-gray-600 tw-bg-opacity-75'
      ]"
    />
    <div
      :id="id"
      :class="[
        withinSidebar ? 'xl:tw-top-16 xl:tw-left-64 xl:tw-right-0 xl:tw-bottom-0' : '',
        'tw-inset-0 tw-fixed tw-z-10 tw-overflow-y-auto'
      ]"
    >
      <div class="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-p-4 tw-text-center sm:tw-p-0">
        <DialogPanel
          :class="[
            'tw-relative tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-p-8',
            wider ? 'sm:tw-max-w-4xl' : 'sm:tw-max-w-xl'
          ]"
        >
          <div v-if="showClose" class="tw-hidden sm:tw-block tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-4">
            <button type="button" class="tw-bg-white tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none" @click="close()">
              <span class="tw-sr-only">Close</span>
              <i class="fa fa-times fas color-heather tw-text-lg hover:tw-text-red-600 tw-transition tw-delay-50" />
            </button>
          </div>

          <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left tw-flex tw-flex-col tw-gap-5">
            <DialogTitle v-if="$slots.title" as="h3" class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
              <slot name="title" />
            </DialogTitle>
            <DialogDescription>
              <slot />
            </DialogDescription>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
  DialogOverlay } from "@headlessui/vue";
import useEventsBus from "../../shared/eventBus";

// To use this component use the following:
// <Modal ref="modal">
// Whatever you want in the modal, (Use <template #title> to put specific code in the title of the modal)
// </Modal>
// In the setup of where you're using the modal, make sure you define the ref with the name you gave it in the template
// Ex: const modal = ref(null), then you can call modal.value.close() & .show() to close and open the modal respectively

// IF USING IN ERB RAILS (or vue files that are not using the vue3 composition api)
// You can trigger any modal used in erb files by using the button-component and passing the id string of the modal to the
// showModal or hideModal prop on the button-component. This will trigger the modal to open or close respectively.

export default {
  name: "Modal",
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    DialogOverlay
  },
  props: {
    // This is the id of the modal, this is used to trigger the modal from other components
    id: {
      type: String,
      required: false
    },
    // This controls whether clicking outisde the modal or pressing the escape key will automatically close the modal
    // If there is any form filling in the modal this should be set to false
    autoClose: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    withinSidebar: {
      type: Boolean,
      default: false
    },
    queryParam: {
      type: String
    },
    wider: {
      type: Boolean,
      default: false
    },
    startOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isOpen = ref(false);
    const { bus } = useEventsBus();
    const urlParams = new URLSearchParams(window.location.search);

    function autoOpenModal() {
      if (urlParams.has(props.queryParam)) {
        show();
      }
    }

    function show() {
      isOpen.value = true;
    }
    function close() {
      isOpen.value = false;

      if (props.queryParam && urlParams) {
        if (urlParams.has(props.queryParam)) {
          urlParams.delete(props.queryParam);
          window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
        }
      }
    }

    if (props.queryParam && urlParams) {
      autoOpenModal();
    }

    onMounted(() => {
      if (props.startOpen) {
        show();
      }
    });

    watch(() => bus.value.get("toggleModal"), val => {
      const [modalId] = val ?? [];

      if (modalId === props.id) isOpen.value = !isOpen.value;
    });

    return {
      isOpen, show, close
    };
  }
};
</script>
