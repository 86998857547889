<template>
  <div :id="sectionType" @mouseover="hovering = true" @mouseleave="hovering = false">
    <div v-if="open || startOpen || allOpen" class="tw-inline-flex tw-w-full tw-justify-between tw-items-center tw-py-2">
      <div class="tw-inline-flex tw-items-center tw-gap-3">
        <RadialProgressBar
          :section-status="sectionStatus"
          :section-percentage="sectionPercentage"
          :size="40"
          :animate="startOpen || open"
        />

        <div class="tw-flex tw-flex-col tw-justify-start tw-text-xs" :class="isComplete ? 'tw-text-green-700' : ''">
          <div class="tw-flex tw-uppercase tw-tracking-wide tw-font-bold tw-mb-0.5">
            {{ sectionName }}
          </div>

          <div class="tw-flex tw-font-semibold" :class="isComplete ? 'tw-font-semibold' : 'tw-text-gray-400'">
            {{ sectionStatus }}
          </div>

          <!-- <div class="tw-flex tw-text-gray-400" v-else>
            Estimated time:
            <div class="tw-font-semibold tw-ml-0.5">
              {{ sectionDuration }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="tw-inline-flex tw-items-center tw-gap-2">
        <tooltip v-if="!startOpen && !allOpen && sectionLink" :id="`${sectionType}_progression_link`" :content="`Go to ${sectionName}`">
          <a :href="sectionLink" @click="trackClick()">
            <i class="far fa-external-link tw-w-5 tw-h-5" />
          </a>
        </tooltip>
        <i v-if="open || hovering" :class="open ? 'tw-transform tw-rotate-180 tw-duration-200' : 'tw-duration-200'" class="far fa-chevron-down tw-w-5 tw-h-5 tw-pt-1" />
      </div>
    </div>
    <div v-else class="tw-inline-flex tw-w-full tw-justify-between tw-items-center tw-py-2">
      <div class="tw-flex tw-items-center tw-gap-3">
        <RadialProgressBar :section-status="sectionStatus" :section-percentage="sectionPercentage" :size="20" />

        <div class="tw-flex tw-items-center tw-text-xs" :class="isComplete ? 'tw-text-green-700' : ''">
          <div class="tw-uppercase tw-tracking-wide tw-font-bold">
            {{ sectionName }}
          </div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-gap-2.5">
        <div class="tw-flex tw-font-semibold tw-text-xs" :class="isComplete ? 'tw-font-semibold tw-text-green-700' : 'tw-text-gray-400'">
          {{ sectionStatus }}
        </div>
        <i v-if="open" :class="open ? 'tw-transform tw-rotate-180 tw-duration-200' : 'tw-duration-200'" class="far fa-chevron-down tw-w-5 tw-h-5 tw-pt-1" />
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from "./radial_progress_bar.vue";

export default {
  components: {
    RadialProgressBar
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    },
    startOpen: {
      type: Boolean,
      required: true
    },
    allOpen: {
      type: Boolean,
      required: false
    },
    hovering: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    sectionPercentage() {
      return this.section.total;
    },
    sectionType() {
      return this.section.type;
    },
    sectionData() {
      return this.section.claim_section;
    },
    sectionStatus() {
      return this.section.status;
    },
    sectionLink() {
      return this.section.link;
    },
    isComplete() {
      return this.sectionPercentage === 100.0;
    },
    sectionName() {
      return this.sectionData.title;
    }
  },
  methods: {
    trackClick() {
      this.track("Claim Progression Link Clicked", { section: this.sectionData?.title || this.sectionType,
        status: this.section.status,
        claimPeriodslug: this.claimPeriodSlug });
    }
  }
};
</script>
