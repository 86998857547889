const costColumns = {
  CostsEmployee: [
    {
      field: "rd_col_1",
      database_field: "name",
      label: "Name",
      format: "String",
      width: "md:tw-w-64",
      rd_width: "md:tw-w-1/5",
      can_receive_feedback: false
    },
    {
      field: "rd_col_2",
      database_field: "job_title",
      label: "Job Title",
      format: "String",
      width: "md:tw-w-64",
      can_receive_feedback: true
    },
    {
      field: "gross_pay",
      label: "Gross Pay",
      format: "Currency",
      width: "md:tw-w-44",
      can_receive_feedback: false,
      tooltip: "Include the total salary paid within the claim period, prior to any tax or benefit deductions, for the employee.",
      has_knowledge_center: true,
      faq_slug: "what-is-gross-pay"
    },
    {
      field: "employer_ni",
      label: "Employer NI",
      format: "Currency",
      width: "md:tw-w-32",
      can_receive_feedback: false,
      tooltip: "Include the total Employers Class 1 NIC relating to the salary the employee received within the claim period. "
    },
    {
      field: "pension",
      label: "Pension",
      format: "Currency",
      width: "md:tw-w-32",
      can_receive_feedback: false,
      tooltip: "Include the total employer's pension contributions paid, relating to the salary the employee received within the claim period."
    },
    {
      field: "reimbursed_expenses",
      label: "Reimbursed Expenses",
      format: "Currency",
      width: "md:tw-w-32",
      can_receive_feedback: false,
      tooltip: "Any reimbursed expenses relating to your R&D projects need to be paid for by the individual on a personal debit or credit card; this expense then needs to have been reimbursed through the employee's salary payment.",
      has_knowledge_center: true,
      faq_slug: "can-i-claim-for-staff-expenses"
    },
    {
      field: "bonuses",
      label: "Bonuses",
      format: "Currency",
      width: "md:tw-w-32",
      can_receive_feedback: false,
      tooltip: "A staff bonus will only be allowable as a qualifying R&D cost if the bonus can be attributed to the R&D project. For example, a sales bonus will not qualify and shouldn't be included in your R&D claim's employee costs. As long as the bonus is not accrued from previous years, it can also be included in the individual's gross pay and can be apportioned for the relevant time spent on R&D activities during the claim period."
    }
  ],
  CostsSubcontractor: [
    {
      field: "rd_col_1",
      label: "External Worker",
      database_field: "name",
      format: "String",
      width: "md:tw-w-1/6",
      rd_width: "md:tw-w-1/5",
      can_receive_feedback: false
    },
    {
      field: "rd_col_2",
      database_field: "work_description",
      label: "Work Description",
      format: "String",
      width: "md:tw-w-1/5",
      can_receive_feedback: true
    },
    {
      field: "subcost_type_id",
      label: "Cost Category",
      format: "Dropdown",
      width: "md:tw-w-64",
      rd_width: "md:tw-w-1/4",
      can_receive_feedback: true,
      has_knowledge_center: true,
      faq_slug: "what-are-external-workers"
    },
    {
      field: "amount",
      label: "Amount",
      format: "Currency",
      width: "md:tw-w-32",
      can_receive_feedback: false
    },
    {
      field: "uk_based",
      label: "Uk-based?",
      format: "RadioBoolean",
      width: "md:tw-w-32",
      can_receive_feedback: false,
      has_knowledge_center: true,
      faq_slug: "can-i-include-overseas-workers-costs"
    },
    {
      field: "invoice",
      label: "Invoice Available?",
      format: "Boolean",
      width: "md:tw-w-28",
      can_receive_feedback: false
    },
    {
      field: "connected",
      label: "Connected Party?",
      format: "Boolean",
      default: false,
      width: "md:tw-w-28",
      can_receive_feedback: false,
      tooltip: "If the subcontractor is a \"connected supplier\" (usually indicated on the company accounts), for example if the supplier is a wholly owned subsidiary or owned by one of the directors, please tick the box.",
      has_knowledge_center: true,
      faq_slug: "what-are-connected-and-unconnected-subcontractors"
    }
  ],
  CostsConsumable: [
    {
      field: "rd_col_1",
      label: "Supplier",
      database_field: "supplier",
      format: "String",
      width: "md:tw-w-48",
      rd_width: "md:tw-w-1/5",
      can_receive_feedback: false
    },
    {
      field: "rd_col_2",
      label: "Item Type",
      database_field: "item_type",
      format: "String",
      width: "md:tw-w-64",
      rd_width: "md:tw-w-1/5",
      can_receive_feedback: true
    },
    {
      field: "subcost_type_id",
      label: "Cost Category",
      format: "Dropdown",
      width: "md:tw-w-64",
      rd_width: "md:tw-w-1/4",
      can_receive_feedback: true
    },
    {
      field: "amount",
      label: "Amount",
      format: "Currency",
      width: "md:tw-w-32",
      can_receive_feedback: false
    }
  ],
  CostsOther: [
    {
      field: "rd_col_1",
      label: "Description",
      database_field: "description",
      format: "String",
      width: "md:tw-w-64",
      rd_width: "md:tw-w-1/4",
      can_receive_feedback: true
    },
    {
      field: "subcost_type_id",
      label: "Cost Category",
      format: "Dropdown",
      width: "md:tw-w-64",
      rd_width: "md:tw-w-1/4",
      can_receive_feedback: true
    },
    {
      field: "amount",
      label: "Amount",
      format: "Currency",
      width: "md:tw-w-32",
      can_receive_feedback: false
    },
    {
      field: "invoice",
      label: "Invoice Available?",
      format: "Boolean",
      width: "md:tw-w-32",
      can_receive_feedback: false
    }
  ]
};

const fundedColumns = [
  {
    field: "grant_funded",
    label: "Grant Funded?",
    format: "Boolean",
    width: "md:tw-w-32",
    tooltip: "If this person worked on a grant funded or customer projects, check this box.",
    has_knowledge_center: true,
    faq_slug: "why-do-i-need-to-confirm-grants-funding"
  },
  {
    field: "pc_grant_funded",
    label: "% Grant Funded",
    format: "Percent",
    width: "md:tw-w-32",
    has_knowledge_center: true,
    faq_slug: "why-do-i-need-to-confirm-grants-funding",
    tooltip: "The % of time/amount allocated to externally or grant funded projects"
  }
];

const disallowedForRole = {
  coordinator: [],
  emp_admin: [],
  accountant: [],
  tech_lead: ["amount", "gross_pay", "employer_ni", "pension", "bonuses", "reimbursed_expenses", "grant_funded", "pc_grant_funded", "capitalised", "intangible"],
  emp_reviewer: []
};

const pcRdColumn = [
  {
    field: "pc_rd",
    label: "R&D time %",
    format: "Percent",
    rd_width: "md:tw-w-32 lg:tw-min-w-32",
    tooltip: "This is the amount of time spent on R&D throughout the claim period only. If an individual or item cost is assigned to more than one project, enter the average R&D time % for all of their associated projects.",
    can_receive_feedback: true,
    has_knowledge_center: true
  }
];

const capitalisedIntangibleColumns = [
  {
    field: "capitalised",
    label: "Capitalised",
    format: "Boolean",
    width: "md:tw-w-32",
    tooltip: "Capitalised costs are recorded as assets, expensed over time, and reflected on the balance sheet."
  },
  {
    field: "intangible",
    label: "Intangible",
    format: "Boolean",
    width: "md:tw-w-32",
    tooltip: "Intangible fixed assets, like software development, offer long-term commercial or monetary value.",
    can_receive_feedback: true,
    has_knowledge_center: true,
    faq_slug: "can-capitalised-assets-be-included-in-my-claim"
  }
];

const conditionalRdColumns = [
  {
    field: "is_direct_activity",
    label: "Direct/Indirect Activity",
    format: "DropdownBoolean",
    rd_width: "md:tw-w-40 lg:tw-min-w-40",
    can_receive_feedback: true,
    can_be_disabled: true,
    tooltip: "Indirect activities are activities which form part of a project but do not directly contribute to the resolution of the scientific or technological uncertainty",
    has_knowledge_center: true,
    faq_slug: "what-is-qia-qda",
    notification_req: "is_direct_activity_set_by_user",
    notification_tooltip: "This was manually set by the User not the platform",
    notification_icon: "user-circle"
  },
  {
    field: "rd_projects",
    label: "R&D Projects",
    format: "DropdownMultiSelect",
    placeholder: "Add R&D Projects...",
    select_all_checkbox_text: "All R&D Projects",
    read_only_text: "R&D Project(s) selected",
    rd_width: "md:tw-w-64 lg:tw-min-w-64",
    tooltip: "Select the R&D project this cost was involved in, you can select multiple projects per cost",
    can_receive_feedback: false,
    can_be_disabled: true
  }
];

const allowedInRd = ["rd_col_1", "rd_col_2", "subcost_type_id"];

// the aditional columns we will show in the reviewers cost table
const allowedInRdEmpowerrd = {
  CostsOther: ["subcost_type_id", "amount"],
  CostsEmployee: ["gross_pay", "employer_ni", "bonuses", "pension", "reimbursed_expenses"],
  CostsSubcontractor: ["subcost_type_id", "amount", "connected"],
  CostsConsumable: ["subcost_type_id", "amount"]
};

// needs to be up to date with missing_fields.yml
const requiredFields = {
  CostsEmployee: ["rd_col_1", "rd_col_2", "gross_pay", "employer_ni", "is_direct_activity", "subcost_type_id"],
  CostsSubcontractor: ["rd_col_1", "rd_col_2", "amount", "is_direct_activity", "subcost_type_id"],
  CostsConsumable: ["rd_col_1", "rd_col_2", "amount", "is_direct_activity", "subcost_type_id"],
  CostsOther: ["rd_col_1", "amount", "is_direct_activity", "subcost_type_id"]
};

export { costColumns,
  fundedColumns,
  disallowedForRole,
  pcRdColumn,
  allowedInRd,
  allowedInRdEmpowerrd,
  requiredFields,
  capitalisedIntangibleColumns,
  conditionalRdColumns };
