<template>
  <FadeTransition>
    <div v-if="feedback" class="tw-mt-4 tw-flex tw-flex-col tw-gap-2">
      <div class="tw-text-yellow-700 tw-text-sm tw-whitespace-pre-wrap">
        <i class="far fa-comment-alt tw-text-base tw-text-warning-yellow tw-inline tw-mr-1" />
        {{ feedback.feedback }}
      </div>
      <div v-if="canShowResolveButton">
        <button
          class="tw-font-medium tw-w-fit tw-text-xs tw-text-gray-600 tw-tracking-tight hover:text-gray-900 hover:tw-bg-gray-100 tw-py-1 tw-px-2 focus:tw-outline-none tw-border tw-rounded-md tw-border-gray-600"
          type="button"
          @click="markResolved()"
        >
          {{ feedback.resolve_activity_type === 'Review/Accept' ? 'Accept Feedback Changes' : 'Mark Feedback as Resolved' }}
        </button>
        <span v-if="feedback.error" class="tw-text-red-500 tw-font-medium tw-text-xs tw-ml-2">
          <i class="fas fa-exclamation-triangle tw-text-base tw-inline" />
          {{ feedback.error[field.label][0] }}
        </span>
      </div>
      <state-wrapper component="UpdateFeedbackForAdmins">
        <UpdateFeedbackForAdmins
          v-if="canShowUpdateButton"
          :section-slug="sectionSlug"
          :feedback="feedback"
          :show-in-small="false"
        />
      </state-wrapper>
    </div>
  </FadeTransition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import FadeTransition from "../transitions/fade.vue";
import UpdateFeedbackForAdmins from "../update_feedback_for_admins.vue";

export default {
  components: {
    FadeTransition,
    UpdateFeedbackForAdmins
  },
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true,
      validator: obj => {
        const valid_field_types = [
          "SmallText",
          "LargeText",
          "YesNoRadio",
          "MultiSelect",
          "RadioSelect",
          "Date",
          "Number",
          "Currency",
          "RadioButtonRevealField",
          "NestedSelect"
        ];

        return valid_field_types.indexOf(obj.type) >= 0;
      }
    }
  },
  setup(props) {
    const store = useStore();
    const userRole = computed(() => store.getters["users/GET_USER_ROLE"]);

    const warningPrompt = computed(() => store.getters["feedbacks/GET_WARNING_PROMPT"]);
    const feedback = computed(() => store.getters["feedbacks/GET_FEEDBACKS_FOR_FIELD"](props.sectionType, props.sectionSlug, props.field.label));
    const claimPeriodSlug = computed(() => store.getters["feedbacks/GET_CLAIM_PERIOD_SLUG"]);

    function markResolved() {
      if (warningPrompt.value) {
        const message = "Once you resolve this final piece of feedback this section will be automatically sent for review. Are you sure you want to proceed?";

        if (confirm(message)) {
          resolve();
        }
      } else {
        resolve();
      }
    }

    function resolve() {
      const feedbackPayload = {
        sectionType: props.sectionType,
        sectionSlug: props.sectionSlug,
        field: props.field.label,
        feedbackId: feedback.value.id
      };

      const statePayload = {
        sectionType: props.sectionType,
        sectionSlug: props.sectionSlug
      };

      store.dispatch("feedbacks/RESOLVE_FEEDBACK", feedbackPayload).then(() => {
        store.dispatch("states/FETCH_STATES_FOR_SECTION", statePayload);
        store.dispatch("states/FETCH_ALL_CLAIM_SECTION_STATES", ({ claimPeriodSlug: claimPeriodSlug.value }));
      });
    }

    const canShowResolveButton = computed(() => ["Coordinator", "Engineer", "Tech lead"].includes(userRole.value));

    const canShowUpdateButton = computed(() => userRole.value == "Emp admin");

    return {
      warningPrompt,
      markResolved,
      feedback,
      userRole,
      canShowResolveButton,
      canShowUpdateButton
    };
  }
};
</script>
