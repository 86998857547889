<template>
  <div class="tw-flex tw-items-center tw-justify-between tw-relative tw-h-12">
    <VueSlider
      ref="slider"
      v-bind="$attrs"
      v-model="model"
      class="tw-flex-1 tw-cursor-pointer"
      @dragging="onChange || clearTimeouts()"
      @drag-end="!modelValue && clearAndSetTimeouts(model)"
      @click="!modelValue && clearAndSetTimeouts(model)"
      @change="onChange"
    />

    <div class="tw-inset-y-0 tw-left-full tw-flex tw-items-center tw-gap-2 tw-pl-4">
      <div v-if="sideButtons & !$attrs.disabled" class="tw-flex tw-flex-col tw-items-end">
        <span
          :class="`${groupPercentage >= 100 ? 'tw-opacity-50' : ''} tw-cursor-pointer`"
          @mousedown="groupPercentage < 100 && startIncrement(true)"
          @mouseup="stopIncrement"
          @mouseleave="stopIncrement"
        >
          <i class="far fa-chevron-up" />
        </span>
        <span
          class="tw-cursor-pointer"
          @mousedown="startIncrement(false)"
          @mouseup="stopIncrement"
          @mouseleave="stopIncrement"
        >
          <i class="far fa-chevron-down" />
        </span>
      </div>
      <div v-if="showSpinner" class="tw-w-[80px]">
        <SavingSpinIndicator ref="spinner" manual />
      </div>
    </div>

    <span v-if="displayValue" class="tw-w-[80px] tw-text-left tw-pl-5 tw-text-s">{{ formattedValue }}</span>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { formatCurrency, numberWithCommas } from "@shared/helpers";
import useEventsBus from "@shared/eventBus";
import SavingSpinIndicator from "@generic/saving_spin_indicator.vue";

const props = defineProps({
  modelValue: {
    type: Number || String
  },
  id: {
    type: String
  },
  storeAction: {
    type: Object,
    default: null
  },
  value: {
    type: Number
  },
  sideButtons: {
    type: Boolean
  },
  onChange: {
    type: Function
  },
  showSpinner: {
    type: Boolean
  },
  localMax: {
    type: Number
  },
  // Use this for actions you want to take when the slider is done moving rather than every move
  onLazyChange: {
    type: Function,
    default: null
  },
  displayValue: {
    type: Object
  },
  groupPercentage: {
    type: Number,
    default: 0
  }
});

const model = props.modelValue ? props.modelValue : ref(props.value);
const store = useStore();

const slider = ref();
const intervalId = ref(null);
const { emit } = useEventsBus();

watch(model, () => {
  if (props.storeAction) {
    store[props.storeAction.action](props.storeAction.name, {
      value: model.value,
      id: props.id,
      ...props.storeAction.data
    });
  }

  if (props.onLazyChange) clearAndSetTimeouts(model.value);
});

function startIncrement(direction) {
  increment(direction);

  intervalId.value = setInterval(() => {
    increment(direction);
  }, 200);
}

function stopIncrement() {
  clearInterval(intervalId.value);
}

function increment(direction) {
  if (direction && slider.value.getValue() >= props.localMax) {
    emit("sliderMaxBlocked");
    return;
  }

  const start = slider.value.getIndex();
  slider.value.setIndex(start + (direction ? 1 : -1));

  if (props.onLazyChange) props.onLazyChange();
}

const spinner = ref();
let timeoutId = null;
let spinnerTimeoutId = null;

function checkLocalMax(val) {
  if (props.localMax !== undefined && (val > props.localMax)) {
    slider.value.setValue(props.localMax);
    emit("sliderMaxBlocked");
  }
}

function clearTimeouts() {
  clearTimeout(spinnerTimeoutId);
  clearTimeout(timeoutId);
}

function clearAndSetTimeouts(val) {
  clearTimeouts();

  spinnerTimeoutId = setTimeout(() => {
    checkLocalMax(val);
    spinner.value?.startSpinner();
  }, 500);

  if (props.onLazyChange) {
    timeoutId = setTimeout(() => {
      triggerLazyChange();
    }, 800);
  }
}

watch(() => props.modelValue, val => {
  clearAndSetTimeouts(val);
});

async function triggerLazyChange() {
  await props.onLazyChange();
  spinner.value?.stopSpinner();
}

const getValue = () => slider.value.getValue();
const getIndex = () => slider.value.getIndex();
const setIndex = index => slider.value.setIndex(index);
const setValue = value => slider.value.setValue(value);

defineExpose({
  getValue,
  getIndex,
  setIndex,
  setValue
});

const formattedValue = computed(() => {
  if (props.displayValue.type === "percentage") return `${model.value}%`;

  const percentageAsValue = (props.displayValue.total / 100) * model.value;

  if (props.displayValue.type === "currency") return formatCurrency(percentageAsValue);
  if (props.displayValue.type === "number") return numberWithCommas(percentageAsValue);

  return percentageAsValue;
});
</script>
