<template>
  <div
    :class="anyValidationErrorsToShow && showErrors ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'"
    class="tw-text-center lg:tw-text-right"
    @click="saveComplete"
  >
    <component
      :is="completeIconType"
      :section-type="sectionType"
      :completed="isCompleted"
      :validation-errors="anyValidationErrorsToShow && showErrors"
      :type="fields[0] ? fields[0].type : null"
    />
  </div>
</template>

<script>
import { watch } from "vue";
import { mapActions, mapGetters } from "vuex";
import { flattenFields, toValidateFields, fieldLabels } from "../../../shared/fields.js";
import useEventsBus from "../../../shared/eventBus";
import { GET_ALL_FIELDS_VALID,
  GET_ANY_VALIDATION_MESSAGES_FOR_FIELDS,
  VALIDATE } from "../../../vuex-store/modules/validations.js";
import { COMPLETE_ASSIGNMENT,
  GET_COMPLETED_ASSIGNMENTS,
  FETCH_ASSIGNMENTS } from "../../../vuex-store/modules/assignments.js";
import SectionComplete from "./complete_icon_types/section_complete.vue";
import FieldComplete from "./complete_icon_types/field_complete.vue";
import FieldGroupComplete from "./complete_icon_types/field_group_complete.vue";
import { FETCH_STATES_FOR_SECTION } from "../../../vuex-store/modules/states.js";

export default {
  name: "CompleteIcon",
  components: {
    SectionComplete,
    FieldComplete,
    FieldGroupComplete
  },
  props: {
    fields: {
      type: Array,
      default: []
    },
    sectionType: {
      type: String,
      required: true,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument"
        ];
        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    completeIconType: {
      type: String,
      required: true,
      validator: str => {
        const valid_complete_icon_types = [
          "SectionComplete",
          "FieldComplete",
          "FieldGroupComplete"
        ];

        return valid_complete_icon_types.indexOf(str) >= 0;
      }
    },
    groupIndex: {
      required: false
    }
  },
  data() {
    return {
      showErrors: false
    };
  },
  computed: {
    ...mapGetters({
      completedAssignments: `assignments/${GET_COMPLETED_ASSIGNMENTS}`,
      anyValidationErrors: `validations/${GET_ANY_VALIDATION_MESSAGES_FOR_FIELDS}`,
      allFieldsValid: `validations/${GET_ALL_FIELDS_VALID}`
    }),
    isCompleted() {
      const completedFields = _.chain(flattenFields(this.fields))
        .map(field => _.get(
          this.completedAssignments,
          `${this.sectionType}:${this.sectionSlug}:${field?.label}`
        ))
        .reject(a => (a ?? false) === false)
        .value();

      return flattenFields(this.fields).length === completedFields.length;
    },
    anyValidationErrorsToShow() {
      return this.anyValidationErrors(this.sectionType, this.sectionSlug, fieldLabels(this.fields)) && !this.isCompleted;
    }
  },
  mounted() {
    const { emit } = useEventsBus();

    watch(() => this.anyValidationErrorsToShow, val => {
      if (val && this.groupIndex && this.groupIndex > 0 && this.completeIconType === "SectionComplete") emit("openAllFieldGroups");
    });
  },
  methods: {
    ...mapActions({
      completeAssignment: `assignments/${COMPLETE_ASSIGNMENT}`,
      fetchAssignments: `assignments/${FETCH_ASSIGNMENTS}`,
      validate: `validations/${VALIDATE}`,
      fetchStatesForSection: `states/${FETCH_STATES_FOR_SECTION}`
    }),
    saveComplete() {
      this.showErrors = true;
      this.validate([this.sectionType, this.sectionSlug, toValidateFields(this.fields), false]);
      if (this.allFieldsValid(fieldLabels(this.fields)) || this.isCompleted) {
        this.completeAssignment({
          section_type: this.sectionType,
          section_slug: this.sectionSlug,
          fields: _.map(flattenFields(this.fields), "label"),
          complete: !this.isCompleted
        }).then(data => {
          if (data?.success) {
            this.fetchAssignments([
              `${this.sectionType}:${this.sectionSlug}`
            ]);
            this.fetchStatesForSection({
              sectionType: this.sectionType,
              sectionSlug: this.sectionSlug
            });
            if (data?.all_completed === true) {
              const { emit } = useEventsBus();
              setTimeout(() => { emit("allCompleted"); }, 1000);
            }
          }
        });
        const eventTracked = this.isCompleted ? "Section Uncompleted" : "Section Completed";
        this.track(eventTracked, {
          sectionType: this.sectionType,
          sectionSlug: this.sectionSlug,
          button: this.completeIconType,
          fields: _.map(flattenFields(this.fields), "label")
        });
      }
      setTimeout(() => this.showErrors = false, 2000);
    }
  }
};
</script>
