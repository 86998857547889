import _ from "lodash";
import { get } from "../../shared/http.js";

export const SET_AUDIT_HISTORY = "SET_EDITED_FEILDS";
export const GET_AUDIT_HISTORY = "GET_AUDIT_HISTORY";
export const GET_AUDIT_HISTORY_FOR_SECTION = "GET_AUDIT_HISTORY_FOR_SECTION";
export const GET_AUDIT_HISTORY_FOR_FIELD = "GET_AUDIT_HISTORY_FOR_FIELD";
export const GET_AUDIT_HISTORY_FOR_FIELDS = "GET_AUDIT_HISTORY_FOR_FIELDS";
export const GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP = "GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP";
export const FETCH_AUDIT_HISTORY = "FETCH_AUDIT_HISTORY";
export const GET_LOADING = "GET_LOADING";

export const audit_history = {
  namespaced: true,
  state: {
    auditHistory: null,
    loading: false
  },
  mutations: {
    setAuditHistory: (state, payload) => state.auditHistory = payload,
    setLoading: (state, payload) => (state.loading = payload)
  },
  actions: {
    [FETCH_AUDIT_HISTORY]: async ({ getters, commit }, payload) => {
      if (getters.GET_AUDIT_HISTORY === null && getters.GET_LOADING != true) {
        commit("setLoading", true);
        const auditHistory = await get(`/api/audits/fetch_audit_history/${payload.sectionType}/${payload.sectionSlug}`);
        commit("setAuditHistory", auditHistory);
        commit("setLoading", false);
      }
    }
  },
  getters: {
    [GET_AUDIT_HISTORY]: state => state.auditHistory,
    [GET_AUDIT_HISTORY_FOR_SECTION]: state => (sectionType, sectionSlug) => {
      if (sectionType && sectionSlug) {
        return _.chain(state.auditHistory)
          .get(`${sectionType}:${sectionSlug}`)
          .filter(a => a.content !== null)
          .orderBy(["created_at"], ["desc"])
          .value();
      } else {
        return null;
      }
    },
    [GET_AUDIT_HISTORY_FOR_FIELD]: state => (field, sectionType = null, sectionSlug = null) => {
      let audits = _.cloneDeep(state.auditHistory);
      if (!audits) {
        return null;
      } else if (sectionType && sectionSlug) {
        audits = _.get(audits, `${sectionType}:${sectionSlug}`);
      } else {
        audits = _.values(audits)[0];
      }

      if (field) {
        return _.chain(audits)
          .get(field)
          .filter(a => a.content !== null)
          .orderBy(["created_at"], ["desc"])
          .value();
      } else {
        return null;
      }
    },
    [GET_AUDIT_HISTORY_FOR_FIELDS]: state => (fields, sectionType = null, sectionSlug = null) => {
      let audits = _.cloneDeep(state.auditHistory);
      if (!audits || _.isEmpty(audits)) {
        return null;
      } else if (sectionType && sectionSlug) {
        audits = _.get(audits, `${sectionType}:${sectionSlug}`);
      } else {
        audits = _.values(audits)[0];
      }

      if (fields && fields.length > 0) {
        return _.chain(audits)
          .pickBy((_value, key) => fields.includes(key))
          .mapValues(audits => _.chain(audits)
                    .filter(a => a.content !== null)
                    .orderBy(["created_at"], ["desc"])
                    .value())
          .value();
      } else {
        return null;
      }
    },
    [GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP]: state => (sectionType, sectionSlug, timestamp, field = null) => {
      if (sectionType && sectionSlug && timestamp) {
        let audits = _.chain(state.auditHistory)
          .get(`${sectionType}:${sectionSlug}`)
          .value();

        if (field) {
          audits = _.get(audits, field);
        } else {
          audits = _.chain(audits).values().flatten().value();
        }

        return _.filter(audits, a => a.created_at >= timestamp
            && a.type === "Audit"
            && (a.content !== null));
      } else {
        return null;
      }
    }
  }
};
