import { get } from "../../shared/http.js";

export const FETCH_DATA = "FETCH_DATA";
export const FETCH_FAQS = "FETCH_FAQS";

export const GET_CLAIM_PERIODS = "GET_CLAIM_PERIODS";
export const GET_COMPANY = "GET_COMPANY";
export const GET_LAST_ACTIVE_SECTION = "GET_LAST_ACTIVE_SECTION";
export const GET_FAQS = "GET_FAQS";

export const company_dashboard = {
  namespaced: true,
  state: {
    claim_periods: null,
    company: null,
    last_active_section: null,
    faqs: [],
    faqsCurrentPage: 0
  },
  mutations: {
    setClaimPeriods: (state, payload) => state.claim_periods = payload,
    setCompany: (state, payload) => state.company = payload,
    setLastActiveSection: (state, payload) => state.last_active_section = payload,
    AppendFAQs: (state, payload) => {
      state.faqs = [...state.faqs, ...payload.faqs];
      state.faqsCurrentPage = payload.page;
    }
  },
  actions: {
    [FETCH_DATA]: async ({ getters, commit, state }, payload) => {
      const response = await get(`/api/company_dashboard/fetch_data/${payload.slug}`);

      commit("setClaimPeriods", response.claim_periods);
      commit("setCompany", response.company);
      commit("setLastActiveSection", response.last_active_section);

      return response;
    },
    [FETCH_FAQS]: async ({ getters, commit, state }, payload) => {
      const page = state.faqsCurrentPage + 1;
      const response = await get(`/api/company_dashboard/faqs?page=${page}`);

      commit(
        "AppendFAQs",
        {
          faqs: response.faqs,
          page
        }
      );

      return response;
    }
  },
  getters: {
    [GET_CLAIM_PERIODS]: state => state.claim_periods,
    [GET_COMPANY]: state => state.company,
    [GET_LAST_ACTIVE_SECTION]: state => state.last_active_section,
    [GET_FAQS]: state => state.faqs
  }
};
