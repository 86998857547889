<template>
  <div>
    <div v-if="mode != 'company'" class="tw-mb-4 tw-text-xs">
      <div class="tw-text-right">
        <div v-if="isFilterSet('emp_admin_id') && tableHasAllData">
          These claims have
          <a
            class="tw-underline"
            target="_blank"
            :href="`/nudges/?claim_manager=${filters['emp_admin_id']}`"
          >
            <span v-text="nudgeCountToDisplay" /> new Nudgescreated last night.
          </a>
        </div>

        <!-- Fill out the space if no filters set to stop the DOM moving around -->
        <div v-if="!isFilterSet('emp_admin_id')">
          <p>&nbsp;</p>
        </div>
      </div>
    </div>

    <div>
      <div class="tw-hidden sm:tw-block">
        <div class="border-b border-gray-200">
          <nav v-if="mode != 'company'" class="tw-w-full tw-inline-flex tw-justify-between  tw-my-1">
            <div class="tw-inline-flex tw-gap-x-2">
              <div class="tw-relative tw-inline-block">
                <dropdown>
                  <template #link>
                    <span class="tw-cursor-pointer">
                      <span class="tw-rounded-md tw-shadow-sm">
                        <button
                          type="button"
                          :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-ring-blue tw-bg-gray-50 active:tw-bg-gray-50 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150', isFilterSet('emp_admin_id') ? 'tw-text-green-600' : '']"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <div class="tw-flex tw-items-center">
                            <i class="fas fa-user-alt tw-mr-2" />
                            <div>
                              <div v-show="isFilterSet('emp_admin_id')">{{ activeClaimManagerName }}</div>
                              <div v-show="!isFilterSet('emp_admin_id')">All Managers</div>
                            </div>
                            <i class="fas fa-chevron-down tw-ml-2" />
                          </div>
                        </button>
                      </span>
                    </span>
                  </template>
                  <template #items>
                    <div class="tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100">
                      <div
                        class="tw-py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <div
                          class="tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                          role="menuitem"
                          @click="updateFilter('emp_admin_id', null)"
                        >
                          All Managers
                        </div>
                        <template
                          v-for="[idx, manager] in Object.entries(managers)"
                          :key="idx"
                        >
                          <div
                            class="tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                            role="menuitem"
                            @click="updateFilter('emp_admin_id', manager.id)"
                          >
                            <span v-text="manager.first_name" />
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </dropdown>
              </div>
              <!-- Combned Filters -->
              <div class="tw-relative tw-inline-block">
                <dropdown>
                  <template #link>
                    <span class="tw-cursor-pointer">
                      <span class="tw-rounded-md tw-shadow-sm">
                        <button
                          type="button"
                          :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-ring-blue tw-bg-gray-50 active:tw-bg-gray-50 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150', anyFilterSet ? 'tw-text-green-600' : '']"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          <div class="tw-flex tw-items-center">
                            <i class="fas fa-filter tw-mr-2" />
                            <div>
                              <div>Filters</div>
                            </div>
                            <i class="fas fa-chevron-down tw-ml-2" />
                          </div>
                        </button>
                      </span>
                    </span>
                  </template>
                  <template #items>
                    <div class="tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100">
                      <div class="tw-py-1">
                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('expected_month_completion_parsed', 'this_month') ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('expected_month_completion_parsed', filterEquals('expected_month_completion_parsed', 'this_month') ? null : 'this_month')">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Submit This Month
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('expected_month_completion_parsed', 'next_month') ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('expected_month_completion_parsed', filterEquals('expected_month_completion_parsed', 'next_month') ? null : 'next_month')">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Submit Next Month
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('accounts_submitted', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('accounts_submitted', filterEquals('accounts_submitted', true) ? null : true)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Accounts Submitted
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('accounts_not_submitted', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('accounts_not_submitted', filterEquals('accounts_not_submitted', true) ? null : true)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Accounts Not Submitted
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('year_end_passed', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('year_end_passed', filterEquals('year_end_passed', true) ? null : true)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Year end Passed
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('flagged', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('flagged', filterEquals('flagged', true) ? null : true)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Flagged
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('auth_agent_requested', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('auth_agent_requested', filterEquals('auth_agent_requested', false) ? null : false)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Auth Agent Not Requested
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('auth_agent_completed', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('auth_agent_completed', filterEquals('auth_agent_completed', false) ? null : false)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Auth Agent Not Completed
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('director_check_created', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('director_check_created', filterEquals('director_check_created', false) ? null : false)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Dir. Check Not Requested
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('director_check_completed', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('director_check_completed', filterEquals('director_check_completed', false) ? null : false)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Dir. Check Not Completed
                        </div>

                        <template v-for="dealCategory in dealCategories" :key="dealCategory">
                          <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('deal_category', dealCategory) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('deal_category', filterEquals('deal_category', dealCategory) ? null : dealCategory)">
                            <i class="fas fa-dot-circle tw-mr-2" />
                            {{ dealCategory }}
                          </div>
                        </template>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('not_onboarded', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('not_onboarded', filterEquals('not_onboarded', false) ? null : false)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Not Onboarded?
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('empowerrd_submitting', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('empowerrd_submitting', filterEquals('empowerrd_submitting', true) ? null : true)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          EmpowerRD Submitting
                        </div>

                        <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('empowerrd_submitting', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('empowerrd_submitting', filterEquals('empowerrd_submitting', false) ? null : false)">
                          <i class="fas fa-dot-circle tw-mr-2" />
                          Client Submitting
                        </div>
                      </div>
                    </div>
                  </template>
                </dropdown>
              </div>
              <!-- end of month submission drop down -->
              <FadeTransition>
                <a v-if="anyFilterSet" @click="resetFilters()">
                  <ButtonComponent
                    id="clear-filters"
                    text="Clear Filters"
                    colour="emp"
                    type="transparent"
                    icon="trash-alt"
                    icon-type="fas"
                    :hide-small="true"
                  />
                </a>
              </FadeTransition>
            </div>

            <div class="tw-w-50 tw-text-right">
              <input
                id="query"
                v-model="query"
                type="text"

                :class="[theme.forms.input, 'tw-px-2 tw-text-xs tw-w-64']"
                placeholder="Quick search claims below..."
                data-toggle="tooltip"
                data-title="Search to filter the active claims below"
              >
            </div>
          </nav>

          <div
            v-show="isLoading"
            id="loading"
            class="tw-w-full tw-text-center tw-my-4"
          >
            <PulsingLoader size="large" color="tw-bg-gray-200" />
          </div>

          <div v-show="!isLoading" v-cloak class="tw-mt-4">
            <claims-table :query="query" :claims="claimsToDisplay" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { documentCsrfToken } from "../../../../shared/http.js";
import { css } from "../../../../shared/theme.js";

import PulsingLoader from "../../../generic/loading_pulse_lines";
import ButtonComponent from "../../../generic/button_component.vue";
import FadeTransition from "../../transitions/fade.vue";

const datefns = require("date-fns");

export default {
  components: {
    PulsingLoader,
    ButtonComponent,
    FadeTransition
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator(value) {
        return value === "claim_manager" || value === "company";
      }
    },
    activeClaimManagerId: {
      type: Number,
      validator(value) {
        return value > 0;
      }
    },
    companyId: {
      type: Number,
      validator(value) {
        return value > 0;
      }
    },
    managers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      theme: css,
      tableHasAllData: false,
      isLoading: true,
      allFetched: false,
      query: "",
      claimsByFilter: [],
      filters: { emp_admin_id: null },
      claims: [],
      claimValues: {},
      nudges: { nudging_claim_period_ids: [] },
      thisMonthAsString: datefns.formatISO9075(datefns.startOfMonth(new Date()), { representation: "date" }),
      nextMonthAsString: datefns.formatISO9075(datefns.startOfMonth(datefns.add(new Date(), { months: 1 })), { representation: "date" }),
      currentDayAsEpoch: new Date().getTime() / 1000,
      dealCategories: ["BELOW MIN FEE", "SMALL/MIN FEE", "MEDIUM", "LARGE"]
    };
  },
  computed: {
    claimsBySelectedManager() {
      if (this.filters.emp_admin_id != null) {
        return this.claims.filter(claim => claim.emp_admin.id == this.filters.emp_admin_id);
      }
    },
    claimsToDisplay() {
      let dataToDisplay = this.claims;

      // Handle claim manager filter
      if (this.filters.emp_admin_id != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.emp_admin.id == this.filters.emp_admin_id);
      }

      // Handle submitting this month filter
      if (this.filters.expected_month_completion_parsed != null) {
        const filterForDate = this.filterValue("expected_month_completion_parsed") == "this_month" ? this.thisMonthAsString : this.nextMonthAsString;
        dataToDisplay = dataToDisplay.filter(claim => claim.expected_month_completion_parsed == filterForDate);
      }

      // Handle accounts submitted filter
      if (this.filters.accounts_submitted != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.accounts_submitted == this.filterValue("accounts_submitted"));
      }

      // Handle accounts not submitted filter
      if (this.filters.accounts_not_submitted != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.accounts_submitted == !this.filterValue("accounts_not_submitted"));
      }

      // Handle submitting this month filter
      if (this.filters.year_end_passed != null) {
        // check date vs today
        dataToDisplay = dataToDisplay.filter(claim => datefns.compareAsc(claim.end_date_as_epoch, this.currentDayAsEpoch) < 1);
      }

      // Handle Flagged
      if (this.filters.flagged != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.flagged == true);
      }

      // Handle Auth Agent Requested.  We only want to know when it is false
      if (this.filters.auth_agent_requested != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.company.auth_agent_requested == false);
      }

      // Handle Auth Agent Completed. We only want to know when it is false
      if (this.filters.auth_agent_completed != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.company.auth_agent_completed == false);
      }

      // Handle FALSEs for director check not created
      if (this.filters.director_check_created != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.company["identity_check_created?"] == false);
      }

      // Handle FALSEs for director check not captured
      if (this.filters.director_check_completed != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.company["identity_check_data_captured?"] == false);
      }

      // Deal Category
      if (this.filters.deal_category != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.deal_category == this.filters.deal_category);
      }

      // Filter if onboarded/not
      if (this.filters.not_onboarded != null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.onboarded == false);
      }

      // Filter if EmpowerRD is submitting or not
      if (this.filters.empowerrd_submitting !== undefined && this.filters.empowerrd_submitting !== null) {
        dataToDisplay = dataToDisplay.filter(claim => claim.finance_document.submit_by_emp === this.filters.empowerrd_submitting);
      }
      return dataToDisplay;
    },
    activeClaimManagerName() {
      if (this.managers.length > 0 && this.isFilterSet("emp_admin_id")) {
        return this.managers.filter(x => x.id == this.filters.emp_admin_id)[0].first_name;
      }
    },
    nudgeCountToDisplay() {
      if (this.claimsBySelectedManager != null && this.nudges.nudging_claim_period_ids != undefined) {
        const thisClaimManagerClaimIds = this.claimsBySelectedManager.map(x => x.id);
        return this.nudges.nudging_claim_period_ids.filter(x => thisClaimManagerClaimIds.indexOf(x) > -1).length;
      }
    },
    anyFilterSet() {
      return _.find(_.values(this.filters), x => x != null) != undefined;
    }
  },
  mounted() {
    if (this.activeClaimManagerId ?? "" !== "") {
      this.updateFilter("emp_admin_id", this.activeClaimManagerId);
    }
    this.getInitialData();
  },
  methods: {
    updateFilter(filter, value) {
      this.filters[filter] = value;
    },
    isFilterSet(filter) {
      return this.filters[filter] ?? "" !== "";
    },
    filterValue(filter) {
      return this.filters[filter];
    },
    filterEquals(filter, value) {
      return this.filterValue(filter) == value;
    },
    getInitialData() {
      let url = "/api/dashboard";

      if (this.mode == "company") {
        url += `?company_id=${this.companyId}`;
        this.allFetched = true;
      } else if (this.claims.length == 0 && this.isFilterSet("emp_admin_id")) {
        url += `?emp_admin_id=${this.activeClaimManagerId}`;
      } else {
        this.allFetched = true;
      }

      fetch(url)
          .then(response => response.json())
          .then(data => {
            this.claims = data;
            this.isLoading = false;
            if (this.allFetched) {
              const slugs = this.claims.map(x => x.slug);
              this.getClaimNudgeCount(slugs);
              this.tableHasAllData = true;
            } else {
              this.getInitialData();
            }
            setTimeout(window.enableTooltips, 500);
          });
    },
    getClaimNudgeCount(claimSlugs) {
      const formData = new FormData();
      formData.append("slugs", JSON.stringify(claimSlugs));

      fetch("/api/dashboard/nudges", {
        method: "POST",
        body: formData,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": documentCsrfToken()
        }
      })
          .then(response => response.json())
          .then(data => {
            this.nudges = data;
          });
    },
    resetFilters() {
      this.filters = {};
    }
  }
};
</script>
