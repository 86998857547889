<template>
  <Disclosure
    v-for="(section, idx) in accordionSections"
    :key="section.id"
    v-slot="{ open, close }"
  >
    <div :class="[wrappingClasses, open ? openClasses : closedClasses ]">
      <DisclosureButton
        :ref="(el) => (elements[idx] = {element: el, close: close, open: open})"
        :data-id="section.id"
        class="tw-w-full focus:tw-outline-none"
        @click="[lastClicked = section.id, checkAllClosed()]"
        @click.once="[$emit('firstClick', section), allClosed = false]"
      >
        <slot name="sectionTitleContent" :section="section" :open="open" />
      </DisclosureButton>
      <Transition
        enter-active-class="tw-transition tw-duration-300 tw-ease-out"
        enter-from-class="tw-transform tw-scale-y-95 tw-opacity-0 tw--translate-y-1/2"
        enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
        move-class="tw-transition tw-duration-300"
        leave-active-class="tw-transition tw-ease-out tw-duration-300"
        leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-to-class="tw-transform tw-opacity-0 tw-scale-y-95 tw--translate-y-1/2"
      >
        <DisclosurePanel>
          <slot name="sectionDropdownContent" :section="section" :open="open" />
        </DisclosurePanel>
      </Transition>
      <AccordionStateEmitter :show="open" @show="hideOther(section.id)" @hide="hide(section.id)" />
    </div>
  </Disclosure>
</template>

<script>
import { ref } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import AccordionStateEmitter from "./accordion_state_emitter.vue";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    AccordionStateEmitter
  },
  props: {
    accordionSections: {
      type: Array,
      required: true,
      validator(array) {
        // Must be an array of objects/hashes
        return typeof array[0] === "object" && array[0] !== null;
      }
    },
    wrappingClasses: {
      type: String,
      required: false
    },
    openClasses: {
      type: String,
      required: false
    },
    closedClasses: {
      type: String,
      required: false
    }
  },
  emits: ["firstClick", "allClosed", "reopened"],
  setup(props) {
    // add Ids to sections
    props.accordionSections.forEach((section, idx) => {
      section.id = idx + 1;
    });

    const elements = ref([]);
    const lastClicked = ref(1);
    const allClosed = ref(true);

    const hideOther = id => {
      const items = elements.value.filter(elm => elm.element.el.getAttribute("data-id") !== id.toString());

      items.forEach(elm => {
        if (elm.open) doClose(elm.close);
      });
    };

    const doClose = close => {
      close();
    };

    return { elements, lastClicked, allClosed, hideOther, doClose };
  },
  methods: {
    hide(id) {
      if (id === this.lastClicked) {
        this.allClosed = true;
        this.$emit("allClosed");
      }
    },
    checkAllClosed() {
      if (this.allClosed) this.$emit("reopened");
    }
  }
};
</script>
