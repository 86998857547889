<template>
  <span v-click-away="away">
    <span @click.stop="handleClick()">
      <slot name="link" />
    </span>

    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-100"
      enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition ease-in duration-75"
      leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
      :css="transition"
      @mouseleave="away"
    >
      <div v-show="show" v-cloak>
        <slot name="items" />
      </div>
    </transition>
  </span>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    transition: {
      type: Boolean,
      default: true,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props, context) {
    const show = ref(false);

    function away() {
      show.value = false;
    }

    function handleClick() {
      if (props.disabled) return;

      show.value = !show.value;
    }

    context.expose({ show, away });

    return { show, away, handleClick };
  }
};
</script>
