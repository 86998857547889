<template>
  <div class="tw-flex tw-gap-2 tw-items-center">
    <div class="tw-inline-block">
      <button-component
        id="save-changes"
        text="Save Changes"
        :colour="field.state >= editedFieldState ? 'confirm' : 'disabled'"
        size="small"
        @click="save(sectionType)"
      />
    </div>
    <transition v-if="!loading && dirty" name="fade" :duration="80">
      <div
        class="tw-inline-block tw-text-sm tw-h-full tw-pl-1.5"
      >
        <i class="fas fa-exclamation tw-mr-1 tw-text-red-600" />
        <span class="tw-text-sm tw-text-red-400">This question has unsaved changes</span>
      </div>
    </transition>
    <saving-spin-indicator :section-type="sectionType" :section-slug="sectionSlug" :fields="[field.label]" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { FIELD_STATE } from "../shared/field_state.js";
import { VALIDATE, GET_LOADING, SET_LOADING } from "../../../../vuex-store/modules/validations.js";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Grant",
          "Status",
          "FinanceDocument"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      loading: `validations/${GET_LOADING}`
    }),
    editedFieldState() {
      return FIELD_STATE.EDITED;
    },
    buttonColor() {
      return this.field.state >= FIELD_STATE.EDITED
        ? "tw-bg-green-600 hover:tw-bg-green-600"
        : "tw-bg-gray-400 hover:tw-bg-gray-500 tw-text-gray-200";
    },
    dirty() {
      return this.field.state >= FIELD_STATE.EDITED;
    }
  },
  methods: {
    ...mapActions({
      validate: `validations/${VALIDATE}`
    }),
    ...mapMutations({
      setLoading: `validations/${SET_LOADING}`
    }),
    save() {
      const fields = {};
      fields[this.field.label] = this.field.value;

      this.setLoading(true);
      this.validate([
        this.sectionType,
        this.sectionSlug,
        fields
      ]).then(data => {
        if (data) {
          this.field.state = FIELD_STATE.SAVED;
        }
      });
    }
  }
};
</script>
