<template>
  <transition name="slide-fade" mode="out-in">
    <slot />
  </transition>
</template>

<script>
export default {};
</script>

<style scoped>
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>
