<template>
  <div class="tw-inline-flex tw-items-center tw-gap-x-3">
    <div class="tw-text-base tw-font-semibold">
      <span :class="[currentCompletion > total ? 'tw-text-error-red' : '']">{{ formattedValue(currentCompletion) }}</span>
      of {{ formattedValue(total) }}
    </div>
    <div v-if="isTotalAllAllocated">
      <i class="fas fa-lg fa-check-circle tw-text-confirm-green" />
    </div>
    <div v-else class="tw-text-sm tw-text-error-red">
      {{ differenceWithTotal }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "TotalCompletion",
  components: {
  },
  props: {
    values: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: false,
      default: 100
    },
    type: {
      type: String,
      required: false,
      default: "percentage",
      validator: value => {
        const validTypes = ["percentage", "currency", "number"];
        return validTypes.includes(value.toLowerCase());
      }
    }
  },
  setup(props) {
    const currentCompletion = computed(() => {
      let sum = 0;

      props.values.forEach(number => {
        sum += number;
      });

      return sum;
    });

    const isTotalAllAllocated = computed(() => currentCompletion.value === props.total);

    const differenceWithTotal = computed(() => {
      const difference = props.total - currentCompletion.value;

      if (difference === 0) { return; }

      const comparativeWord = difference > 0 ? "left" : "over";

      return `${formattedValue(difference)} ${comparativeWord}`;
    });

    function formattedValue(number) {
      const absoluteNumber = Math.abs(number);

      if (props.type === "percentage") {
        return `${absoluteNumber}%`;
      } if (props.type === "currency") {
        return `£${absoluteNumber}`;
      }

      return absoluteNumber;
    }

    return {
      currentCompletion,
      differenceWithTotal,
      isTotalAllAllocated,
      formattedValue
    };
  }
};
</script>
