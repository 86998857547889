<template>
  <BarChart :chart-data="analyticsData" :options="options" />
</template>

<script>
import { BarChart } from "vue-chart-3";

export default {
  name: "RD7Chart",
  components: { BarChart },
  props: {
    analyticsData: {
      required: true,
      type: Object
    },
    title: {
      required: false,
      type: String
    },
    xTitle: {
      required: false,
      type: String
    },
    yTitle: {
      required: false,
      type: String
    },
    legend: {
      required: false,
      type: Boolean
    },
    currency: {
      required: false,
      type: Boolean
    },
    percentage: {
      required: false,
      type: Boolean
    },
    noTooltips: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Claim Size Band",
              font: { size: 16 }
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "% of Claims ",
              font: { size: 16 }
            },
            ticks: { callback: this.benchmarkingTicks }
          }
        },
        plugins: {
          legend: {
            position: "bottom",
            display: false
          },
          title: {
            display: true,
            text: "SME Scheme Claim Sizes",
            font: { size: 18 }
          },
          tooltip: {
            callbacks: {
              label: this.benchmarkingPointlabel,
              afterLabel: this.benchmarkingPointFooter
            }
          }
        }

      }
    };
  },
  mounted() {
    this.options.plugins.title.text = this.title;
    // if (this.legend === false) {
    //   this.options.plugins.legend.display = false;
    // }
    // if (this.currency) {
    //   this.options.scales.y.ticks = {
    //     callback: function(value, index, values) {
    //       if (parseInt(value) >= 1000) {
    //         return '£' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //       } else {
    //         return '£' + value;
    //       }
    //     }
    //   }
    // }
    // if (this.percentage) {
    //   this.options.scales.y.ticks = {
    //     callback: function(value, index, values) {
    //       return value + '%';
    //     }
    //   }
    // }
    if (this.xTitle) {
      this.options.scales.x.title.text = this.xTitle;
    }
    if (this.yTitle) {
      this.options.scales.y.title.text = this.yTitle;
    }
    // if (this.noTooltips) {
    //   this.options.plugins.tooltip.enabled = false
    // }
  },
  methods: {
    benchmarkingTicks(value, index, values) {
      return `${Math.floor(value * 100)}%`;
    },
    benchmarkingPointlabel(tooltipItems) {
      return `${Math.round(tooltipItems.formattedValue * 100)}% of claims`;
    },
    benchmarkingPointFooter(tooltipItems) {
      return tooltipItems.raw.matched === true ? "Your Claim falls in this band" : "";
    }
  }
};
</script>
