import { SECTION_STATUS } from "../../shared/section_status.js";
import { get } from "../../shared/http.js";

export const FETCH_STATES_FOR_SECTION = "FETCH_STATES_FOR_SECTION";
export const FETCH_ALL_CLAIM_SECTION_STATES = "FETCH_ALL_CLAIM_SECTION_STATES";
export const FETCH_SIDEBAR_DATA = "FETCH_SIDEBAR_DATA";

export const SET_EDIT_STATES = "SET_EDIT_STATES";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const SET_STATE_MESSAGE = "SET_STATE_MESSAGE";
export const SET_LOADING = "SET_LOADING";
export const SET_SECTION_STATUS = "SET_SECTION_STATUS";

export const GET_STATES = "GET_STATES";
export const GET_EDIT_STATE = "GET_EDIT_STATE";
export const GET_STATE_MESSAGE = "GET_STATE_MESSAGE";
export const GET_FEEDBACK_VISIBLE = "GET_FEEDBACK_VISIBLE";
export const GET_USER_TYPE = "GET_USER_TYPE";
export const GET_SUBMITTED = "GET_SUBMITTED";
export const GET_LOADING = "GET_LOADING";
export const GET_SECTION_STATUS = "GET_SECTION_STATUS";
export const GET_ENABLED_FEATURE_FLAGS = "GET_ENABLED_FEATURE_FLAGS";
export const GET_FLAG_ENABLED = "GET_FLAG_ENABLED";
export const GET_ALL_CLAIM_SECTION_STATES = "GET_ALL_CLAIM_SECTION_STATES";
export const GET_CLAIM_SECTION_STATUS = "GET_CLAIM_SECTION_STATUS";
export const GET_SIDEBAR_CLAIM_DATA = "GET_SIDEBAR_CLAIM_DATA";
export const GET_SIDEBAR_CLAIM_PERIODS = "GET_SIDEBAR_CLAIM_PERIODS";
export const GET_CLAIM_PERIOD = "GET_CLAIM_PERIOD";

export const states = {
  namespaced: true,
  state: {
    states: null,
    claimSectionStates: null,
    userType: null,
    stateMessage: null,
    enabledFeatureFlags: [],
    submitted: null,
    loading: false,
    sidebarClaimData: null,
    sidebarClaimPeriods: null
  },
  mutations: {
    setLoading: (state, payload) => state.loading = payload,
    setUserType: (state, payload) => state.userType = payload,
    setStates: (state, payload) => state.states = payload,
    setStateMessage: (state, payload) => state.stateMessage = payload,
    setEnabledFeatureFlags: (state, payload) => state.enabledFeatureFlags = payload,
    [SET_SECTION_STATUS]: (state, payload) => state.states.sectionStatus = payload,
    setClaimSectionStates: (state, payload) => state.claimSectionStates = payload,
    setSidebarClaimData: (state, payload) => state.sidebarClaimData = payload,
    setSidebarClaimPeriods: (state, payload) => state.sidebarClaimPeriods = payload
  },
  actions: {
    [FETCH_STATES_FOR_SECTION]: async ({ getters, commit, state }, payload) => {
      if (state.states === null) {
        commit("setLoading", true);
      }

      const response = await get(`/api/states/fetch_section_states/${payload.sectionType}/${payload.sectionSlug}${payload?.rd ? "/?rd=true" : ""}`);

      commit("setUserType", response.userType);
      commit("setStateMessage", response.stateMessage);
      commit("setStates", response.states);
      commit("setEnabledFeatureFlags", response.enabledFeatureFlags);
      commit("setLoading", false);

      return response;
    },
    [FETCH_ALL_CLAIM_SECTION_STATES]: async ({ commit, state }, payload) => {
      if (!payload.claimPeriodSlug) {
        return;
      }
      const response = await get(`/api/states/fetch_all_claim_sections_states/${payload.claimPeriodSlug}`);

      commit("setClaimSectionStates", response);

      return response;
    },
    [FETCH_SIDEBAR_DATA]: async ({ commit, state }, payload) => {
      if (!payload.claimPeriodSlug) {
        return;
      }
      const response = await get(`/api/claim_sidebar/fetch_data/${payload.claimPeriodSlug}/?page_string=${payload.pageString}`);

      commit("setSidebarClaimData", response.claim_data);
      commit("setSidebarClaimPeriods", response.claim_periods);

      return response;
    }
  },
  getters: {
    [GET_LOADING]: state => state.loading,
    [GET_STATES]: state => state.states,
    [GET_ALL_CLAIM_SECTION_STATES]: state => state.claimSectionStates,
    [GET_USER_TYPE]: state => state.userType,
    [GET_EDIT_STATE]: state => state.states?.editable,
    [GET_SECTION_STATUS]: state => state.states?.sectionStatus,
    [GET_FEEDBACK_VISIBLE]: state => state.states?.feedbackVisible,
    [GET_STATE_MESSAGE]: state => state.stateMessage,
    [GET_SUBMITTED]: state => state.states?.sectionStatus === SECTION_STATUS.CLAIM_SUBMITTED,
    [GET_ENABLED_FEATURE_FLAGS]: state => state.enabledFeatureFlags,
    [GET_FLAG_ENABLED]: state => flag => state.enabledFeatureFlags.includes(flag),
    [GET_CLAIM_SECTION_STATUS]: state => (section_label, slug = null) => {
      if (section_label === "projects") {
        return state.claimSectionStates?.[section_label]?.[`Project:${slug}`]?.status;
      } else {
        return state.claimSectionStates?.[section_label]?.status;
      }
    },
    [GET_SIDEBAR_CLAIM_DATA]: state => state.sidebarClaimData,
    [GET_SIDEBAR_CLAIM_PERIODS]: state => state.sidebarClaimPeriods,
    [GET_CLAIM_PERIOD]: state => state.sidebarClaimData?.claim_period
  }
};
