<template>
  <div>
    <div class="tw-flex tw-items-center tw-gap-x-2">
      <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-3/4">
        <div>
          <!-- this should be an autocomplete -->
          <input
            v-model="query"
            type="text"
            :class="[theme.forms.input, 'tw-px-2 tw-text-xs']"
            placeholder="Start typing..."
            @focus="queryFocussed = true"
            @blur="debounceLoseFocus()"
            @keyup="debounceSearch()"
            @keydown.escape="cancelSearch()"
          >

          <transition
            enter-active-class="tw-transition tw-ease-out tw-duration-100"
            enter-from-class="tw-transform tw-opacity-0"
            enter-to-class="tw-transform tw-opacity-100 "
            leave-active-class="tw-transition duration-75"
            leave-from-class="tw-transform tw-opacity-100 "
            leave-to-class="tw-transform tw-opacity-0"
          >
            <div v-show="queryFocussed && resultCount() > 0" v-cloak>
              <div
                id="results"
                class="
                      tw-origin-top-left
                      tw-absolute
                      tw-left-0
                      tw-w-96
                      tw-rounded-md
                      tw-shadow-lg
                      tw-bg-white
                      tw-z-50
                      tw-p-2
                    "
              >
                <template
                  v-for="[idx, result] in Object.entries(results)"
                  :key="idx"
                >
                  <div :class="[theme.dropDown.itemDiv]" @click="assignToTaxonomy(result.id)">
                    <span v-text="result.level_1" /> > <span v-text="result.level_2" />
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div>
        X
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { css } from "../../../shared/theme.js";
import { post } from "../../../shared/http.js";

export default {
  components: {

  },
  props: {
    modelString: {
      type: String,
      required: true
    },
    rdCol1: {
      type: String,
      required: false
    },
    rdCol2: {
      type: String,
      required: false
    },
    taxonomies: {
      type: Array,
      required: true
    }

  },
  data() {
    return {
      theme: css,
      query: "",
      taxonomyId: null,
      results: [],
      queryFocussed: false,
      hasBeenSet: false
    };
  },
  created() {
    this.debounceSearch = _.debounce(this.search, 500);
    this.debounceLoseFocus = _.debounce(this.blur, 100);
  },
  unmounted() {
    this.debounceSearch.cancel();
  },
  methods: {
    resultCount() {
      return (this.results).length;
    },
    assignToTaxonomy(taxonomyId) {
      const url = `/api/cost_taxonomies/create_taxonomy_match/${this.modelString}`;
      post(url, { rd_col_1: this.rdCol1, rd_col_2: this.rdCol2, cost_taxonomy_id: taxonomyId })
        .then(response => response.json())
        .then(data => {
          this.hasBeenSet = true;
        });
    },
    blur() {
      this.queryFocussed = false;
    },
    search() {
      this.results = _.filter(this.taxonomies, t => this.searchMatches(this.query, t));
    },
    searchMatches(query, taxonomy) {
      return (taxonomy.level_1.toLowerCase().indexOf(this.query) > -1 || taxonomy.level_2.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
    },
    cancelSearch() {
      this.query = "";
      this.results = {};
      this.debounceSearch.cancel();
    }
  }
};
</script>
