<template>
  <div class="tw-justify-end tw-w-full tw-text-right tw-items-end tw-flex">
    <input
      id="globalQuery"
      v-model="query"
      type="text"
      :class="[theme.forms.input, 'tw-px-2 tw-text-xs tw-w-64']"
      placeholder="Global search..."
      data-toggle="tooltip"
      data-title="Start typing to search all companies/claims (min. 2 characters)"
      @focus="queryFocussed = true"
      @blur="debounceLoseFocus()"
      @keyup="debounceSearch()"
      @keydown.escape="cancelSearch()"
    >
    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-100"
      enter-from-class="tw-transform tw-opacity-0"
      enter-to-class="tw-transform tw-opacity-100 "
      leave-active-class="tw-transition duration-75"
      leave-from-class="tw-transform tw-opacity-100 "
      leave-to-class="tw-transform tw-opacity-0"
    >
      <div v-show="queryFocussed && resultCount() > 0" v-cloak>
        <div
          id="results"
          class="
            tw-origin-top-right
            tw-absolute
            tw-right-0
            tw-w-64
            tw-rounded-md
            tw-shadow-lg
            tw-bg-white
            tw-z-50
            tw-p-2
          "
        >
          <div v-if="results.companies !== undefined">
            <template
              v-for="[idx, company] in Object.entries(results.companies)"
              :key="idx"
            >
              <a :href="`/companies/${company.slug}`" target="_blank">
                <div
                  class="
                    tw-flex
                    tw-items-center
                    tw-px-1
                    tw-truncate
                    tw-text-xs
                    tw-py-1
                    tw-leading-5
                    tw-text-gray-700
                    hover:tw-bg-gray-100 hover:tw-text-gray-900
                    focus:tw-outline-none
                    focus:tw-bg-gray-100
                    focus:tw-text-gray-900
                  "
                >
                  <i class="fas fa-building fa-1x tw-text-gray-500 tw-mr-2" />
                  <span v-text="company.name" />
                </div>
              </a>
            </template>
          </div>

          <div v-if="results.claim_periods !== undefined">
            <template
              v-for="[idx, cp] in Object.entries(results.claim_periods)"
              :key="idx"
            >
              <a :href="`/claim_periods/${cp.slug}`" target="_blank">
                <div
                  class="
                    tw-flex
                    tw-items-center
                    tw-px-1
                    tw-truncate
                    tw-text-xs
                    tw-py-1
                    tw-leading-5
                    tw-text-gray-700
                    hover:tw-bg-gray-100 hover:tw-text-gray-900
                    focus:tw-outline-none
                    focus:tw-bg-gray-100
                    focus:tw-text-gray-900
                  "
                >
                  <i class="fas fa-pound-sign fa-1x tw-text-gray-500 tw-mr-2" />
                  <span v-text="cp.name" />
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { css } from "../../../../shared/theme.js";

export default {
  data() {
    return {
      theme: css,
      query: "",
      results: {},
      queryFocussed: false
    };
  },
  created() {
    this.debounceSearch = _.debounce(this.search, 500);
    this.debounceLoseFocus = _.debounce(this.blur, 100);
  },
  unmounted() {
    this.debounceSearch.cancel();
  },
  methods: {
    resultCount() {
      return (
        Object.keys(this.results.companies || []).length
        + Object.keys(this.results.claim_periods || []).length
      );
    },
    blur() {
      this.queryFocussed = false;
    },
    search() {
      if (this.query.length < 2) {
        this.results = {};
        return;
      }

      fetch(`/api/dashboard/search/${this.query}`)
        .then(response => response.json())
        .then(data => {
          this.results = data.search_results || {};
        });
    },
    cancelSearch() {
      this.query = "";
      this.results = {};
      this.debounceSearch.cancel();
    }
  }
};
</script>
