<template>
  <div>
    <h1 class="tw-text-2xl tw-mb-2">
      Submissions
    </h1>

    <div class="tw-my-5">
      <TabGroup manual>
        <TabList style="margin-bottom: -1px;">
          <SimpleTab text="Active" />
          <SimpleTab text="Submitted" />
        </TabList>
        <TabPanels class="bg-white tw-p-3 tw-border tw-border-disable-grey-250">
          <TabPanel>
            <SubmissionsTable
              :submissions="activeSubmissions.submissions"
              :error-message="activeSubmissions.errorMessage"
              :is-loading="activeSubmissions.isLoading"
            />
          </TabPanel>
          <TabPanel>
            <SubmissionsTable
              :submissions="submittedSubmissions.submissions"
              :error-message="submittedSubmissions.errorMessage"
              :is-loading="submittedSubmissions.isLoading"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { get } from "@shared/http.js";
import { HttpErrorResponse } from "@shared/interfaces";
import SimpleTab from "@generic/simple_tab.vue";
import { TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
import SubmissionsTable from "./submissions_table.vue";
import { Submission } from "./interfaces";

interface submissionRef {
  submissions: Array<Submission>;
  errorMessage: string;
  isLoading: boolean;
}

const props = defineProps({
  userSlug: String
});

const activeSubmissions = ref<submissionRef>({ submissions: [], errorMessage: "", isLoading: true });
const submittedSubmissions = ref<submissionRef>({ submissions: [], errorMessage: "", isLoading: true });

getSubmissions();

async function getSubmissions() {
  const appendedUserSlug = props.userSlug ? `&submitter_id=${props.userSlug}` : "";

  get(`/api/v1/submissions?status=active${appendedUserSlug}`).then(res => setSubmissionData(activeSubmissions.value, res));
  get(`/api/v1/submissions?status=submitted${appendedUserSlug}`).then(res => setSubmissionData(submittedSubmissions.value, res));
}

function setSubmissionData(submissionType: submissionRef, res: Submission[] | HttpErrorResponse) {
  submissionType.isLoading = false;

  if ("error" in res) {
    submissionType.errorMessage = "There was an error fetching the submissions.";
    return;
  }

  return submissionType.submissions = res;
}

</script>
