import { get, patch } from "@shared/http.js";

// mutations
export const SET_EXTERNAL_WORKERS = "SET_EXTERNAL_WORKERS";
export const SET_EXTERNAL_WORKERS_SECTION = "SET_EXTERNAL_WORKERS_SECTION";
export const SET_EXTERNAL_WORKER = "SET_EXTERNAL_WORKER";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_PAGE_ERROR_MESSAGE = "SET_PAGE_ERROR_MESSAGE";
export const SET_DATA_FETCH_ERROR_MESSAGE = "SET_DATA_FETCH_ERROR_MESSAGE";
export const SET_COMPLETED_AT = "SET_COMPLETED_AT";

// actions
export const FETCH_EXTERNAL_WORKERS = "FETCH_EXTERNAL_WORKERS";
export const UPDATE_EXTERNAL_WORKER = "UPDATE_EXTERNAL_WORKER";
export const COMPLETE_EXTERNAL_WORKERS_SECTION = "COMPLETE_EXTERNAL_WORKERS_SECTION";
export const SAVE_EPW_SUBCOST = "SAVE_EPW_SUBCOST";

// getters
export const GET_EXTERNAL_WORKERS = "GET_EXTERNAL_WORKERS";
export const GET_EXTERNAL_WORKERS_SECTION = "GET_EXTERNAL_WORKERS_SECTION";
export const GET_IS_LOADING = "GET_IS_LOADING";
export const GET_DATA_FETCH_ERROR_MESSAGE = "GET_DATA_FETCH_ERROR_MESSAGE";
export const GET_PAGE_ERROR_MESSAGE = "GET_PAGE_ERROR_MESSAGE";

export const external_workers = {
  namespaced: true,
  state: {
    isLoading: true,
    external_workers: [],
    external_workers_section: {},
    dataFetchErrorMessage: "",
    pageErrorMessage: null
  },
  mutations: {
    [SET_EXTERNAL_WORKERS]: (state, payload) => (state.external_workers = payload),
    [SET_EXTERNAL_WORKERS_SECTION]: (state, payload) => state.external_workers_section = payload,
    [SET_EXTERNAL_WORKER]: (state, payload) => {
      const { slug, field, value } = payload;
      const externalWorkerToUpdate = state.external_workers.find(worker => worker.slug === slug);

      externalWorkerToUpdate[field] = value;
    },
    [SET_IS_LOADING]: (state, payload) => (state.isLoading = payload),
    [SET_DATA_FETCH_ERROR_MESSAGE]: (state, payload) => (state.dataFetchErrorMessage = payload),
    [SET_PAGE_ERROR_MESSAGE]: (state, payload) => (state.pageErrorMessage = payload),
    [SET_COMPLETED_AT]: (state, payload) => state.external_workers_section.completed_at = payload
  },
  actions: {
    [FETCH_EXTERNAL_WORKERS]: async ({ commit }, payload) => {
      const { externalWorkersSectionSlug } = payload;

      const api = `/api/v1/external_workers_section/${externalWorkersSectionSlug}`;
      const response = await get(api);

      if (response.error) {
        commit(SET_DATA_FETCH_ERROR_MESSAGE, "There was a problem fetching your EPW cost data. Please try again.");
      } else {
        commit(SET_EXTERNAL_WORKERS, response.costs_epws_needing_paye_reference);
        commit(SET_EXTERNAL_WORKERS_SECTION, response)
      }

      commit(SET_IS_LOADING, false);
    },
    COMPLETE_EXTERNAL_WORKERS_SECTION: async ({ state, commit }) => {
      const api = `/api/v1/external_workers_section/${state.external_workers_section.slug}`;

      const response = await patch(api, { completed: true });

      if (response.status === 400) {
        commit(SET_PAGE_ERROR_MESSAGE, response.data.error);
      } else if (response.data?.error_messages) {
        commit(SET_PAGE_ERROR_MESSAGE, Object.values(response.data.error_messages)[0]);
      } else {
        commit(SET_PAGE_ERROR_MESSAGE, null);

        commit(SET_COMPLETED_AT, response.completed_at);
      }
    },
    [UPDATE_EXTERNAL_WORKER]: async ({ commit, dispatch }, payload) => {
      const { slug, field, newValue } = payload;

      commit(SET_EXTERNAL_WORKER, { slug, field, value: newValue });

      const res = await dispatch(SAVE_EPW_SUBCOST, payload);

      return res;
    },
    [SAVE_EPW_SUBCOST]: async ({ state }, payload) => {
      const { slug, field } = payload;

      const api = `/api/v1/epw_subcosts/${slug}`;

      const value = state.external_workers.find(worker => worker.slug === slug)[field];

      const res = await patch(api, { [field]: value });

      return res;
    }
  },
  getters: {
    [GET_EXTERNAL_WORKERS]: state => state.external_workers,
    [GET_EXTERNAL_WORKERS_SECTION]: state => state.external_workers_section,
    [GET_IS_LOADING]: state => state.isLoading,
    [GET_DATA_FETCH_ERROR_MESSAGE]: state => state.dataFetchErrorMessage,
    [GET_PAGE_ERROR_MESSAGE]: state => state.pageErrorMessage
  }
};
