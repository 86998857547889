<template>
  <tooltip v-if="section || sectionComplete" class="tw-flex tw-items-center" :content="statusTooltip">
    <i :class="statusIconClasses" class="tw-w-4 tw-flex-shrink-0 tw-text-sm tw-flex-none tw-text-center" aria-hidden="true" />
  </tooltip>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { SECTION_STATUS } from "../../../shared/section_status.js";

export default {
  props: {
    section: {
      type: Object,
      required: false
    },
    sectionLabel: {
      type: String,
      required: false
    },
    checkUnresolvedFeedback: {
      type: Boolean,
      required: false
    },
    rd: {
      type: Boolean,
      required: false
    },
    sectionComplete: {
      type: Boolean,
      required: false
    },
    completeTooltip: {
      type: String,
      required: false
    },
    currentPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const store = useStore();
    const sectionStatus = computed(() => store.getters["states/GET_CLAIM_SECTION_STATUS"](props.sectionLabel, props.section?.slug));
    const isSectionComplete = computed(() => props.sectionComplete || SECTION_STATUS.SECTION_COMPLETE <= sectionStatus.value);
    const anyUnresolvedFeedback = computed(() => SECTION_STATUS.UNRESOLVED_FEEDBACK === sectionStatus.value);
    const sectionInReview = computed(() => [SECTION_STATUS.FINALISED, SECTION_STATUS.IN_REVIEW].includes(sectionStatus.value));

    const statusIconClasses = computed(() => {
      if (anyUnresolvedFeedback.value) {
        return `far fa-comment-alt-lines ${props.currentPage ? "tw-text-yellow-400 group-hover:tw-text-yellow-500" : "tw-text-yellow-300 group-hover:tw-text-yellow-400"}`;
      } else if (isSectionComplete.value) {
        return `fas fa-check-circle ${props.currentPage ? "tw-text-green-400 group-hover:tw-text-green-500" : "tw-text-green-500 group-hover:tw-text-green-600"}`;
      } else if (sectionInReview.value) {
        return "fas fa-hourglass-start tw-text-gray-400 group-hover:tw-text-gray-300";
      } else {
        return "";
      }
    });

    const statusTooltip = computed(() => {
      if (anyUnresolvedFeedback.value) {
        return props.section?.title?.length > 25 ? `${(`This ${props.section?.className?.toLowerCase()}`) || props.section.title} has unresolved feedback` : `${props.rd ? "Your costs have" : `${props.section?.title || "This section"} has`} unresolved feedback`;
      } else if (isSectionComplete.value && props.completeTooltip) {
        return props.completeTooltip;
      } else if (isSectionComplete.value && props.section) {
        return props.section.title?.length > 25 ? `This ${props.section?.className?.toLowerCase() || props.section.title} is complete` : `${props.rd ? "Time spent on R&D is" : `${props.section.title} is`} complete`;
      } else if (sectionInReview.value) {
        return `This ${props.completeTooltip ? "section" : props.section?.className?.toLowerCase()} is being reviewed by our claim team`;
      } else {
        return "";
      }
    });

    return {
      statusIconClasses,
      statusTooltip,
      anyUnresolvedFeedback,
      sectionStatus,
      isSectionComplete,
      sectionInReview
    };
  }
};
</script>
