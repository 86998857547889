<template>
  <div
    :class="[
      'field-group tw-bg-white sm:tw-rounded-lg sm:tw-shadow tw-py-4',
      fieldsHaveFeedback ? 'tw-border-amber-300 tw-border-2' : '',
    ]"
  >
    <div
      class="tw-flex tw-justify-between tw-items-stretch tw-gap-2 tw-pl-3.5 tw-pr-5 tw-py-1"
      :class="expanded && $refs.field_group_blurb && $refs.field_group_blurb.show ? 'tw-mb-2' : ''"
    >
      <div
        class="tw-flex tw-items-center tw-cursor-pointer"
        :aria-label="`Expand ${groupName} section`"
        @click="expanded = !expanded"
      >
        <div class="tw-mx-2 tw-w-6">
          <i :class="['fas fa-chevron-circle-right fa-lg tw-duration-300', expanded ? 'tw-transform tw-rotate-90' : '']" />
        </div>

        <div class="tw-block">
          <div class="tw-flex tw-gap-2 tw-items-center tw-justify-start">
            <h3 class="tw-text-lg xl:tw-text-xl tw-font-medium tw-text-gray-900 tw-mb-0 2xl:tw-flex-none">
              Step {{ groupIndex + 1 }}: {{ groupName }}
            </h3>
            <KnowledgeCenterTriggerIcon
              v-if="fields[0].field_group_knowledge_centre"
              :question="fields[0].field_group_key"
              :section="section.type"
              icon
            />
            <state-wrapper component="CompleteInfo">
              <span class="tw-hidden sm:tw-block tw-flex-none">
                <CompleteInfo
                  :fields="fields"
                  :section-type="section.type"
                  :section-slug="section.slug"
                />
              </span>
            </state-wrapper>
            <div v-if="fieldsHaveFeedback" class="tw-cursor-auto tw-ml-1">
              <Tippy
                content="There are questions with unresolved feedback in this section"
                delay="200"
              >
                <div class="tw-flex">
                  <span>
                    <i class="far fa-comment-alt-lines fa-1x tw-text-warning-yellow tw-mt-1.5" />
                  </span>
                  <div class="tw-relative tw-inline-flex">
                    <span class="tw-flex tw-h-3 tw-w-3 tw--ml-1.5">
                      <span class="tw-animate-ping tw-absolute tw-inline-flex tw-h-3 tw-w-3 tw-rounded-full tw-bg-warning-yellow tw-opacity-75" />
                      <span class="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-warning-yellow" />
                    </span>
                  </div>
                </div>
              </Tippy>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tw-flex-shrink-0 tw-mx-1 tw-flex tw-items-end tw-flex-col tw-gap-y-1"
      >
        <state-wrapper component="AssignIcon">
          <AssignIcon
            :fields="fields"
            :section-type="section.type"
            :section-slug="section.slug"
            assign-icon-type="FieldGroupAssign"
          />
        </state-wrapper>
        <state-wrapper component="CompleteIcon">
          <CompleteIcon
            :fields="fields"
            :section-type="section.type"
            :section-slug="section.slug"
            :group-index="groupIndex"
            complete-icon-type="FieldGroupComplete"
          />
        </state-wrapper>
      </div>
    </div>
    <state-wrapper ref="field_group_blurb" component="field_group_blurb">
      <div class="tw-px-6">
        <transition
          enter-active-class="tw-transition tw-ease-out tw-duration-100"
          enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
          enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-active-class="tw-transition ease-in duration-75"
          leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
        >
          <p
            v-show="expanded && fields[0].field_group_blurb"
            class="tw-text-xs tw-text-gray-500"
            v-html="fields[0].field_group_blurb"
          />
        </transition>
        <transition
          enter-active-class="tw-transition tw-ease-out tw-duration-100"
          enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
          enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-active-class="tw-transition ease-in duration-75"
          leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
          leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
        >
          <p
            v-show="expanded && fields[0].field_group_warning_blurb"
            class="tw-text-xs tw-text-warning-yellow tw-font-medium tw-italic"
            v-html="fields[0].field_group_warning_blurb"
          />
        </transition>
      </div>
    </state-wrapper>

    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-100"
      enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition ease-in duration-75"
      leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
      <div v-show="expanded" v-cloak>
        <hr class="tw-my-3">
        <div class="tw-flex tw-flex-col tw-gap-y-5">
          <template v-for="(field, idx) in orderedFields" :key="idx">
            <div
              :id="`question_${field.label}`"
              :class="[
                'tw-transition-bg tw-duration-300 tw-ease-out tw-delay-75',
                fieldBgColour(field)
              ]"
            >
              <span v-if="field.type === 'Paired'">
                <PairedField
                  :field="fieldProxy(field)"
                  :section-slug="section.slug"
                  :section-type="section.type"
                  :group-index="groupIndex"
                />
              </span>
              <span v-else-if="field.type === 'RadioButtonRevealField'" class="tw-flex tw-flex-col tw-gap-y-5">
                <RadioButtonRevealField
                  :field="fieldProxy(field)"
                  :section-slug="section.slug"
                  :section-type="section.type"
                  :group-index="groupIndex"
                />
              </span>
              <span v-else>
                <Field
                  :field="fieldProxy(field)"
                  :section-slug="section.slug"
                  :section-type="section.type"
                  :group-index="groupIndex"
                />
              </span>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import { mapGetters } from "vuex";
import { Tippy } from "vue-tippy";
import KnowledgeCenterTriggerIcon from "@generic/knowledge_centre_trigger_icon.vue";
import useEventsBus from "../../../shared/eventBus.js";

import { featureEnabled, flattenFields } from "../../../shared/fields.js";

import { FIELD_STATE } from "./shared/field_state.js";
import { SECTION_STATUS } from "../../../shared/section_status.js";
import { GET_COMPLETED_ASSIGNMENTS } from "../../../vuex-store/modules/assignments.js";
import { GET_FEEDBACKS_FOR_FIELD } from "../../../vuex-store/modules/feedbacks.js";
import { GET_USER_TYPE, GET_SECTION_STATUS } from "../../../vuex-store/modules/states.js";
import { GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP } from "../../../vuex-store/modules/audit_history.js";
import { GET_LAST_REVIEW_COMPLETED_FOR_SECTION } from "../../../vuex-store/modules/reviews.js";
import { GET_NEW_PROJECT } from "../../../vuex-store/modules/sections.js";
import { GET_HELPHERO_TOUR_STARTED } from "../../../vuex-store/modules/users.js";

import Field from "./field.vue";
import PairedField from "./paired_field.vue";
import AssignIcon from "./assign_icon.vue";
import CompleteIcon from "./complete_icon.vue";
import CompleteInfo from "./complete_info.vue";
import RadioButtonRevealField from "./radio_button_reveal_field.vue";

export default {
  components: {
    AssignIcon,
    CompleteIcon,
    CompleteInfo,
    Field,
    PairedField,
    RadioButtonRevealField,
    Tippy,
    KnowledgeCenterTriggerIcon
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    groupName: {
      type: String,
      default: ""
    },
    groupIndex: {
      type: Number
    },
    fields: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      expanded: false,
      hasBeenClosed: false,
      isCompleted: false
    };
  },
  computed: {
    orderedFields() {
      return _.chain(this.fields).orderBy("order").value();
    },
    ...mapGetters({
      completedAssignments: `assignments/${GET_COMPLETED_ASSIGNMENTS}`,
      userType: `states/${GET_USER_TYPE}`,
      getSectionStatus: `states/${GET_SECTION_STATUS}`,
      auditsSinceReview: `audit_history/${GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP}`,
      lastReviewCompleted: `reviews/${GET_LAST_REVIEW_COMPLETED_FOR_SECTION}`,
      helpheroTourStarted: `users/${GET_HELPHERO_TOUR_STARTED}`,
      newProject: `sections/${GET_NEW_PROJECT}`
    }),
    fieldsHaveFeedback() {
      if (featureEnabled(this.options, "feature_integrated_feedback") === false) {
        return false;
      }

      const feedbacks = _.chain(this.fields)
        .flatMap(field => {
          const feedback = this.$store.getters[
            `feedbacks/${GET_FEEDBACKS_FOR_FIELD}`
          ](this.section.type, this.section.slug, field.label);

          return feedback !== undefined && feedback !== null;
        })
        .compact()
        .value();

      return feedbacks.includes(true);
    },
    inProgress() {
      return this.getSectionStatus <= SECTION_STATUS.IN_PROGRESS;
    },
    fieldsChangedSinceLastReview() {
      return _.chain(this.auditsSinceReview(this.section.type, this.section.slug, this.lastReviewCompleted?.completed_at))
              .map(f => f.col_ref)
              .value();
    }
  },
  mounted() {
    this.unwatch = this.$store.watch(
      (_state, getters) => getters[`assignments/${GET_COMPLETED_ASSIGNMENTS}`],
      newValue => {
        const completedFields = _.chain(flattenFields(this.fields))
          .map(field => _.get(
            newValue,
            `${this.section.type}:${this.section.slug}:${field?.label}`
          ))
          .reject(a => (a ?? false) === false)
          .value();

        this.isCompleted = flattenFields(this.fields).length === completedFields.length;

        if (
          this.isCompleted
          && this.expanded === true
          && this.hasBeenClosed === false
        ) {
          this.expanded = false;
          this.hasBeenClosed = true;
        } else if (this.isCompleted) {
          this.hasBeenClosed = true;
        }
      }
    );
    if (this.userType === "reviewer" || this.userType === "admin") {
      this.expanded = true;
    } else if (!this.isCompleted) {
      this.expanded = this.groupIndex === 0;
    }

    if (this.newProject && (this.helpheroTourStarted("New Project Methodology") === false)) {
      this.expanded = true;
    }

    const { bus } = useEventsBus();

    watch(() => bus.value.get("openFieldGroup"), val => {
      const [index] = val ?? [];

      if (index === this.groupIndex) {
        this.expanded = true;
      }
    });

    watch(() => bus.value.get("openAllFieldGroups"), () => {
      this.expanded = true;
    });
  },
  methods: {
    fieldProxy(field) {
      const reactiveField = reactive(field);
      reactiveField.state = field.state ?? FIELD_STATE.UNTOUCHED;
      return reactiveField;
    },
    fieldHasFeedback(field) {
      if (field.type === "Paired") {
        return false;
      }
      const feedback = this.$store.getters[
          `feedbacks/${GET_FEEDBACKS_FOR_FIELD}`
      ](this.section.type, this.section.slug, field.label);

      return feedback !== undefined && feedback !== null;
    },
    fieldBgColour(field) {
      if (this.fieldHasFeedback(field)) {
        return "tw-bg-amber-50 tw-rounded-lg tw-px-4 tw-mx-2 tw-py-3";
      } if (["reviewer", "admin"].includes(this.userType) && this.fieldsChangedSinceLastReview?.includes(field.label)) {
        return "tw-bg-blue-50 tw-rounded-lg tw-px-4 tw-mx-2 tw-py-3";
      }
      return "tw-px-6";
    }
  },
  unmount() {
    this.unwatch();
  }
};
</script>
