<template>
  <div v-if="!loading" :class="fieldType === 'Percent' ? 'tw-w-fit' : 'tw-w-full'">
    <div v-if="fieldType === 'Currency'">
      <tooltip :content="editable ? '' : 'This data is imported from your accounting integration so cannot be changed here, any needed changes should be done there.'">
        <div class="tw-relative tw-rounded-md tw-shadow-sm">
          <Currency
            ref="inputRef"
            :value="val"
            :editable="editable"
            class="subcost-currency-input"
            use-emitter
            aria-describedby="price-currency"
            @currency-input="updateCurrencyInput"
            @keyup.enter="onEnter"
            @keydown.up="onKeydown"
            @keydown.down="onKeydown"
          />
        </div>
      </tooltip>
    </div>

    <div v-if="fieldType === 'Percent'">
      <div class="tw-relative tw-rounded-md tw-shadow-sm">
        <input
          ref="inputRef"
          v-model="percentValue"
          type="number"
          step="1"
          :class="[anyErrors ? theme.forms.input_error : theme.forms.input, 'tw-pr-6 tw-text-xs subcost-pc-input']"
          @input="val = Math.round(val)"
          @keyup.enter="onEnter"
          @keydown.up="onKeydown"
          @keydown.down="onKeydown"
        >
        <div
          class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"
        >
          <span class="tw-text-gray-500 sm:tw-text-sm">%</span>
        </div>
      </div>
    </div>

    <div v-if="fieldType === 'Boolean' && !disabledMessage">
      <div class="tw-text-center">
        <input
          ref="inputRef"
          v-model="val"
          type="checkbox"
          :class="[theme.forms.checkbox]"
          :checked="val === true || val === 'true'"
          @keydown.up="onKeydown"
          @keydown.down="onKeydown"
          @keydown.enter="val = !val"
        >
      </div>
    </div>

    <div v-if="fieldType === 'String'">
      <div class="tw-relative tw-rounded-md tw-shadow-sm">
        <tooltip :content="editable ? '' : 'This data is imported from your accounting integration so cannot be changed here, any needed changes should be done there.'">
          <input
            ref="inputRef"
            v-model="val"
            type="text"
            :class="[anyErrors ? theme.forms.input_error : theme.forms.input, 'tw-px-2 tw-text-xs', 'subcost-text-input']"
            :disabled="!editable"
            :readonly="!editable"
            @keyup.enter="onEnter"
            @keydown.up="onKeydown"
            @keydown.down="onKeydown"
          >
        </tooltip>
      </div>
    </div>

    <div v-if="fieldType === 'Dropdown'">
      <select v-if="column.field === 'subcost_type_id'" v-model="val" :class="[theme.forms.input, 'tw-text-xs']">
        <option :value="null" />
        <template v-for="subcostType in subcostTypes" :key="subcostType.id">
          <option :value="subcostType.id" :selected="subcostType.id === val">
            {{ subcostType.label }}
          </option>
        </template>
      </select>
    </div>

    <div v-if="fieldType === 'DropdownBoolean'">
      <tooltip :content="disabledMessage">
        <select v-model="val" :disabled="disabledMessage" :class="[!!disabledMessage ? theme.forms.inputDisabled : theme.forms.input, 'tw-text-xs']">
          <option :value="null" />
          <template v-for="(value, label) in columnOptions" :key="value">
            <option :value="value" :selected="value === val">
              {{ label }}
            </option>
          </template>
        </select>
      </tooltip>
    </div>

    <div v-if="fieldType == 'RadioBoolean'" class="tw-inline-flex tw-gap-x-2">
      <template v-for="(value, label) in columnOptions" :key="value">
        <div class="tw-inline-flex tw-gap-x-1.5 tw-items-center">
          <input
            v-model="val"
            type="radio"
            :class="theme.forms.radio"
            :value="value"
          >
          <label
            :for="`${column.field}_${value}`"
            class="tw-font-medium tw-text-sm tw-text-gray-700 tw-mb-0"
          >
            {{ label }}
          </label>
        </div>
      </template>
    </div>

    <div v-if="fieldType === 'DropdownMultiSelect'">
      <tooltip
        v-if="column.field === 'rd_projects'"
        :content="disabledMessage"
        :tooltip-options="{ hideOnClick: false }"
      >
        <DropdownMultiSelect
          v-model="val"
          :disabled="!!disabledMessage"
          :placeholder="column.placeholder"
          :select-all-text="column.select_all_checkbox_text"
          :options="dropdownOptions"
          :read-only-text="column.read_only_text"
        />
      </tooltip>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { useStore } from "vuex";
import { onMounted, computed, ref, watch, nextTick } from "vue";

import useEventsBus from "../../../shared/eventBus.js";
import { css } from "../../../shared/theme.js";
import DropdownMultiSelect from "../dynamic_forms/fields/dropdown_multi_select.vue";
import Currency from "../dynamic_forms/fields/currency.vue";

export default {
  components: {
    DropdownMultiSelect,
    Currency
  },
  props: {
    fieldValue: {
      required: true
    },
    column: {
      type: Object
    },
    subCostType: {
      type: String
    },
    subCost: {
      type: Object
    },
    costSlug: {
      type: String
    },
    showAddRd: {
      type: Boolean,
      default: false
    },
    columnOptions: {
      type: Object
    },
    subcostTypes: {
      type: Object
    },
    disabledMessage: {
      type: String
    },
    cellPosition: {
      type: Object
    }
  },
  setup(props, context) {
    const theme = css;
    const store = useStore();
    const debouncedSave = props.column.field === "rd_projects" ? _.debounce(handleProjectAllocations, 1000) : _.debounce(save, 1000);
    const { emit, bus } = useEventsBus();

    const loading = ref(true);
    const changed = ref(false);
    const val = ref(null);
    const initialVal = ref(null);
    const inputRef = ref(null);
    const percentValue = computed({
      get() {
        return val.value || val.value === 0 ? parseInt(val.value) : "";
      },
      set(newValue) {
        val.value = parseFloat(newValue);
      }
    });

    function onKeydown(e) {
      e.preventDefault();

      const positionToMoveTo = { ...props.cellPosition };

      if (e.key === "ArrowUp") {
        positionToMoveTo.row -= 1;
      } else if (e.key === "ArrowDown") {
        positionToMoveTo.row += 1;
      }

      emit("move-focus", positionToMoveTo);
    }

    watch(() => bus.value.get("move-focus"), positionToMoveTo => {
      if (_.isEqual(positionToMoveTo[0], props.cellPosition)) {
        inputRef.value.tagName === "INPUT" ? inputRef.value.focus() : inputRef.value.focusInput();
      }
    });

    const disabledIfFromIntegration = ["rd_col_1", "amount"];
    const fieldType = props.column.format;

    const editable = computed(() => {
      if (disabledIfFromIntegration.indexOf(props.column.field) === -1) { return true; }

      return props.subCost.integration_grouping === null;
    });

    const anyARValidationsForSection = computed(() => store.getters["validations/GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_SECTION"](props.subCostType, props.subCost.slug));
    const anyErrors = computed(() => store.getters["validations/GET_ANY_VALIDATION_MESSAGES_FOR_FIELD"](props.subCostType, props.subCost.slug, props.column.database_field || props.column.field));
    const errorMessage = computed(() => store.getters["validations/GET_VALIDATION_MESSAGES_FOR_FIELD"](props.subCostType, props.subCost.slug, props.column.database_field || props.column.field));
    const isEmpowerrd = computed(() => store.getters["users/GET_IS_EMPOWERRD"]);
    const cost = computed(() => store.getters["costs/GET_COST"]);
    const dropdownOptions = computed(() => {
      if (props.column.field === "rd_projects") {
        return cost.value.valid_rd_projects;
      }
    });

    function updateCurrencyInput(updatedValue) {
      val.value = updatedValue;
    }

    function save() {
      const fields = {};
      const field = props.column?.database_field || props.column.field;
      fields[field] = val.value;

      store.dispatch("validations/VALIDATE", [props.subCostType, props.subCost.slug, fields]).then(data => {
        if (!data?.success) return;

        if (anyARValidationsForSection.value) {
          store.commit("costs/SET_UNSAVED_COST", { subCostType: props.subCostType, subcostSlug: props.subCost.slug });
        } else {
          updateSubcosts();
        }
      });
      changed.value = true;
    }

    function updateSubcosts() {
      store.dispatch("costs/UPDATE_SUBCOSTS", {
        costSlug: props.costSlug,
        subCostType: props.subCostType,
        rd: props.showAddRd,
        isEmpowerrd: isEmpowerrd.value,
        value: val.value,
        col: props.column.field,
        subCostSlug: props.subCost.slug
      });
    }

    function handleProjectAllocations() {
      store.dispatch("costs/HANDLE_PROJECT_SUBCOSTS", {
        costSlug: props.costSlug,
        subCostType: props.subCostType,
        subcostSlug: props.subCost.slug,
        projectCostBreakdownSlug: cost.value.project_cost_breakdown?.slug,
        rd: props.showAddRd,
        rdProjectSlugs: val.value,
        initialVal: initialVal.value
      }).then(() => {
        updateSubcosts();
      });

      changed.value = true;
    }

    onMounted(() => {
      if (props.column.field === "rd_projects") {
        val.value = props.subCost.rd_projects.map(project => project.slug);
        initialVal.value = props.subCost.rd_projects.map(project => project.slug);
      } else {
        val.value = props.fieldValue ?? props.column.default;
        initialVal.value = props.fieldValue;
      }

      loading.value = false;
    });

    watch(() => val.value, () => {
      if ((val.value != initialVal.value && val.value != props.fieldValue) || changed.value === true) {
        props.subCost[props.column.database_field || props.column.field] = val.value;
        debouncedSave();
      }
    }, { deep: true });

    watch(() => props.fieldValue, newVal => {
      if (newVal != val.value && changed.value === false) {
        val.value = newVal;
      }
    });

    watch(() => anyErrors.value, () => {
      nextTick(() => {
        if (anyErrors.value) {
          emit("showSubcostErrors", props.subCost.slug);
          setTimeout(() => { emit("hideSubcostErrors", props.subCost.slug); }, 5000);
        } else {
          emit("hideSubcostErrors", props.subCost.slug);
        }
      });
    });

    function onEnter() {
      context.emit("childEditOnEnter", props.subCost);
    }

    return {
      theme,
      val,
      save,
      anyErrors,
      errorMessage,
      fieldType,
      editable,
      debouncedSave,
      percentValue,
      onEnter,
      loading,
      dropdownOptions,
      updateCurrencyInput,
      onKeydown,
      inputRef
    };
  }
};
</script>
