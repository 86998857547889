<template>
  <div v-if="editable" class="tw-flex tw-items-center tw-justify-end">
    <button-component
      :text="text"
      icon="plus"
      type="transparent"
      colour="disabled"
      :on-click="createRdProject"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  props: {
    slug: {
      required: true,
      type: String
    },
    text: {
      required: false,
      type: String,
      default: "Add R&D Project"
    },
    editable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();

    function createRdProject() {
      store.dispatch(
        "project_selection/CREATE_RD_PROJECT",
        { project_selection_slug: props.slug }
      );
    }

    return {
      createRdProject
    };
  }
};
</script>
