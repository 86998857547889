<template>
  <div :class="field.half_width ? 'tw-w-1/2' : 'tw-w-full'">
    <div v-if="editable">
      <div class="tw-relative">
        <input
          :id="`${sectionType}_${sectionSlug}_${field.label}`"
          v-model="field.value"
          type="text"
          :name="field.label"
          :class="[theme.forms.input, borderStyle]"
          :placeholder="field.field_placeholder_blurb"
          :maxlength="field.limit"
          @input="inputted"
        >
        <span class="tw-absolute tw-right-0 tw-inset-y-0 tw-flex tw-items-center tw-m-1">
          <SavingSpinIndicator
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :fields="[field.label]"
            :bg-color="true"
            @finishedSaving="clean()"
          />
        </span>
      </div>

      <span
        v-if="field.limit"
        :class="['tw-italic tw-text-xs', hitMax ? 'tw-text-error-red tw-animate-bounce' : 'tw-text-disable-grey-500']"
      >
        Max {{ numberWithCommas(field.limit) }} characters
        <i v-if="hitMax" class="fas fa-exclamation-triangle" />
      </span>
    </div>

    <div v-else>
      <div class="tw-text-gray-500 tw-text-sm">
        {{ field.value || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from "@shared/helpers.js";
import SavingSpinIndicator from "@generic/saving_spin_indicator.vue";
import { css } from "@shared/theme.js";
import { FIELD_STATE } from "../shared/field_state.js";

export default {
  components: {
    SavingSpinIndicator
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument",
          "RdProject"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      theme: css,
      borderStyle: null,
      hitMax: false
    };
  },
  watch: {
    "field.value": function (val) {
      this.hitMax = val.length === 50;

      this.debouncedBorderStyle();
    },
    "field.state": function () {
      this.debouncedBorderStyle();
    }
  },
  mounted() {
    this.debouncedBorderStyle = _.debounce(this.changeBorderStyle, 1000);
  },
  methods: {
    changeBorderStyle() {
      if (this.field.state === FIELD_STATE.EDITED) {
        this.borderStyle = this.theme.borderStyles.edited;
      } else if (this.field.state === FIELD_STATE.SAVED) {
        this.borderStyle = this.theme.borderStyles.styleSaved;
      }
    },
    clean() {
      this.borderStyle = this.theme.borderStyles.untouched;
    },
    numberWithCommas
  }
};
</script>
