<template>
  <Tippy
    v-if="hasFeedback"
    ref="sectionFeedbackTooltip"
    :interactive="true"
    :allow-h-t-m-l="true"
    theme="light"
  >
    <slot name="toggle">
      <span :class="background ? `${bgColor} tw-font-semibold tw-rounded-lg tw-px-2 tw-py-1.5 ${textColor}` : ''">
        <i :class="`far fa-comment-alt fa-1x ${textColor}`" />
      </span>
    </slot>
    <template #content="{ hide }">
      <slot :hide="hide">
        <div class="tw-flex-col tw-flex tw-gap-1.5 tw-p-2 tw-justify-start tw-font-normal">
          <div :class="textColor">
            <i :class="`far fa-comment-alt tw-text-base ${textColor} tw-inline tw-mr-1`" />
            {{ feedback.feedback }}
          </div>
          <div v-if="canShowResolveButton">
            <div class="tw-flex tw-flex-col tw-gap-2">
              <button class="tw-w-fit tw-font-medium tw-text-sm tw-border tw-rounded-md tw-border-gray-600 hover:tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-gray-600 hover:tw-text-gray-900 focus:tw-outline-none" type="button" @click.stop="markResolved()">
                {{ resolveTypeText }}
              </button>
              <BounceTransition>
                <span v-if="feedback.error" class="tw-text-red-500 tw-font-medium tw-text-xs tw-inline-block">
                  <i class="fas fa-exclamation-triangle tw-text-base" />
                  {{ feedback.error[colRef][0] }}
                </span>
              </BounceTransition>
            </div>
          </div>
          <state-wrapper component="UpdateFeedbackForAdmins">
            <UpdateFeedbackForAdmins
              v-if="canShowUpdateButton"
              :section-slug="sectionSlug"
              :feedback="feedback"
              @hideSectionFeedbackTooltip="hideSectionFeedbackTooltip()"
            />
          </state-wrapper>
        </div>
      </slot>
    </template>
  </Tippy>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Tippy } from "vue-tippy";

import BounceTransition from "../transitions/bounce.vue";
import UpdateFeedbackForAdmins from "../update_feedback_for_admins.vue";

export default {
  components: {
    Tippy,
    BounceTransition,
    UpdateFeedbackForAdmins
  },
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "CostsEmployee",
          "CostsSubcontractor",
          "CostsConsumable",
          "CostsOther",
          "EpwContract",
          "ReviewTask"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    parentSectionType: {
      type: String,
      required: false,
      validator: value => {
        const validSections = [
          "Cost",
          "EpwContractReview"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    parentSectionSlug: {
      type: String,
      required: false
    },
    colRef: {
      type: String,
      required: false
    },
    feedback: {
      type: Object,
      required: true
    },
    background: {
      type: Boolean,
      required: false
    },
    userRole: {
      type: String,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: false
    },
    redirectUrl: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const sectionFeedbackTooltip = ref(null);

    function markResolved() {
      const feedbackPayload = {
        sectionType: props.sectionType,
        sectionSlug: props.sectionSlug,
        field: props.colRef,
        feedbackId: props.feedback.id
      };

      const statePayload = {
        sectionType: props.parentSectionType,
        sectionSlug: props.parentSectionSlug,
        rd: props.parentSectionType === "Cost"
      };

      store.dispatch("feedbacks/RESOLVE_FEEDBACK", feedbackPayload).then(() => {
        if (props.redirectUrl) {
          window.location.href = props.redirectUrl;
        } else {
          store.dispatch("feedbacks/FETCH_FEEDBACKS_FOR_SECTION", statePayload);
          store.dispatch("feedbacks/FETCH_ALL_CLAIM_SECTION_FEEDBACKS", ({ claimPeriodSlug: props.claimPeriodSlug }));
        }
      });
    }

    const hasFeedback = computed(() => props.feedback !== undefined && props.feedback !== null && props.feedback.feedback !== null);

    const resolveTypeText = computed(() => {
      if (props.feedback.resolve_activity_type === "Review/Accept") return "Acknowledge Feedback";
      if (props.feedback.resolve_activity_type === "Accept Non-Qualifying") return "Resolve and Accept as Non-Qualifying";

      return "Mark as Resolved";
    });

    // Add once Resolve is flexible
    // if (props.sectionType === "ReviewTask") return props.userRole === "emp_admin";
    const canShowResolveButton = computed(() => props.userRole === "coordinator" || props.userRole === "tech_lead");

    const textColor = computed(() => (props.feedback.resolve_activity_type === "Review/Accept" ? "tw-text-yellow-400" : "tw-text-rose-400"));

    const bgColor = computed(() => (props.feedback.resolve_activity_type === "Review/Accept" ? "tw-bg-amber-50" : "tw-bg-rose-50"));

    const canShowUpdateButton = computed(() => props.sectionType !== "ReviewTask" && props.userRole === "emp_admin");

    function hideSectionFeedbackTooltip() {
      setTimeout(() => { sectionFeedbackTooltip.value?.hide(); }, 200);
    }

    return {
      markResolved,
      hasFeedback,
      resolveTypeText,
      canShowResolveButton,
      textColor,
      bgColor,
      canShowUpdateButton,
      sectionFeedbackTooltip,
      hideSectionFeedbackTooltip
    };
  }
};
</script>
