<template>
  <div v-if="fieldType == 'Currency'">
    <div v-if="val != null">
      {{ formatCurrency(val) }}
    </div>
    <div v-else class="tw-text-gray-400 tw-italic">
      -
    </div>
  </div>

  <div v-if="fieldType == 'String'" class="tw-w-full">
    <div v-if="val != null">
      {{ val }}
    </div>
    <div v-else class="tw-text-gray-400 tw-italic">
      (No value)
    </div>
  </div>

  <div v-if="fieldType == 'Percent'" class="tw-w-fit">
    <div v-if="val || val === 0 ">
      {{ nFormatter(val, 1) }}%
    </div>
    <div v-else class="tw-text-gray-400 tw-italic">
      -
    </div>
  </div>

  <div v-if="['Boolean', 'RadioBoolean'].includes(fieldType)" class="tw-text-center tw-w-full">
    <div v-if="val == true" class="tw-mx-auto">
      <i class="far fa-check" />
    </div>
    <div v-if="val == false && fieldType == 'RadioBoolean'">
      <i class="far fa-times" />
    </div>
  </div>

  <div v-if="['Dropdown', 'DropdownBoolean'].includes(fieldType)" class="tw-w-full">
    <div v-if="!!disabledMessage" class="tw-text-gray-400 tw-italic" />
    <div v-else-if="val != null">
      {{ dropdownLabel || '' }}
    </div>
    <div v-else class="tw-text-gray-400 tw-italic">
      -
    </div>
  </div>

  <div v-if="fieldType === 'DropdownMultiSelect'" class="tw-w-full">
    <DropdownMultiSelect
      v-model="val"
      :editable="false"
      :options="dropdownOptions"
      :read-only-text="column.read_only_text"
      :disabled="!!disabledMessage"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { css } from "../../../shared/theme.js";

import { nFormatter, formatCurrency } from "../../../shared/helpers.js";
import DropdownMultiSelect from "../dynamic_forms/fields/dropdown_multi_select.vue";

export default {
  components: {
    DropdownMultiSelect
  },
  props: {
    fieldType: {
      type: String
    },
    val: {
      type: [Number, String, Boolean, Array]
    },
    label: {
      type: String
    },
    column: {
      type: Object
    },
    columnOptions: {
      type: Object
    },
    disabledMessage: {
      type: String
    }
  },
  setup(props) {
    const store = useStore();

    const val = computed(() => {
      if (props.column.field === "rd_projects") {
        return props.val?.map(rdProject => rdProject.slug);
      } else {
        return props.val;
      }
    });

    const cost = computed(() => store.getters["costs/GET_COST"]);
    const dropdownOptions = computed(() => {
      if (props.column.field === "rd_projects") {
        return cost.value.valid_rd_projects;
      }
    });

    const dropdownLabel = computed(() => props.label || _.findKey(props.columnOptions, value => value === val.value));

    return {
      dropdownOptions,
      val,
      dropdownLabel
    };
  },
  data() {
    return {
      theme: css,
      editModeAll: false,
      editModeSingle: null,
      nFormatter,
      formatCurrency
    };
  }
};
</script>
