<template>
  <div class="tw-mt-1 tw-pb-3 tw-px-2">
    <div class="tw-text-light-grey-blue tw-text-xs">
      {{ sectionBlurb }}
    </div>
    <div v-if="unresolvedFeedback" class="tw-mt-2">
      <a :href="dropdownData.link || feedbackLink" class="tw-inline-flex tw-gap-2 tw-items-center tw-text-xs tw-text-yellow-400 hover:tw-text-yellow-600">
        <i class="fas fa-exclamation-triangle" />
        {{ unresolvedFeedbackBlurb }}
      </a>
    </div>
    <div v-if="dropdownType">
      <component
        :is="dropdownType"
        :is-complete="sectionPercentage >= 90.0"
        :section-type="sectionType"
        :section-data="sectionData"
        :dropdown-data="dropdownData"
        :claim-period-slug="claimPeriodSlug"
      />
    </div>
  </div>
</template>

<script>
import ProgressionCheckboxes from "./dropdown_types/progression_checkboxes.vue";
import ProjectsProgression from "./dropdown_types/projects_progression.vue";
import ProgressionTable from "./dropdown_types/progression_table.vue";

export default {
  components: {
    ProgressionCheckboxes,
    ProjectsProgression,
    ProgressionTable
  },
  props: {
    claimPeriodSlug: {
      type: String,
      required: false
    },
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    sectionBlurb() {
      return this.sectionData.blurb;
    },
    sectionPercentage() {
      return this.section.total;
    },
    sectionType() {
      return this.section.type;
    },
    sectionData() {
      return this.section.claim_section;
    },
    dropdownData() {
      return this.section.dropdown_data;
    },
    feedbackLink() {
      return this.section.feedback_link;
    },
    dropdownType() {
      const validDropdownTypes = [
        "ProgressionCheckboxes",
        "ProjectsProgression",
        "ProgressionTable"
      ];
      if (validDropdownTypes.indexOf(this.section.claim_section.dropdownType) >= 0) {
        return this.section.claim_section.dropdownType;
      }
    },
    unresolvedFeedback() {
      if (this.section.type == "review") {
        return;
      } else {
        return this.section.unresolved_feedback;
      }
    },
    unresolvedFeedbackBlurb() {
      let sectionName = null;

      if (this.section.type == "projects") {
        sectionName = "narrative";
      } else if (this.section.type == "epw_contracts") {
        sectionName = "3rd party contract";
      } else {
        sectionName = "cost";
      }

      return `You have unresolved ${sectionName} feedback. Click to resolve them`;
    }
  },
  mounted() {
    this.track("Claim Progression Dropdown Opened", { section: this.sectionData?.title || this.sectionType,
      status: this.section.status,
      claimPeriodslug: this.claimPeriodSlug });
  }
};
</script>
