<template>
  <div>
    <h2 class="tw-text-lg tw-uppercase">
      {{ project.title }}
    </h2>
    <p class="tw-mb-5">
      The Total Qualifying Expenditure (TQE) for this project was {{ formatCurrency(tolalTqe) }}. How
      much of the project's qualifying expenditure
      <span class="tw-font-bold">during the {{ claimTitle }} claim period</span> was covered by the grant?
      <span>
        <KnowledgeCentreTriggerIcon
          class="tw-mx-1"
          icon
          faq="why-do-i-need-to-confirm-how-much-of-my-project-s-expenditure-was-grant-funded"
        />
      </span>
    </p>

    <div class="tw-flex tw-justify-start tw-items-center tw-space-x-5">
      <div class="tw-flex tw-space-x-8">
        <GrantInput
          v-for="grantClaimAllocation in sortedGrantClaimAllocations"
          :key="grantClaimAllocation.id"
          :grant-claim-allocation="grantClaimAllocation"
          :project-id="project.id"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { formatCurrency } from "@shared/helpers";
import KnowledgeCentreTriggerIcon from "@generic/knowledge_centre_trigger_icon.vue";
import GrantInput from "./grant_input.vue";

const props = defineProps({
  project: {
    type: Object
  },
  claimTitle: {
    type: String
  }
});

const sortedGrantClaimAllocations = computed(() => [...props.project.grant_claim_allocations].sort((a, b) => a.id - b.id));
const tolalTqe = computed(() => props.project.cost_breakdowns.reduce((acc, next) => acc + parseFloat(next.total_qualifying_expenditure), 0));
</script>
