<template>
  <div v-if="anyProcesses">
    <div class="tw-mb-4">
      These are the Submission Review Processes needed for this submission.
    </div>
    <div class="tw-w-full tw-flex tw-flex-col tw-gap-y-2">
      <SubmissionReviewProcess
        v-for="submissionReviewProcess in submissionReviewProcesses"
        :key="submissionReviewProcess.slug"
        v-bind="submissionReviewProcess"
        :submission="submission"
        :user-role="userRole"
      />
    </div>
  </div>
  <div v-else class="tw-text-center tw-text-gray-500 tw-text-sm">
    No Submission Review Processes are needed for this submission.
  </div>
</template>

<script setup>
import { computed } from "vue";
import SubmissionReviewProcess from "./submission_review_process.vue";

const props = defineProps({
  submissionReviewProcesses: {
    type: Array
  },
  submission: {
    type: Object
  },
  userRole: {
    type: String
  }
});

const anyProcesses = computed(() => props.submissionReviewProcesses && props.submissionReviewProcesses.length > 0);

</script>
