import _ from "lodash";
import { get, patch, post } from "../../shared/http.js";

export const FETCH_INCOMPLETE_REVIEWS = "FETCH_INCOMPLETE_REVIEWS";
export const FETCH_COMPLETE_REVIEWS = "FETCH_COMPLETE_REVIEWS";
export const FETCH_SECTION_REVIEWS = "FETCH_SECTION_REVIEWS";
export const FETCH_AVAILABLE_REVIEWERS = "FETCH_AVAILABLE_REVIEWERS";

export const GET_INCOMPLETE_REVIEWS = "GET_INCOMPLETE_REVIEWS";
export const GET_COMPLETE_REVIEWS = "GET_COMPLETE_REVIEWS";
export const GET_REVIEW_FOR_SECTION = "GET_REVIEW_FOR_SECTION";
export const GET_INCOMPLETE_REVIEWS_FOR_REVIEWER = "GET_INCOMPLETE_REVIEWS_FOR_REVIEWER";
export const GET_COMPLETE_REVIEWS_FOR_REVIEWER = "GET_COMPLETE_REVIEWS_FOR_REVIEWER";
export const GET_SECTION_REVIEWS = "GET_SECTION_REVIEWS";
export const GET_LAST_REVIEW_COMPLETED_FOR_SECTION = "GET_LAST_REVIEW_COMPLETED_FOR_SECTION";
export const GET_AVAILABLE_REVIEWERS = "GET_AVAILABLE_REVIEWERS";
export const GET_AVAILABLE_REVIEWERS_FOR_TYPE = "GET_AVAILABLE_REVIEWERS_FOR_TYPE";
export const GET_LOADING = "GET_LOADING";

export const START_REVIEW = "START_REVIEW";
export const COMPLETE_REVIEW = "COMPLETE_REVIEW";
export const REJECT_REVIEW = "REJECT_REVIEW";

export const reviews = {
  namespaced: true,

  state: {
    incompleteReviews: [],
    completeReviews: [],
    sectionReviews: null,
    availableReviewers: {},
    loading: false
  },

  mutations: {
    setIncompleteReviews: (state, payload) => state.incompleteReviews = payload,
    setCompleteReviews: (state, payload) => state.completeReviews = payload,
    setSectionReviews: (state, payload) => state.sectionReviews = payload,
    setAvailableReviewers: (state, payload) => state.availableReviewers = payload,
    setLoading: (state, payload) => state.loading = payload
  },
  actions: {
    [FETCH_INCOMPLETE_REVIEWS]: async ({ commit }, params) => {
      get("/api/v1/reviews", params).then(data => {
        commit("setIncompleteReviews", data);
      });
    },
    [FETCH_COMPLETE_REVIEWS]: async ({ commit }, params) => {
      get("/api/v1/reviews", params).then(data => {
        commit("setCompleteReviews", data);
      });
    },
    [FETCH_SECTION_REVIEWS]: async ({ commit, state }, payload) => {
      if (state.loading !== true) {
        if (state.sectionReviews === null) {
          commit("setLoading", true);
        }

        get(
          `/api/reviews/reviews_for_section/${payload.sectionType}/${payload.sectionSlug}`
        ).then(data => {
          commit("setSectionReviews", data);
          commit("setLoading", false);
        });
      }
    },
    [START_REVIEW]: async ({ dispatch }, payload) => {
      const response = await patch(`/api/reviews/${payload.reviewSlug}/start`);

      dispatch(FETCH_SECTION_REVIEWS, payload);
      return response;
    },
    [COMPLETE_REVIEW]: async ({ dispatch }, payload) => {
      const response = await post(`/api/reviews/${payload.reviewSlug}/complete`, payload);

      return response;
    },
    [REJECT_REVIEW]: async ({ dispatch }, payload) => {
      const response = await post(`/api/reviews/${payload.reviewSlug}/reject`, payload);

      return response;
    },
    [FETCH_AVAILABLE_REVIEWERS]: async ({ commit }) => {
      get(
        "/api/reviews/available_reviewers"
      ).then(data => {
        commit("setAvailableReviewers", data);
        commit("setLoading", false);
      });
    }
  },
  getters: {
    [GET_LOADING]: state => state.loading,
    [GET_INCOMPLETE_REVIEWS]: state => state.incompleteReviews,
    [GET_COMPLETE_REVIEWS]: state => state.completeReviews,
    [GET_SECTION_REVIEWS]: state => state.sectionReviews,
    [GET_AVAILABLE_REVIEWERS]: state => state.availableReviewers,
    [GET_AVAILABLE_REVIEWERS_FOR_TYPE]: state => reviewType => state.availableReviewers[reviewType],
    [GET_REVIEW_FOR_SECTION]: state => _.chain(state.sectionReviews)
        .filter(r => !r.completed_at)
        .head()
        .value(),
    [GET_INCOMPLETE_REVIEWS_FOR_REVIEWER]: state => userSlug => _.chain(state.incompleteReviews)
        .filter(r => r.reviewer?.slug === userSlug)
        .value(),
    [GET_COMPLETE_REVIEWS_FOR_REVIEWER]: state => userSlug => _.chain(state.completeReviews)
        .filter(r => r.reviewer?.slug === userSlug)
        .value(),
    [GET_LAST_REVIEW_COMPLETED_FOR_SECTION]: state => _.chain(state.sectionReviews)
        .filter(r => r.completed_at)
        .orderBy(["completed_at"], ["desc"])
        .head()
        .value()
  }
};
