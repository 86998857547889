import axios from "axios";
import _ from "lodash";
import { captureException } from "@sentry/browser";
import { keysToSnakeCase } from "./helpers";

const headers = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-Token": documentCsrfToken()
};

export function documentCsrfToken() {
  return document
    .querySelector("meta[name='csrf-token']")?.getAttribute("content");
}

export async function get(url, params) {
  try {
    const response = await axios.get(url, { headers, params });
    const { data = {} } = response;
    const hasErrored = checkIfErrored(data);

    return hasErrored ? generateErrorObject(response) : data;
  } catch (error) {
    return generateErrorObject(error.response, error);
  }
}

export async function del(url, dataToSend) {
  try {
    const response = await axios.delete(url, { data: dataToSend }, { headers });
    const { data = {} } = response;
    const hasErrored = checkIfErrored(data);

    return hasErrored ? generateErrorObject(response) : data;
  } catch (error) {
    return generateErrorObject(error.response, error);
  }
}

export async function post(url, dataToSend) {
  try {
    const response = await axios.post(url, dataToSend, { headers });
    const { data = {} } = response;
    const hasErrored = checkIfErrored(data);

    return hasErrored ? generateErrorObject(response) : data;
  } catch (error) {
    return generateErrorObject(error.response, error);
  }
}

export async function patch(url, dataToSend) {
  try {
    const response = await axios.patch(url, dataToSend, { headers });
    const { data = {} } = response;
    const hasErrored = checkIfErrored(data);

    return hasErrored ? generateErrorObject(response) : data;
  } catch (error) {
    return generateErrorObject(error.response, error);
  }
}

export async function put(url, dataToSend) {
  try {
    const response = await axios.put(url, dataToSend, { headers });
    const { data = {} } = response;
    const hasErrored = checkIfErrored(data);

    return hasErrored ? generateErrorObject(response) : data;
  } catch (error) {
    return generateErrorObject(error.response, error);
  }
}

function checkIfErrored(data) {
  return _.isObject(data) && data.error;
}

function generateErrorObject(response, fullError = null) {
  captureException(fullError || response);

  const errorObject = {
    error: true,
    status: response.status,
    message: response.data?.message,
    statusText: response.statusText,
    data: response.data,
    response
  };

  console.error(errorObject);

  return errorObject;
}
