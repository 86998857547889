<template>
  <tooltip :content="tooltip" :delay="400">
    <div :class="color" class="tw-inline-flex">
      <span class="tw-text-sm tw-mr-2 tw-hidden xl:tw-inline">
        {{ label }}
      </span>
      <span class="tw-hidden xl:tw-inline">
        <i :class="icon" class="fa-1x tw-pt-0.5" />
      </span>
      <span class="xl:tw-hidden">
        <i class="far fa-check-square tw-mr-1 fa-1x tw-pt-0.5" />
      </span>
    </div>
  </tooltip>
  <div v-if="validationErrors">
    <span class="tw-text-xs tw-font-semibold tw-text-red-500 tw-mr-1">
      Some answers need addressing before you can complete this section.
    </span>
  </div>
</template>

<script>

export default {
  props: {
    sectionType: {
      type: String,
      required: false
    },
    completed: {
      type: Boolean,
      required: true
    },
    validationErrors: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  computed: {
    color() {
      if (this.completed) {
        return "tw-text-green-600 hover:tw-text-green-700";
      }
      return "tw-text-gray-500 hover:tw-text-gray-900";
    },
    label() {
      if (this.completed) {
        return "Section Completed";
      }
      return "Mark as complete";
    },
    icon() {
      if (this.completed) {
        return "far fa-check-square tw-mr-1";
      }
      return "far fa-square tw-mr-1";
    },
    tooltip() {
      if (this.completed) {
        return "All the questions in this section are complete";
      }
      return "Click to mark all the questions in this section as complete";
    }
  }
};
</script>
