<template>
  <div class="tw-bg-white tw-rounded-md tw-p-4 tw-pr-0">
    <div v-if="!isReviewer" class="tw-w-full tw-justify-between tw-flex tw-mb-1 tw-items-start tw-pr-4">
      <div class="tw-prose tw-prose-sm tw-max-w-full tw-w-3/4">
        <CmsContentSlot
          v-if="blurbContentSlotId"
          :content-slot-id="blurbContentSlotId"
          size="small"
          classes="tw--mt-3"
        />
      </div>

      <div class="tw-flex tw-flex-col tw-gap-y-2 tw-items-end">
        <div v-if="canEdit" class="tw-flex tw-gap-x-2 tw-items-center tw-flex-0">
          <div v-if="!showAddRd" @click="createSubcost({ costSlug: slug, subCostType: costType, rd: showAddRd })">
            <Button text="Add Cost" icon="fa-plus" />
          </div>
          <div v-show="editModeAll" @click="setEditModeAll(false), slugsToDelete = [], allSelected = false">
            <tooltip content="This will save all your changes made">
              <Button text="View Mode" icon="fa-eye" />
            </tooltip>
          </div>
          <div v-show="!editModeAll" @click="setEditModeAll(true)">
            <Button text="Edit All" icon="fa-pencil" />
          </div>
        </div>
        <FadeTransition>
          <div v-if="editModeAll && costsToShow.length > 0" class="tw-w-full">
            <ButtonComponent
              :class="[slugsToDelete.length > 0 ? '' : 'tw-opacity-40']"
              :text="`Delete Selected (${slugsToDelete.length})`"
              icon="fas fa-trash-alt"
              :full-width="true"
              colour="error"
              :disable-click="slugsToDelete.length === 0"
              @click="slugsToDelete.length > 0 ? confirmDelete(costType, slugsToDelete) : null"
            />
          </div>
        </FadeTransition>
      </div>
    </div>

    <div ref="tableParent" :class="`tw-shadow-sm tw-max-w-full tw-overflow-auto  ${tableNeedsScroll ? 'tw-pb-2 tw-pr-4' : 'tw-mr-4'}`">
      <transition
        enter-active-class="tw-transition tw-ease-out "
        enter-from-class="tw-transform tw-opacity-0"
        enter-to-class="tw-transform tw-opacity-100 tw-duration-200"
        leave-active-class="tw-transition tw-ease-in duration-200"
        leave-from-class="tw-transform tw-opacity-100"
        leave-to-class="tw-transform tw-opacity-0"
      >
        <div v-show="hasGlobalErrors" class="tw-w-100 tw-p-2 tw-mb-2 tw-bg-red-100 tw-text-red-400">
          {{ getGlobalError }}
        </div>
      </transition>
      <table ref="table" class="tw-w-full tw-overflow-x-scroll">
        <thead>
          <tr>
            <th :class="[theme.tables.th_dark, 'tw-w-12 tw-min-w-12']">
              <input
                v-if="editModeAll && costsToShow.length > 0"
                v-model="allSelected"
                type="checkbox"
                :class="[theme.forms.checkbox, 'tw-border-white checked:tw-ring-1 tw-ring-white']"
                @click="selectAllCosts()"
              >
            </th>
            <th
              v-for="col in getColumns"
              :key="col"
              :class="[
                theme.tables.th_dark,
                showAddRd ? (col.rd_width || col.width) : col.width,
                'tw-space-x-1.5',
                col.tooltip != undefined
                  ? 'tw-cursor-pointer tw-underline tw-underline-offset-2'
                  : ''
              ]"
            >
              <span>
                <tooltip :content="col.tooltip">
                  {{ col.label }}
                </tooltip>
                <span v-if="requiredField(col.field)">
                  <tooltip :tooltip-options="{allowHTML: true}" :content="`<i>${col.label} is required${conditionalRdCol(col.field) ? ' for qualifying costs' : ''}<i>`">
                    <span class="tw-text-red-500">*</span>
                  </tooltip>
                </span>
              </span>
              <KnowledgeCentreTriggerIcon
                v-if="col.has_knowledge_center"
                :question="!!col.faq_slug ? null : col.database_field || col.field"
                :faq="col.faq_slug"
                section="Cost"
                icon
              />
            </th>
            <th v-if="showReviewComponents" :class="[theme.tables.th_dark, 'tw-w-20']">
              <Tippy content="This is the figure for your total qualifying expenditure, based on the percentages added in the 'Time Spent on R&D' section.">
                TQE
              </Tippy>
            </th>
            <th v-if="showAutoFeedback" :class="[theme.tables.th_dark, 'tw-w-20']">
&nbsp;
            </th>
            <th v-if="showReviewComponents" :class="[theme.tables.th_dark, 'tw-w-20']">
&nbsp;
            </th>
            <th :class="[theme.tables.th_dark, 'tw-w-20']">
&nbsp;
            </th>
          </tr>
        </thead>

        <tr
          v-for="(subCost, subCostIndex) in costsToShow"
          :key="subCost.slug"
          :cost-key="`${costType}::${subCost.slug}`"
          :class="[costBgColour(subCost), 'tw-text-sm']"
          @dblclick="setEditModeSingle(subCost)"
        >
          <td class="tw-px-2">
            <div
              v-if="canEdit"
              :class="[
                showAsEditable(subCost) ? 'tw-flex' : 'hover-target',
                'tw-text-center tw-items-center tw-gap-x-1.5'
              ]"
            >
              <div
                v-if="!editModeAll"
                class="tw-cursor-pointer tw-space-x-1.5"
              >
                <i
                  :class="['fas tw-text-xs', showAsEditable(subCost) ? 'fa-check tw-text-green-600' : 'fa-pencil']"
                  @click="showAsEditable(subCost) ? closeSingleSelection(subCost) : setEditModeSingle(subCost)"
                />
                <i
                  class="fas fa-trash-alt tw-text-xs hover:tw-text-red-600"
                  @click="confirmDelete(costType, [subCost.slug])"
                />
              </div>
              <div v-if="editModeAll">
                <input
                  v-model="slugsToDelete"
                  type="checkbox"
                  :value="subCost.slug"
                  :class="[theme.forms.checkbox]"
                >
              </div>
              <div
                v-if="subCost.integration_grouping != null"
                class="tw-cursor-pointer"
              >
                <Tippy content="This cost is linked to your accounting system">
                  <img src="/images/xero-logo.blue.png" class="tw-h-4 tw-w-4" alt="This cost is linked to your accounting system">
                </Tippy>
              </div>
            </div>
          </td>

          <td
            v-for="(col, colIndex) in getColumns"
            :key="`${subCost.slug}_${col.label}`"
            valign="middle"
            :class="[
              theme.tables.td,
              showAddRd ? (col.rd_width || col.width) : col.width,
              'tw-flex-shrink-0',
              col.format == 'Percent' ? 'tw-items-center' :'',
              canEdit == false ? '' : 'tw-cursor-pointer'
            ]"
          >
            <div>
              <div
                v-if="showAsEditable(subCost)"
                class="tw-flex tw-gap-x-3 tw-items-center"
              >
                <CostTableEditField
                  v-if="!shouldHide(col, subCost)"
                  :column="col"
                  :sub-cost-type="costType"
                  :sub-cost="subCost"
                  :cost-slug="slug"
                  :show-add-rd="showAddRd"
                  :column-options="columnOptions(col)"
                  :subcost-types="subcostTypes"
                  :disabled-message="dropdownDisabledMessage(col, subCost)"
                  :field-value="subCost[col.field]"
                  :cell-position="{ col: colIndex, row: subCostIndex }"
                  @childEditOnEnter="closeSingleSelection($event)"
                />

                <div
                  v-if="showAddRd == true && ((col.database_field || col.field) == 'pc_rd' || hasFeedback(costType, subCost.slug, col.database_field || col.field))"
                  class="tw-flex tw-gap-x-2 tw-items-center"
                >
                  <SubcostHistorical
                    v-if="(col.database_field || col.field) == 'pc_rd'"
                    :section-type="costType"
                    :section-slug="subCost.slug"
                    :current="subCost[col.field]"
                  />

                  <div
                    v-if="hasFeedback(costType, subCost.slug, col.database_field || col.field)"
                    :class="['tw-flex tw-gap-x-2', hasFeedback(costType, subCost.slug, col.database_field || col.field) ? feedbackTextColor(getUnresolvedFeedbacksForField(costType, subCost.slug, col.database_field || col.field)) : '']"
                  >
                    <SectionFeedback
                      :feedback="getUnresolvedFeedbacksForField(costType, subCost.slug, col.database_field || col.field)"
                      :section-type="costType"
                      :section-slug="subCost.slug"
                      parent-section-type="Cost"
                      :parent-section-slug="slug"
                      :background="true"
                      :col-ref="col.database_field || col.field"
                      :user-role="userRole"
                      :claim-period-slug="claimPeriodSlug"
                    />
                  </div>
                </div>
              </div>

              <div v-else class="tw-flex tw-gap-x-2 tw-items-center tw-w-full" @click="setEditModeSingle(subCost)">
                <div :class="['tw-flex tw-flex-1 tw-gap-x-2 tw-items-center tw-transition-bg tw-duration-300 tw-ease-in-out tw-delay-150 tw-w-full', hasFeedback(costType, subCost.slug, col.database_field || col.field) ? `${feedbackTextColor(getUnresolvedFeedbacksForField(costType, subCost.slug, col.database_field || col.field))} tw-rounded-md tw-px-2 tw-py-1.5 tw-font-medium ${feedbackBgColor(getUnresolvedFeedbacksForField(costType, subCost.slug, col.database_field || col.field))}` : '']">
                  <NotificationComponent
                    :show="isEmpowerrd && subCost[col.notification_req]"
                    :text="col.notification_tooltip"
                    :icon="col.notification_icon"
                    class="tw-w-full"
                  >
                    <CostTableDisplayField
                      v-if="!shouldHide(col, subCost)"
                      :column="col"
                      :field-type="col.format"
                      :val="subCost[col.field]"
                      :label="col.format === 'Dropdown' ? dropdownColLabel(subCost[col.field]) : null"
                      :column-options="columnOptions(col)"
                      :disabled-message="dropdownDisabledMessage(col, subCost)"
                    />
                  </NotificationComponent>
                  <SectionFeedback
                    v-if="hasFeedback(costType, subCost.slug, col.database_field || col.field)"
                    :feedback="getUnresolvedFeedbacksForField(costType, subCost.slug, col.database_field || col.field)"
                    :section-type="costType"
                    :section-slug="subCost.slug"
                    parent-section-type="Cost"
                    :parent-section-slug="slug"
                    :col-ref="col.database_field || col.field"
                    :user-role="userRole"
                    :claim-period-slug="claimPeriodSlug"
                  />
                </div>
                <SubcostHistorical
                  v-if="(col.database_field || col.field) == 'pc_rd'"
                  :section-type="costType"
                  :section-slug="subCost.slug"
                  :current="subCost[col.field]"
                />
                <div v-if="showReviewComponents && col.can_receive_feedback == true" class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-text-center tw-gap-x-1.5 tw-gap-y-0.5">
                  <NotificationComponent>
                    <LeaveFeedbackIcon
                      :field="subCost"
                      :existing-feedback="getUnresolvedFeedbacksForField(costType, subCost.slug, col.field || col.database_field)"
                      :canned-feedback="filterAutoForSubcost(subCost)"
                      :section-type="costType"
                      :options="{ claimPeriodSlug: claimPeriodSlug, showInternationalSubcontractorWork: showInternationalSubcontractorWork }"
                      :section-slug="slug"
                      parent-section-type="Cost"
                      :col-ref-database="col.database_field == undefined ? col.field : col.database_field"
                      :col-ref-object="col.field"
                      :user-slug="userSlug"
                    />
                    <template #notification>
                      <div class="tw-text-xs">
                        <PreviousResolvedFeedbackIcon
                          :section-type="costType"
                          :section-slug="subCost.slug"
                          :col-ref="col.database_field || col.field"
                        />
                      </div>
                    </template>
                  </NotificationComponent>
                </div>
              </div>
            </div>
          </td>
          <!-- contains element bound to cost calcualtions -->
          <td v-if="showReviewComponents">
            <div class="tw-text-center tw-text-gray-400">
              <div
                v-if="getCalculatedTotal(costType, subCost.slug) != null"
                :class="[getCalculatedTotal(costType, subCost.slug) >= 10000 && ['CostsConsumable','CostsOther'].includes(costType) ? 'tw-font-semibold tw-text-red-400' : '']"
              >
                £{{ nFormatter(getCalculatedTotal(costType, subCost.slug), 1) }}
              </div>
              <div v-else class="tw-text-gray-400 tw-italic">
                -
              </div>
            </div>
          </td>
          <td v-if="showAutoFeedback">
            <div v-if="getAutoFeedback(costType) != undefined && hasFeedback(costType, subCost.slug, 'pc_rd') != true && _.first(filterAutoForSubcost(subCost)) != undefined" :class="[theme.tables.td, 'tw-text-center']">
              <div class="tw-text-center">
                <AutoCostFeedbackIcon :feedbacks="filterAutoForSubcost(subCost)" :options="{claimPeriodSlug: claimPeriodSlug}" :claim-period-slug="claimPeriodSlug" />
              </div>
            </div>
          </td>
          <td v-if="showReviewComponents">
            <div class="tw-flex tw-items-center tw-gap-x-1">
              <div v-if="filterAutoForSubcost(subCost) != (undefined || [])" :class="[theme.tables.td, 'tw-text-center']">
                <div class="tw-text-center">
                  <CannedFeedbackIcon
                    :field="subCost"
                    :existing-feedback="getUnresolvedFeedbacksForField(costType, subCost.slug, 'pc_rd')"
                    :canned-feedback="filterAutoForSubcost(subCost)"
                    :options="{claimPeriodSlug: claimPeriodSlug}"
                    :section-slug="slug"
                    section-type="Cost"
                    :user-slug="userSlug"
                  />
                </div>
              </div>
              <div v-if="isEmpowerrd" class="tw-text-lg">
                <state-wrapper component="AuditHistoryTrigger">
                  <AuditHistoryTrigger
                    :section-type="costType"
                    :section-slug="subCost.slug"
                  />
                </state-wrapper>
              </div>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-x-1 tw-h-full">
              <state-wrapper component="OverseasSubcontractorJustification">
                <OverseasSubcontractorJustification
                  v-if="
                    showAddRd == true &&
                      costType == 'CostsSubcontractor' &&
                      subCost.uk_based == false
                  "
                  :sub-cost="subCost"
                />
              </state-wrapper>
              <state-wrapper component="SectionValidationMessages">
                <tooltip v-if="getAnyValidationsForSubcost(costType, subCost.slug)" :ref="(el) => (errorTooltips[subCost.slug] = el)">
                  <div class="tw-text-center tw-px-2">
                    <i class="fas fa-exclamation fa-lg tw-text-error-red" />
                  </div>
                  <template #content>
                    <div class="tw-p-1">
                      <div class="tw-flex tw-flex-col tw-gap-y-2">
                        <div
                          v-for="valMessage in getValidationMessagesForSubcost(costType, subCost.slug, true)"
                          :key="valMessage"
                        >
                          <div class="tw-text-sm">
                            {{ valMessage }}
                          </div>
                          <ButtonComponent
                            v-if="valMessage.includes('allocation to be this high')"
                            class="tw-mt-2"
                            text="Find out more"
                            icon="question-circle"
                            type="transparent"
                            colour="emp"
                            @click="trigger(subCost.slug, 'excessively-high-r-and-d-allocations')"
                          />
                        </div>
                        <span v-if="getAnyActiveRecordValidationsForSection(costType, subCost.slug)" class="tw-text-red-500 tw-font-medium tw-text-sm tw-inline-block">
                          <i class="fas fa-exclamation-triangle tw-text-base" />
                          Please ensure this answer has been updated before saving
                        </span>
                      </div>
                    </div>
                  </template>
                </tooltip>
              </state-wrapper>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Tippy } from "vue-tippy";
import { watch, ref, nextTick } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { costColumns,
  fundedColumns,
  disallowedForRole,
  pcRdColumn,
  allowedInRd,
  allowedInRdEmpowerrd,
  requiredFields,
  capitalisedIntangibleColumns,
  conditionalRdColumns } from "./cost-columns.js";
import { css } from "../../../shared/theme.js";
import { featureEnabled, optionNotSet } from "../../../shared/fields.js";
import { nFormatter, pluralize } from "../../../shared/helpers.js";

import AutoCostFeedbackIcon from "../../generic/auto_cost_feedback_icon.vue";
import CannedFeedbackIcon from "../../generic/canned_feedback_icon.vue";
import LeaveFeedbackIcon from "../sections/leave_feedback_icon.vue";
import SectionFeedback from "../sections/section_feedback.vue";
import SubcostHistorical from "./subcost_historical.vue";
import AuditHistoryTrigger from "../dynamic_forms/audit_history_trigger.vue";
import OverseasSubcontractorJustification from "./overseas_subcontractor_justification.vue";
import NotificationComponent from "../../generic/notification_component.vue";

import strapiContentIds from "../../../shared/strapi_content_ids.js";

import { GET_SUBCOSTS,
  CREATE_SUBCOST,
  FETCH_SUBCOSTS,
  DELETE_SUBCOSTS,
  GET_SUBCOST_VALIDATION_ERRORS,
  GET_GLOBAL_ERROR,
  FETCH_AUTO_FEEDBACK,
  GET_AUTO_FEEDBACK,
  FETCH_HISTORICALS,
  GET_CALCULATED_TOTAL,
  FETCH_CALCULATED_TOTALS,
  SET_SAVED_COST,
  GET_UNSAVED_COSTS,
  GET_ANY_UNSAVED_COSTS,
  GET_SUBCOST_TYPES,
  GET_ALL_SUBCOST_TYPES,
  GET_COST,
  FETCH_QDA } from "../../../vuex-store/modules/costs.js";

import { FETCH_CLAIM_FEEDBACK, GET_FEEDBACKS_FOR_FIELD } from "../../../vuex-store/modules/feedbacks.js";
import { GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP } from "../../../vuex-store/modules/audit_history.js";
import { GET_LAST_REVIEW_COMPLETED_FOR_SECTION } from "../../../vuex-store/modules/reviews.js";
import { GET_IS_EMPOWERRD, GET_USER_ROLE } from "../../../vuex-store/modules/users";
import { VALIDATE,
  GET_VALIDATION_MESSAGES_FOR_SECTION,
  GET_ANY_VALIDATIONS_FOR_SECTION,
  GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_SECTION } from "../../../vuex-store/modules/validations.js";

import { GET_EDIT_STATE, FETCH_STATES_FOR_SECTION } from "../../../vuex-store/modules/states.js";
import { FETCH_DATA } from "../../../vuex-store/modules/project_selection.js";

import CostTableDisplayField from "./cost_table_display_field.vue";
import CostTableEditField from "./cost_table_edit_field.vue";
import CmsContentSlot from "../../generic/cms_content_slot.vue";
import Button from "../../generic/simple_button.vue";
import PreviousResolvedFeedbackIcon from "../../generic/previous_resolved_feedback_icon.vue";
import ButtonComponent from "../../generic/button_component.vue";
import FadeTransition from "../transitions/fade.vue";
import useEventsBus from "../../../shared/eventBus.js";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";

const { emit } = useEventsBus();

export default {
  components: {
    CostTableDisplayField,
    CostTableEditField,
    CmsContentSlot,
    Tippy,
    Button,
    AutoCostFeedbackIcon,
    SectionFeedback,
    CannedFeedbackIcon,
    LeaveFeedbackIcon,
    SubcostHistorical,
    PreviousResolvedFeedbackIcon,
    AuditHistoryTrigger,
    ButtonComponent,
    OverseasSubcontractorJustification,
    FadeTransition,
    KnowledgeCentreTriggerIcon,
    NotificationComponent
  },
  props: {
    costType: {
      type: String,
      required: true,
      validator: value => {
        const validSections = [
          "CostsSubcontractor",
          "CostsEmployee",
          "CostsOther",
          "CostsConsumable"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    showAddRd: {
      type: Boolean,
      default: false
    },
    externalFunding: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      required: true
    },
    userRole: {
      type: String,
      required: true
    },
    userSlug: {
      type: String,
      required: true
    },
    claimPeriodSlug: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const errorTooltips = ref({});
    const { bus } = useEventsBus();

    watch(() => bus.value.get("showSubcostErrors"), val => {
      const [subcostSlug] = val ?? [];

      errorTooltips.value[subcostSlug]?.show();
    });

    watch(() => bus.value.get("hideSubcostErrors"), val => {
      const [subcostSlug] = val ?? [];
      errorTooltips.value[subcostSlug]?.hide();
    });

    function trigger(subcostSlug, faq) {
      nextTick(() => {
        errorTooltips.value[subcostSlug]?.hide();
        setTimeout(() => { triggerKnowledgeCentre({ slug: faq }); }, 250);
      });
    }

    return { errorTooltips, trigger };
  },
  data() {
    return {
      theme: css,
      strapiContentIds,
      nFormatter,
      _,
      editModeAll: false,
      subCostEditableStatus: {},
      slugsToDelete: [],
      allSelected: false,
      tableNeedsScroll: false
    };
  },
  computed: {
    ...mapGetters({
      getSubCosts: `costs/${GET_SUBCOSTS}`,
      getCalculatedTotal: `costs/${GET_CALCULATED_TOTAL}`,
      getSubCostValidationErrors: `costs/${GET_SUBCOST_VALIDATION_ERRORS}`,
      getAutoFeedback: `costs/${GET_AUTO_FEEDBACK}`,
      getGlobalError: `costs/${GET_GLOBAL_ERROR}`,
      getUnresolvedFeedbacksForField: `feedbacks/${GET_FEEDBACKS_FOR_FIELD}`,
      isEmpowerrd: `users/${GET_IS_EMPOWERRD}`,
      getUserRole: `users/${GET_USER_ROLE}`,
      auditsSinceReview: `audit_history/${GET_SECTION_AUDIT_HISTORY_SINCE_TIMESTAMP}`,
      lastReviewCompleted: `reviews/${GET_LAST_REVIEW_COMPLETED_FOR_SECTION}`,
      getAnyValidationsForSubcost: `validations/${GET_ANY_VALIDATIONS_FOR_SECTION}`,
      getValidationMessagesForSubcost: `validations/${GET_VALIDATION_MESSAGES_FOR_SECTION}`,
      getAnyActiveRecordValidationsForSection: `validations/${GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_SECTION}`,
      getUnsavedCosts: `costs/${GET_UNSAVED_COSTS}`,
      getAnyUnsavedCosts: `costs/${GET_ANY_UNSAVED_COSTS}`,
      getAllSubcostTypes: `costs/${GET_ALL_SUBCOST_TYPES}`,
      getSubcostTypes: `costs/${GET_SUBCOST_TYPES}`,
      getEditState: `states/${GET_EDIT_STATE}`,
      getCost: `costs/${GET_COST}`
    }),
    hasGlobalErrors() {
      return this.getGlobalError != null;
    },
    canEdit() {
      return this.getEditState;
    },
    costsToShow() {
      return this.getSubCosts(this.costType);
    },
    costsToShowBySlug() {
      return _.keyBy(this.costsToShow, "slug");
    },
    useLegacyGrantAllocation() {
      return this.$store.getters["feature_flags/GET_FEATURE_FLAG"]("legacy_grant_allocation");
    },
    getColumns() {
      const baseColumns = this.getBaseColumns;
      const fundedColsToAdd = this.getExternalFundingColumnsToAdd;
      const capitalisedIntangibleColumnsToAdd = capitalisedIntangibleColumns.filter(col => this.getCost?.[`show_${col.field}_column`]);

      return [...baseColumns, ...fundedColsToAdd, ...capitalisedIntangibleColumnsToAdd]
        .filter(col => !disallowedForRole[this.userRole].includes(col.field));
    },
    getBaseColumns() {
      const baseCols = costColumns[this.costType];

      if (this.showAddRd) {
        const additionalEmpRdColumns = this.isEmpowerrd ? allowedInRdEmpowerrd[this.costType] : [];
        const allowedColumns = [...allowedInRd, ...additionalEmpRdColumns];

        const allowedBaseColumns = baseCols.filter(col => allowedColumns.includes(col.field));
        const rdTablecolumns = [...allowedBaseColumns, ...pcRdColumn];

        conditionalRdColumns.forEach(col => {
          if (this.getCost?.[`show_${col.field}_column`]) rdTablecolumns.push(col);
        });

        return rdTablecolumns;
      } else {
        return baseCols;
      }
    },
    getExternalFundingColumnsToAdd() {
      if (this.externalFunding) {
        if (!this.isEmpowerrd && this.showAddRd) {
          return [];
        } else if (this.useLegacyGrantAllocation) {
          return fundedColumns;
        } else if (this.costType === "CostsSubcontractor") {
          return fundedColumns.filter(col => col.field === "grant_funded");
        }

        return [];
      } else {
        return [];
      }
    },
    blurbContentSlotId() {
      if (this.showAddRd) {
        return this.strapiContentIds.costs.rdTable[this.costType];
      }

      return this.strapiContentIds.costs.captureTable[this.costType];
    },
    showAutoFeedback() {
      return this.showAddRd === true && optionNotSet(this.options, "pc_rd_ready_for_review");
    },
    showReviewComponents() {
      return this.isEmpowerrd;
    },
    isReviewer() {
      return this.getUserRole === "Emp reviewer";
    },
    getCostSlugs() {
      return _.map(this.getSubCosts(this.costType), "slug");
    },
    subcostTypes() {
      return this.getSubcostTypes(this.costType);
    },
    allSubcostTypes() {
      return this.getAllSubcostTypes(this.costType);
    },
    showInternationalSubcontractorWork() {
      return featureEnabled(this.options, "feature_international_subcontractors");
    }
  },
  watch: {
    costsToShow(newData, oldCount) {
      // Find subcosts that should be editable by default and return their slugs
      const subCostsDefaultEditable = _
        .chain(newData)
        .filter(o => o["default_editable?"] == true)
        .map(o => o.slug)
        .value();

      const allSlugs = _.map(newData, "slug");

      // Work out which new slugs we are seeing
      const newSlugsToSet = _.difference(allSlugs, _.keys(this.subCostEditableStatus));

      const deltaOfSubCostEditableStatus = _.reduce(newSlugsToSet, (result, value, key) => {
        result[value] = subCostsDefaultEditable.indexOf(value) > -1;
        return result;
      }, {});

      this.subCostEditableStatus = _.assign(this.subCostEditableStatus, deltaOfSubCostEditableStatus);
      this.checkScroll();
    },
    slugsToDelete(_val) {
      if (_val.length === this.costsToShow.length && _val.length > 0) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    }
  },
  mounted() {
    this.fetchCosts({ costSlug: this.slug, subCostType: this.costType, rd: this.showAddRd });
    this.fetchHistoricals({ costSlug: this.slug });

    if (this.isEmpowerrd) {
      this.fetchCalculatedTotals({ costSlug: this.slug, subCostType: this.costType });
    }

    if (this.showAddRd) {
      this.fetchAutoFeedback({ costSlug: this.slug, subCostType: this.costType });

      if (this.getCost.show_rd_projects_column && this.getCost.project_selection_slug) {
        this.fetchRdProjects({ slug: this.getCost.project_selection_slug });
      }
    }

    const { bus } = useEventsBus();

    watch(() => bus.value.get("reValidateOtherTypes"), val => {
      const [type] = val ?? [];
      if (type !== this.costType) {
        this.reValidateUnsaved();
      }
    });
  },
  methods: {
    ...mapActions({
      fetchCosts: `costs/${FETCH_SUBCOSTS}`,
      fetchAutoFeedback: `costs/${FETCH_AUTO_FEEDBACK}`,
      createSubCost: `costs/${CREATE_SUBCOST}`,
      deleteSubCosts: `costs/${DELETE_SUBCOSTS}`,
      fetchHistoricals: `costs/${FETCH_HISTORICALS}`,
      fetchCalculatedTotals: `costs/${FETCH_CALCULATED_TOTALS}`,
      fetchClaimFeedback: `feedbacks/${FETCH_CLAIM_FEEDBACK}`,
      validate: `validations/${VALIDATE}`,
      fetchStatesForSection: `states/${FETCH_STATES_FOR_SECTION}`,
      fetchRdProjects: `project_selection/${FETCH_DATA}`,
      updateQDA: `costs/${FETCH_QDA}`
    }),
    ...mapMutations({
      setSavedCost: `costs/${SET_SAVED_COST}`
    }),
    requiredField(col) {
      if (col === "uk_based" && this.costType == "CostsSubcontractor" && this.getCost?.claim_post_legislation_change) {
        return true;
      }

      return requiredFields[this.costType]?.includes(col);
    },
    checkScroll() {
      const parentWidth = this.$refs.tableParent.offsetWidth;
      const childWidth = this.$refs.table.offsetWidth;

      this.tableNeedsScroll = childWidth > parentWidth;
    },
    confirmDelete(costType, slugs) {
      const slugCount = slugs.length;
      const message = `"Are you sure you want to delete ${slugCount > 1 ? "these" : "this"} ${pluralize(slugCount, "cost")}?"`;

      if (confirm(message)) {
        this.deleteSubCosts({ costSlug: this.slug, subCostType: costType, subcostSlugs: slugs, rd: this.showAddRd }).then(() => {
          this.validate(["Cost", this.slug]);
          this.reValidateUnsaved();
          emit("reValidateOtherTypes", this.costType);
          this.fetchClaimFeedback({ force: true });
          this.fetchStatesForSection({ sectionType: "Cost", sectionSlug: this.slug, rd: this.showAddRd });
          this.slugsToDelete = [];
          this.allSelected = false;
        });
      }
    },
    async createSubcost(payload) {
      await this.createSubCost(payload);
      await this.validate(["Cost", this.slug]);
      this.reValidateUnsaved();
      emit("reValidateOtherTypes", this.costType);
    },
    reValidateUnsaved() {
      if (this.getAnyUnsavedCosts) {
        _.forEach(this.getUnsavedCosts?.[this.costType], slug => {
          this.checkSubcostBeforeSave(this.costsToShowBySlug?.[slug]);
        });
      }
    },
    setEditModeSingle(subCost) {
      if (this.canEdit === false) { return; }
      this.subCostEditableStatus[subCost.slug] = true;
    },
    setEditModeAll(editable) {
      // Reassigns the data variable with everything set to true/false based on `editable`
      this.subCostEditableStatus = _.reduce(this.subCostEditableStatus, (result, _value, key) => {
        if (editable) {
          result[key] = editable;
        } else {
          const blockSave = this.getAnyActiveRecordValidationsForSection(this.costType, key);
          result[key] = blockSave ? true : editable;
        }
        return result;
      }, {});

      // Convenience helper for the UI knowing what start we are in
      this.editModeAll = editable;
    },
    closeSingleSelection(subcost) {
      this.checkSubcostBeforeSave(subcost).then(valid => {
        if (!valid) { return; }

        this.setSavedCost({ subCostType: this.costType, subcostSlug: subcost.slug });
        this.subCostEditableStatus[subcost.slug] = false;
      });
    },
    async checkSubcostBeforeSave(subcost) {
      const fieldsJson = _.reduce(this.getColumns, (result, col) => {
        const field = col?.database_field || col?.field;
        result[field] = subcost?.[col?.field];
        return result;
      }, {});

      await this.validate([this.costType, subcost.slug, fieldsJson]);

      if (this.getAnyActiveRecordValidationsForSection(this.costType, subcost.slug)) {
        this.errorTooltips[subcost.slug]?.show();
        return false;
      }

      await this.updateQDA({ costType: this.costType, costSlug: subcost.slug });

      return true;
    },
    showAsEditable(subCost) {
      if (this.canEdit === false) { return; }
      return this.subCostEditableStatus[subCost.slug] == true;
    },
    hasFeedback(type, slug, field) {
      if (featureEnabled(this.options, "feature_integrated_feedback") == false) {
        return false;
      }
      return this.getUnresolvedFeedbacksForField(type, slug, field) !== undefined;
    },
    filterAutoForSubcost(subCost) {
      return _.filter(this.getAutoFeedback(this.costType), o => o.slug == subCost.slug);
    },
    feedbackTextColor(feedback) {
      return feedback.resolve_activity_type === "Review/Accept" ? "tw-text-yellow-400" : "tw-text-rose-400";
    },
    feedbackBgColor(feedback) {
      return feedback.resolve_activity_type === "Review/Accept" ? "tw-bg-amber-50" : "tw-bg-rose-50";
    },
    selectAllCosts() {
      this.allSelected = !this.allSelected;

      if (this.allSelected) {
        this.slugsToDelete = this.getCostSlugs;
      } else {
        this.slugsToDelete = [];
      }
    },
    costBgColour(subcost) {
      const subcostChangesSinceLastReview = _.chain(this.auditsSinceReview(this.costType, subcost?.slug, this.lastReviewCompleted?.completed_at))
         .value();

      if (["emp_reviewer", "emp_admin"].includes(this.userRole) && subcostChangesSinceLastReview?.length > 0) {
        return "tw-bg-blue-50 hover-trigger";
      } else if (this.getAnyValidationsForSubcost(this.costType, subcost?.slug && this.showAsEditable(subcost))) {
        return "tw-bg-error-red-100";
      } else {
        return "hover:tw-bg-gray-100 odd:tw-bg-white even:tw-bg-gray-50 hover-trigger";
      }
    },
    dropdownColLabel(field_value) {
      if (this.allSubcostTypes != null && field_value != null) {
        return _.filter(this.allSubcostTypes, type => type.id == field_value)[0].label;
      }
    },
    columnOptions(col) {
      return this.getCost?.column_options?.[col.field];
    },
    conditionalRdCol(field) {
      return _.map(conditionalRdColumns, "field").includes(field);
    },
    shouldHide(col, subcost) {
      return col.field === "intangible" && !subcost.capitalised;
    },
    dropdownDisabledMessage(col, subcost) {
      if (!col.can_be_disabled) return;

      if (!this.getCost.project_selection_completed && col.field === "rd_projects") {
        return "You need to complete the Project Selection to select R&D Projects for this cost";
      }

      if (subcost.pc_rd === null || subcost.pc_rd == 0) {
        return `You only need to select ${col.label} for costs with an R&D %, add an R&D % for this cost to unlock this column`;
      }
    }
  }
};
</script>
