import { validate } from "../../shared/validation.js";
import { get } from "../../shared/http.js";

export const SET_VALIDATION_MESSAGES_AND_FIELDS = "SET_VALIDATION_MESSAGES_AND_FIELDS";
export const GET_ALL_VALIDATION_MESSAGES = "GET_ALL_VALIDATION_MESSAGES";
export const GET_ANY_VALIDATION_MESSAGES = "GET_ANY_VALIDATION_MESSAGES";
export const GET_READY_TO_SEND_FOR_REVIEW = "GET_READY_TO_SEND_FOR_REVIEW";
export const GET_VALIDATIONS = "GET_VALIDATIONS";
export const GET_VALID_FIELDS = "GET_VALID_FIELDS";
export const GET_ALL_FIELDS_VALID = "GET_ALL_FIELDS_VALID";
export const GET_FIELDS_BEING_VALIDATED = "GET_FIELDS_BEING_VALIDATED";
export const GET_VALIDATION_MESSAGES_FOR_FIELD = "GET_VALIDATION_MESSAGES_FOR_FIELD";
export const GET_ANY_VALIDATION_MESSAGES_FOR_FIELD = "GET_ANY_VALIDATION_MESSAGES_FOR_FIELD";
export const GET_ANY_VALIDATION_MESSAGES_FOR_FIELDS = "GET_ANY_VALIDATION_MESSAGES_FOR_FIELDS";
export const GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_SECTION = "GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_SECTION";
export const GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_FIELDS = "GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_FIELDS";
export const GET_LINKED_VALIDATIONS_VALID = "GET_LINKED_VALIDATIONS_VALID";
export const GET_ANY_LINKED_VALIDATIONS = "GET_ANY_LINKED_VALIDATIONS";
export const GET_ANY_VALIDATIONS_FOR_SECTION_TYPE = "GET_ANY_VALIDATIONS_FOR_SECTION_TYPE";
export const GET_VALIDATION_MESSAGES_FOR_SECTION = "GET_VALIDATION_MESSAGES_FOR_SECTION";
export const GET_ANY_VALIDATIONS_FOR_SECTION = "GET_ANY_VALIDATIONS_FOR_SECTION";
export const GET_VALIDATIONS_FOR_VALIDATION_TYPE = "GET_VALIDATIONS_FOR_VALIDATION_TYPE";
export const GET_ANY_VALIDATIONS_FOR_VALIDATION_TYPE = "GET_ANY_VALIDATIONS_FOR_VALIDATION_TYPE";
export const GET_VALIDATIONS_FOR_SECTION_TYPE = "GET_VALIDATIONS_FOR_SECTION_TYPE";
export const GET_SECTION_VALID = "GET_SECTION_VALID";

export const GET_LINKED_VALIDATIONS = "GET_LINKED_VALIDATIONS";
export const SET_VALID_FIELDS = "SET_VALID_FIELDS";
export const SET_VALIDATION_PARAMS = "SET_VALIDATION_PARAMS";
export const SET_LOADING = "SET_LOADING";
export const SET_FIELDS_BEING_VALIDATED = "SET_FIELDS_BEING_VALIDATED";
export const VALIDATE = "VALIDATE";
export const FETCH_LINKED_VALIDATIONS = "FETCH_LINKED_VALIDATIONS";
export const GET_LOADING = "GET_LOADING";

export const validations = {
  namespaced: true,
  state: {
    messages: {},
    validFields: [],
    validations: null,
    fieldsBeingValidated: [],
    linkedValidations: null,
    validationParams: {},
    loading: false
  },
  mutations: {
    [SET_LOADING]: (state, payload) => state.loading = payload,
    setValidationMessages: (state, payload) => (state.messages = payload),
    setValidFields: (state, payload) => state.validFields = payload,
    [SET_FIELDS_BEING_VALIDATED]: (state, payload) => (state.fieldsBeingValidated = payload),
    setValidations: (state, payload) => (state.validations = payload),
    setLinkedValidations: (state, payload) => (state.linkedValidations = payload),
    [SET_VALIDATION_PARAMS]: (state, payload) => (state.validation_params = { ...state.validation_params, ...payload })
  },
  actions: {
    [SET_VALIDATION_MESSAGES_AND_FIELDS]: async ({ commit, state }, payload) => {
      const valMessages = _.cloneDeep(state.messages);
      let valFields = _.cloneDeep(state.validFields);
      const validations = _.cloneDeep(state.validations);
      const { response } = payload;

      const anyMissingRdError = _.some(validations?.missing_some_pc_rd, v => v.subcost_type === response.type);

      if (anyMissingRdError && payload.fields?.pc_rd >= 0) {
        delete valMessages[response.type];
        _.remove(validations.missing_some_pc_rd, v => v.subcost_type === response.type);
      }

      _.forEach(response.col_refs, col_ref => {
        const fieldId = `${response.type}:${response.slug}:${col_ref}`;
        let fieldValid = null;

        if (response.type.includes("Cost")) {
          const anyFieldValidation = _.chain(response.validations)
                                    .omit(["valid", "type"])
                                    .values()
                                    .flatten()
                                    .map(v => `${v.section_type}:${v.section_slug}:${v.field}`)
                                    .some(key => key === fieldId)
                                    .value();

          fieldValid = !anyFieldValidation;
        } else {
          fieldValid = (response.valid_fields ?? []).indexOf(col_ref) > -1;
        }

        _.forEach(response.validation_types, type => {
          if (fieldValid) {
            _.remove(validations[type], v => v?.field == col_ref && v?.section_slug == response.slug);
          } else if ((response.validations[type] ?? []).length > 0) {
            validations[type] = _.unionWith(validations[type], response.validations[type], _.isEqual);
          }
        });
        validations.valid = _.every(response.validation_types, type => _.isEmpty(validations[type]));

        if (fieldValid) {
          delete valMessages[fieldId];
          valFields?.push(col_ref);
          valFields = _.uniq(valFields);
        } else if ((response.validation_messages[fieldId] ?? []).length > 0) {
          valMessages[fieldId] = response.validation_messages[fieldId];
          _.pull(valFields, col_ref);
        } else {
          _.pull(valFields, col_ref);
        }
      });

      commit("setValidFields", valFields);
      commit("setValidationMessages", valMessages);
      commit("setValidations", validations);
    },
    [VALIDATE]: async ({ commit, dispatch, state, getters, rootState }, payload) => {
      const [sectionType, sectionSlug, fields, validate_full] = payload;
      const validation_params = { ...state.validation_params, validate_full: validate_full ?? false };

      if (fields) {
        commit(SET_FIELDS_BEING_VALIDATED, _.map(Object.keys(fields), k => `${sectionType}:${sectionSlug}:${k}`));
      }

      if (sectionType.includes("Cost") && getters.GET_ANY_VALIDATION_MESSAGES
        && getters.GET_ANY_VALIDATIONS_FOR_VALIDATION_TYPE("missing_fields")
        && !getters.GET_ANY_VALIDATIONS_FOR_VALIDATION_TYPE("active_record")
      ) {
        validation_params.validate_full = true;
      }

      const response = await validate(sectionType, sectionSlug, fields, validation_params);

      if (["Cost", "EpwContractReview", "ProjectSelection"].includes(response?.type) || (_.isEmpty(state.messages) && _.isEmpty(state.validFields) && state.validations === null)) {
        commit("setValidFields", response.valid_fields);
        commit("setValidationMessages", response.validation_messages);
        commit("setValidations", response.validations);
      } else {
        await dispatch(SET_VALIDATION_MESSAGES_AND_FIELDS, { response, fields });
      }

      commit(SET_LOADING, false);

      if (validate_full) {
        commit("fields/REMOVE_ALL_EDITED_FIELDS", null, { root: true });
      } else {
        let key = `${sectionType}:${sectionSlug}`;
        if (fields) key += `:${Object.keys(fields)[0]}`;
        commit("fields/REMOVE_FROM_EDITED_FIELDS", key, { root: true });
      }

      return response;
    },
    [FETCH_LINKED_VALIDATIONS]: async ({ commit, state }, payload) => {
      if (state.linkedValidations === null) {
        get(`/api/forms/validate_linked_sections/${payload.sectionType}/${payload.sectionSlug}`)
          .then(data => {
            commit("setLinkedValidations", data);
          });
      }
    }
  },
  getters: {
    [GET_LOADING]: state => state.loading,
    [GET_FIELDS_BEING_VALIDATED]: state => state.fieldsBeingValidated,
    [GET_ALL_VALIDATION_MESSAGES]: state => state.messages,
    [GET_VALID_FIELDS]: state => state.validFields,
    [GET_ALL_FIELDS_VALID]: state => fields => _.every(fields, f => state.validFields.includes(f)),
    [GET_VALIDATIONS]: state => state.validations,
    [GET_SECTION_VALID]: state => state.validations?.valid,
    [GET_LINKED_VALIDATIONS]: state => state.linkedValidations,
    [GET_ANY_LINKED_VALIDATIONS]: state => state.linkedValidations !== null && _.size(state.linkedValidations) > 0,
    [GET_LINKED_VALIDATIONS_VALID]: state => {
      if (state.linkedValidations === null || _.size(state.linkedValidations) === 0) {
        return false;
      }
      return _.chain(state.linkedValidations)
        .values()
        .every("valid")
        .value();
    },
    [GET_ANY_VALIDATION_MESSAGES]: state => _.size(state.messages) > 0,
    [GET_VALIDATIONS_FOR_VALIDATION_TYPE]: state => validationType => state.validations?.[validationType],
    [GET_ANY_VALIDATIONS_FOR_VALIDATION_TYPE]: state => validationType => state.validations?.[validationType]?.length > 0,
    [GET_VALIDATION_MESSAGES_FOR_FIELD]: state => (sectionType, sectionSlug, field) => {
      const key = `${sectionType}:${sectionSlug}:${field}`;

      return _.get(state.messages, key) ?? [];
    },
    [GET_ANY_VALIDATION_MESSAGES_FOR_FIELD]: state => (sectionType, sectionSlug, field) => {
      const key = `${sectionType}:${sectionSlug}:${field}`;

      return (_.get(state.messages, key) ?? [])?.length > 0;
    },
    [GET_VALIDATION_MESSAGES_FOR_SECTION]: (state, getters) => (sectionType, sectionSlug, groupMissing = null) => {
      if (getters.GET_ANY_VALIDATION_MESSAGES === false) {
        return false;
      }

      let sectionMessages = _.chain(state.messages)
        .pickBy((v, k) => k.startsWith(`${sectionType}:${sectionSlug}`))
        .values()
        .flatten()
        .uniq()
        .value();

      if (groupMissing) {
        const [missing, others] = _.partition(sectionMessages, m => m.startsWith("Please specify") || m.startsWith("Please fill this answer"));
        if (missing.length > 1 && sectionType.includes("Cost")) {
          sectionMessages = _.concat(others, "Please provide missing required information for this cost.");
        }
      }
      return sectionMessages;
    },
    [GET_ANY_VALIDATIONS_FOR_SECTION]: (state, getters) => (sectionType, sectionSlug) => {
      if (getters.GET_ANY_VALIDATION_MESSAGES === false) {
        return false;
      }
      return _.chain(state.messages)
        .keys()
        .some(k => k.startsWith(`${sectionType}:${sectionSlug}:`))
        .value();
    },
    [GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_SECTION]: state => (sectionType, sectionSlug) => _.chain(state.validations)
        .get("active_record")
        .some(v => v.section_type === sectionType && v.section_slug === sectionSlug)
        .value(),
    [GET_ANY_ACTIVE_RECORD_VALIDATIONS_FOR_FIELDS]: state => fields => _.chain(state.validations)
        .get("active_record")
        .some(v => fields.includes(v.field))
        .value(),
    [GET_ANY_VALIDATIONS_FOR_SECTION_TYPE]: (state, getters) => sectionType => {
      if (getters.GET_ANY_VALIDATION_MESSAGES === false) {
        return false;
      }
      return _.chain(state.messages)
        .keys()
        .some(k => k.startsWith(`${sectionType}`))
        .value();
    },
    [GET_ANY_VALIDATION_MESSAGES_FOR_FIELDS]: (state, getters) => (sectionType, sectionSlug, fields) => {
      if (getters.GET_ANY_VALIDATION_MESSAGES === false) {
        return false;
      }

      return _.chain(fields)
        .map(f => `${sectionType}:${sectionSlug}:${f}`)
        .some(k => (_.get(state.messages, k) ?? [])?.length > 0)
        .value();
    },
    [GET_READY_TO_SEND_FOR_REVIEW]: (state, getters) => (sectionType, newProject = false) => {
      if (["Project", "Challenge"].includes(sectionType) && !newProject && !getters.GET_LINKED_VALIDATIONS_VALID) {
        return false;
      }

      return state.validations?.valid;
    },
    [GET_VALIDATIONS_FOR_SECTION_TYPE]: state => sectionType => _.chain(state.validations)
      .values()
      .flatten()
      .pickBy(v => (v.subcost_type || v.section_type) === sectionType)
      .values()
      .value()
  }
};
