<template>
  <div class="tw-flex tw-items-center tw-gap-6">
    <div class="tw-w-3/5 tw-flex-none">
      <SliderComponent
        :id="slug"
        ref="slider"
        :on-lazy-change="validate"
        :show-spinner="!disabled"
        side-buttons
        :interval="5"
        tooltip="always"
        lazy
        :local-max="localMax"
        :value="model"
        :store-action="{
          action: 'commit',
          name: 'project_selection/SET_EXPENDITURE',
        }"
        adsorb
        :tooltip-formatter="'{value}%'"
        :disabled="disabled"
        :group-percentage="expendituresTotal"
      />
    </div>
    <div class="tw-pl-8 tw-flex-shrink">
      <state-wrapper component="ValidationMessage">
        <ValidationMessage section-type="RdProject" :section-slug="slug" :field="{ label: 'percent_expenditure' }" />
      </state-wrapper>
    </div>
    <tooltip
      v-if="disabled && invalid"
      content="Based on your answers in the Validation step we've deemed this project is unlikely to qualify for R&D"
    >
      <div class="tw-flex tw-gap-2 tw-items-center tw-text-sm">
        <i class="far fa-exclamation-triangle fa-lg tw-text-error-red-750" />
        Not a valid R&D Project
      </div>
    </tooltip>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import SliderComponent from "../../../generic/slider_component.vue";
import ValidationMessage from "../../dynamic_forms/validation_message.vue";

export default {
  components: {
    SliderComponent,
    ValidationMessage
  },
  props: {
    model: {
      required: true,
      type: Number,
      default: 0
    },
    slug: {
      required: true,
      type: String
    },
    projectSelectionSlug: {
      required: true,
      type: String
    },
    editable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const slider = ref();

    const rdProject = computed(() => store.getters["project_selection/GET_RD_PROJECT_BY_SLUG"](props.slug));
    const invalid = computed(() => store.getters["project_selection/GET_RD_PROJECT_INVALID"](props.slug));
    const disabled = computed(() => !props.editable || invalid.value);
    const expendituresTotal = computed(() => store.getters["project_selection/GET_EXPENDITURES_TOTAL"]);
    const localMax = ref(100 - expendituresTotal.value + props.model);

    async function validate() {
      await store.dispatch("validations/VALIDATE", ["RdProject", props.slug, { percent_expenditure: slider.value.getValue() }]);

      store.dispatch("project_selection/FETCH_DATA", { slug: props.projectSelectionSlug });
      store.dispatch("validations/VALIDATE", ["ProjectSelection", props.projectSelectionSlug]);
    }

    watch(() => expendituresTotal.value, val => {
      const newLocal = 100 - val + slider.value.getValue();
      localMax.value = newLocal > 0 ? newLocal : 0;
    });

    return {
      slider,
      rdProject,
      disabled,
      validate,
      localMax,
      expendituresTotal,
      invalid
    };
  }
};
</script>
