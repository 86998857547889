<template>
  <div
    v-if="show && (text || title || $slots.default || $slots.content)"
    :id="id"
    class="tw-p-4"
    :class="[
      theme[themeType].bg,
      $slots.default ? theme[themeType].text : '',
      noBorder ? 'tw-rounded-md' : theme[themeType].border
    ]"
  >
    <slot>
      <div class="tw-flex tw-gap-3 tw-items-stretch">
        <div v-if="!noIcon" class="tw-flex-none tw-text-sm tw-text-center">
          <i :class="theme[themeType].icon" class="tw-h-5 tw-w-5" />
        </div>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-3 tw-text-sm" :class="theme[themeType].text">
          <div v-if="title" class="tw-font-semibold">
            {{ title }}
          </div>
          <div v-if="text" v-html="text" />
          <slot name="content" />
          <div v-if="links" class="tw-flex tw-gap-3 tw--mx-2 tw--mb-1.5 tw-font-medium">
            <template v-for="(link, idx) in links" :key="idx">
              <button-component
                v-if="link.text"
                :id="link.id ? `banner-link-${link.id}` : null"
                :colour="themeType"
                :text="link.text"
                :link="link.url"
                type="light"
              />
            </template>
          </div>
        </div>
        <div v-if="dismiss" class="tw-flex-none">
          <div class="tw--mx-1.5 tw--my-1.5" @click="dismissBanner">
            <button-component
              :id="id ? `${id}-dismiss-button` : null"
              icon="times"
              :colour="themeType"
              type="light"
              size="small"
            />
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { reactive, ref, watch, computed } from "vue";
import { css } from "../../shared/theme.js";
import useEventsBus from "../../shared/eventBus.js";

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      validator: value => {
        const validTypes = ["error", "warning", "confirm", "cta", "disabled"];
        return validTypes.indexOf(value) >= 0;
      }
    },
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    links: {
      type: Object,
      required: false
    },
    dismiss: {
      type: Boolean,
      required: false,
      default: false
    },
    dismissApiCall: {
      type: Object,
      required: false
    },
    noIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const theme = reactive(css.alert);
    const show = ref(true);
    const { bus } = useEventsBus();

    function hideBanner() {
      show.value = false;
    }

    const themeType = computed(() => {
      if (props.type) return props.type;
      if (props.text?.includes("save") || props.text?.includes("success")) return "confirm";

      return "error";
    });

    function dismissBanner() {
      if (props.dismissApiCall) {
        fetch(props.dismissApiCall.url, {
          method: props.dismissApiCall.method
        });
      }
      hideBanner();
    }

    watch(() => bus.value.get("dismissBanner"), () => {
      dismissBanner();
    });

    return {
      theme,
      show,
      dismissBanner,
      hideBanner,
      themeType
    };
  }
};
</script>
