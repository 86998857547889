import { get } from "../../shared/http.js";

// mutations
const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS";
const SET_LOADING = "SET_LOADING";

// actions
const FETCH_FEATURE_FLAGS = "FETCH_FEATURE_FLAGS";

// getters
const GET_ALL_FEATURE_FLAGS = "GET_ALL_FEATURE_FLAGS";
const GET_FEATURE_FLAG = "GET_FEATURE_FLAG";

export const feature_flags = {
  namespaced: true,
  state: {
    feature_flags: {},
    loading: false
  },
  mutations: {
    [SET_FEATURE_FLAGS]: (state, payload) => {
      state.feature_flags = payload;
    },
    [SET_LOADING]: (state, payload) => {
      state.loading = payload;
    }
  },
  actions: {
    [FETCH_FEATURE_FLAGS]: async ({ commit, state }, payload) => {
      if (!state.loading && _.isEmpty(state.feature_flags)) {
        commit(SET_LOADING, true);
        const featureFlags = await get(`/api/feature_flags?claim_period_slug=${payload}`);
        commit(SET_FEATURE_FLAGS, featureFlags);
        commit(SET_LOADING, false);
      }
    }
  },
  getters: {
    [GET_ALL_FEATURE_FLAGS]: state => state.feature_flags,
    [GET_FEATURE_FLAG]: state => flag => state.feature_flags[flag]
  }
};
