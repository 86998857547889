<template>
  <Tippy :content="tooltip">
    <div class="tw-cursor-pointer" :class="color">
      <i :class="icon" class="fas fa-1x" />
    </div>
  </Tippy>
</template>

<script>
import { Tippy } from "vue-tippy";

export default {
  components: {
    Tippy
  },
  props: {
    sectionType: {
      type: String,
      required: false
    },
    assigned: {
      type: Boolean,
      required: true
    },
    assignedToUser: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    color() {
      if (this.assignedToUser) {
        return "tw-text-green-600 hover:tw-text-green-700 tw-font-semibold";
      } else if (this.assigned) {
        return "tw-text-indigo-500 hover:tw-text-indigo-700";
      } else {
        return "tw-text-gray-500 hover:tw-text-gray-900";
      }
    },
    icon() {
      if (this.assigned) {
        return "fa-user tw-pr-1";
      } else {
        return "fa-user-plus";
      }
    },
    tooltip() {
      if (this.assignedToUser) {
        return "This question has been assigned to you, click to re-assign or assign others";
      } else if (this.assigned) {
        return "Click to re-assign or assign others to this question";
      } else {
        return "Click here to assign this question to someone in your company";
      }
    }
  }
};
</script>
