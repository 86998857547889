<template>
  <Tippy v-if="hasCannedFeedback && enabled" :interactive="true" theme="light">
    <slot name="toggle">
      <div class="tw-cursor-pointer tw-text-gray-600 hover:tw-text-gray-900 tw-rounded-full tw-bg-blue-200 tw-inline-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
        <i class="far fa-bolt fa-lg" />
      </div>
    </slot>
    <template #content="{ hide }">
      <slot :hide="hide">
        <div class="tw-flex-col tw-flex tw-gap-2 tw-p-2 tw-justify-start tw-font-normal">
          <div class="tw-text-sm tw-font-bold tw-text-left">
            Leave Canned Feedback
          </div>
          <div v-for="(feedback, idx) in cannedFeedback" :key="idx">
            <div
              class="tw-font-medium tw-text-left tw-cursor-pointer tw-text-sm tw-text-gray-600 hover:tw-text-gray-900 hover:tw-underline"
              @click.stop="existingFeedback ? updateFeedback(feedback) : createFeedback(feedback)"
            >
              <i :class="[feedbackLevelIcon(feedbackLevel(feedback)), 'fas fa-md tw-mr-1']" /> {{ feedback.message }}
            </div>
          </div>
        </div>
      </slot>
    </template>
  </Tippy>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch, ref, onMounted } from "vue";
import { Tippy } from "vue-tippy";

export default {
  components: {
    Tippy
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    existingFeedback: {
      type: Object,
      required: false
    },
    cannedFeedback: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      default: {}
    },
    sectionSlug: {
      type: String,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    userSlug: {
      type: String,
      required: true
    }

  },
  setup(props) {
    const store = useStore();
    const enabled = ref(false);

    const hasCannedFeedback = computed(() => props.cannedFeedback.length > 0 ?? undefined);

    const sectionReview = computed(() => store.getters["reviews/GET_REVIEW_FOR_SECTION"]);

    onMounted(() => {
      setEnabledState();
    });

    watch(sectionReview, newReview => {
      setEnabledState();
    });

    function feedbackLevel(feedback) {
      return (feedback.data || {}).feedback_level;
    }

    function resolveActivityType(feedback) {
      const level = feedbackLevel(feedback);
      switch (level) {
        case "unacceptable":
          return "Changes Needed";
        default:
          return "Review/Accept";
      }
    }

    function feedbackLevelIcon(level) {
      switch (level) {
        case "unacceptable":
          return "fa-do-not-enter tw-text-red-600";
        default:
          return "fa-exclamation-triangle tw-text-yellow-400";
      }
    }

    function createFeedback(feedback) {
      store.dispatch("feedbacks/CREATE_FEEDBACK", {
        fields: {
          col_ref: feedback.column,
          feedback: feedback.message,
          review_id: sectionReview.value.id,
          resolve_activity_type: resolveActivityType(feedback),
          feedbacked_value: props.field.pc_rd
        },
        feedbackable_type: feedback.type,
        feedbackable_slug: feedback.slug,
        claim_period_slug: props.options.claimPeriodSlug
      });
    }
    function updateFeedback(feedback) {
      store.dispatch("feedbacks/UPDATE_FEEDBACK", {
        fields: {
          feedback: feedback.message,
          feedbacked_value: props.field.pc_rd,
          review_id: sectionReview.value.id,
          resolve_activity_type: resolveActivityType(feedback)
        },
        feedbacked_value: props.field.pc_rd,
        feedbackable_type: feedback.type,
        feedbackable_slug: feedback.slug,
        append: false,
        claim_period_slug: props.options.claimPeriodSlug,
        id: props.existingFeedback.id
      });
    }

    function setEnabledState() {
      if (sectionReview.value !== undefined) {
        const isReviewAssignedToMe = sectionReview.value?.reviewer?.slug === props.userSlug;
        enabled.value = (sectionReview.value.started_at !== null && isReviewAssignedToMe == true);
      }
    }

    return {
      hasCannedFeedback,
      createFeedback,
      updateFeedback,
      feedbackLevel,
      feedbackLevelIcon,
      enabled
    };
  }
};
</script>
