import _ from "lodash";
import { get, post, patch, del } from "../../shared/http.js";

export const FETCH_CLAIM_FEEDBACK = "FETCH_CLAIM_FEEDBACK";

export const GET_ALL_FEEDBACKS = "GET_ALL_FEEDBACKS";
export const GET_ALL_FEEDBACKS_UNRESOLVED = "GET_ALL_FEEDBACKS_UNRESOLVED";
export const GET_FEEDBACKS_FOR_FIELD = "GET_FEEDBACKS_FOR_FIELD";
export const GET_RESOLVED_FEEDBACKS_FOR_FIELD = "GET_RESOLVED_FEEDBACKS_FOR_FIELD";
export const GET_FEEDBACKS_FOR_SECTION = "GET_FEEDBACKS_FOR_SECTION";
export const GET_UNRESOLVED_FEEDBACKS_FOR_SECTION = "GET_UNRESOLVED_FEEDBACKS_FOR_SECTION";
export const GET_UNRESOLVED_FEEDBACKS_COUNT = "GET_UNRESOLVED_FEEDBACKS_COUNT";
export const GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTION = "GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTION";
export const GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTIONS = "GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTIONS";
export const GET_REVIEW_FEEDBACKS = "GET_REVIEW_FEEDBACKS";
export const GET_WARNING_PROMPT = "GET_WARNING_PROMPT";
export const GET_LOADING = "GET_LOADING";

export const FETCH_ALL_CANNED_NARRATIVE_FEEDBACK = "FETCH_ALL_CANNED_NARRATIVE_FEEDBACK";
export const GET_CANNED_NARRATIVE_FEEDBACK_FOR_QUESTION = "GET_CANNED_NARRATIVE_FEEDBACK_FOR_QUESTION";

export const GET_CLAIM_PERIOD_SLUG = "GET_CLAIM_PERIOD_SLUG";
export const SET_CLAIM_PERIOD_SLUG = "SET_CLAIM_PERIOD_SLUG";
export const SET_LOADING = "SET_LOADING";

export const RESOLVE_FEEDBACK = "RESOLVE_FEEDBACK";
export const CREATE_FEEDBACK = "CREATE_FEEDBACK";
export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const DELETE_FEEDBACK = "DELETE_FEEDBACK";

export const feedbacks = {
  namespaced: true,
  state: {
    feedbacks: null,
    claimPeriodSlug: null,
    loading: false,
    warningPrompt: false,
    loadingCannedNarrativeFeedback: false,
    cannedNarrativeFeedback: null
  },
  mutations: {
    setLoading: (state, payload) => state.loading = payload,
    setLoadingCannedNarrativeFeedback: (state, payload) => state.loadingCannedNarrativeFeedback = payload,
    setAllFeedbacks: (state, payload) => state.feedbacks = payload,
    setWarningPrompt: (state, payload) => state.warningPrompt = payload,
    setCannedNarrativeFeedback: (state, payload) => {
      state.cannedNarrativeFeedback = payload;
    },
    [SET_CLAIM_PERIOD_SLUG]: (state, payload) => {
      state.claimPeriodSlug = payload;
    }
  },
  actions: {
    [FETCH_CLAIM_FEEDBACK]: async ({ commit, getters, state }, payload) => {
      const keys = _.keys(getters.GET_ALL_FEEDBACKS ?? {});

      if (state.loading == true) { return; }

      if (keys.length === 0 || payload.response !== undefined || payload.force == true) {
        if (state.feedbacks === null) {
          commit("setLoading", true);
        }
        const response = await get(`/api/feedbacks/get_all_feedbacks/${payload.claimPeriodSlug || state.claimPeriodSlug}`);

        commit("setAllFeedbacks", response);
        commit("setLoading", false);

        return response;
      }
    },
    [FETCH_ALL_CANNED_NARRATIVE_FEEDBACK]: async ({ commit, getters, state }, payload) => {
      if (state.cannedNarrativeFeedback === null && state.loadingCannedNarrativeFeedback == false) {
        commit("setLoadingCannedNarrativeFeedback", true);

        get(
          "/api/feedbacks/canned_narrative_feedback"
        ).then(data => {
          commit("setCannedNarrativeFeedback", data);
          commit("setLoadingCannedNarrativeFeedback", false);
        });
      }
    },
    [RESOLVE_FEEDBACK]: async ({ commit, getters }, payload) => {
      const feedbacks = getters.GET_ALL_FEEDBACKS[
          `${payload.sectionType}:${payload.sectionSlug}:${payload.field}`
      ];

      const feedback = _.filter(
        feedbacks,
        f => f.id === payload.feedbackId
      )[0];

      if (feedback.resolved === false) {
        const data = {
          feedbackableType: payload.sectionType,
          feedbackableId: payload.sectionSlug,
          colRefsAndIds: [[payload.field, payload.feedbackId]],
          colRefs: [payload.field]
        };

        const response = await post("/api/feedbacks/resolve_feedbacks", {
          feedbacks_json: data
        });

        const allFeedbacks = _.cloneDeep(getters.GET_ALL_FEEDBACKS);
        const feedbacksResponse = response.feedbacks;
        _.each(feedbacksResponse.col_refs_and_ids, colRefAndId => {
          const key = `${feedbacksResponse.section_type}:${feedbacksResponse.section_slug}:${colRefAndId[0]}`;
          _.each(allFeedbacks[key], f => {
            if (f.id === colRefAndId[1]) {
              if (Object.keys(feedbacksResponse.errors).length > 0) {
                f.error_state = true;
                f.error = feedbacksResponse.errors;
                return;
              }

              f.resolved = true;
            }
          });
        });
        commit("setAllFeedbacks", allFeedbacks);
        commit("setWarningPrompt", response.warning_prompt);

        return feedbacksResponse;
      }
    },
    [GET_WARNING_PROMPT]: async ({ commit, getters }, payload) => {
      const response = await get(`/api/feedbacks/get_warning_prompt/${payload.sectionType}/${payload.sectionSlug}`);

      if (response) commit("setWarningPrompt", response);

      return response;
    },
    [DELETE_FEEDBACK]: async ({ dispatch, state }, payload) => {
      del(`/api/feedbacks/${payload.id}`).then(data => {
        dispatch(FETCH_CLAIM_FEEDBACK, { force: true });
      });
    },
    [UPDATE_FEEDBACK]: async ({ commit, dispatch, state }, payload) => {
      if (!state.claimPeriodSlug && payload.claimPeriodSlug) {
        commit(SET_CLAIM_PERIOD_SLUG, payload.claimPeriodSlug);
      }
      patch(`/api/feedbacks/${payload.feedbackable_type}/${payload.feedbackable_slug}/${payload.id}`, payload).then(data => {
        if (data.error == true) {
          // Not sure how we want to process the error here
          console.log("There was an error, and unable to update feedback");
          console.log(data.response);
          return;
        }
        dispatch(FETCH_CLAIM_FEEDBACK, data);
      });
    },
    [CREATE_FEEDBACK]: async ({ commit, dispatch, state }, payload) => {
      if (!state.claimPeriodSlug && payload.claimPeriodSlug) {
        commit(SET_CLAIM_PERIOD_SLUG, payload.claimPeriodSlug);
      }
      post(`/api/feedbacks/${payload.feedbackable_type}/${payload.feedbackable_slug}`, payload).then(data => {
        if (data.error == true) {
          // Not sure how we want to process the error here
          console.log("There was an error, and unable to create feedback");
          console.log(data.response);
          return;
        }
        dispatch(FETCH_CLAIM_FEEDBACK, data);
      });
    }
  },
  getters: {
    [GET_LOADING]: state => state.loading,
    [GET_ALL_FEEDBACKS]: state => state.feedbacks,
    [GET_CANNED_NARRATIVE_FEEDBACK_FOR_QUESTION]: state => question => state.cannedNarrativeFeedback[question],
    [GET_WARNING_PROMPT]: state => state.warningPrompt,
    [GET_CLAIM_PERIOD_SLUG]: state => state.claimPeriodSlug,
    [GET_ALL_FEEDBACKS_UNRESOLVED]: state => _.chain(state.feedbacks)
        .values()
        .flatten()
        .filter(f => f.resolved === false)
        .value(),
    [GET_UNRESOLVED_FEEDBACKS_FOR_SECTION]: state => (type, slug) => _.chain(state.feedbacks)
        .values()
        .flatten()
        .filter(f => f.feedbackable_type === type && f.slug === slug && f.resolved !== true)
        .uniqBy(f => f.col_ref)
        .value(),
    [GET_UNRESOLVED_FEEDBACKS_COUNT]: state => (type, slug) => _.chain(state.feedbacks)
        .values()
        .flatten()
        .filter(f => f.feedbackable_type === type && f.slug === slug && f.resolved !== true)
        .uniqBy(f => f.col_ref)
        .size()
        .value(),
    [GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTION]: state => (type, slug) => _.chain(state.feedbacks)
        .values()
        .flatten()
        .some(f => {
          if (f.resolved === true) { return false; }
          if (type === "Cost") {
            return f.feedbackable_type.indexOf("Costs") > -1;
          } else if (type === "EpwContractReview") {
            return f.feedbackable_type === "EpwContract";
          } else {
            return f.feedbackable_type === type && f.slug === slug;
          }
        })
.value(),
    [GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTIONS]: state => (type, slugs) => _.chain(state.feedbacks)
        .values()
        .flatten()
        .some(f => f.feedbackable_type === type && slugs.indexOf(f.slug) > -1 && f.resolved !== true)
        .value(),
    [GET_FEEDBACKS_FOR_SECTION]: state => (type, slug) => _.chain(state.feedbacks)
        .values()
        .flatten()
        .filter(f => f.feedbackable_type === type && f.slug === slug)
        .value(),
    [GET_FEEDBACKS_FOR_FIELD]: state => (type, slug, field) => {
      if (field !== null || field !== undefined) {
        const key = `${type}:${slug}:${field}`;

        return _.chain(state.feedbacks)
          .get(key)
          .filter(f => f.resolved === false)
          .orderBy(["updated_at"], ["desc"])
          .value()[0];
      } else {
        return null;
      }
    },
    [GET_RESOLVED_FEEDBACKS_FOR_FIELD]: state => (type, slug, field) => {
      if (field !== null || field !== undefined) {
        const key = `${type}:${slug}:${field}`;

        return _.chain(state.feedbacks)
          .get(key)
          .filter(f => f.resolved === true)
          .orderBy(["updated_at"], ["desc"])
          .value()[0];
      } else {
        return null;
      }
    },
    [GET_REVIEW_FEEDBACKS]: state => review_ids => {
      if (review_ids?.length > 0) {
        return _.chain(state.feedbacks)
          .values()
          .flatten()
          .filter(f => review_ids.includes(f.review_id))
          .value();
      } else {
        return null;
      }
    }
  }
};
