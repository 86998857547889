<template>
  <TransitionRoot as="template" :show="open">
    <div tabindex="0" @keyup.esc="close()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 sm:scale-100"
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          id="modal-id"
          class="
            tw-overflow-x-hidden
            tw-overflow-y-auto
            tw-z-50
            tw-fixed
            tw-inset-0
            tw-outline-none
            focus:tw-outline-none
            tw-justify-center tw-items-center
          "
        >
          <div class="tw-relative tw-my-6 tw-mx-auto tw-w-1/2">
            <!--content-->
            <div
              class="
                tw-border-0
                tw-rounded-lg
                tw-shadow-lg
                tw-relative
                tw-flex
                tw-flex-col
                tw-w-full
                tw-bg-white
                tw-outline-none
                focus:tw-outline-none
                tw-px-8 tw-py-8 tw-divide-y tw-divide-gray-200
              "
            >
              <!--header-->
              <div class="tw-flex tw-items-start tw-justify-between">
                <h3 class="tw-text-xl tw-font-semibold">
                  <div
                    v-show="loading"
                    class="
                      tw-h-6
                      tw-rounded-sm
                      tw-bg-gray-200
                      tw-animate-pulse
                      tw-mb-4
                    "
                  />
                  <span v-show="!loading">Feedback for {{ titleToShow }}</span>
                </h3>
                <div class="tw-cursor-pointer" @click="close()">
                  <i class="fa fa-times fas tw-mx-2 color-heather tw-text-lg" />
                </div>
              </div>
              <!--body-->
              <div class="tw-overflow-y-scroll" style="height: 500px">
                <div v-show="errorState">
                  <p>
                    Unfortunately we had a problem loading that history; an
                    error has been logged and the team will be alerted
                  </p>
                </div>

                <div v-show="loading && !errorState" class="tw-px-2 tw-py-2">
                  <PulsingLoader />
                </div>

                <div v-show="!loading" class="tw-px-2 tw-py-2">
                  <div class="tw-relative tw-overflow-y-auto tw-prose tw-prose-sm">
                    <template v-for="(feedback, idx) in feedbacks" :key="idx">
                      <p class="tw-text-md tw-font-medium">
                        {{ feedback.created_at }}
                      </p>
                      <p>{{ feedback.comment }}</p>

                      <div v-show="feedback.resolved == true">
                        <p class="tw-italic">
                          This feedback has been resolved
                        </p>
                      </div>

                      <div v-show="feedback.resolved != true">
                        <SimpleButton icon="fa-check" text="Mark as Resolved" @click="resolve(feedback.id)" />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import PulsingLoader from "../../generic/loading_pulse_lines.vue";
import SimpleButton from "../../generic/simple_button.vue";
import { get, post } from "../../../shared/http.js";

export default {
  components: {
    PulsingLoader,
    TransitionChild,
    TransitionRoot,
    SimpleButton
  },
  setup() {
    const open = ref(false);

    return {
      open
    };
  },
  data() {
    return {
      loading: true,
      errorState: false,
      feedbacks: [],
      title: null,
      sectionType: null,
      sectionSlug: null,
      colRef: null
    };
  },
  computed: {
    titleToShow() {
      if (this.feedbacks[0] !== undefined) {
        return this.feedbacks[0].field_label;
      }
      return this.title;
    }
  },
  created() {
    window.addEventListener("feedbackModal", this.trigger);
    window.addEventListener("closeFeedbackModal", this.close);
  },
  methods: {
    close() {
      this.open = false;
    },
    trigger(event) {
      this.title = event.detail.title;
      this.sectionType = event.detail.sectionType;
      this.sectionSlug = event.detail.slug;
      this.colRef = event.detail.colRef;
      this.open = true;

      get(
        `/api/feedbacks/for_field/${event.detail.sectionType}/${event.detail.slug}/${event.detail.colRef}`
      ).then(data => {
        this.feedbacks = data;
        this.loading = false;
      });
    },
    resolve(feedback_id) {
      post(
        `/api/feedbacks/resolve/${this.sectionType}/${this.sectionSlug}/${this.colRef}/${feedback_id}`
      ).then(data => {
        this.close();
      });
    }
  }
};
</script>
