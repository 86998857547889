<template>
  <transition name="fade">
    <div v-if="anyValidationErrors" class="tw-mt-1">
      <template v-for="(message, idx) in validationMessages" :key="idx">
        <i class="fas fa-exclamation tw-text-red-500 tw-mr-2" />
        <span class="tw-text-red-500 tw-text-xs">
          {{ message }}
        </span>
      </template>
    </div>
  </transition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant", "RdProject", "ProjectSelection"];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true,
      validator: obj => (obj.label ?? "").length > 0
    }
  },
  setup(props) {
    const store = useStore();

    const anyValidationErrors = computed(() => store.getters["validations/GET_ANY_VALIDATION_MESSAGES_FOR_FIELD"](props.sectionType, props.sectionSlug, props.field.label));
    const validationMessages = computed(() => store.getters["validations/GET_VALIDATION_MESSAGES_FOR_FIELD"](props.sectionType, props.sectionSlug, props.field.label));

    return {
      anyValidationErrors,
      validationMessages
    };
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
