<template>
  <TransitionRoot as="template" :show="true">
    <div tabindex="0" @keyup.esc="close()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 sm:scale-100"
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          id="modal-id"
          class="
            tw-overflow-x-hidden
            tw-overflow-y-auto
            tw-fixed
            tw-inset-0
            tw-z-50
            tw-outline-none
            focus:tw-outline-none
            tw-justify-center tw-items-center
          "
        >
          <div class="tw-relative tw-my-6 tw-mx-auto tw-w-1/2">
            <!--content-->
            <div
              class="
                tw-border-0
                tw-rounded-lg
                tw-shadow-lg
                tw-relative
                tw-flex
                tw-flex-col
                tw-w-full
                tw-bg-white
                tw-outline-none
                focus:tw-outline-none
                tw-px-8 tw-py-8 tw-divide-y tw-divide-gray-200
              "
            >
              <!--header-->
              <div class="tw-flex tw-items-start tw-justify-between">
                <h3 class="tw-text-xl tw-font-semibold">
                  Search for a Claim period
                </h3>
                <div class="tw-cursor-pointer" @click="close()">
                  <i class="fa fa-times fas tw-mx-2 color-heather tw-text-lg" />
                </div>
              </div>
              <!--body-->
              <div
                :class="['tw-overflow-y-scroll', 'tw-relative  tw-flex-auto']"
              >
                <input
                  id="globalQuery"
                  v-model="query"
                  type="text"
                  :class="theme.forms.input"
                  placeholder="Global Claim Search..."
                  @focus="queryFocussed = true"
                  @blur="debounceLoseFocus()"
                  @keyup="debounceSearch()"
                  @keydown.escape="cancelSearch()"
                >

                <table v-show="results.length > 0" class="tw-w-full tw-text-sm tw-my-4">
                  <thead>
                    <tr>
                      <th :class="[theme.tables.th, 'tw-w-3/12']">
                        Company
                      </th>
                      <th :class="[theme.tables.th, 'tw-w-3/12']">
                        Period
                      </th>
                      <th :class="[theme.tables.th, 'tw-w-2/12']">
                        Value
                      </th>
                      <th :class="[theme.tables.th, 'tw-w-1/12']" />
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="claim in results" :key="claim.slug">
                      <tr>
                        <td :class="[theme.tables.td]">
                          {{ claim.company.name }}
                        </td>
                        <td :class="[theme.tables.td]">
                          {{ claim.title_short }}
                        </td>
                        <td :class="[theme.tables.td, 'tw-text-center']">
                          <Tippy
                            :content="expectedBenefitPopup(claim)"
                            :allow-h-t-m-l="true"
                            :interactive="true"
                          >
                            £<span
                              class="tw-font-medium"
                              v-text="nFormatter(claim._claim_value, 1)"
                            />
                          </Tippy>
                        </td>
                        <td>
                          <a :href="`/claim_benefits/find_or_create/${claim.slug}`" target="_blank">
                            <i class="fa fa-arrow-right fas" />
                          </a>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <!--footer-->
              <div
                class="
                  flex
                  items-center
                  justify-end
                  p-6
                  border-t border-solid border-blueGray-200
                  rounded-b
                "
              />
            </div>
          </div>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>

<script>
import { Tippy } from "vue-tippy";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { nFormatter } from "../../../../shared/helpers.js";
import { css } from "../../../../shared/theme.js";

export default {
  components: {
    Tippy,
    TransitionChild,
    TransitionRoot
  },
  props: {
    openProp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      open: false,
      errorState: false,
      query: "",
      results: [],
      queryFocussed: false,
      theme: css
    };
  },

  created() {
    this.debounceSearch = _.debounce(this.search, 500);
    this.debounceLoseFocus = _.debounce(this.blur, 100);
  },
  unmounted() {
    this.debounceSearch.cancel();
  },

  methods: {
    nFormatter(num, digits) {
      return nFormatter(num, digits);
    },
    blur() {
      this.queryFocussed = false;
    },
    close() {
      this.$emit("close");
    },
    search() {
      if (this.query.length < 2) {
        this.results = {};
        return;
      }

      fetch(`/api/claim_benefits/search/${this.query}`)
      .then(response => response.json())
      .then(data => {
        this.results = data.search_results || {};
      });
    },
    expectedBenefitPopup(claim_period) {
      let string = `<b>£${Number(claim_period._claim_value).toLocaleString()}`;
      string += "</b><br /><br />";
      claim_period._expected_benefits.forEach(benefit_part => {
        string
            += `${benefit_part.scheme.toString()
          } - ${
            benefit_part.type
          } - £${
            Number(benefit_part.value).toLocaleString()}`;
        string += "<br />";
      });
      return string;
    },
    cancelSearch() {
      this.query = "";
      this.results = {};
      this.debounceSearch.cancel();
    }

  }
};
</script>
