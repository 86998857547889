<template>
  <div>
    <div v-if="dataFromCall">
      <div class="tw-min-h-[520px] tw-relative tw-pb-10">
        <p v-if="answerResponseMessage" class="tw-mt-7" v-html="answerResponseMessage" />

        <div v-else>
          <div class="tw-mt-7 tw-mb-3" v-html="text" />

          <div v-if="question">
            <p v-html="question.questionText" />

            <ul class="tw-pb-10" style="list-style-type: none">
              <li v-for="(option, index) in question.options" :key="index" class="tw-mb-3">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <label class="tw-m-0 tw-cursor-pointer tw-font-normal tw-grow tw-pr-4" :for="`option-${index}`">
                    <span class="tw-font-bold">{{ index + 1 }}. </span>{{ option.text }}
                  </label>
                  <input
                    :id="`option-${index}`"
                    class="focus:tw-outline-none focus:tw-shadow-none focus:tw-ring-0 tw-cursor-pointer"
                    type="checkbox"
                    :value="option.text"
                    :model="userAnswer"
                    :checked="_.some(userAnswer, option)"
                    @change="(e) => handleCheckboxChange(e, option)"
                  >
                </div>
              </li>
            </ul>
          </div>

          <div v-if="increment === dataFromCall.length - 1" class="tw-mt-5">
            <label class="tw-m-0 tw-cursor-pointer tw-font-normal tw-grow tw-pr-4 tw-mb-5">
              I confirm I understand what constitutes R&D for the purposes of this claim.

              <input
                class="focus:tw-outline-none focus:tw-shadow-none focus:tw-ring-0 tw-cursor-pointer"
                type="checkbox"
                :model="confirmUnderstanding"
                @change="confirmUnderstanding = !confirmUnderstanding"
              >
            </label>
            <button-component
              text="Finish Elearn"
              :colour="confirmUnderstanding ? 'cta' : 'disabled'"
              :disable-click="!confirmUnderstanding"
              @click.native="confirmUnderstanding && finishElearn()"
            />
          </div>
        </div>

        <span class="tw-absolute tw-right-0 tw-bottom-3 tw-font-semibold">
          step {{ increment + 1 }} of {{ dataFromCall.length }}
        </span>
      </div>

      <ElearnControls
        :prev-disabled="prevDisabled"
        :next-disabled="nextDisabled"
        :total-stages="dataFromCall.length - 1"
        :current-stage="increment"
        :should-submit="question && !answerResponseMessage"
        @prev="prev()"
        @next="next()"
        @submitAnswer="submitAnswer()"
      />
    </div>

    <div v-if="showError">
      <p class="tw-text-center">
        We're having trouble fetching your elearn content. Please try again later.
      </p>
    </div>
  </div>
</template>

<script setup>
import { marked } from "marked";
import { defineProps, onMounted, ref, computed, inject } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import { patch } from "../../../shared/http.js";
import ElearnControls from "./elearn_controls.vue";

const props = defineProps({
  level: {
    type: String
  },
  claimSlug: {
    type: String
  },
  userId: {
    type: String
  },
  industryType: {
    type: String
  }
});

const getElearnContentId = () => {
  if (props.industryType === "software") {
    if (props.level === "beg") return 1;
    if (props.level === "int") return 2;
    if (props.level === "adv") return 3;
  } else if (props.industryType === "manufacturing") {
    if (props.level === "beg") return 4;
    if (props.level === "int") return 5;
    if (props.level === "adv") return 6;
  }
};

const store = useStore();
const track = inject("track");
const url = new URL(window.location.href);
const plan = url.searchParams.has("plan") ? url.searchParams.get("plan") : null;
const cp = url.searchParams.has("cp") ? url.searchParams.get("cp") : null;
const cumulativeAnswers = [];
const increment = ref(0);
const dataFromCall = ref(null);
const userAnswer = ref([]);
const answerResponseMessage = ref("");
const confirmUnderstanding = ref(false);
const showError = ref(false);

onMounted(async () => {
  const elearnContentId = getElearnContentId();
  const data = await store.dispatch("elearn/FETCH_DATA", { elearnContentId });

  if (_.isEmpty(data)) {
    showError.value = true;
  } else {
    dataFromCall.value = data.Slidelist;

    dataFromCall.value ? fireAnalyticsEvent("Elearn Started") : (showError.value = true);
  }
});

const text = computed(() => {
  if (dataFromCall.value) {
    const slide = dataFromCall.value[increment.value].e_learn_slide;
    return marked(slide.Text);
  }
});

const question = computed(() => {
  const { Type, Question_Data, Correct_Answer_Response, Incorrect_Answer_Response } = dataFromCall.value[increment.value].e_learn_slide;

  return Type === "question"
    ? {
      questionText: Question_Data.question,
      correctText: Correct_Answer_Response,
      wrongText: Incorrect_Answer_Response,
      options: Question_Data.options
    }
    : null;
});

const finishElearn = async () => {
  try {
    const answerTotals = _.countBy(cumulativeAnswers);
    const data = {
      plan,
      el_level: props.level,
      cp,
      correct: answerTotals.correct,
      incorrect: answerTotals.incorrect
    };

    await patch(`/set_el_level/${props.userId}`, data);

    fireAnalyticsEvent("Elearn Finished Clicked", {
      totalCorrect: answerTotals.correct,
      totalIncorrect: answerTotals.incorrect
    });

    window.location.href = `/claim_periods/${props.claimSlug}/dashboard`;
  } catch (error) {
    console.log(`There was an error updating the user profile with the elearn results: ${error}`);
  }
};

const isYesOrNo = computed(() => {
  const correctAndIncorrect = question.value.options.reduce(
    (acc, next) => {
      next.isCorrect ? acc.correct++ : acc.incorrect++;
      return acc;
    },
    { correct: 0, incorrect: 0 }
  );

  return correctAndIncorrect.correct === 1 && correctAndIncorrect.incorrect === 1;
});

const handleCheckboxChange = (event, option) => {
  if (event.target.checked) {
    isYesOrNo.value ? (userAnswer.value = [option]) : userAnswer.value.push(option);
  } else {
    userAnswer.value = userAnswer.value.filter(item => !_.isEqual(item, option));
  }
};

const isAnswerCorrect = () => {
  const anyIncorrect = userAnswer.value.some(object => !object.isCorrect);

  if (anyIncorrect) return false;

  const amountOfCorrectAnswers = question.value.options.reduce((acc, next) => (next.isCorrect ? acc + 1 : acc), 0);

  return amountOfCorrectAnswers === userAnswer.value.length;
};

const submitAnswer = () => {
  const isCorrect = isAnswerCorrect();

  answerResponseMessage.value = marked(question.value[isCorrect ? "correctText" : "wrongText"]);
  userAnswer.value = [];
  cumulativeAnswers.push(isCorrect ? "correct" : "incorrect");

  fireAnalyticsEvent("Elearn Answer Question Clicked", { isCorrect });
};

const next = () => {
  fireAnalyticsEvent("Elearn Next Clicked");

  userAnswer.value = [];
  answerResponseMessage.value = "";
  increment.value++;
};
const nextDisabled = computed(() => (
  increment.value === dataFromCall.value?.length - 1
    || (question.value && userAnswer.value.length === 0 && !answerResponseMessage.value)
));

const prev = () => {
  fireAnalyticsEvent("Elearn Prev Clicked");

  if (!answerResponseMessage.value) {
    userAnswer.value = [];
    increment.value--;
  }

  if (question.value) cumulativeAnswers.pop();

  answerResponseMessage.value = "";
  confirmUnderstanding.value = false;
};
const prevDisabled = computed(() => increment.value === 0);

const fireAnalyticsEvent = (event, additionalProperties = {}) => {
  const properties = {
    level: props.level,
    cp,
    path: url.pathname,
    industryType: props.industryType,
    stage: increment.value + 1,
    totalStage: dataFromCall.value.length,
    ...additionalProperties
  };

  track(event, properties);
};
</script>

<style lang="scss" scoped>
:deep() {
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  li {
    margin-bottom: 8px;
    padding-left: 20px;

    &::marker {
      font-weight: bold;
    }
  }
}
</style>
