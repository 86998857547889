<template>
  <div class="tw-w-full">
    <div v-if="!basicForm" class="tw-flex tw-items-stretch tw-justify-end">
      <div class="tw-text-right tw-h-full tw-flex-shrink-0">
        <div class="tw-flex tw-gap-x-2">
          <div class="tw-flex tw-flex-col tw-items-end">
            <state-wrapper component="CompleteIcon">
              <CompleteIcon
                :fields="field.subfields"
                :section-type="sectionType"
                :section-slug="sectionSlug"
                complete-icon-type="FieldComplete"
              />
            </state-wrapper>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        :class="[
          'tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-flow-row-dense tw-gap-y-1',
          basicForm ? 'tw-gap-x-4' : 'tw-gap-x-2'
        ]"
      >
        <!-- field 1 -->
        <label
          :for="field.subfields[0].label"
          :field-key="field.subfields[0].label"
          :group-index="groupIndex"
          class="tw-block tw-text-large tw-font-medium tw-mb-0 md:tw-col-start-1"
        >
          <div class="tw-inline tw-space-x-3 tw-mb-2">
            <span>
              {{ field.subfields[0].title }}
              <span v-if="fieldRequired(sectionType, field.subfields[0].label)">
                <tooltip content="Required">
                  <span class="tw-text-red-500 tw-text-base">*</span>
                </tooltip>
              </span>
            </span>
            <state-wrapper component="SectionKnowledgeCentre">
              <KnowledgeCentreTriggerIcon
                v-if="field.subfields[0].knowledge_centre"
                :question="field.subfields[0].label"
                :section="sectionType"
                :icon="true"
              />
            </state-wrapper>
            <state-wrapper v-if="!basicForm" component="AuditHistoryTrigger">
              <AuditHistoryTrigger
                :field="field.subfields[0]"
                :section-type="sectionType"
                :section-slug="sectionSlug"
              />
            </state-wrapper>
            <state-wrapper v-if="!basicForm" component="PreviousResolvedFeedbackIcon">
              <PreviousResolvedFeedbackIcon
                :section-type="sectionType"
                :section-slug="sectionSlug"
                :col-ref="field.subfields[0].label"
              />
            </state-wrapper>
            <saving-spin-indicator
              v-if="showSpinner(field.subfields[0].type)"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :fields="[field.subfields[0].label]"
            />
          </div>
        </label>
        <state-wrapper
          v-slot="{ editable }"
          :component="fieldOne.type"
        >
          <div class="md:tw-col-start-1">
            <component
              :is="fieldOne.type"
              :editable="editable"
              :field="fieldOne"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :basic-form="basicForm"
              :is-reviewer="false"
            />
          </div>
        </state-wrapper>
        <state-wrapper component="field_blurb">
          <div class="md:tw-col-start-1">
            <p
              v-show="fieldOne.field_blurb !== undefined && fieldOne.field_blurb !== null"
              class="
                tw-text-xs
                tw-text-gray-500
                tw-font-normal
                tw-mx-1
                tw-mt-1
                tw-mb-0
              "
              v-html="fieldOne.field_blurb"
            />
          </div>
        </state-wrapper>
        <div class="md:tw-col-start-1">
          <IsBeingEdited
            v-if="false"
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :section-field-name="field.subfields[0].label"
          />
        </div>
        <state-wrapper component="ValidationMessage">
          <div class="md:tw-col-start-1">
            <ValidationMessage
              :field="field.subfields[0]"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :section-field-name="field.subfields[0].label"
              class="tw-mx-1"
            />
          </div>
        </state-wrapper>
        <state-wrapper v-if="!basicForm" component="LeaveNarrativeFeedback">
          <div class="md:tw-col-start-1">
            <LeaveNarrativeFeedback
              v-if="field.subfields[0].can_leave_feedback"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :field="field.subfields[0]"
            />
          </div>
        </state-wrapper>
        <state-wrapper component="DebouncedSave">
          <DebouncedSave
            :field="field.subfields[0]"
            :section-type="sectionType"
            :section-slug="sectionSlug"
          />
        </state-wrapper>
        <!-- end field 1 -->
        <!-- field 2 -->
        <label
          :for="field.subfields[1].label"
          :field-key="field.subfields[1].label"
          :group-index="groupIndex"
          class="tw-block tw-text-large tw-font-medium tw-mb-0 tw-mt-5 md:tw-mt-0 md:tw-col-start-2"
        >
          <div class="tw-inline tw-space-x-3 tw-mb-2">
            <span>
              {{ field.subfields[1].title }}
              <span v-if="fieldRequired(sectionType, field.subfields[1].label)">
                <tooltip content="Required">
                  <span class="tw-text-red-500 tw-text-base">*</span>
                </tooltip>
              </span>
            </span>
            <state-wrapper component="SectionKnowledgeCentre">
              <KnowledgeCentreTriggerIcon
                v-if="field.subfields[1].knowledge_centre"
                :question="field.subfields[1].label"
                :section="sectionType"
                :icon="true"
              />
            </state-wrapper>
            <state-wrapper v-if="!basicForm" component="AuditHistoryTrigger">
              <AuditHistoryTrigger
                :field="field.subfields[1]"
                :section-type="sectionType"
                :section-slug="sectionSlug"
              />
            </state-wrapper>
            <state-wrapper v-if="!basicForm" component="PreviousResolvedFeedbackIcon">
              <PreviousResolvedFeedbackIcon
                :section-type="sectionType"
                :section-slug="sectionSlug"
                :col-ref="field.subfields[1].label"
              />
            </state-wrapper>
            <saving-spin-indicator
              v-if="showSpinner(field.subfields[1].type)"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :fields="[field.subfields[1].label]"
            />
          </div>
        </label>
        <state-wrapper
          v-slot="{ editable }"
          :component="fieldTwo.type"
        >
          <div class="md:tw-col-start-2">
            <component
              :is="fieldTwo.type"
              :editable="editable"
              :field="fieldTwo"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :basic-form="basicForm"
              :is-reviewer="false"
            />
          </div>
        </state-wrapper>
        <state-wrapper component="field_blurb">
          <div class="md:tw-col-start-2">
            <p
              v-show="fieldTwo.field_blurb !== undefined && fieldTwo.field_blurb !== null"
              class="
                tw-text-xs
                tw-text-gray-500
                tw-font-normal
                tw-mx-1
                tw-mt-1
                tw-mb-0
              "
              v-html="fieldTwo.field_blurb"
            />
          </div>
        </state-wrapper>
        <div class="md:tw-col-start-2">
          <IsBeingEdited
            v-if="false"
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :section-field-name="field.subfields[1].label"
          />
        </div>
        <state-wrapper component="ValidationMessage">
          <div class="md:tw-col-start-2">
            <ValidationMessage
              :field="field.subfields[1]"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :section-field-name="field.subfields[1].label"
              class="tw-mx-1"
            />
          </div>
        </state-wrapper>
        <state-wrapper v-if="!basicForm" component="LeaveNarrativeFeedback">
          <div class="md:tw-col-start-2">
            <LeaveNarrativeFeedback
              v-if="field.subfields[1].can_leave_feedback"
              :section-type="sectionType"
              :section-slug="sectionSlug"
              :field="field.subfields[1]"
            />
          </div>
        </state-wrapper>
        <state-wrapper component="DebouncedSave">
          <DebouncedSave
            :field="field.subfields[1]"
            :section-type="sectionType"
            :section-slug="sectionSlug"
          />
        </state-wrapper>
        <!-- end field 2 -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { SET_FIELD_TO_WRITE } from "@store/fields.js";
import { GET_FEEDBACKS_FOR_FIELD } from "@store/feedbacks.js";
import { GET_FIELD_REQUIRED } from "@store/sections.js";

import SmallText from "./fields/small_text.vue";
import Date from "./fields/date.vue";
import Number from "./fields/number.vue";
import Currency from "./fields/currency.vue";
import YesNoRadio from "./fields/yes_no_radio.vue";
import NestedSelect from "./fields/nested_select.vue";
import DropdownSelect from "./fields/dropdown_select.vue";

import CompleteIcon from "./complete_icon.vue";
import IsBeingEdited from "./is_being_edited.vue";
import ValidationMessage from "./validation_message.vue";
import DebouncedSave from "./save_buttons/debounced_save";
import LeaveNarrativeFeedback from "./leave_narrative_feedback.vue";
import AuditHistoryTrigger from "./audit_history_trigger.vue";
import PreviousResolvedFeedbackIcon from "../../generic/previous_resolved_feedback_icon.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";

import { FIELD_STATE } from "./shared/field_state.js";

export default {
  components: {
    CompleteIcon,
    Date,
    SmallText,
    DebouncedSave,
    IsBeingEdited,
    ValidationMessage,
    Number,
    Currency,
    YesNoRadio,
    NestedSelect,
    LeaveNarrativeFeedback,
    PreviousResolvedFeedbackIcon,
    AuditHistoryTrigger,
    KnowledgeCentreTriggerIcon,
    DropdownSelect
  },
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument",
          "RdProject"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    userSlug: {
      type: String,
      default: null
    },
    groupIndex: {
      required: false
    },
    basicForm: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getFeedbackFields: `feedbacks/${GET_FEEDBACKS_FOR_FIELD}`,
      fieldRequired: `sections/${GET_FIELD_REQUIRED}`
    }),
    fields() {
      return [];
    },
    fieldOne() {
      if (!this.basicForm) {
        this.field.subfields[0].state = this.field.subfields[0].state ?? FIELD_STATE.UNTOUCHED;
      }
      return this.field.subfields[0];
    },
    fieldTwo() {
      if (!this.basicForm) {
        this.field.subfields[1].state = this.field.subfields[1].state ?? FIELD_STATE.UNTOUCHED;
      }
      return this.field.subfields[1];
    }
  },
  watch: {
    "fieldOne.value": function (_val) {
      this.setFieldToWrite(
        `${this.sectionType}:${this.sectionSlug}:${this.field.subfields[0].label}`
      );
      if (this.field.subfields[0].state < FIELD_STATE.EDITED) {
        this.field.subfields[0].state = FIELD_STATE.EDITED;
      }
    },
    "fieldTwo.value": function (_val) {
      this.setFieldToWrite(
        `${this.sectionType}:${this.sectionSlug}:${this.field.subfields[1].label}`
      );
      if (this.field.subfields[1].state < FIELD_STATE.EDITED) {
        this.field.subfields[1].state = FIELD_STATE.EDITED;
      }
    }
  },
  methods: {
    ...mapMutations({
      setFieldToWrite: `fields/${SET_FIELD_TO_WRITE}`
    }),
    showSpinner(type) {
      if (type === "YesNoRadio") {
        return false;
      }
      return this.basicForm && ["MultiSelect", "RadioSelect", "NestedSelect", "DropdownSelect"].includes(type);
    }
  }
};
</script>
