<template>
  <div class="tw-flex tw-flex-wrap">
    <div class="md:tw-pr-2 tw-my-2 md:tw-my-0 tw-w-full md:tw-w-1/3">
      <label>Total PAYE</label>
      <input
        id="finance_document_rdec_paye_liability"
        v-model.number="formPayeLiability.value"
        step="0.01"
        data-id="financeDocId"
        data-class-name="FinanceDocument"
        type="number"
        name="finance_document[rdec_paye_liability]"
        :class="[formPayeLiability.error ? errorClasses : '', inputClass]"
        @change="validate(formPayeLiability)"
      >
      <p v-show="formPayeLiability.error !== null" class="tw-mt-2 tw-text-xs tw-text-red-600" v-text="formPayeLiability.error" />
    </div>
    <div class="md:tw-px-2 tw-my-2 md:tw-my-0 tw-w-full md:tw-w-1/3">
      <label>
        Total Employer NI
      </label>
      <input
        id="finance_document_rdec_employer_ni"
        v-model.number="formEmployerNi.value"
        step="0.01"
        data-id="financeDocId"
        data-class-name="FinanceDocument"
        type="number"
        name="finance_document[rdec_employer_ni]"
        :class="[formEmployerNi.error ? errorClasses : '', inputClass]"
        @change="validate(formEmployerNi)"
      >
      <p v-show="formEmployerNi.error !== null" class="tw-mt-2 tw-text-xs tw-text-red-600" v-text="formEmployerNi.error" />
    </div>
    <div class="md:tw-pl-2 tw-my-2 md:tw-my-0 tw-w-full md:tw-w-1/3">
      <label>
        Total Employee NI
      </label>
      <input
        id="finance_document_rdec_employee_ni"
        v-model.number="formEmployeeNi.value"
        step="0.01"
        data-id="financeDocId"
        data-class-name="FinanceDocument"
        type="number"
        name="finance_document[rdec_employee_ni]"
        :class="[formEmployeeNi.error ? errorClasses : '', inputClass]"
        @change="validate(formEmployeeNi)"
      >
      <p v-show="formEmployeeNi.error !== null" class="tw-mt-2 tw-text-xs tw-text-red-600" v-text="formEmployeeNi.error" />
    </div>
  </div>

  <div class="tw-my-4">
    <p>
      Your payroll manager should be able to share this data with you.
      It can often be found on your P32 document. Note the financial year
      for this claim period is {{ financeDocTitle }}.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    employeeNi: {
      type: Number,
      required: false,
      default: 0
    },
    employerNi: {
      type: Number,
      required: false,
      default: 0
    },
    financeDocId: {
      type: Number,
      required: true,
      validator(value) {
        return value > 0;
      }
    },
    payeLiability: {
      type: Number,
      required: false,
      default: 0
    },
    financeDocTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formEmployeeNi: {
        value: this.employeeNi,
        error: null,
        name: "employee_ni"
      },
      formEmployerNi: {
        value: this.employerNi,
        error: null,
        name: "employer_ni"
      },
      formPayeLiability: {
        value: this.payeLiability,
        error: null,
        name: "paye_liability"
      },
      inputClass: "numeric float optional focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-border-solid tw-form-input tw-block tw-w-full sm:tw-text-sm tw-pl-7 sm:tw-text-sm tw-border-gray-300 tw-rounded-md",
      errorClasses: [
        "tw-border-red-300", "tw-text-red-900", "tw-placeholder-red-300", "focus:tw-outline-none",
        "focus:tw-ring-red-500", "focus:tw-border-red-500"
      ]
    };
  },
  mounted() {
    this.formEmployeeNi.value = this.formEmployeeNi.value.toFixed(2);
    this.formEmployerNi.value = this.formEmployerNi.value.toFixed(2);
    this.formPayeLiability.value = this.formPayeLiability.value.toFixed(2);
  },
  methods: {
    validate(field) {
      this.resetAllErrors();
      field.value = field.value.toFixed(2);

      if (field.value < 0) field.error = "Please enter positive values here";

      if (field.name === "employee_ni" && field.value > this.formEmployerNi.value) field.error = "Normally Employee NI contributions are lower than Employer NI contributions";

      if (field.name === "employer_ni" && field.value < this.formEmployeeNi.value) field.error = "Normally we expect to see Employer NI contributions higher than Employee NI";
    },
    resetAllErrors() {
      this.formEmployeeNi.error = null;
      this.formEmployerNi.error = null;
      this.formPayeLiability.error = null;
    }
  }
};
</script>
