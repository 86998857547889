import { post } from "./http.js";

export async function validate(className, slug, fields = null, params = {}) {
  let apiRouteController = "forms";

  if (className.includes("Cost")) {
    apiRouteController = "costs";
  } else if (className.includes("EpwContract")) {
    apiRouteController = "epw_contract_reviews";
  } else if (className.includes("ProjectSelection")) {
    apiRouteController = "project_selections";
  }

  const validationErrors = post(`/api/${apiRouteController}/validate/${className}/${slug}`, {
    fields,
    ...params
  });
  return validationErrors;
}
