<template>
  <form v-if="method !== 'get'" :action="action" :method="method">
    <input type="hidden" name="authenticity_token" :value="csrfToken()" />
    <input v-for="key in Object.keys(data)" v-bind:key="key" type="hidden" :name="key" :value="data[key]" />
    <slot />
  </form>
  <slot v-else />
</template>

<script setup>
const props = defineProps({
  method: {
    type: String,
    required: true
  },
  action: {
    type: String,
    required: false
  },
  data: {
    type: Object,
    required: false,
    default: () => {
      return {}
    }
  }
});

function csrfToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}
</script>
