import { format } from "date-fns";

export function nFormatter(num, digits) {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function numberWithCommas(num) {
  if (num === undefined) {
    return "";
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function decimalGreaterThan(x, y, decimalPlaces) {
  const exponent = 10 ** decimalPlaces;

  return Math.round(x * exponent) > Math.round(y * exponent);
}

export function generateUUID() { // Public Domain/MIT
  let d = new Date().getTime();
  if (typeof performance !== "undefined" && typeof performance.now === "function") {
    d += performance.now(); // use high-precision timer if available
  }
  const newGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
  });

  return newGuid;
}

export function truncate(string = "", length = 50) {
  if (string === null || string === undefined) {
    return string;
  } else if (string.length > length) {
    return `${string.substring(0, length)}...`;
  } else {
    return string;
  }
}

export const pluralize = (count, noun, with_number = false) => `${with_number ? `${count} ` : ""}${noun}${(count > 1 && noun.slice(-1) !== "s") ? "s" : ""}`;

export const pluralizeHasHave = count => (count == 1 ? "has" : "have");

export const capitalize = string => string[0].toUpperCase() + string.slice(1).toLowerCase();

export const titleCase = string => string.replace(/_/g, " ").split(" ").map(capitalize).join(" ");

export const ordinalize = number => {
  const suffixes = ["th", "st", "nd", "rd"];
  const lastTwoDigits = number % 100;
  return number + (suffixes[(lastTwoDigits - 20) % 10] || suffixes[lastTwoDigits] || suffixes[0]);
};

export const snakeToPascalCase = string => {
  const str = string.replace(/_/, " ");

  return titleCase(str).replace(/\s/g, "");
};

export async function copyToClipboard(val) {
  try {
    await navigator.clipboard.writeText(val);
  } catch ($e) {
  }
}

export const formatCurrency = (value, forceDecimals, defaultToZero = true) => {
  if (!value) {
    if (defaultToZero) {
      value = 0;
    } else return;
  }

  value = parseFloat(value);

  let decimalsToUse;
  if (forceDecimals) {
    decimalsToUse = 2;
  } else {
    const hasDecimals = value % 1 !== 0;
    decimalsToUse = hasDecimals ? 2 : 0;
  }

  return `${parseFloat(value).toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 2,
    minimumFractionDigits: decimalsToUse
  })}`;
};

export const formatDate = (date, pattern = "do LLL yy") => {
  if (!date) return;

  return format(new Date(date), pattern);
};

export const formatPercentage = (value, defaultToZero = true) => {
  value = defaultToZero && !value ? 0 : parseFloat(value / 100);

  if (Number.isNaN(value)) return;

  return `${value.toLocaleString("en-GB", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2
  })}`;
};
