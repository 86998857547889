<template>
  <ExpandClose ref="expandClose">
    <state-wrapper v-slot="{ editable }">
      <div v-if="!loading && rdProject" :class="editable ? 'tw-px-4 xl:tw-px-6' : 'tw-px-8'" class="tw-py-4">
        <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-grid-flow-row-dense tw-gap-x-4 tw-gap-y-5">
          <template v-for="(field, idx) in orderedFields" :key="idx">
            <component
              :is="fieldComponent(field.type)"
              :id="`question_${field.label}`"
              :field="field"
              :section-slug="rdProject.slug"
              :section-type="rdProject.type"
              basic-form
              :class="[
                'tw-transition-bg tw-duration-300 tw-ease-out tw-delay-75',
                gridClasses(field)
              ]"
              subfield-classes="tw-col-start-3"
            />
          </template>
        </div>
        <div class="tw-flex tw-justify-end tw-mt-3">
          <state-wrapper component="SaveAllButton">
            <div class="tw-w-1/4">
              <SaveAllButton v-if="rdProject" :section="rdProject" @finishedSaving="allSaved" />
            </div>
          </state-wrapper>
        </div>
      </div>
    </state-wrapper>
  </ExpandClose>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { fieldLabels, toValidateFields } from "../../../shared/fields.js";
import useEventsBus from "../../../shared/eventBus";
import Field from "../dynamic_forms/field.vue";
import RadioButtonRevealField from "../dynamic_forms/radio_button_reveal_field.vue";
import Paired from "../dynamic_forms/paired_field.vue";
import SaveAllButton from "../dynamic_forms/save_buttons/save_all_button.vue";
import ExpandClose from "../transitions/expand_close.vue";

export default {
  components: {
    Field,
    RadioButtonRevealField,
    Paired,
    SaveAllButton,
    ExpandClose
  },
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const store = useStore();
    const { emit } = useEventsBus();
    const expandClose = ref();
    const loading = ref(true);

    const rdProject = computed(() => store.getters["sections/GET_SECTION_BY_SLUG"](props.slug));
    const fieldsBeingValidated = computed(() => store.getters["validations/GET_FIELDS_BEING_VALIDATED"]);

    const orderedFields = computed(() => _.chain(rdProject.value?.fields).orderBy("order").value());

    function allSaved() {
      toggleForm();
      emit("flashSaved", props.slug);
    }

    function fieldComponent(type) {
      if (["Paired", "RadioButtonRevealField"].includes(type)) {
        return type;
      }
      return "Field";
    }

    function gridClasses(field) {
      switch (field.type) {
        case "LargeText":
          return "tw-row-span-3 tw-col-span-2";
        case "RadioButtonRevealField":
          return "tw-row-span-1";
        case "Paired":
          return "tw-col-span-4 tw-row-span-1";
        default:
          return field.label === "start_date" ? "tw-col-start-3 tw-col-span-1" : "tw-col-span-2";
      }
    }

    function validate(full = false) {
      store.dispatch("validations/VALIDATE", [
        "RdProject", props.slug, toValidateFields(rdProject.value?.fields), full
      ]).then(() => {
        store.dispatch("project_selection/REFRESH_RD_PROJECT", { slug: props.slug });
        return Promise.resolve();
      });
    }

    onMounted(() => {
      Promise.all([
        validate()
      ]).then(() => {
        loading.value = false;
      });
    });

    function toggleForm() {
      expandClose.value.toggleSection();
      if (expandClose.value.isCollapsed) {
        store.dispatch("project_selection/REFRESH_RD_PROJECT", { slug: props.slug });
      }
    }

    const { bus } = useEventsBus();

    watch(() => fieldsBeingValidated.value, () => {
      if (fieldsBeingValidated.value?.some(f => f.includes(`RdProject:${props.slug}`))) {
        store.dispatch("project_selection/REFRESH_RD_PROJECT", { slug: props.slug });
      }
    });

    watch(() => bus.value.get("toggleRdProjectForm"), val => {
      const [slug] = val ?? [];
      if (slug === props.slug) {
        toggleForm();
      }
    });

    watch(() => bus.value.get("validateAll"), val => {
      validate(true);
    });

    return {
      loading,
      rdProject,
      orderedFields,
      gridClasses,
      fieldComponent,
      toggleForm,
      allSaved,
      expandClose
    };
  }
};

</script>
