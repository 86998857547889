<template>
  <DataTable
    :data="configuredData"
    :columns="tableColumns"
    :paginator-rows="50"
    enable-search
    :error-message="errorMessage"
    :is-loading="isLoading"
    row-classes="tw-cursor-pointer"
    use-row-click
    @on-row-click="onRowClick"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DataTableRowClickEvent } from "primevue/datatable";
import { formatDate, formatCurrency } from "@shared/helpers.js";
import DataTable from "@generic/data_table.vue";
import IconGroup from "@generic/icon_group.vue";
import { Submission } from "./interfaces";

const props = defineProps<{
  submissions: Submission[]
  errorMessage: string
  isLoading: boolean
}>();

const configuredData = computed(() => props.submissions.map(submission => ({
  ...submission,
  icons: {
    icons: constructIcons(submission)
  }
})));

function onRowClick(event: DataTableRowClickEvent) {
  const { data, originalEvent } = event;

  if (originalEvent?.target instanceof HTMLElement && originalEvent?.target?.hasAttribute("exclude-from-click")) return;

  window.location.href = data.submission_href;
}

function constructIcons(submission: Submission) {
  return [
    {
      type: "check",
      tooltip: "Mark As Client Notified",
      linkData: {
        href: `/submission/${submission.slug}?submission%5Bskip_email%5D=true&submission%5Bstatus%5D=Client+Notified`,
        "data-method": "PATCH",
        "data-confirm": "Are you sure? This will not email the client"
      }
    },
    {
      type: "edit",
      tooltip: "Edit Claim Period",
      linkData: {
        href: `/claim_periods/${submission.claim_period_slug}/edit`,
        "data-method": "GET"
      }
    },
    {
      type: "file-alt",
      tooltip: "Claim Summary",
      linkData: {
        href: `/claim_periods/${submission.claim_period_slug}/full_claim_view`,
        "data-method": "GET"
      }
    },
    {
      type: "trash",
      tooltip: "Delete",
      linkData: {
        href: `/submission/${submission.slug}`,
        "data-method": "DELETE",
        "data-confirm": "Are you sure?"
      }
    }
  ];
}

const tableColumns = [
  {
    header: "Company",
    field: "company_name",
    sortable: true,
    colClasses: "tw-w-1/6",
    alignmentOverride: "tw-text-left"
  },
  {
    header: "Claim Period",
    field: "claim_period_title",
    colClasses: "tw-w-1/6",
    alignmentOverride: "tw-text-left"
  },
  {
    header: "Value",
    field: "rd_claim_value",
    formatter: formatCurrency,
    formatterArguments: [false, false],
    sortable: true
  },
  {
    header: "UTR",
    field: "unique_taxpayer_ref"
  },
  {
    header: "Manager",
    field: "claim_manager",
    sortable: true
  },
  {
    header: "Finance",
    field: "finance_user",
    sortable: true
  },
  {
    header: "Assigned At",
    field: "assigned_at",
    formatter: formatDate,
    sortable: true
  },
  {
    header: "Days Assigned",
    field: "days_assigned",
    sortable: true
  },
  {
    header: "Priority",
    field: "priority",
    sortable: true
  },
  {
    header: "Exp. Completion Month",
    field: "expected_month_completion",
    sortable: true
  },
  {
    header: "Status",
    field: "status",
    sortable: true
  },
  {
    header: "",
    field: "icons",
    component: IconGroup,
    excludeFromClick: true
  }
];
</script>
