<template>
  <transition name="slide-up" v-bind="$attrs">
    <slot />
  </transition>
</template>

<script>
export default {};
</script>

<style scoped>
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.2s ease-out;
  }

  .slide-up-enter-from {
    opacity: 0;
    transform: translateY(5px);
  }

  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }
</style>
