import _ from "lodash";
import { feature_flags } from "../vuex-store/modules/feature_flags";

export function flattenFields(fields, onlyShown = null) {
  return _.chain(fields)
    .map(f => {
      if (f.type === "Paired") {
        return f.subfields;
      } else if (f.type === "RadioButtonRevealField" && f.subfields && onlyShown) {
        return [f].concat(_.filter(f.subfields, sf => ((sf?.subfield_type === "Reveal") ? showSubfield(f, sf) : true)));
      } else if (f.type === "RadioButtonRevealField" && f.subfields) {
        return [f].concat(f.subfields);
      } else {
        return f;
      }
    })
    .flatten()
    .value();
}

export function toValidateFields(fields) {
  if (!fields) return null;

  return _.chain(flattenFields(fields))
    .reduce((result, field) => {
      result[field.label] = field?.value;
      return result;
    }, {})
    .value();
}

export function fieldLabels(fields) {
  return _.map(flattenFields(fields), "label");
}

export function showSubfield(field, subfield) {
  if (field.value === null || field.value === undefined) {
    return false;
  } else {
    const show = subfield.reveal_key.includes(String(field.value));

    return show;
  }
}

export function featureEnabled(options, feature) {
  return (options !== undefined && options[feature] !== undefined && options[feature] !== null && options[feature] != "false" && options[feature] != false);
}
export function optionSet(options, option) {
  return (options !== undefined && options[option] !== undefined && options[option] !== null && options[option] != "false" && options[option] != false);
}

export function optionNotSet(options, option) {
  return !optionSet(options, option);
}

export function track(event_name, properties) {
  if (isNewAnalytics()) {
    window.analytics.track(event_name, properties);
  } else {
    mixpanel.track(event_name, properties);
  }
}

export function trackButtonClick(id, params) {
  track("Button Clicked", _.merge({
    button_id: id
  }, params));
}

// Helper function to get the feature flags
export function isNewAnalytics() {
  return feature_flags.state.feature_flags.new_analytics || window.new_analytics;
}
