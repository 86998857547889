<template>
  <button-component id="section-complete-icon" :tooltip="tooltip" :tooltip-options="{ delay: [400, null] }">
    <div :class="color" class="section-complete tw-inline-flex tw-font-semibold">
      <span class="tw-text-base tw-text-right tw-mr-2 tw-hidden lg:tw-inline">
        {{ label }}
      </span>
      <span class="tw-hidden lg:tw-inline tw-text-center">
        <i :class="icon" class="fa-lg tw-pt-1.5 lg:tw-px-1" />
      </span>
      <span class="lg:tw-hidden">
        <i class="far fa-check-square fa-lg tw-pt-1.5" />
      </span>
    </div>
  </button-component>
  <div v-if="validationErrors">
    <span class="tw-text-xs tw-font-semibold tw-text-red-500 tw-mr-1">
      Some answers need addressing before you can complete this section.
    </span>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    sectionType: {
      type: String,
      required: false
    },
    completed: {
      type: Boolean,
      required: true
    },
    validationErrors: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const newProject = computed(() => store.getters["sections/GET_NEW_PROJECT"]);

    const sectionTypeName = computed(() => {
      if (props.sectionType == "Grant") {
        return "External Funding";
      } if (props.sectionType == "Status") {
        return "Business Questionnaire";
      } if (props.sectionType == "Project" && !newProject.value) {
        return "Project Details";
      }

      return props.sectionType;
    });

    const color = computed(() => {
      if (props.completed) {
        return "tw-text-green-600 hover:tw-text-green-700";
      }
      return "tw-text-gray-500 hover:tw-text-gray-900";
    });

    const label = computed(() => {
      if (props.completed) {
        return `${sectionTypeName.value} completed`;
      }
      return `Mark ${sectionTypeName.value} as complete`;
    });

    const icon = computed(() => {
      if (props.completed) {
        return "far fa-check-square";
      }
      return "far fa-square";
    });

    const tooltip = computed(() => {
      if (props.completed) {
        return `${sectionTypeName.value} is complete`;
      }
      return `Click to mark ${sectionTypeName.value} as complete`;
    });

    return {
      newProject, sectionTypeName, color, label, icon, tooltip
    };
  }
};
</script>
