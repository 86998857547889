<template>
  <div class="tw-flex tw-items-center tw-justify-end tw-gap-2">
    <state-wrapper component="ValidationMessage">
      <transition name="fade" :duration="2000">
        <tooltip v-if="anyValidationMessages" content="Missing details, expand to finish">
          <span class="tw-text-base tw-text-error-red tw-flex tw-items-center tw-justify-center tw-pr-2">
            <i class="fas fa-exclamation-triangle" />
          </span>
        </tooltip>
      </transition>
      <transition name="fade" :duration="2000">
        <span v-if="flashSaved" class="tw-text-xs tw-text-confirm-green tw-flex tw-items-center tw-justify-center tw-gap-1.5">
          <span class="tw-hidden lg:tw-block">Saved</span>
          <i class="fas fa-check-circle" />
        </span>
      </transition>
    </state-wrapper>
    <span class="tw-flex-none">
      <span class="tw-text-cta-blue tw-cursor-pointer" @click="edit">{{ editable ? 'Edit' : 'See Details' }}</span>
      <span v-if="editable">
        |
        <span class="tw-text-error-red tw-cursor-pointer" @click="deleteRdProject">Delete</span>
      </span>
    </span>
  </div>
</template>

<script setup>
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";
import useEventsBus from "../../../../shared/eventBus";

const props = defineProps({
  slug: {
    required: true,
    type: String
  },
  editable: {
    required: true,
    type: Boolean
  }
});

const store = useStore();
const { emit, bus } = useEventsBus();
const flashSaved = ref(false);
const anyValidationMessages = computed(() => store.getters["validations/GET_ANY_VALIDATIONS_FOR_SECTION"]("RdProject", props.slug));

function edit() {
  emit("toggleRdProjectForm", props.slug);
}

function deleteRdProject() {
  const message = "Are you sure you want to delete this R&D Project?";

  if (confirm(message)) store.dispatch("project_selection/DELETE_RD_PROJECT", { slug: props.slug });
}
watch(() => bus.value.get("flashSaved"), val => {
  const [slug] = val ?? [];
  if (slug === props.slug) {
    flashSaved.value = true;
    setTimeout(() => {
      flashSaved.value = false;
    }, 1000);
  }
});
</script>
