<template>
  <nav v-cloak class="tw-h-full tw-flex tw-flex-col">
    <ClaimSidebarUserSection :user-role="userRole" :claim-period="claimPeriod" />

    <ClaimSidebarClaimSwitcher :user-role="userRole" :claim-period="claimPeriod" :claim-periods="claimPeriods" />

    <!-- Old Claim Directory Pre Aug 2023 -->
    <div v-if="legacyView" id="claim-sections" class="tw-space-y-1 tw-mt-3 tw-overflow-y-scroll-auto">
      <div id="status_sidebar">
        <div v-if="status">
          <tooltip :content="claimPeriod.status_access ? null : 'You do not have access to this section'">
            <a
              :href="claimPeriod.status_access ? `/statuses/${status.slug}/build/status` : null"
              :class="[sectionTextColouring(status, claimPeriod.status_access), 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter']"
              :aria-current="status.current ? 'page' : undefined"
              @click="trackButtonClick('status-link-sidebar')"
            >
              <span :class="claimPeriod.status_access ? '' : 'tw-text-gray-500'" class="tw-flex tw-items-center">
                <i :class="sectionIconColouring(status, claimPeriod.status_access)" class="tw-w-6 far fa-building tw-text-center tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
                Business Questionnaire
              </span>
              <ClaimSidebarSectionStatus :section="status" :current-page="status.current" section-label="status" />
            </a>
          </tooltip>
        </div>
        <div v-else>
          <tooltip :content="claimPeriod.status_access ? null : 'You do not have access to this section'">
            <a :href="claimPeriod.status_access ? `/statuses/new?cp_id=${claimPeriod.slug}` : null" class="tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter tw-text-gray-100 hover:tw-bg-deep-blue-light" aria-current="undefined">
              <span :class="claimPeriod.status_access ? 'tw-text-gray-100' : 'tw-text-gray-500'" class="tw-flex tw-items-center hover:tw-bg-deep-blue-light">
                <i :class="claimPeriod.status_access ? 'tw-text-gray-200 group-hover:tw-text-white' : ''" class="tw-w-6 far fa-building tw-flex-shrink-0 tw-text-center tw-pr-2" aria-hidden="true" />
                Business Questionnaire
              </span>
            </a>
          </tooltip>
        </div>
      </div>
      <div v-if="claimPeriod.external_funding_access" id="external_funding_sidebar">
        <a
          :href="`/claim_periods/${claimPeriod.slug}/external_funding`"
          :class="[claimPeriod.external_funding_page ? 'tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white' : 'tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white', 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter']"
          :aria-current="claimPeriod.external_funding_page ? 'page' : undefined"
          @click="trackButtonClick('external-funding-link-sidebar')"
        >
          <span class="tw-flex tw-items-center">
            <i :class="claimPeriod.external_funding_page ? 'tw-text-white' : 'tw-text-gray-200 group-hover:tw-text-white'" class="tw-w-6 far fa-hand-holding-usd tw-text-center tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
            External Funding
          </span>
          <ClaimSidebarSectionStatus
            :section-complete="claimPeriod.external_funding_complete"
            complete-tooltip="External Funding information has been provided"
            :current-page="claimPeriod.external_funding_page"
            section-label="external_funding"
          />
        </a>
      </div>
      <div v-if="claimPeriod.processing_docs_access" id="processing_docs_sidebar">
        <a
          :href="`/claim_periods/${claimPeriod.slug}/processing_docs`"
          :class="[claimPeriod.processing_docs_page ? 'tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white' : 'tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white', 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter']"
          :aria-current="claimPeriod.processing_docs_page ? 'page' : undefined"
          @click="trackButtonClick('processing-docs-link-sidebar')"
        >
          <span class="tw-flex tw-items-center">
            <i :class="claimPeriod.processing_docs_page ? 'tw-text-white' : 'tw-text-gray-200 group-hover:tw-text-white'" class="tw-w-6 far fa-id-card tw-text-center tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
            Compliance Documents
          </span>
          <ClaimSidebarSectionStatus
            :section-complete="claimPeriod.processing_docs_complete"
            complete-tooltip="Compliance Documents and contact details have been provided"
            :current-page="claimPeriod.processing_docs_page"
            section-label="compliance_docs"
          />
        </a>
      </div>
      <div id="cost_sidebar">
        <div v-if="cost">
          <tooltip :content="claimPeriod.cost_access ? null : 'You do not have access to this section'">
            <a
              :href="claimPeriod.cost_access ? `/costs/${cost.slug}/edit` : null"
              :class="[sectionTextColouring(cost, claimPeriod.cost_access), 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter']"
              :aria-current="cost.current ? 'page' : undefined"
              @click="trackButtonClick('costs-link-sidebar')"
            >
              <span :class="claimPeriod.cost_access ? '' : 'tw-text-gray-500'" class="tw-flex tw-items-center">
                <i :class="sectionIconColouring(cost, claimPeriod.cost_access)" class="tw-w-6 far fa-coins tw-text-center tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
                Costs
              </span>
              <ClaimSidebarSectionStatus :section="cost" :current-page="cost.current" section-label="costs" />
            </a>
          </tooltip>
        </div>
        <div v-else>
          <tooltip :content="claimPeriod.cost_access ? null : 'You do not have access to this section'">
            <a :href="claimPeriod.cost_access ? `/costs/new?cp_id=${claimPeriod.slug}` : null" class="tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter tw-text-gray-100 hover:tw-text-white hover:tw-bg-deep-blue-light" aria-current="undefined">
              <span :class="claimPeriod.cost_access ? 'tw-text-gray-100' : 'tw-text-gray-500'" class="tw-flex tw-items-center hover:tw-bg-deep-blue-light">
                <i :class="claimPeriod.cost_access ? 'tw-text-gray-200 group-hover:tw-text-white' : ''" class="tw-w-6 fas fa-coins tw-flex-shrink-0 tw-text-center tw-pr-2" aria-hidden="true" />
                Costs
              </span>
            </a>
          </tooltip>
        </div>
      </div>
      <div id="add_rd_sidebar">
        <div v-if="cost">
          <tooltip :content="claimPeriod.cost_rd_access ? null : 'You do not have access to this section'">
            <a
              :href="claimPeriod.cost_rd_access ? `/add_rd/${cost.slug}`: null"
              :class="[sectionTextColouring(cost, claimPeriod.cost_rd_access, 'add_rd'), 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter']"
              :aria-current="cost.add_rd ? 'page' : undefined"
              @click="trackButtonClick('add-rd-link-sidebar')"
            >
              <span :class="claimPeriod.cost_rd_access ? '' : 'tw-text-gray-500'" class="tw-flex tw-items-center">
                <i :class="sectionIconColouring(cost, claimPeriod.cost_rd_access, 'add_rd')" class="tw-w-6 fas fa-percentage tw-text-center tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
                Time Spent on R&D
              </span>

              <ClaimSidebarSectionStatus
                :section="cost"
                :check-unresolved-feedback="true"
                :rd="true"
                :current-page="cost.add_rd"
                section-label="costs_rd"
              />
            </a>
          </tooltip>
        </div>
        <div v-else>
          <tooltip :content="claimPeriod.cost_rd_access ? null : 'You do not have access to this section'">
            <a :href="claimPeriod.cost_rd_access ? `/costs/new/?cp_id=${claimPeriod.slug}?link_rd=true` : null" class="tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter tw-text-gray-100 hover:tw-text-white hover:tw-bg-deep-blue-light" aria-current="undefined">
              <span :class="claimPeriod.cost_rd_access ? 'tw-text-gray-100' : 'tw-text-gray-500'" class="tw-flex tw-items-center hover:tw-bg-deep-blue-light">
                <i :class="claimPeriod.cost_rd_access ? 'tw-text-gray-200 group-hover:tw-text-white' : ''" class="tw-w-6 fas fa-percentage tw-flex-shrink-0 tw-text-center tw-pr-2" aria-hidden="true" />
                Time Spent on R&D
              </span>
            </a>
          </tooltip>
        </div>
      </div>
      <div v-if="epw && claimPeriod.epw_access" id="epw_contract_sidebar">
        <a
          :href="`/epw_contract_reviews/${epw.slug}/edit`"
          :class="[claimPeriod.epw_contract_page ? 'tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white' : 'tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white', 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter']"
          :aria-current="claimPeriod.epw_contract_page ? 'page' : undefined"
          @click="trackButtonClick('epw-contract-link-sidebar')"
        >
          <span class="tw-flex tw-items-center">
            <i :class="claimPeriod.epw_contract_page ? 'tw-text-white' : 'tw-text-gray-200 group-hover:tw-text-white'" class="tw-w-6 far fa-file-alt tw-text-center tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
            3rd Party Contracts
          </span>
          <ClaimSidebarSectionStatus
            :section="epw"
            :check-unresolved-feedback="true"
            complete-tooltip="Your 3rd Party Contracts is complete"
            :current-page="claimPeriod.epw_contract_page"
            section-label="epw_contracts"
          />
        </a>
      </div>
      <div id="finance_docs_sidebar">
        <div v-if="financeDocument">
          <tooltip :content="claimPeriod.finance_access ? null : 'You do not have access to this section'">
            <a
              :href="claimPeriod.finance_access ? `/finance_documents/${financeDocument.slug}/edit` : null"
              :class="[sectionTextColouring(financeDocument, claimPeriod.finance_access), 'tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-tracking-tight tw-font-semibold']"
              :aria-current="financeDocument.current ? 'page' : undefined"
              @click="trackButtonClick('finance-docs-link-sidebar')"
            >
              <span :class="claimPeriod.finance_access ? 'tw-text-gray-100' : 'tw-text-gray-500'" class="tw-flex tw-items-center">
                <i :class="sectionIconColouring(financeDocument, claimPeriod.finance_access)" class="tw-w-6 far fa-file-invoice tw-text-center tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
                Finance Documents
              </span>
              <ClaimSidebarSectionStatus :section="financeDocument" :current-page="financeDocument.current" section-label="finance_docs" />
            </a>
          </tooltip>
        </div>
        <div v-else>
          <tooltip :content="claimPeriod.finance_access ? null : 'You do not have access to this section'">
            <a :href="claimPeriod.finance_access ? '/finance_documents/new' : null" class="tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter tw-text-gray-100 hover:tw-bg-deep-blue-light" aria-current="undefined">
              <span :class="claimPeriod.finance_access ? '' : 'tw-text-gray-500'" class="tw-flex tw-items-center tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white">
                <i :class="claimPeriod.finance_access ? 'tw-text-gray-200 group-hover:tw-text-white' : ''" class="tw-w-6 fas far fa-file-alt tw-flex-shrink-0 tw-pr-2" aria-hidden="true" />
                Finance Documents
              </span>
            </a>
          </tooltip>
        </div>
      </div>
      <div id="projects_sidebar">
        <Disclosure v-slot="{ open }" :default-open="projectsOpen(projects, claimPeriod.projects_access)">
          <DisclosureButton :class="claimPeriod.projects_access ? 'tw-cursor-pointer tw-text-gray-100 hover:tw-bg-deep-blue-light' : 'tw-cursor-auto tw-text-gray-500'" class="tw-group tw-border-none tw-outline-none tw-w-full tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter focus:tw-outline-none">
            <tooltip class="tw-flex-1 tw-grow tw-flex tw-jusitfy-start" :content="claimPeriod.projects_access ? `Manage Projects` : 'You do not have access to this section'">
              <a
                :href="claimPeriod.projects_access ? `/claim_periods/${claimPeriod.slug}/manage_projects` : null"
                :class="claimPeriod.projects_access ? 'hover:tw-text-white tw-text-gray-100' : 'tw-text-gray-500'"
                class="tw-w-full tw-text-left tw-flex tw-items-center"
                @click="trackButtonClick('manage-projects-link-sidebar')"
              >
                <i :class="claimPeriod.projects_access ? 'tw-text-gray-200 group-hover:tw-text-white' : 'tw-text-gray-500'" class="tw-w-6 fas fa-tasks tw-pr-2 tw-text-left tw-text-sm tw-flex-shrink-0 tw-flex-none" aria-hidden="true" />
                <span :class="['tw-flex tw-items-center tw-gap-2', claimPeriod.projects_access ? 'hover:tw-text-white' : 'tw-cursor-text']">
                  <span class="tw-text-sm">Projects</span>
                  <tooltip content="This claim is on the new Project Methodology, click to find out more">
                    <span v-if="claimPeriod.new_project_methodology" class="tw-inline-flex tw-text-white tw-items-center tw-rounded-full tw-border tw-border-white tw-border-dashed tw-px-2 tw-py-0.5 tw-text-xs tw-font-medium">New</span>
                  </tooltip>
                </span>
              </a>
            </tooltip>
            <span v-if="claimPeriod.projects_access" class="flex-none tw-flex tw-items-center">
              <tooltip content="Add New Project">
                <a
                  :href="`/projects/?claim_period_id=${claimPeriod.slug}`"
                  data-method="POST"
                  :data-confirm="`${dataConfirmMessage(claimPeriod.key, 'Project')}`"
                  class="tw-bg-transparent tw-text-gray-100 hover:tw-text-gray-300 tw-px-4"
                  @click="trackButtonClick('add-project-link-sidebar')"
                >
                  <i class="fas fa-plus tw-flex-shrink-0 tw-flex-none" aria-hidden="true" />
                </a>
              </tooltip>
              <tooltip :content="`${open ? 'Close' : 'Open'} Projects`" class="tw-flex tw-items-center">
                <i :class="open ? 'tw-transform tw-rotate-180' : ''" class="fas fa-chevron-down tw-flex-shrink-0 tw-text-sm tw-flex-none tw-px-0.5" aria-hidden="true" />
              </tooltip>
            </span>
          </DisclosureButton>
          <DisclosurePanel v-if="claimPeriod.projects_access" class="">
            <div v-for="project in projects" :key="project.title" class="tw-bg-darker-blue">
              <span v-if="claimPeriod.new_project_methodology" :class="[project.current ? 'tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white' : 'tw-text-gray-100 hover:tw-bg-deep-blue-light', 'tw-group tw-flex tw-items-center tw-px-4 tw-py-1.5 tw-text-xs tw-font-semibold tw-tracking-tighter']">
                <a
                  :href="claimPeriod.projects_access ? `/projects/${project.slug}/build/project` : null"
                  :class="project.current ? 'tw-text-white' : 'tw-text-gray-100'"
                  class="tw-flex tw-justify-between tw-items-center tw-text-xs tw-flex-1 tw-pl-2 hover:tw-text-white tw-truncate"
                  :aria-current="project.current ? 'page' : undefined"
                  @click="trackButtonClick('project-link-sidebar', { slug: project.slug })"
                >
                  <tooltip class="tw-truncate tw-flex tw-items-center" :content="truncate(project.title, 25)">
                    <i :class="project.current ? 'tw-text-white' : 'tw-text-gray-200 group-hover:tw-text-white'" class="fas fa-long-arrow-alt-right tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
                    <span :class="project.title ? '' : 'tw-text-white'" class="tw-truncate tw-leading-4">{{ project.title ? project.title : 'Untitled Project' }}</span>
                  </tooltip>
                  <ClaimSidebarSectionStatus
                    :section="project"
                    :check-unresolved-feedback="true"
                    :current-page="project.current"
                    section-label="projects"
                  />
                </a>
              </span>
              <Disclosure v-else v-slot="{ open }" :default-open="projectOpen(project, projects)">
                <span :class="[project.current ? 'tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white' : 'tw-text-gray-100 hover:tw-bg-deep-blue-light', 'tw-group tw-flex tw-items-center tw-px-4 tw-py-1.5 tw-text-xs tw-font-semibold tw-tracking-tighter']">
                  <tooltip :content="`${open ? 'Close' : 'Open'} Challenges`">
                    <DisclosureButton :class="[project.current ? 'tw-text-white hover:tw-text-gray-100 hover:tw-bg-muted-blue' : 'tw-text-gray-200 hover:tw-text-gray-100 hover:tw-bg-deep-blue-light']" class="tw-z-10 tw-border-none tw-bg-transparent tw-flex-shrink-0 tw-outline-none tw-p-0 tw-pr-2 focus:tw-outline-none">
                      <i :class="open ? 'tw-transform tw-rotate-180' : ''" class="fas fa-chevron-down tw-flex-shrink-0 tw-text-xs tw-flex-none" aria-hidden="true" />
                    </DisclosureButton>
                  </tooltip>
                  <a
                    :href="claimPeriod.projects_access ? `/projects/${project.slug}/build/project` : null"
                    :class="project.current ? 'tw-text-white' : 'tw-text-gray-100'"
                    class="tw-flex tw-justify-between tw-items-center tw-text-xs tw-flex-1 hover:tw-text-white tw-truncate"
                    :aria-current="project.current ? 'page' : undefined"
                    @click="trackButtonClick('project-link-sidebar', { slug: project.slug })"
                  >
                    <tooltip class="tw-truncate" :content="truncate(project.title, 25)">
                      <span :class="project.title ? '' : 'tw-text-white'" class="tw-truncate">{{ project.title ? project.title : 'Untitled Project' }}</span>
                    </tooltip>
                    <ClaimSidebarSectionStatus
                      :section="project"
                      :check-unresolved-feedback="true"
                      :current-page="project.current"
                      section-label="projects"
                    />
                  </a>
                </span>
                <DisclosurePanel class="tw-bg-darkest-blue">
                  <div v-for="challenge in project.challenges" :key="challenge.title">
                    <a
                      :href="claimPeriod.projects_access ? `/challenges/${challenge.slug}/build/challenge` : null"
                      :class="[challenge.current ? 'tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white' : 'tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white', 'tw-group tw-flex tw-items-center tw-justify-between tw-pl-6 tw-pr-4 tw-py-1 tw-text-xs tw-font-semibold tw-tracking-tighter tw-truncate']"
                      :aria-current="challenge.current ? 'page' : undefined"
                      @click="trackButtonClick('challenge-link-sidebar', { slug: challenge.slug })"
                    >
                      <tooltip class="tw-truncate" :content="truncate(challenge.title, 25)">
                        <i :class="challenge.current ? 'tw-text-white' : 'tw-text-gray-200 group-hover:tw-text-white'" class="fas fa-long-arrow-alt-right tw-pr-2 tw-flex-shrink-0" aria-hidden="true" />
                        <span :class="challenge.title ? '' : 'tw-text-white'" class="tw-truncate">{{ challenge.title ? challenge.title : 'Untitled Challenge' }}</span>
                      </tooltip>
                      <ClaimSidebarSectionStatus
                        :section="challenge"
                        :check-unresolved-feedback="true"
                        :current-page="challenge.current"
                      />
                    </a>
                  </div>
                  <div v-if="project.challenges.length < 6 && !project.ready_for_review && claimPeriod.projects_access">
                    <tooltip :content="`${project.challenges.length > 0 ? `Add a new challenge to ${project.title ? project.title : 'this project'}` : `${project.title ? project.title : 'This project'} has no challenges, click to add one`}`">
                      <a
                        :href="claimPeriod.projects_access ? `/challenges/?project_id=${project.id}` : null"
                        :data-confirm="`${dataConfirmMessage(project.key, 'Challenge')}`"
                        data-method="POST"
                        class="tw-text-white hover:tw-text-white tw-bg-transparent hover:tw-bg-deep-blue-light tw-group tw-flex tw-items-center tw-px-6 tw-py-1 tw-text-xs tw-font-semibold tw-tracking-tighter"
                        @click="trackButtonClick('add-challenge-sidebar')"
                      >
                        <i class="fas fa-plus tw-flex-shrink-0 tw-flex-none tw-text-gray-200 group-hover:tw-text-white tw-pr-2" aria-hidden="true" />
                        <span class="tw-italic">Add New Challenge</span>
                      </a>
                    </tooltip>
                  </div>
                </DisclosurePanel>
              </Disclosure>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>

    <!-- New Claim Directory with Aug 2023 Workflow change -->
    <div v-else id="claim-sections" class="tw-space-y-3 tw-mt-3 tw-overflow-y-scroll-auto">
      <div class="tw-text-gray-100 tw-px-4 tw-py-1 tw-text-sm tw-tracking-tight tw-font-semibold">
        Setup
        <div class="tw-space-y-1 tw-pt-1">
          <div v-for="item in sidebarItems.setup" :key="item.text">
            <ClaimSidebarItem
              v-if="item.displayCondition"
              :item="item"
            />
          </div>
        </div>
      </div>

      <div class="tw-text-gray-100 tw-text-sm tw-tracking-tight tw-font-semibold">
        <Tooltip :content="projectsHeadingTooltip">
          <a :href="claimPeriod.projects_access ? `/claim_periods/${claimPeriod.slug}/manage_projects` : null" class="tw-group tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-1 tw-text-sm tw-font-semibold tw-tracking-tighter tw-text-gray-100 hover:tw-bg-deep-blue-light" aria-current="undefined">
            <span :class="claimPeriod.projects_access ? '' : 'tw-text-gray-500'" class="tw-flex tw-items-center tw-gap-2 tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white">
              Projects
            </span>
          </a>
        </Tooltip>

        <div class="tw-space-y-1 tw-px-4">
          <ClaimSidebarItem
            v-for="project in sidebarItems.projects"
            :key="project.title"
            :item="project"
          />
        </div>
      </div>

      <div class="tw-text-gray-100 tw-px-4 tw-py-1 tw-text-sm tw-tracking-tight tw-font-semibold">
        Costs
        <div class="tw-space-y-1 tw-pt-1">
          <div v-for="item in sidebarItems.costs" :key="item.text">
            <ClaimSidebarItem
              v-if="item.displayCondition"
              :item="item"
            />
          </div>
        </div>
      </div>

      <div class="tw-text-gray-100 tw-px-4 tw-py-1 tw-text-sm tw-tracking-tight tw-font-semibold">
        Allocation
        <div class="tw-space-y-1 tw-pt-1">
          <div v-for="item in sidebarItems.allocation" :key="item.text">
            <ClaimSidebarItem
              v-if="item.displayCondition"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import _ from "lodash";

import { Disclosure,
  DisclosureButton,
  DisclosurePanel } from "@headlessui/vue";
import Tooltip from "@generic/tooltip.vue";
import { truncate } from "@shared/helpers.js";
import { SECTION_STATUS } from "@shared/section_status.js";
import ClaimSidebarSectionStatus from "./claim_sidebar_section_status.vue";
import ClaimSidebarItem from "./claim_sidebar_item.vue";
import ClaimSidebarUserSection from "./claim_sidebar_user_section.vue";
import ClaimSidebarClaimSwitcher from "./claim_sidebar_claim_switcher.vue";

const props = defineProps({
  claimData: {
    type: Object,
    required: true
  },
  claimPeriods: {
    type: Array,
    required: true
  }
});

const trackButtonClick = inject("trackButtonClick");
const store = useStore();
const userRole = computed(() => store.getters["users/GET_USER_ROLE"]);
const claimPeriod = computed(() => props.claimData?.claim_period);
const projects = computed(() => props.claimData?.projects);
const financeDocument = computed(() => props.claimData?.finance_document);
const cost = computed(() => props.claimData?.cost);
const status = computed(() => props.claimData?.status);
const epw = computed(() => claimPeriod.value?.epw_contract_review);
const projectSelection = computed(() => claimPeriod.value?.project_selection);
const projectCostBreakdown = computed(() => claimPeriod.value?.project_cost_breakdown);
const projectSelectionCompleted = computed(() => projectSelection.value?.section_status >= SECTION_STATUS.SECTION_COMPLETE);
const legacyView = computed(() => claimPeriod.value?.pre_aug_2023_workflow);
const showExternalWorkers = computed(() => claimPeriod.value?.external_workers_section_exists);

const projectsHeadingTooltip = computed(() => {
  if (claimPeriod.value?.project_selection_enabled && claimPeriod.value?.projects_access) {
    return projectSelectionCompleted.value ? "Manage all Projects" : "Complete Project Selection first to proceed with Projects";
  } else if (claimPeriod.value?.projects_access) {
    return "Manage all Projects";
  }
});

function anyUnresolvedFeedback(type, slug) {
  return store.getters["feedbacks/GET_ANY_UNRESOLVED_FEEDBACK_FOR_SECTION"](type, slug);
}

function dataConfirmMessage(key, modelString) {
  const response = props.claimData.narrative_response[key];

  return response != null && response.status === "CREATE_WITH_WARNING"
    ? response.message
    : `Are you sure you want to create a new ${modelString.toLowerCase()}?`;
}

function projectsOpen(projects, projectsAccess) {
  if (!projectsAccess) return false;

  const rfrs = _.filter(projects, p => { p.ready_for_review; });
  if (rfrs.length < projects.length) return true;

  const projectFeedbacks = _.filter(projects, p => { anyUnresolvedFeedback(p.className, p.slug); });
  const challengeFeedbacks = _.filter(projects, p => _.filter(p.challenges, c => { anyUnresolvedFeedback(c.className, c.slug); }).length > 0);

  if (projectFeedbacks.length > 0 || challengeFeedbacks.length > 0) {
    return true;
  } else if (rfrs.length === projects.length) {
    return false;
  } else {
    return true;
  }
}

function projectOpen(project, projects) {
  const feedbacks = _.filter(project.challenges, c => { anyUnresolvedFeedback(c.className, c.slug); });

  if (feedbacks.length > 0) {
    return true;
  } else if (project.ready_for_review) {
    return false;
  } else if (projects?.length > 2 || project.challenges?.length > 2) {
    return false;
  } else {
    return project.challenges.length > 0;
  }
}

function sectionIconColouring(section, access, current = "current") {
  if (!access) {
    return "tw-text-gray-500";
  } else if (current === true || section?.[current]) {
    return "tw-text-white";
  } else {
    return "tw-text-gray-200 group-hover:tw-text-white";
  }
}

function sectionTextColouring(section, access, current = "current") {
  if (!access) {
    return "tw-text-gray-500";
  } else if (current === true || section?.[current]) {
    return "tw-bg-light-muted-blue tw-text-white hover:tw-bg-muted-blue hover:tw-text-white";
  } else {
    return "tw-text-gray-100 hover:tw-bg-deep-blue-light hover:tw-text-white";
  }
}

const sidebarItems = computed(() => ({
  setup: [
    {
      displayCondition: true,
      text: "Business Questionnaire",
      iconClass: "fa-file-alt",
      claimPeriod: claimPeriod.value,
      sectionObject: status.value,
      trackId: "status-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.status_access,
      isOnPage: status.value?.current,
      url: status.value ? `/statuses/${status.value?.slug}/build/status` : `/statuses/new?cp_id=${claimPeriod.value?.slug}`,
      sectionLabel: "status"
    },
    {
      displayCondition: claimPeriod.value?.processing_docs_access,
      text: "Compliance",
      iconClass: "fa-file-search",
      claimPeriod: claimPeriod.value,
      sectionObject: {},
      isSectionComplete: claimPeriod.value?.processing_docs_complete,
      trackId: "processing-docs-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.processing_docs_access,
      isOnPage: claimPeriod.value?.processing_docs_page,
      url: `/claim_periods/${claimPeriod.value?.slug}/processing_docs`,
      sectionLabel: "compliance_docs",
      sectionStatusTooltipContent: "Compliance Documents and contact details have been provided"
    },
    {
      displayCondition: true,
      text: "Project Selection",
      iconClass: "fa-ballot-check",
      claimPeriod: claimPeriod.value,
      sectionObject: projectSelection.value,
      trackId: "project-selection-link-sidebar",
      isEnabled: claimPeriod.value?.project_selection_enabled,
      canAccess: claimPeriod.value?.project_selection_access,
      isOnPage: claimPeriod.value?.project_selection_page,
      url: projectSelection.value ? projectSelection.value?.redirect_url : `/claim_periods/${claimPeriod.value?.slug}/manage_projects`,
      sectionLabel: "project_selection",
      sectionStatusTooltipContent: "Project Selection process has been completed"
    },
    {
      displayCondition: true,
      text: "Finance Documents",
      iconClass: "fa-folder-open",
      claimPeriod: claimPeriod.value,
      sectionObject: financeDocument.value,
      trackId: "finance-docs-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.finance_access,
      isOnPage: financeDocument.value?.current,
      url: financeDocument.value ? `/finance_documents/${financeDocument.value?.slug}/edit` : "/finance_documents/new",
      sectionLabel: "finance_docs"
    },
    {
      displayCondition: claimPeriod.value?.external_funding_access,
      text: "External Funding",
      iconClass: "fa-sack",
      claimPeriod: claimPeriod.value,
      sectionObject: {},
      trackId: "external-funding-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.external_funding_access,
      isOnPage: claimPeriod.value?.external_funding_page,
      url: `/claim_periods/${claimPeriod.value?.slug}/external_funding`,
      sectionLabel: "external_funding",
      sectionStatusTooltipContent: "External Funding information has been provided"
    }
  ],
  projects: projects.value.map(project => ({
    text: truncate(project.title, 25),
    iconClass: "fa-folder",
    claimPeriod: claimPeriod.value,
    sectionObject: project,
    trackId: "project-link-sidebar",
    isEnabled: true,
    canAccess: claimPeriod.value?.projects_access,
    isOnPage: project.current,
    url: `/projects/${project.slug}/build/project`,
    sectionLabel: "projects",
    checkUnresolvedFeedback: true,
    tooltip: project.title
  })),
  costs: [
    {
      displayCondition: true,
      text: "Costs",
      iconClass: "fa-coins",
      claimPeriod: claimPeriod.value,
      sectionObject: cost.value,
      trackId: "costs-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.cost_access,
      isOnPage: cost.value?.current,
      url: cost.value ? `/costs/${cost.value?.slug}/edit` : `/costs/new?cp_id=${claimPeriod.value?.slug}`,
      sectionLabel: "costs"
    },
    {
      displayCondition: epw.value && claimPeriod.value?.epw_access,
      text: "3rd Party Contracts",
      iconClass: "fa-file-signature",
      claimPeriod: claimPeriod.value,
      sectionObject: epw.value,
      trackId: "epw-contract-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.epw_access,
      isOnPage: claimPeriod.value?.epw_contract_page,
      url: `/epw_contract_reviews/${epw.value?.slug}/edit`,
      sectionLabel: "epw_contracts",
      checkUnresolvedFeedback: true,
      sectionStatusTooltipContent: "Your 3rd Party Contracts is complete"
    },
    {
      displayCondition: true,
      text: "Time Spent on R&D",
      iconClass: "fa-percentage",
      claimPeriod: claimPeriod.value,
      sectionObject: cost.value,
      trackId: "add-rd-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.cost_rd_access,
      isOnPage: cost.value?.add_rd,
      url: cost.value ? `/add_rd/${cost.value?.slug}` : `/costs/new/?cp_id=${claimPeriod.value.slug}?link_rd=true`,
      sectionLabel: "costs_rd",
      checkUnresolvedFeedback: true,
      rd: true
    },
    {
      displayCondition: showExternalWorkers.value,
      text: "EPW Additional Info",
      iconClass: "fa-file-signature",
      claimPeriod: claimPeriod.value,
      trackId: "external-workers-link-sidebar",
      isEnabled: true,
      canAccess: true,
      isOnPage: false,
      isSectionComplete: claimPeriod.value?.external_workers_section_completed,
      url: `/claim_periods/${claimPeriod.value?.slug}/external_workers`,
      sectionLabel: "external_workers"
    }
  ],
  allocation: [
    {
      displayCondition: true,
      text: "Assigning Costs to Projects",
      iconClass: "fa-sliders-h",
      claimPeriod: claimPeriod.value,
      sectionObject: projectCostBreakdown.value,
      trackId: "project-cost-breakdown-link-sidebar",
      isEnabled: claimPeriod.value?.project_cost_breakdown_enabled,
      canAccess: claimPeriod.value?.project_cost_breakdown_access && !projectCostBreakdown.value?.not_required,
      isOnPage: claimPeriod.value?.project_cost_breakdown_page,
      url: projectCostBreakdown.value ? `/project_cost_breakdowns/${projectCostBreakdown.value?.slug}/` : `/claim_periods/${claimPeriod.value?.slug}/dashboard`,
      sectionLabel: "project_cost_breakdown",
      sectionStatusTooltipContent: projectCostBreakdown.value?.not_required ? "This section is not needed as your claim only has 1 project" : "All Costs have been assigned to their relevant Projects"
    },
    {
      displayCondition: claimPeriod.value?.project_grant_allocation_required,
      text: "Allocate Grant Funding",
      iconClass: "fa-chart-pie",
      claimPeriod: claimPeriod.value,
      sectionObject: {},
      isSectionComplete: claimPeriod.value?.project_grant_allocation_completed,
      trackId: "project-grant-allocation-link-sidebar",
      isEnabled: true,
      canAccess: claimPeriod.value?.project_grant_allocation_access,
      isOnPage: claimPeriod.value?.project_grant_allocation_page,
      url: `/claim_periods/${claimPeriod.value?.slug}/project_grant_allocations`,
      sectionLabel: "project_grant_allocations",
      sectionStatusTooltipContent: claimPeriod.value?.project_grant_allocation_completed ? "Grant Claim Allocation has been completed" : "",
      tooltipContent: projectCostBreakdown.value?.["completed?"] ? "" : "Before you can proceed you must complete the 'Assigning Costs to Project' section"
    }
  ]
}));
</script>
