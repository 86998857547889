<template>
  <div class="tw-overflow-x-auto tw-w-full tw-mt-3">
    <table class="tw-min-w-full tw-shadow tw-overflow-hidden tw-divide-y tw-divide-gray-200">
      <thead class="">
        <tr>
          <th
            v-for="(subcostType, idx) in dropdownData.costs"
            :key="idx"
            scope="col"
            :class="isComplete ? 'tw-text-green-700' : 'tw-text-light-grey-blue'"
            class="tw-px-1 tw-py-1 tw-text-center tw-text-xs tw-font-semibold"
          >
            {{ titleCase(getSubcostName(subcostType)) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="(subcostType, idx) in dropdownData.costs" :key="idx" class="tw-px-1 tw-py-1 tw-whitespace-nowrap tw-text-center">
            <Tooltip :id="`${sectionType}_${subcostType.type}_progression`" :content="tooltipContent(subcostType)" class="tw-inline-flex tw-items-center tw-gap-2 tw-text-xs tw-text-light-grey-blue">
              <div :class="subcostColor(subcostType)">
                {{ tableContent(subcostType) }}
              </div>
              <i v-if="!isComplete && isCosts && (subcostType.valid_costs_count < subcostType.total_count)" class="fas fa-exclamation-triangle tw-text-xs tw-text-yellow-400" />
            </Tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { titleCase } from "@shared/helpers.js";
import Tooltip from "@generic/tooltip.vue";

const props = defineProps({
  sectionData: {
    type: Object,
    required: false
  },
  dropdownData: {
    type: Object,
    required: true
  },
  sectionType: {
    type: String,
    required: true,
    validator: str => {
      const validSectionTypes = [
        "costs",
        "costs_rd",
        "project_cost_breakdown"
      ];

      return validSectionTypes.indexOf(str) >= 0;
    }
  },
  isComplete: {
    type: Boolean
  }
});

const isRd = computed(() => props.sectionType === "costs_rd");
const isCosts = computed(() => props.sectionType === "costs");
const isProjectLinking = computed(() => props.sectionType === "project_cost_breakdown");

const completedValue = computed(() => {
  if (isCosts.value) {
    return "valid_costs_count";
  } else if (isRd.value) {
    return "valid_rd_count";
  } else {
    return "fully_allocated_count";
  }
});

const totalValue = computed(() => (isCosts.value || isRd.value ? "total_count" : "fully_allocated_count"));

function tableContent(subcost_type) {
  if (isCosts.value) {
    return subcost_type.total_count > 0 ? subcost_type.total_count : 0;
  } else {
    return asPortionOf(subcost_type, completedValue.value, totalValue.value);
  }
}

function asPortionOf(subcost_type, completed, total) {
  if (props.isComplete) {
    return `${subcost_type[total]} / ${subcost_type[total]}`;
  } else if (subcost_type[total] > 0) {
    return `${subcost_type[completed]} / ${subcost_type[total]}`;
  } else {
    return "N/A";
  }
}

function subcostColor(subcost_type) {
  if (subcost_type[totalValue.value] === 0) {
    return "";
  } else if (props.isComplete || (subcost_type[completedValue.value] === subcost_type[totalValue.value])) {
    return "tw-text-green-700 tw-font-semibold";
  } else {
    return "";
  }
}

function tooltipContent(subcost_type) {
  if (props.isComplete) {
    return "";
  } else if (subcost_type[totalValue.value] === 0) {
    return `No ${getSubcostName(subcost_type, true)} costs provided`;
  } else if (subcost_type[completedValue.value] < subcost_type[totalValue.value]) {
    return `Some ${getSubcostName(subcost_type, true)} costs ${missingPhrase.value}`;
  } else {
    return "";
  }
}

const missingPhrase = computed(() => {
  if (isCosts.value) {
    return "are incomplete";
  } else if (isRd.value) {
    return "are missing an R&D percentage";
  } else {
    return "have not been fully allocated";
  }
});

function getSubcostName(subcost_type, prefix = false) {
  let name = "";
  if (prefix && isProjectLinking.value) {
    name += "qualifying ";
  }

  if (subcost_type.type === "Subcontractors") {
    name += "external worker";
  } else {
    name += subcost_type.type.slice(0, -1).toLowerCase();
  }
  return name;
}
</script>
