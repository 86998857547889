<template>
  <div>
    <label v-if="label" class="tw-text-xs tw-mb-2" :for="id">
      {{ label }}
    </label>
    <div class="tw-w-full">
      <div v-if="editable" class="tw-relative tw-rounded-md tw-shadow-sm">
        <div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
          <span class="tw-text-gray-500 sm:tw-text-sm">
            <i class="fas fa-pound-sign fa-sm" />
          </span>
        </div>
        <input
          v-if="useEmitter"
          :id="id"
          ref="input"
          v-model="model"
          type="number"
          :class="[theme.forms.input, borderStyle]"
          class="tw-pl-6 tw-text-xs"
          :aria-describedby="ariaDescribedby"
          step="0.01"
          onkeydown="return event.keyCode !== 69 || event.keyCode !== 189"
          @input="handleInput"
          @keydown.up.prevent
          @keydown.down.prevent
        >
        <input
          v-else
          :id="`${sectionType}_${sectionSlug}_${field.label}`"
          ref="input"
          v-model="field.value"
          type="number"
          :name="field.label"
          min="0"
          :class="[theme.forms.input, borderStyle]"
          class="tw-pl-6 tw-text-xs"
          @input="handleInput"
        >
        <span class="tw-absolute tw-right-0 tw-inset-y-0 tw-flex tw-items-center tw-m-1">
          <saving-spin-indicator
            v-if="useEmitter"
            ref="spinner"
            manual
            no-text
            :has-errored="saveState === 'saveError'"
          />
          <saving-spin-indicator
            v-else
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :fields="[field.label]"
            bg-color
            @finishedSaving="clean()"
          />
        </span>
      </div>
      <div v-else>
        <div class="tw-text-gray-750 tw-text-sm tw-flex tw-text-center tw-gap-1">
          <span>£ {{ field.value || value || '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FIELD_STATE } from "../shared/field_state.js";
import { css } from "../../../../shared/theme.js";

export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Number, String]
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String
    },
    editable: {
      type: Boolean,
      default: false
    },
    useEmitter: {
      type: Boolean
    },
    saveState: {
      type: String
    },
    label: {
      type: String
    },
    id: {
      type: String
    },
    ariaDescribedby: {
      type: String
    }
  },
  emits: ["currency-input"],
  data() {
    return {
      theme: css,
      borderStyle: null,
      model: this.value,
      previousValidValue: this.value
    };
  },
  watch: {
    "field.value": function () {
      this.debouncedBorderStyle();
    },
    "field.state": function () {
      this.debouncedBorderStyle();
    },
    model() {
      this.$emit("currency-input", this.model);
    },
    saveState() {
      if (this.saveState === "saving") {
        this.$refs.spinner.startSpinner();
      } else if (this.saveState === "saveSuccess") {
        this.$refs.spinner.stopSpinner();
        this.borderStyle = this.theme.borderStyles.styleSaved;
        setTimeout(() => this.borderStyle = this.theme.borderStyles.untouched, 1300);
      } else if (this.saveState === "saveError") {
        this.$refs.spinner.stopSpinner();
        this.borderStyle = this.theme.borderStyles.error;
        setTimeout(() => this.borderStyle = this.theme.borderStyles.untouched, 1300);
      }
    }
  },
  mounted() {
    this.debouncedBorderStyle = _.debounce(this.changeBorderStyle, 1000);
  },
  methods: {
    changeBorderStyle() {
      if (this.field.state === FIELD_STATE.EDITED) {
        this.borderStyle = this.theme.borderStyles.edited;
      } else if (this.field.state === FIELD_STATE.SAVED) {
        this.borderStyle = this.theme.borderStyles.styleSaved;
      }
    },
    clean() {
      this.borderStyle = this.theme.borderStyles.untouched;
    },
    handleInput(event) {
      const hasThreeDecimalPlaces = /^-?\d+\.\d{3,}$/;
      const isNegative = event.target.value < 0;
      const numberAsString = event.target.value.toString();

      if (hasThreeDecimalPlaces.test(numberAsString)) {
        event.target.value = parseFloat(numberAsString.slice(0, -1));
      } else if (!event.target.validity.valid || isNegative) {
        event.target.value = this.previousValidValue;
      }

      this.model = event.target.value;
      this.previousValidValue = event.target.value;
    },
    focusInput() {
      this.$refs.input.focus();
    }
  }
};
</script>
