<template>
  <div class="tw-w-full">
    <div v-if="editable && !isReviewer" :class="['tw-max-w-lg tw-flex tw-gap-3', basicForm ? null : 'tw-flex-col']">
      <label :for="`${sectionType}_${sectionSlug}_${field.label}_true`" class="tw-mb-0">
        <div class="tw-flex tw-items-center tw-gap-x-2">
          <input
            :id="`${sectionType}_${sectionSlug}_${field.label}_true`"
            v-model="field.value"
            type="radio"
            class="
              tw-form-radio tw-border-1 tw-text-deep-blue tw-border-solid focus:tw-ring-deep-blue
            "
            value="true"
          >
          <div class="tw-font-medium tw-text-sm tw-text-gray-700">{{ fieldValueToLabel[true] || 'Yes' }}</div>
        </div>
      </label>

      <label :for="`${sectionType}_${sectionSlug}_${field.label}_false`" class="tw-mb-0">
        <div class="tw-flex tw-items-center tw-gap-x-2">
          <input
            :id="`${sectionType}_${sectionSlug}_${field.label}_false`"
            v-model="field.value"
            type="radio"
            class="
              tw-form-radio tw-border-1 tw-text-deep-blue tw-border-solid focus:tw-ring-deep-blue
            "
            value="false"
          >
          <div class="tw-font-medium tw-text-sm tw-text-gray-700">{{ fieldValueToLabel[false] || 'No' }}</div>
        </div>
      </label>
      <saving-spin-indicator
        v-if="basicForm"
        :section-type="sectionType"
        :section-slug="sectionSlug"
        :fields="[field.label]"
      />
    </div>
    <div v-else>
      <div class="tw-text-gray-500 tw-text-sm">
        {{ field.value ? 'Yes' : 'No' }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_MULTI_SELECT_OPTIONS } from "../../../../vuex-store/modules/sections.js";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    sectionType: {
      type: String,
      validator: value => {
        const validSections = [
          "Project",
          "Challenge",
          "ClaimPeriod",
          "Status",
          "Grant",
          "FinanceDocument",
          "RdProject"
        ];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isReviewer: {
      type: Boolean,
      required: true
    },
    basicForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayValues: { true: "Yes", false: "No", null: "(Not answered)" }
    };
  },
  computed: {
    ...mapGetters({
      options: `sections/${GET_MULTI_SELECT_OPTIONS}`
    }),
    fieldValueToLabel() {
      const valueToLabel = {};
      const options = this.options(`${this.sectionType}:${this.sectionSlug}:${this.field.label}`);

      if (!options) {
        return valueToLabel;
      }

      options.forEach(option => {
        valueToLabel[option[0]] = option[1];
      });
      return valueToLabel;
    }
  }
};
</script>
