<template>
  <div class="tw-w-full tw-text-xs tw-flex tw-mt-2 tw-flex-col tw-gap-y-1.5">
    <template v-for="option in feedbackRatOptions" :key="option.id">
      <div v-if="option.shouldShow" @click.stop>
        <label :for="option.id" class="tw-mb-0">
          <div class="tw-flex tw-items-center tw-gap-x-2">
            <input
              :id="option.id"
              v-model="feedbackRat"
              type="radio"
              :class="[theme.forms.radio]"
              :value="option.value"
            >
            <div class="tw-font-medium tw-text-gray-700">{{ option.label }}</div>
          </div>
        </label>
      </div>
    </template>
    <textarea
      v-model="feedbackComment"
      rows="2"
      :class="[theme.forms.textarea, 'tw-mb-1']"
      placeholder="Please indicate why you have updated this feedback"
      @click.stop
    />
    <ButtonComponent
      :id="`admin-update-${feedback.feedbackable_type.toLowerCase()}-feedback`"
      text="Update Feedback"
      icon="save"
      :colour="buttonColour"
      :full-width="showInSmall"
      size="small"
      :disable-click="error !== null"
      :tooltip="error ? error : null"
      @click="updateFeedback"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { css } from "../../shared/theme.js";

import ButtonComponent from "../generic/button_component.vue";

export default {
  name: "UpdateFeedbackForAdmins",
  components: {
    ButtonComponent
  },
  props: {
    sectionSlug: {
      type: String,
      required: true
    },
    feedback: {
      type: Object,
      required: true
    },
    showInSmall: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ["hideSectionFeedbackTooltip"],
  setup(props, context) {
    const store = useStore();
    const theme = css;

    const claimPeriodSlug = computed(() => store.getters["feedbacks/GET_CLAIM_PERIOD_SLUG"]);

    const feedbackRatOptions = [
      { label: "Review/Accept", value: "Review/Accept", id: "rat_review", shouldShow: true },
      { label: "Changes Needed", value: "Changes Needed", id: "rat_changes_needed", shouldShow: true },
      { label: "Non-Qualifying", value: "Accept Non-Qualifying", id: "rat_non_qualifying", shouldShow: props.feedback.col_ref == "pc_rd" }
    ];

    const feedbackRat = ref(props.feedback.resolve_activity_type);
    const feedbackComment = ref("");
    const error = ref("You must leave a comment to update this feedback");

    const triggerUpdate = computed(() => feedbackRat.value !== props.feedback.resolve_activity_type && feedbackComment.value.trim() !== "");

    function updateFeedback() {
      if (!triggerUpdate.value) return;

      const feedbackPayload = {
        fields: {
          resolve_activity_type: feedbackRat.value,
          comment: feedbackComment.value
        },
        feedbackable_type: props.feedback.feedbackable_type,
        feedbackable_slug: props.sectionSlug,
        claimPeriodSlug: claimPeriodSlug.value,
        id: props.feedback.id
      };

      store.dispatch("feedbacks/UPDATE_FEEDBACK", feedbackPayload).then(() => {
        context.emit("hideSectionFeedbackTooltip");
        feedbackComment.value = "";
      });
    }

    const buttonColour = computed(() => {
      if (triggerUpdate.value) {
        error.value = null;
        return "cta";
      }

      return "disabled";
    });

    return {
      theme,
      feedbackRat,
      updateFeedback,
      feedbackRatOptions,
      feedbackComment,
      error,
      buttonColour
    };
  }
};
</script>
