<template />

<script>
export default {
  props: {
    sectionType: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    }
  },
  methods: {
    triggerQuestionChange() {
    }
  }
};
</script>
