<template>
  <div v-if="feedback" class="tw-text-warning-yellow tw-mt-4 tw-text-sm tw-whitespace-pre-wrap">
    <i class="far fa-comment-alt tw-text-base tw-text-warning-yellow tw-inline tw-mr-1" />
    {{ feedback.feedback }}
  </div>
  <div v-if="sectionReview && editable" class="tw-flex tw-flex-col tw-mt-2 tw-gap-2">
    <div class="tw-flex tw-items-center">
      <button-component
        :text="feedbackButtonText"
        size="small"
        colour="warning"
        icon="comment-alt"
        @click="leaveFeedback()"
      />
    </div>
    <div v-if="showFeedback" class="tw-flex tw-flex-col tw-gap-2 tw-bg-warning-yellow-100 tw-rounded-md tw-p-2">
      <textarea
        ref="feedbackTextArea"
        v-model="feedbackText"
        rows="4"
        :class="theme.forms.textarea"
      />

      <div v-if="cannedFeedbacks !== undefined" class="tw-my-4 tw-flex tw-flex-col">
        <template v-for="(cannedFeedback, idx) in cannedFeedbacks" :key="idx">
          <div class="tw-flex tw-items-center tw-gap-x-2">
            <input
              :id="`canned_${cannedFeedback.id}`"
              type="radio"
              name="canned_feedback_id"
              :class="[theme.forms.radio]"
              :value="`canned_${cannedFeedback.id}`"
              @click="setCannedFeedback(cannedFeedback)"
            >
            <label class="tw-font-medium tw-text-gray-500 tw-mb-0" :for="`canned_${cannedFeedback.id}`">{{ cannedFeedback.title }}</label>
          </div>
        </template>
      </div>
      <div class="tw-flex tw-gap-x-2">
        <div>
          <label for="rat_review">
            <div class="tw-flex tw-items-center tw-gap-x-2">
              <input
                id="rat_review"
                v-model="feedbackRat"
                type="radio"
                :class="[theme.forms.radio]"
                value="Review/Accept"
              >
              <div class="tw-font-medium tw-text-gray-700">Review/Accept</div>
            </div>
          </label>
        </div>
        <div>
          <label for="rat_changes_needed">
            <div class="tw-flex tw-items-center tw-gap-x-2">
              <input
                id="rat_changes_needed"
                v-model="feedbackRat"
                type="radio"
                :class="[theme.forms.radio]"
                value="Changes Needed"
              >
              <div class="tw-font-medium tw-text-gray-700">Changes/Needed</div>
            </div>
          </label>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-items-center tw-gap-x-2">
          <button-component
            :text="feedback ? 'Update Feedback' : 'Create Feedback'"
            icon="plus-circle"
            size="small"
            @click="saveFeedback()"
          />
          <div v-if="errorMessage" class="tw-text-error-red-750 tw-font-medium tw-text-xs">
            <i class="fas fa-exclamation-triangle tw-text-base tw-inline" />
            {{ errorMessage }}
          </div>
        </div>
        <div>
          <button-component
            v-if="feedback"
            text="Delete Feedback"
            colour="error"
            icon="trash-alt"
            size="small"
            @click="deleteFeedback()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { css } from "../../../shared/theme.js";
import useEventsBus from "../../../shared/eventBus";

import Feedback from "./feedback.vue";
import { FIELD_STATE } from "./shared/field_state.js";

export default {
  components: {
    Feedback
  },
  props: {
    sectionSlug: {
      type: String,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { emit } = useEventsBus();
    const store = useStore();
    const theme = css;
    const showFeedback = ref(false);
    const errorMessage = ref("");
    const fieldDirty = computed(() => props.field.state >= FIELD_STATE.EDITED);

    const feedback = computed(() => store.getters["feedbacks/GET_FEEDBACKS_FOR_FIELD"](props.sectionType, props.sectionSlug, props.field.label));
    const feedbackText = ref("");
    const feedbackRat = ref("Review/Accept");
    const sectionReview = computed(() => store.getters["reviews/GET_REVIEW_FOR_SECTION"]);

    const cannedFeedbacks = computed(() => store.getters["feedbacks/GET_CANNED_NARRATIVE_FEEDBACK_FOR_QUESTION"](props.field.label));

    function leaveFeedback() {
      if (showFeedback.value) {
        checkBeforeClose();
        if (errorMessage.value !== "") return;

        removeFieldAudits();
      } else {
        if (feedback.value !== undefined) {
          feedbackText.value = feedback.value.feedback;
          feedbackRat.value = feedback.value.resolve_activity_type;
        }
        store.dispatch("audit_history/FETCH_AUDIT_HISTORY", {
          sectionSlug: props.sectionSlug,
          sectionType: props.sectionType
        });

        emit("addFieldAudits", {
          label: props.field.label,
          title: props.field.title
        });
      }

      showFeedback.value = !showFeedback.value;
    }

    function saveFeedback() {
      if (feedbackText.value.trim() === "") {
        errorMessage.value = `Please add some feedback before ${feedback.value === undefined ? "creating" : "updating"} the feedback`;
        return;
      }
      if (fieldDirty.value) {
        errorMessage.value = "The question for this feedback has unsaved changes, please save before submitting feedback";
        return;
      }
      if (feedback.value === undefined) {
        createFeedback();
      } else {
        updateFeedback();
      }

      removeFieldAudits();
      showFeedback.value = false;
    }

    function createFeedback() {
      store.dispatch("feedbacks/CREATE_FEEDBACK", {
        fields: {
          col_ref: props.field.label,
          feedback: feedbackText.value,
          // This may need to be changed to an id for testing
          review_id: sectionReview.value.id,
          resolve_activity_type: feedbackRat.value,
          feedbacked_value: props.field.value
        },
        feedbacked_value: props.field.value,
        feedbackable_type: props.sectionType,
        feedbackable_slug: props.sectionSlug
      });
    }

    function updateFeedback() {
      store.dispatch("feedbacks/UPDATE_FEEDBACK", {
        fields: {
          col_ref: props.field.label,
          feedback: feedbackText.value,
          // This may need to be changed to an id for testing
          review_id: sectionReview.value.id,
          resolve_activity_type: feedbackRat.value,
          feedbacked_value: props.field.value
        },
        feedbacked_value: props.field.value,
        feedbackable_type: props.sectionType,
        feedbackable_slug: props.sectionSlug,
        append: false,
        id: feedback.value.id
      });
    }

    function deleteFeedback() {
      store.dispatch("feedbacks/DELETE_FEEDBACK", {
        id: feedback.value.id
      });

      showFeedback.value = false;
    }

    function removeFieldAudits() {
      emit("removeFieldAudits", {
        label: props.field.label,
        title: props.field.title
      });
    }

    function checkBeforeClose() {
      if (errorMessage.value !== "") {
        errorMessage.value = "";
      } else if (feedback.value === undefined) {
        errorMessage.value = feedbackText.value === "" ? "" : "Be careful! You haven't submitted your feedback";
      } else if (feedbackText.value !== feedback.value.feedback || feedbackRat.value !== feedback.value.resolve_activity_type) {
        errorMessage.value = "Be careful! You haven't submitted your feedback changes.";
      } else {
        return;
      }
    }

    function setCannedFeedback(cannedFeedback) {
      this.feedbackText = this.feedbackText.trim() === "" ? cannedFeedback.text : `${this.feedbackText} ${cannedFeedback.text}`;
      this.feedbackRat = cannedFeedback.resolve_activity_type;
      // @todo track
    }

    const feedbackButtonText = computed(() => {
      if (showFeedback.value) {
        return "Hide";
      } if (feedback.value) {
        return "Edit Feedback";
      }
      return "Leave Feedback";
    });

    onMounted(() => {
      store.dispatch("feedbacks/FETCH_ALL_CANNED_NARRATIVE_FEEDBACK");
    });

    return {
      feedback,
      feedbackText,
      feedbackRat,
      showFeedback,
      theme,
      sectionReview,
      createFeedback,
      updateFeedback,
      leaveFeedback,
      saveFeedback,
      errorMessage,
      feedbackButtonText,
      cannedFeedbacks,
      setCannedFeedback,
      deleteFeedback
    };
  }
};
</script>
