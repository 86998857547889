<template>
  <div class="tw-flex tw-justify-between tw-my-1">
    <div class="tw-inline-flex tw-gap-x-2">
      <FindOtherClaims v-show="isModalVisible" @close="closeModal" />
      <!-- Benefit Status -->
      <div class="tw-relative tw-inline-block">
        <dropdown>
          <template #link>
            <span class="tw-cursor-pointer">
              <span class="tw-rounded-md tw-shadow-sm">
                <button
                  type="button"
                  :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-ring-blue tw-bg-gray-50 active:tw-bg-gray-50 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150', isFilterSet('status') ? 'tw-text-green-600' : '']"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div class="tw-flex tw-items-center">
                    <i class="fas fa-filter tw-mr-2" />
                    <div>
                      <div>Status</div>
                    </div>
                    <i class="fas fa-chevron-down tw-ml-2" />
                  </div>
                </button>
              </span>
            </span>
          </template>
          <template #items>
            <div class="tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100">
              <div class="tw-py-1">
                <template v-for="[idx, status] in Object.entries(allStatuses)" :key="status">
                  <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('status', status) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('status', filterEquals('status', status) ? null : status)">
                    <i class="fas fa-dot-circle tw-mr-2" />
                    {{ status }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </dropdown>
      </div>
      <!-- end benefit status -->
      <!-- Combned Filters -->
      <div class="tw-relative tw-inline-block">
        <dropdown>
          <template #link>
            <span class="tw-cursor-pointer">
              <span class="tw-rounded-md tw-shadow-sm">
                <button
                  type="button"
                  :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-ring-blue tw-bg-gray-50 active:tw-bg-gray-50 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150', anyFilterSet ? 'tw-text-green-600' : '']"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div class="tw-flex tw-items-center">
                    <i class="fas fa-filter tw-mr-2" />
                    <div>
                      <div>Filters</div>
                    </div>
                    <i class="fas fa-chevron-down tw-ml-2" />
                  </div>
                </button>
              </span>
            </span>
          </template>
          <template #items>
            <div class="tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100">
              <div class="tw-py-1">
                <div :class="[theme.dropDown.itemDiv, filterEquals('submit_by_emp', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('submit_by_emp', filterEquals('submit_by_emp', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  EmpowerRD Submitting
                </div>
                <div :class="[theme.dropDown.itemDiv, filterEquals('submit_by_emp', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('submit_by_emp', filterEquals('submit_by_emp', false) ? null : false)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Client Submitting
                </div>
                <div :class="[theme.dropDown.itemDiv, filterEquals('accounts_submitted', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('accounts_submitted', filterEquals('accounts_submitted', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Accounts Submitted
                </div>
                <div :class="[theme.dropDown.itemDiv, filterEquals('accounts_not_submitted', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('accounts_not_submitted', filterEquals('accounts_not_submitted', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Accounts Not Submitted
                </div>
                <div :class="[theme.dropDown.itemDiv, filterEquals('expecting_cash', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('expecting_cash', filterEquals('expecting_cash', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Expecting Cash
                </div>
                <div :class="[theme.dropDown.itemDiv, filterEquals('expecting_tax_reduction', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('expecting_tax_reduction', filterEquals('expecting_tax_reduction', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Expecting Tax Reduction
                </div>
                <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('auth_agent_requested', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('auth_agent_requested', filterEquals('auth_agent_requested', false) ? null : false)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Auth Agent Not Requested
                </div>
                <div :class="['tw-block tw-cursor-pointer tw-px-4 tw-py-2 tw-text-xs tw-leading-5 tw-text-gray-500 ', filterEquals('auth_agent_completed', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('auth_agent_completed', filterEquals('auth_agent_completed', false) ? null : false)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Auth Agent Not Completed
                </div>
                <div :class="[theme.dropDown.itemDiv, filterEquals('invoice_paid', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('invoice_paid', filterEquals('invoice_paid', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Invoice Paid
                </div>

                <div :class="[theme.dropDown.itemDiv, filterEquals('invoice_paid', false) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('invoice_paid', filterEquals('invoice_paid', false) ? null : false)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Invoice Not Paid
                </div>

                <div :class="[theme.dropDown.itemDiv, filterEquals('due_now', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('due_now', filterEquals('due_now', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Benefit Due Now
                </div>

                <div :class="[theme.dropDown.itemDiv, filterEquals('possibly_due', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('possibly_due', filterEquals('possibly_due', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Benefit Possibly Due
                </div>

                <div :class="[theme.dropDown.itemDiv, filterEquals('flagged', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('flagged', filterEquals('flagged', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Flagged
                </div>

                <div :class="[theme.dropDown.itemDiv, filterEquals('enquiry_opened', true) ? 'tw-text-green-600' : '']" role="menuitem" @click="updateFilter('enquiry_opened', filterEquals('enquiry_opened', true) ? null : true)">
                  <i class="fas fa-dot-circle tw-mr-2" />
                  Enquiry Opened
                </div>
              </div>
            </div>
          </template>
        </dropdown>
      </div>
      <FadeTransition>
        <a v-if="anyFilterSet" @click="resetFilters()">
          <ButtonComponent
            id="clear-filters"
            text="Clear Filters"
            colour="emp"
            type="transparent"
            icon="trash-alt"
            icon-type="fas"
            :hide-small="true"
          />
        </a>
      </FadeTransition>
    </div>
    <div class="tw-flex tw-items-center tw-gap-x-2">
      <!-- Benefit Status -->

      <Tippy content="Search for company name, total benefit value or UTR">
        <input
          id="query"
          v-model="query"
          type="text"
          :class="['tw-w-64', theme.forms.input]"
          placeholder="Quick search benefits below..."
        >
      </Tippy>

      <Tippy content="Find another claim period not listed below">
        <button type="button" :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-ring-blue tw-bg-gray-50 active:tw-bg-gray-50 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150']" @click="showModal()">
          <div class="tw-flex tw-items-center">
            <i class="fas fa-plus tw-mr-2" />
            <div>
              <div>Find Other Claim</div>
            </div>
          </div>
        </button>
      </Tippy>

      <div class="tw-hidden tw-relative tw-inline-block tw-text-left tw-mx-1">
        <dropdown>
          <template #link>
            <span class="tw-cursor-pointer">
              <span class="tw-rounded-md tw-shadow-sm">
                <button
                  type="button"
                  :class="['tw-inline-flex tw-justify-center tw-w-full tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-border-blue-300 focus:tw-ring-blue tw-bg-gray-50 active:tw-bg-gray-50 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150']"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div class="tw-flex tw-items-center">
                    <i class="fas fa-bars tw-mr-2" />
                    <div>
                      <div>Menu</div>
                    </div>
                    <i class="fas fa-chevron-down tw-ml-2" />
                  </div>
                </button>
              </span>
            </span>
          </template>
          <template #items>
            <div class="tw-absolute tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-z-100 tw-origin-top-right">
              <div class="tw-py-1">
                <a :class="[theme.dropDown.itemDiv]" role="menuitem">
                  <i class="fas fa-plus tw-mr-2" />
                  Track Missing Claim
                </a>
              </div>
            </div>
          </template>
        </dropdown>
      </div>
      <!-- end benefit status -->
    </div>
  </div>
  <!-- end of month submission drop down -->
  <div v-show="isLoading" id="loading" class="tw-w-full tw-text-center tw-my-4">
    <PulsingLoader :size="large" color="tw-bg-gray-200" />
  </div>
  <div v-show="tableHasBenefits && !isLoading" v-cloak class="tw-mt-4">
    <claim-benefit-table :query="query" :benefits="benefitsToDisplay" />
  </div>
</template>

<script>
import { Tippy } from "vue-tippy";
import { documentCsrfToken } from "../../../../shared/http.js";
import FindOtherClaims from "./benefits_find_other_claim";
import { css } from "../../../../shared/theme.js";

import PulsingLoader from "../../../generic/loading_pulse_lines";
import ButtonComponent from "../../../generic/button_component.vue";
import FadeTransition from "../../transitions/fade.vue";

const datefns = require("date-fns");

export default {
  components: {
    PulsingLoader,
    FindOtherClaims,
    Tippy,
    ButtonComponent,
    FadeTransition
  },
  props: {
    allStatuses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableHasBenefits: false,
      isLoading: true,
      query: "",
      isModalVisible: false,
      benefitsByFilter: [],
      filters: {},
      theme: css,
      claimBenefits: [],
      claimValues: {},
      thisMonthAsString: datefns.formatISO9075(datefns.startOfMonth(new Date()), { representation: "date" }),
      nextMonthAsString: datefns.formatISO9075(datefns.startOfMonth(datefns.add(new Date(), { months: 1 })), { representation: "date" }),
      currentDayAsEpoch: new Date().getTime() / 1000
    };
  },
  computed: {
    benefitsToDisplay() {
      let dataToDisplay = this.claimBenefits;

      if (this.filters.status != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.status.status == this.filters.status);
      }

      if (this.filters.submit_by_emp != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.claim_period.finance_document.submit_by_emp == this.filters.submit_by_emp);
      }

      if (this.filters.accounts_submitted != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.claim_period.accounts_submitted == this.filters.accounts_submitted);
      }

      if (this.filters.accounts_not_submitted != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.claim_period.accounts_submitted == !this.filters.accounts_not_submitted);
      }

      // expecting_cash
      // expecting_tax_reduction
      if (this.filters.expecting_cash != null) {
        dataToDisplay = dataToDisplay.filter(benefit => _.filter(benefit.claim_period._expected_benefits, { benefit_type: "Tax Credit (Cash)" }).length > 0);
      }
      if (this.filters.expecting_tax_reduction != null) {
        dataToDisplay = dataToDisplay.filter(benefit => _.filter(benefit.claim_period._expected_benefits, { benefit_type: "Tax Saving (Reduction)" }).length > 0);
      }

      // Handle Auth Agent Requested.  We only want to know when it is false
      if (this.filters.auth_agent_requested != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.claim_period.company.auth_agent_requested == false);
      }

      // Handle Auth Agent Completed. We only want to know when it is false
      if (this.filters.auth_agent_completed != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.claim_period.company.auth_agent_completed == false);
      }

      // Handle Invoice Paid
      if (this.filters.invoice_paid != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit["invoice_paid?"] == this.filters.invoice_paid);
      }

      // Handle Due Now
      if (this.filters.due_now != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.due_status.status == "Due");
      }

      // Handle Possibly Due
      if (this.filters.possibly_due != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.due_status.status == "Possibly Due");
      }

      // Handle Flagged
      if (this.filters.flagged != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.flagged == true);
      }

      // Handle Enquiry Opened
      if (this.filters.enquiry_opened != null) {
        dataToDisplay = dataToDisplay.filter(benefit => benefit.enquiry_opened == true);
      }

      return dataToDisplay;
    },
    anyFilterSet() {
      return (
        _.find(_.values(this.filters), x => x != null) != undefined
      );
    }
  },
  mounted() {
    if (this.activeClaimManagerId ?? "" !== "") {
      this.updateFilter("emp_admin_id", this.activeClaimManagerId);
    }
    this.getInitialData();
  },
  methods: {
    showModal() {
      console.log(this.isModalVisible);
      this.isModalVisible = true;
      console.log(this.isModalVisible);
    },
    closeModal() {
      this.isModalVisible = false;
    },
    updateFilter(filter, value) {
      this.filters[filter] = value;
    },
    isFilterSet(filter) {
      return this.filters[filter] ?? "" !== "";
    },
    filterValue(filter) {
      return this.filters[filter];
    },
    filterEquals(filter, value) {
      return this.filterValue(filter) == value;
    },
    getInitialData() {
      const url = "/api/claim_benefits";
      fetch(url)
                .then(response => response.json())
                .then(data => {
                  this.isLoading = false;
                  this.claimBenefits = data;
                  const slugs = this.claimBenefits.map(x => x.slug);
                  this.tableHasBenefits = true;
                  setTimeout(window.enableTooltips, 500);
                });
    },
    resetFilters() {
      this.filters = {};
    }
  }
};
</script>
