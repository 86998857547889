// icon default is paper-plane
// type default is primary
// colour default is cta

const DEFAULT_OPTIONS = {
  icon: "paper-plane",
  colour: "cta",
  type: "primary"
};

export const sendForReviewOptions = {
  projects: {
    // IN_PROGRESS
    0: {
      ...DEFAULT_OPTIONS,
      buttonText: "Send Project For Review"
    },
    // FINALISED
    1: {
      buttonText: "Recall From Review",
      icon: "undo",
      colour: "disabled",
      type: "transparent"
    }
  },
  new_project: {
    // IN_PROGRESS
    0: {
      ...DEFAULT_OPTIONS,
      buttonText: "Send Project For Review"
    },
    // FINALISED
    1: {
      buttonText: "Recall From Review",
      icon: "undo",
      colour: "disabled",
      type: "transparent"
    }
  },
  costs_rd: {
    // IN_PROGRESS
    0: {
      ...DEFAULT_OPTIONS,
      buttonText: "Send Time Spent on R&D For Review"
    },
    // FINALISED
    1: {
      buttonText: "Recall from Review",
      icon: "undo",
      colour: "disabled",
      type: "transparent"
    }
  },
  costs: {
    // IN_PROGRESS
    0: {
      ...DEFAULT_OPTIONS,
      buttonText: "Mark Cost Collection as Complete",
      icon: "check-circle",
      colour: "confirm"
    },
    // FINALISED
    1: {
      buttonText: "Cost Collection Complete",
      icon: "check-circle",
      type: "transparent",
      colour: "confirm"
    },
    // SECTION_COMPLETE
    4: {
      buttonText: "Cost Collection Complete",
      icon: "check-circle",
      type: "transparent",
      colour: "confirm"
    }
  },
  epw_contract_review: {
    // IN_PROGRESS
    0: {
      ...DEFAULT_OPTIONS,
      buttonText: "Send Contracts For Review"
    }
  },
  grant: {
    // IN_PROGRESS
    0: {
      ...DEFAULT_OPTIONS,
      buttonText: "Mark Funding Source as Complete",
      icon: "check-circle"
    },
    // FINALISED
    1: {
      buttonText: "Funding Source Completed",
      icon: "check-circle",
      type: "transparent",
      colour: "confirm"
    },
    // SECTION_COMPLETE
    4: {
      buttonText: "Funding Source Completed",
      icon: "check-circle",
      type: "transparent",
      colour: "confirm"
    }
  }
};

export const sendForReviewCopy = {
  projects: {
    send: {
      modalTitle: "Send Project For Review",
      modalInfo: "If you are happy with all your answers, send this project and it's challenge(s) for drafting. Our technical write-up experts may leave feedback asking for further information if required.",
      modalWarning: "Once sent no edits can be made whilst the section is in review. We will let you know as soon as the feedback is available."
    },
    recall: {
      modalTitle: "Recall Project From Review",
      modalInfo: "The review for this project has not started yet so you can recall it from review to make further changes before sending it back for review."
    }
  },
  new_project: {
    send: {
      modalTitle: "Send Project For Review",
      modalInfo: "If you are happy with all your answers, send this project for drafting. Our technical write-up experts may leave feedback asking for further information if required.",
      modalWarning: "Once sent no edits can be made whilst the section is in review. We will let you know as soon as the feedback is available."
    },
    recall: {
      modalTitle: "Recall Project From Review",
      modalInfo: "The review for this project has not started yet so you can recall it from review to make further changes before sending it back for review."
    }
  },
  costs_rd: {
    send: {
      modalTitle: "Send Time Spent on R&D for Review",
      modalButton: "Confirm Sending for Review",
      modalInfo: "If you are happy with all your percentages for time spent on R&D, send this section to be reviewed by our team of R&D experts. They will leave feedback to help ensure your claim is in line with HMRC's expectations.",
      modalWarning: "Once sent no edits can be made to any costs details or their R&D Percentages whilst the section is in review. We will let you know as soon as the feedback is available and you can then make any necessary changes accordingly."
    },
    recall: {
      modalTitle: "Recall Time Spent on R&D From Review",
      modalInfo: "The review for this section has not started yet so you can recall it from review to make further changes before sending it back for review."
    }
  },
  costs: {
    send: {
      modalTitle: "Mark Cost Collection as Complete",
      modalButton: "Confirm Cost Collection as complete",
      modalInfo: "If you are happy with all your costs, mark this section as complete. You will need to have completed all costs before you can send your R&D percentages for review."
    },
    recall: {
      modalTitle: "Undo Cost Collection Completion",
      modalInfo: "Unmark your cost collection as complete if you have further changes you want to make.",
      modalButton: "Unmark Cost Collection as Complete"
    }
  },
  epw_contract_review: {
    send: {
      modalTitle: "Send 3rd Party Contracts for review",
      modalButton: "Send Contracts for Review",
      modalInfo: "If you've provided your 3rd Party Contract(s), send them to be reviewed by our team. We need at least the contract for the highest value External Provided Worker you're claiming for."
    }
  },
  grant: {
    send: {
      modalTitle: "Mark this External Funding Source as Complete",
      modalButton: "Confirm Funding Source as complete",
      modalInfo: "If you are happy with all of the details provided for this source of funding, mark this section as complete."
    },
    recall: {
      modalTitle: "Undo Funding Source Completion",
      modalInfo: "Unmark your funding source as complete if you have further changes you want to make.",
      modalButton: "Unmark Funding Source as Complete"
    }
  }
};

export const sendForReviewTooltip = {
  projects: {
    invalid: "Before we can review your project and its challenges, please resolve your invalid answers",
    valid: "Send your project and its challenges to our claim team to review.",
    noChallenges: "The Project needs at least one challenge to be ready to submit to our claim team",
    incompleteSections: "The following sections need completing before sending for review: "
  },
  new_project: {
    invalid: "Before we can review your project, please resolve your invalid answers",
    valid: "Send your project to our claim team to review.",
    noMessages: "Before we can review your project, please finish providing all relevant information, click to highlight any errors or missing information required"
  },
  costs_rd: {
    invalid: "Before we can review your Time Spent on R&D, please resolve any errors with your costs or R&D Percentages",
    noMessages: "Before we can review your Time Spent on R&D, please finish your costs and R&D percentages, click to highlight any errors or missing information required",
    valid: "Send your Time Spent on R&D percentages and costs to our claim team to review."
  },
  costs: {
    invalid: "Before you can complete your costs, please resolve any errors displayed",
    noMessages: "Before you can complete your costs, please finish providing all relevant information, click to highlight any costs missing required information",
    valid: "Mark your costs as complete",
    recall: "Click to unmark your costs as complete"
  },
  epw_contract_review: {
    invalid: "Before you can send your contracts for review, please resolve any errors displayed",
    noMessages: "Before we can review, please provide the contract for at least the highest value cost displayed",
    valid: "Send your 3rd Party contracts to be reviewed"
  },
  grant: {
    invalid: "Before you can complete this funding source, please resolve any errors displayed",
    noMessages: "Before you can complete this funding source, please finish providing all relevant information, click to highlight any missing required information",
    valid: "Mark this funding source as complete",
    recall: "Click to unmark this funding source as complete"
  }
};
