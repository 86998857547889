<template>
  <Modal id="projectSelectionConfirmationModal" :show-close="false">
    <p class="tw-mb-5">
      Well done! Based on the information you provided, you need to detail
      {{ totalToDetail || '0' }} R&D projects in the next section. You can complete your selection now by
      clicking 'Finalise' below. If you feel you're not done yet with the selection, you can return to Step 1 or Step 2
      to make adjustments.
    </p>

    <p class="tw-mb-10">
      Please note that once you select "Finalise," your R&D project selection will be locked, and you won't be able to
      make any further changes.
    </p>

    <div class="tw-space-x-10 tw-flex tw-justify-center">
      <ButtonComponent
        :on-click="() => emit('toggleModal', 'projectSelectionConfirmationModal')"
        text="Cancel"
        colour="confirm"
        type="transparent"
        size="small"
        uppercase
        button-classes="tw-px-8 tw-font-bold tw-tracking-tight"
      />
      <ButtonComponent
        :on-click="progress"
        text="Finalise"
        colour="confirm"
        size="small"
        uppercase
        button-classes="tw-px-8 tw-font-bold tw-tracking-tight"
      />
    </div>
  </Modal>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import useEventsBus from "../../../shared/eventBus";
import Modal from "../../generic/modal_component.vue";
import ButtonComponent from "../../generic/button_component.vue";

defineProps({
  progress: {
    type: Function
  }
});

const store = useStore();
const { emit } = useEventsBus();
const totalToDetail = computed(() => store.getters["project_selection/GET_TOTAL_NUMBER_TO_DETAIL"]);
</script>
