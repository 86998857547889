<template>
  <tooltip v-if="parentSection" :content="statusTooltip">
    <div
      id="section-status"
      :class="statusColor"
      class="tw-hidden lg:tw-flex tw-justify-center tw-items-center tw-text-lg tw-gap-2 tw-font-semibold"
    >
      <span>{{ parentSection }} Status: {{ sectionStatusLabel }}</span>
      <i :class="statusIcon" />
    </div>
  </tooltip>
</template>

<script>

import { computed } from "vue";
import { useStore } from "vuex";
import { SECTION_STATUS, SECTION_STATUS_LABELS, SECTION_STATUS_TOOLTIPS } from "../../../shared/section_status.js";

export default {
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const sectionStatus = computed(() => store.getters["states/GET_SECTION_STATUS"]);
    const parentSection = computed(() => props.section?.section_title);

    const sectionStatusLabel = computed(() => SECTION_STATUS_LABELS[sectionStatus.value]);

    const statusColor = computed(() => {
      if (sectionStatus.value <= SECTION_STATUS.IN_REVIEW) return "tw-text-disable-grey";
      if (sectionStatus.value <= SECTION_STATUS.UNRESOLVED_FEEDBACK) return "tw-text-warning-yellow";

      return "tw-text-confirm-green";
    });

    const statusIcon = computed(() => {
      if (sectionStatus.value <= SECTION_STATUS.IN_REVIEW) return "fas fa-clock tw-pt-0.5";
      if (sectionStatus.value <= SECTION_STATUS.UNRESOLVED_FEEDBACK) return "far fa-comment-alt-exclamation";

      return "fas fa-check-circle";
    });

    const statusTooltip = computed(() => SECTION_STATUS_TOOLTIPS?.[props.section?.section_type_key]?.[sectionStatus.value]);

    return {
      parentSection,
      sectionStatusLabel,
      statusColor,
      statusIcon,
      statusTooltip
    };
  }
};
</script>
