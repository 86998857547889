<template>
  <span>
    <span
      v-for="person in sectionUsers"
      v-show="sectionUsers.length > 0"
      :key="person.id"
    >
      <div
        class="tw-flex tw-justify-between tw-mb-1"
        @mouseenter="sectionUserHovering = person.id"
        @mouseleave="sectionUserHovering = null"
      >
        <div class="tw-flex tw-content-center">
          <div class="tw-mr-2 tw-h-8 tw-w-8">
            <span
              class="
                tw-flex
                tw-items-center
                tw-justify-center
                tw-w-full
                tw-h-full
                tw-rounded-full
                tw-bg-gray-500
              "
            >
              <span class="tw-font-medium tracking-tighter tw-text-white">{{ person.first_name[0] }}{{ person.last_name[0] }}</span>
            </span>
          </div>
          <div>
            <div
              data-toggle="tooltip"
              class="tw-mb-0 tw-pb-0"
              data-placement="right"
              :title="person.email"
            >
              <span>{{ person.first_name }} {{ person.last_name }}</span>
              <span class="tw-text-xs tw-text-gray-600"><span v-if="person.role_label !== null">({{ person.role_label }})</span>
                <span v-show="person.is_assigned" class="tw-font-medium tw-italic">
                  - Primary</span>
              </span>
              <span
                v-show="sectionUserHovering == person.id && !person.is_assigned"
                class="
                  tw-cursor-pointer tw-text-xs tw-text-gray-600 tw-font-bold tw-pl-2
                "
                @click.once="makePrimary(person.id)"
              >Make primary</span>
            </div>
          </div>
        </div>

        <div @click="updateSectionUsers('remove', person.id)">
          <div class="tw-inline-flex tw-rounded-md">
            <button
              type="button"
              class="
                tw-border
                tw-border-transparent
                tw-text-xs
                tw-leading-4
                tw-px-2
                tw-py-1
                tw-font-medium
                tw-rounded-md
                tw-text-white
                tw-bg-slate-400
                focus:tw-outline-none
                tw-transition tw-ease-in-out tw-duration-75
                hover:tw-no-underline
              "
            >
              <div class="tw-flex tw-items-center"><div>Remove</div></div>
            </button>
          </div>
        </div>
      </div>
    </span>

    <span v-if="model.className !== 'ClaimPeriod'">
      <div v-show="sectionUsers.length == 0">
        <p class="tw-text-gray-600 tw-text-xs">
          No one has been added to this section yet.
        </p>
      </div>

      <div>
        <hr class="tw-my-4">
        <div
          class="
            tw-flex tw-items-center tw-justify-items-stretch tw-w-full tw-mb-4
          "
        >
          <div
            class="tw-flex tw-items-center tw-w-1/2 tw-gap-x-1 tw-cursor-pointer"
            @click="addToClaimExpanded = !addToClaimExpanded"
          >
            <span v-show="!addToClaimExpanded">
              <svg
                class="tw-w-5"
                style="width: 1.25rem"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <span v-show="addToClaimExpanded">
              <svg
                class="tw-w-5"
                style="width: 1.25rem"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>

            <span>Add someone to this
              {{ model.title || model.classNameHuman || model.className }}</span>
          </div>

          <div class="tw-w-1/2 tw-text-right">
            <button-component type="transparent" text="Invite Someone New" @click="showModal = !showModal" />
          </div>
        </div>
        <!-- collapsible holder -->

        <div v-show="addToClaimExpanded">
          <span
            v-for="person in claimPeriodUsers.filter(
              (person) =>
                sectionUsers.filter((tm) => tm.email == person.email).length ==
                0
            )"
            :key="person.id"
          >
            <div class="tw-flex tw-justify-between">
              <div class="tw-flex tw-content-center">
                <div class="tw-mr-2 tw-mb-4 tw-h-8 tw-w-8">
                  <span
                    class="
                      tw-inline-flex
                      tw-items-center
                      tw-justify-center
                      tw-w-full
                      tw-h-full
                      tw-rounded-full
                      tw-bg-gray-500
                    "
                  >
                    <span
                      class="tw-font-medium tw-tracking-tighter tw-text-white"
                    >{{ person.first_name[0] }}{{ person.last_name[0] }}</span>
                  </span>
                </div>
                <div>
                  <div
                    data-toggle="tooltip"
                    class="tw-mb-0 tw-pb-0"
                    data-placement="right"
                    :title="person.email"
                  >
                    <span>{{ person.first_name }} {{ person.last_name }}</span>
                    <span class="tw-text-xs tw-text-gray-600"><span v-if="person.role_label !== null">({{ person.role_label }})</span></span>
                  </div>
                </div>
              </div>
              <div @click="updateSectionUsers('add', person.id)">
                <div class="tw-inline-flex tw-rounded-md">
                  <button
                    type="button"
                    class="
                      tw-border
                      tw-border-transparent
                      tw-text-xs
                      tw-leading-4
                      tw-px-2
                      tw-py-1
                      tw-font-medium
                      tw-rounded-md
                      tw-text-white
                      tw-bg-green-600
                      focus:tw-outline-none
                      tw-transition tw-ease-in-out tw-duration-75
                      hover:tw-no-underline
                    "
                  >
                    <div class="tw-flex tw-items-center"><div>Add</div></div>
                  </button>
                </div>
              </div>
            </div>
          </span>
        </div>
        <!-- add to claim expanded -->
      </div>
      <!-- only show if anyone left -->
    </span>

    <div v-show="showModal" v-cloak>
      <div
        class="
          tw-fixed tw-bottom-0 tw-inset-x-0 tw-px-4 tw-pb-6
          sm:tw-inset-0
          sm:tw-p-0
          sm:tw-flex
          sm:tw-items-center
          sm:tw-justify-center
          tw-z-50
        "
      >
        <div class="tw-fixed tw-inset-0 tw-transition-opacity">
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          />
        </div>

        <div
          class="
            tw-bg-white
            tw-rounded-lg
            tw-px-4
            tw-pt-5
            tw-pb-4
            tw-overflow-hidden
            tw-shadow-xl
            tw-transform
            tw-transition-opacity
            sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div v-show="invited" v-cloak>
            <div
              class="
                tw-border-l-4
                tw-text-xs
                tw-border-l-solid
                tw-border-green-500
                tw-bg-green-100
                tw-p-1
                tw-pl-3
                tw-mt-2
                tw-mb-4
                tw-text-green-700
              "
              role="alert"
            >
              <div>User has been invited and emailed</div>
            </div>
          </div>

          <div
            v-show="Object.keys(errors).length > 0"
            v-cloak
            id="invite-form-errors-holder"
          >
            <div
              class="
                tw-border-l-4
                tw-text-xs
                tw-border-l-solid
                tw-border-red-500
                tw-bg-red-100
                tw-p-1
                tw-pl-3
                tw-mt-2
                tw-mb-4
                tw-text-red-700
              "
              role="alert"
            >
              <div v-for="error in Object.keys(errors)" :key="error">
                <span
                  class="tw-font-bold tw-mr-1"
                  v-text="toTitleCase(error)"
                />
                <span v-text="errors[error].join(', ')" />
              </div>
            </div>
          </div>

          <div class="sm:tw-flex sm:tw-items-start">
            <form
              id="new_user"
              class="simple_form new_user"
              action="/users"
              accept-charset="UTF-8"
              method="post"
            >
              <div class="tw-grid tw-grid-cols-8 tw-gap-2">
                <div class="tw-col-span-8 sm:tw-col-span-4">
                  <div class=" string optional user_first_name">
                    <label
                      class="col-form-label string optional"
                      for="user_first_name"
                    >First name</label>

                    <input
                      id="user_first_name"
                      v-model="user.firstName"
                      :class="theme.forms.input"
                      type="text"
                      name="user[first_name]"
                    >
                  </div>
                </div>
                <div class="tw-col-span-8 sm:tw-col-span-4">
                  <div class="string optional user_last_name">
                    <label
                      class="col-form-label string optional"
                      for="user_last_name"
                    >Last name</label>
                    <input
                      id="user_last_name"
                      v-model="user.lastName"
                      :class="theme.forms.input"
                      type="text"
                      name="user[last_name]"
                    >
                  </div>
                </div>
                <div class="tw-col-span-8 sm:tw-col-span-4">
                  <div class="email optional user_email">
                    <label
                      class="col-form-label email optional"
                      for="user_email"
                    >Email</label>
                    <input
                      id="user_email"
                      v-model="user.email"
                      :class="theme.forms.input"
                      type="email"
                      name="user[email]"
                    >
                  </div>
                </div>
                <div class="tw-col-span-8 sm:tw-col-span-4">
                  <div class="tel optional user_phone_number">
                    <label
                      class="col-form-label tel optional"
                      for="user_phone_number"
                    >Mobile Phone Number</label>
                    <input
                      id="user_phone_number"
                      v-model="user.phoneNumber"
                      :class="theme.forms.input"
                      type="tel"
                      name="user[phone_number]"
                    >
                  </div>
                  <p class="help-text" />
                </div>
                <div class="tw-col-span-8 sm:tw-col-span-4">
                  <div class="form-group select optional user_role">
                    <label
                      class="col-form-label select optional"
                      for="user_role"
                    >Role</label>
                    <select
                      id="user_role"
                      v-model="user.roleIds"
                      class="form-control select optional"
                      name="user[role]"
                    >
                      <option label=" " />
                      <option value="4">Engineer</option>
                      <option value="5">Accountant</option>
                      <option value="2">Coordinator</option>
                      <option value="3">Tech Lead</option>
                    </select>
                  </div>
                </div>
                <div class="tw-col-span-8 sm:tw-col-span-6">
                  <div
                    class="form-check boolean optional user__section_assigned"
                  >
                    <input
                      type="hidden"
                      value="0"
                      name="user[_section_assigned]"
                    >
                    <label
                      class="boolean optional form-check-label"
                      for="user__section_assigned"
                    >
                      <input
                        id="user__section_assigned"
                        v-model="user.sectionAssigned"
                        :class="theme.forms.checkbox"
                        type="checkbox"
                        value="1"
                        name="user[_section_assigned]"
                      >
                      Assign this section to them
                    </label>
                    <small class="form-text text-muted">This user will be responsible for ensuring this section
                      is completed</small>
                  </div>
                </div>
                <div
                  class="
                    tw-col-span-8
                    sm:tw-col-span-8
                    tw-flex tw-justify-between tw-mt-4
                  "
                >
                  <div>
                    <div class="tw-inline-flex tw-rounded-md">
                      <button
                        type="button"
                        class="
                          tw-border
                          tw-border-transparent
                          tw-text-sm
                          tw-leading-3
                          tw-px-3
                          tw-py-2
                          tw-font-medium
                          tw-rounded-md
                          tw-text-white
                          tw-bg-red-600
                          focus:tw-outline-none
                          tw-transition tw-ease-in-out tw-duration-75
                          hover:tw-no-underline
                        "
                      >
                        <div
                          class="tw-flex tw-items-center"
                          @click="showModal = !showModal"
                        >
                          <div>Close</div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div
                      v-show="!invited"
                      class="tw-inline-flex tw-rounded-md"
                      @click="inviteUser()"
                    >
                      <button
                        type="button"
                        class="
                          tw-border
                          tw-border-transparent
                          tw-text-sm
                          tw-leading-3
                          tw-px-3
                          tw-py-2
                          tw-font-medium
                          tw-rounded-md
                          tw-text-white
                          tw-bg-green-600
                          focus:tw-outline-none
                          tw-transition tw-ease-in-out tw-duration-75
                          hover:tw-no-underline
                        "
                      >
                        <div class="tw-flex tw-items-center">
                          <div>Invite</div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <!-- invite click handler -->
                </div>
                <!-- bottom button holders -->
              </div>
              <!-- form grid -->
            </form>
          </div>
          <!-- flex to hold form -->
        </div>
        <!-- modal white bg-->
      </div>
      <!-- modal master -->
    </div>
    <!-- alpine modal holder -->
  </span>
</template>

<script>
import { css } from "../../../../shared/theme.js";

export default {
  props: {
    model: {
      id: {
        type: Number,
        required: true
      },
      slug: {
        type: String,
        required: true
      },
      className: {
        type: String,
        required: true
      },
      claimPeriodSlug: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: false
      }
    }
  },
  data() {
    return {
      showModal: false,
      theme: css,
      sectionUsers: [],
      sectionUserHovering: null,
      errors: {},
      addToClaimExpanded: false,
      claimPeriodUsers: [],
      invited: false,
      user: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        roleIds: null,
        sectionAssigned: null
      }
    };
  },
  mounted() {
    this.fetchSectionUsers();
    this.fetchClaimPeriodUsers();
  },
  methods: {
    fetchClaimPeriodUsers() {
      let url = `/api/section/users/${this.model.className}/${this.model.slug}/${this.model.claimPeriodSlug}?claim_users=true`;

      if (this.model.subsection !== null) url = `/api/section/users/${this.model.className}/${this.model.slug}/${this.model.claimPeriodSlug}?claim_users=true&subsection=${this.model.subsection}`;

      this.fetchUsers(url)
        .then(response => response.json())
        .then(data => {
          this.claimPeriodUsers = data;
        });
    },
    fetchSectionUsers() {
      let url = `/api/section/users/${this.model.className}/${this.model.slug}/${this.model.claimPeriodSlug}`;

      if (this.model.subsection !== null) url = `/api/section/users/${this.model.className}/${this.model.slug}/${this.model.claimPeriodSlug}?subsection=${this.model.subsection}`;

      this.fetchUsers(url)
        .then(response => response.json())
        .then(data => {
          this.sectionUsers = data;
        });
    },
    fetchUsers(url) {
      return fetch(url, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document
            .querySelector("meta[name='csrf-token']")
            ?.getAttribute("content")
        }
      });
    },

    makePrimary(user_id) {
      this.updateSectionUsers("make_primary", user_id);
    },
    updateSectionUsers(action, user_id) {
      const formData = new FormData();

      formData.append("user_id", user_id);
      formData.append("section_type", this.model.className);
      formData.append("section_id", this.model.slug);
      formData.append("update_action", action);

      if (this.model.subsection !== null) formData.append("subsection", this.model.subsection);

      this.errors = {};

      console.log(formData);

      fetch(
        `/api/section/update_users/${this.model.className}/${this.model.slug}/${this.model.claimPeriodSlug}`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": document
              .querySelector("meta[name='csrf-token']")
              .getAttribute("content")
          }
        }
      )
        .then(response => response.json())
        .then(json => {
          if ("errors" in json) this.errors = json.errors;

          this.fetchSectionUsers();
        });
    },
    inviteUser() {
      const formData = new FormData();
      formData.append("user[first_name]", this.user.firstName);
      formData.append("user[last_name]", this.user.lastName);
      formData.append("user[email]", this.user.email);
      if (this.user.phoneNumber !== null) formData.append("user[phone_number]", this.user.phoneNumber);

      formData.append("user[role_ids]", this.user.roleIds);
      formData.append("user[_section_type]", this.model.className);
      formData.append("user[_section_id]", this.model.id);
      formData.append("user[_section_assigned]", this.user.sectionAssigned);

      if (this.model.subsection !== null) formData.append("user[_section_subsection]", this.model.subsection);

      this.errors = {};

      fetch("/users/invitation", {
        method: "POST",
        body: formData,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document
            .querySelector("meta[name='csrf-token']")
            .getAttribute("content")
        }
      })
        .then(response => response.json())
        .then(json => {
          if ("errors" in json) this.errors = json.errors;

          if ("email" in json) {
            this.invited = true;
            this.resetUserData();
            this.fetchSectionUsers();
            setTimeout(this.hideModal, 2000);
          }
        });
    },
    hideModal() {
      this.showModal = false;
    },
    resetUserData() {
      this.user = {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        roleIds: null,
        sectionAssigned: null
      };
    },
    toTitleCase(str) {
      return str
        .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
        .replace("_", " ");
    }
  }
};
</script>
